/* eslint  max-classes-per-file: 0 */
import { AccountApi } from 'features/Api';
import { useGetIdealAccountTimeline } from 'features/Api/Account/getIdealAccountTimeline';
import React, { FC, ReactElement, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateTime, Duration } from 'luxon';
import { Button, Icon, Placeholder, PlaceholderImage, Popup } from 'semantic-ui-react';
import {
  Chart,
  ChartConfiguration,
  ChartDataset,
  Color,
  registerables,
  ScatterDataPoint,
  ScriptableScaleContext, ScriptableTooltipContext,
} from 'chart.js';
import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment, { Moment } from 'moment';

import { OpportunityStage } from 'models/opportunity';
import { ActualTimeline, IdealTimelineWithStartDate } from 'models/account/timeline/timeline';
import { selectOrganizationOpportunityStages } from 'selectors/organization';
import { AccountTimelineTooltip } from './AccountWeeklyAverageTooltip';

export interface ActivityBreakdown {
  total: number;
  email: {
    all: number;
    inbound: number;
    outbound: number;
  };
  answeredCall: number;
  meeting: number;
}

export interface ActivitiesByWeekDataPoint {
  x: number;
  y: number;
  date: string;
  weekStart: string;
  weekEnd: string;
  activityBreakdown: ActivityBreakdown;
}

export class ActualDataPoint implements ScatterDataPoint {
  type = 'ActualDataPoint';

  x: number;

  y: number;

  daysSinceTimelineStart: number;

  daysInStage: number;

  activitiesPerWeek: number;

  activityBreakdown: ActivityBreakdown;

  stageName: string;

  color: string;

  weekStart: Moment;

  weekEnd: Moment;

  constructor(x: number | string,
    y: number,
    daysSinceTimelineStart: number,
    daysInStage: number,
    activitiesPerWeek: number,
    activityBreakdown: ActivityBreakdown,
    stageName: string,
    color: string,
    weekStart: string,
    weekEnd: string) {
    if (typeof x === 'string') {
      this.x = moment(x).unix() * 1000;
    } else this.x = x;
    this.y = y;
    this.daysSinceTimelineStart = daysSinceTimelineStart;
    this.daysInStage = daysInStage;
    this.activitiesPerWeek = activitiesPerWeek;
    this.activityBreakdown = activityBreakdown;
    this.stageName = stageName;
    this.color = color;
    this.weekStart = moment(weekStart);
    this.weekEnd = moment(weekEnd);
  }
}

const notableActivityTypes = ['answeredCall', 'meeting', 'stageChange'] as const;
type NotableActivityType = typeof notableActivityTypes[number];

export class NotableActivityDataPoint implements ScatterDataPoint {
  type: NotableActivityType;

  date: Moment;

  activityId: number;

  x: number;

  y: number;

  constructor(activityId: number, type: NotableActivityType, date: string | Moment) {
    this.activityId = activityId;

    this.type = type;

    if (moment.isMoment(date)) {
      this.date = date;
    } else {
      this.date = moment(date);
    }

    this.x = this.date.unix() * 1000;

    this.y = 5;
  }
}

export class StageChangeDataPoint extends NotableActivityDataPoint {
  stageFrom: string;

  stageTo: string;

  constructor(stageFrom: string, stageTo: string, date: string | Moment) {
    super(0, 'stageChange', date);
    this.stageFrom = stageFrom;
    this.stageTo = stageTo;
  }
}

const getNotableActivitiesDatasets = (
  notableActivities: AccountApi.getAccountTimeline.Response['notableActivities'],
  actualTimeline: ActualTimeline,
  notableActivityColor = '#f59ca9',
  stateChangeColor = 'rgba(33, 133, 208, 1)',
): ChartDataset<'scatter'>[] => {
  const tooltipLabelCallback = (context): string => {
    let label = context.dataset.label || '';

    if (label) {
      label += ': ';
    }
    if (context.parsed.y !== null) {
      label += context.parsed.y + 1;
    }
    return label;
  };
  /* eslint-disable object-curly-newline */
  const datasets: { answeredCall: ChartDataset<'scatter'>; meeting: ChartDataset<'scatter'>} = {
    answeredCall: {
      label: 'Answered Calls',
      pointStyle: 'rectRot',
      yAxisID: 'yNotableActivities',
      data: [],
      type: 'scatter',
      pointRadius: 5,
      pointHoverRadius: 5,
      pointBackgroundColor: notableActivityColor,
      pointHoverBackgroundColor: notableActivityColor,
      tooltip: {
        callbacks: { label: tooltipLabelCallback },
      },
    },
    meeting: {
      label: 'Meetings',
      pointStyle: 'rectRot',
      yAxisID: 'yNotableActivities',
      data: [],
      type: 'scatter',
      pointRadius: 5,
      pointHoverRadius: 5,
      pointBackgroundColor: notableActivityColor,
      pointHoverBackgroundColor: notableActivityColor,
      tooltip: {
        callbacks: { label: tooltipLabelCallback },
      },
    },
  };
  notableActivities.forEach(activity => {
    const { activityId, type, date } = activity;
    const dataPoint = new NotableActivityDataPoint(activityId, type, date);
    if (datasets[type]) datasets[type].data.push(dataPoint);
  });
  /* eslint-enable object-curly-newline */

  const stageChangeDataPoints = actualTimeline.getStageChangeDataPoints();
  const stageChangeDataset: ChartDataset<'scatter'> = {
    label: 'Opportunity Stage Start',
    pointStyle: 'rectRot',
    yAxisID: 'yNotableActivities',
    data: stageChangeDataPoints,
    type: 'scatter',
    pointRadius: 5,
    pointHoverRadius: 5,
    pointBackgroundColor: stateChangeColor,
    backgroundColor: stateChangeColor,
    borderColor: stateChangeColor,
    tooltip: { callbacks: { label: tooltipLabelCallback } },
  };

  return [datasets.answeredCall, datasets.meeting, stageChangeDataset];
};

const getAllDatasets = (
  idealTimeline: IdealTimelineWithStartDate,
  actualTimeline: ActualTimeline,
  notableActivities: AccountApi.getAccountTimeline.Response['notableActivities'],
  opportunityStages: OpportunityStage[],
  showIdeal: boolean,
): ChartDataset<'line' | 'scatter'>[] => {
  let datasets: ChartDataset<'line' | 'scatter'>[] = [];
  if (showIdeal) {
    datasets = datasets.concat(idealTimeline.getDatasets(opportunityStages));
  }
  if (actualTimeline) {
    datasets = datasets.concat(actualTimeline.getDatasets(opportunityStages));
    getNotableActivitiesDatasets(notableActivities, actualTimeline).forEach(dataset => {
      datasets.push(dataset);
    });
  }
  return datasets;
};

const getSuggestedMin = (actualTimeline: ActualTimeline): number => {
  let firstDate = DateTime.now();
  if (actualTimeline.getStageChangeDataPoints().length === 0
    && actualTimeline.getNumberOfActivitiesByWeekDataPoints() === 1) {
    const firstDataPoint = actualTimeline.getFirstActivitiesByWeekDataPoint();
    if (firstDataPoint) firstDate = DateTime.fromISO(firstDataPoint.date);
    return firstDate.minus({ week: 1 }).toMillis();
  }
  return firstDate.minus({ week: 1 }).toMillis();
};

const getMin = (actualTimeline: ActualTimeline): number | undefined => {
  const firstDataPoint = actualTimeline.getFirstActivitiesByWeekDataPoint();
  if (firstDataPoint) {
    const oneDay = Duration.fromObject({ days: 1 }).toMillis();
    return DateTime.fromISO(firstDataPoint.date).toMillis() - oneDay;
  }
  return undefined;
};

const getInitialMin = (actualTimeline: ActualTimeline): number | undefined => {
  const boundaries = actualTimeline.getStageDateBoundaries();
  if (boundaries) {
    const { start } = boundaries;
    const oneDay = Duration.fromObject({ days: 1 }).toMillis();
    const min = start.startOf('week').toMillis() - oneDay;
    return min;
  }
  const firstDataPoint = actualTimeline.getFirstActivitiesByWeekDataPoint();
  if (firstDataPoint) {
    const oneDay = Duration.fromObject({ days: 1 }).toMillis();
    return DateTime.fromISO(firstDataPoint.date).toMillis() - oneDay;
  }
  return undefined;
};

const getSuggestedMax = (actualTimeline: ActualTimeline): number => {
  let firstDate = DateTime.now();
  const firstDataPoint = actualTimeline.getFirstActivitiesByWeekDataPoint();
  if (firstDataPoint) firstDate = DateTime.fromISO(firstDataPoint.date);
  return firstDate.plus({ week: 1 }).toMillis();
};

const getZoomMin = (actualTimeline: ActualTimeline): number => {
  let firstDate = DateTime.now();
  const firstDataPoint = actualTimeline.getFirstActivitiesByWeekDataPoint();
  if (firstDataPoint) firstDate = DateTime.fromISO(firstDataPoint.date);
  return firstDate.minus({ day: 1 }).toMillis();
};

const getZoomMax = (actualTimeline: ActualTimeline, idealTimeline?: IdealTimelineWithStartDate): number => {
  let lastActual = DateTime.now().toMillis();
  const lastDataPoint = actualTimeline.getLastActivitiesByWeekDataPoint();
  if (lastDataPoint) lastActual = DateTime.fromISO(lastDataPoint.date).plus({ day: 1 }).toMillis();

  if (!idealTimeline) return lastActual;
  const lastIdeal = idealTimeline.getEndDate().add(1, 'day').unix() * 1000;

  return Math.max(lastActual, lastIdeal);
};

const zoomShowAll = (
  chart: Chart<'line' | 'scatter'>,
  actualTimeline: ActualTimeline,
  idealTimeline: IdealTimelineWithStartDate,
  showIdeal: boolean,
): void => {
  const min = getMin(actualTimeline);
  if (!min) return;

  const max = getZoomMax(actualTimeline, (showIdeal ? idealTimeline : undefined));
  const { config: { options } } = chart;

  if (options && options.scales && options.scales.x) {
    options.scales.x.min = min;
    options.scales.x.max = max;
    chart.update();
  }
};

const zoomShowOpportunityBoundaries = (chart: Chart<'line' | 'scatter'>, actualTimeline: ActualTimeline): void => {
  const boundaries = actualTimeline.getStageDateBoundaries();
  if (boundaries) {
    const { config: { options } } = chart;
    if (options && options.scales && options.scales.x) {
      const oneDay = Duration.fromObject({ days: 1 }).toMillis();
      const { start, end } = boundaries;
      const min = start.startOf('week').toMillis() - oneDay;
      const max = end.endOf('week').toMillis() + oneDay;
      options.scales.x.min = min;
      options.scales.x.max = max;
      chart.update();
    }
  }
};

const zoomIn = (chart: Chart<'line' | 'scatter'>, actualTimeline: ActualTimeline): void => {
  const min = getMin(actualTimeline);
  if (!min) return;

  const max = getZoomMax(actualTimeline);
  const { config: { options } } = chart;

  if (options && options.scales && options.scales.x) {
    const newMin = (options.scales.x.min as number) || min;
    const newMax = (options.scales.x.max as number) || max;
    const step = Duration.fromObject({ weeks: 7 }).toMillis();
    if (newMin + step < newMax - step) {
      options.scales.x.min = newMin + step;
      options.scales.x.max = newMax - step;
      chart.update();
    }
  }
};

const canZoomIn = (chart: Chart<'line' | 'scatter'>, actualTimeline: ActualTimeline): boolean => {
  const min = getMin(actualTimeline);
  if (!min) return false;

  const max = getZoomMax(actualTimeline);
  const { config: { options } } = chart;

  if (options && options.scales && options.scales.x) {
    const newMin = (options.scales.x.min as number) || min;
    const newMax = (options.scales.x.max as number) || max;
    const step = Duration.fromObject({ weeks: 7 }).toMillis();
    return (newMin + step < newMax - step);
  }
  return false;
};

const zoomOut = (chart: Chart<'line' | 'scatter'>, actualTimeline: ActualTimeline): void => {
  const min = getMin(actualTimeline);
  if (!min) return;

  const max = getZoomMax(actualTimeline);
  const { config: { options } } = chart;

  if (options && options.scales && options.scales.x) {
    const newMin = (options.scales.x.min as number) || min;
    const newMax = (options.scales.x.max as number) || max;
    const step = Duration.fromObject({ weeks: 7 }).toMillis();
    options.scales.x.min = Math.max(newMin - step, min);
    options.scales.x.max = Math.min(newMax + step, max);
    chart.update();
  }
};

const canZoomOut = (chart: Chart<'line' | 'scatter'>, actualTimeline: ActualTimeline): boolean => {
  const min = getMin(actualTimeline);
  if (!min) return false;

  const max = getZoomMax(actualTimeline);
  const { config: { options } } = chart;

  if (options && options.scales && options.scales.x) {
    const newMin = options.scales.x.min || min;
    const newMax = options.scales.x.max || max;

    return (newMin > min) || (newMax < max);
  }
  return false;
};

const getConfig = (
  idealTimeline: IdealTimelineWithStartDate,
  actualTimeline: ActualTimeline,
  notableActivities: AccountApi.getAccountTimeline.Response['notableActivities'],
  opportunityStages: OpportunityStage[],
  showIdeal: boolean,
  setTooltipCtx: React.Dispatch<
    React.SetStateAction<ScriptableTooltipContext<'line' | 'scatter'> | null>
  >,
): ChartConfiguration<'line' | 'scatter'> => ({
  type: 'line',
  data: {
    datasets: getAllDatasets(idealTimeline, actualTimeline, notableActivities, opportunityStages, showIdeal),
  },
  options: {
    bezierCurve: false,
    interaction: { mode: 'nearest', axis: 'x' },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        suggestedMax: getSuggestedMax(actualTimeline),
        suggestedMin: getSuggestedMin(actualTimeline),
        min: getInitialMin(actualTimeline),
        time: { unit: 'week' },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      yMain: {
        title: {
          display: true,
          text: 'Avg. Activities per Week',
        },
        type: 'linear',
        suggestedMax: 5,
        min: 0,
        grace: '5%',
        /**
         * stackWeight determines how much space should be allocated to this axis, vs any other axis
         * in the same dimension.
         *
         * i.e. if the ratio of this stackWeight, to the stackWeight of yNotableActivities gets bigger,
         * the notable activities axis will become smaller.
         */
        stackWeight: 20,
        stacked: true,
        stack: 'stack',
        /**
         * weight determines the order of axes in the same dimension.
         *
         * e.g. setting this to a lower value than the value of yNotableActivities.weight will move this below
         * notable activities
         */
        weight: 1,
        grid: {
          drawBorder: true,
          color: (ctx: ScriptableScaleContext): Color => {
            if (ctx.tick.value === 0) return Chart.defaults.borderColor.toString();
            return 'rgba(0,0,0,0)';
          },
        },
      },
      yNotableActivities: {
        type: 'category',
        stackWeight: 1,
        stacked: true,
        stack: 'stack',
        weight: -5,
        reverse: true,
        grid: {
          display: false,
          drawBorder: false,
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: (ctx: ScriptableTooltipContext<'line' | 'scatter'>): void => setTooltipCtx(ctx),
        boxWidth: 350,
      },
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          speed: 1,
        },
        limits: {
          x: {
            min: getZoomMin(actualTimeline),
            max: getZoomMax(actualTimeline, idealTimeline),
          },
        },
      },
    },
  },
});

const ZoomButtons: FC<{
  chart?: Chart<'scatter' | 'line'>;
  showIdeal: boolean;
  actualTimeline?: ActualTimeline;
  idealTimeline?: IdealTimelineWithStartDate;
}> = ({ chart, showIdeal, actualTimeline, idealTimeline }) => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      right: 0,
    }}
  >
    <Button
      icon
      disabled={chart && actualTimeline && !canZoomIn(chart, actualTimeline)}
      onClick={(): void => { if (chart && actualTimeline) zoomIn(chart, actualTimeline); }}
    >
      <Popup
        basic
        content="Zoom in"
        trigger={(<Icon name="zoom in" />)}
      />
    </Button>
    <Button
      icon
      disabled={chart && actualTimeline && !canZoomOut(chart, actualTimeline)}
      onClick={(): void => { if (chart && actualTimeline) zoomOut(chart, actualTimeline); }}
    >
      <Popup
        basic
        content="Zoom out"
        trigger={(<Icon name="zoom out" />)}
      />
    </Button>
    <Button
      icon
      onClick={(): void => {
        if (chart && actualTimeline && idealTimeline) {
          zoomShowAll(chart, actualTimeline, idealTimeline, showIdeal);
        }
      }}
    >
      <Popup
        basic
        content="Show entire timeline"
        trigger={(<Icon name="globe" />)}
      />
    </Button>
    <Button
      icon
      onClick={(): void => {
        if (chart && actualTimeline) {
          zoomShowOpportunityBoundaries(chart, actualTimeline);
        }
      }}
    >
      <Popup
        basic
        content="Show since opportunity open"
        trigger={(<Icon name="chart line" />)}
      />
    </Button>
  </div>
);

export const AccountTimeline = (props: {
  accountId: number; showIdeal: boolean; opportunityId?: number; height?: number;
}): ReactElement => {
  const { accountId, showIdeal, opportunityId, height = 500 } = props;

  const {
    data: accountTimelineData,
  } = AccountApi.getAccountTimeline.useGetAccountTimelineQuery({ accountId, opportunityId });

  const {
    data: idealTimelineData,
    isFetching,
  } = useGetIdealAccountTimeline({});

  const [tooltipCtx, setTooltipCtx] = useState<null | ScriptableTooltipContext<'line' | 'scatter'>>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [chart, setChart] = useState<Chart<'scatter' | 'line'> | undefined>(undefined);
  const [actualTimeline, setActualTimeline] = useState<ActualTimeline | undefined>(undefined);
  const [idealTimeline, setIdealTimeline] = useState<IdealTimelineWithStartDate | undefined>(undefined);

  const opportunityStages = useSelector(selectOrganizationOpportunityStages);

  const canvasRef = useCallback((canvasNode: HTMLCanvasElement | null): void => {
    if (!idealTimelineData || !accountTimelineData || isFetching) return;
    const { accountTimeline: accountTimelineDataPoints, activitiesByWeek, notableActivities } = accountTimelineData;
    const newIdealTimeline = new IdealTimelineWithStartDate(idealTimelineData, accountTimelineDataPoints);
    const newActualTimeline = new ActualTimeline(accountTimelineDataPoints, activitiesByWeek);
    setIdealTimeline(newIdealTimeline);
    setActualTimeline(newActualTimeline);
    if (!canvasNode || !opportunityStages) return;

    const config = getConfig(
      newIdealTimeline, newActualTimeline, notableActivities, opportunityStages, showIdeal, setTooltipCtx,
    );

    if (chart) chart.destroy();
    const ctx = canvasNode.getContext('2d');
    if (ctx) {
      Chart.register(...registerables);
      Chart.register(zoomPlugin);
      setChart((new Chart(ctx, config)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountTimelineData, idealTimelineData, opportunityStages, showIdeal, isFetching]);

  if (isFetching) {
    return (
      <Placeholder>
        <PlaceholderImage square />
      </Placeholder>
    );
  }
  if (!idealTimelineData) return <>No ideal timeline</>;
  if (!accountTimelineData) return <>No data</>;
  const { accountTimeline } = accountTimelineData;
  if (!accountTimeline) return <>No account timeline</>;
  if (!opportunityStages) return <>No opportunity stages</>;

  return (
    <div style={{ height }}>
      <ZoomButtons chart={chart} actualTimeline={actualTimeline} idealTimeline={idealTimeline} showIdeal={showIdeal} />
      <canvas ref={canvasRef} height={300} />
      <AccountTimelineTooltip ctx={tooltipCtx} accountId={accountId} />
    </div>
  );
};
