import { createSlice } from '@reduxjs/toolkit';
import { DateSelectionSliceState } from '../DatePicker/datePickerTypes';
import { createDateSelectionSliceSelectorsAndReducers } from '../DatePicker/datePickerFunctions';

const SLICE_NAME = 'contact';

export interface ContactState extends DateSelectionSliceState { }

const initialState: ContactState = {
  dateSelection: {
    type: 'relative',
    start: { days: -14 },
    end: { days: 0 },
  },
};

const selectorsAndReducer = createDateSelectionSliceSelectorsAndReducers(SLICE_NAME);

const {
  setDateSelectionReducer: setDateSelectionFn,
  setAbsoluteStartEndDatesReducer: setAbsoluteStartEndDatesFn,
  setDateSelectionAroundDateReducer: setDateSelectionAroundDateFn,
} = selectorsAndReducer;

export const contactSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setContactDateSelection: setDateSelectionFn,
    setContactDateSelectionWithStartEndDates: setAbsoluteStartEndDatesFn,
    setContactDateSelectionAroundDate: setDateSelectionAroundDateFn,
  },
});

export const {
  selectDateSelection: selectContactDateSelection,
  selectStartAndEndDates: selectContactStartAndEndDates,
} = selectorsAndReducer;

export const {
  setContactDateSelectionWithStartEndDates,
  setContactDateSelectionAroundDate,
  setContactDateSelection,
} = contactSlice.actions;
