import React, { Component } from 'react';
import {
  Chart,
  ChartConfiguration,
  ChartDataset, Color,
  registerables, ScriptableScaleContext, ScriptableTooltipContext,
} from 'chart.js';
import 'chartjs-adapter-moment';
import { OpportunityStage } from '../../../../../models/opportunity';
import { IdealTimelineWithoutStartDate } from '../../../../../models/account/timeline/timeline';
import { AccountTimelineTooltip } from '../AccountTimeline/AccountWeeklyAverageTooltip';
import { TimelineDataPoint } from '../../../../../features/Api/Account/getAccountTimeline';

interface Props {
  isLoading: boolean;
  idealTimeline: TimelineDataPoint[];
  opportunityStages: OpportunityStage[];
}
interface State {
  tooltipContext: ScriptableTooltipContext<'line' | 'scatter'> | null;
  idealTimeline: IdealTimelineWithoutStartDate;
  isEmpty: boolean;
}

export default class IdealTimeline extends Component<Props, State> {
  canvas;

  chart: Chart | null = null;

  constructor(props) {
    super(props);
    this.canvas = React.createRef();

    Chart.register(...registerables);

    const { idealTimeline: idealTimelineApiResponse } = props;
    const idealTimeline = new IdealTimelineWithoutStartDate(idealTimelineApiResponse);
    this.state = { idealTimeline, isEmpty: true, tooltipContext: null };
  }

  componentDidMount(): void {
    this.renderChart();
  }

  componentDidUpdate(prevProps, prevState): void {
    const {
      props: { idealTimeline: idealTimelineProp, isLoading, opportunityStages },
      state: { idealTimeline: idealTimelineState },
    } = this;

    if (prevProps.idealTimeline !== idealTimelineProp) {
      this.updateTimeline();
    }
    if (prevProps.opportunityStages.length !== opportunityStages.length) {
      this.renderChart();
    }
    const hasStoppedLoading = prevProps.isLoading && !isLoading;
    const idealTimelineStateHasChanged = JSON.stringify(prevState.idealTimeline) !== JSON.stringify(idealTimelineState);
    if (hasStoppedLoading || idealTimelineStateHasChanged) {
      this.renderChart();
    }
  }

  updateTimeline = (): void => {
    const { props: { idealTimeline: idealTimelineApiResponse } } = this;
    const idealTimeline = new IdealTimelineWithoutStartDate(idealTimelineApiResponse);
    this.setState({ idealTimeline });
  };

  getAllDatasets = (): ChartDataset<'line'>[] => {
    const { state: { idealTimeline }, props: { opportunityStages } } = this;
    return idealTimeline.getDatasets(opportunityStages);
  };

  externalTooltipCallback = (newContext: ScriptableTooltipContext<'line' | 'scatter'>): void => {
    const { state: { tooltipContext: currentContext } } = this;
    if (currentContext !== newContext) {
      this.setState({ tooltipContext: newContext });
    }
  }

  renderChart = (): void => {
    const datasets = this.getAllDatasets();
    this.setState({ isEmpty: datasets.length === 0 });
    if (this.chart) this.chart.destroy();
    const config: ChartConfiguration<'line'> = {
      type: 'line',
      data: { datasets },
      options: {
        interaction: { mode: 'point' },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'linear',
            ticks: { stepSize: 1 },
            title: {
              display: true,
              text: 'Days Since Opportunity Open',
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            type: 'linear',
            suggestedMax: 5,
            min: 0,
            grace: '5%',
            title: {
              display: true,
              text: 'Avg. Activities per Week',
            },
            grid: {
              drawBorder: true,
              color: (ctx: ScriptableScaleContext): Color => {
                if (ctx.tick.value === 0) return Chart.defaults.borderColor.toString();
                return 'rgba(0,0,0,0)';
              },
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: false,
            external: this.externalTooltipCallback,
            boxWidth: 350,
          },
          legend: { display: false },
        },
      },
    };
    const ctx = this.canvas.current.getContext('2d');
    this.chart = new Chart(ctx, config);
  };

  render(): React.ReactNode {
    const { tooltipContext, isEmpty } = this.state;
    const display = isEmpty ? 'flex' : 'none';
    const noDataOverlayStyles: React.CSSProperties = {
      height: '100%',
      width: '100%',
      position: 'absolute',
      backgroundColor: 'rgba(1,1,1,.5)',
      justifyContent: 'center',
      alignContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      fontSize: '1.4rem',
      color: 'honeydew',
      display,
    };
    return (
      <div style={{ position: 'relative', overflow: 'visible', height: '450px' }}>
        <div style={noDataOverlayStyles}>
          An ideal opportunity timeline chart will be generated when your
          organization has more sales data in BuyerSight.
        </div>
        <canvas ref={this.canvas} style={{ width: '100%' }} />
        <AccountTimelineTooltip ctx={tooltipContext} isIdeal />
      </div>
    );
  }
}
