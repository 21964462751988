import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { GoalAccount } from 'models/goal';
import { getSelectedTeamMembers } from 'selectors/team';
import AccountAppLink from 'components/Links/AccountAppLink';

export const GoalTrackingAccount = (props: {
  data: GoalAccount[];
}): ReactElement => {
  const { data } = props;

  const teamMembers = useSelector(getSelectedTeamMembers);
  const trackedTeamMemberIds = teamMembers.filter(u => u.isTracked).map(u => u.id);

  const rows = data.map(r => {
    let ownerNode: React.ReactNode = r.ownerName;
    if (trackedTeamMemberIds.includes(r.ownerId)) {
      ownerNode = (<Link to={`/app/team/${r.ownerId}`}>{r.ownerName}</Link>);
    }
    return (
      <Table.Row key={r.id}>
        <Table.Cell>
          <AccountAppLink account={{ id: r.id, name: r.name }} />
        </Table.Cell>
        <Table.Cell>{r.industry}</Table.Cell>
        <Table.Cell>{r.type}</Table.Cell>
        <Table.Cell>{ownerNode}</Table.Cell>
      </Table.Row>
    );
  });

  return (
    <>
      <Table sortable compact basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Account</Table.HeaderCell>
            <Table.HeaderCell>Industry</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Owner</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows}
        </Table.Body>
      </Table>
    </>
  );
};

export default GoalTrackingAccount;
