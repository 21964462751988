import React, { FC, useState, useCallback } from 'react';
import { Chart, registerables, ChartConfiguration, ChartDataset, TooltipItem } from 'chart.js';
import moment, { MomentInput } from 'moment';
import 'chartjs-adapter-moment';

import { MappedDataSet, MetricGroupByOptions } from 'features/Api/Activity/';
import { makeColorGenerator } from 'lib/util';
import bslegend from 'lib/bslegend';

const tooltipDisplayFormat = 'MMMM DD, YYYY';

const getChartConfig = (
  datasets: ChartDataset<'line'>[],
  groupBy?: MetricGroupByOptions,
): ChartConfiguration<'line'> => ({
  type: 'line',
  data: {
    datasets,
  },
  options: {
    interaction: {
      mode: 'index',
      axis: 'x',
    },
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      bslegend: { containerID: 'legend-container' },
      tooltip: {
        callbacks: {
          title: (tooltipItems: TooltipItem<'line'>[]): string => {
            const item = tooltipItems[0];
            const start = moment.utc(item.parsed.x).format(tooltipDisplayFormat);
            if (groupBy && ['week', 'month', 'quarter'].includes(groupBy)) {
              const end = moment.utc(item.parsed.x).add(1, groupBy).subtract(1, 'day').format(tooltipDisplayFormat);
              return `${start} to ${end}`;
            }
            return start;
          },
        },
      },
    },
    tension: 0.5,
    borderWidth: 2,
    pointBorderWidth: 4,
    scales: {
      x: {
        type: 'time',
        grid: { display: false },
        ticks: {
          autoSkip: false,
          maxTicksLimit: 28,
          source: 'data',
        },
        time: {
          unit: 'day',
          round: 'day',
          tooltipFormat: tooltipDisplayFormat,
          parser: (v: unknown): number => {
            const m = moment(moment.utc((v as MomentInput)).format('YYYY MM DD'));
            return m.toDate().getTime();
          },
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  },
  plugins: [bslegend],
});

const MetricOverTimeChart: FC<{
  data: MappedDataSet;
  groupBy?: MetricGroupByOptions;
}> = ({ data, groupBy }) => {
  const [chart, setChart] = useState<Chart<'line'> | undefined>(undefined);

  if (!data) return null;

  const canvasRef = useCallback((canvasNode: HTMLCanvasElement | null): void => {
    if (!canvasNode) return;

    if (chart) chart.destroy();

    const colorGenerator = makeColorGenerator();
    const chartDatasets = Object.values(data).map(dataset => {
      const color = colorGenerator();
      return {
        borderColor: color,
        backgroundColor: color,
        ...dataset,
      };
    });

    const config: ChartConfiguration<'line'> = getChartConfig(chartDatasets, groupBy);

    const ctx = canvasNode.getContext('2d');
    if (ctx) {
      Chart.register(...registerables);
      setChart((new Chart(ctx, config)));
    }
  }, [data]);

  return (
    <>
      <div id="legend-container" />
      <canvas ref={canvasRef} />
    </>
  );
};

export default MetricOverTimeChart;
