import { FunnelFilter } from 'features/Funnel/funnelSlice';
import { constructQueryString } from 'lib/util';
import { Funnel, FunnelStats } from 'models/funnel';
import { Picklist } from 'models/picklist';

import baseApi from '../apiSlice';
import { ApiResponse } from '../apiTypes';

const ONE_HOUR = 3600;

export interface FunnelQuery {
  teamId: number;
  startDate: string;
  endDate: string;
  filter?: FunnelFilter;
}
export interface LevelQuery {
  teamId: number;
  levelId: number;
  startDate: string;
  endDate: string;
  filter?: FunnelFilter;
}
export interface FilterQuery {
  startDate: string;
  endDate: string;
}

export interface FunnelResponse extends ApiResponse {
  funnel: Funnel;
  stats: FunnelStats[];
}

export interface FunnelFiltersResponse extends ApiResponse {
  type: string[];
  industry: string[];
  country: string[];
  state: string[];
  leadSource: string[];
  custom?: Picklist[];
}

const funnelStatsApi = baseApi.injectEndpoints({
  refetchOnMountOrArgChange: true,
  endpoints: builder => ({
    getFunnelStats: builder.query<FunnelResponse, FunnelQuery>({
      query: ({ teamId, startDate, endDate, filter }) => {
        const queryString = constructQueryString(filter);
        return `funnel/team/${teamId}/${startDate}-${endDate}${queryString}`;
      },
    }),
    getFunnelLevelDetails: builder.query<FunnelResponse, LevelQuery>({
      query: ({ teamId, startDate, endDate, levelId, filter }) => {
        const queryString = constructQueryString(filter);
        return `funnel/team/${teamId}/${startDate}-${endDate}/details/${levelId}${queryString}`;
      },
    }),
    getFunnelFilterValues: builder.query<FunnelFiltersResponse, FilterQuery>({
      query: ({ startDate, endDate }) => `funnel/filters/${startDate}-${endDate}`,
      keepUnusedDataFor: ONE_HOUR,
    }),
  }),
});

export default funnelStatsApi;
