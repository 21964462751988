import { connect } from 'react-redux';
import Heap from 'react-heap';

const heapId = process.env.REACT_APP_HEAP_ID;

const ConnectedHeap = heapId ? connect(
  (state: any): any => ({
    appId: heapId,
    userId: state.app.originalUserEmail ? `${state.app.originalUserEmail}->${state.app.email}` : state.app.email,
    userData: {
      id: state.app.id,
      firstName: state.app.firstName,
      lastName: state.app.lastName,
      email: state.app.email,
      originalUserEmail: state.app.originalUserEmail,
    },
  }),
)(Heap) : (): null => null;

export default ConnectedHeap;
