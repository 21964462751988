/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

type PrivateRouteProps = {
  component: any;
  isAuthenticated?: boolean;
  redirectTo?: string;
  path: string;
};

export default class PrivateRoute extends Component<PrivateRouteProps, {}> {
  static defaultProps = {
    redirectTo: '/',
    isAuthenticated: false,
  };

  render(): React.ReactNode {
    const { component: PrivateComponent, redirectTo, isAuthenticated, ...moreProps } = this.props;
    const contents = (props): React.ReactNode => (isAuthenticated ? (
      <PrivateComponent {...props} />
    ) : (
      <Redirect
        to={{
          pathname: redirectTo,
          from: props.location,
        }}
      />
    ));
    return <Route {...moreProps} render={contents} />;
  }
}
