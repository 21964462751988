import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, Route, Switch, RouteComponentProps } from 'react-router-dom';
import { Label, Menu } from 'semantic-ui-react';

import allActions, { bindCombinedActions } from 'actions';
import { getTrackedUsers } from 'selectors/user';
import AccessControl, { Permission } from 'components/AccessControl';
import DatePicker from 'features/DatePicker/DatePicker';
import { selectAppDateSelection, setAppDateSelection } from 'features/App/appSlice';
import { TimePeriod } from 'features/DatePicker/datePickerTypes';

import UserScorecard from './UserScorecard';
import UserOverview from './UserOverview/UserOverview';
import UserActivity from './UserActivity';
import UserInfoPlain from './components/UserInfo/UserInfoPlain';

import css from './User.module.css';

interface Props extends RouteComponentProps {
  actions: any;
  isLoadingUserInfo: boolean;
  isLoadingTrackedUsers: boolean;
  isSyncing: boolean;
  location: any;
  match: { params: { userId: string }; path: string; url: string; isExact: boolean };
  trackedUsers: Array<any>;
  userInfo: any;
  timePeriod: TimePeriod;
}
interface State { }

interface UserMenuItem {
  beta?: boolean;
  label: string;
  route: string;
  component: any;
  exact?: boolean;
  permission?: Permission;
}

class User extends Component<Props, State> {
  componentDidMount(): void {
    const { actions, match, trackedUsers } = this.props;

    const userId = parseInt(match.params.userId, 10);

    const fetchInfo = async (): Promise<void> => {
      if (userId && trackedUsers.length === 0) {
        await actions.user.fetchUsers();
      }
      if (userId) {
        await actions.user.fetchUserInfo(userId);
      }
    };
    fetchInfo();
  }

  componentDidUpdate(): void {
    const { actions, isLoadingUserInfo, userInfo, match } = this.props;

    const paramUserId = parseInt(match.params.userId, 10);

    if (!isLoadingUserInfo && userInfo.id && userInfo.id !== paramUserId) {
      actions.user.fetchUserInfo(paramUserId);
    }
  }

  componentWillUnmount(): void {
    const { actions } = this.props;
    actions.activity.clearActivities();
    actions.user.clearUser();
  }

  render(): React.ReactNode {
    const { location, isLoadingUserInfo, userInfo, timePeriod } = this.props;

    const menuItemConfig: Array<UserMenuItem> = [
      { label: 'Overview', route: `/app/team/${userInfo.id}`, component: UserScorecard, exact: true },
      { label: 'Charts', route: `/app/team/${userInfo.id}/chart`, component: UserOverview },
      { label: 'Activity', route: `/app/team/${userInfo.id}/activityFeed`, component: UserActivity },
    ];
    const menuItems = menuItemConfig.map((config): React.ReactNode => {
      const { beta, label, route, exact, permission } = config;
      return (
        <AccessControl key={label} accessingTo={permission || 'rep'}>
          <Menu.Item as={NavLink} to={route} disabled={isLoadingUserInfo} exact={exact}>
            {label}
            {beta ? (<Label>beta</Label>) : null}
          </Menu.Item>
        </AccessControl>
      );
    });
    const routes = menuItemConfig.reverse().map((config): React.ReactNode => {
      const { route, exact, component } = config;
      return <Route key={route} path={route} component={component} exact={exact} />;
    });
    const hideDate = location.pathname.includes('activityFeed');
    return (
      <div className={css.user}>
        <div className={css.userHeader}>
          <UserInfoPlain user={userInfo} />
          {hideDate ? null : (<DatePicker reduxAction={setAppDateSelection} timePeriod={timePeriod} />)}
        </div>
        <Menu pointing secondary>
          {menuItems}
        </Menu>
        <Switch>
          {routes}
        </Switch>
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    isLoadingUserInfo: state.user.isLoadingUserInfo,
    userInfo: state.user.userInfo,
    isLoadingTrackedUsers: state.user.isLoadingUsers,
    trackedUsers: getTrackedUsers(state),
    isSyncing: state.user.isSyncing,
    timePeriod: selectAppDateSelection(state),
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(User);
