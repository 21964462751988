import { AccountApi } from 'features/Api';
import moment, { Moment } from 'moment';

export class TimelineStage {
  name: string;

  masterLabel: string;

  sortOrder: number;

  daysSinceTimelineStart: number;

  dateStart?: Moment;

  daysInStage: number;

  totalActivities: number;

  activitiesPerWeek: number;

  totalEmails: number;

  emailsPerWeek: number;

  totalAnsweredCalls: number;

  answeredCallsPerWeek: number;

  totalMeetings: number;

  meetingsPerWeek: number;

  constructor(timelineDataPoint: AccountApi.getAccountTimeline.TimelineDataPoint) {
    const {
      stageName: name,
      masterLabel,
      sortOrder,
      daysSinceTimelineStart,
      dateStart,
      daysInStage,
      totalActivities,
      activitiesPerWeek,
      totalEmails,
      emailsPerWeek,
      totalAnsweredCalls,
      answeredCallsPerWeek,
      totalMeetings,
      meetingsPerWeek,
    } = timelineDataPoint;

    this.name = name;
    this.masterLabel = masterLabel;
    this.sortOrder = sortOrder;
    this.daysSinceTimelineStart = daysSinceTimelineStart ? parseFloat(daysSinceTimelineStart.toFixed(3)) : 0;
    if (dateStart) {
      this.dateStart = moment(dateStart).utc();
    }
    this.daysInStage = daysInStage ? parseFloat(daysInStage.toFixed(3)) : 0;
    this.totalActivities = totalActivities;
    this.activitiesPerWeek = activitiesPerWeek;
    this.totalEmails = totalEmails;
    this.emailsPerWeek = emailsPerWeek;
    this.totalAnsweredCalls = totalAnsweredCalls;
    this.answeredCallsPerWeek = answeredCallsPerWeek;
    this.totalMeetings = totalMeetings;
    this.meetingsPerWeek = meetingsPerWeek;
  }

  isClosed(): boolean {
    return this.name.toLowerCase().indexOf('closed') > -1;
  }
}
