import React from 'react';
import { Checkbox, Popup, Icon, CheckboxProps } from 'semantic-ui-react';

import css from './TrackedSeats.module.css';

interface TrackedSeatsProps {
  selected?: boolean;
  disabled: boolean;
  numOfTrackedUsers: number;
  maxTrackedSeats: number;
  onChange: (checked: boolean) => void;
}

const TrackedSeats: React.FC<TrackedSeatsProps> = ({
  selected,
  disabled,
  numOfTrackedUsers,
  maxTrackedSeats,
  onChange,
}) => {
  const handleTrackedChange = (
    _: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps,
  ): void => {
    onChange(!!data.checked);
  };

  return (
    <div className={css.trackedSeatsContainer}>
      <div className={css.trackedSeatsContentContainer}>
        <Checkbox
          checked={selected}
          disabled={disabled}
          label="Track User"
          onChange={handleTrackedChange}
        />
        <br />
        <label>{`${numOfTrackedUsers} out of ${maxTrackedSeats} seats utilized`}</label>
      </div>
      <Popup
        hoverable
        mouseLeaveDelay={500}
        content={(
          <label>
            Contact your account manager or support@buyersight.io to increase
            the number of seats in your organization.
          </label>
        )}
        trigger={<Icon name="question circle" />}
      />
    </div>
  );
};

export default TrackedSeats;
