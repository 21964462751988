import React, { ReactElement } from 'react';
import { Header, Popup } from 'semantic-ui-react';

import { TeamApi } from 'features/Api';
import { getGoalHeaderText } from 'lib/util';

import css from './styles.module.css';

const GoalTrackingHeader = (props: {
  userGoal: TeamApi.Goals.GoalAttainment;
  previousGoalBtn?: ReactElement;
  nextGoalBtn?: ReactElement;
}): ReactElement => {
  const { userGoal, previousGoalBtn, nextGoalBtn } = props;
  const {
    goalName,
    period,
  } = userGoal;
  const { subHeaderText, popupContent } = getGoalHeaderText(period);
  let subHeader = (
    <Header.Subheader>
      {previousGoalBtn}
      {subHeaderText}
      {nextGoalBtn}
    </Header.Subheader>
  );
  if (popupContent) {
    subHeader = <Popup content={popupContent} trigger={subHeader} />;
  }

  return (
    <Header className={css.header}>
      {subHeader}
      {goalName}
    </Header>
  );
};

export default GoalTrackingHeader;
