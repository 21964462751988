/* eslint-disable react/no-array-index-key */
import React from 'react';
import withSortableTable, { WithSortableTable } from 'components/withSortableTable';
import { Table } from 'semantic-ui-react';
import { BSTableProps } from './BSTable';

interface SortableTableProps<T>
  extends Omit<BSTableProps<T>, 'sortable' | 'searchKey' | 'searchPlaceholder'>, WithSortableTable<T> {
  isSortable?: boolean;
}

function SortableTable<T>({
  properties,
  sortable,
  isSortable = true,
  renderRow,
  onRowSelect,
  ...tableProps
}: SortableTableProps<T>): React.ReactElement {
  return (
    <Table selectable sortable={isSortable} basic="very" {...tableProps}>
      <Table.Header>
        <Table.Row>
          {properties.map(p => {
            const handleSorting = p.sort ? (): void => { sortable.handleSort(p.key, p.sortType); } : undefined;
            const sorted = p.sort && sortable.column === p.key ? sortable.direction : undefined;

            return (
              <Table.HeaderCell
                key={p.key}
                sorted={sorted}
                onClick={handleSorting}
                {...p.headerCellProps}
              >
                {p.title}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {sortable.data.map((rowData: T, index: number, array: T[]) => {
          if (renderRow !== undefined) {
            return renderRow(rowData, index, array);
          }
          return (
            <Table.Row onClick={(): void => onRowSelect !== undefined && onRowSelect(rowData)} key={index}>
              {properties.map(p => (
                <Table.Cell key={p.key}>
                  {rowData[p.key]}
                </Table.Cell>
              ))}
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

export default withSortableTable(SortableTable, 'data') as
  <T extends any>(props: Omit<BSTableProps<T>, 'sortable' | 'searchKey' | 'searchPlaceholder'>) => React.ReactElement;
