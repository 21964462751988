/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import _, { isEqual } from 'lodash';

interface SortableApi<T> {
  setData: (data: T[]) => void;
  handleSort: (columnName: string, columnType?: ColumnType) => void;
}

export type ColumnType = 'date' | 'number' | 'string';
type Direction = 'ascending' | 'descending';
interface SortableState<T> {
  column: string;
  data: T[];
  direction: Direction;
  columnType: ColumnType;
}

export interface WithSortableTable<T> {
  sortable: SortableApi<T> & SortableState<T>;
}

type KeysWhichValuesAre<I, T> = {
  [K in keyof I]: T extends I[K] ? K : never
}[keyof I]

export default function withSortableTable<
  DataType,
  P extends WithSortableTable<DataType>>(
  WrappedComponent: React.ComponentType<P>,
  sortableDataKeyName?: KeysWhichValuesAre<P, DataType[]>,
): React.ComponentType<Omit<P, keyof WithSortableTable<DataType>>> {
  class Component extends React.Component<Omit<P, keyof WithSortableTable<DataType>>, SortableState<DataType>> {
    static displayName = `withSortableTable(${WrappedComponent.displayName})`;

    constructor(props: Omit<P, keyof WithSortableTable<DataType>>) {
      super(props);
      this.state = {
        data: [],
        column: '',
        direction: 'ascending',
        columnType: 'string',
      };
    }

    componentDidMount(): void {
      if (sortableDataKeyName) {
        const { [sortableDataKeyName]: data } = this.props;
        this.setData(data as unknown as DataType[]);
      }
    }

    componentDidUpdate = (prevProps: P): void => {
      if (sortableDataKeyName) {
        const prevData = prevProps[sortableDataKeyName as string];
        const { [sortableDataKeyName]: data } = this.props;
        if (!isEqual(prevData, data)) {
          this.setData(data as unknown as DataType[]);
        }
      }
    }

    setData = (data: DataType[]): void => {
      const { column, columnType, direction } = this.state;
      const sortedData = this.applySort(data, column, columnType, direction);
      this.setState({ data: sortedData });
    }

    applySort = (data: DataType[], column: string, columnType: ColumnType, direction: Direction): DataType[] => {
      let sortedData: DataType[] = data;
      if (column) {
        switch (columnType) {
          case 'string':
            sortedData = _.sortBy(data, d => {
              try {
                return d[column].toLowerCase();
              } catch (e) {
                return d[column];
              }
            });
            break;
          case 'number':
            sortedData = _.sortBy(data, d => {
              try {
                return parseFloat(d[column]);
              } catch (e) {
                return d[column];
              }
            });
            break;
          case 'date':
            sortedData = _.sortBy(data, d => {
              try {
                if (d[column] !== undefined) {
                  return new Date(d[column]);
                }
                return undefined;
              } catch (e) {
                return d[column];
              }
            });
            break;
          default:
            sortedData = _.sortBy(data, [column]);
            break;
        }

        if (direction !== 'ascending') {
          sortedData.reverse();
        }
      }
      return sortedData;
    }

    handleSort = (columnName: string, columnType: ColumnType = 'string'): void => {
      const { direction, data } = this.state;
      const revertedDirection = direction === 'ascending' ? 'descending' : 'ascending';
      const sortedData = this.applySort(data, columnName, columnType, revertedDirection);
      this.setState({
        data: sortedData,
        column: columnName,
        direction: revertedDirection,
        columnType,
      });
    }

    render(): React.ReactNode {
      const { column, direction, data, columnType } = this.state;
      const sortable: WithSortableTable<DataType>['sortable'] = {
        column,
        data,
        columnType,
        direction,
        handleSort: this.handleSort,
        setData: this.setData,
      };
      return (
        <WrappedComponent
          sortable={sortable}
          {...(this.props as any)}
        />
      );
    }
  }

  return Component;
}
