import React, { ReactElement } from 'react';
import { DateTime } from 'luxon';
import { Card, Grid, GridColumn, GridRow, Popup } from 'semantic-ui-react';

import DimmerLoader from 'components/DimmerLoader';
import { TeamApi } from 'features/Api';
import Arc from './Arc';
import Header from './Header';
import Label from './Label';

import css from './styles.module.css';

export const GoalTrackingCard = (props: {
  isLoading: boolean;
  userGoal: TeamApi.Goals.GoalAttainment;
  previousGoalBtn?: ReactElement;
  nextGoalBtn?: ReactElement;
  goalDetailsModal?: ReactElement;
  parentCarouselColumns?: number;
  handleGoalDetailsModal?: Function;
  setGoalAttainmentInModal?: Function;
}): ReactElement => {
  const {
    isLoading,
    userGoal,
    parentCarouselColumns,
    previousGoalBtn,
    nextGoalBtn,
    handleGoalDetailsModal,
  } = props;
  let wide = false;
  if (parentCarouselColumns === 1) wide = true;

  const {
    value,
    target,
    goalMet,
    period: {
      start,
      end,
      unit,
      index: periodIndex,
    },
  } = userGoal;

  let timeRemainingRow = <></>;
  let arcElement = <Arc value={value} target={target} periodComplete={periodIndex > 0} goalMet={goalMet} />;

  const now = DateTime.now();
  const startDt = DateTime.fromISO(start);
  const endDt = DateTime.fromISO(end);
  const durationMs = startDt.diff(endDt).as('milliseconds');
  const progressMs = startDt.diff(now).as('milliseconds');
  const progressPct = Math.floor(100 * (progressMs / durationMs));
  const progressStr = `${progressPct}%`;

  const daysRemaining = Math.floor(endDt.diff(now).as('days'));

  let text;
  if (daysRemaining > 0) {
    text = `${progressStr} of the current ${unit} has elapsed with ${daysRemaining} days remaining`;
  } else {
    text = `The previous ${unit} has ended`;
  }
  if (wide) {
    timeRemainingRow = (
      <GridRow stretched columns={1}>
        <GridColumn>
          {text}
        </GridColumn>
      </GridRow>
    );
  } else if (periodIndex >= 0) {
    arcElement = (
      <Popup
        content={text}
        trigger={<div>{arcElement}</div>}
      />
    );
  }

  const handleDetailsModal = (): void => {
    if (typeof handleGoalDetailsModal === 'undefined') { return; }
    handleGoalDetailsModal(userGoal);
  };

  return (
    <Card className={css.GoalTrackingCard} fluid onClick={handleDetailsModal}>
      <DimmerLoader active={isLoading} />
      <Card.Content>
        <Grid>
          <GridRow stretched>
            <GridColumn>
              <Header
                userGoal={userGoal}
                showTimeRemainingPopup={!wide}
                previousGoalBtn={previousGoalBtn}
                nextGoalBtn={nextGoalBtn}
              />
            </GridColumn>
          </GridRow>
          <GridRow stretched columns={2}>
            <GridColumn>
              <Label target={target} value={value} />
            </GridColumn>
            <GridColumn>
              {arcElement}
            </GridColumn>
          </GridRow>
          {timeRemainingRow}
        </Grid>
      </Card.Content>
    </Card>
  );
};

export default GoalTrackingCard;
