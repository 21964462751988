import {
  AccountTableView,
} from 'App/Main/AccountHealthRoute/AccountHealthDash/components/ActiveAccountList/ActiveAccountList';
import { push } from 'connected-react-router';
import { AccountHealthInfo } from 'features/Account/accountTypes';
import { AccountApi } from 'features/Api';
import { useGetIdealAccountTimeline } from 'features/Api/Account/getIdealAccountTimeline';
import { healthStatusMap } from 'models/account';
import qs from 'query-string';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectOrganizationOpportunityStages } from 'selectors/organization';
import { Segment, Tab, DropdownItemProps, Icon, Popup, Header, Dropdown, TabPaneProps, Menu } from 'semantic-ui-react';
import { debounce, sortBy, clone } from 'lodash';
import { RouteComponentProps } from 'react-router-dom';

import api from 'lib/api';
import {
  getTrackedSelectedTeamMembers,
  getSelectedTeamId,
} from 'selectors/team';

import { DateTime } from 'luxon';
import AccountHealthFilterModal from './components/AccountHealthFilterModal';
import ActiveAccountList, { ActiveAccountListFilters } from './components/ActiveAccountList';

import css from './AccountHealthDash.module.css';
import IdealTimeline from '../components/IdealTimeline/IdealTimeline';

interface Props extends RouteComponentProps {
  navigate: Function;
}

const ACCOUNT_HEALTH_FILTER_KEY = 'accountHealthFilterKey';

const transformApiResponse: (
  newAccounts: AccountHealthInfo[],
  existingAccountTableViews: AccountTableView[],
  oldUserOptions: UserOptions,
) => {
  newAccountTableViews: AccountTableView[];
  newUserOptions: UserOptions;
} = (newAccounts, existingAccountTableViews, oldUserOptions) => {
  const newUserOptions = clone(oldUserOptions);
  const newAccountTableViews = clone(existingAccountTableViews);
  newAccounts.forEach(account => {
    const {
      id: accountId,
      name: accountName,
      status,
      ownerId,
      ownerName: owner,
      lastActivityDate,
      openOpportunityCount: opportunityCount,
      stageName,
      criticalMetrics,
      warningMetrics,
    } = account;

    if (!newUserOptions[ownerId]) {
      newUserOptions[ownerId] = { key: ownerId, value: ownerId, text: owner };
    }

    newAccountTableViews.push({
      accountId,
      accountName,
      status,
      ownerId,
      stageName: stageName || '',
      owner,
      lastActivityDate,
      opportunityCount,
      criticalMetrics,
      warningMetrics,
    });
  });
  const dupeFinder: { [accountId: number]: boolean } = {};
  const filteredNewAccounts = newAccountTableViews.filter(row => {
    const { accountId } = row;
    if (dupeFinder[accountId]) return false;
    dupeFinder[accountId] = true;
    return true;
  });
  return {
    newAccountTableViews: filteredNewAccounts,
    newUserOptions,
  };
};

const AccountHealthIdealTimeline: FC<{}> = () => {
  const { data: idealTimelineData, isFetching } = useGetIdealAccountTimeline({ });
  const opportunityStages = useSelector(selectOrganizationOpportunityStages);
  let isLoading = false;
  if (isFetching || !opportunityStages) isLoading = true;
  return (
    <Segment className={css.timelineSection}>
      <Header size="large">Ideal Health Timeline</Header>
      <IdealTimeline
        isLoading={isLoading}
        idealTimeline={idealTimelineData || []}
        opportunityStages={opportunityStages || []}
      />
    </Segment>
  );
};

const getInitialFilterState = (props: Props): ActiveAccountListFilters => {
  const { location: { search } } = props;
  const filterStr = localStorage.getItem(ACCOUNT_HEALTH_FILTER_KEY);
  const filters = filterStr ? JSON.parse(filterStr) : {};

  const urlParams = qs.parse(search);
  if (urlParams.owners) {
    const ownersArr: string[] = Array.isArray(urlParams.owners) ? urlParams.owners : [urlParams.owners];
    return {
      ...filters,
      ownerIds: ownersArr.map(o => parseInt(o, 10)),
    };
  }
  return filters;
};

interface UserOptions {
  [userId: number]: DropdownItemProps;
}

const AccountHealthDash: FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const teamId = useSelector(getSelectedTeamId) || 0;
  const teamMembers = useSelector(getTrackedSelectedTeamMembers);

  const [filters, setFilters] = useState(getInitialFilterState(props));
  const [page, setPage] = useState(1);

  const [accountList, setAccountList] = useState([] as DropdownItemProps[]);
  const { data: accountsData, isFetching } = AccountApi.getAllAccounts.useGetAccountHealthQuery({ teamId, page });

  const [activeAccountTableViews, setActiveAccounts] = useState([] as AccountTableView[]);
  const [inactiveAccountTableViews, setInactiveAccounts] = useState([] as AccountTableView[]);
  const [userOptions, setUserOptions] = useState({} as UserOptions);

  const [activePane, setActivePane] = useState('active'); // Default to 'active'

  const navigate = bindActionCreators((path: string) => push(path), dispatch);

  // reset the lists every time we change team
  useEffect(() => {
    setActiveAccounts([]);
    setInactiveAccounts([]);
  }, [teamId]);

  if (accountsData && !isFetching) {
    const { active, inactive } = accountsData;
    const {
      newAccountTableViews: newActiveAccountTableViews,
      newUserOptions: userOptionsAfterActiveAccounts,
    } = transformApiResponse(active, activeAccountTableViews, userOptions);

    const {
      newAccountTableViews: newInactiveAccountTableViews,
      newUserOptions,
    } = transformApiResponse(inactive, inactiveAccountTableViews, userOptionsAfterActiveAccounts);

    if (JSON.stringify(newActiveAccountTableViews) !== JSON.stringify(activeAccountTableViews)) {
      setActiveAccounts(newActiveAccountTableViews);
    }
    if (JSON.stringify(newInactiveAccountTableViews) !== JSON.stringify(inactiveAccountTableViews)) {
      setInactiveAccounts(newInactiveAccountTableViews);
    }
    if (JSON.stringify(newUserOptions) !== JSON.stringify(userOptions)) {
      setUserOptions(newUserOptions);
    }
  }

  const debouncedSearch = debounce(async search => {
    const resp = await api.searchAccounts({ search });
    const list = resp.accounts.map(a => ({ key: a.id, text: a.name, value: a.id }));
    setAccountList(list);
  }, 500);

  const handleFilterSave = (_, data): void => {
    const newFilters = {
      ...filters,
      ownerIds: data?.owners,
      statuses: data?.status,
    };
    localStorage.setItem(ACCOUNT_HEALTH_FILTER_KEY, JSON.stringify(newFilters));
    setFilters(() => newFilters);
  };

  const handleAccountSelect = (_, data): void => {
    const accountIdNav = data.value;
    navigate(`/app/account/${accountIdNav}/health`);
  };

  const handleSearch = (_, data): void => {
    const { searchQuery: searchText } = data;
    if (searchText) {
      debouncedSearch(searchText);
    }
  };

  const handleDownloadChart = (): void => {
    if (!accountsData || isFetching) return;
    const title = 'Account Health';
    const header: string[] = [];
    const data: any[][] = [];

    const now = DateTime.now().toFormat('yyyyLLdd');

    const accounts = activePane === 'active' ? accountsData.active : accountsData.inactive;

    if (accounts.length === 0) return;

    const cleanedData = accounts
      .filter(account => (account.openOpportunityCount > 0))
      .map(account => {
        const { status } = account;
        const statusObj = healthStatusMap[status];
        return {
          accountId: account.extId,
          accountName: account.name,

          opportunityName: account.opportunityName,
          opportunityOwner: account.ownerName,
          opportunityStageName: account.stageName,
          health: statusObj.text,

          criticalMetrics: account.criticalMetrics?.join(', ') || '',
          warningMetrics: account.warningMetrics?.join(', ') || '',
          healthyMetrics: account.healthyMetrics?.join(', ') || '',

          lastActivityDate: account.lastActivityDate,
          daysSinceLastActivity: account.timeSinceLastActivity,
          daysInStage: account.timeInStage,

          totalNumOfContacts: account.totalNumOfContacts,
          repResponseTimeMean: account.repResponseTimeMean,
          prospectResponseTimeMean: account.prospectResponseTimeMean,
          repFollowupTimeMean: account.repFollowupTimeMean,
          wordCountMean: account.wordCountMean,
          fleschKincaidGradeMean: account.fleschKincaidGradeMean,
          dailyActivityVelocity: account.dailyActivityVelocity,
          dailyEmailVelocity: account.dailyEmailVelocity,
          dailyOutboundVelocity: account.dailyOutboundVelocity,
          dailyInboundVelocity: account.dailyInboundVelocity,
          dailyCallVelocity: account.dailyCallVelocity,
          dailyMeetingVelocity: account.dailyMeetingVelocity,
          startingStage: account.startingStage,
          firstCloseDate: account.firstCloseDate,
          lastCloseDate: account.lastCloseDate,
        };
      });

    Object.keys(cleanedData[0]).forEach((key: string): void => {
      header.push(key);
    });

    cleanedData.forEach(rowData => {
      data.push(Object.values(rowData).map(d => (d ? `"${d}"` : '')));
    });

    const fileName = `${now}_${title}`;

    const rows = [header, ...data];
    const csvContent = `data:text/csv;charset=utf-8,${rows.map(e => e.join(',')).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const activeLabel = (
    <Menu.Item key="active">
      <Popup
        basic
        content="An account is “active” if it has activity from a BuyerSight tracked user within 30 days."
        trigger={(<span>Active</span>)}
      />
    </Menu.Item>
  );

  const panes: TabPaneProps['panes'] = [
    {
      menuItem: activeLabel,
      render: (): ReactElement => (
        <Tab.Pane loading={isFetching && !activeAccountTableViews.length} className={css.pane}>
          <ActiveAccountList
            accounts={activeAccountTableViews}
            filters={filters}
            stages={[]}
            teamMembers={teamMembers}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Inactive',
      render: (): ReactElement => (
        <Tab.Pane loading={isFetching && !inactiveAccountTableViews.length} className={css.pane}>
          <ActiveAccountList
            accounts={inactiveAccountTableViews}
            filters={filters}
            stages={[]}
            teamMembers={teamMembers}
            isLoadingMore={isFetching}
            onLoadMore={(): void => setPage(value => value + 1)}
          />
        </Tab.Pane>
      ),
    },
  ];

  const allDropdownUsers = sortBy(Object.values(userOptions), 'text');
  return (
    <div className={css.container}>
      <Segment>
        <div className={css.filterIcon}>
          <AccountHealthFilterModal
            filters={filters}
            onChange={handleFilterSave}
            ownerOptions={allDropdownUsers}
          />
          <div className="link brand">
            <Popup
              inverted
              basic
              size="mini"
              position="bottom center"
              style={{ backgroundColor: '#5A5B5D' }}
              content="Download"
              trigger={(
                <Icon
                  name="download"
                  title="Download as CSV"
                  onClick={handleDownloadChart}
                />
              )}
            />
          </div>
        </div>
        <div className={css.searchBar}>
          <Dropdown
            deburr
            icon="search"
            onSearchChange={handleSearch}
            onChange={handleAccountSelect}
            options={accountList}
            placeholder="Search..."
            search
            selection
          />
        </div>
        <div className={css.clear}>
          <Tab
            panes={panes}
            menu={{ compact: true, pointing: true, secondary: true }}
            activeIndex={activePane === 'active' ? 0 : 1}
            onTabChange={(_, data): void => setActivePane(data.activeIndex === 0 ? 'active' : 'inactive')}
          />
        </div>
      </Segment>
      <AccountHealthIdealTimeline />
    </div>
  );
};

export default AccountHealthDash;
