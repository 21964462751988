import React, { useState, useEffect, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Menu, Popup, Icon } from 'semantic-ui-react';

import { formatDate } from 'lib/util';
import { Notification } from 'models/alert';
import { fetchSidebarAlertNotifications, updateAlertNotification } from 'actions/alert-actions';
import AlertLanguageProcessor from 'lib/AlertLanguageProcessor';

import css from './AlertNotificationSidebarItem.module.css';

interface Props {
  alertData: Notification;
  limit: number;
}

type State = any;

const AlertNotificationSidebarItem: React.FC<Props> = ({ alertData, limit }): React.ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoadingResolve, setIsLoadingResolve] = useState(false);
  const [isLoadingDismiss, setIsLoadingDismiss] = useState(false);
  const [hover, setHover] = useState(false);

  const errorMessage = useSelector((state: State) => state.alert.notificationError);

  const handleToast = (message: string): void => {
    toast(message, {
      position: 'top-center',
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const handleResolve = async (e: SyntheticEvent): Promise<void> => {
    e.stopPropagation();
    setIsLoadingResolve(true);
    await dispatch(updateAlertNotification(alertData.id, {
      seen: true,
      dismissed: false,
      resolved: true,
    }));
    handleToast('Notification resolved');
    setIsLoadingResolve(false);
    await dispatch(fetchSidebarAlertNotifications({ limit }));
  };

  const handleDismiss = async (e: SyntheticEvent): Promise<void> => {
    e.stopPropagation();
    setIsLoadingDismiss(true);
    await dispatch(updateAlertNotification(alertData.id, {
      seen: true,
      dismissed: true,
      resolved: false,
    }));
    handleToast('Notification dismissed');
    setIsLoadingDismiss(false);
    await dispatch(fetchSidebarAlertNotifications({ limit }));
  };

  const handleRedirectToRule = (): void => {
    history.push(`/app/alerts?ruleId=${alertData.alertRuleId}`);
  };

  useEffect(() => {
    if (errorMessage) {
      handleToast(errorMessage);
    }
  }, [errorMessage]);


  const menuItemClassName = `
    ${css.menuItem} ${!alertData.seen && css.unseen} ${css.alert}
  `;

  const notificationToProcess = {
    id: alertData.id,
    userName: alertData.userName,
    metric: alertData.metric,
    thresholdValue: alertData.thresholdValue,
    thresholdOperator: alertData.thresholdOperator,
    recordedValue: alertData.recordedValue,
  };

  return (
    <Menu.Item onClick={handleRedirectToRule} className={menuItemClassName}>
      <div
        onMouseEnter={(): void => setHover(true)}
        onMouseLeave={(): void => setHover(false)}
        className={css.line}
      >
        <div className={css.leftItems}>
          <div className={css.detail}>
            <div>
              {AlertLanguageProcessor.getAlertNotificationStatement(notificationToProcess)}
            </div>
            <div className={css.date}>
              {formatDate(alertData.createdAt)}
            </div>
          </div>
        </div>
        {hover && (
          <div className={css.actions}>
            <Popup
              content="Resolve"
              hoverable
              position="top right"
              trigger={(
                <Icon
                  className={css.actionIcon}
                  color="green"
                  loading={isLoadingResolve}
                  onClick={handleResolve}
                  name="check"
                  size="large"
                />
              )}
            />
            <Popup
              content="Dismiss"
              hoverable
              position="top right"
              trigger={(
                <Icon
                  className={css.actionIcon}
                  color="red"
                  loading={isLoadingDismiss}
                  name="delete"
                  onClick={handleDismiss}
                  size="large"
                />
              )}
            />
          </div>
        )}
      </div>
    </Menu.Item>
  );
};

export default AlertNotificationSidebarItem;
