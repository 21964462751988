import {
  Benchmark,
  Classification,
  Datum,
  SortSettings,
  Value,
} from 'components/TeamComparisonChart/TeamComparisonChart';
import { createContext, useContext } from 'react';

export interface TeamComparisonChartContextProps {
  legend: Classification[];
  data: Datum[];
  higherIsBetter: boolean;
  benchmark?: Benchmark;
  sorting: {
    sortSettings: SortSettings;
    setSortSettings: (s: SortSettings) => void;
  };
  maxValue: Value;

  // categoryFiltering: {
  //   shownCategories: Classification[];
  //   showAllCategories: () => void;
  //   hideAllCategories: () => void;
  //   showCategory: (c: Classification) => void;
  // };
}

const TeamComparisonChartContext = createContext<TeamComparisonChartContextProps | undefined>(undefined);

export const TeamComparisonChartProvider = TeamComparisonChartContext.Provider;

export const useTeamComparisonChartContext = (): TeamComparisonChartContextProps => {
  const context = useContext(TeamComparisonChartContext);
  if (!context) {
    throw new Error(
      "You must've used a TeamComparisonChart subcomponent outside of its parent",
    );
  }
  return context;
};
