import React, { ReactElement, FC } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Header } from 'semantic-ui-react';

import DatePicker from 'features/DatePicker/DatePicker';
import { setAppDateSelection, selectAppDateSelection } from 'features/App/appSlice';
import { metricnameToActivityStatnameMap } from 'features/Api/Activity';
import { allMetricsDisplayInfo, MetricName } from 'models/metric';

import css from './MetricOverTimeChartModal.module.css';
import TeamComparisonChartHistoric from '../../../../Dashboard/components/Comparison/components/TeamComparisonChartHistoric';

const MetricOverTimeChartModal: FC<{
    open: boolean;
    onClose;
    modalMetric: MetricName;
    teamId: number;
    userId?: number;
  }> = ({
    open: isModalOpen,
    onClose,
    modalMetric,
    teamId,
    userId,
  }): ReactElement => {
    const timePeriod = useSelector(selectAppDateSelection);

    const mappedStatname = metricnameToActivityStatnameMap[modalMetric];
    if (!mappedStatname) return (<></>);

    const title = allMetricsDisplayInfo[modalMetric]?.title || modalMetric;

    return (
      <Modal
        open={isModalOpen}
        onClose={onClose}
      >
        <Header>
          {title}
          <div className={css.datePicker}>
            <DatePicker timePeriod={timePeriod} reduxAction={setAppDateSelection} />
          </div>
        </Header>

        <Modal.Content>
          <TeamComparisonChartHistoric
            modalMetric={mappedStatname}
            timePeriod={timePeriod}
            teamId={teamId}
            userId={userId}
          />
        </Modal.Content>
      </Modal>
    );
  };

export default MetricOverTimeChartModal;
