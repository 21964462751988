import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FaCompressAlt, FaExpandAlt } from 'react-icons/fa';
import { Table, Grid, Popup, DropdownItemProps } from 'semantic-ui-react';
import { Duration } from 'luxon';

import allActions, { bindCombinedActions } from 'actions';
import api from 'lib/api';
import { getTrackedSelectedTeamMembersForDropdown } from 'selectors/team';
import { getPipelineSettings } from 'selectors/organization';

import AccessControl from 'components/AccessControl';
import DashboardTile from 'components/DashboardTile';

import PipelineFilterModal from '../PipelineFilterModal';
import css from './StageTable.module.css';


interface StageTableFilterOptions {
  ownerIds?: number[];
  opportunityTypes?: string[];
  lookback?: number;
  custom?: { [key: string]: Array<string|number> };
}

interface Props {
  actions: any;
  opportunityTypes: string[];
  pipelineSettings: any;
  selectedTeamId: number;
  teamMembersForDropdown: DropdownItemProps[];
}

interface State {
  isLoading: boolean;
  isMinimized: boolean;
  filters: StageTableFilterOptions;
  stageTableData: any;
}

const STAGE_TABLE_FILTER_KEY = 'stageTableFilters';

class StageTable extends Component<Props, State> {
  _isMounted = false;

  constructor(props) {
    super(props);

    const filterStr = localStorage.getItem(STAGE_TABLE_FILTER_KEY);
    const filters = filterStr ? JSON.parse(filterStr) : {};

    this.state = {
      isLoading: true,
      isMinimized: true,
      filters,
      stageTableData: [],
    };
  }

  componentDidMount(): void {
    this._isMounted = true;
    this.fetchPipelineData();
  }

  componentDidUpdate(prevProps, prevState: State): void {
    const { selectedTeamId } = this.props;
    const { filters } = this.state;
    const teamDiff = selectedTeamId !== prevProps.selectedTeamId;
    const filterDiff = filters !== prevState.filters;
    if (teamDiff || filterDiff) {
      this.fetchPipelineData();
    }
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  fetchPipelineData = async (): Promise<void> => {
    const { selectedTeamId } = this.props;
    const { filters } = this.state;
    const opts: any = { teamId: selectedTeamId, ...filters };
    if (filters.custom) {
      opts.custom = JSON.stringify(filters.custom);
    }
    this.setState({ isLoading: true });
    const resp = await api.getPipelineStages(opts);
    if (!resp.status) {
      // eslint-disable-next-line
      console.error('Error fetching pipeline stages');
    }

    if (this._isMounted) {
      this.setState({
        stageTableData: resp.data,
        isLoading: false,
      });
    }
  };

  handleExpand = (): void => {
    this.setState((prevState: State) => ({ isMinimized: !prevState.isMinimized }));
  };

  handleFilterChange = (_, data): void => {
    this.setState(prevState => {
      const newFilters = {
        ...prevState.filters,
        ownerIds: data?.owners,
        opportunityTypes: data?.types,
        lookback: data?.lookback,
        custom: data?.custom,
      };
      Object.keys(newFilters).forEach(key => newFilters[key] === undefined && delete newFilters[key]);
      localStorage.setItem(STAGE_TABLE_FILTER_KEY, JSON.stringify(newFilters));
      return { filters: newFilters };
    });
  };

  render(): React.ReactElement {
    const { opportunityTypes, pipelineSettings, teamMembersForDropdown } = this.props;
    const { filters, isLoading, isMinimized, stageTableData } = this.state;

    const tooltipStyle = { backgroundColor: '#5A5B5D' };

    const lookbackOptions: DropdownItemProps[] = [
      { key: '30 Days', text: 'Previous 30 Days', value: 30 },
      { key: '60 Days', text: 'Previous 60 Days', value: 60 },
      { key: '90 Days', text: 'Previous 90 Days', value: 90 },
      { key: '180 Days', text: 'Previous 180 Days', value: 180 },
    ];

    const stageTableRows: React.ReactElement = stageTableData
      .filter(row => (isMinimized && row.activityCount > 0) || !isMinimized)
      .map(row => {
        const conversionRate = row.numWonOpportunities / row.numOpportunities;
        return (
          <Table.Row key={row.stageName}>
            <Table.Cell className={css.stageNameData}>{row.masterLabel}</Table.Cell>
            <Table.Cell className={css.displayedData} textAlign="center">
              {row.activityCount?.toFixed(1) || 0}
            </Table.Cell>
            <Table.Cell className={css.displayedData} textAlign="center">
              {row.contactCount?.toFixed(1) || 0}
            </Table.Cell>
            <Table.Cell className={css.displayedData}>
              {row.timeInStage ? `${Duration.fromObject(row.timeInStage).days} days` : '--'}
            </Table.Cell>
            <Table.Cell className={css.displayedData} textAlign="center">
              {conversionRate ? `${(conversionRate * 100).toFixed(1)}%` : '--'}
            </Table.Cell>
            <Table.Cell className={css.displayedData} textAlign="center">
              {row.numOpportunities}
            </Table.Cell>
          </Table.Row>
        );
      });
    const opportunityTypeOptions = opportunityTypes.map(o => ({ key: o, text: o, value: o }));
    const cornerIcon = isMinimized
      ? (<FaExpandAlt onClick={this.handleExpand} />)
      : (<FaCompressAlt onClick={this.handleExpand} />);
    return (
      <DashboardTile isLoading={isLoading} isMinimized={isMinimized} onClose={this.handleExpand} size="large">
        <Grid divided="vertically">
          <Grid.Row columns={2}>
            <Grid.Column>
              <p style={{ fontWeight: 'bolder' }}>
                {`Stages (opportunities closed within ${filters.lookback || 90} days)`}
              </p>
            </Grid.Column>
            <Grid.Column>
              <div className={css.toolkitContainer}>
                <AccessControl accessingTo="manager">
                  <PipelineFilterModal
                    filters={filters}
                    onChange={this.handleFilterChange}
                    lookbackOptions={lookbackOptions}
                    opportunityTypeOptions={opportunityTypeOptions}
                    ownerOptions={teamMembersForDropdown}
                    customFilters={pipelineSettings?.customFilters}
                  />
                </AccessControl>
                <div className="link brand">
                  <Popup
                    inverted
                    basic
                    size="mini"
                    position="bottom center"
                    style={tooltipStyle}
                    content={isMinimized ? 'Expand' : 'Collapse'}
                    trigger={cornerIcon}
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* Displayed Content */}
        <Table verticalAlign="middle">
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>Stage Name</Table.HeaderCell>
              <Popup
                basic
                content="Average number of activities logged in the stage"
                position="top left"
                trigger={(<Table.HeaderCell textAlign="center">Avg Activities</Table.HeaderCell>)}
              />
              <Popup
                basic
                content="Average number of contacts associated to any activity in the stage"
                position="top left"
                trigger={(<Table.HeaderCell textAlign="center">Avg Contacts</Table.HeaderCell>)}
              />
              <Popup
                basic
                content="Average number of days each opportunity spent in the stage"
                position="top left"
                trigger={(<Table.HeaderCell>Time in Stage</Table.HeaderCell>)}
              />
              <Popup
                basic
                content="Percentage of closed opportunities that touched this stage and were eventually won."
                position="top left"
                trigger={(<Table.HeaderCell textAlign="center">Conversion Rate</Table.HeaderCell>)}
              />
              <Popup
                basic
                content="The number of closed opportunities that touched this stage."
                position="top left"
                trigger={(<Table.HeaderCell textAlign="center">Num Opportunities Touched</Table.HeaderCell>)}
              />
            </Table.Row>
          </Table.Header>
          <Table.Body>{stageTableRows}</Table.Body>
          <Table.Footer fullWidth style={{ display: isMinimized ? '' : 'none' }}>
            <Table.Row>
              <Table.HeaderCell colSpan="6" textAlign="center">
                <div className="link" onClick={this.handleExpand}>
                  View all Stages
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </DashboardTile>
    );
  }
}

export default connect(
  (state: any) => ({
    opportunityTypes: state.organization.opportunityTypes,
    pipelineSettings: getPipelineSettings(state),
    selectedTeamId: state.team.selectedTeamId,
    teamMembersForDropdown: getTrackedSelectedTeamMembersForDropdown(state),
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(StageTable);
