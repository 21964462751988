import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import qs from 'query-string';
import { Grid, Ref, Segment, Sticky, Container } from 'semantic-ui-react';
import { AnimatePresence, motion } from 'framer-motion';

import { RootState } from 'store';
import allActions, { bindCombinedActions } from 'actions';
import { getSelectedActivity } from 'selectors/activity';
import { getTrackedUsers } from 'selectors/user';

import ActivityDetails from 'components/ActivityDetails';
import ActivityHistory from 'components/ActivityHistory';
import ActivitySearchBar, { DisplayOptions } from 'components/ActivitySearchBar';
import ActivityInfoBar from 'components/ActivityInfoBar';
import { hasPermission, Permission } from 'components/AccessControl';

import { TimePeriod } from 'features/DatePicker/datePickerTypes';
import DatePicker from 'features/DatePicker/DatePicker';
import {
  getIsoDatesFromTimePeriod,
  getStartAndEndDatesFromSingleDate,
  getTimePeriodFromLuxonDates,
} from 'features/DatePicker/datePickerFunctions';
import { selectAppDateSelection } from 'features/App/appSlice';

import { Activity, ActivityAccount, ActivityContact, Tag } from 'models/activity';
import { OpportunityStage } from 'models/opportunity';
import { User } from 'models/user';

interface Props {
  actions: any;
  activities: Array<Activity>;
  activityAccounts: Array<ActivityAccount>;
  activityContacts: Array<ActivityContact>;
  activityTags: Array<Tag>;
  isLoadingActivities: boolean;
  isLoadingUserInfo: boolean;
  isLoadingTrackedUsers: boolean;
  isSyncing: boolean;
  location: any;
  match: { params: { userId: string } };
  opportunityStages: Array<OpportunityStage>;
  permission: Permission;
  selectedActivity: any;
  selectedId?: number;
  trackedUsers: Array<User>;
  userInfo: any;
  userTimePeriod: TimePeriod;
}

interface State {
  displayOptions: DisplayOptions;
  timePeriod: TimePeriod;
}

class UserActivity extends Component<Props, State> {
  contextRef = React.createRef();

  constructor(props) {
    super(props);

    const defaultDisplayOptions: DisplayOptions = {
      showEmail: true,
      showCall: true,
      showEvent: true,
      showTask: true,
      showSms: true,
      showLinkedIn: true,
      showAutomatic: true,
      searchTerm: '',
      accountWhitelist: [],
      contactWhitelist: [],
      opportunityStageWhitelist: [],
      tagWhitelist: [],
      filterEmailByDirection: 'both',
      emailPredictionFilters: {
        automatic: true,
        followup: false,
        pricing: false,
        prospecting: false,
        all: true,
      },
    };

    let { userTimePeriod } = this.props;

    const { location: { search } } = props;
    const params = qs.parse(search);

    if (params.date) {
      const { start: urlStart, end: urlEnd } = getStartAndEndDatesFromSingleDate(params.date.toString());
      userTimePeriod = getTimePeriodFromLuxonDates(urlStart, urlEnd);
    }

    this.state = {
      displayOptions: Object.assign(defaultDisplayOptions, props.displayOptions),
      timePeriod: userTimePeriod,
    };
  }

  componentDidMount(): void {
    const { actions, userInfo, location } = this.props;
    const { timePeriod } = this.state;
    const { start: startDate, end: endDate } = getIsoDatesFromTimePeriod(timePeriod);

    const { search } = location;
    const params = qs.parse(search);
    if (params.activity) {
      const selectedId = parseInt(params.activity as string, 10);
      actions.activity.selectActivity(selectedId);
    }

    const fetchInfo = async (): Promise<void> => {
      if (userInfo.isTracked) {
        actions.activity.searchActivities({
          ownerId: userInfo.id,
          startDate,
          endDate,
          getTags: true,
          getAccounts: true,
          getContacts: true,
        }, false);
      }
    };
    fetchInfo();
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const { actions, selectedId, selectedActivity, isLoadingActivities, userInfo, userTimePeriod } = this.props;
    const { timePeriod } = this.state;
    const { start: startDate, end: endDate } = getIsoDatesFromTimePeriod(timePeriod);
    if (prevProps.isLoadingActivities && !isLoadingActivities) {
      if (selectedId && selectedActivity && selectedActivity?.id !== prevProps.selectedActivity?.id) {
        this.scrollTo(`activity-${selectedActivity.id}`);
      }
    }
    if (prevProps.userInfo.id !== userInfo.id) {
      if (userInfo.isTracked) {
        actions.activity.searchActivities({
          ownerId: userInfo.id,
          startDate,
          endDate,
          getTags: true,
          getAccounts: true,
          getContacts: true,
        });
      }
    } else if (prevState.timePeriod !== timePeriod) {
      actions.activity.searchActivities({
        ownerId: userInfo.id,
        startDate,
        endDate,
        getTags: true,
        getAccounts: true,
        getContacts: true,
      }, false);
    } else if (prevProps.userTimePeriod !== userTimePeriod) {
      const { start: propsStartDate, end: propsEndDate } = getIsoDatesFromTimePeriod(userTimePeriod);
      actions.activity.searchActivities({
        ownerId: userInfo.id,
        startDate: propsStartDate,
        endDate: propsEndDate,
        getTags: true,
        getAccounts: true,
        getContacts: true,
      }, false);
      this.setTimePeriod(userTimePeriod);
    }
  }

  handleSearch = (newOptions: any): void => {
    const { displayOptions } = this.state;
    this.setState({ displayOptions: { ...displayOptions, ...newOptions } });
  };

  setTimePeriod = (timePeriod: TimePeriod): void => {
    this.setState({ timePeriod });
  }

  scrollTo = (id: string): void => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView();
    }
  };

  filterHistory = (history: Array<any>, displayOptions: DisplayOptions): Array<any> => {
    const {
      accountWhitelist,
      contactWhitelist,
      opportunityStageWhitelist,
      showEmail,
      showCall,
      showTask,
      showEvent,
      showSms,
      showLinkedIn,
      showAutomatic,
      searchTerm,
      tagWhitelist,
      filterEmailByDirection,
      emailPredictionFilters,
    } = displayOptions;
    const lowerSearchTerm = searchTerm.toLowerCase();
    return history.filter((activity): boolean => {
      switch (activity.icon) {
        case 'mail':
          if (!showEmail) return false;
          break;
        case 'call':
          if (!showCall) return false;
          break;
        case 'tasks':
          if (!showTask) return false;
          break;
        case 'calendar':
          if (!showEvent) return false;
          break;
        case 'sms':
          if (!showSms) return false;
          break;
        case 'linkedin':
          if (!showLinkedIn) return false;
          break;
        default:
          return false;
      }
      if (!showAutomatic && activity.isAutomatic) return false;
      if (lowerSearchTerm && (
        !activity.description
        || !activity.description.toLowerCase().includes(lowerSearchTerm))
      ) {
        return false;
      }
      if (accountWhitelist?.length
        && !accountWhitelist.includes(activity.accountId)
        && !accountWhitelist.filter(a => activity.groupAccountIds?.includes(a)).length
      ) {
        return false;
      }
      if (contactWhitelist?.length
        && !contactWhitelist.includes(activity.contactId)
        && !contactWhitelist.filter(c => activity.additionalContactIds?.includes(c)).length
        && !contactWhitelist.filter(c => activity.groupContactIds?.includes(c)).length
      ) {
        return false;
      }
      if (opportunityStageWhitelist?.length) {
        const matchedStages = activity.opportunityStages.filter(value => opportunityStageWhitelist.includes(value));
        if (matchedStages.length) {
          return true;
        }
        return false;
      }
      if (tagWhitelist?.length) {
        if (activity.tags) {
          const matchedTags = tagWhitelist.filter(value => activity.tags.includes(value));
          if (matchedTags.length) {
            return true;
          }
        }
        return false;
      }

      const filterByPrediction = emailPredictionFilters.all
        || (emailPredictionFilters.automatic && activity.isAutomatic)
        || (emailPredictionFilters.followup && activity.isFollowup)
        || (emailPredictionFilters.pricing && activity.isPricing)
        || (emailPredictionFilters.prospecting && activity.isProspecting);

      if (activity.icon === 'mail') {
        const filterByMatchingDirection = filterEmailByDirection === 'both' || activity.direction === filterEmailByDirection;
        return filterByMatchingDirection && filterByPrediction;
      }

      return filterByPrediction;
    });
  };

  selectActivity = (activity): void => {
    const { actions, selectedActivity } = this.props;
    if (selectedActivity?.id === activity?.id) {
      actions.activity.selectActivity(null);
    } else {
      actions.activity.selectActivity(activity.id);
    }
  };

  renderUserActivity = (): React.ReactNode => {
    const { displayOptions } = this.state;
    const { isLoadingActivities, isSyncing, activities, selectedId } = this.props;
    const { timePeriod } = this.state;
    const { start, end } = getIsoDatesFromTimePeriod(timePeriod);
    const loadingMessage = isSyncing ? 'Syncing activity data from Salesforce...' : undefined;
    return (
      <Segment>
        <ActivityHistory
          activityHistory={this.filterHistory(activities, displayOptions)}
          isLoading={isLoadingActivities || isSyncing}
          endDate={moment(end).endOf('day')}
          loadingMessage={loadingMessage}
          onClick={this.selectActivity}
          selectedId={selectedId}
          startDate={moment(start)}
          view="account"
        />
      </Segment>
    );
  };

  renderActivityDetails = (): React.ReactNode => {
    const {
      actions,
      selectedId,
      selectedActivity,
      userInfo,
      isLoadingUserInfo,
    } = this.props;
    const { timePeriod } = this.state;
    const { start: startDate, end: endDate } = getIsoDatesFromTimePeriod(timePeriod);
    if (selectedActivity && userInfo && !isLoadingUserInfo && selectedActivity.ownerId !== userInfo.id) {
      actions.activity.unselectActivity();
      return null;
    }
    if (!selectedId || !selectedActivity) return null;

    const handleUpdateOpportunity = async (): Promise<any> => {
      await actions.activity.searchActivities({
        ownerId: userInfo.id,
        startDate,
        endDate,
        getTags: true,
        getAccounts: true,
        noCache: true,
      }, false);
    };

    const onTagAdd = async (activityId: string, tag: string): Promise<any> => {
      const response = await actions.activity.addTag(activityId, tag);
      if (response.status) {
        await actions.activity.searchActivities({
          ownerId: userInfo.id,
          startDate,
          endDate,
          getTags: true,
          getAccounts: true,
          noCache: true,
        }, false);
      }
      return response;
    };

    const onTagRemove = async (activityId: string, tag: string): Promise<any> => {
      const response = await actions.activity.removeTag(activityId, tag);
      if (response.status) {
        actions.activity.searchActivities({
          ownerId: userInfo.id,
          startDate,
          endDate,
          getTags: true,
          getAccounts: true,
          noCache: true,
        }, false);
      }
      return response;
    };

    return (
      <Container>
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.2 }}
          exit={{ scale: 0 }}
        >
          <ActivityDetails
            activity={selectedActivity}
            ownerId={userInfo.id}
            onTagAdd={onTagAdd}
            onTagRemove={onTagRemove}
            onUpdateOpportunity={handleUpdateOpportunity}
          />
        </motion.div>
      </Container>
    );
  };

  render(): React.ReactNode {
    const { displayOptions } = this.state;
    const {
      isLoadingActivities,
      opportunityStages,
      activities,
      activityAccounts,
      activityContacts,
      activityTags,
      permission,
      userInfo,
    } = this.props;
    const { timePeriod } = this.state;
    const manageLink = hasPermission(permission, 'admin')
      ? (<div><Link to="/app/settings/teams">Managed Tracked Users</Link></div>) : null;
    const userActivity = userInfo.isTracked ? this.renderUserActivity() : (
      <Segment>
        This user is not tracked.
        {manageLink}
      </Segment>
    );

    const datePicker = (<DatePicker fluid callback={this.setTimePeriod} timePeriod={timePeriod} />);

    return (
      <>
        <ActivitySearchBar
          accounts={activityAccounts}
          contacts={activityContacts}
          datePicker={datePicker}
          displayOptions={displayOptions}
          isLoadingTags={isLoadingActivities}
          onChange={this.handleSearch}
          opportunityStages={opportunityStages}
          tags={activityTags}
        />
        <ActivityInfoBar
          isLoading={isLoadingActivities}
          accounts={activityAccounts}
          contacts={activityContacts}
          activities={this.filterHistory(activities, displayOptions)}
        />
        <Ref innerRef={this.contextRef}>
          <Grid columns={2}>
            <Grid.Column>
              {userActivity}
            </Grid.Column>
            <Grid.Column>
              <Sticky context={this.contextRef} offset={10}>
                <AnimatePresence initial={false}>
                  {this.renderActivityDetails()}
                </AnimatePresence>
              </Sticky>
            </Grid.Column>
          </Grid>
        </Ref>
      </>
    );
  }
}

export default connect(
  (state: RootState): Partial<Props> => ({
    isLoadingActivities: state.activity.isLoadingActivities,
    selectedId: state.activity.selectedId,
    selectedActivity: getSelectedActivity(state),
    activities: state.activity.activities,
    activityAccounts: state.activity.activityAccounts,
    activityContacts: state.activity.activityContacts,
    activityTags: state.activity.activityTags,
    isLoadingUserInfo: state.user.isLoadingUserInfo,
    userInfo: state.user.userInfo,
    isLoadingTrackedUsers: state.user.isLoadingUsers,
    opportunityStages: state.organization.opportunityStages,
    trackedUsers: getTrackedUsers(state),
    isSyncing: state.user.isSyncing,
    permission: state.app.permission,
    userTimePeriod: selectAppDateSelection(state),
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(UserActivity);
