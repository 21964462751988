import React from 'react';

import UserClockLight from './CustomIcons/UserClockLight';
import EnvelopeOpenTextLight from './CustomIcons/EnvelopeOpenTextLight';
import MailBulkLight from './CustomIcons/MailBulkLight';
import RunningLight from './CustomIcons/RunningLight';
import StopwatchLight from './CustomIcons/StopwatchLight';

import css from './BSCustomIcon.module.css';

export type BSCustomIconName = 'user-clock' | 'envelope-open-text' | 'mail-bulk' | 'running' | 'stop-watch'
export const bsCustomIconNames = ['user-clock', 'envelope-open-text', 'mail-bulk', 'running', 'stop-watch'];

const IconToNameMap: Record<BSCustomIconName, any> = {
  'user-clock': UserClockLight,
  'envelope-open-text': EnvelopeOpenTextLight,
  'mail-bulk': MailBulkLight,
  running: RunningLight,
  'stop-watch': StopwatchLight,
};

interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: BSCustomIconName;
  className?: string;
}

const BSCustomIcon: React.FC<IconProps> = ({ name, className, ...rest }) => {
  let Icon = IconToNameMap[name];
  if (Icon === undefined) {
    Icon = (): React.ReactNode => <i className={`${css.icon} icon`} />;
    // eslint-disable-next-line
    console.error(`Custom BuyerSight icon: ${name} was not found.`);
  }
  // eslint-disable-next-line
  return <Icon className={`${className} ${css.icon}`} width="21px" height="21px" {...rest} />;
};

export default BSCustomIcon;
