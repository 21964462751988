import React, { useState, SyntheticEvent, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import moment from 'moment';
import Calendar from 'react-calendar';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Table, Icon, Modal, Button, Popup, Menu, Header, Dropdown } from 'semantic-ui-react';

import { deleteAlertRule, pauseAlertRule, batchUpdateAllNotificationsInAlertRule, fetchSidebarAlertNotifications } from 'actions/alert-actions';
import useClickAwayListener from 'hooks/useClickAwayListener';
import { formatDate } from 'lib/util';
import { RulesNotifications } from 'models/alert';
import AccessControl from 'components/AccessControl';

import RulesRowNotificationItem from './RulesRowNotificationItem';

import css from './RulesRow.module.css';

const dateOptions = [
  { text: '1 day', value: 1, key: 1 },
  { text: '1 week', value: 2, key: 2 },
  { text: '2 weeks', value: 3, key: 3 },
  { text: '1 month', value: 4, key: 4 },
  { text: 'Custom', value: 5, key: 5 },
];

interface ModalData {
  open: boolean;
  action: Function;
  content: string;
}

interface RulesRowProps {
  activeAlertRuleIndex: number | null;
  alertRule: RulesNotifications;
  highlighted: boolean;
  onHover: Function;
  onOpen: Function;
  rowHoverIndex: number | null;
}

const RulesRow: React.FC<RulesRowProps> = ({
  activeAlertRuleIndex,
  alertRule,
  highlighted,
  onHover,
  onOpen,
  rowHoverIndex,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref: any = useRef();

  const [notificationHoveredIndex, setNotificationHoveredIndex] = useState<number | null>(null);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingResolveAll, setIsLoadingResolveAll] = useState(false);
  const [isLoadingDismissAll, setIsLoadingDismissAll] = useState(false);

  const [isPauseMenuOpen, setIsPauseMenuOpen] = useState(false);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [confirmModal, setConfirmModal] = useState<ModalData>({ open: false, action: () => {}, content: '' });
  const [showPauseCalendar, setShowPauseCalendar] = useState(false);
  const [pauseDate, setPauseDate] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);

  useClickAwayListener(ref, () => setIsPauseMenuOpen(false));

  const {
    id,
    description,
    isPaused,
    mostRecentNotification,
    notifications,
    notificationCount,
    paused,
  } = alertRule;

  const chevronIcon: any = `chevron ${activeAlertRuleIndex === id ? 'down' : 'right'}`;

  const handleToast = (message: string): void => {
    toast(message, {
      position: 'top-center',
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const handleHoverNotification = (notificationId: number | null): void => setNotificationHoveredIndex(notificationId);

  const handleEdit = (e: SyntheticEvent): void => {
    e.stopPropagation();
    history.push(`/app/alert/${id}`);
  };

  const handleDelete = async (e: SyntheticEvent): Promise<void> => {
    e.stopPropagation();
    setIsLoadingDelete(true);
    await dispatch(deleteAlertRule(id));
    handleToast('Alert rule deleted');
    setIsLoadingDelete(false);
  };

  const handleCloseDateModal = (): void => {
    setIsDateModalOpen(false);
  };

  const handleCloseConfirmModal = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setConfirmModal({ open: false, content: '', action: () => {} });
  };

  const handlePauseAlertRule = (pause: boolean): void => {
    dispatch(pauseAlertRule(id, pause ? pauseDate : null));
    setIsDateModalOpen(false);
    setShowPauseCalendar(false);
    setPauseDate(new Date());
  };

  const handleTogglePauseMenu = (e: SyntheticEvent): void => {
    e.stopPropagation();
    setIsPauseMenuOpen(!isPauseMenuOpen);
  };

  const handleChange = (_event: SyntheticEvent, result): void => {
    switch (result.value) {
      case 1:
        setPauseDate(moment().add(1, 'day').toDate());
        break;
      case 2:
        setPauseDate(moment().add(1, 'week').toDate());
        break;
      case 3:
        setPauseDate(moment().add(2, 'week').toDate());
        break;
      case 4:
        setPauseDate(moment().add(1, 'month').toDate());
        break;
      case 5:
        setShowPauseCalendar(true);
        return;
      default:
        break;
    }
    setShowPauseCalendar(false);
  };

  const handleCalendarChange = (value): void => {
    setPauseDate(value);
  };

  const handleResolveBatch = async (): Promise<void> => {
    setIsLoadingResolveAll(true);
    await dispatch(batchUpdateAllNotificationsInAlertRule(id, 'resolved'));
    handleToast('Notifications resolved');
    setIsLoadingResolveAll(false);
    onOpen(null);
    dispatch(fetchSidebarAlertNotifications({ limit: 20, offset: 0 }));
    handleCloseConfirmModal();
  };

  const handleDismissBatch = async (): Promise<void> => {
    setIsLoadingDismissAll(true);
    await dispatch(batchUpdateAllNotificationsInAlertRule(id, 'dismissed'));
    handleToast('Notifications dismissed');
    setIsLoadingDismissAll(false);
    onOpen(null);
    dispatch(fetchSidebarAlertNotifications({ limit: 20, offset: 0 }));
    handleCloseConfirmModal();
  };

  const handleOpenRule = (ruleId: number): void => {
    if (Number(alertRule.notificationCount) > 0) {
      onOpen(ruleId);
    }
  };

  const pausedIcon = paused != null ? (
    <Icon className={css.customIcon} color="yellow" name="clock" />
  ) : (
    <Icon className={css.bellIcon} name="bell" />
  );

  return (
    <React.Fragment key={id}>
      <Modal className={css.modal} open={confirmModal.open} onClose={handleCloseConfirmModal} size="small">
        <Modal.Header>
          Confirm
          {' '}
          {confirmModal.content}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Header>
              Are you sure you want to
              {' '}
              {confirmModal.content}
              ?
            </Header>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleCloseConfirmModal}>Cancel</Button>
          <Button className="brand" primary onClick={(): void => confirmModal.action()}>{confirmModal.content}</Button>
        </Modal.Actions>
      </Modal>
      <Modal className={css.modal} open={isDateModalOpen} onClose={handleCloseDateModal} size="small">
        <Modal.Header>Pause Settings</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Header>Pause for</Header>
          </Modal.Description>
          <Dropdown
            className={css.dropdown}
            fluid
            onChange={handleChange}
            options={dateOptions}
            placeholder="Choose date"
            selection
          />
          {showPauseCalendar && (
            <div className={css.datepickerContainer}>
              <Calendar
                calendarType="US"
                className={css.datePicker}
                minDate={new Date()}
                onChange={handleCalendarChange}
                value={pauseDate}
              />
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleCloseDateModal}>Cancel</Button>
          <Button className="brand" primary onClick={(): void => handlePauseAlertRule(true)}>Save</Button>
        </Modal.Actions>
      </Modal>
      <Table.Row
        className={`${css.row} ${highlighted ? css.highlight : ''}`}
        onClick={(): void => handleOpenRule(id)}
        onMouseEnter={(): void => onHover(id)}
        onMouseLeave={(): void => onHover(null)}
      >
        <Table.Cell onClick={handleTogglePauseMenu}>
          <Popup
            className={css.pausePopup}
            open={isPauseMenuOpen}
            position="bottom left"
            style={{ padding: 0 }}
            trigger={pausedIcon}
          >
            <Menu ref={ref} className={css.pauseMenu} vertical>
              {
                isPaused ? (
                  <Menu.Item header>{`Paused until ${formatDate(paused, 'MMM DD, YYYY')}`}</Menu.Item>
                ) : null
              }
              <Menu.Item
                active={!isPaused}
                onClick={(): void => handlePauseAlertRule(false)}
              >
                <div>
                  <Icon className={css.bellIcon} name="bell" />
                  <span>Alert On</span>
                </div>
              </Menu.Item>
              <Menu.Item
                active={isPaused}
                onClick={(): void => setIsDateModalOpen(true)}
              >
                <div>
                  <Icon className={css.customIcon} color="yellow" name="clock" />
                  <span>Paused</span>
                </div>
              </Menu.Item>
            </Menu>
          </Popup>
        </Table.Cell>
        <Table.Cell>
          {
            mostRecentNotification
              ? formatDate(mostRecentNotification, 'MMM DD, YYYY')
              : '--'
          }
        </Table.Cell>
        <Table.Cell>{ notificationCount }</Table.Cell>
        <Table.Cell>{ description }</Table.Cell>
        {rowHoverIndex && rowHoverIndex === id ? (
          <>
            <Table.Cell onClick={handleEdit} className={css.actionIcon}>
              <AccessControl accessingTo="manager">
                <Popup
                  content="Edit"
                  hoverable
                  inverted
                  basic
                  size="mini"
                  position="bottom center"
                  trigger={(
                    <Icon
                      className={css.editIcon}
                      loading={false}
                      name="edit"
                    />
                  )}
                />
              </AccessControl>
            </Table.Cell>
            <Table.Cell
              onClick={(e: SyntheticEvent): void => { e.stopPropagation(); setOpenModal(true); }}
              className={css.actionIcon}
            >
              <AccessControl accessingTo="manager">
                <Popup
                  content="Delete"
                  hoverable
                  inverted
                  basic
                  size="mini"
                  position="bottom center"
                  trigger={(
                    <Icon
                      className={css.deleteIcon}
                      loading={isLoadingDelete}
                      name="trash"
                    />
                  )}
                />
              </AccessControl>
            </Table.Cell>
          </>
        ) : (
          <>
            <Table.Cell className={css.emptyCell} />
            <Table.Cell className={css.emptyCell} />
          </>
        )}
        <Table.Cell>
          {Number(alertRule.notificationCount) > 0 && (
            <Icon name={chevronIcon} />
          )}
        </Table.Cell>
      </Table.Row>
      <AnimatePresence initial={false}>
        {activeAlertRuleIndex === id && (
          <>
            <motion.tr
              className={css.openedRule}
              initial={{ opacity: 0, scaleY: 0, height: 0 }}
              animate={{ opacity: 1, scaleY: 1, height: '100%' }}
              exit={{ opacity: 0, scaleY: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Table.HeaderCell />
              <Table.HeaderCell>
                Notification Date
              </Table.HeaderCell>
              <Table.HeaderCell colSpan="3">
                Notification Message
              </Table.HeaderCell>
              <Table.HeaderCell
                className={css.actionIcon}
                onClick={
                  (event): void => {
                    event.stopPropagation();
                    setConfirmModal({ open: true, action: handleResolveBatch, content: 'Resolve All' });
                  }
                }
              >
                <Popup
                  content="Resolve All"
                  hoverable
                  inverted
                  basic
                  size="mini"
                  position="bottom center"
                  trigger={(
                    <Icon
                      className={css.resolveAllIcon}
                      loading={isLoadingResolveAll}
                      name="check"
                    />
                  )}
                />
              </Table.HeaderCell>
              <Table.HeaderCell
                className={css.actionIcon}
                onClick={
                  (event): void => {
                    event.stopPropagation();
                    setConfirmModal({ open: true, action: handleDismissBatch, content: 'Dismiss All' });
                  }
                }
              >
                <Popup
                  content="Dismiss All"
                  hoverable
                  inverted
                  basic
                  size="mini"
                  position="bottom center"
                  trigger={(
                    <Icon
                      className={css.dismissAllIcon}
                      loading={isLoadingDismissAll}
                      name="delete"
                    />
                  )}
                />
              </Table.HeaderCell>
            </motion.tr>

            {notifications.map((notif: any): React.ReactNode => (
              <RulesRowNotificationItem
                hoveredId={notificationHoveredIndex}
                key={notif.id}
                notification={notif}
                onHover={handleHoverNotification}
              />
            ))}
          </>
        )}
      </AnimatePresence>
      <Modal open={openModal}>
        <Modal.Header>Deletion of an Alert Rule</Modal.Header>
        <Modal.Content>
          Are you sure you want to delete this alert rule?
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={(event): void => { event.stopPropagation(); setOpenModal(false); }}>Cancel</Button>
          <Button className={css.deleteButton} onClick={handleDelete}>Delete</Button>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};

export default RulesRow;
