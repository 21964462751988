import moment from 'moment';
import prettyCron from 'prettycron';

import metricDefinitions, { AlertRuleLanguagePack, MetricType } from './metricDefinitions';

export type AlertRuleThresholdOperator = '>' | '<' | '>=' | '<=' | '=';

export type AlertRuleToProcess = {
  userName?: string;
  metric: MetricType;
  metricName?: string;
  thresholdOperator: AlertRuleThresholdOperator;
  thresholdValue: number;
  timeFrame?: number;
  deliveryMethod: Array<string>;
  deliveryTo: Array<string>;
  alertFrequency: string;
  alertLimit?: { days?: number; weeks?: number };
};

export type AlertNotificationToProcess = {
  id: number;
  userName?: string;
  metric: MetricType;
  metricName?: string;
  thresholdOperator: AlertRuleThresholdOperator;
  thresholdValue: number;
  recordedValue: number;
}

export default class AlertLanguageProcessor {
  public static getAlertRuleStatement(rule: AlertRuleToProcess): string | undefined {
    const {
      metric,
      metricName,
      thresholdValue,
      thresholdOperator,
      userName,
      timeFrame,
      alertFrequency,
      alertLimit,
      deliveryMethod,
      deliveryTo,
    } = rule;
    let userStatement;
    let metricStatement;
    if (metric === 'SalesforceReport') {
      userStatement = '';
      const operatorText = this.getOperatorText(thresholdOperator, 'notCountable');
      metricStatement = `${metricName} is ${operatorText} ${thresholdValue}`;
    } else {
      userStatement = userName ? `${userName} has ` : '';
      const languagePack: AlertRuleLanguagePack | undefined = metricDefinitions[metric];
      if (!languagePack) {
        return undefined;
      }
      const thresholdValueWithUnit = `${thresholdValue}${languagePack.unit || ''}`;
      const operatorText = this.getOperatorText(thresholdOperator, languagePack.countable);
      metricStatement = `${operatorText} ${thresholdValueWithUnit}`;
      if (languagePack.preValueDescription) {
        metricStatement = `${operatorText} ${languagePack.preValueDescription} ${thresholdValueWithUnit}`;
      }
      if (languagePack.postValueDescription) {
        const postStatement = thresholdValue === 1
          ? languagePack.postValueDescription.one
          : languagePack.postValueDescription.many;
        metricStatement = `${metricStatement} ${postStatement}`;
      }
    }
    let timeFrameText = '';
    if (timeFrame) {
      if (timeFrame === 24) {
        timeFrameText = ' daily';
      } else if (timeFrame === 168) {
        timeFrameText = ' weekly';
      } else {
        timeFrameText = ` over ${moment.duration(timeFrame, 'hours').humanize()}`;
      }
    }
    let deliveryText = '';
    if (deliveryTo && deliveryMethod) {
      deliveryText = ` notify ${deliveryTo.join(', ')} ${this.getDeliveryMethodText(deliveryMethod)}`;
    }
    let frequencyText = '';
    if (alertFrequency && !alertFrequency.startsWith('* *')) {
      frequencyText = ` ${prettyCron.toString(alertFrequency).replace('Mon, Tue, Wed, Thu and Fri', 'weekdays')}`;
    }
    let limitText = '';
    if (alertLimit) {
      let limitAmount;
      if (alertLimit.days) {
        limitAmount = alertLimit.days;
        if (limitAmount % 7 === 0) {
          limitAmount /= 7;
          limitText = limitAmount > 1 ? ` every ${limitAmount} weeks` : ' weekly';
        } else {
          limitText = limitAmount > 1 ? ` every ${limitAmount} days` : ' daily';
        }
      } else if (alertLimit.weeks) {
        limitAmount = alertLimit.weeks;
        limitText = limitAmount > 1 ? ` every ${limitAmount} weeks` : ' weekly';
      } else if (typeof alertLimit === 'string' || alertLimit instanceof String) {
        limitText = ` every ${alertLimit}`;
      }
    }

    return `If ${userStatement}${metricStatement}${timeFrameText}${deliveryText}${frequencyText}${limitText}`;
  }

  public static getAlertNotificationStatement(notification: AlertNotificationToProcess): string | undefined {
    const {
      userName,
      metric,
      metricName,
      thresholdValue,
      thresholdOperator,
      recordedValue,
    } = notification;
    let userStatement;
    let metricStatement;
    let recordedStatement;
    const recordedValueFixed = Math.round((recordedValue + Number.EPSILON) * 100) / 100;
    if (metric === 'SalesforceReport') {
      userStatement = '';
      const operatorText = this.getOperatorText(thresholdOperator, 'notCountable');
      metricStatement = `${metricName} is ${operatorText} ${thresholdValue}`;
      recordedStatement = recordedValueFixed;
    } else {
      userStatement = userName ? `${userName} has ` : '';
      const languagePack: AlertRuleLanguagePack | undefined = metricDefinitions[metric];
      if (!languagePack) {
        return undefined;
      }
      const operatorText = this.getOperatorText(thresholdOperator, languagePack.countable);
      const thresholdValueWithUnit = `${thresholdValue}${languagePack.unit || ''}`;
      const recordedValueWithUnit = `${recordedValueFixed}${languagePack.unit || ''}`;
      metricStatement = `${operatorText} ${thresholdValueWithUnit}`;
      recordedStatement = `${recordedValueWithUnit}`;
      if (languagePack.preValueDescription) {
        metricStatement = `${operatorText} ${languagePack.preValueDescription} ${thresholdValueWithUnit}`;
      }
      if (languagePack.postValueDescription) {
        const thresholdPostStatement = thresholdValue === 1
          ? languagePack.postValueDescription.one
          : languagePack.postValueDescription.many;
        metricStatement = `${metricStatement} ${thresholdPostStatement}`;
        const recordedPostStatement = recordedValue === 1
          ? languagePack.postValueDescription.one
          : languagePack.postValueDescription.many;
        recordedStatement = `${recordedStatement} ${recordedPostStatement}`;
      }
    }

    return `${userStatement}${metricStatement} (${recordedStatement})`;
  }

  public static getOperatorText(thresholdOperator, countable): string {
    const operatorsText = {
      english: {
        '>': {
          countable: 'greater than',
          notCountable: 'greater than',
        },
        '<': {
          countable: 'fewer than',
          notCountable: 'less than',
        },
        '>=': {
          countable: 'greater or equal to',
          notCountable: 'greater or equal to',
        },
        '<=': {
          countable: 'less than or equal to',
          notCountable: 'less than or equal to',
        },
        '=': {
          countable: 'equal to',
          notCountable: 'equal to',
        },
      },
    };
    return operatorsText.english[thresholdOperator][countable];
  }

  public static getDeliveryMethodText(deliveryMethod: Array<string>): string {
    const translatedDeliveryMethod: string[] = deliveryMethod.map((method: string) => {
      switch (method) {
        case 'app':
          return 'in app';
        case 'email':
          return 'by email';
        case 'slack':
          return 'on Slack';
        default:
          return '';
      }
    });
    if (translatedDeliveryMethod.length <= 2) {
      return translatedDeliveryMethod.join(' and ');
    }
    const lastElement = translatedDeliveryMethod.pop();
    const lastElementWithAnd = `and ${lastElement}`;
    translatedDeliveryMethod.push(lastElementWithAnd);
    return translatedDeliveryMethod.join(', ');
  }
}
