import { constructQueryString } from 'lib/util';
import { Goal, GoalBreakdownObject, GoalBreakdownType } from 'models/goal';

import baseApi from '../apiSlice';
import { ApiResponse } from '../apiTypes';

export interface AllTeamGoalsQuery {
  teamId: number;
}
export interface AllUserGoalsQuery {
  teamId: number;
  userId: number;
}
export interface TeamGoalQuery {
  teamId: number;
  goalId: number;
  periodIndex?: number;
}
export interface UserGoalQuery {
  teamId: number;
  userId: number;
  goalId: number;
  periodIndex?: number;
}

export interface TeamGoalAttainment {
  [userId: number]: GoalAttainment | undefined;
}

// index = 0 if this goal is currently open; > 0 indicates how many periods past, e.g. 2 = 2 months ago
export interface GoalPeriod {
  index: number;
  unit: 'week' | 'month' | 'quarter';
  start: string;
  end: string;
}

export interface GoalAttainment {
  goalId: number;
  goalName: string;

  userId: number;
  teamId: number | undefined;
  projectedAttainment: number;

  value: number;
  breakdown: {
    type: GoalBreakdownType;
    data: Array<GoalBreakdownObject>;
  };

  target: number;
  goalMet: boolean;
  period: GoalPeriod;
}

export interface UserGoalResponse extends ApiResponse {
  goals: Array<{
    goal: Goal;
    goalAttainments: GoalAttainment[];
    period: GoalPeriod;
  }>;
}

export interface GoalResponse extends ApiResponse, TeamGoalData {}

export interface TeamGoalData {
  goal: Goal;
  goalAttainments: TeamGoalAttainment;
  period: GoalPeriod;
}

export interface TeamGoalResponse extends ApiResponse {
  goals: Array<TeamGoalData>;
}

export interface HistoricalGoalQueryOptions {
  periodIndex?: number;
  lookback?: number;
  userId?: number;
}
export interface HistoricalGoalQuery {
  teamId: number;
  goalId: number;
  opts?: HistoricalGoalQueryOptions;
}
export interface HistoricalGoalResponse extends ApiResponse {
  goal: Goal;
  goalResponses: Array<TeamGoalData>;
}

const CACHE_TIME = 120; // 2 min

const teamGoalAttainmentApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTeamPrimaryGoalAttainment: builder.query<GoalResponse, AllTeamGoalsQuery>({
      query: ({ teamId }) => `goal/team/${teamId}/primary`,
      keepUnusedDataFor: CACHE_TIME, // 2 min
    }),
    getTeamGoalAttainment: builder.query<TeamGoalResponse, AllTeamGoalsQuery>({
      query: ({ teamId }) => `goal/team/${teamId}`,
      keepUnusedDataFor: CACHE_TIME, // 2 min
    }),
    getMemberGoalAttainment: builder.query<UserGoalResponse, AllUserGoalsQuery>({
      query: ({ teamId, userId }) => `goal/user/${teamId}/${userId}/`,
      keepUnusedDataFor: CACHE_TIME, // 2 min
    }),
    getTeamGoal: builder.query<GoalResponse, TeamGoalQuery>({
      query: ({ teamId, goalId, periodIndex }) => {
        const queryStr = constructQueryString({ periodIndex });
        return `goal/team/${teamId}/${goalId}${queryStr}`;
      },
      keepUnusedDataFor: CACHE_TIME, // 2 min
    }),
    getMemberGoal: builder.query<GoalResponse, UserGoalQuery>({
      query: ({ teamId, userId, goalId, periodIndex }) => {
        const queryStr = constructQueryString({ periodIndex });
        return `goal/user/${teamId}/${userId}/${goalId}${queryStr}`;
      },
      keepUnusedDataFor: CACHE_TIME, // 2 min
    }),
    getGoalOverTime: builder.query<HistoricalGoalResponse, HistoricalGoalQuery>({
      query: ({ teamId, goalId, opts }) => {
        const queryString = constructQueryString(opts);
        return `goal/team/${teamId}/${goalId}/historical${queryString}`;
      },
    }),
  }),
});

export const useGetTeamPrimaryGoalAttainmentQuery = teamGoalAttainmentApi
  .endpoints
  .getTeamPrimaryGoalAttainment
  .useQuery;
export const useGetTeamGoalAttainmentQuery = teamGoalAttainmentApi.endpoints.getTeamGoalAttainment.useQuery;
export const useGetMemberGoalAttainmentQuery = teamGoalAttainmentApi.endpoints.getMemberGoalAttainment.useQuery;

export default teamGoalAttainmentApi;
