import React from 'react';
import { useSelector } from 'react-redux';
import { Label, Icon, Menu } from 'semantic-ui-react';
import { getUnreadNotificationsCount } from 'selectors/alert';

import css from './NotificationsBadge.module.css';

interface Props {
  onNotificationToggle: (event: React.SyntheticEvent) => void;
  visible: boolean;
}

const NotificationsBadge: React.FC<Props> = ({ onNotificationToggle, visible }): React.ReactElement => {
  const handleToggleNotificationSidebar = (event: React.SyntheticEvent): void => onNotificationToggle(event);

  const unreadNotifications = useSelector(getUnreadNotificationsCount);

  return (
    <div
      id="alert-badge"
      className={css.badge}
      onClick={handleToggleNotificationSidebar}
    >
      <Menu
        className={`${css.menuCompact} ${visible ? css.activeBadge : css.inactiveBadge}`}
        compact
      >
        <Menu.Item className={css.menuItem}>
          <Icon
            className={css.icon}
            inverted
            name="bell"
            size="large"
          />
          {unreadNotifications > 0 && (
            <Label
              circular
              className={css.label}
              floating
              color="red"
            >
              {unreadNotifications}
            </Label>
          )}
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default NotificationsBadge;
