import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Header, Icon, Segment } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';

import actions, { bindCombinedActions } from '../../../actions';

import css from './TermsOfService.module.css';

import ToSText from './ToSText';

interface Props {
  history: any;
}

class TermsOfService extends Component<Props, {}> {
  render(): React.ReactNode {
    const { history } = this.props;
    return (
      <div className={css.main}>
        <Header>
          Terms of Service
        </Header>
        <Segment className={css.tos}>
          <div className={css.content}>
            <ReactMarkdown
              className={css.recommendationMarkdown}
              allowDangerousHtml
              linkTarget="_blank"
            >
              {ToSText}
            </ReactMarkdown>
          </div>
        </Segment>
        <div className={css.buttonSection}>
          <div className="link" onClick={history.goBack}>
            <Icon name="arrow left" />
            back
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: any) => ({ consentRequest: state.app.consentRequest, tosConsented: state.app.tosConsented }),
  dispatch => ({
    actions: bindCombinedActions(actions, dispatch, ['app']),
    navigate: bindActionCreators((path: string) => push(path), dispatch),
  }),
)(TermsOfService);
