import React, { FC, useEffect, useMemo, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import {
  Grid,
  InputOnChangeData,
  Menu,
  Modal,
  TransitionGroup,
  Segment,
  Header,
  Popup,
  Placeholder,
  Icon,
} from 'semantic-ui-react';
import { FiTarget } from 'react-icons/fi';
import { GiChart } from 'react-icons/gi';
import {
  getIsProspecting,
  getSelectedTeamId,
  getSelectedTeamMembers,
  getTrackedSelectedTeamMembersForDropdown,
} from 'selectors/team';
import { getGoalHeaderText } from 'lib/util';

import { isPipelineMetricName } from 'models/metric/pipelineMetrics';
import BSIcon from 'components/BSIcon';
import ClearableInput from 'components/Input/ClearableInput';
import {
  SortSettings,
  TeamComparisonChart,
  Value,
} from 'components/TeamComparisonChart/TeamComparisonChart';
import { TeamComparisonChartBody } from 'components/TeamComparisonChart/Body/Body';
import { useGetTeamComparisonData } from 'components/TeamComparisonChart/helperFunctions';

import { TeamApi } from 'features/Api';
import { TeamGoalData } from 'features/Api/Goals';
import { selectAppDateSelection } from 'features/App/appSlice';
import { getApiDatesFromTimePeriod } from 'features/DatePicker/datePickerFunctions';

import { allMetricsDisplayInfo, getMetricDisplayInfo, MetricName } from 'models/metric';
import { emailMetricDisplayInfo, EmailMetricName } from 'models/metric/activityMetrics';

import NavHeader from '../NavHeader';
import SettingsModal, { ComparisonSettings } from './components/SettingsModal/SettingsModal';
import GoalModalContent from './components/GoalModalContent';
import MetricModalContent from './components/MetricModalContent';
import { convertAttainmentToDatum } from './components/goalUtil';

import css from './TeamComparison.module.css';

interface State {
  includedRepIds: number[];
  settings: ComparisonSettings;
  sortSettings?: SortSettings;
  filters: {
    metric: string;
  };
  goalId: number | false;
  modalMetric: EmailMetricName | false;
  modalDisplayAsPercentage: boolean;
}

const getInitialState = (): State => ({
  includedRepIds: [],
  repFilters: [],
  settings: { sort: 'metricDesc' },
  sortSettings: undefined,
  filters: {
    metric: '',
  },
  goalId: false,
  modalMetric: false,
  modalDisplayAsPercentage: false,
});

const stateReducer = (state: State, action): State => {
  switch (action.type) {
    case 'SET_REP_FILTER':
      return {
        ...state,
        includedRepIds: action.includedRepIds,
      };
    case 'CHANGE_SORT':
      return {
        ...state,
        sortSettings: action.sortSettings,
      };
    case 'CHANGE_METRIC_FILTER':
      return {
        ...state,
        filters: {
          metric: action.metricFilter,
        },
      };
    case 'OPEN_MODAL':
      return {
        ...state,
        modalMetric: action.modalMetric || false,
        goalId: action.goalId || false,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        modalMetric: false,
        goalId: false,
      };
    case 'SET_MODAL_PERCENT':
      return {
        ...state,
        modalDisplayAsPercentage: action.modalPercent,
      };
    default:
      throw new Error();
  }
};

interface ManagerListProps {}

const StatsCard: FC<{
  metric: MetricName;
  teamId: number;
  start: string;
  end: string;
  includedRepIds?: number[];
  sortSettings: SortSettings | undefined;
}> = ({
  metric,
  teamId,
  start,
  end,
  includedRepIds,
  sortSettings,
}) => {
  const {
    isFetching,
    data,
    legend,
    averageValue,
  } = useGetTeamComparisonData(metric, teamId, start, end, includedRepIds);
  const { title, higherIsBetter } = getMetricDisplayInfo(metric);
  return (
    <TeamComparisonChart
      data={data || []}
      legend={legend}
      higherIsBetter={higherIsBetter}
      sortSettings={sortSettings}
    >
      <TeamComparisonChart.Header className={css.header}>
        <GiChart className={css.icon} color="purple" />
        <Header>
          {title}
          { averageValue !== undefined
            ? (
              <Header.Subheader>
                {`Average: ${averageValue.displayValue}`}
              </Header.Subheader>
            )
            : <></> }
        </Header>
      </TeamComparisonChart.Header>
      <TeamComparisonChartBody loading={isFetching}>
        <TeamComparisonChartBody.NameColumn />
        <TeamComparisonChartBody.BarColumn>
          { averageValue
            ? <TeamComparisonChartBody.BarColumn.BenchmarkLine value={averageValue} />
            : <></> }
        </TeamComparisonChartBody.BarColumn>
        <TeamComparisonChartBody.ValueColumn />
      </TeamComparisonChartBody>
    </TeamComparisonChart>
  );
};

const GoalCard: FC<{
  goalData: TeamGoalData;
  includedRepIds?: number[];
  isFetching: boolean;
  sortSettings: SortSettings | undefined;
}> = ({
  goalData,
  includedRepIds,
  isFetching,
  sortSettings,
}) => {
  const { goal, period } = goalData;
  const { displayName: title, target } = goal;
  const teamMembers = useSelector(getSelectedTeamMembers);
  const { data: unfilteredData, max } = convertAttainmentToDatum(goalData, teamMembers);
  const data = unfilteredData.filter(d => !includedRepIds?.length || includedRepIds.includes(d.userId));

  const targetValue: Value = {
    sortValue: target,
    displayValue: target.toString(),
  };
  const maxValue: Value = {
    sortValue: max,
    displayValue: max.toString(),
  };
  const { subHeaderText, popupContent } = getGoalHeaderText(period);

  return (
    <TeamComparisonChart
      data={data}
      higherIsBetter
      maxValue={maxValue}
      sortSettings={sortSettings}
    >
      <TeamComparisonChart.Header className={css.header}>
        <FiTarget color="blue" className={css.icon} />
        <Header>
          <Popup
            content={popupContent}
            trigger={(
              <Header.Subheader>
                {subHeaderText}
              </Header.Subheader>
            )}
          />
          {title}
          { target !== undefined
            ? (
              <Header.Subheader>
                {`Target: ${target}`}
              </Header.Subheader>
            )
            : <></> }
        </Header>
      </TeamComparisonChart.Header>
      <TeamComparisonChartBody loading={isFetching}>
        <TeamComparisonChartBody.NameColumn />
        <TeamComparisonChartBody.BarColumn>
          { target
            ? <TeamComparisonChartBody.BarColumn.BenchmarkLine value={targetValue} color="#2C87DC" />
            : <></> }
        </TeamComparisonChartBody.BarColumn>
        <TeamComparisonChartBody.ValueColumn />
      </TeamComparisonChartBody>
    </TeamComparisonChart>
  );
};

export const ManagerList: FC<ManagerListProps> = () => {
  const teamMemberOptions = useSelector(getTrackedSelectedTeamMembersForDropdown);
  const [state, dispatch] = useReducer(stateReducer, getInitialState());
  const teamId = useSelector(getSelectedTeamId);
  const timePeriod = useSelector(selectAppDateSelection);
  const isProspecting = useSelector(getIsProspecting);

  if (!teamId) return <></>;

  const {
    data: goalsApiResponse,
    isFetching,
  } = TeamApi.Goals.useGetTeamGoalAttainmentQuery({ teamId });

  const handleSearch = (_, searchData: InputOnChangeData): void => {
    dispatch({ type: 'CHANGE_METRIC_FILTER', metricFilter: searchData.value.trim() });
  };

  const setDisplayAsPercentage: (newVal: boolean) => void = newVal => {
    dispatch({ type: 'SET_MODAL_PERCENT', modalPercent: newVal });
  };

  const debouncedSearchHandler = useMemo(
    () => debounce(handleSearch, 500),
    [],
  );
  useEffect(() => (): void => {
    debouncedSearchHandler.cancel();
  });

  const {
    settings,
    includedRepIds,
    sortSettings,
    filters: { metric: nameFilter },
    goalId,
    modalMetric,
    modalDisplayAsPercentage,
  } = state;

  const { start, end } = getApiDatesFromTimePeriod(timePeriod);

  const { prospectingPercentage: _, ...prospectingMetricDisplayInfo } = emailMetricDisplayInfo;
  const metricDisplayInfo = isProspecting ? prospectingMetricDisplayInfo : allMetricsDisplayInfo;

  const filteredMetrics = Object.values(metricDisplayInfo).filter(
    ({ title }) => title.toLowerCase().search(nameFilter.toLowerCase()) >= 0,
  ).filter(
    ({ enabled }) => enabled,
  ).sort(({ order: a = Infinity }, { order: b = Infinity }) => a - b);

  const goalData = goalId ? goalsApiResponse?.goals.find(g => g.goal.id === goalId) : false;

  const modalIsOpen = (modalMetric !== false) || (goalData !== false);
  let modalTitle;
  if (modalMetric !== false) {
    modalTitle = getMetricDisplayInfo(modalMetric).title;
  } else if (goalData) {
    modalTitle = goalData.goal.displayName;
  }

  const handleSettingsChange = (e: React.MouseEvent<HTMLButtonElement>, data: ComparisonSettings): void => {
    e.stopPropagation();
    const { reps, sort } = data;
    let newSort: SortSettings | undefined;
    if (sort === 'rep') {
      newSort = { column: 'names', direction: 'ascending' };
    } else if (sort === 'metricAsc') {
      newSort = { column: 'optimality', direction: 'ascending' };
    } else if (sort === 'metricDesc') {
      newSort = { column: 'optimality', direction: 'descending' };
    }
    dispatch({ type: 'CHANGE_SORT', sortSettings: newSort });
    dispatch({ type: 'SET_REP_FILTER', includedRepIds: reps });
  };

  const goalLoadingCard = (
    <Grid.Column>
      <Segment className={css.statsCard} loading>
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </Segment>
    </Grid.Column>
  );
  const goalCards = isFetching ? goalLoadingCard : goalsApiResponse?.goals.map(gData => {
    const { goal } = gData;
    return (
      <Grid.Column
        onClick={(): void => {
          dispatch({ type: 'OPEN_MODAL', modalMetric: false, goalId: goal.id });
        }}
        key={`goal_${goal.id}`}
      >
        <Segment className={css.statsCard}>
          <GoalCard
            goalData={gData}
            includedRepIds={includedRepIds}
            isFetching={isFetching}
            sortSettings={sortSettings}
          />
        </Segment>
      </Grid.Column>
    );
  });
  const handleCloseModal = (): void => { dispatch({ type: 'CLOSE_MODAL' }); };
  const closeIcon = <Icon name="x" onClick={(): void => handleCloseModal()} className={`${css.floatRight} ${css.pointer}`} />;
  return (
    <>
      <Modal
        open={modalIsOpen}
        onClose={(): void => handleCloseModal()}
        closeOnDimmerClick
      >
        <Modal.Header>
          {modalTitle}
          {' '}
          { isPipelineMetricName(modalMetric) ? (
            <Link to="/app/pipeline" className="link brand">
              <BSIcon name="long arrow alternate right" />
            </Link>
          ) : <></>}
          {closeIcon}
        </Modal.Header>
        <Modal.Content>
          {modalMetric !== false
            ? (
              <MetricModalContent
                modalMetric={modalMetric}
                teamId={teamId}
                timePeriod={timePeriod}
                displayAsPercentage={modalDisplayAsPercentage}
                setDisplayAsPercentage={setDisplayAsPercentage}
                includedRepIds={includedRepIds}
                sortSettings={sortSettings}
              />
            )
            : null }
          {goalData
            ? (
              <GoalModalContent
                goalData={goalData}
                teamId={teamId}
                displayAsPercentage={modalDisplayAsPercentage}
                setDisplayAsPercentage={setDisplayAsPercentage}
                includedRepIds={includedRepIds}
                sortSettings={sortSettings}
              />
            )
            : null }
        </Modal.Content>
      </Modal>
      <TransitionGroup
        as={Grid}
        container
        columns={3}
        animation="browse"
        directional
      >
        <Grid.Row>
          <Grid.Column width={16}>
            <NavHeader>
              <Menu.Item>
                <ClearableInput placeholder="Search Metrics" onChange={debouncedSearchHandler} />
              </Menu.Item>
              <Menu.Item>
                <SettingsModal
                  settings={settings}
                  teamOptions={teamMemberOptions}
                  onChange={handleSettingsChange}
                />
              </Menu.Item>
            </NavHeader>
          </Grid.Column>
        </Grid.Row>
        {goalCards}
        {filteredMetrics.map(({ name }) => (
          <Grid.Column
            onClick={(): void => {
              dispatch({ type: 'OPEN_MODAL', modalMetric: name });
            }}
            key={name}
          >
            <Segment className={css.statsCard}>
              <StatsCard
                metric={name}
                teamId={teamId}
                start={start}
                end={end}
                includedRepIds={includedRepIds}
                sortSettings={sortSettings}
              />
            </Segment>
          </Grid.Column>
        ))}
      </TransitionGroup>
    </>
  );
};

export default ManagerList;
