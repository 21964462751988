/* eslint-disable @typescript-eslint/camelcase */
import { ProspectingMetricCodename } from 'features/Api/Prospecting';
import { round } from 'lib/util';

import { MetricLanguageKit } from './MetricLanguageKit';
import { ResponseTimeLanguageKit } from './ResponseTimeLanguageKit';

type CloseDateMetric =
  'close_date_push_out_count' |
  'close_date_pull_in_count' |
  'close_date_avg_push_out' |
  'close_date_avg_pull_in' |
  'close_date_total_mid_stage_pull_in' |
  'close_date_total_mid_stage_push_out';

const langCloseDateMetric: {
  [key in CloseDateMetric]: MetricLanguageKit
} = {
  close_date_push_out_count: new MetricLanguageKit({
    name: 'Close Dates Pushed Out',
    description: 'Number of times the close date has been pushed out (moved to a later date)',
    defaultValue: 0,
    unit: {
      zero: '',
      one: '',
      other: '',
    },
  }),
  close_date_pull_in_count: new MetricLanguageKit({
    name: 'Close Dates Pulled In',
    description: 'Number of times the close date has been pulled in (moved to an earlier date)',
    defaultValue: 0,
    unit: {
      zero: '',
      one: '',
      other: '',
    },
  }),
  close_date_avg_push_out: new MetricLanguageKit({
    name: 'Avg Close Date Push Out',
    description: 'Average size of close date push outs in days',
    defaultValue: 0,
    unit: {
      zero: 'days',
      one: 'day',
      other: 'days',
    },
  }),
  close_date_avg_pull_in: new MetricLanguageKit({
    name: 'Avg Close Date Pull In',
    description: 'Average size of close date pull ins in days',
    defaultValue: 0,
    unit: {
      zero: 'days',
      one: 'day',
      other: 'days',
    },
  }),
  close_date_total_mid_stage_pull_in: new MetricLanguageKit({
    name: 'Net Close Date Pull In',
    description: 'Net number of days the close date was pulled in to date or before the opportunity was closed',
    defaultValue: 0,
    unit: {
      zero: 'days',
      one: 'day',
      other: 'days',
    },
  }),
  close_date_total_mid_stage_push_out: new MetricLanguageKit({
    name: 'Net Close Date Pushed Out',
    description: 'Net number of days the close date was pushed out to date or before the opportunity was closed',
    defaultValue: 0,
    unit: {
      zero: 'days',
      one: 'day',
      other: 'days',
    },
  }),
};

type StageMovementMetric =
  'stage_num_skipped_many' |
  'stage_num_backward' |
  'stage_num_reopened' |
  'stage_avg_skip_size' |
  'time_in_stage';

const langStageMovementMetric: {
  [key in StageMovementMetric]: MetricLanguageKit
} = {
  stage_num_skipped_many: new MetricLanguageKit({
    name: 'Large Stage Skips',
    description: 'Number of times more than 1 stage is skipped',
    defaultValue: 0,
    unit: {
      zero: '',
      one: '',
      other: '',
    },
  }),
  stage_avg_skip_size: new MetricLanguageKit({
    name: 'Avg Stages Skipped',
    description: 'When stage(s) are skipped, the average number skipped at a time',
    defaultValue: 0,
    unit: {
      zero: 'stages',
      one: 'stage',
      other: 'stages',
    },
  }),
  stage_num_backward: new MetricLanguageKit({
    name: 'Backward Stage Changes',
    description: 'Number of backward stage changes',
    defaultValue: 0,
    unit: {
      zero: '',
      one: '',
      other: '',
    },
  }),
  stage_num_reopened: new MetricLanguageKit({
    name: 'Opportunity Times Reopened',
    description: 'Number of times the opportunity reopened after being closed',
    defaultValue: 0,
    unit: {
      zero: '',
      one: '',
      other: '',
    },
  }),
  time_in_stage: new MetricLanguageKit({
    name: 'Days in stage',
    description: 'The days since the current stage started',
    defaultValue: 0,
    unit: {
      zero: 'days',
      one: 'day',
      other: 'days',
    },
  }),
};

type ActivityGapMetric =
  'avg_contact_gap' |
  'avg_opportunity_gap' |
  'big_contact_gap_count' |
  'big_opportunity_gap_count';

const langActivityGapMetric: {
  [key in ActivityGapMetric]: MetricLanguageKit
} = {
  avg_contact_gap: new MetricLanguageKit({
    name: 'Avg Contact Gap',
    description: 'On average, how much time has passed between two activities that take place with the same contact.',
    unit: {
      zero: 'days',
      one: 'day',
      other: 'days',
    },
  }),
  avg_opportunity_gap: new MetricLanguageKit({
    name: 'Avg Opportunity Gap',
    description: 'On average, how much time has passed between two activities that take place with any contact.',
    unit: {
      zero: 'days',
      one: 'day',
      other: 'days',
    },
  }),
  big_contact_gap_count: new MetricLanguageKit({
    name: 'Big Contact Gap Count',
    description: 'How many times has 7 or more consecutive days elapsed between activities with the same contact.',
    unit: {
      zero: 'gaps',
      one: 'gap',
      other: 'gaps',
    },
  }),
  big_opportunity_gap_count: new MetricLanguageKit({
    name: 'Big Opportunity Gap Count',
    description: 'How many times has 7 or more consecutive days elapsed between activities with any contact.',
    unit: {
      zero: 'gaps',
      one: 'gap',
      other: 'gaps',
    },
  }),
};

type ActivityMetrics =
  'daily_activity_velocity' |
  'daily_meeting_velocity' |
  'daily_call_velocity' |
  'daily_inbound_velocity' |
  'daily_mail_velocity' |
  'daily_outbound_velocity' |
  'prospect_response_time_mean' |
  'rep_followup_time_mean' |
  'rep_response_time_mean';

const langActivityMetric: {
  [key in ActivityMetrics]: MetricLanguageKit
} = {
  daily_activity_velocity: new MetricLanguageKit({
    name: 'Weekly Activity Velociy',
    description: 'Average number of activities per week over the last 30 days',
    defaultValue: 0,
    valueFn: (value: number): number => round(value * 7),
    unit: {
      zero: 'activities',
      one: 'activity',
      other: 'activities',
    },
  }),
  daily_meeting_velocity: new MetricLanguageKit({
    name: 'Monthly Meeting Velocity',
    description: 'Average number of meetings per month over the last 30 days',
    defaultValue: 0,
    valueFn: (value: number): number => round(value * 30),
    unit: {
      zero: 'meetings',
      one: 'meeting',
      other: 'meetings',
    },
  }),
  daily_call_velocity: new MetricLanguageKit({
    name: 'Monthly Call Velocity',
    description: 'Average number of calls per month over the last 30 days',
    defaultValue: 0,
    valueFn: (value: number): number => round(value * 30),
    unit: {
      zero: 'calls',
      one: 'call',
      other: 'calls',
    },
  }),
  daily_inbound_velocity: new MetricLanguageKit({
    name: 'Weekly Inbound Email Velocity',
    description: 'Average number of inbound emails per week received by reps over the last 30 days',
    defaultValue: 0,
    valueFn: (value: number): number => round(value * 7),
    unit: {
      zero: 'emails',
      one: 'emails',
      other: 'emails',
    },
  }),
  daily_mail_velocity: new MetricLanguageKit({
    name: 'Weekly Email Velocity',
    description: 'Average number of inbound and outbound emails per week over the last 30 days',
    defaultValue: 0,
    valueFn: (value: number): number => round(value * 7),
    unit: {
      zero: 'emails',
      one: 'email',
      other: 'emails',
    },
  }),
  daily_outbound_velocity: new MetricLanguageKit({
    name: 'Weekly Outbound Email Velocity',
    description: 'Average number of outbound emails per week sent by reps over the last 30 days',
    defaultValue: 0,
    valueFn: (value: number): number => round(value * 7),
    unit: {
      zero: 'emails',
      one: 'email',
      other: 'emails',
    },
  }),
  prospect_response_time_mean: new ResponseTimeLanguageKit({
    name: 'Prospect Response Time',
    description: 'Average number of days the prospect took to respond to emails.',
    unit: {
      zero: 'days',
      one: 'day',
      other: 'days',
    },
  }),
  rep_followup_time_mean: new ResponseTimeLanguageKit({
    name: 'Rep Followup Time',
    description: 'Average number of days between outbound emails from the rep.',

    unit: {
      zero: 'days',
      one: 'day',
      other: 'days',
    },
  }),
  rep_response_time_mean: new ResponseTimeLanguageKit({
    name: 'Rep Response Time',
    description: 'Average number of days the rep took to respond to inbound emails',

    unit: {
      zero: 'days',
      one: 'day',
      other: 'days',
    },
  }),
};

type ActivityOccurrenceMetric = ActivityGapMetric | ActivityMetrics;

type EmailMetric = 'flesch_kincaid_grade_mean' | 'word_count_mean';

const langEmailMetric: {
  [key in EmailMetric]: MetricLanguageKit
} = {
  flesch_kincaid_grade_mean: new MetricLanguageKit({
    name: 'Email Grade Level',
    description: 'Average “grade level” measuring readability of outbound emails',
    unit: {
      zero: '',
      one: '',
      other: '',
    },
  }),
  word_count_mean: new MetricLanguageKit({
    name: 'Email Word Count',
    description: 'Average word count of outbound emails excluding signatures, URLs, and formatting code.',
    unit: {
      zero: 'words',
      one: 'word',
      other: 'words',
    },
  }),
};

type ContactMetric = 'total_num_of_contacts';

const langContactMetric: {
  [key in ContactMetric]: MetricLanguageKit
} = {
  total_num_of_contacts: new MetricLanguageKit({
    name: 'Total Number of Contacts',
    description: 'The number of distinct contacts associated with activities for this account.',
    unit: {
      zero: 'contacts',
      one: 'contact',
      other: 'contacts',
    },
  }),
};

export type MetricCodename = CloseDateMetric | StageMovementMetric |
  ActivityOccurrenceMetric | EmailMetric | ContactMetric;

export interface Metric {
  metricName: MetricCodename | ProspectingMetricCodename;
  value: number | null;
}

export const langMetric: {
  [key in MetricCodename]: MetricLanguageKit
} = {
  ...langCloseDateMetric,
  ...langActivityGapMetric,
  ...langContactMetric,
  ...langEmailMetric,
  ...langActivityMetric,
  ...langStageMovementMetric,
};
// eslint-enable @typescript-eslint/camelcase */ //
