import React from 'react';
import { Loader } from 'semantic-ui-react';

import css from './LoadingDimmmer.module.css';

interface LoadingDimmerProps {
  active: boolean;
}

const LoadingDimmer: React.FC<LoadingDimmerProps> = ({ active }) => {
  if (active) {
    return (
      <div className={`${css.container} ${css.active}`}>
        <Loader active={active} />
      </div>
    );
  }
  return null;
};

export default LoadingDimmer;
