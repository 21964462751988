/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Input, InputProps } from 'semantic-ui-react';

const ClearableInput = React.forwardRef<Input, InputProps>((props, ref): React.ReactElement => {
  const { value: propValue, defaultValue } = props;
  const [value, setValue] = useState(propValue || '');

  const handleClear = (e, data): void => {
    const { onChange } = props;
    const { value: dataValue } = data;
    setValue(dataValue);
    if (onChange) {
      onChange(e, data);
    }
  };
  const handleChange = (e, data): void => {
    const { onChange } = props;
    setValue(e.target.value);
    if (onChange) {
      onChange(e, data);
    }
  };
  return (
    <Input
      ref={ref}
      {...props}
      action={{ icon: 'cancel', onClick: handleClear, value: defaultValue || '', basic: true }}
      onChange={handleChange}
      value={value}
    />
  );
});

export default ClearableInput;
