import { Datum, Classification } from 'components/TeamComparisonChart/TeamComparisonChart';
import { TeamGoalData, GoalAttainment } from 'features/Api/Goals';
import { formatStat } from 'lib/util';

const BLUE = '#335FCC';
// const LIGHT_BLUE = '#E6F1FF';
const RED = '#db2828';
// const LIGHT_RED = '#ffdada';
const GREEN = '#44af69';
// const LIGHT_GREEN = '#e3f3e9';

export const GOAL_MODAL_COLORS = {
  BLUE,
  RED,
  GREEN,
};

export const convertAttainmentToDatum = (
  { goal, goalAttainments, period }: TeamGoalData,
  teamMembers,
): { data: Datum[]; max: number; legend: Array<Classification>} => {
  const ABOVE_TEXT = 'Above Target';
  const BELOW_TEXT = 'Below Target';

  const { target: defaultTarget } = goal;
  let max = defaultTarget;
  const legend: Array<Classification> = [
    { name: ABOVE_TEXT, color: GREEN },
    { name: BELOW_TEXT, color: (period.index === 0) ? BLUE : RED },
  ];
  const data: Datum[] = Object.values(goalAttainments).map((attainment: GoalAttainment) => {
    const { userId, target, value } = attainment;
    if (value > max) {
      max = value;
    }
    const user = teamMembers.find(m => m.id === userId);
    const userName = `${user?.firstName} ${user?.lastName[0]}.`;
    if (value === null) {
      return {
        userId,
        userName,
        value: {
          sortValue: value,
          displayValue: 'N/A',
          percentOfBenchmarkDisplayValue: 'N/A',
          classification: {
            name: 'N/A',
            color: '',
          },
        },
      };
    }
    let color = '#a5a5a5';
    if (value >= target) {
      color = GREEN;
    }
    if (value < target) {
      if (period.index === 0) {
        color = BLUE;
      } else {
        color = RED;
      }
    }

    return {
      userId,
      userName,
      value: {
        sortValue: value,
        displayValue: formatStat(value, 0).toString(),
        percentOfBenchmarkDisplayValue: `${formatStat((value / target) * 100)}%`,
        classification: {
          name: 'goal',
          color,
        },
      },
    };
  });
  return { data, max, legend };
};
