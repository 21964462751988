import { createActions } from 'redux-actions';

import { Permission } from '../components/AccessControl';
import api from '../lib/api';
import { handleError } from '.';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const actions = createActions({
  USER: {
    FETCH_USER_INFO_REQUEST: undefined,
    FETCH_USER_INFO_SUCCESS: userInfo => ({ userInfo }),
    FETCH_USER_INFO_FAILURE: msg => ({ msg }),
    FETCH_USER_TAGS_REQUEST: undefined,
    FETCH_USER_TAGS_SUCCESS: tags => ({ tags }),
    FETCH_USER_TAGS_FAILURE: msg => ({ msg }),
    FETCH_USERS_REQUEST: undefined,
    FETCH_USERS_SUCCESS: users => ({ users }),
    FETCH_USERS_FAILURE: msg => ({ msg }),
    FETCH_SALESFORCE_USERS_REQUEST: undefined,
    FETCH_SALESFORCE_USERS_SUCCESS: users => ({ users }),
    FETCH_SALESFORCE_USERS_FAILURE: msg => ({ msg }),
    SYNC_USER_REQUEST: undefined,
    SYNC_USER_COMPLETE: userInfo => ({ userInfo }),
    CLEAR_USER: undefined,
  },
});
/* eslint-enable @typescript-eslint/explicit-function-return-type */

export const fetchUserInfo = userId => async (dispatch: Function): Promise<any> => {
  dispatch(actions.user.fetchUserInfoRequest());
  const response = await api.getUser(userId);
  if (response.status) {
    dispatch(actions.user.fetchUserInfoSuccess(response.user));
  } else {
    handleError(response, dispatch);
    dispatch(actions.user.fetchUserInfoFailure(`There was an error fetching the user with id ${userId}`));
  }
  return response;
};

export const fetchSalesforceUsers = (opts = {}) => async (dispatch: Function): Promise<any> => {
  dispatch(actions.user.fetchSalesforceUsersRequest());
  const response = await api.getSalesforceUsers(opts);
  if (response.status) {
    dispatch(actions.user.fetchSalesforceUsersSuccess(response.users));
  } else {
    handleError(response, dispatch);
    dispatch(actions.user.fetchSalesforceUsersFailure('There was an error fetching users'));
  }
  return response;
};

export const fetchUsers = () => async (dispatch: Function): Promise<any> => {
  dispatch(actions.user.fetchUsersRequest());
  const response = await api.getUsers();
  if (response.status) {
    dispatch(actions.user.fetchUsersSuccess(response.users));
  } else {
    handleError(response, dispatch);
    dispatch(actions.user.fetchUsersFailure('There was an error fetching users'));
  }
  return response;
};

export const setUserPermissions = (id: string, permission: Permission) => async (dispatch: Function): Promise<any> => {
  const response = await api.setUserPermissions(id, permission);
  if (response.status) {
    dispatch(fetchUsers());
  } else {
    handleError(response, dispatch);
  }
  return response;
};

export const trackUser = (
  user: { id?: number; extId: string; extSource: string; isTracked: boolean },
) => async (dispatch: Function): Promise<any> => {
  const trackedUser = { ...user };
  if (trackedUser.isTracked) {
    const syncResponse = await api.saveUserFromSalesforce(trackedUser.extId);
    // lil hacky but we have to sync the user first to ensure there is a user ID
    // the second sync will not sync the user again because it will check to see if it already exists
    await api.saveUserFromSalesforce(trackedUser.extId);
    if (!syncResponse.status) {
      handleError(syncResponse, dispatch);
      return syncResponse;
    }
    trackedUser.id = syncResponse.user.id;
  }
  const response = await api.setTrackedUser(trackedUser);
  if (response.status) {
    dispatch(fetchUsers());
  } else {
    handleError(response, dispatch);
  }
  return response;
};

export const saveUserFromSalesforce = (
  salesforceUserId: string,
  opts: { force?: boolean; permission: string; isTracked: boolean },
) => async (dispatch: Function): Promise<any> => {
  dispatch(actions.user.syncUserRequest());
  const response = await api.saveUserFromSalesforce(salesforceUserId, opts);
  await api.applyAllTagRules();
  if (!response.status) {
    handleError(response, dispatch);
  }
  dispatch(actions.user.syncUserComplete(response.user));
  return response;
};

const userActions = {
  user: {
    ...actions.user,
    fetchUserInfo,
    fetchSalesforceUsers,
    fetchUsers,
    trackUser,
    saveUserFromSalesforce,
    setUserPermissions,
  },
};

export default userActions;
