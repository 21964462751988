import { useTeamComparisonBodyChartContext } from 'components/TeamComparisonChart/Body/BodyContext';
import { ColumnHeader } from 'components/TeamComparisonChart/Body/Columns/ColumnHeader';
import React, { FC } from 'react';
import css from '../Body.module.css';

export interface PercentColumnProps {
  title?: string;
}

export const PercentColumn: FC<PercentColumnProps> = ({
  title = '% of Avg',
}) => {
  const {
    data,
    columnHeaders: {
      visible: showHeaders,
    },
  } = useTeamComparisonBodyChartContext();
  return (
    <section className={`${css.column} ${css.values}`}>
      { showHeaders
        ? <ColumnHeader columnCategory="values" title={title} />
        : <></> }
      <ol>
        {data.map(
          ({ userName, value: { percentOfBenchmarkDisplayValue } }): React.ReactNode => (
            <li key={userName}>
              {percentOfBenchmarkDisplayValue}
            </li>
          ),
        )}
      </ol>
    </section>
  );
};
