import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon, Loader, Segment, Table, Menu, Pagination, PaginationProps } from 'semantic-ui-react';
import { skipToken } from '@reduxjs/toolkit/query';

import { prospectingApi } from 'features/Api';
import { ProspectingAccount } from 'features/Api/Prospecting';
import { getSelectedTeamId } from 'selectors/team';

import {
  ProspectingAccountFilters,
} from 'App/Main/ProspectingHealthRoute/ProspectingAccountList/ProspectingAccountList';

import css from './styles.module.css';

const ROWS_PER_PAGE = 100;

type SomeKeysOfObject<T, U extends keyof T> = U;

type Column = SomeKeysOfObject<
  ProspectingAccount,
  'name' | 'numContacts' | 'ownerName' | 'prospectingPhase' | 'contactsWithRecommendations'
>;

const initialSortDirection: Record<Column, SortDirection> = {
  name: 'ascending',
  numContacts: 'descending',
  ownerName: 'ascending',
  prospectingPhase: 'ascending',
  contactsWithRecommendations: 'descending',
} as const;

type SortDirection = 'ascending' | 'descending';

type SortSettings = {
  column: Column;
  direction: SortDirection;
};

const getInitialSortSettings = (): SortSettings => ({
  column: 'contactsWithRecommendations',
  direction: 'descending',
});

const reverseDirection = (direction: SortDirection): SortDirection => {
  if (direction === 'ascending') return 'descending';
  return 'ascending';
};

const getNewSortSettings = (prev: SortSettings, columnClicked: Column): SortSettings => {
  const { column, direction } = prev;

  let newDirection = initialSortDirection[columnClicked];
  if (column === columnClicked) newDirection = reverseDirection(direction);

  return {
    column: columnClicked,
    direction: newDirection,
  };
};

interface HeaderCallback {
  (prev: SortSettings): void;
}

interface CallbackConstructor {
  (column: Column): HeaderCallback;
}

interface SortFn {
  (accounts: ProspectingAccount[], sortSettings: SortSettings): ProspectingAccount[];
}

const sortAccounts: SortFn = (accounts, sortSettings) => {
  const { column, direction } = sortSettings;
  const sorted = accounts.slice().sort((a, b) => {
    const valA = a[column];
    const valB = b[column];
    if (typeof valA === 'number' && typeof valB === 'number') return valA - valB;
    if (typeof valA === 'string' && typeof valB === 'string') return valA.localeCompare(valB);
    /**
     * Code will never reach this point, because (at least at time of commit):
     *   - valA & valB will always be the same type
     *   - valA & valB are only of type number or string
     */
    return 0;
  });
  if (direction === 'descending') return sorted.reverse();
  return sorted;
};

const filterAccounts = (accounts: ProspectingAccount[], filters: ProspectingAccountFilters): ProspectingAccount[] => {
  let filteredAccounts = accounts;
  const { ownerIds, phases, recommendations, hasActivityWithOwnerIds, hasRecommendation } = filters;
  if (ownerIds?.length) {
    filteredAccounts = filteredAccounts.filter(a => ownerIds.indexOf(a.ownerId) >= 0);
  }
  if (hasActivityWithOwnerIds?.length) {
    filteredAccounts = filteredAccounts.filter(
      a => a.activityOwnerIds?.some(r => hasActivityWithOwnerIds.indexOf(r) >= 0),
    );
  }
  let getPreConversation = true;
  let getPostConversation = true;
  if (phases?.length) {
    filteredAccounts = filteredAccounts.filter(a => phases.indexOf(a.prospectingPhase) >= 0);
    getPreConversation = phases.includes('pre conversation');
    getPostConversation = phases.includes('post conversation');
  }
  if (recommendations?.length) {
    filteredAccounts = filteredAccounts.filter(a => recommendations?.some(r => {
      if (getPreConversation) return a.preConversationRecommendations?.includes(r);
      if (getPostConversation) return a.postConversationRecommendations?.includes(r);
      return a.recommendations?.includes(r);
    }));
  }
  if (hasRecommendation !== undefined) {
    if (hasRecommendation) filteredAccounts = filteredAccounts.filter(a => a.numRecommendations > 0);
    else filteredAccounts = filteredAccounts.filter(a => a.numRecommendations === 0);
  }
  return filteredAccounts;
};

const SortIcon = (props: { column: Column; sortSettings: SortSettings }): ReactElement => {
  const { column, sortSettings } = props;
  const {
    column: sortedColumn,
    direction,
  } = sortSettings;
  if (sortedColumn !== column) return <></>;
  const styles = { float: 'right' };
  if (direction === 'descending') return <Icon name="chevron down" style={styles} />;
  return <Icon name="chevron up" style={styles} />;
};

const NoAccountRow = (props: { status: 'active' | 'inactive' }): ReactElement => {
  const { status } = props;
  const teamId = useSelector(getSelectedTeamId);

  const inactiveQueryParams = teamId && status === 'inactive' ? { teamId } : skipToken;
  const noAccountQueryParams = teamId && status === 'active' ? { teamId } : skipToken;
  const {
    data: inactiveData,
    isFetching: inactiveIsLoading,
  } = prospectingApi.endpoints.getProspectingInactiveContacts.useQuery(inactiveQueryParams);
  const {
    data: noAccountData,
    isLoading: noAccountIsLoading,
  } = prospectingApi.endpoints.getProspectingContactsNoAccount.useQuery(noAccountQueryParams);
  const isLoading = status === 'inactive' ? inactiveIsLoading : noAccountIsLoading;
  const data = status === 'inactive' ? inactiveData : noAccountData;
  let numContacts: React.ReactNode = '-';
  let contactsWithRecommendations: React.ReactNode = '-';
  if (!isLoading && data !== undefined) {
    numContacts = data.contacts.length;
    contactsWithRecommendations = data.contacts.filter(c => c.recommendations?.length > 0).length;
  } else if (isLoading) {
    numContacts = (<Loader active inline size="mini" />);
  }
  const owner = '-';
  const phase = '-';
  return (
    <Table.Row className={css.noAccountRow}>
      <Table.Cell className={css.name} column="Account Name">
        <Link to={(location): string => `${location.pathname}/contacts?status=${status}`}>
          Contacts without Account
        </Link>
      </Table.Cell>
      <Table.Cell className={css.numContacts} column="Contacts">
        {numContacts}
      </Table.Cell>
      <Table.Cell className={css.numContacts} column="Recommendations">
        {contactsWithRecommendations}
      </Table.Cell>
      <Table.Cell className={css.ownerName} column="Owner">
        {owner}
      </Table.Cell>
      <Table.Cell className={css.prospectingPhase} column="Phase">
        {phase}
      </Table.Cell>
    </Table.Row>
  );
};

const getPagination = (onPageChange, totalPages, activePage): React.ReactNode => {
  if (totalPages <= 1) { return null; }
  return (
    <Menu pagination>
      <Pagination
        firstItem={{ content: <Icon name="angle double left" />, icon: true }}
        lastItem={{ content: <Icon name="angle double right" />, icon: true }}
        prevItem={{ content: <Icon name="angle left" />, icon: true }}
        nextItem={{ content: <Icon name="angle right" />, icon: true }}
        totalPages={totalPages}
        activePage={activePage}
        onPageChange={onPageChange}
      />
    </Menu>
  );
};

export const ProspectingAccountTable = (props: {
  teamId: number;
  status: 'active' | 'inactive';
  filters: ProspectingAccountFilters;
}): ReactElement => {
  const { teamId, status, filters } = props;
  const [sortSettings, setSort] = useState(getInitialSortSettings());
  const [activePage, setActivePage] = useState<number>(1);
  const activeQueryParams = status === 'active' ? { teamId } : skipToken;
  const inactiveQueryParams = status === 'inactive' ? { teamId } : skipToken;
  const {
    data: activeData,
    isLoading: activeIsLoading,
  } = prospectingApi.endpoints.getProspectingAccounts.useQuery(activeQueryParams);

  const {
    data: inactiveData,
    isLoading: inactiveIsLoading,
  } = prospectingApi.endpoints.getProspectingInactiveAccounts.useQuery(inactiveQueryParams);

  const data = status === 'active' ? activeData : inactiveData;
  const isLoading = status === 'active' ? activeIsLoading : inactiveIsLoading;

  if (isLoading) {
    return (<Segment className={css.accountList}><Loader active /></Segment>);
  }
  if (!data?.status) {
    const msg = data?.msg || 'Error retrieving insights';
    return (<Segment>{msg}</Segment>);
  }

  const callbackConstructor: CallbackConstructor = column => (): void => {
    setSort(prev => getNewSortSettings(prev, column));
  };
  const { accounts } = data;
  const filteredAccounts = filterAccounts(accounts, filters);
  const sortedAccounts = sortAccounts(filteredAccounts, sortSettings);
  const startSlice = ROWS_PER_PAGE * (activePage - 1);
  const endSlice = ROWS_PER_PAGE * activePage;
  const tableData: any[] = sortedAccounts.slice(startSlice, endSlice);
  let totalPages = 0;
  if (sortedAccounts && tableData[0]) {
    totalPages = Math.ceil(sortedAccounts.length / ROWS_PER_PAGE);
  }
  if (totalPages && activePage > totalPages) {
    setActivePage(1);
  }
  const onPageChange = (_, paginationData: PaginationProps): void => {
    let pageNum: number;
    if (typeof paginationData.activePage === 'string') {
      pageNum = Number.parseInt(paginationData.activePage, 10);
    } else {
      pageNum = paginationData.activePage || 1;
    }
    setActivePage(pageNum);
  };
  const pagination = getPagination(onPageChange, totalPages, activePage);
  const rows = tableData.map(a => {
    const {
      id,
      name,
      numContacts,
      contactsWithRecommendations,
      ownerName,
      prospectingPhase,
    } = a;
    const displayPhase = prospectingPhase === 'pre conversation' ? 'Pre Conversation' : 'Conversation';
    return (
      <Table.Row key={`account-row-${id}`}>
        <Table.Cell className={css.name}>
          <Link to={(location): string => `${location.pathname}/${id}/contacts`}>
            {name}
          </Link>
        </Table.Cell>
        <Table.Cell className={css.numContacts}>
          {numContacts}
        </Table.Cell>
        <Table.Cell className={css.contactsWithRecommendations}>
          {contactsWithRecommendations}
        </Table.Cell>
        <Table.Cell className={css.ownerName}>
          {ownerName}
        </Table.Cell>
        <Table.Cell className={css.prospectingPhase}>
          {displayPhase}
        </Table.Cell>
      </Table.Row>
    );
  });
  return (
    <Table sortable compact basic="very">
      <Table.Header className={css.tableHeader}>
        <Table.Row>
          <Table.HeaderCell onClick={callbackConstructor('name')} width={5}>
            Account Name
            <SortIcon column="name" sortSettings={sortSettings} />
          </Table.HeaderCell>
          <Table.HeaderCell onClick={callbackConstructor('numContacts')} width={2}>
            Total Contacts
            <SortIcon column="numContacts" sortSettings={sortSettings} />
          </Table.HeaderCell>
          <Table.HeaderCell
            className={css.numContacts}
            onClick={callbackConstructor('contactsWithRecommendations')}
            width={3}
          >
            Contacts With Recommendations
            <SortIcon column="contactsWithRecommendations" sortSettings={sortSettings} />
          </Table.HeaderCell>
          <Table.HeaderCell onClick={callbackConstructor('ownerName')} width={3}>
            Owner
            <SortIcon column="ownerName" sortSettings={sortSettings} />
          </Table.HeaderCell>
          <Table.HeaderCell onClick={callbackConstructor('prospectingPhase')} width={3}>
            Phase
            <SortIcon column="prospectingPhase" sortSettings={sortSettings} />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <NoAccountRow status={status} />
        {rows}
      </Table.Body>
      <Table.Footer className={css.containerPagination}>
        <Table.Row>
          <Table.HeaderCell colSpan="5" style={{ textAlign: 'center' }}>
            {pagination}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default ProspectingAccountTable;
