import React from 'react';
import { Icon, Popup, SemanticICONS } from 'semantic-ui-react';
import BSCustomIcon, { BSCustomIconName } from 'components/BSIcon/BSCustomIcon';
import { isBSCustomIconName } from 'components/BSIcon/utils';

import css from './BehaviorMetric.module.css';

interface BehaviorMetricProps {
  icon: SemanticICONS | BSCustomIconName;
  color?: string;
  backgroundColor?: string;
  label: string;
  value: string;
  popupText?: string | any;
}

const BehaviorMetric: React.FC<BehaviorMetricProps> = ({
  icon,
  backgroundColor = 'rgb(240,244,250)',
  color = 'rgb(31,96,211)',
  label,
  value,
  popupText,
}) => {
  const behaviorMetricNode = (
    <div className={css.container} style={{ backgroundColor }}>
      <div className={css.valueContainer} style={{ color }}>
        {
          isBSCustomIconName(icon)
            ? <BSCustomIcon name={icon} className={css.icon} />
            : <Icon name={icon} className={css.icon} />
        }
        {value}
      </div>
      <div className={css.labelContainer}>
        {label}
      </div>
    </div>
  );
  return popupText ? (
    <Popup
      basic
      className={css.popup}
      content={popupText}
      hoverable
      mouseLeaveDelay={500}
      position="top center"
      trigger={behaviorMetricNode}
    />
  ) : behaviorMetricNode;
};

export default BehaviorMetric;
