import React, { Component } from 'react';
import { Button, Dimmer } from 'semantic-ui-react';
import moment, { Moment } from 'moment';

import { Activity } from 'models/activity';
import ActivityGroup from './components/ActivityGroup';

import css from './ActivityHistory.module.css';

type DisplayModes = 'days' | 'weeks' | 'months';

interface ActivityHistoryProps {
  activityHistory: Array<Activity>;
  alwaysExpand: boolean;
  endDate: Moment;
  isLoading: boolean;
  loadingMessage?: string;
  onClick: Function;
  onLoadMore?: (event: any, data: any) => void;
  selectedId: number;
  startDate: Moment;
  view: string;
}

interface State {
  displayMode: DisplayModes;
}

export default class ActivityHistory extends Component<ActivityHistoryProps, State> {
  static defaultProps = {
    alwaysExpand: false,
    isLoading: false,
    onClick: (): void => { /* do nothing */ },
    onLoadMore: null,
    selectedId: null,
    view: '',
  };

  constructor(props) {
    super(props);

    this.state = { displayMode: 'days' };
  }

  groupActivities(history, start, end, displayMode: DisplayModes = 'days', condensed = true): Array<any> | null {
    if (start.isAfter(end)) {
      return null;
    }
    const historyCopy = [...history];
    const groupedHistory: Array<any> = [];
    const currentStart = moment(end).startOf(displayMode);
    const currentEnd = moment(end).endOf(displayMode);
    let activity = { ...historyCopy.shift() };
    let gapEnd = currentEnd.clone();
    let isEmptyGap = false;
    let i = 0;
    while (currentEnd.isSameOrAfter(start)) {
      const activities: Array<any> = [];
      while (activity.id) {
        const activityDate = moment(activity.activityDate);
        if (activityDate.isBetween(currentStart, currentEnd, undefined, '[)')) {
          activities.push(activity);
          activity = { ...historyCopy.shift() };
        } else if (activityDate.isAfter(currentEnd)) {
          activity = { ...historyCopy.shift() };
        } else {
          break;
        }
      }
      if (!condensed) {
        groupedHistory.push({
          startDate: currentStart.clone(),
          endDate: currentEnd.clone(),
          isGap: false,
          activities,
        });
      } else if (activities.length > 0) {
        if (isEmptyGap) {
          groupedHistory.push({
            startDate: currentStart.clone(),
            endDate: gapEnd.clone(),
            isGap: true,
            activities: [],
          });
          isEmptyGap = false;
        }
        groupedHistory.push({
          startDate: currentStart.clone(),
          endDate: currentEnd.clone(),
          isGap: false,
          activities,
        });
      } else if (!isEmptyGap) {
        isEmptyGap = true;
        gapEnd = currentEnd.clone();
      }
      currentEnd.subtract(1, displayMode);
      currentStart.subtract(1, displayMode);
      i += 1;
      if (i > 10000) break; // to prevent infinite loops
    }
    return groupedHistory;
  }

  renderActivity = (): React.ReactNode => {
    const { displayMode } = this.state;
    const {
      activityHistory,
      alwaysExpand,
      endDate: historyEndDate,
      startDate: historyStartDate,
      selectedId,
      onClick,
      view,
    } = this.props;

    const noActivityComponent = (<div className={css.textHeader}>No activity found in selected time range</div>);

    if (!activityHistory || activityHistory.length === 0) {
      return noActivityComponent;
    }
    const datediff = historyEndDate.diff(historyStartDate, 'days');
    const groupedHistory = this.groupActivities(activityHistory, historyStartDate, historyEndDate, displayMode);

    // this happens on the activity page where we load all activity. should probably not do that and make it
    // more like the user page
    if (!groupedHistory || groupedHistory.length === 0) {
      return noActivityComponent;
    }

    return groupedHistory.map((dateGroup: { activities: Array<any>; isGap: boolean; startDate: any; endDate: any }) => {
      const { startDate, endDate, isGap, activities } = dateGroup;
      const isSelectedActivityInGroup = activities.find((a: any): boolean => (selectedId === a.id)) !== undefined;

      const condensed = !isGap
        && (datediff > 3)
        && !isSelectedActivityInGroup;

      return (
        <ActivityGroup
          key={`${startDate.format('x')}${isGap ? 'g' : ''}`}
          activities={activities}
          alwaysExpand={alwaysExpand}
          condensed={condensed}
          displayMode={displayMode}
          startDate={startDate}
          endDate={endDate}
          onClick={onClick}
          selectedId={selectedId}
          view={view}
        />
      );
    });
  };

  render(): React.ReactNode {
    const { isLoading, loadingMessage, onLoadMore } = this.props;
    const loadMore = onLoadMore
      ? (<Button basic className={css.loadMore} onClick={onLoadMore}>Load More</Button>)
      : null;
    return (
      <div>
        {isLoading && loadingMessage ? (<div className={css.loadingMessage}>{loadingMessage}</div>) : ''}
        <Dimmer.Dimmable className={css.activityTimeline} blurring dimmed={isLoading}>
          <Dimmer active={isLoading} inverted />
          {this.renderActivity()}
          {loadMore}
        </Dimmer.Dimmable>
      </div>
    );
  }
}
