import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export const AccountAppLink = (props: {
  account: {
    id?: number;
    name?: string;
  };
}): ReactElement | null => {
  const { account: { id, name } } = props;
  if (!id || !name) return null;
  return (<Link to={`/app/account/${id}/activity`}>{name}</Link>);
};

export default AccountAppLink;
