import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import { Icon } from 'semantic-ui-react';

import allActions from '../actions/app-actions';

import css from './App.module.css';

interface Props {
  actions: any;
  loadingMessage: string;
  location: { search: string };
  isLoggedIn: boolean;
}

class Callback extends Component<Props> {
  componentDidMount(): void {
    const { location, actions } = this.props;
    const { search } = location;
    const params = qs.parse(search);
    actions.auth(params.code);
  }

  render(): React.ReactNode {
    const { loadingMessage } = this.props;
    return (
      <div className={css.App}>
        <div className={css.loadingContainer}>
          <Icon name="sync" loading />
          {loadingMessage}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: any) => ({ isLoggedIn: state.app.isLoggedIn, loadingMessage: state.app.loadingMessage }),
    dispatch => ({ actions: bindActionCreators(allActions.app, dispatch) }),
  )(Callback),
);
