import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Container,
  Dropdown,
  DropdownItemProps,
  Form,
  Header,
  Icon,
  Popup,
  Tab,
  Table,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import Calendar from 'react-calendar';

import allActions, { bindCombinedActions } from 'actions';
import api from 'lib/api';
import { formatDate } from 'lib/util';
import { Notification } from 'models/recommendation';
import LoadingDimmer from 'components/LoadingDimmer/LoadingDimmer';
import { hasPermission } from 'components/AccessControl';

import RecommendationRenderer from '../components/RecommendationRenderer';

import css from './RecommendationAdmin.module.css';

interface Props {
  actions: any;
  history: any;
  match: any;
  users: any[];
  recommendationTags: string[];
  isTagsLoaded: boolean;
  selectedOrganizationId: number;
}

interface State {
  recommendationId: number;
  title: string;
  body: string;
  tags: string[];
  tagOptions: Array<DropdownItemProps>;
  userIds: number[];
  notificationDate: Date;
  notificationTime: string;
  notifications: Notification[];
  showDateTimePicker: boolean;
  isSaving: boolean;
  error?: string;
}

class RecommendationAdmin extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { recommendationTags } = props;

    const recommendationId = parseInt(props.match.params.recommendationId, 10);

    const tagOptions = recommendationTags?.map(t => ({ text: t, value: t })) || [];
    this.state = {
      recommendationId,
      title: '',
      body: '',
      tags: [],
      tagOptions,
      userIds: [],
      notificationDate: new Date(),
      notificationTime: '9:00 AM',
      notifications: [],
      showDateTimePicker: false,
      isSaving: false,
    };
  }

  componentDidMount(): void {
    const { actions, history, selectedOrganizationId, users, isTagsLoaded } = this.props;
    if (!selectedOrganizationId) {
      history.replace('/app/recommendations/admin');
      return;
    }
    if (users.length === 0) {
      actions.admin.fetchUsersByOrg(selectedOrganizationId, { orderBy: 'last_name, first_name' });
    }
    if (!isTagsLoaded) {
      actions.admin.fetchRecommendationTagsByOrg(selectedOrganizationId);
    }
    this.loadData();
  }

  componentDidUpdate(prevProps): void {
    const { recommendationTags, isTagsLoaded } = this.props;
    if (!prevProps.isTagsLoaded && isTagsLoaded) {
      const tagOptions = recommendationTags?.map(t => ({ text: t, value: t })) || [];
      ((): void => { this.setState({ tagOptions }); })();
    }
  }

  loadData = async (): Promise<void> => {
    const { recommendationId } = this.state;
    if (!recommendationId) return;
    const response = await api.getRecommendationForAdmin(recommendationId, { fetchUser: true });
    if (response.status) {
      const { recommendation, notifications } = response;
      const { title, body, tags } = recommendation;
      const tagOptions = tags?.map(t => ({ text: t, value: t })) || [];
      this.setState({ title, body, tags, notifications, tagOptions });
    } else {
      toast.error(`could not load recommendation with id ${recommendationId}`);
    }
  };

  handleAddition = (_, data): void => {
    const { value } = data;
    this.setState(prevState => ({ tagOptions: [{ text: value, value }, ...prevState.tagOptions] }));
  };

  handleOnChange = (field: string): any => (_, data): void => {
    const newState = {};
    newState[field] = data.value;
    this.setState(newState);
  };

  handleCancel = (): void => {
    const { history } = this.props;
    history.goBack();
  };

  handleCalendarChange = (value: Date | Date[]): void => {
    if (Array.isArray(value)) {
      this.setState({ notificationDate: value[0], showDateTimePicker: false });
    } else {
      this.setState({ notificationDate: value, showDateTimePicker: false });
    }
  };

  handleSave = async (): Promise<void> => {
    const { actions, history, selectedOrganizationId } = this.props;
    const { recommendationId, title, body, tags, userIds, notificationDate, notificationTime } = this.state;

    const notificationDateTime = moment(`${moment(notificationDate).format('YYYY-MM-DD')} ${notificationTime}`);
    this.setState({ isSaving: true });
    await api.saveRecommendation({
      id: recommendationId,
      organizationId: selectedOrganizationId,
      title,
      body,
      tags,
      userIds,
      notificationDate: notificationDateTime,
    });
    this.setState({ isSaving: false });
    toast(`Saved recommendation ${title}`);
    actions.admin.fetchRecommendationTagsByOrg(selectedOrganizationId);
    history.push('/app/recommendations/admin');
  };

  handleDelete = async (notificationId: number): Promise<void> => {
    const { selectedOrganizationId: organizationId } = this.props;
    await api.deleteRecommendationNotification(notificationId, organizationId);
    this.loadData();
  };

  uploadImage = async (e): Promise<void> => {
    const file = e.target.files[0];
    const resp = await api.uploadImage(file);
    if (resp.status) {
      const imgUrl = resp.publicUrl;
      this.setState(state => ({ ...state, body: `${state.body}\n\n![${file.name}](${imgUrl})` }));
    }
  }

  uploadFile = async (e): Promise<void> => {
    const file = e.target.files[0];
    const resp = await api.uploadFile(file);
    if (resp.status) {
      const imgUrl = resp.publicUrl;
      this.setState(state => ({ ...state, body: `${state.body}\n\n[${file.name}](${imgUrl})` }));
    }
  }

  render(): React.ReactNode {
    const { users, match } = this.props;
    const {
      title,
      body,
      tags,
      tagOptions,
      notifications,
      userIds,
      notificationDate,
      notificationTime,
      showDateTimePicker,
      isSaving,
    } = this.state;

    const deliveredUserIds = notifications.map((n: Notification): number => n.userId);

    const userOptions: DropdownItemProps[] = users
      .filter(
        u => !deliveredUserIds.includes(u.id)
        && (hasPermission(u.permission, 'manager') || u.isTracked)
        && u.active,
      ).map((u: any): any => ({
        key: u.id,
        value: u.id,
        text: u.name,
      }));

    const timeOptions: DropdownItemProps[] = [];
    for (let hour = 0; hour < 24; hour += 1) {
      const hourOption = moment({ hour }).format('h:mm A');
      const halfhourOption = moment({ hour, minute: 30 }).format('h:mm A');
      timeOptions.push({
        key: hourOption,
        value: hourOption,
        text: hourOption,
      });
      timeOptions.push({
        key: halfhourOption,
        value: halfhourOption,
        text: halfhourOption,
      });
    }

    const panes = [
      {
        menuItem: 'Edit',
        render: (): React.ReactNode => (
          <Tab.Pane>
            <LoadingDimmer active={isSaving} />
            <Form>
              <Form.Field>
                <label>Title</label>
                <Form.Input value={title} onChange={this.handleOnChange('title')} />
              </Form.Field>
              <Form.Field>
                <label>Body</label>
                <Form.TextArea
                  value={body}
                  onChange={this.handleOnChange('body')}
                  rows={12}
                />
              </Form.Field>
              <Form.Field>
                <label>Tags</label>
                <Form.Dropdown
                  allowAdditions
                  search
                  selection
                  multiple
                  onAddItem={this.handleAddition}
                  onChange={this.handleOnChange('tags')}
                  options={tagOptions}
                  value={tags}
                />
              </Form.Field>
              <Form.Group>
                <Form.Field>
                  <label>Image Upload</label>
                  <input type="file" onChange={this.uploadImage} />
                </Form.Field>
                <Form.Field>
                  <label>File Upload</label>
                  <input type="file" onChange={this.uploadFile} />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>Deliver to</label>
                  <Form.Dropdown
                    search
                    selection
                    multiple
                    options={userOptions}
                    onChange={this.handleOnChange('userIds')}
                    value={userIds}
                  />
                </Form.Field>
                <Form.Field>
                  <label>at Date</label>
                  <Form.Dropdown
                    className={css.calendarDropdown}
                    onFocus={(): void => { this.setState({ showDateTimePicker: true }); }}
                    onBlur={(): void => { this.setState({ showDateTimePicker: false }); }}
                    text={formatDate(notificationDate, 'MMM DD, YYYY')}
                    open={showDateTimePicker}
                    selection
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <Calendar
                          calendarType="US"
                          onChange={this.handleCalendarChange}
                          value={notificationDate}
                        />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Form.Dropdown>
                </Form.Field>
                <Form.Field>
                  <label>Time</label>
                  <Form.Dropdown
                    selection
                    options={timeOptions}
                    onChange={this.handleOnChange('notificationTime')}
                    value={notificationTime}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Button primary onClick={this.handleSave} disabled={isSaving}>Save</Form.Button>
                <Form.Button onClick={this.handleCancel}>Cancel</Form.Button>
              </Form.Group>
            </Form>
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Preview',
        render: (): React.ReactNode => (
          <Tab.Pane>
            <div className={css.header}>
              <div>
                <h1 title={title}>{title}</h1>
              </div>
              <div className={css.controls}>
                <div>
                  {formatDate(notificationDate)}
                </div>
              </div>
            </div>
            <RecommendationRenderer
              body={body}
              tags={tags}
            />
          </Tab.Pane>
        ),
      },
    ];

    const defaultActiveIndex = match.params.recommendationId ? 1 : 0;

    return (
      <Container className={css.editContainer}>
        <Tab panes={panes} defaultActiveIndex={defaultActiveIndex} />
        {notifications.length
          ? <NotificationSection notifications={notifications} onDelete={this.handleDelete} />
          : undefined}
      </Container>
    );
  }
}

export default connect(
  (state: any) => ({
    users: state.admin.users,
    selectedOrganizationId: state.admin.selectedOrganizationId,
    recommendationTags: state.admin.recommendationTags,
    isTagsLoaded: state.admin.isTagsLoaded,
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(RecommendationAdmin);

interface NotificationSectionProps {
  notifications: Array<Notification>;
  onDelete: Function;
}
const NotificationSection: React.FC<NotificationSectionProps> = ({ notifications, onDelete }) => {
  const check = (<Icon name="check" />);
  const notificationRows = notifications.map((n: Notification): React.ReactNode => (
    <Table.Row key={n.id} className={css.notificationRow}>
      <Table.Cell>{n.userName}</Table.Cell>
      <Table.Cell>{formatDate(n.notificationDate)}</Table.Cell>
      <Table.Cell>{formatDate(n.seenAt)}</Table.Cell>
      <Table.Cell>{formatDate(n.resolvedAt)}</Table.Cell>
      <Table.Cell textAlign="center">{n.helpful ? check : undefined}</Table.Cell>
      <Table.Cell textAlign="center">
        <Popup
          content={n.feedback}
          trigger={n.feedback ? check : undefined}
        />
      </Table.Cell>
      <Table.Cell textAlign="center">
        <div className="link brand" onClick={(): void => { onDelete(n.id); }}><Icon name="x" /></div>
      </Table.Cell>
    </Table.Row>
  ));

  return (
    <>
      <Header>Notifications</Header>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Delivery Date</Table.HeaderCell>
            <Table.HeaderCell>Seen</Table.HeaderCell>
            <Table.HeaderCell>Resolved</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Helpful</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Feedback</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Unsend</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {notificationRows}
        </Table.Body>
      </Table>
    </>
  );
};
