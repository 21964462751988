import { useEffect } from 'react';

/**
 * Listens for key events
 * @param keyCode for more info about key codes go to: https://keycode.info/
 * @param onKeyPress
 */
function useKeyboardListener(keyCode: number, onKeyPress: () => void): void {
  useEffect(() => {
    const handleOnKeyPress = (event: KeyboardEvent): void => {
      if (keyCode === event.keyCode) {
        onKeyPress();
      }
    };

    document.addEventListener('keydown', handleOnKeyPress);
    return (): void => {
      document.removeEventListener('keydown', handleOnKeyPress);
    };
  }, [onKeyPress, keyCode]);
}

export default useKeyboardListener;
