import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export const ContactAppLink = (props: {
  contact: {
    id?: number;
    name?: string;
  };
}): ReactElement | null => {
  const { contact: { id, name } } = props;
  if (!id || !name) return null;
  return (<Link to={`/app/contact/${id}/activity`}>{name}</Link>);
};

export default ContactAppLink;
