import React, { Component } from 'react';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';

import { makeColorGenerator } from 'lib/util';
import bslegend from 'lib/bslegend';
import { OpportunityStage } from 'models/opportunity';
import { User } from 'models/user';

export interface DataSet {
  label?: string;
  data: number[];
  backgroundColor?: string;
  stack?: string;
}

export interface PipelineCreatedFilters {
  ownerIds?: number[];
  stageNames?: string[];
  opportunityTypes?: string[];
  displayValue?: 'amount' | 'opportunityCnt';
  groupBy?: 'stageName' | 'ownerId';
  custom?: { [key: string]: Array<string|number> };
}

interface Props {
  pipelineData: any;
  isLoading: boolean;
  isMinimized: boolean;
  stages?: OpportunityStage[];
  userMap: Array<User>;
  filters?: PipelineCreatedFilters;
  timeUnit?: 'day' | 'month' | 'week';
}
interface State {
  labels?: string[];
  datasets: DataSet[];
}

const formatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export default class PipelineCreatedChart extends Component<Props, State> {
  canvas;

  chart;

  constructor(props) {
    super(props);
    this.canvas = React.createRef();
    const { datasets, labels } = this.convertPipelineDataToDataSets(props.pipelineData);
    this.state = {
      datasets,
      labels,
    };
    Chart.register(...registerables);
  }

  componentDidMount(): void {
    this.renderChart();
  }

  componentDidUpdate(prevProps): void {
    const { pipelineData, userMap, filters, isLoading, isMinimized } = this.props;
    if (
      !isLoading
      && (prevProps.pipelineData !== pipelineData
        || prevProps.isMinimized !== isMinimized
        || prevProps.userMap.length !== userMap.length
        || prevProps.filters !== filters)
    ) {
      ((): void => {
        const { datasets, labels } = this.convertPipelineDataToDataSets(pipelineData);
        this.setState({ datasets, labels });
      })();
    }
    this.renderChart();
  }

  convertPipelineDataToDataSets = (pipelineData): { datasets: DataSet[]; labels: string[] } => {
    const { filters, userMap, isMinimized, stages } = this.props;

    // TODO: Make colors consistent so stage names have the same color between
    // amount and opportunityCnt views
    const colorGenerator = makeColorGenerator();

    const labels: string[] = [];
    const keyedDatasets: any = [];
    const displayValue = filters?.displayValue || 'amount';
    const groupBy = filters?.groupBy || 'stageName';

    const datalabels = isMinimized
      ? { display: false }
      : {
        align: 'end',
        anchor: 'end',
        color: 'rgba(0, 0, 0, 0.77)',
        display: false,
        formatter: (val): string => (displayValue === 'amount' ? formatter.format(val) : val),
        font: { style: 'bold' },
      };

    Object.keys(pipelineData).forEach((key): void => {
      const dayData = pipelineData[key];
      labels.push(key);

      dayData.filter(data => {
        let showRow = true;
        if (filters?.ownerIds?.length) {
          showRow = showRow && filters.ownerIds.includes(data.ownerId);
        } else {
          const userIds = userMap.map(u => u.id);
          showRow = showRow && userIds.includes(data.ownerId);
        }
        if (filters?.stageNames?.length) {
          showRow = showRow && filters.stageNames.includes(data.masterLabel);
        }
        if (filters?.opportunityTypes?.length) {
          showRow = showRow && filters.opportunityTypes.includes(data.type);
        }
        if (filters?.custom) {
          Object.entries(filters.custom).forEach(([k, values]) => {
            if (values.length) {
              showRow = showRow && values.includes(data.customData[k]);
            }
          });
        }
        return showRow;
      }).forEach(data => {
        // assumes data is sorted by date order
        const labelField = groupBy === 'stageName' ? 'masterLabel' : groupBy;
        const datasetLabel = data[labelField];
        const value: number = displayValue === 'opportunityCnt' ? 1 : data.amount;

        if (value === 0) return;
        if (keyedDatasets[datasetLabel]) {
          if (keyedDatasets[datasetLabel].data[key]) {
            keyedDatasets[datasetLabel].data[key] += value;
          } else {
            keyedDatasets[datasetLabel].data[key] = value;
          }
        } else {
          let label = datasetLabel;
          let backgroundColor;
          let sortOrder;
          if (groupBy === 'ownerId') {
            const user = userMap.find(u => u.id === datasetLabel);
            label = user?.name || datasetLabel;
            backgroundColor = colorGenerator();
          } else {
            const stage = stages?.find(s => s.masterLabel === datasetLabel);
            backgroundColor = stage?.color || colorGenerator();
            sortOrder = stage?.sortOrder;
          }
          keyedDatasets[datasetLabel] = {
            backgroundColor,
            borderSkipped: false,
            borderRadius: 2.5,
            data: { [key]: value },
            datalabels,
            label,
            sortOrder,
          };
        }
      });
    });
    const sortFn = (prev, curr): number => {
      if (curr.sortOrder) return prev.sortOrder - curr.sortOrder;
      return (curr.label < prev.label) ? 1 : -1;
    };
    const datasets: DataSet[] = Object.values(keyedDatasets);
    datasets.sort(sortFn);
    return {
      datasets,
      labels,
    };
  };

  renderChart = (): void => {
    const { datasets, labels } = this.state;
    const { isMinimized, timeUnit } = this.props; // Filters

    if (this.chart) this.chart.destroy();

    const ctx = this.canvas.current.getContext('2d');
    /* eslint-disable object-curly-newline */
    const chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets,
      },
      options: {
        interaction: {
          mode: 'index',
        },
        scales: {
          xAxis: {
            type: 'time',
            time: {
              unit: timeUnit || 'day',
              minUnit: 'day',
            },
            grid: { display: false },
            stacked: true,
          },
          yAxis: {
            beginAtZero: true,
            stacked: true,
            ticks: {
              // Formatter of Y, we need to analyze our rules according to ammounts
              callback(label: any): string {
                // Include a dollar sign in the ticks
                if (label >= 1000000) {
                  return `$${label / 1000000}M`;
                } if (label >= 1000) {
                  return `$${label / 1000}k`;
                }
                return `$${label}`;
              },
            },
          },
        },
        plugins: {
          title: {
            align: 'start',
          },
          ChartDataLabels,
          tooltip: {
            bodySpacing: 5,
            mode: 'x',
            intersect: true,
            position: 'nearest',
            padding: 7,
            backgroundColor: '#5A5B5D',
            callbacks: {
              title: (tooltipItems): string => moment(tooltipItems[0].label).format('MMM DD, YYYY'),
              label: (context): string => `${context.dataset.label}: ${formatter.format(context.parsed.y)}`,
              footer(tooltipItems): string {
                let total = 0;
                tooltipItems.forEach(item => {
                  const value = item.parsed.y;
                  total += value;
                });
                const formattedTotal = formatter.format(total);
                return `Total: ${formattedTotal}`;
              },
            },
          },
          legend: {
            display: false,
          },
          bslegend: {
            display: !isMinimized,
            containerID: 'pipeline-created-chart',
            flexDirection: 'column',
            borderWidth: 1,
          },
        },
        title: {
          display: false,
          text: 'Creation Over Time',
          align: 'start',
          position: 'top',
        },
        responsive: true,
        elements: { line: { tension: 0 } },
      },
      plugins: [bslegend],
    });
    /* eslint-enable object-curly-newline */
    this.chart = chart;
  };

  render(): React.ReactNode {
    const { isMinimized } = this.props;
    const style: React.CSSProperties = {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    };
    return (
      <div style={style}>
        <div style={{ width: isMinimized ? '100%' : '80%' }}><canvas ref={this.canvas} /></div>
        <div id="pipeline-created-chart" />
      </div>
    );
  }
}
