import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Segment, Button } from 'semantic-ui-react';

import allActions, { bindCombinedActions } from 'actions';

import BSIcon from 'components/BSIcon';

import css from './SalesforceSync.module.css';
import SyncCronSelector from './SyncCronSelector';
import { ActivityIcons } from '../../../../models/activity';

interface Props {
  actions: any;
  isLoadingTaskmap: boolean;
  taskmap: {string: ActivityIcons};
  isUpdatingSettings: boolean;
}

interface State{
  activityCron: string | undefined;
}

// TODO:
/*
  - make the taskmap editable
*/

class SalesforceSync extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { activityCron: undefined };
  }

  componentDidMount(): void {
    const { actions, isLoadingTaskmap, taskmap } = this.props;
    if (!isLoadingTaskmap && !taskmap) {
      actions.settings.getSettingsTaskmap();
    }
  }

  handleActivityCronChange = (cron: string): void => {
    this.setState({ activityCron: cron });
  }

  handleSaveSettings = (): void => {
    const { actions } = this.props;
    const { activityCron } = this.state;

    actions.organization.setOrganizationSettings({ activitySyncSchedule: activityCron });
  }

  render(): React.ReactNode {
    const { isLoadingTaskmap, taskmap, isUpdatingSettings } = this.props;
    const { activityCron } = this.state;
    if (isLoadingTaskmap) {
      return (<>Loading...</>);
    }
    const iconMap = Object.entries(taskmap).map(
      ([activityType, activityIcon]) => (
        <div key={activityType} className={css.mapRow}>
          <BSIcon activityIcon={activityIcon} activityType={activityType} circular bordered />
          {' '}
          {activityType}
        </div>
      ),
    );
    return (
      <Segment className={css.info}>
        <Header>Salesforce Sync Settings</Header>
        {false && <Header>Activity Types</Header>}
        {false && iconMap}
        <Header>Activity Sync Frequency</Header>
        <SyncCronSelector initialValue={activityCron} onChange={this.handleActivityCronChange} />
        <div className={css.saveBtnContainer}>
          <Button
            className={css.saveBtn}
            onClick={this.handleSaveSettings}
            disabled={activityCron === undefined}
            loading={isUpdatingSettings}
          >
            Save
          </Button>
        </div>
      </Segment>
    );
  }
}

export default connect(
  (state: any) => ({
    isLoadingTaskmap: state.settings.isLoadingTaskmap,
    isUpdatingSettings: state.organization.isUpdatingSettings,
    taskmap: state.settings.taskmap,
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(SalesforceSync);
