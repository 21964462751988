import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';

import ContactActivity from './ContactActivity';
import { RootState } from '../../../store';

interface Props {
  selectedTeamId: number | undefined;
}

interface State {}

class ContactHealthRoute extends Component<Props, State> {
  render(): React.ReactNode {
    const { selectedTeamId } = this.props;
    if (!selectedTeamId) {
      return (<Segment>Please select a team to view</Segment>);
    }
    return (
      <Switch>
        <Route path="/app/contact/:contactId/activity" component={ContactActivity} />
      </Switch>
    );
  }
}

export default connect(
  (state: RootState): Partial<Props> => ({ selectedTeamId: state.team.selectedTeamId }),
  null,
)(ContactHealthRoute);
