import React, { useState } from 'react';
import { DropdownItemProps } from 'semantic-ui-react';

import { FunnelStats } from 'models/funnel';

import FunnelLevel from './components/FunnelLevel';
import FunnelLegend from './components/FunnelLegend';

import css from './styles.module.css';
import FunnelDetailModal from './components/FunnelDetailModal';

export const funnelLabelMap = {
  working: 'Working',
  inactive: 'Inactive',
  hasRecommendation: 'Has recommendation',
  won: 'Won',
  lost: 'Lost',
};

interface Props {
  label?: string;
  stats: FunnelStats[];
  expanded?: boolean;
}

const Funnel: React.FC<Props> = ({ stats, expanded = false }) => {
  const [open, setOpen] = useState(false);
  const [modalLevel, setModalLevel] = useState<FunnelStats>();
  const [modalFilter, setModalFilter] = useState();

  const numLevels = stats.length;

  let i = 0;
  const step = 80 / (numLevels);
  const levels = stats.map(level => {
    const topOffset = Math.floor(i * step) / 2;
    const bottomOffset = (i === numLevels - 1) ? topOffset : Math.floor((i + 1) * step) / 2;

    const alpha = Math.floor(20 + ((i * 80) / (numLevels - 1))) / 100;

    const hasDetails = !!level.details?.length;
    const onClick = hasDetails ? (clickedLevel, filter = undefined): void => {
      setModalLevel(clickedLevel);
      setModalFilter(filter);
      setOpen(true);
    } : undefined;

    i += 1;
    return (
      <FunnelLevel
        key={level.label}
        alpha={alpha}
        expanded={expanded}
        level={level}
        topOffset={topOffset}
        bottomOffset={bottomOffset}
        onClick={onClick}
      />
    );
  });

  const breakdownSet = new Set<string>();
  stats.forEach(l => {
    Object.keys(l.breakdown).forEach(key => breakdownSet.add(key));
  });
  const breakdowns = Array.from<string>(breakdownSet);
  const breakdownOptions: DropdownItemProps[] = breakdowns.map(b => ({
    key: b,
    text: funnelLabelMap[b] || b,
    value: b,
  }));

  return (
    <>
      <div className={css.funnel}>
        {levels}
      </div>
      {expanded ? <FunnelLegend breakdownOptions={breakdownOptions} /> : null}
      <FunnelDetailModal
        open={open}
        level={modalLevel}
        breakdownOptions={breakdownOptions}
        filter={modalFilter}
        setFilter={setModalFilter}
        onOpen={(): void => setOpen(true)}
        onClose={(): void => setOpen(false)}
      />
    </>
  );
};

export default Funnel;
