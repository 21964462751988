import { connect } from 'react-redux';
import React from 'react';
import { isEmpty } from 'lodash';

const RecommendationBreadcrumb = (state): React.ReactElement => {
  if (isEmpty(state.recommendation)) {
    return <>Loading...</>;
  }
  return (
    <span>
      {state.recommendation.title}
    </span>
  );
};

export default connect(
  (state: any) => ({ recommendation: state.recommendation.currentRecommendation }),
)(RecommendationBreadcrumb);
