import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Grid, GridColumn, GridRow, Loader, Segment } from 'semantic-ui-react';

import { selectAppDateSelection } from 'features/App/appSlice';
import { getSelectedTeamId } from 'selectors/team';
import { getIsoDatesFromTimePeriod } from 'features/DatePicker/datePickerFunctions';
import { selectUserInfoId } from 'selectors/user';
import { RootState } from 'store';

import { UserPipelineWidget } from './PipelineWidget/UserPipelineWidget';
import { ActivityWidget } from './ActivityWidget/ActivityWidget';
import { Notifications } from './Notifications/Notifications';

import { BasicStats } from './BasicStats/BasicStats';

export function UserScorecard(): ReactElement {
  const timePeriod = useSelector(selectAppDateSelection);
  const { start: startDateIso, end: endDateIso } = getIsoDatesFromTimePeriod(timePeriod);

  const userId = useSelector(selectUserInfoId);
  const teamId = useSelector(getSelectedTeamId);
  const isLoadingTeamSettings = useSelector((state: RootState) => state.team.isLoadingTeamSettings);

  if (!userId) return <>Unable to get userID</>;
  if (!teamId) return <>Unable to get teamID</>;

  if (isLoadingTeamSettings) {
    const loadingSegment = (<Segment><Loader active /></Segment>);
    return (
      <Grid>
        <GridRow columns={1}>
          <GridColumn>
            <BasicStats userId={userId} teamId={teamId} startDateIso={startDateIso} endDateIso={endDateIso} />
          </GridColumn>
        </GridRow>
        <GridRow columns={3} stretched>
          <GridColumn width={6}>
            {loadingSegment}
          </GridColumn>
          <GridColumn width={6}>
            {loadingSegment}
          </GridColumn>
          <GridColumn width={4}>
            {loadingSegment}
          </GridColumn>
        </GridRow>
      </Grid>
    );
  }

  return (
    <Grid>
      <GridRow columns={1}>
        <GridColumn>
          <BasicStats userId={userId} teamId={teamId} startDateIso={startDateIso} endDateIso={endDateIso} />
        </GridColumn>
      </GridRow>
      <GridRow columns={3} stretched>
        <GridColumn width={6}>
          <ActivityWidget userId={userId} teamId={teamId} startDateIso={startDateIso} endDateIso={endDateIso} />
        </GridColumn>
        <GridColumn width={6}>
          <UserPipelineWidget userId={userId} teamId={teamId} startDateIso={startDateIso} endDateIso={endDateIso} />
        </GridColumn>
        <GridColumn width={4}>
          <Notifications userId={userId} teamId={teamId} startDateIso={startDateIso} endDateIso={endDateIso} />
        </GridColumn>
      </GridRow>
    </Grid>
  );
}

export default UserScorecard;
