import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { debounce } from 'lodash';

import { prospectingApi } from 'features/Api';

interface Props {}

const ProspectingSearchBar: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [searchItems, setSearchItems] = useState<DropdownItemProps[]>([]);

  const [searchProspecting, { isLoading }] = prospectingApi.endpoints.search.useLazyQuery();

  const navigate = bindActionCreators((path: string) => push(path), dispatch);

  const debouncedSearch = debounce(async search => {
    const resp = await searchProspecting({ search });
    const list: DropdownItemProps[] = resp.data?.results?.map(r => ({
      key: `${r.accountId}${r.contactId}`,
      text: r.name,
      value: `${r.accountId}-${r.contactId}`,
    })) || [];
    setSearchItems(list);
  }, 500);

  const handleSearch = (_, data): void => {
    const { searchQuery: searchText } = data;
    if (searchText?.length > 1) {
      debouncedSearch(searchText);
    }
  };

  const handleItemSelect = (_, data): void => {
    const [accountId, contactId] = data.value.split('-');

    if (contactId && contactId !== 'undefined') {
      if (accountId !== 'undefined') {
        navigate(`/app/prospecting/${accountId}/contacts/${contactId}`);
      } else {
        navigate(`/app/prospecting/contacts/${contactId}`);
      }
    } else {
      navigate(`/app/prospecting/${accountId}/contacts`);
    }
  };

  return (
    <Dropdown
      deburr
      icon="search"
      loading={isLoading}
      onSearchChange={handleSearch}
      onChange={handleItemSelect}
      options={searchItems}
      placeholder="Search..."
      search
      selection
    />
  );
};

export default ProspectingSearchBar;
