import { useState, useEffect } from 'react';

export default function useOnFlagChange(flag: any, callback: () => void, cleanup?: () => void): void {
  const [prevFlagValue, setPrevFlagValue] = useState(flag);
  useEffect(() => {
    if (prevFlagValue !== flag) {
      callback();
      setPrevFlagValue(flag);
    }
    return cleanup;
  }, [flag, callback, prevFlagValue, cleanup]);
}
