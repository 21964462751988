import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';

import AccountActivity from './AccountActivity';
import AccountHealthDash from './AccountHealthDash';
import { RootState } from '../../../store';
import { AccountHealthPage } from './AccountHealthPage/AccountHealthPage';

interface Props {
  selectedTeamId: number | undefined;
}

interface State {}

class AccountHealthRoute extends Component<Props, State> {
  render(): React.ReactNode {
    const { selectedTeamId } = this.props;
    if (!selectedTeamId) {
      return (<Segment>Please select a team to view</Segment>);
    }
    return (
      <Switch>
        <Route path="/app/account/:accountId/activity" component={AccountActivity} />
        <Route path="/app/account/:accountId/health" component={AccountHealthPage} />
        <Redirect from="/app/account/:accountId" to="/app/account/:accountId/health" />
        <Route path="/app/account" component={AccountHealthDash} />
      </Switch>
    );
  }
}

export default connect(
  (state: RootState): Partial<Props> => ({ selectedTeamId: state.team.selectedTeamId }),
  null,
)(AccountHealthRoute);
