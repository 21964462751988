import { createSlice } from '@reduxjs/toolkit';
import { DateSelectionSliceState } from '../DatePicker/datePickerTypes';
import { createDateSelectionSliceSelectorsAndReducers } from '../DatePicker/datePickerFunctions';

const SLICE_NAME = 'account_';

export interface AccountState extends DateSelectionSliceState { }

const initialState: AccountState = {
  dateSelection: {
    type: 'relative',
    start: { days: -14 },
    end: { days: 0 },
  },
};

const selectorsAndReducer = createDateSelectionSliceSelectorsAndReducers(SLICE_NAME);

const {
  setDateSelectionReducer: setDateSelectionFn,
  setAbsoluteStartEndDatesReducer: setAbsoluteStartEndDatesFn,
  setDateSelectionAroundDateReducer: setDateSelectionAroundDateFn,
} = selectorsAndReducer;

export const accountSlice = createSlice({
  name: SLICE_NAME, // todo: once state.account is migrated, rename this without trailing _
  initialState,
  reducers: {
    setAccountDateSelection: setDateSelectionFn,
    setAccountDateSelectionWithStartEndDates: setAbsoluteStartEndDatesFn,
    setAccountDateSelectionAroundDate: setDateSelectionAroundDateFn,
  },
});

export const {
  selectDateSelection: selectAccountDateSelection,
  selectStartAndEndDates: selectAccountStartAndEndDates,
} = selectorsAndReducer;

export const {
  setAccountDateSelectionWithStartEndDates,
  setAccountDateSelectionAroundDate,
  setAccountDateSelection,
} = accountSlice.actions;
