import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

interface DimmerLoaderProps {
  active: boolean;
  inverted?: boolean;
}

const DimmerLoader: React.FC<DimmerLoaderProps> = ({ active, inverted }: DimmerLoaderProps): React.ReactElement => (
  <Dimmer active={active} inverted={inverted}>
    <Loader inline="centered" />
  </Dimmer>
);

DimmerLoader.defaultProps = {
  inverted: true,
};

export default DimmerLoader;
