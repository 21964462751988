import remark from 'remark';
import strip from 'strip-markdown';
import { DateTime } from 'luxon';
import moment, { Moment } from 'moment';

import { GoalPeriod } from 'features/Api/Goals';
import { ActivityColorsRgb, ActivityIcons } from '../models/activity';


const DEFAULT_DAYS_AGO = 14;

export function formatDate(rawDate, fmt = 'MMM DD, YYYY h:mma', today = false): string | undefined {
  const m = moment(rawDate);
  if (!m.isValid()) return 'Never';
  if (today && moment().isSame(m, 'day')) return 'Today';
  return m.format(fmt);
}

export function formatDecimal(decimal?: number): number | undefined {
  if (decimal !== undefined && typeof decimal === 'number') {
    if (decimal === 0) {
      return 0;
    }
    return parseFloat(decimal.toFixed(2));
  }
  return undefined;
}

export function isToday(date: string): boolean {
  const today = moment();
  return moment(date).startOf('day').isSame(today.startOf('day'));
}

export function isThisWeekButNotToday(date: string): boolean {
  const today = moment();
  return moment(date).isSame(today, 'week') && !isToday(date);
}

export function isLastWeek(date): boolean {
  const today = moment();
  const lastWeek = moment().subtract(1, 'week');
  return moment(date).isBetween(lastWeek, today, 'day') && !isThisWeekButNotToday(date);
}

export function isOlder(date): boolean {
  const lastWeek = moment().subtract(1, 'week');
  return moment(date).isBefore(lastWeek, 'day');
}

export function calcStartEndDate(date): { startDate: Moment; endDate: Moment } {
  const end = moment.min(moment(date).add(DEFAULT_DAYS_AGO / 2, 'days'), moment());

  const endDate = moment(end);
  const startDate = moment(end).subtract(DEFAULT_DAYS_AGO, 'days');
  return {
    startDate,
    endDate,
  };
}

export function getColorRgb(icon: ActivityIcons): ActivityColorsRgb {
  switch (icon) {
    case 'calendar':
      return 'rgba(245, 156, 169, 0.7)';
    case 'mail':
      return 'rgba(33, 133, 208, .7)';
    case 'call':
      return 'rgba(0, 181, 173, 0.7)';
    case 'tasks':
      return 'rgba(68, 175, 105, 0.7)';
    case 'sms':
      return 'rgba(140, 75, 156, 0.7)'; // '#8C4B9C' purple,
    case 'linkedin':
      return 'rgb(10 102 194)';
    default:
      return 'rgba(140, 75, 156, 0.7)';
  }
}

export function makeColorGenerator(): Function {
  const colors = [
    'rgba(33, 133, 208, .7)', // '#2185D0' blue,
    'rgba(245, 156, 169, 0.7)', // '#F59CA9' pink,
    'rgba(68, 175, 105, 0.7)', // '#44AF69' green,
    'rgba(140, 75, 156, 0.7)', // '#8C4B9C' purple,
    'rgba(208, 94, 33, 0.7)', // '#D05E21' orange,
    'rgba(169, 245, 156, 0.7)', // '#A9F59C' lime,
    'rgba(0, 181, 173, 0.7)', // '#00B5AD' teal,
    'rgba(175, 77, 68, 0.7)', // '#AF4D44' brown,
  ];
  let index = 0;
  return (): ActivityColorsRgb => {
    const color = colors[index] as ActivityColorsRgb;
    if (index === colors.length - 1) index = 0;
    else index += 1;
    return color;
  };
}

export type IndexedColorGenerator = (key: string | number) => string;
export const makeIndexedColorGenerator: (colors?: string[]) => IndexedColorGenerator = (colors = [
  'rgba(33, 133, 208, .7)', // '#2185D0' blue,
  'rgba(245, 156, 169, 0.7)', // '#F59CA9' pink,
  'rgba(68, 175, 105, 0.7)', // '#44AF69' green,
  'rgba(140, 75, 156, 0.7)', // '#8C4B9C' purple,
  'rgba(208, 94, 33, 0.7)', // '#D05E21' orange,
  'rgba(169, 245, 156, 0.7)', // '#A9F59C' lime,
  'rgba(0, 181, 173, 0.7)', // '#00B5AD' teal,
  'rgba(175, 77, 68, 0.7)', // '#AF4D44' brown,
]) => {
  let index = 0;
  const stringDict = {};
  return (key: string | number): string => {
    if (typeof key === 'string') {
      if (key in stringDict) {
        return stringDict[key];
      }
      const color = colors[index];
      stringDict[key] = color;
      if (index === colors.length - 1) index = 0;
      else index += 1;
      return color;
    }

    return colors[key % colors.length];
  };
};

export function iconToPrettyName(icon: ActivityIcons): string {
  switch (icon) {
    case 'mail':
      return 'Email';
    case 'call':
      return 'Call';
    case 'calendar':
      return 'Event';
    case 'tasks':
      return 'Task';
    case 'linkedin':
      return 'LinkedIn';
    default:
      return 'Unknown';
  }
}

// we may not need this since we sort on the server side now
export function sortHistory(history, reverse = true): any {
  return history.sort((left, right) => {
    const leftDate = moment(left.StartDateTime);
    const rightDate = moment(right.StartDateTime);
    if (leftDate.isBefore(rightDate)) {
      return reverse ? 1 : -1;
    }
    // is same or after
    return reverse ? -1 : 1;
  });
}

export async function stripMarkdown(text): Promise<string> {
  const { contents: strippedBody } = await remark().use(strip).process(text);
  return strippedBody.toString();
}

export function stripHTML(text: string | null | undefined): string {
  if (!text) return '';
  const tmp = document.createElement('div');
  tmp.innerHTML = text;
  return tmp.textContent || tmp.innerText || '';
}

export function abbreviateNumber(num: number, digits = 1): string {
  const ONE_MILLION = 1000000;
  const ONE_THOUSAND = 1000;

  if (num > ONE_MILLION) {
    const roundedVal = (num / ONE_MILLION).toFixed(digits);
    return `${roundedVal}M`;
  } if (num > ONE_THOUSAND) {
    const roundedVal = (num / ONE_THOUSAND).toFixed(digits);
    return `${roundedVal}K`;
  }
  return `${num}`;
}

export function getGoalHeaderText(period: GoalPeriod): { subHeaderText: string; popupContent?: string } {
  const {
    start,
    end,
    unit,
    index,
  } = period;
  let subHeaderText;
  if (unit === 'month') {
    subHeaderText = `${DateTime.fromISO(start, { zone: 'utc' }).toFormat('LLLL')} goal`;
  } else {
    subHeaderText = `${unit}ly goal`;
  }

  const startStr = DateTime.fromISO(start, { zone: 'utc' }).toFormat('MMM dd, yyyy');
  const endStr = DateTime.fromISO(end, { zone: 'utc' }).toFormat('MMM dd, yyyy');
  let popupContent: string | undefined = `${startStr} to ${endStr}`;
  if (index === 0) {
    subHeaderText = `Current ${subHeaderText}`;
  } else if (index === 1) {
    subHeaderText = `Previous ${subHeaderText}`;
  } else {
    subHeaderText = popupContent;
    popupContent = undefined;
  }

  return { subHeaderText, popupContent };
}

export function round(num: number, precision = 1): number {
  let smartPrecision = precision;
  if (num < 0.1 && num >= 0.01 && smartPrecision < 2) {
    smartPrecision = 2;
  }
  if (num < 0.01 && num >= 0.001 && smartPrecision < 3) {
    smartPrecision = 3;
  }
  const multiplier = 10 ** smartPrecision || 0;
  return Math.round(num * multiplier) / multiplier;
}
export function secondsToHours(seconds: number, precision = 1): number {
  const minutes = seconds / 60;
  const hours = minutes / 60;
  return round(hours, precision);
}

export function constructQueryString(opts: any = {}): string {
  const keys = Object.keys(opts);
  if (keys.length === 0) return '';
  const optsArray: Array<string> = [];
  Object.entries(opts).forEach(([key, v]: [string, any]): void => {
    let valuesToProcess = v;
    if (!Array.isArray(valuesToProcess)) {
      valuesToProcess = [valuesToProcess];
    }
    valuesToProcess.forEach(vtp => {
      let value = vtp;
      if (typeof value === 'undefined') return;
      if (value instanceof Boolean && value) {
        optsArray.push(`${key}`);
        return;
      }
      if (value instanceof Date || moment(value, 'YYYY-MM-DD', true).isValid()) {
        value = moment(value).format('YYYY-MM-DDTHH:mm:ss');
      }
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }
      optsArray.push(`${key}=${encodeURIComponent(value)}`);
    });
  });
  return `?${optsArray.join('&')}`;
}

export const formatStat = (stat: number | string | undefined, precision = 1): number | string => {
  if (typeof stat === 'number') {
    return stat !== 0 ? Math.abs(stat).toFixed(precision) : Math.abs(stat);
  } if (typeof stat === 'string') {
    return Math.abs(parseFloat(stat)).toFixed(precision);
  }
  return '-';
};
