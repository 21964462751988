import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import qs from 'query-string';

import actions, { fetchAlertNotifications, fetchAlertRules } from 'actions/alert-actions';
import usePrevious from 'hooks/usePrevious';
import { RulesNotifications } from 'models/alert';
import { getRulesWithNotifications, getUniqueMetricsFromAlertRules } from 'selectors/alert';
import { getSelectedTeamMembers } from 'selectors/team';
import RulesTable from './components/RulesTable';
import SearchBar from './components/SearchBar';

import css from './AlertsDash.module.css';

const { clearAlertRule } = actions.alert;

type Props = RouteComponentProps;

type State = any;

export interface DisplayOptions {
  searchTerm: string;
  metricsWhiteList: Array<string>;
  userWhitelist: Array<number>;
}

const defaultDisplayOptions: DisplayOptions = {
  showAlerts: true,
  showRecommendations: true,
  searchTerm: '',
  metricsWhiteList: [],
  userWhitelist: [],
};

const AlertsDash: React.FC<Props> = ({ location }) => {
  const { search } = location;
  const params = qs.parse(search);

  if (params.users) {
    const usersArr: string[] = Array.isArray(params.users) ? params.users : [params.users];
    defaultDisplayOptions.userWhitelist = usersArr.map(u => parseInt(u, 10));
  }

  const teamId = useSelector((state: State) => state.team.selectedTeamId);
  const users = useSelector(getSelectedTeamMembers);
  const isLoadingNotifications = useSelector((state: State) => state.alert.isLoadingNotifications);
  const isLoadingRules = useSelector((state: State) => state.alert.isLoadingRules);
  const rulesNotifications = useSelector(getRulesWithNotifications);
  const metrics = useSelector(getUniqueMetricsFromAlertRules);

  const [displayOptions, setDisplayOptions] = useState<DisplayOptions>(defaultDisplayOptions);
  const [filteredData, setFilteredData] = useState(rulesNotifications);

  const prevRulesNotifications = usePrevious(rulesNotifications);

  const dispatch = useDispatch();
  const history = useHistory();
  const urlState: any = history?.location?.state;
  const showUpdatedToast = urlState?.ruleUpdated;

  const handleToast = (message: string): void => {
    toast(message, {
      position: 'top-center',
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const filterData = (data: Array<RulesNotifications>, options: DisplayOptions): void => {
    const {
      metricsWhiteList,
      searchTerm,
      userWhitelist,
    } = options;

    const lowerSearchTerm = searchTerm.toLowerCase();

    const newData = data.filter((ruleNotification): boolean => {
      if (lowerSearchTerm && (
        !ruleNotification.description
        || !ruleNotification.description.toLowerCase().includes(lowerSearchTerm))
      ) {
        return false;
      }

      if (userWhitelist.length && !userWhitelist.includes(ruleNotification.userId)) {
        return false;
      }

      if (metricsWhiteList.length && !metricsWhiteList.includes(ruleNotification.metric)) {
        return false;
      }

      return true;
    });

    setFilteredData(newData);
  };

  useEffect(() => {
    if (showUpdatedToast) {
      handleToast('Alert rule updated');
      history.replace('/app/alerts', null);
    }
  }, [showUpdatedToast, history]);

  useEffect(() => {
    dispatch(clearAlertRule());
  }, [dispatch]);

  useEffect(() => {
    if (JSON.stringify(prevRulesNotifications) !== JSON.stringify(rulesNotifications)) {
      dispatch(fetchAlertNotifications());
      dispatch(fetchAlertRules());
    }
    filterData(rulesNotifications, displayOptions);
  }, [displayOptions, rulesNotifications, prevRulesNotifications, dispatch]);


  useEffect(() => {
    dispatch(fetchAlertNotifications());
    dispatch(fetchAlertRules());
  }, [teamId, dispatch]);

  const handleSearch = (newOptions): void => {
    setDisplayOptions({ ...displayOptions, ...newOptions });
  };

  return (
    <div className={css.dashboard}>
      <SearchBar
        displayOptions={displayOptions}
        metrics={metrics}
        onChange={handleSearch}
        users={users}
      />
      <RulesTable
        data={filteredData}
        isLoading={isLoadingNotifications || isLoadingRules}
      />
    </div>
  );
};

export default AlertsDash;
