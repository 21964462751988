import { useEffect, useState } from 'react';
import useDebounce from './useDebounce';

export const useSearchable = <E>(entities: E[], searchableKey: string, debounce = true): [
  E[],
  string,
  React.Dispatch<React.SetStateAction<string>>
] => {
  const [filteredEntities, setFilteredEntities] = useState(entities);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, !debounce ? 0 : undefined);
  useEffect(() => {
    const searchedEntities = entities.filter(
      entity => entity[searchableKey].toLowerCase().includes(debouncedSearchQuery.toLowerCase()),
    );
    setFilteredEntities(searchedEntities);
  }, [debouncedSearchQuery, entities, searchableKey]);

  return [filteredEntities, searchQuery, setSearchQuery];
};
