import React from 'react';
import { Popup } from 'semantic-ui-react';

import { FunnelStats } from 'models/funnel';

import { funnelLabelMap } from '../Funnel';
import FunnelLegend from './FunnelLegend';

import css from '../styles.module.css';

interface Props {
  alpha: number;
  level: FunnelStats;
  topOffset: number;
  bottomOffset: number;
  expanded?: boolean;
  onClick?: Function;
}

const FunnelLevel: React.FC<Props> = ({
  alpha,
  level,
  topOffset,
  bottomOffset,
  expanded,
  onClick,
}) => {
  const { label, total, breakdown, conversion } = level;

  const breakdownSummary = Object.entries(breakdown).map(([key, value]) => ({
    key,
    value,
    text: funnelLabelMap[value] || value,
  }));

  let barContent: React.ReactNode = (<>&nbsp;</>);
  if (expanded && Object.entries(breakdown).length) {
    let i = 1;
    let firstStyle = '';
    let lastStyle = '';
    const offsetDiff = bottomOffset - topOffset;
    const offset = (topOffset === bottomOffset) ? 0 : bottomOffset;
    const totalWidth = 100 - 2 * offset;
    const multiplier = 100 / totalWidth;
    const innerOffset = offsetDiff * multiplier;
    barContent = Object.entries(breakdown).map(([key, value]) => {
      if (i === 1) firstStyle = key;
      const barWidth = Math.round((value / total) * 100);
      const width = `${barWidth}%`;
      const styles = { width };

      i += 1;
      lastStyle = key;

      const handleClick = (): void => {
        const filter = { status: key };
        if (onClick) onClick(level, filter);
      };

      const breakdownNode = (
        <div key={key} className={`${css.breakdown} ${css[key]}`} style={styles} onClick={handleClick}>
          {value}
        </div>
      );

      const breakdownLabel = funnelLabelMap[key];

      return (
        <Popup
          key={key}
          basic
          content={breakdownLabel}
          trigger={breakdownNode}
        />
      );
    });
    const paddingStyles = { width: `${innerOffset}%` };

    const firstClick = (): void => {
      const filter = { status: firstStyle };
      if (onClick) onClick(level, filter);
    };

    const lastClick = (): void => {
      const filter = { status: lastStyle };
      if (onClick) onClick(level, filter);
    };
    barContent = (
      <>
        <div className={`${css.breakdown} ${css[firstStyle]}`} style={paddingStyles} onClick={firstClick}>
          &nbsp;
        </div>
        {barContent}
        <div className={`${css.breakdown} ${css[lastStyle]}`} style={paddingStyles} onClick={lastClick}>
          &nbsp;
        </div>
      </>
    );
  }

  const topLeft = `${topOffset}%`;
  const topRight = `${100 - topOffset}%`;
  const bottomRight = `${100 - bottomOffset}%`;
  const bottomLeft = `${bottomOffset}%`;
  const color = `rgb(221, 62, 148, ${alpha})`;

  const barStyles = {
    backgroundColor: color,
    clipPath: `polygon(${topLeft} 0, ${topRight} 0, ${bottomRight} 100%, ${bottomLeft} 100%)`,
  };

  const handleClick = expanded ? undefined : (): void => {
    if (onClick) onClick(level);
  };

  const barNode = (
    <div className={css.bar} style={{ width: `${100 - (2 * topOffset)}%` }} onClick={handleClick}>
      {barContent}
    </div>
  );

  const className = expanded ? `${css.funnelLevel} ${css.expanded}` : css.funnelLevel;

  const bar = expanded ? barNode : (
    <Popup
      basic
      content={<FunnelLegend breakdownOptions={breakdownSummary} direction="column" />}
      trigger={barNode}
      position="right center"
    />
  );

  return (
    <div className={className}>
      <div className={css.left}>
        <div className={css.label}>{label}</div>
        <div className={css.total}>{total}</div>
      </div>
      <div className={css.level} style={barStyles}>
        {bar}
      </div>
      <div className={css.right}>
        {conversion ? `${Math.round(conversion * 100)}%` : null}
      </div>
    </div>
  );
};

export default FunnelLevel;
