/* eslint  max-classes-per-file: 0 */
import { ApiResponse } from '../Api/apiTypes';
import { abbreviateNumber } from '../../lib/util';

export interface PipelineOpenApiQueryParam {
  // teamId: number;
  // ownerId: number;
  startDateIso: string;
  endDateIso: string;
}

export interface PipelineStagesApiQueryParam {
  teamId: number;
  ownerIds: number[];
  opportunityTypes?: string[];
  // startDateIso: string;
  // endDateIso: string;
}

interface StageData {
  ownerId: number;
  masterLabel: string;
  opportunityCnt: number;
  amount: number;
}

export interface GetUserPipelineAtDateApiQueryParam {
  teamId: number;
  userId: number;
  dateIso: string;
}

export type UserPipeline = StageData[];

export interface GetUserPipelineAtDateApiResponse extends ApiResponse{
  data: UserPipeline;
}
export abstract class DollarValue {
  private props;

  get amount(): number {
    return this.props.amount;
  }

  get currencySymbol(): string {
    return '$';
  }

  public getFormattedString(abbreviate = true): string {
    const abbreviatedAmount = abbreviateNumber(this.amount);
    return `${this.currencySymbol}${abbreviate ? abbreviatedAmount : this.amount}`;
  }

  protected static roundAmount(amount: number): number {
    return parseFloat(amount.toFixed(2));
  }

  protected constructor(props: { amount: number }) {
    this.props = props;
  }
}

export class PipelineDollarValue extends DollarValue {
  public static create(amount: number): PipelineDollarValue {
    if (amount < 0) throw Error('PipelineDollarValue cannot be negative');

    const roundedAmount = this.roundAmount(amount);
    return new PipelineDollarValue({ amount: roundedAmount });
  }

  // eslint-disable-next-line no-useless-constructor
  private constructor(props) {
    super(props);
  }
}
