import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Icon, Segment } from 'semantic-ui-react';

import BSIcon from 'components/BSIcon';
import { formatDate } from 'lib/util';
import { Activity as ActivityModel, ActivityIcons } from 'models/activity';
import Activity from '../Activity';

import css from './ActivityGroup.module.css';

interface Props {
  activities: ActivityModel[];
  alwaysExpand: boolean;
  condensed?: boolean;
  displayMode: string;
  endDate?: any;
  onClick: Function;
  onClickSummary?: (event: any) => void;
  selectedId: number;
  startDate?: any;
  view: string;
}

interface State {
  condensed: boolean;
}

export default class ActivityGroup extends Component<Props, State> {
  static defaultProps = {
    alwaysExpand: false,
    condensed: true,
    onClick: (): void => { /* do nothing */ },
    onClickSummary: null,
  };

  constructor(props) {
    super(props);

    this.state = { condensed: props.condensed };
  }

  getMeta(view, activity): React.ReactNode {
    const activityId = activity.id;
    const { accountId, accountName, ownerId, ownerName, isTracked } = activity;
    const startDate = formatDate(activity.activityDate, 'YYYY-MM-DD');
    const accountLink = accountId ? (
      <Link to={`/app/account/${accountId}/activity?activity=${activityId}&date=${startDate}`}>{accountName}</Link>
    ) : 'No account associated';

    let userLink = null;
    if (ownerId) {
      userLink = isTracked
        ? (<Link to={`/app/team/${ownerId}/activityFeed?activity=${activityId}&date=${startDate}`}>{ownerName}</Link>)
        : ownerName;
    }

    switch (view) {
      case 'account':
        return accountLink;
      case 'user':
        return userLink;
      default:
        return (
          <>
            {accountLink}
            -
            {' '}
            {userLink}
          </>
        );
    }
  }

  handleClick = (e): void => {
    e.preventDefault();
    const { condensed } = this.state;
    this.setState({ condensed: !condensed });
  }

  renderCondensed(): React.ReactNode {
    const { onClickSummary, activities } = this.props;
    const clickFn = onClickSummary || this.handleClick;

    const activitiesCount: { [icon in ActivityIcons]?: { count: number; title: string; type: string }} = {};
    activities.forEach(activity => {
      const { icon, type, groupCnt } = activity;
      if (typeof activitiesCount[icon] === 'undefined') activitiesCount[icon] = { count: 0, title: type, type };
      const countObj = activitiesCount[icon];
      if (countObj) {
        countObj.count += groupCnt;
      }
    });

    const summary = Object.keys(activitiesCount).map((icon: any) => {
      const { count, title, type } = activitiesCount[icon];
      if (count === 0) return null;
      return (
        <motion.div
          initial={{ scaleY: 0, height: 0, opactity: 0 }}
          animate={{ scaleY: 1, height: '100%', transition: { delay: 0.1 } }}
          transition={{ duration: 0.1 }}
          exit={{ scaleY: 0, height: 0 }}
          className={`${css.activitySummary} ${css[icon]}`}
          key={icon}
        >
          <Segment className={css.summaryRowSegment}>
            <BSIcon
              bordered
              className={css.icon}
              size="large"
              title={title}
              activityIcon={icon}
              activityType={type}
            />
            <div className={`${css.count} ${css[icon]}`}>
              {count}
            </div>
          </Segment>
        </motion.div>
      );
    });

    return (
      <div className={css.summaryRow} onClick={clickFn}>
        {summary}
      </div>
    );
  }

  renderActivities = (): React.ReactNode => {
    const { condensed } = this.state;
    const {
      activities,
      displayMode,
      endDate,
      selectedId,
      startDate,
      onClick,
      view,
    } = this.props;

    const dateFormat = (displayMode === 'weeks') ? 'MMM DD h:mma' : 'h:mma';

    if (activities.length === 0) {
      if (condensed || endDate.diff(startDate, 'days') <= 1) {
        return (<div className={css.noActivity} />);
      }
      return (
        <div className={css.noActivity}>
          <Icon name="ellipsis vertical" />
        </div>
      );
    }

    return activities.map(activity => {
      const element = (
        <Activity
          key={activity.id}
          id={activity.id}
          date={formatDate(activity.activityDate, dateFormat)}
          description={activity.description}
          emailDetails={activity.emailDetails}
          groupCnt={activity.groupCnt}
          icon={activity.icon}
          metaDetails={this.getMeta(view, activity)}
          selected={activity.id === selectedId}
          subject={activity.subject}
          type={activity.type}
          onClick={(): void => { onClick(activity); }}
        />
      );
      return element;
    });
  }

  render(): React.ReactNode {
    const { condensed } = this.state;
    const { activities, alwaysExpand, onClickSummary, startDate, endDate } = this.props;
    const clickFn = onClickSummary || this.handleClick;

    let displayEnd: any = null;
    const format = 'MMM DD, YYYY';

    if (endDate) {
      let link: any = null;
      if (activities.length > 1 && !alwaysExpand) {
        link = (
          <div className={`${css.expandToggle}`} onClick={clickFn}>
            <Icon className={`${css.icon} ${!condensed ? css.rotate : ''}`} name="angle right" />
          </div>
        );
      }
      displayEnd = (
        <div className={css.dateHeader}>
          {formatDate(endDate, format, true)}
          {link}
        </div>
      );
    }

    const className = (activities.length === 0 && !condensed && endDate.diff(startDate, 'days') > 1)
      ? `${css.activityGroup} ${css.noLine}` : css.activityGroup;

    const showCondensed = condensed && activities.length > 1 && !alwaysExpand;

    return (
      <div className={className}>
        {displayEnd}
        <motion.div
          animate={{ minHeight: condensed ? 70 : 80 }}
          className={css.activitySection}
        >
          <AnimatePresence>
            {showCondensed && this.renderCondensed()}
          </AnimatePresence>
          <AnimatePresence>
            {!showCondensed && this.renderActivities()}
          </AnimatePresence>
        </motion.div>
      </div>

    );
  }
}
