import baseApi from '../apiSlice';
import { ApiResponse } from '../apiTypes';

export interface Query {
  teamId: number;
}

export interface Response extends ApiResponse {
  notifications: {
    [userId: number]: number;
  };
}

const teamNotificationApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTeamNotificationCounts: builder.query<Response, Query>({
      query: ({ teamId }) => `team/${teamId}/notification`,
    }),
  }),
});

export const useGetTeamNotificationCountsQuery = teamNotificationApi.endpoints.getTeamNotificationCounts.useQuery;
