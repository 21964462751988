import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { Link, Route, Switch } from 'react-router-dom';
import { Button, Message, Icon } from 'semantic-ui-react';

import allActions, { bindCombinedActions } from 'actions';
import { getSelectedTeam } from 'selectors/team';
import { RootState } from 'store';

import { TeamWithMembers } from 'models/team';

import { Permission, hasPermission } from 'components/AccessControl';

import ManagerList from './components/ManagerList';
import Comparison from './components/Comparison';

import css from './Dashboard.module.css';

interface Props {
  actions: any;
  navigate: Function;
  redirect: Function;
  loggedInUserId: number | null;
  team?: TeamWithMembers;
  isLoadingUsers: boolean;
  permission: Permission;
  isLoadingTeamSettings: boolean;
}

interface State { }

class Dashboard extends Component<Props, State> {
  componentDidMount(): void {
    const { permission, loggedInUserId, redirect } = this.props;
    if (!hasPermission(permission, 'manager')) {
      redirect(`/app/team/${loggedInUserId}`);
    }
  }

  componentDidUpdate(): void {
    const { permission, loggedInUserId, redirect } = this.props;
    if (!hasPermission(permission, 'manager')) {
      redirect(`/app/team/${loggedInUserId}`);
    }
  }

  renderSetupNotifiction = (): React.ReactNode => {
    const { permission, isLoadingUsers } = this.props;
    let teamLink: React.ReactNode;
    if (isLoadingUsers) {
      return (
        <>
          <Icon name="circle notch" loading />
          {' '}
          Loading...
        </>
      );
    }
    if (hasPermission(permission, 'admin')) {
      teamLink = (
        <>
          <p>
            Please create and manage users from the Settings pages
          </p>
          <p>
            <Button as={Link} to="/app/settings/teams">Manage Teams</Button>
          </p>
        </>
      );
    } else {
      teamLink = (
        <p>
          Please contact your BuyerSight admin or account owner to set up your team.
        </p>
      );
    }
    return (
      <Message>
        <p>
          Your organization has not set up any teams yet that you are a part of.
        </p>
        {teamLink}
      </Message>
    );
  };

  render(): React.ReactNode {
    const { team, isLoadingTeamSettings } = this.props;
    if (isLoadingTeamSettings) return null;

    const content = (team) ? (
      <Switch>
        <Route path="/app/comparison" component={Comparison} />
        <Route path="/" component={ManagerList} />
      </Switch>
    ) : this.renderSetupNotifiction();
    return (
      <div className={css.dashboard}>
        {content}
      </div>
    );
  }
}

export default connect(
  (state: RootState) => ({
    permission: state.app.permission,
    loggedInUserId: state.app.id,
    team: getSelectedTeam(state),
    isLoadingUsers: state.user.isLoadingUsers,
    isLoadingTeamSettings: state.team.isLoadingTeamSettings,
  }),
  dispatch => ({
    actions: bindCombinedActions(allActions, dispatch),
    navigate: bindActionCreators((path: string) => push(path), dispatch),
    redirect: bindActionCreators((path: string) => replace(path), dispatch),
  }),
)(Dashboard);
