import React from 'react';
import css from 'components/TeamComparisonChart/Body/Columns/BarColumn/BarColumn.module.css';

interface Props {
  value: number | null;
  max: number;
  color: string;
  height?: number;
}

export const SimpleProgressBar: (props: Props) => React.ReactElement = ({
  value,
  max,
  color,
  height,
}) => {
  let widthPct = 1;
  if (value !== null) {
    widthPct = (value / max) * 100;
  }
  if (widthPct < 1) widthPct = 1;
  if (widthPct > 100) widthPct = 100;
  return (
    <div
      className={`${css.outer}`}
      style={{
        height,
        borderRadius: height ? height * 2 : undefined,
      }}
    >
      <div
        className={css.inner}
        style={{
          width: `${widthPct}%`,
          backgroundColor: color,
        }}
      />
    </div>
  );
};
