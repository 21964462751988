import { PipelineDollarValue } from 'features/Pipeline/pipelineTypes';
import { MetricDisplayInfo } from 'models/metric/index';

const pipelineMetricNames = ['pipelineCreated', 'pipelineLost', 'pipelineWon', 'pipelineOpen'] as const;
export type PipelineMetricName = typeof pipelineMetricNames[number];
export const isPipelineMetricName = (x: any): x is PipelineMetricName => pipelineMetricNames.includes(x);

export const pipelineMetricDisplayInfo: {
  [stat in PipelineMetricName]: MetricDisplayInfo & {
    name: stat;
  }
} = {
  pipelineCreated: {
    name: 'pipelineCreated',
    title: 'Pipeline Created',
    higherIsBetter: true,
    displayValueFn: (val: number): string => PipelineDollarValue.create(val).getFormattedString(),
    unitIcon: 'hashtag',
    order: 120,
    enabled: true,
  },
  pipelineLost: {
    name: 'pipelineLost',
    title: 'Pipeline Lost',
    higherIsBetter: false,
    displayValueFn: (val: number): string => PipelineDollarValue.create(val).getFormattedString(),
    unitIcon: 'hashtag',
    order: 140,
    enabled: true,
  },
  pipelineWon: {
    name: 'pipelineWon',
    title: 'Pipeline Won',
    higherIsBetter: true,
    displayValueFn: (val: number): string => PipelineDollarValue.create(val).getFormattedString(),
    unitIcon: 'hashtag',
    order: 130,
    enabled: true,
  },
  pipelineOpen: {
    name: 'pipelineOpen',
    title: 'Open Pipeline',
    higherIsBetter: true,
    displayValueFn: (val: number): string => PipelineDollarValue.create(val).getFormattedString(),
    unitIcon: 'hashtag',
    order: 110,
    enabled: true,
  },
};
