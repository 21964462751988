import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Dimmer, Header, Icon, Label, Modal, Segment } from 'semantic-ui-react';

import allActions, { bindCombinedActions } from 'actions';
import api from 'lib/api';
import { Tag } from 'models/activity';

import BSTable from 'components/Table/BSTable';
import Rule from './components/Rule';

import css from './Tags.module.css';

interface Props {
  actions: any;
  isLoadingRules: boolean;
  isLoadingTags: boolean;
  rules: Array<any>;
  tags: Array<Tag>;
}
interface State {
  showModal: boolean;
  modalContent: any;
}

class Tags extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      modalContent: null,
    };
  }

  componentDidMount(): void {
    const { actions } = this.props;
    actions.activity.getTagRules();
    actions.activity.getTags();
  }


  applyRule = async (ruleId): Promise<any> => {
    const { actions } = this.props;
    const response = await actions.activity.applyTagRule(ruleId, { syncAll: true });
    if (response.status) {
      toast(`Tagged ${response.count} activities`);
      actions.activity.getTags();
    }
    return response;
  };

  saveRule = (rule: any): Promise<any> => {
    const { actions } = this.props;
    return actions.activity.saveTagRule(rule);
  };

  deleteRule = (rule: any): void => {
    const { actions } = this.props;
    const content = (
      <>
        Are you sure you want to delete this rule for
        {' '}
        <Label className={css.tag} content={rule.tagName} />
        ?
      </>
    );
    const deleteFn = async (): Promise<void> => {
      await actions.activity.deleteTagRule(rule.id);
      this.setState({ showModal: false });
    };
    const modalActions = (
      <Button onClick={deleteFn} negative>Delete</Button>
    );
    const modalContent = {
      header: 'Delete Rule',
      content,
      actions: modalActions,
    };
    this.setState({ showModal: true, modalContent });
  };

  deleteTagFromAll = async (tag: Tag): Promise<void> => {
    const { actions } = this.props;

    const content = (
      <>
        Are you sure you want to delete the tag
        {' '}
        <Label className={css.tag} content={tag.tagName} />
        {' '}
        from all activities?
      </>
    );
    const deleteFn = async (): Promise<void> => {
      const response = await actions.activity.removeTagFromAll(tag.id);
      if (response.status) {
        toast(`Removed tag ${tag.tagName}`);
        actions.activity.getTags();
      }
      this.setState({ showModal: false });
    };
    const modalActions = (
      <Button onClick={deleteFn} negative>Delete</Button>
    );
    const modalContent = {
      header: 'Delete Tag',
      content,
      actions: modalActions,
    };
    this.setState({ showModal: true, modalContent });
  };

  renderModal = (): React.ReactNode => {
    const { showModal, modalContent } = this.state;
    const closeFn = (): void => { this.setState({ showModal: false }); };
    if (!modalContent) return null;
    const { header, content, actions } = modalContent;

    return (
      <Modal open={showModal} onClose={closeFn}>
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>
          {content}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeFn}>Cancel</Button>
          {actions}
        </Modal.Actions>
      </Modal>
    );
  }

  renderRules = (): React.ReactNode => {
    const { actions, isLoadingRules, rules, tags } = this.props;

    const onPreview = async (opts = {}): Promise<any> => {
      const response = await api.searchActivities(opts);
      if (response.error === 'NO_AUTH' || response.error === 'INVALID_TOKEN') {
        actions.app.logout();
      }
      return response;
    };

    let content;
    if (isLoadingRules) {
      content = 'Loading...';
    } else {
      const renderRuleRow = (r: any): React.ReactNode => (
        <Rule
          key={r.id}
          rule={r}
          tags={tags}
          onApply={this.applyRule}
          onDelete={this.deleteRule}
          onPreview={onPreview}
          onSave={this.saveRule}
        />
      );

      const rulesPlus = rules.concat([{
        id: 0,
        count: 0,
        tagName: '',
        has: [],
        doesNotHave: [],
      }]);

      content = (
        <BSTable
          searchKey="tagName"
          properties={[{ key: 'tagName', title: 'Tag', sort: true },
            { key: 'has', title: 'Has' },
            { key: 'doesNotHave', title: 'Does not have' },
            { key: 'type', title: 'Types' },
            { key: 'lastModified', title: 'Last Modified', sort: true, sortType: 'date' },
          ]}
          renderRow={renderRuleRow}
          data={rulesPlus}
        />
      );
    }
    return (
      <Segment>
        <Header>Tag Rules</Header>
        <div className={css.tagRuleSection}>
          {content}
        </div>
      </Segment>
    );
  };

  renderTags = (): React.ReactNode => {
    const { isLoadingTags, tags } = this.props;

    const content = tags.map(t => {
      const { id, tagName, count } = t;
      return (
        <Label key={id} className={css.tag}>
          {tagName}
          <Label.Detail>{count}</Label.Detail>
          <Icon name="delete" onClick={(): void => { this.deleteTagFromAll(t); }} />
        </Label>
      );
    });

    return (
      <Segment>
        <Header>Tag Summary</Header>
        {content}
        <Dimmer inverted active={isLoadingTags} />
      </Segment>
    );
  };

  render(): React.ReactNode {
    return (
      <div className={css.tags}>
        {this.renderTags()}
        {this.renderRules()}
        {this.renderModal()}
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    isLoadingRules: state.activity.isLoadingRules,
    isLoadingTags: state.activity.isLoadingTags,
    rules: state.activity.rules,
    tags: state.activity.tags,
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(Tags);
