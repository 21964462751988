import { AccountHealth } from '../../../models/account';
import baseApi from '../apiSlice';
import { ApiResponse } from '../apiTypes';
import { Indicator } from '../../../models/account/health/indicator';

export interface Query {
  accountId: number;
  teamId?: number;
}

export interface Response extends ApiResponse {
  account: {
    id: number;
    extId: string;
    extSource: string;
    name: string;
    status: AccountHealth;
    ownerId: number;
    ownerName: string;
    lastActivityId: number;
    lastActivityDate: string;
    createDate: string;
    opportunities: {
      id: number;
      extId: string;
      extSource: string;
      metrics: Indicator[];
      name: string;
      ownerId: number;
      ownerName: string;
      stageName: string;
      type: string;
    }[];
  };
}

const accountApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getAccountHealth: builder.query<Response, Query>({
      query: ({ accountId }) => `account/health/v2/${accountId}`,
    }),
    getAccount: builder.query<Response, Query>({
      query: ({ accountId }) => `account/${accountId}`,
    }),
  }),
});

export const useGetAccountHealthQuery = accountApi.endpoints.getAccountHealth.useQuery;
export const useGetAccountQuery = accountApi.endpoints.getAccount.useQuery;
