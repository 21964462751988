import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Segment } from 'semantic-ui-react';

import allActions, { bindCombinedActions } from 'actions';
import OpenPipeline from './components/OpenPipeline';
import PipelineWaterfall from './components/PipelineWaterfall';
import PipelineCreated from './components/PipelineCreated';
import PipelineClosed from './components/PipelineClosed';
import StageTable from './components/StageTable';
import css from './PipelineDash.module.css';

interface Props {
  actions: any;
  selectedTeamId: string;
}

interface State {}

class RecommendationAdmin extends Component<Props, State> {
  render(): React.ReactNode {
    const { selectedTeamId } = this.props;
    if (!selectedTeamId) {
      return (<Segment>Please select a team to view</Segment>);
    }
    return (
      <div className={css.dashboard}>
        <Grid columns="16">
          <Grid.Row>
            <Grid.Column width="8">
              <OpenPipeline />
            </Grid.Column>
            <Grid.Column width="8">
              <PipelineWaterfall />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="8">
              <PipelineCreated />
            </Grid.Column>
            <Grid.Column width="8">
              <PipelineClosed />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="16">
              <StageTable />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default connect(
  (state: any) => ({ selectedTeamId: state.team.selectedTeamId }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(RecommendationAdmin);
