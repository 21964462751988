import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment } from 'semantic-ui-react';

import { formatDate } from 'lib/util';
import { Activity, ActivityContact } from 'models/activity';
import allActions, { bindCombinedActions } from 'actions';
import ExternalLink from 'components/ExternalLink';
import BSIcon from 'components/BSIcon';
import { Opportunity } from 'models/opportunity';
import OpportunitiesSection from '../OpportunitiesSection';

import css from './ActivityPanel.module.css';
import { ClassificationSection } from './ClassificationsSection';
import { ActivityPanelBody } from './ActivityPanelBody';
import { TagSection } from './TagSection';

interface ActivityPanelProps {
  actions: any;
  as: any;
  activity: Activity;
  activityContacts: Array<ActivityContact>;
  defaultActive?: boolean;
  onTagAdd: Function;
  onTagRemove: Function;
  onUpdateOpportunity: Function;
  tags: string[];
}

interface ActivityDetailsState {
  active?: boolean;
  opportunity: Array<any>;
}

class ActivityPanel extends Component<ActivityPanelProps, ActivityDetailsState> {
  static defaultProps = {
    as: Segment,
    defaultActive: true,
    onTagAdd: (): void => { /* do nothing */ },
    onTagRemove: (): void => { /* do nothing */ },
  };

  constructor(props: ActivityPanelProps) {
    super(props);

    this.state = {
      active: props.defaultActive,
      opportunity: [],
    };
  }

  componentDidMount(): void {
    this.fetchOpportunity();
  }

  componentDidUpdate(prevProps: ActivityPanelProps): void {
    const { activity, defaultActive } = this.props;

    if (prevProps.activity.id !== activity.id) {
      ((): void => {
        this.setState({ active: defaultActive, opportunity: [] });
        this.fetchOpportunity();
      })();
    }
  }

  fetchOpportunity = async (): Promise<void> => {
    const { actions, activity } = this.props;
    const response = await actions.activity.getActivityOpportunity(activity.id);
    if (response.opportunity && response.opportunity.length) {
      this.setState({ opportunity: response.opportunity });
    }
  };

  handleToggleActive = (): void => {
    this.setState(prevState => ({ active: !prevState.active }));
  };

  handleUpdateOpportunityStatus = async (opportunity: Opportunity, opts: any): Promise<void> => {
    const { actions, activity, onUpdateOpportunity } = this.props;
    await actions.activity.updateActivityOpportunity(opportunity.opportunityId, activity.id, opts);
    this.fetchOpportunity();
    onUpdateOpportunity();
  };

  render(): React.ReactNode {
    const { opportunity, active } = this.state;
    const { as, activity, activityContacts, onTagAdd, onTagRemove, tags } = this.props;
    const { activityDate, extId, extSource, direction, icon } = activity;
    let { type } = activity;
    if (type === null) type = 'Unknown';
    let directionNode: any = null;
    if (direction !== null) {
      const directionIconName = direction === 'outbound' ? 'arrow right' : 'arrow left';
      const title = direction === 'outbound' ? 'Outbound Email' : 'Inbound Email';
      directionNode = <BSIcon title={title} name={directionIconName} />;
    }
    const header = (
      <div className={css.date}>
        <BSIcon title={type} activityIcon={icon} activityType={type} />
        {directionNode}
        {` ${type} `}
        -
        {` ${formatDate(activityDate, 'MMM DD, YYYY h:mma')} `}
        <ExternalLink extId={extId} extSource={extSource} type="activity" />
      </div>
    );

    return (
      <Segment as={as} className={css.activityPanel} onClick={this.handleToggleActive}>
        <OpportunitiesSection
          onUpdateOpportunityStatus={this.handleUpdateOpportunityStatus}
          opportunities={opportunity}
        />
        {header}
        <TagSection
          activity={activity}
          onTagAdd={onTagAdd}
          onTagRemove={onTagRemove}
          tags={tags}
        />
        <ClassificationSection activity={activity} />
        <ActivityPanelBody activity={activity} activityContacts={activityContacts} active={active} />
      </Segment>
    );
  }
}

export default connect(
  (state: any) => ({
    activityContacts: state.activity.activityContacts,
    tags: state.activity.activityTags.map(t => t.tagName),
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(ActivityPanel);
