import { createSelector } from 'reselect';
import { User } from 'models/user';
import { RootState } from '../store';

const usersSelector = (state): Array<User> => state.user.users;

export const getTrackedUsers = createSelector<any, any, any>(
  usersSelector,
  (users: Array<User>): Array<User> => (users !== undefined ? users.filter(user => user.isTracked) : []),
);

export const getUserSfIds = createSelector<any, any, any>(
  usersSelector,
  (users: Array<User>): Array<string> => (users !== undefined ? users.map(user => user.sfId) : []),
);

export const getNumOfTrackedUsers = createSelector<any, any, any>(
  getTrackedUsers,
  (users: Array<User>): number => users.length,
);

export const selectUserInfo = (state: RootState): User => state.user.userInfo;

export const selectUserInfoId = (state: RootState): number | null => selectUserInfo(state).id;
