import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Input,
  InputOnChangeData,
  Modal,
} from 'semantic-ui-react';

import allActions, { bindCombinedActions } from 'actions';

import { User } from 'models/user';
import { getTeamMembersOfTeam } from 'selectors/team';
import { useSearchable } from 'hooks/useSearchable';
import css from './AddTeamModal.module.css';
import SimpleUserTable from '../SimpleUserTable/SimpleUserTable';

interface AddUserModalProps {
  open: boolean;
  onClose: () => void;
  actions: any;
  teamId: string;
  teamName: string;
  users: User[];
  teamMembers: User[];
  isLoadingUsers: boolean;
  isAddingTeamMember: boolean;
}

const AddUserModalBase: React.FC<AddUserModalProps> = ({
  actions,
  open,
  onClose,
  teamId,
  teamName,
  users,
  teamMembers,
  isLoadingUsers,
  isAddingTeamMember,
}: AddUserModalProps): React.ReactElement => {
  const [selectedUserIds, setSelectedUserIds] = React.useState<string[]>([]);
  const [filteredUsers, , setSearchQuery] = useSearchable<User>(users, 'name');

  const handleQueryChange = (_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
    setSearchQuery(data.value);
  };

  const handleAddTeamMember = (): void => {
    if (selectedUserIds) {
      actions.team.addTeamMember(selectedUserIds, teamId);
      onClose();
    }
  };

  const handleSelectUser = (userId: string): void => {
    const userIdIndex = selectedUserIds.findIndex(id => userId === id);
    // toggles selected user
    if (userIdIndex === -1) {
      setSelectedUserIds([...selectedUserIds, userId]);
    } else {
      const selectedUserIdsWithRemoved = [...selectedUserIds];
      selectedUserIdsWithRemoved.splice(userIdIndex, 1);
      setSelectedUserIds(selectedUserIdsWithRemoved);
    }
  };

  const filteredNonRepeatedUsers = filteredUsers.filter(user => !(teamMembers.map(tm => tm.id).includes(user.id)));

  return (
    <Modal closeIcon closeOnDimmerClick open={open} onClose={onClose}>
      <Modal.Header>{`Adding User to ${teamName}`}</Modal.Header>
      <Modal.Content>
        <Input
          fluid
          placeholder="Search user"
          onChange={handleQueryChange}
        />
        <SimpleUserTable
          loading={isLoadingUsers}
          users={filteredNonRepeatedUsers}
          selectedUserId={selectedUserIds}
          onSelectUser={handleSelectUser}
        />
      </Modal.Content>
      <Modal.Actions className={css.addTeamButtonContainer}>
        <Button
          loading={isAddingTeamMember}
          disabled={selectedUserIds.length === 0}
          onClick={handleAddTeamMember}
        >
          Add
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default connect(
  (state: any, ownProps: any) => {
    const teamMembersSelector = getTeamMembersOfTeam(ownProps.teamId);
    const teamMembers: User[] = teamMembersSelector(state);
    return ({
      users: state.user.users,
      isLoadingUsers: state.user.isLoadingUsers,
      isAddingTeamMember: state.team.isAddingTeamMember,
      teamMembers,
    });
  },
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(AddUserModalBase);
