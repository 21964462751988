/* eslint-disable */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedTeamId } from 'selectors/team';
import { Card, Grid, Icon, Loader, SemanticICONS } from 'semantic-ui-react';
import reactStringReplace from 'react-string-replace';

import { formatStat } from 'lib/util';
import { prospectingApi } from 'features/Api';
import { ProspectingMetric, ProspectingMetricCodename } from 'features/Api/Prospecting';

import css from './styles.module.css';

interface ProspectingMetricDetails {
  codename: ProspectingMetricCodename;
  enabled: boolean;
  color: string;
  icon: SemanticICONS;
  insight?: string;
  details: string;
}

interface RecommendationDetails {
  codename: ProspectingMetricCodename;
  name: string;
  recommendation: string;
  issue: string;
}

const RECOMMENDATION_COLORS = {
  RED: '#DD4E3E',
  GREEN: '#3EDD87',
  PURPLE: '#733EDD',
  BLUE: '#3EC3DD',
};


/* eslint-disable @typescript-eslint/camelcase */
const recommendationToInsightMap: Partial<Record<ProspectingMetricCodename, ProspectingMetricCodename>> = {
  activity_velocity: 'activity_count',
  flesch_kincaid_grade_mean: 'flesch_kincaid_grade_mean',
  rep_response_time_mean: 'rep_response_time_mean',
  word_count_mean: 'word_count_mean',
  rep_followup_time_mean: 'rep_followup_time_mean',
  big_gap_count: 'big_gap_count',
};

export const recommendationDetailsConfig: Partial<Record<ProspectingMetricCodename, RecommendationDetails>> = {
  activity_velocity: {
    codename: 'activity_velocity',
    name: 'activities per week',
    recommendation: 'Prospecting is moving too slow (:actualValue activities per week), increase the rate of activities',
    issue: '',
  },
  is_multi_channel: {
    codename: 'is_multi_channel',
    name: 'is multi channel',
    recommendation: 'Only one prospecting channel used for more than :actualValue activities',
    issue: '',
  },
  big_gap_count: {
    codename: 'big_gap_count',
    name: 'activity gap count',
    recommendation: 'Too many large gaps in activity (:actualValue gaps of 15+ days)',
    issue: '',
  },
  flesch_kincaid_grade_mean: {
    codename: 'flesch_kincaid_grade_mean',
    name: 'email grade level',
    recommendation: 'Grade level is too high (:actualValue)',
    issue: '',
  },
  needs_response: {
    codename: 'needs_response',
    name: 'needs response',
    recommendation: 'Prospect has not been responded to in :actualValue days',
    issue: '',
  },
  rep_followup_time_mean: {
    codename: 'rep_followup_time_mean',
    name: 'follow-up time',
    recommendation: 'Follow up time is too long (:actualValue days)',
    issue: '',
  },
  rep_response_time_mean: {
    codename: 'rep_response_time_mean',
    name: 'response time',
    recommendation: 'Rep response time to emails is too long (:actualValue days)',
    issue: '',
  },
  prospect_response_time_mean: {
    codename: 'prospect_response_time_mean',
    name: 'prospect gone dark',
    recommendation: 'Prospect has gone dark (:actualValue days)',
    issue: '',
  },
  word_count_mean: {
    codename: 'word_count_mean',
    name: 'email length',
    recommendation: 'Prospecting emails are too long (:actualValue words)',
    issue: '',
  },
};

export const prospectingMetricDetails: Partial<Record<ProspectingMetricCodename, ProspectingMetricDetails>> = {
  activity_count: {
    codename: 'activity_count',
    enabled: true,
    color: RECOMMENDATION_COLORS.GREEN,
    icon: 'mail',
    insight: '',
    details: '',
  },
  is_multi_channel: {
    codename: 'is_multi_channel',
    enabled: true,
    color: RECOMMENDATION_COLORS.GREEN,
    icon: 'tty',
    insight: 'Multi-channel prospecting is :lift more effective',
    details: 'Multi-channel prospecting has a higher chance of success than relying on only one channel (email, phone, LinkedIn). BuyerSight found your team is :lift more successful when more than one channel is used. Coach reps to prospect through multiple channels.',
  },
  flesch_kincaid_grade_mean: {
    codename: 'flesch_kincaid_grade_mean',
    enabled: true,
    color: RECOMMENDATION_COLORS.BLUE,
    icon: 'pencil',
    insight: 'Emails with grade level under :targetValue are more likely to be responded to',
    details: 'Grade level is a measure of how difficult an email is to read, with higher meaning more difficult. Easier to read prospecting emails tend to get more responses. For your team, BuyerSight found that emails with grade level under :targetValue performed the best. Coach reps to keep prospecting emails under this threshold.',
  },
  needs_response: {
    codename: 'needs_response',
    enabled: true,
    color: RECOMMENDATION_COLORS.RED,
    icon: 'clock outline',
    details: 'This prospect sent an email, but has not gotten a response yet. The amount of time a rep waits before responding is often correlated with deal success. ',
  },
  rep_response_time_mean: {
    codename: 'rep_response_time_mean',
    enabled: true,
    color: RECOMMENDATION_COLORS.GREEN,
    icon: 'clock outline',
    insight: 'Deals are most successful when reps respond within :targetValue days of receiving an inbound email',
    details: 'The amount of time a rep waits before responding is often correlated with deal success. BuyerSight found that deals are most successful when reps respond within :targetValue days of receiving an inbound email. Coach reps to reply quickly and stay under this threshold',
  },
  word_count_mean: {
    codename: 'word_count_mean',
    enabled: true,
    color: RECOMMENDATION_COLORS.RED,
    icon: 'pencil',
    insight: 'Prospecting emails with fewer than :targetValue words tend to get more responses',
    details: 'Shorter prospecting emails tend to get more responses. For your team, BuyerSight found that emails under :targetValue words performed the best. Coach reps to keep prospecting emails under this limit.',
  },
  rep_followup_time_mean: {
    codename: 'rep_followup_time_mean',
    enabled: true,
    color: RECOMMENDATION_COLORS.PURPLE,
    icon: 'stopwatch',
    insight: 'Follow ups are sent within :targetValue days',
    details: 'Buyersight found that your team has more success when follow ups are sent less than :targetValue days later. Waiting more than :targetValue days between follow ups reduces the chance of a response.',
  },
  prospect_response_time_mean: {
    codename: 'prospect_response_time_mean',
    enabled: true,
    color: RECOMMENDATION_COLORS.GREEN,
    icon: 'comments',
    insight: 'Prospects tend to respond within :targetValue days',
    details: 'BuyerSight found that deals are less likely to be successful when the prospect has not sent an inbound email, answered a call, or attending a meeting within :targetValue days of the last outbound activity.',
  },
  big_gap_count: {
    codename: 'big_gap_count',
    enabled: true,
    color: RECOMMENDATION_COLORS.RED,
    icon: 'lightbulb',
    insight: 'BuyerSight found large gaps in activity over 15 days are not associated with success',
    details: 'BuyerSight found large gaps in activity over 15 days are not associated with success. We recommend maintaining a consistent flow of activity with prospects.',
  },
};
/* eslint-enable @typescript-eslint/camelcase */

const RecommendationCard = (props: {
  codename: ProspectingMetricCodename;
  partition: 'preConversation' | 'postConversation';
  metrics?: ProspectingMetric[];
}): React.ReactElement => {
  const { codename, partition, metrics } = props;
  const teamId = useSelector(getSelectedTeamId);
  const [showDetails, setShowDetails] = useState(false);

  if (!teamId) {
    return <></>;
  }

  const replaceFn = valueMap => (match, i): React.ReactNode => {
    const key = `${match}-${i}`;
    const value = typeof valueMap === 'object' ? valueMap[match] : valueMap;
    return (
      <span key={key} className={css.value} style={{ color }}>
        {value}
      </span>
    );
  };

  const {
    data,
    isLoading,
  } = prospectingApi.endpoints.getProspectingStrategyInsights.useQuery({ teamId });

  const metric = metrics?.find(m => m.metricName === codename);
  let insightCodename = codename;
  let metricSection: React.ReactNode = null;
  let recConfig: RecommendationDetails | undefined;
  if (metric) {
    insightCodename = recommendationToInsightMap[codename] || codename;
    recConfig = recommendationDetailsConfig[codename];
  }

  let insightWithValue: React.ReactNode = <Loader active />;
  let detailsSection: React.ReactNode = null;
  const metricDetails = prospectingMetricDetails[insightCodename];
  if (!metricDetails) return <></>;
  const { enabled, icon, insight, color, details } = metricDetails;

  const iconColor = '#FFFFFF';
  const iconBg = color;

  if (metric && recConfig) {
    let rawValue = metric.value || 0;
    if (codename === 'activity_velocity') rawValue *= 7;
    if (codename === 'is_multi_channel') rawValue = 5;
    const actualValue = formatStat(rawValue);
    metricSection = reactStringReplace(recConfig.recommendation, /:(\w+)/, replaceFn({ actualValue }));
    metricSection = (
      <div className={css.recommendation}>
        {metricSection}
      </div>
    );
  } else if (metricDetails.insight === undefined) {
    // this metric does not have a strategy insight to display
    return <></>;
  }

  if (!enabled) return <></>;

  if (!isLoading && data) {
    const thresholds = data[partition];
    const threshold = thresholds.find(t => t.metricName === insightCodename);
    if (threshold === undefined) return <></>;
    const targetValue = formatStat(threshold.meanWon);

    // special logic for the activity count recommendation
    if (insightCodename === 'activity_count') {
      const timeThreshold = thresholds.find(t => t.metricName === 'time_in_current');
      const inboundThreshold = thresholds.find(t => t.metricName === 'inbound_email_count');
      const outboundThreshold = thresholds.find(t => t.metricName === 'outbound_email_count');
      const callThreshold = thresholds.find(t => t.metricName === 'call_count');
      const meetingThreshold = thresholds.find(t => t.metricName === 'meeting_count');
      const linkedInThreshold = thresholds.find(t => t.metricName === 'linkedin_count');
      const smsThreshold = thresholds.find(t => t.metricName === 'sms_count');

      let timeStr: React.ReactNode = '';
      let timeValue;
      let velocityStr: React.ReactNode = '';
      if (timeThreshold?.meanWon) {
        timeStr = ' and :timeValue days';
        timeValue = formatStat(timeThreshold.meanWon);
        const velocity = formatStat(threshold.meanWon / timeThreshold.meanWon * 7);
        velocityStr = reactStringReplace(' (:x activities per week)', ':x', replaceFn(velocity));
      }

      const activityBreakdowns: React.ReactNode[] = [];
      if (inboundThreshold?.meanWon && inboundThreshold.meanWon > 0.1) {
        activityBreakdowns.push(reactStringReplace(':x inbound emails', ':x', replaceFn(formatStat(inboundThreshold.meanWon))));
      }
      if (outboundThreshold?.meanWon && outboundThreshold.meanWon > 0.1) {
        activityBreakdowns.push(reactStringReplace(':x outbound emails', ':x', replaceFn(formatStat(outboundThreshold.meanWon))));
      }
      if (callThreshold?.meanWon && callThreshold.meanWon > 0.1) {
        activityBreakdowns.push(reactStringReplace(':x calls', ':x', replaceFn(formatStat(callThreshold.meanWon))));
      }
      if (meetingThreshold?.meanWon && meetingThreshold.meanWon > 0.1) {
        activityBreakdowns.push(reactStringReplace(':x meetings', ':x', replaceFn(formatStat(meetingThreshold.meanWon))));
      }
      if (linkedInThreshold?.meanWon && linkedInThreshold.meanWon > 0.1) {
        activityBreakdowns.push(reactStringReplace(':x LinkedIn messages', ':x', replaceFn(formatStat(linkedInThreshold.meanWon))));
      }
      if (smsThreshold?.meanWon && smsThreshold.meanWon > 0.1) {
        activityBreakdowns.push(reactStringReplace(':x SMS messages', ':x', replaceFn(formatStat(smsThreshold.meanWon))));
      }

      /* eslint-disable max-len */
      const activityInsight = (partition === 'postConversation')
        ? `After a conversation starts, getting to your success criteria takes :targetValue activities${timeStr} on average.`
        : `Average activities required to get to conversation: :targetValue activities${timeStr}.`;
      /* eslint-enable max-len */

      insightWithValue = reactStringReplace(activityInsight, /:(\w+)/, replaceFn({
        targetValue,
        timeValue,
      }));

      if (velocityStr) {
        insightWithValue = [insightWithValue, velocityStr];
      }

      /* eslint-disable max-len */
      const detailsIntro = (partition === 'postConversation')
      ? `BuyerSight found that after a conversation starts, your team takes :targetValue activities${timeStr} on average to get to your success criteria`
      : `BuyerSight found your team usually needs :targetValue activities${timeStr} to get a conversation on average`;

      const activityBreakdownNodes = activityBreakdowns.reduce((prev, curr) => [prev, ', ', curr]);
      /* eslint-enable max-len */

      detailsSection = [
        reactStringReplace(detailsIntro, /:(\w+)/, replaceFn({ targetValue, timeValue })),
        velocityStr,
        '. Coach reps to increase the pace of activities to increase the chances of success.',
        (<p key="filler" />),
        (<p key="breakdown">{activityBreakdownNodes}</p>),
      ];
    } else if (insightCodename === 'is_multi_channel') {
      const { validationCriticalWon, validationCriticalLost } = threshold;

      const lift = `${formatStat((1 - (validationCriticalWon / validationCriticalLost)) * 100)}%`;

      insightWithValue = reactStringReplace(insight, ':lift', replaceFn(lift));
      detailsSection = reactStringReplace(details, ':lift', replaceFn(lift));
    } else {
      insightWithValue = reactStringReplace(insight, ':targetValue', replaceFn(targetValue));
      detailsSection = reactStringReplace(details, ':targetValue', replaceFn(targetValue));
    }
  }

  const display = showDetails ? 'inherit' : 'none';
  const handleClick = (): void => setShowDetails(!showDetails);

  const cardTitle = metricSection || insightWithValue;

  return (
    <Card className={css.recommendationCard} onClick={handleClick}>
      <Card.Content as={Grid}>
        <Grid.Row>
          <Grid.Column width={2} className={css.cardIconColumn}>
            <div className={css.iconBg} style={{ backgroundColor: iconBg }}>
              <Icon name={icon} size="large" style={{ color: iconColor }} className={css.icon} />
            </div>
          </Grid.Column>
          <Grid.Column width={14} verticalAlign="middle">
            <div className={css.insight}>
              {cardTitle}
            </div>
            <div className={css.details} style={{ display }}>
              {detailsSection}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Card.Content>
    </Card>
  );
};

export default RecommendationCard;
