import React, { useState } from 'react';
import { Grid, GridColumn, GridRow, Header, Radio, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { AccountTimeline } from '../../components/AccountTimeline/AccountTimeline';

export const AccountHealthTimeline: React.FC<{
  accountId: number;
  opportunityId?: number;
}> = ({ accountId, opportunityId }) => {
  const [showIdeal, setShowIdeal] = useState<boolean>(false);
  return (
    <Segment>
      <Grid stretched>
        <GridRow>
          <GridColumn width={10}>
            <Header>
              <Link to={`/app/account/${accountId}/activity`}>
                Account Timeline
              </Link>
            </Header>
          </GridColumn>
          <GridColumn width={6} style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-evenly' }}>
            Toggle Ideal Timeline:
            <Radio
              toggle
              checked={showIdeal}
              onClick={(): void => setShowIdeal(!showIdeal)}
              style={{ flexGrow: 'revert' }}
            />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn width={16}>
            <AccountTimeline accountId={accountId} opportunityId={opportunityId} showIdeal={showIdeal} height={500} />
          </GridColumn>
        </GridRow>
      </Grid>
    </Segment>
  );
};
