import { Notification, AlertRule, RulesNotifications } from 'models/alert';
import AlertLanguageProcessor from 'lib/AlertLanguageProcessor';
import { Recommendation } from '../models/recommendation';

export const getUnreadNotificationsCount = ({ alert, recommendation }): number => {
  const alertNotificationsCount = alert.notifications && alert.notifications.length
    ? alert.notifications.filter(
      (notification: Notification) => !notification.seen && notification.active,
    )?.length
    : 0;
  const recommentationNotificationsCount = recommendation.sidebar && recommendation.sidebar.length
    ? recommendation.sidebar.filter(
      (rec: Recommendation) => !rec.seenAt && rec.active,
    )?.length
    : 0;
  return alertNotificationsCount + recommentationNotificationsCount;
};

// Ids of unread notifications
export const getUnreadNotificationIds = ({ alert }): Array<number> => (
  alert.notifications.reduce(
    (filtered: Array<number>, notification: Notification) => {
      if (!notification.seen) {
        filtered.push(notification.id);
      }
      return filtered;
    }, [],
  )
);

export const getActiveNotifications = ({ alert }): Array<Notification> => (
  alert.sidebarNotifications?.filter(
    (notification: Notification) => notification.active
  )
);

export const getRulesWithNotifications = ({ user, alert }): Array<RulesNotifications> => alert.rules.reduce(
  (filtered: Array<RulesNotifications>, alertRule: AlertRule) => {
    if (!alertRule.deleted) {
      const { users } = user;
      const notifications = alert.notifications.filter(
        (notification: Notification) => notification.alertRuleId === alertRule.id && notification.active,
      );

      // map the userId and deliveryTo here
      const userName = users.find(u => u.id === alertRule.userId)?.name || '';
      const deliveryToNames = alertRule.deliveryTo
        .map(deliveryToId => users.find(u => u.id === deliveryToId)?.name || '')
        .filter(a => a !== '');

      const description = AlertLanguageProcessor.getAlertRuleStatement({
        id: alertRule.id,
        userName,
        metric: alertRule.metric,
        metricName: alertRule.metricName,
        thresholdOperator: alertRule.thresholdOperator,
        thresholdValue: alertRule.thresholdValue,
        timeFrame: alertRule.timeFrame,
        deliveryMethod: alertRule.deliveryMethod,
        alertFrequency: alertRule.alertFrequency,
        alertLimit: alertRule.alertLimit,
        deliveryTo: deliveryToNames,
      });

      if (description !== undefined) {
        filtered.push({
          ...alertRule,
          description,
          notifications,
        });
      }
    }

    return filtered;
  }, [],
);


export const getUniqueMetricsFromAlertRules = ({ alert }): Array<string> => alert.rules.map(
  (alertRule: AlertRule) => alertRule.metric,
).filter((value, index, self) => self.indexOf(value) === index);
