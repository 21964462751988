import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';

import { AccountApi, prospectingApi } from 'features/Api';
import { useGetTeamNotificationCountsQuery } from 'features/Api/Team/getNotificationCounts';
import { getIsProspecting } from 'selectors/team';

import css from './styles.module.css';

function AlertsSegment(props: {
  userId: number;
  teamId: number;
}): ReactElement {
  const { userId, teamId } = props;

  const { data, isFetching } = useGetTeamNotificationCountsQuery({ teamId });

  let text = 'Error';
  if (isFetching) text = 'Loading...';
  else if (data && typeof data.notifications[userId] === 'number') {
    text = `${data.notifications[userId]} Alerts`;
  }
  return (
    <Link to={`/app/alerts?users=${userId}`}>
      <Segment>
        {text}
      </Segment>
    </Link>
  );
}

function AccountsHealthSegments(props: {
  userId: number;
  teamId: number;
}): ReactElement {
  const { userId, teamId } = props;
  const { data, isFetching } = AccountApi.getAllAccounts.useGetAccountHealthQuery({ teamId, ownerId: userId });

  const getCounts = (accountHealth: AccountApi.getAllAccounts.Response): {
    countUnhealthy: number;
    countNeedsAttn: number;
  } => {
    let countUnhealthy = 0;
    let countNeedsAttn = 0;
    if (accountHealth.active) {
      accountHealth.active.forEach(account => {
        const isUnhealthy = account.status === 'critical';
        const isNeedsAttn = account.status === 'warning';
        if (isUnhealthy) countUnhealthy += 1;
        else if (isNeedsAttn) countNeedsAttn += 1;
      });
    }
    return {
      countUnhealthy,
      countNeedsAttn,
    };
  };

  let countUnhealthyText = 'Error';
  let countNeedsAttnText = 'Error';
  if (isFetching) {
    countUnhealthyText = 'Loading...';
    countNeedsAttnText = 'Loading...';
  } else if (data) {
    const { countNeedsAttn, countUnhealthy } = getCounts(data);
    countUnhealthyText = `${countUnhealthy} Unhealthy Accounts`;
    countNeedsAttnText = `${countNeedsAttn} Accounts Need Attention`;
  }

  return (
    <>
      <Link to={`/app/account?pane=active&ownerId=${userId}`}>
        <Segment>
          {countUnhealthyText}
        </Segment>
      </Link>
      <Link to={`/app/account?pane=active&ownerId=${userId}`}>
        <Segment>
          {countNeedsAttnText}
        </Segment>
      </Link>
    </>
  );
}

function ProspectingHealthSegment(props: {
  userId: number;
  teamId: number;
}): ReactElement {
  const { userId, teamId } = props;

  const { data, isFetching } = prospectingApi.endpoints.getProspectingAccounts.useQuery({ teamId, ownerId: userId });
  const {
    data: noAccountData,
    isFetching: noAccountFetching,
  } = prospectingApi.endpoints.getProspectingContactsNoAccount.useQuery({ teamId, ownerId: userId });

  let accountsWithRecommendations = 'Error';
  let contactsWithRecommendations = 'Error';

  if (isFetching || noAccountFetching) {
    accountsWithRecommendations = 'Loading...';
    contactsWithRecommendations = 'Loading...';
  } else if (data && noAccountData) {
    const noAccountContacts = noAccountData?.contacts?.filter(c => c.ownerId === userId);
    const noAccountRecs = noAccountContacts?.filter(c => c.recommendations?.length).length || 0;

    const { accounts } = data;
    const userAccounts = accounts?.filter(a => a.ownerId === userId) || [];
    const accountCnt = userAccounts.filter(a => a.contactsWithRecommendations > 0).length;
    const contactCnt = userAccounts.reduce((prev, a) => prev + a.contactsWithRecommendations, noAccountRecs);
    accountsWithRecommendations = `${accountCnt} Accounts with recs`;
    contactsWithRecommendations = `${contactCnt} Contacts with recs`;
  }

  return (
    <>
      <Link to={`/app/prospecting?owners=${userId}&hasRecommendation`}>
        <Segment>
          {accountsWithRecommendations}
        </Segment>
      </Link>
      <Link to={`/app/prospecting?owners=${userId}&hasRecommendation`}>
        <Segment>
          {contactsWithRecommendations}
        </Segment>
      </Link>
    </>
  );
}

export function Notifications(props: {
  userId: number;
  teamId: number;
  startDateIso: string;
  endDateIso: string;
}): ReactElement {
  const { userId, teamId, startDateIso, endDateIso } = props;
  const isProspecting = useSelector(getIsProspecting);

  const section = isProspecting
    ? <ProspectingHealthSegment userId={userId} teamId={teamId} />
    : <AccountsHealthSegments userId={userId} teamId={teamId} />;

  return (
    <Segment className={css.Notifications}>
      <h4>Notifications</h4>
      <div>
        <AlertsSegment userId={userId} teamId={teamId} startDateIso={startDateIso} endDateIso={endDateIso} />
        {section}
      </div>
    </Segment>
  );
}
