import { handleActions } from 'redux-actions';
import actions from 'actions';
import { Recommendation } from 'models/recommendation';

interface AdminState {
  organizations: any[];
  selectedOrganizationId?: number;
  users: any[];
  recommendations: Recommendation[];
  recommendationTags: string[];
  isTagsLoaded: boolean;
}

let savedSelectedOrganizationId;
const savedValue = sessionStorage.getItem('adminSelectedOrganizationId');
if (savedValue) {
  savedSelectedOrganizationId = parseInt(savedValue, 10);
}

const initialState: AdminState = {
  organizations: [],
  selectedOrganizationId: savedSelectedOrganizationId,
  users: [],
  recommendations: [],
  recommendationTags: [],
  isTagsLoaded: false,
};

const reducer = handleActions(
  {
    [actions.admin.fetchOrganizationsSuccess]: (state, { payload: { organizations } }) => ({
      ...state,
      organizations,
    }),
    [actions.admin.fetchUsersByOrgSuccess]: (state, { payload: { users } }) => ({
      ...state,
      users,
    }),
    [actions.admin.fetchRecommendationsByOrgSuccess]: (state, { payload: { recommendations } }) => ({
      ...state,
      recommendations,
    }),
    [actions.admin.fetchRecommendationTagsByOrgSuccess]: (state, { payload: { tags: recommendationTags } }) => ({
      ...state,
      recommendationTags,
      isTagsLoaded: true,
    }),
    [actions.admin.setSelectedOrganization]: (state, { payload: { selectedOrganizationId } }) => {
      sessionStorage.setItem('adminSelectedOrganizationId', selectedOrganizationId);
      return {
        ...state,
        selectedOrganizationId,
      };
    },
  },
  initialState,
);

export default reducer;
