import React from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Modal,
  InputOnChangeData,
  Button,
} from 'semantic-ui-react';
import * as Sentry from '@sentry/browser';

import api from 'lib/api';
import allActions, { bindCombinedActions } from 'actions';

import css from './AddTeamModal.module.css';

interface AddTeamModalBaseProps {
  actions: any;
  open: boolean;
  onClose: () => void;
}

const AddTeamModalBase: React.FC<AddTeamModalBaseProps> = ({
  open,
  onClose,
  actions,
}: AddTeamModalBaseProps): React.ReactElement => {
  const [teamName, setTeamName] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleTeamNameChange = (_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
    setTeamName(data.value);
  };

  const handleAddTeam = (): void => {
    setLoading(true);
    api.createTeam(teamName).then(() => {
      setLoading(false);
      onClose();
      actions.team.fetchTeams();
    }).catch(err => {
      Sentry.captureException(err);
    });
  };

  return (
    <Modal closeIcon closeOnDimmerClick open={open} onClose={onClose}>
      <Modal.Header>
        Add Team
      </Modal.Header>
      <Modal.Content>
        <Input
          fluid
          placeholder="Team Name"
          onChange={handleTeamNameChange}
          value={teamName}
        />
      </Modal.Content>
      <Modal.Actions className={css.addTeamButtonContainer}>
        <Button loading={loading} disabled={teamName === ''} onClick={handleAddTeam}>Add</Button>
      </Modal.Actions>
    </Modal>
  );
};
export default connect(
  null,
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(AddTeamModalBase);
