import React, { FC, ReactElement, ReactNode, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Grid,
  Icon,
  Image,
  InputOnChangeData,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from 'semantic-ui-react';

import { getSelectedTeamMembers, getSelectedTeamId } from 'selectors/team';

import { selectAppDateSelection } from 'features/App/appSlice';
import { getApiDatesFromTimePeriod } from 'features/DatePicker/datePickerFunctions';

import { TeamApi } from 'features/Api';
import { GoalAttainment } from 'features/Api/Goals';
import { ActivityIcons } from 'models/activity';
import { User } from 'models/user';

import BSIcon from 'components/BSIcon';
import BSBadgedIcon from 'components/BSBadgedIcon/BSBadgedIcon';
import ClearableInput from 'components/Input/ClearableInput';

import NavHeader from '../../NavHeader';

import css from './SDRManagerList.module.css';

interface State {
  tableData: User[];
  sortColumn: 'name' | undefined;
  sortDirection: 'descending' | 'ascending' | undefined;
  filters: {
    name: string;
  };
}

const getInitialState = (tableData: User[]): State => ({
  tableData,
  sortColumn: undefined,
  sortDirection: undefined,
  filters: {
    name: '',
  },
});

const stateReducer = (state: State, action): State => {
  switch (action.type) {
    case 'UPDATE_TABLE_DATA':
      return {
        ...state,
        tableData: action.tableData,
      };
    case 'CHANGE_SORT':
      if (state.sortColumn === action.sortColumn) {
        return {
          ...state,
          tableData: state.tableData.slice().reverse(),
          sortDirection: state.sortDirection === 'ascending' ? 'descending' : 'ascending',
        };
      }

      return {
        ...state,
        sortColumn: action.column,
        tableData: state.tableData.sort((a, b) => a.name.localeCompare(b.name)),
        sortDirection: 'ascending',
      };
    case 'CHANGE_NAME_FILTER':
      return {
        ...state,
        filters: {
          name: action.nameFilter,
        },
      };
    default:
      throw new Error();
  }
};

interface ManagerListProps {}

export const SDRManagerList: FC<ManagerListProps> = () => {
  const teamId = useSelector(getSelectedTeamId);
  const teamUsers = useSelector(getSelectedTeamMembers);
  const [state, dispatch] = useReducer(stateReducer, getInitialState(teamUsers));

  if (!teamId) return null;

  if (state.tableData.length !== teamUsers.length) {
    dispatch({ type: 'UPDATE_TABLE_DATA', tableData: teamUsers });
  }

  const {
    data: goalsApiResponse,
    isFetching,
  } = TeamApi.Goals.useGetTeamGoalAttainmentQuery({ teamId });

  const { tableData, sortColumn, sortDirection, filters: { name: nameFilter } } = state;

  let goalTitle: ReactNode = '';
  const primaryGoal = goalsApiResponse?.goals[0] || undefined;
  if (primaryGoal) {
    const { goal, period } = primaryGoal;
    const { displayName } = goal;
    const timePeriodStr = `${period.index === 0 ? 'Current' : 'Previous'} ${period.unit}`;
    goalTitle = `${displayName} (${timePeriodStr})`;
  }

  const rows = tableData.map((user: User): React.ReactNode => {
    if (!user.id || !user.isTracked) return null;
    if (user.name.toLowerCase().indexOf(nameFilter.toLowerCase()) === -1) return null;
    const userId = user.id;
    const goalAttainment: GoalAttainment | undefined = primaryGoal?.goalAttainments && !isFetching
      ? primaryGoal.goalAttainments[userId]
      : undefined;
    return (
      <UserRow user={user} key={userId} attainment={goalAttainment} />
    );
  });

  function handleSearch(_, searchData: InputOnChangeData): void {
    dispatch({ type: 'CHANGE_NAME_FILTER', nameFilter: searchData.value.trim() });
  }

  return (
    <>
      <NavHeader>
        <Menu.Item>
          <ClearableInput placeholder="Search Names" onChange={handleSearch} />
        </Menu.Item>
      </NavHeader>
      <Table sortable basic="very" fixed className={css.ManagerList}>
        <TableHeader>
          <TableRow>
            <TableHeaderCell
              width={4}
              sorted={sortColumn === 'name' ? sortDirection : undefined}
              onClick={(): void => dispatch({ type: 'CHANGE_SORT', column: 'name' })}
            >
              Name
            </TableHeaderCell>
            <TableHeaderCell width={5}>Activities</TableHeaderCell>
            <TableHeaderCell className={css.numberMetric}>
              {goalTitle}
            </TableHeaderCell>
            <TableHeaderCell className={css.numberMetric}>Avg Grade Level</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {rows}
        </TableBody>
      </Table>
    </>
  );
};

function NameCell(props: {
  user: User;
}): ReactElement {
  const { user: { id, name, photoUrl } } = props;
  return (
    <TableCell>
      <Link className={css.statLink} to={`/app/team/${id}`}>
        <div className={`${css.stat} ${css.profile}`}>
          <div>
            {
              photoUrl
                ? (<Image src={photoUrl} circular className={css.userPhoto} />)
                : <Icon name="user" size="big" />
            }
          </div>
          <div className={css.userInfo}>
            {name}
          </div>
        </div>
      </Link>
    </TableCell>
  );
}

function ActivitiesCell(props: { userId: number }): ReactElement {
  const { userId } = props;
  const teamId = useSelector(getSelectedTeamId) || 0;
  const timePeriod = useSelector(selectAppDateSelection);

  const { start, end } = getApiDatesFromTimePeriod(timePeriod);
  const { data, isFetching } = TeamApi.getActivityStats.useActivityStatsQuery({
    teamId,
    startDate: start,
    endDate: end,
  });

  if (isFetching || !data?.activityCounts.members[userId]) return <TableCell>Loading...</TableCell>;

  const userStats = data.activityCounts.members[userId];

  const {
    types: {
      email: {
        total: mailCount,
        types: {
          inbound: inboundCount,
          outbound: outboundCount,
        },
      },
      call: { total: callCount },
      event: { total: meetingCount },
      task: {
        total: tasksCount,
        types: {
          sms: smsCount,
          linkedin: linkedinCount,
        },
      },
    },
  } = userStats;
  const activityCount: Array<{ icon: ActivityIcons; count?: number; title: string; type: string }> = [
    { icon: 'mail', count: mailCount, title: 'Email', type: 'Email' },
    { icon: 'call', count: callCount, title: 'Call', type: 'Call' },
    { icon: 'calendar', count: meetingCount, title: 'Event', type: 'Event' },
    { icon: 'tasks', count: tasksCount + smsCount + linkedinCount, title: 'Task', type: 'Task' },
  ];
  const summary = activityCount.map(activity => {
    const { icon, count = 0 } = activity;
    let popup: ReactElement | string | undefined;
    if (icon === 'mail') {
      popup = (
        <Grid className={css.popupContent}>
          <Grid.Row>
            <Grid.Column width="10" className={css.header}>
              <BSIcon
                activityIcon="mail"
                circular
                bordered
                cornerIcon={(<Icon corner name="arrow left" />)}
              />
              Inbound
            </Grid.Column>
            <Grid.Column width="6">
              {inboundCount}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="10" className={css.header}>
              <BSIcon
                activityIcon="mail"
                circular
                bordered
                cornerIcon={(<Icon corner name="arrow right" />)}
              />
              Outbound
            </Grid.Column>
            <Grid.Column width="6">
              {outboundCount}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="16">
              <i>Automatic responses are filtered from the scorecard metrics</i>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    } else if (icon === 'tasks') {
      popup = (
        <Grid className={css.popupContent}>
          <Grid.Row>
            <Grid.Column width="10" className={css.header}>
              <BSIcon activityIcon="tasks" circular bordered />
              Tasks
            </Grid.Column>
            <Grid.Column width="6">
              {tasksCount}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="10" className={css.header}>
              <BSIcon activityIcon="sms" circular bordered />
              SMS
            </Grid.Column>
            <Grid.Column width="6">
              {smsCount}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="10" className={css.header}>
              <BSIcon activityIcon="linkedin" circular bordered />
              LinkedIn
            </Grid.Column>
            <Grid.Column width="6">
              {linkedinCount}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
    return (
      <BSBadgedIcon
        badge={count}
        activityIcon={icon}
        size="small"
        style={{ margin: '.1rem' }}
        key={`${icon}-${userId}`}
        popup={popup}
      />
    );
  });
  return (
    <TableCell className={css.activities}>
      <Link className={css.statLink} to={`/app/team/${userId}/activityFeed`}>
        {summary}
      </Link>
    </TableCell>
  );
}

function GoalCell(props: { userId: number; attainment?: GoalAttainment }): ReactElement {
  const { userId, attainment } = props;
  let content = '--';
  if (attainment) {
    const percent = Math.round(100 * (attainment.value / attainment.target));
    content = `${attainment.value} (${percent}%)`;
  }

  return (
    <TableCell className={css.numberMetric}>
      <Link className={css.statLink} to={`/app/team/${userId}`}>
        {content}
      </Link>
    </TableCell>
  );
}

function AvgGradeLevelCell(props: { userId: number }): ReactElement {
  const { userId } = props;
  const teamId = useSelector(getSelectedTeamId) || 0;
  const timePeriod = useSelector(selectAppDateSelection);
  const { start, end } = getApiDatesFromTimePeriod(timePeriod);
  const { data, isFetching } = TeamApi.getActivityStats.useActivityStatsQuery({
    teamId,
    startDate: start,
    endDate: end,
  });

  if (isFetching || !data?.emailStats.members[userId]) return <TableCell>Loading...</TableCell>;

  const userStats = data.emailStats.members[userId];
  const { avgGradeLevel } = userStats;
  const avgGradeLevelStr = avgGradeLevel !== null ? avgGradeLevel.toFixed(1) : '--';
  return (
    <TableCell className={css.numberMetric}>
      <Link className={css.statLink} to={`/app/team/${userId}/activityFeed`}>
        {avgGradeLevelStr}
      </Link>
    </TableCell>
  );
}

function UserRow(props: {
  user: User;
  attainment?: GoalAttainment;
}): ReactElement {
  const { user, attainment } = props;
  const userId = user.id ? user.id : 0;
  return (
    <TableRow>
      <NameCell user={user} />
      <ActivitiesCell userId={userId} />
      <GoalCell userId={userId} attainment={attainment} />
      <AvgGradeLevelCell userId={userId} />
    </TableRow>
  );
}

export default SDRManagerList;
