import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Activity } from 'models/activity';
import BSChart from 'components/BSChart';
import { DataSet } from 'components/BSChart/BSChart';
import { selectAppStartAndEndDates } from 'features/App/appSlice';
import { SimpleJSDateTimePeriod } from 'features/DatePicker/datePickerTypes';

interface Props {
  activities: Array<Activity>;
  isLoading: boolean;
  userInfo: any;
  timePeriod: SimpleJSDateTimePeriod;
}

interface State { }

class UserActivityTypesOverTimeGraph extends Component<Props, State> {
  getChartData(): object {
    if (!this.props) return {};
    const { activities, timePeriod: { start, end } } = this.props;
    const accountsPerDay = {};
    const dateFormat = 'YYYY-MM-DD';
    activities.forEach(activity => {
      const activityDate = moment(activity.activityDate).startOf('day');
      const key = activityDate.format(dateFormat);
      const activityAccount = activity.accountId;
      if (!accountsPerDay[key]) accountsPerDay[key] = {};
      if (!accountsPerDay[key][activityAccount]) accountsPerDay[key][activityAccount] = true;
    });
    const startDate = moment(start).startOf('day');
    const endDate = moment.min(moment(end), moment()).endOf('day');
    const data: object[] = [];
    const iterator = moment(startDate).clone();
    const accountsPerDayArr: Array<{ x: string | number; y: number }> = [];
    while (iterator.isSameOrBefore(endDate)) {
      const current = iterator.format(dateFormat);
      if (!accountsPerDay[current]) accountsPerDayArr.push({ x: current, y: 0 });
      else {
        accountsPerDay[current] = accountsPerDayArr.push({
          x: current,
          y: Object.keys(accountsPerDay[current]).length,
        });
      }
      iterator.add(1, 'day');
    }
    data.push({
      label: 'Accounts',
      data: accountsPerDayArr,
      backgroundColor: 'rgba(33,133,208,.7)',
      borderColor: 'rgba(33,133,208,.7)',
      fill: false,
    });
    return data;
  }

  render(): React.ReactNode {
    const { timePeriod: { start, end }, isLoading, userInfo } = this.props;
    const { id } = userInfo;
    const datasets = this.getChartData();
    return (
      <Segment>
        <BSChart
          startDate={start}
          endDate={end}
          datasets={datasets as DataSet[]}
          title="Accounts Contacted Over Time"
          titleLink={`/app/team/${id}/accounts`}
          isLoading={isLoading}
        />
      </Segment>
    );
  }
}

export default connect(
  (state: any) => ({
    activities: state.activity.activities,
    isLoading: state.activity.isLoadingActivities,
    userInfo: state.user.userInfo,
    timePeriod: selectAppStartAndEndDates(state),
  }),
)(UserActivityTypesOverTimeGraph);
