import React from 'react';
import { Table, Icon } from 'semantic-ui-react';

import css from './RulesHeader.module.css';

interface RulesHeaderProps {
  column: string | null;
  direction: any;
  onSort: Function;
}

const RulesHeader: React.FC<RulesHeaderProps> = ({ column, direction, onSort }) => (
  <Table.Header>
    <Table.Row className={css.header}>
      <Table.HeaderCell>
        <Icon name="bell" />
      </Table.HeaderCell>
      <Table.HeaderCell
        sorted={column === 'mostRecentNotification' ? direction : null}
        onClick={(): void => onSort('mostRecentNotification')}
      >
        Most Recent Notification
      </Table.HeaderCell>
      <Table.HeaderCell
        sorted={column === 'notificationCount' ? direction : null}
        onClick={(): void => onSort('notificationCount')}
      >
        Count
      </Table.HeaderCell>
      <Table.HeaderCell>
        Alert Description
      </Table.HeaderCell>
      <Table.HeaderCell />
      <Table.HeaderCell />
      <Table.HeaderCell />
    </Table.Row>
  </Table.Header>
);

export default RulesHeader;
