import param from 'jquery-param';

import { Threshold } from 'models/team/settings/accountHealth';
import { Account } from 'models/account';
import { User } from 'models/user';

import baseApi from '../apiSlice';
import { ApiResponse } from '../apiTypes';

const ONE_HOUR = 3600;
const TEN_MINUTES = 600;

export interface ProspectingHealthQuery {
  teamId: number;
  ownerId?: number;
}

export interface ContactsByAccountQuery {
  teamId: number;
  accountId: number;
}

export interface ContactQuery {
  teamId: number;
  contactId: number;
}

export interface ProspectingSearchQuery {
  search: string;
}

export interface StrategyResponse extends ApiResponse {
  preConversation: Array<Threshold & {id: number}>;
  postConversation: Array<Threshold & {id: number}>;
}

export type ProspectingPhase = 'pre conversation' | 'post conversation';
export type ProspectingRecommendation = 'activity_velocity' | 'big_gap_count' | 'flesch_kincaid_grade_mean' | 'prospect_response_time_mean' | 'rep_followup_time_mean' | 'rep_response_time_mean' | 'is_multi_channel' | 'word_count_mean' | 'needs_response';
export interface ProspectingAccount extends Account {
  contactsWithRecommendations: number;
  recommendations: ProspectingRecommendation[];
  preConversationRecommendations?: ProspectingRecommendation[];
  postConversationRecommendations?: ProspectingRecommendation[];
  numRecommendations: number;
  numContacts: number;
  prospectingPhase: ProspectingPhase;
  ownerName: string;
  activityOwnerIds: number[];
}

export interface AccountResponse extends ApiResponse {
  accounts: ProspectingAccount[];
  activityOwners: User[];
}

const prospectingMetrics = [
  'activity_count',
  'activity_velocity',
  'big_gap_count',
  'big_gap_mean',
  'flesch_kincaid_grade_mean',
  'rep_followup_time_mean',
  'rep_response_time_mean',
  'needs_response',
  'prospect_response_time_mean',
  'time_in_current',
  'word_count_mean',

  'contact_count',
  'engaged_contact_count',

  'is_multi_channel',
  'inbound_email_count',
  'outbound_email_count',
  'call_count',
  'meeting_count',
  'linkedin_count',
  'sms_count',
] as const;

export type ProspectingMetricCodename = typeof prospectingMetrics[number];

export interface ProspectingContact {
  accountId: number;
  active: boolean;
  createdAt: string;
  customData: null;
  data: any;
  deleted: boolean;
  email: string;
  extId: string;
  extSource: string;
  firstActivity: string;
  id: number;
  isActive?: boolean;
  isNew: boolean;
  lastSync: string;
  leadSource: null;
  name: string;
  numActivities: number;
  numRecommendations: number;
  organizationId: number;
  ownerId: number;
  activityOwnerIds: number[];
  prospectingPhase: ProspectingPhase;
  recommendations: ProspectingMetricCodename[];
  sfId: string;
  title: string;
  type: string;
}

export interface ContactsResponse extends ApiResponse {
  contacts: ProspectingContact[];
  owners: User[];
}

export interface ProspectingMetric extends Threshold {
  contactId: number;
  accountId?: number;
  value?: number;
}

export interface ContactResponse extends ApiResponse {
  contact: ProspectingContact;
  owners: User[];
  metrics: ProspectingMetric[];
}

export interface ProspectingSearchResult {
  contactId?: number;
  accountId?: number;
  name: string;
  type: string;
}

export interface ProspectingSearchResponse extends ApiResponse {
  results: ProspectingSearchResult[];
}

const prospectingApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getProspectingStrategyInsights: builder.query<StrategyResponse, ProspectingHealthQuery>({
      query: ({ teamId }) => `prospecting/strategy/${teamId}`,
      keepUnusedDataFor: ONE_HOUR,
    }),
    getProspectingAccounts: builder.query<AccountResponse, ProspectingHealthQuery>({
      query: ({ teamId, ownerId }) => `prospecting/health/${teamId}/accounts?${param({ ownerId })}`,
      keepUnusedDataFor: TEN_MINUTES,
    }),
    getProspectingInactiveAccounts: builder.query<AccountResponse, ProspectingHealthQuery>({
      query: ({ teamId, ownerId }) => `prospecting/health/${teamId}/accounts/inactive?${param({ ownerId })}`,
      keepUnusedDataFor: TEN_MINUTES,
    }),
    getProspectingContactsByAccount: builder.query<ContactsResponse, ContactsByAccountQuery>({
      query: ({ teamId, accountId }) => `prospecting/health/${teamId}/account/${accountId}/contacts`,
    }),
    getProspectingContactsNoAccount: builder.query<ContactsResponse, ProspectingHealthQuery>({
      query: ({ teamId }) => `prospecting/health/${teamId}/contacts`,
    }),
    getProspectingInactiveContacts: builder.query<ContactsResponse, ProspectingHealthQuery>({
      query: ({ teamId }) => `prospecting/health/${teamId}/contacts/inactive`,
      keepUnusedDataFor: TEN_MINUTES,
    }),
    getProspectingAllContacts: builder.query<ContactsResponse, ProspectingHealthQuery>({
      query: ({ teamId }) => `prospecting/health/${teamId}/contacts/all`,
      keepUnusedDataFor: TEN_MINUTES,
    }),
    getProspectingContact: builder.query<ContactResponse, ContactQuery>({
      query: ({ teamId, contactId }) => `prospecting/health/${teamId}/contacts/${contactId}`,
    }),
    search: builder.query<ProspectingSearchResponse, ProspectingSearchQuery>({
      query: ({ search }) => `prospecting/search/${search}`,
    }),
  }),
});

export const useGetProspectingStrategyInsightsQuery = prospectingApi.endpoints.getProspectingStrategyInsights.useQuery;
export const useGetProspectingAccountsQuery = prospectingApi.endpoints.getProspectingAccounts.useQuery;
export const useGetProspectingInactiveAccountsQuery = prospectingApi.endpoints.getProspectingInactiveAccounts.useQuery;
export const useGetProspectingContactsByAccountQuery = prospectingApi
  .endpoints
  .getProspectingContactsByAccount
  .useQuery;
export const useGetProspectingContactsNoAccountQuery = prospectingApi
  .endpoints
  .getProspectingContactsNoAccount
  .useQuery;
export const useGetProspectingInactiveContactsQuery = prospectingApi
  .endpoints
  .getProspectingContactsNoAccount
  .useQuery;
export const useGetProspectingAllContactsQuery = prospectingApi.endpoints.getProspectingAllContacts.useQuery;

export default prospectingApi;
