import { connect } from 'react-redux';
import React from 'react';
import { isEmpty } from 'lodash';

const UserBreadcrumb = (state): React.ReactElement => {
  if (isEmpty(state.userInfo)) {
    return <>Loading...</>;
  }
  return (
    <span>
      {`${state.userInfo.firstName} ${state.userInfo.lastName}`}
    </span>
  );
};

export default connect(
  (state: any) => ({ userInfo: state.user.userInfo }),
)(UserBreadcrumb);
