import React, { FC } from 'react';
import css from './Header.module.css';

export const TeamComparisonChartHeader: FC<{
  className?: string;
}> = ({ children, className = '' }) => (
  <header className={`${css.header} ${className}`}>
    {children}
  </header>
);
