import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';

import 'semantic-ui-css/semantic.min.css';

import './index.css';

import App from './App';

import store, { history, persistor } from './store';

const RootNode = (): React.ReactElement => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

export default RootNode;
