import { createActions } from 'redux-actions';
import hash from 'object-hash';

import { ActivityAccount, ActivityContact, ActivityOwner, Tag } from 'models/activity';

import api from '../lib/api';
import { handleError } from '.';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const actions = createActions({
  ACTIVITY: {
    DELETE_TAG_RULE_SUCCESS: (id: number) => ({ id }),
    GET_TAGS_REQUEST: null,
    GET_TAGS_SUCCESS: (tags: Array<any>) => ({ tags }),
    GET_TAGS_FAILURE: null,
    GET_TAG_RULES_REQUEST: null,
    GET_TAG_RULES_SUCCESS: (rules: Array<any>) => ({ rules }),
    GET_TAG_RULES_FAILURE: null,
    ADD_TAG_REQUEST: null,
    ADD_TAG_SUCCESS: null,
    ADD_TAG_FAILURE: (msg: string) => ({ msg }),
    REMOVE_TAG_REQUEST: null,
    REMOVE_TAG_SUCCESS: null,
    REMOVE_TAG_FAILURE: (msg: string) => ({ msg }),
    SAVE_TAG_RULE_REQUEST: null,
    SAVE_TAG_RULE_SUCCESS: (rule: any) => ({ rule }),
    SAVE_TAG_RULE_FAILURE: null,
    CLEAR_ACTIVITIES: null,
    SEARCH_ACTIVITIES_REQUEST: null,
    SEARCH_ACTIVITIES_SUCCESS: (activities: Array<any>) => ({ activities }),
    SEARCH_ACTIVITIES_FAILURE: null,
    SET_ACTIVITY_ACCOUNTS: (activityAccounts: Array<ActivityAccount>) => ({ activityAccounts }),
    SET_ACTIVITY_CONTACTS: (activityContacts: Array<ActivityContact>) => ({ activityContacts }),
    SET_ACTIVITY_OWNERS: (activityOwners: Array<ActivityOwner>) => ({ activityOwners }),
    SET_ACTIVITY_TAGS: (activityTags: Array<Tag>) => ({ activityTags }),
    SELECT_ACTIVITY: (activityId: number) => ({ activityId }),
    UNSELECT_ACTIVITY: null,
    SET_ACTIVITY_SEARCH_HASH: (searchHash: string) => ({ searchHash }),
    SET_TAGS_TO_ACTIVITY: (tags: Array<any>, activityId: string) => ({ tags, activityId }),
  },
});
/* eslint-enable @typescript-eslint/explicit-function-return-type */

export const searchActivities = (
  opts: any = {},
  clearActivities = true,
) => async (dispatch: Function, getState: Function): Promise<any> => {
  const { noCache, getTags, getAccounts, getContacts, getOwners, ...searchOpts } = opts;
  const state = getState();
  const searchHash = hash(searchOpts);
  if (clearActivities) {
    dispatch(actions.activity.clearActivities());
  } else if (!noCache && state.activity.searchHash === searchHash) {
    // we have everything we need
    return { status: true };
  }
  dispatch(actions.activity.searchActivitiesRequest());
  const response = await api.searchActivities(opts);
  if (response.status) {
    dispatch(actions.activity.searchActivitiesSuccess(response.activities));
    if (getAccounts) {
      const activityAccounts = response.accounts.filter(account => account.id !== null);
      dispatch(actions.activity.setActivityAccounts(activityAccounts));
    }
    if (getContacts) {
      dispatch(actions.activity.setActivityContacts(response.contacts));
    }
    if (getOwners) {
      dispatch(actions.activity.setActivityOwners(response.users));
    }
    if (getTags) {
      dispatch(actions.activity.setActivityTags(response.tags));
    }
    dispatch(actions.activity.setActivitySearchHash(searchHash));
  } else {
    handleError(response, dispatch);
    dispatch(actions.activity.searchActivitiesFailure());
  }
  return response;
};

export const getGroupedActivities = (
  groupId,
  startDate,
  endDate,
  noCache = false,
  opts = {},
): Function => async (): Promise<any> => {
  const response = await api.searchActivities({ groupId, startDate, endDate, noCache, ...opts });
  return response;
};

export const getActivityOpportunity = (activityId: number) => async (dispatch: Function): Promise<any> => {
  const response = await api.getActivityOpportunity(activityId);
  if (!response.status) {
    handleError(response, dispatch);
  }
  return response;
};

export const getTags = (opts: any = {}) => async (dispatch: Function): Promise<any> => {
  dispatch(actions.activity.getTagsRequest());
  const response = await api.getTags(opts);
  if (response.status) {
    dispatch(actions.activity.getTagsSuccess(response.tags));
    if (opts.activityId !== undefined) {
      dispatch(actions.activity.setTagsToActivity(response.tags, opts.activityId));
    }
  } else {
    handleError(response, dispatch);
    dispatch(actions.activity.getTagsFailure());
  }
  return response;
};

export const getTagRules = (opts: { tagName?: string } = {}) => async (dispatch: Function): Promise<any> => {
  dispatch(actions.activity.getTagRulesRequest());
  const response = await api.getTagRules(opts);
  if (response.status) {
    dispatch(actions.activity.getTagRulesSuccess(response.rules));
  } else {
    handleError(response, dispatch);
    dispatch(actions.activity.getTagRulesFailure());
  }
  return response;
};

export const updateActivityOpportunity = (opportunityId: number, activityId: number, opts) => async (
  dispatch: Function): Promise<any> => {
  const response = await api.updateActivityOpportunity(opportunityId, activityId, opts);
  if (!response.status) {
    handleError(response, dispatch);
  }
  return response;
};

export const saveTagRule = rule => async (dispatch: Function): Promise<any> => {
  dispatch(actions.activity.saveTagRuleRequest());
  const response = await api.saveTagRule(rule);
  if (response.status) {
    dispatch(actions.activity.saveTagRuleSuccess(response.rule));
  } else {
    handleError(response, dispatch);
    dispatch(actions.activity.saveTagRuleFailure());
  }
  return response;
};

export const deleteTagRule = ruleId => async (dispatch: Function): Promise<any> => {
  const response = await api.deleteTagRule(ruleId);
  if (response.status) {
    dispatch(actions.activity.deleteTagRuleSuccess(ruleId));
  } else {
    handleError(response, dispatch);
  }
  return response;
};

export const applyTagRule = (ruleId, opts = {}) => async (dispatch: Function): Promise<any> => {
  const response = await api.applyTagRule(ruleId, opts);
  if (!response.status) {
    handleError(response, dispatch);
  }
  return response;
};

export const addTag = (activityId: number, tag: string) => async (dispatch: Function): Promise<any> => {
  dispatch(actions.activity.addTagRequest());
  const response = await api.addTag(activityId, tag);
  if (response.status) {
    dispatch(actions.activity.addTagSuccess());
  } else {
    handleError(response, dispatch);
    dispatch(actions.activity.addTagFailure(response.msg));
  }
  return response;
};

export const removeTag = (activityId: number, tag: string) => async (dispatch: Function): Promise<any> => {
  dispatch(actions.activity.removeTagRequest());
  const response = await api.removeTag(activityId, tag);
  if (response.status) {
    dispatch(actions.activity.removeTagSuccess());
  } else {
    handleError(response, dispatch);
    dispatch(actions.activity.removeTagFailure(response.msg));
  }
  return response;
};

export const removeTagFromAll = (tagId: number) => async (dispatch: Function): Promise<any> => {
  dispatch(actions.activity.removeTagRequest());
  const response = await api.removeTagFromAll(tagId);
  if (response.status) {
    dispatch(actions.activity.removeTagSuccess());
  } else {
    handleError(response, dispatch);
    dispatch(actions.activity.removeTagFailure(response.msg));
  }
  return response;
};

const activityActions = {
  activity: {
    ...actions.activity,
    searchActivities,
    getActivityOpportunity,
    getGroupedActivities,
    getTags,
    getTagRules,
    addTag,
    updateActivityOpportunity,
    removeTag,
    removeTagFromAll,
    applyTagRule,
    saveTagRule,
    deleteTagRule,
  },
};

export default activityActions;
