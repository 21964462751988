import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Icon, Placeholder, Segment, Loader } from 'semantic-ui-react';

import api from 'lib/api';
import { Activity } from 'models/activity';
import ExternalLink from 'components/ExternalLink';

import css from './AccountInfo.module.css';

interface StateProps {
  accountActivity: Array<Activity>;
  accountInfo: {
    id: number;
    description: string;
    lastSync: string;
    name: string;
    ownerId: number;
    extId: string;
    extSource: string;
  };
  isLoading: boolean;
  onSync?: Function;
}

interface ActivityDate {
  date: Date;
  id: number;
}

const AccountInfo: React.FC<StateProps> = ({ accountInfo, isLoading, accountActivity }) => {
  const [isFetchingAccountActivityInfo, setIsFetchingAccountActivityInfo] = useState(false);
  const [lastActivity, setLastActivity] = useState<ActivityDate | undefined>(undefined);
  const [firstActivity, setFirstActivity] = useState<ActivityDate | undefined>(undefined);
  const { id, name, extId, extSource } = accountInfo;

  useEffect(() => {
    if (id !== undefined) {
      setIsFetchingAccountActivityInfo(true);
      api.getAccountActivityInfo(id).then(res => {
        setIsFetchingAccountActivityInfo(false);
        if (res.status) {
          setLastActivity({ date: res.data.lastActivityDate, id: res.data.lastActivityId });
          setFirstActivity({ date: res.data.firstActivityDate, id: res.data.firstActivityId });
        }
      }).finally(() => {
        setIsFetchingAccountActivityInfo(false);
      });
    }
  }, [id]);

  const renderFirstAndLastActivity = (): React.ReactNode => {
    if (firstActivity === undefined || lastActivity === undefined) {
      return null;
    }

    const firstActivityDate = moment(firstActivity.date);
    const lastActivityDate = moment(lastActivity.date);

    const baseUrl = `/app/account/${id}/activity`;
    return (
      <>
        <div className={css.stat}>
          <div className={css.label}>Most Recent Activity</div>
          <div className={css.value}>
            <Link to={`${baseUrl}?activity=${lastActivity.id}&date=${lastActivityDate.format('YYYY-MM-DD')}`}>
              {lastActivityDate.format('MMM DD, YYYY')}
            </Link>
          </div>
        </div>
        <div className={css.stat}>
          <div className={css.label}>First Activity</div>
          <div className={css.value}>
            <Link to={`${baseUrl}?activity=${firstActivity.id}&date=${firstActivityDate.format('YYYY-MM-DD')}`}>
              {firstActivityDate.format('MMM DD, YYYY')}
            </Link>
          </div>
        </div>
      </>
    );
  };


  const renderAvgWeekTouch = (): React.ReactNode => {
    if (firstActivity === undefined) {
      return null;
    }

    const numWeeks = Math.ceil(moment().diff(firstActivity.date, 'days') / 7);
    const avgActivityPerWeek = numWeeks ? Math.floor((100 * accountActivity.length) / numWeeks) / 100 : 0;

    return (
      <div className={css.stat}>
        <div className={css.label}>Avg. Touchpoints / Week</div>
        <div className={css.value}>
          {avgActivityPerWeek}
        </div>
      </div>
    );
  };

  return isLoading ? (
    <Segment>
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    </Segment>
  ) : (
    <Segment className={css.content}>
      <Icon name="building" size="big" />
      <div className={css.accountHeader}>
        {name}
        {' '}
        <ExternalLink extId={extId} extSource={extSource} type="account" />
      </div>
      {isFetchingAccountActivityInfo && <Loader active />}
      {(lastActivity !== undefined && firstActivity !== undefined)
          && (
            <>
              {renderFirstAndLastActivity()}
              {renderAvgWeekTouch()}
            </>
          )}
    </Segment>
  );
};

export default AccountInfo;
