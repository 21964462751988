import React, { useState } from 'react';
import { Table, Button, Icon, Loader, Popup, Confirm } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import api from 'lib/api';
import allActions, { bindCombinedActions } from 'actions';
import { connect } from 'react-redux';
import PermissionsInfo from 'components/PermissionsInfo';

import UserNotInTeamIndicator from 'components/UserNotInTeamIndicator/UserNotInATeamIndicator';

import BSTable from 'components/Table/BSTable';
import css from './UserManageList.module.css';
import { permissionToLabel } from '../AccessControl';

interface UserManageListProps {
  isLoadingUsers: boolean;
  users: any[];
  handleOpenEditUserModal: (user: any) => void;
  handleOpenAddUserModal: () => void;
  actions: any;
}

const UserManageList: React.FC<UserManageListProps> = ({
  isLoadingUsers,
  users,
  handleOpenEditUserModal,
  handleOpenAddUserModal,
  actions,
}) => {
  const [deactivatingUserId, setDeactivatingUserId] = useState<string | undefined>();
  const [openConfirmDeactivateUser, setOpenConfirmDeactivateUser] = useState(false);

  const handleCancelDeactivateUser = (): void => {
    setOpenConfirmDeactivateUser(false);
    setDeactivatingUserId(undefined);
  };

  const handleDeactivateUser = async (): Promise<void> => {
    if (deactivatingUserId) {
      await api.deactivateUser(deactivatingUserId);
      setDeactivatingUserId(undefined);
      handleCancelDeactivateUser();
      actions.user.fetchUsers();
    }
  };

  const handleDeactivateUserConfirmation = (userId: string): void => {
    setDeactivatingUserId(userId);
    setOpenConfirmDeactivateUser(true);
  };

  const renderUserRow = (user): React.ReactNode => (
    <Table.Row key={user.extId}>
      <Table.Cell>
        <Link to={`/app/team/${user.id}`}>{user.name}</Link>
        {' '}
        <UserNotInTeamIndicator userId={user.id} />
      </Table.Cell>
      <Table.Cell>{user.role}</Table.Cell>
      <Table.Cell>
        {user ? permissionToLabel(user.permission) : null}
        {' '}
        <PermissionsInfo permission={user.permission} />
      </Table.Cell>
      <Table.Cell textAlign="center">{user.isTracked ? <Icon name="check" /> : null}</Table.Cell>
      <Table.Cell className={css.buttonCell} textAlign="center">
        <Popup
          position="top center"
          trigger={<Button icon="edit" basic onClick={(): void => handleOpenEditUserModal(user)} />}
          content="Edit user"
          inverted
        />
        <Popup
          position="top center"
          trigger={(
            <Button
              basic
              icon="trash"
              loading={deactivatingUserId === user.id}
              onClick={(): void => handleDeactivateUserConfirmation(user.id)}
            />
          )}
          content="Delete user"
          inverted
        />
      </Table.Cell>
    </Table.Row>
  );

  return (
    <>
      <div>
        <Loader active={isLoadingUsers} />
        <BSTable
          properties={[
            { key: 'name', title: 'User', sort: true },
            { key: 'role', title: 'Role', sort: true },
            { key: 'permission', title: 'Permission Level' },
            {
              key: 'isTracked',
              title: 'Tracked',
              headerCellProps: { textAlign: 'center' },
            },
            { key: 'edit/delete', title: '' },
          ]}
          renderRow={renderUserRow}
          data={users}
          searchKey="name"
        />
        <div className={css.addUserBtnContainer}>
          <Button onClick={handleOpenAddUserModal}>+ Add User</Button>
        </div>
      </div>
      <Confirm
        header="Delete User"
        size="small"
        confirmButton={{ primary: false, color: 'red', children: 'Delete user' }}
        content="Are you sure you want to delete this user?"
        open={openConfirmDeactivateUser}
        onCancel={handleCancelDeactivateUser}
        onConfirm={handleDeactivateUser}
      />
    </>
  );
};

export default connect(
  (state: any) => ({ isLoadingUsers: state.user.isLoadingUsers }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(UserManageList);
