import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTeamComparisonBodyChartContext } from 'components/TeamComparisonChart/Body/BodyContext';
import { ColumnHeader } from 'components/TeamComparisonChart/Body/Columns/ColumnHeader';
import css from '../Body.module.css';

export interface NameColumnProps {
  title?: string;
  linkNames?: boolean;
}

export const NameColumn: FC<NameColumnProps> = ({
  title = 'Reps',
  linkNames = false,
}) => {
  const {
    data,
    columnHeaders: {
      visible: showHeaders,
    },
  } = useTeamComparisonBodyChartContext();
  return (
    <section className={`${css.column} ${css.names}`}>
      { showHeaders
        ? <ColumnHeader columnCategory="names" title={title} />
        : <></> }
      <ol>
        {data.map(
          ({ userName, userId }): React.ReactNode => (
            <li key={userName}>
              { linkNames
                ? <Link to={`/app/team/${userId}`}>{userName}</Link>
                : userName }
            </li>
          ),
        )}
      </ol>
    </section>
  );
};
