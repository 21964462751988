import { createSelector, OutputSelector } from 'reselect';
import { DropdownItemProps } from 'semantic-ui-react';

import { User } from 'models/user';
import { Team, Teams, TeamMember, TeamWithMembers } from 'models/team';

import { RootState } from '../store';

function getUsersFromIds(users, userIds): any[] {
  return users.filter(user => userIds.find(userId => user.id === userId) !== undefined);
}

export const getUsersGroupedByTeams = createSelector<any, any, any, any, TeamWithMembers[]>(
  state => state.user.users,
  state => state.team.teams,
  state => state.team.teamMembers,
  (users, teams, teamMembers) => Object.values<Team>(teams.byIds).map(team => {
    const membersOfTeam = teamMembers.filter(tm => tm.teamId === team.id) || [];
    return {
      ...team,
      members: getUsersFromIds(users, membersOfTeam.map(tm => tm.userId)),
      // TODO: Normalize the user reducer and this will be a lot  easier
    };
  }),
);

export const getTeamsOfUser = createSelector<any, any, any, any, Team[]>(
  state => state.team.teams,
  state => state.team.teamMembers,
  state => state.app.id,
  (teams: Teams, teamMembers: TeamMember[]) => {
    const userTeamIds = teamMembers.map(tm => tm.teamId);
    return Object.values<Team>(teams.byIds).filter(team => userTeamIds.includes(team.id));
  },
);

export const getSelectedTeamId = (state: RootState): number | undefined => state.team.selectedTeamId;

export const getSelectedTeam = createSelector<any, any, any, TeamWithMembers | undefined>(
  state => state.team.selectedTeamId,
  getUsersGroupedByTeams,
  (selectedTeamId, teams) => {
    if (teams !== undefined && teams.length > 0) {
      return selectedTeamId !== undefined ? teams.find(team => selectedTeamId === team.id) : teams[0];
    }
    return undefined;
  },
);

export const getTrackedSelectedTeam = createSelector<any, any, TeamWithMembers | undefined>(
  getSelectedTeam,
  (team: TeamWithMembers): TeamWithMembers | undefined => {
    if (team) {
      // eslint-disable-next-line no-param-reassign
      team.members = team.members.filter((user: User) => user.isTracked);
      return team;
    }
    return undefined;
  },
);

export const getSelectedTeamMembers = createSelector<any, any, User[]>(
  getSelectedTeam,
  (team: TeamWithMembers): User[] => (team ? team.members : []),
);

export const getSelectedTeamMembersForDropdown = createSelector<any, any, DropdownItemProps[]>(
  getSelectedTeam,
  (team: TeamWithMembers): Array<any> => (team
    ? team.members.map((user: User) => (
      { key: user.id, value: user.id, text: user.name }
    ))
    : []),
);

export const getTrackedSelectedTeamMembers = createSelector<TeamWithMembers, any, User[]>(
  getTrackedSelectedTeam,
  (team: TeamWithMembers): User[] => (team ? team.members : []),
);

export const getTrackedSelectedTeamMembersForDropdown = createSelector<any, any, DropdownItemProps[]>(
  getTrackedSelectedTeam,
  (team: TeamWithMembers): Array<any> => (team
    ? team.members.map((user: User) => (
      { key: user.id, value: user.id, text: user.name }
    ))
    : []),
);


export const getTeamMembersOfTeam = (teamId: number): OutputSelector<any, User[], (res: any) => User[]> => (
  createSelector<any, any, User[]>(
    getUsersGroupedByTeams,
    teams => {
      const team = teams.find(t => t.id === teamId);
      if (team !== undefined) {
        return team.members;
      }
      return [];
    },
  )
);

export const getTeams = createSelector<any, any, Team[]>(
  state => state.team.teams,
  teams => Object.values<Team>(teams.byIds),
);

export const getIsProspecting = createSelector<any, any, boolean, boolean>(
  state => state.team.settings,
  state => state.app.isSuperAdmin,
  (settings: any, isSuperAdmin: boolean) => settings?.features?.isProspecting
    || (settings?.features?.isProspectingTest && isSuperAdmin),
);

export const isUserInATeam = (userId: number): OutputSelector<any, any, (res: any, res2: any) => boolean> => (
  createSelector<any, any, any, boolean>(
    state => state.team.teams,
    state => state.team.teamMembers,
    (teams: Teams, teamMembers: TeamMember[]) => {
      const userTeamIds = teamMembers.filter(tm => tm.userId === userId).map(tm => tm.teamId);
      const teamsOfUser = Object.values<Team>(teams.byIds).filter(team => userTeamIds.includes(team.id));
      return teamsOfUser.length > 0;
    },
  )
);
