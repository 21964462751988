import { AccountApi } from 'features/Api';
import React, { CSSProperties, ReactElement, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import tinycolor from 'tinycolor2';
import {
  Dropdown, DropdownItemProps, Grid, GridColumn,
  GridRow, Header, Placeholder, PlaceholderLine, Segment,
} from 'semantic-ui-react';

import { AccountHealth, healthStatusMap } from 'models/account';
import ExternalLink from 'components/ExternalLink';
import css from '../styles.module.css';


const AccountHealthBadge: React.FC<{ healthStatus: AccountHealth }> = ({ healthStatus }) => {
  const healthLang = healthStatusMap[healthStatus];
  if (!healthLang) return null;
  const { text: title, color } = healthLang;
  const backgroundColor = tinycolor(color).lighten(40).toString();
  const styles: CSSProperties = {
    color,
    backgroundColor,
    borderRadius: 20,
  };

  return (
    <span style={styles} className={css.healthBadge}>
      {title}
    </span>
  );
};

const loading = undefined;
const loaderElement = (
  <Placeholder>
    <PlaceholderLine />
  </Placeholder>
);
const notAvailable = null;

interface OpportunityDetails {
  owner: string | typeof loading | typeof notAvailable;
  stageName: string | typeof loading | typeof notAvailable;
  type: string | typeof loading | typeof notAvailable;
  timeSinceLastActivity: number | typeof loading | typeof notAvailable;
}

const OpportunityDetailsRows: React.FC<{ opportunityDetails: OpportunityDetails }> = ({
  opportunityDetails: {
    owner,
    stageName,
    type,
    timeSinceLastActivity,
  },
}) => {
  let ownerElement: ReactElement;
  if (owner === loading) ownerElement = loaderElement;
  else if (owner === notAvailable) ownerElement = <>--</>;
  else ownerElement = <>{owner}</>;

  let stageNameElement: ReactElement;
  if (stageName === loading) stageNameElement = loaderElement;
  else if (stageName === notAvailable) stageNameElement = <>--</>;
  else stageNameElement = <>{stageName}</>;

  let typeElement: ReactElement;
  if (type === loading) typeElement = loaderElement;
  else if (type === notAvailable) typeElement = <>--</>;
  else typeElement = <>{type}</>;


  const dayCountFormatter = Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'day',
    maximumFractionDigits: 1,
  });
  let lastActivityClass = '';
  let lastActivityElement: ReactElement;
  if (timeSinceLastActivity === loading) lastActivityElement = loaderElement;
  else if (timeSinceLastActivity === notAvailable) lastActivityElement = <>--</>;
  else {
    const formatParts = dayCountFormatter.formatToParts(timeSinceLastActivity);
    const intPart = formatParts.find(p => p.type === 'integer');
    if (intPart && parseInt(intPart.value, 10) >= 30) lastActivityClass = css.critical;
    lastActivityElement = <>{`${dayCountFormatter.format(timeSinceLastActivity)} ago`}</>;
  }

  const noAccountHealthInfo = ((owner === notAvailable)
  && (stageName === notAvailable)
  && (type === notAvailable)
  && (timeSinceLastActivity === notAvailable));

  return (
    <>
      { noAccountHealthInfo ? 'No account health info available'
        : (
          <>
            <GridRow divided className={`${css.OpportunityRow} ${css.Label}`}>
              <GridColumn width={3}>
                Opportunity Owner
              </GridColumn>
              <GridColumn width={3}>
                Opportunity Stage
              </GridColumn>
              <GridColumn width={3}>
                Opportunity Type
              </GridColumn>
              <GridColumn width={3}>
                Last Activity
              </GridColumn>
            </GridRow>
            <GridRow divided className={`${css.OpportunityRow} ${css.Value}`}>
              <GridColumn width={3}>
                {ownerElement}
              </GridColumn>
              <GridColumn width={3}>
                {stageNameElement}
              </GridColumn>
              <GridColumn width={3}>
                {typeElement}
              </GridColumn>
              <GridColumn width={3} className={lastActivityClass}>
                {lastActivityElement}
              </GridColumn>
            </GridRow>
          </>
        )}
    </>
  );
};

export const AccountHealthHeader: React.FC<{
  accountId: number;
  selectedOpportunityId: number;
  setSelectedOpportunityId: (oppId: number) => any;
}> = props => {
  const { accountId, selectedOpportunityId, setSelectedOpportunityId } = props;
  const {
    data: accountData,
    isFetching: accountIsFetching,
  } = AccountApi.getAccount.useGetAccountHealthQuery({ accountId });

  let accountName = loaderElement;
  const [accountHealthBadge, setAccountHealthBadge] = useState(<></>);
  const [opportunityDetails, setOpportunityDetails] = useState<OpportunityDetails>({
    owner: loading,
    stageName: loading,
    type: loading,
    timeSinceLastActivity: loading,
  });
  const [opportunityExtId, setOpportunityExtId] = useState<string>();
  const [opportunityExtSource, setOpportunityExtSource] = useState<string>();

  if (accountData && !accountIsFetching) {
    const { account } = accountData;
    accountName = (
      <>
        {accountData.account.name}
        {' '}
        <ExternalLink extId={account.extId} extSource={account.extSource} type="account" />
      </>
    );
  }
  const [opportunityOptions, setOpportunityOptions] = useState<DropdownItemProps[]>([]);

  useEffect(() => {
    if (accountData && !accountIsFetching) {
      const { account } = accountData;
      setAccountHealthBadge(<AccountHealthBadge healthStatus={account.status} />);

      const oppDetails = opportunityDetails;

      const lastActivityDate = DateTime.fromISO(account.lastActivityDate);
      oppDetails.timeSinceLastActivity = DateTime.now().diff(lastActivityDate).as('days');

      const { opportunities } = account;
      if (opportunities) {
        let options = opportunities.map(opp => ({
          value: opp.id,
          name: opp.name,
          text: opp.name,
        }));
        const opportunity = opportunities.find(opp => opp.id === selectedOpportunityId) || opportunities[0];
        if (opportunity) {
          if (opportunity.id !== selectedOpportunityId) setSelectedOpportunityId(opportunity?.id);
          oppDetails.owner = opportunity.ownerName;
          oppDetails.stageName = opportunity.stageName;
          oppDetails.type = opportunity.type;
          setOpportunityExtId(opportunity.extId);
          setOpportunityExtSource(opportunity.extSource);
        } else {
          options = [{
            value: -1,
            name: 'No Open Opportunity',
            text: 'No Open Opportunity',
          }];
          oppDetails.owner = notAvailable;
          oppDetails.stageName = notAvailable;
          oppDetails.type = notAvailable;
          oppDetails.timeSinceLastActivity = notAvailable;
        }
        setOpportunityDetails(oppDetails);
        setOpportunityOptions(options);
      }
    }
  }, [selectedOpportunityId, opportunityDetails, accountData, accountIsFetching, setSelectedOpportunityId]);
  return (
    <Segment className={css.AccountHealthHeader}>
      <Grid>
        <GridRow>
          <GridColumn width={16} className={css.header}>
            <Header as="h2">
              {accountName}
              {accountHealthBadge}
            </Header>
            <div className={css.select}>
              <Dropdown
                selection
                options={opportunityOptions}
                value={selectedOpportunityId || undefined}
                onChange={(e, { value }): void => {
                  if (typeof value === 'number' && opportunityOptions.find((option): boolean => option.value === value)) {
                    setSelectedOpportunityId(value);
                  }
                }}
              />
              {' '}
              <ExternalLink extId={opportunityExtId} extSource={opportunityExtSource} type="opportunity" />
            </div>
          </GridColumn>
        </GridRow>
        <OpportunityDetailsRows opportunityDetails={opportunityDetails} />
      </Grid>
    </Segment>
  );
};
