import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Segment, Header, Dropdown, Table, Label } from 'semantic-ui-react';
import moment from 'moment';

import allActions, { bindCombinedActions } from 'actions';
import { DBRecommendation } from 'models/recommendation';

import css from './RecommendationAdmin.module.css';

interface Props {
  actions: any;
  organizations: Array<any>;
  recommendations: Array<DBRecommendation>;
  selectedOrganizationId?: number;
  tags: string[];
  isTagsLoaded: boolean;
}

interface State {
  activeTagFilters: string[];
}

class RecommendationAdmin extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = { activeTagFilters: [] };
  }

  componentDidMount(): void {
    const { actions, selectedOrganizationId, isTagsLoaded } = this.props;
    actions.admin.fetchOrganizations();
    if (selectedOrganizationId) {
      actions.admin.fetchRecommendationsByOrg(selectedOrganizationId, { orderBy: 'created_at DESC ' });
      actions.admin.fetchUsersByOrg(selectedOrganizationId, { orderBy: 'last_name, first_name' });
      if (!isTagsLoaded) {
        actions.admin.fetchRecommendationTagsByOrg(selectedOrganizationId);
      }
    }
  }

  componentDidUpdate(prevProps): void {
    const { actions, selectedOrganizationId } = this.props;
    if (prevProps.selectedOrganizationId !== selectedOrganizationId) {
      actions.admin.fetchRecommendationsByOrg(selectedOrganizationId, { orderBy: 'created_at DESC ' });
      actions.admin.fetchUsersByOrg(selectedOrganizationId, { orderBy: 'last_name, first_name' });
      actions.admin.fetchRecommendationTagsByOrg(selectedOrganizationId);
    }
  }

  handleOrganizationSelectChange = async (_, data): Promise<void> => {
    const { actions } = this.props;
    const selectedOrganizationId = data.value;
    actions.admin.setSelectedOrganization(selectedOrganizationId);
  };

  toggleTag = (tag: string): void => {
    const { activeTagFilters } = this.state;
    const index = activeTagFilters.indexOf(tag);
    const whitelist = (index === -1) ? activeTagFilters.concat(tag) : activeTagFilters.filter(id => (id !== tag));
    this.setState({ activeTagFilters: whitelist });
  };

  render(): React.ReactNode {
    const { activeTagFilters } = this.state;
    const { organizations, recommendations, tags, selectedOrganizationId } = this.props;

    const options = organizations.map((o: any): any => ({
      key: o.id,
      text: o.name,
      value: o.id,
    }));

    const showAllClassName = activeTagFilters.length === 0 ? css.activeLabel : '';

    const tagSection = (
      <div className={css.tagFilters}>
        <Header as="h5" className={css.tagHeader}>Tags</Header>
        <Label
          as="a"
          className={showAllClassName}
          onClick={(): void => { this.setState({ activeTagFilters: [] }); }}
        >
          Show All
        </Label>
        {tags.map(tag => {
          const tagClassName = activeTagFilters.includes(tag) ? css.activeLabel : '';
          return (
            <Label
              as="a"
              key={tag}
              className={tagClassName}
              onClick={(): void => { this.toggleTag(tag); }}
            >
              {tag}
            </Label>
          );
        })}
      </div>
    );

    const filterFn = (r): boolean => (
      activeTagFilters.length === 0
      || activeTagFilters.filter(t => r.tags?.includes(t)).length > 0
    );

    const rows = recommendations.filter(filterFn).map(r => (
      <Table.Row key={r.id}>
        <Table.Cell>
          <Link to={`/app/recommendations/admin/edit/${r.id}`}>{r.title}</Link>
        </Table.Cell>
        <Table.Cell>
          {moment(r.createdAt).format('MMM DD, YYYY HH:mm:ss')}
        </Table.Cell>
      </Table.Row>
    ));

    const createRecommendationLink = selectedOrganizationId ? (
      <div className={css.new}>
        <Link to="/app/recommendations/admin/edit">Add New Recommendation</Link>
      </div>
    ) : null;
    return (
      <div className={css.dashboard}>
        <Segment>
          <div className={css.controls}>
            <label>Organization: </label>
            <Dropdown
              selection
              loading={options.length === 0}
              options={options}
              onChange={this.handleOrganizationSelectChange}
              value={selectedOrganizationId}
            />
          </div>
          {tagSection}
          <div className={css.list}>
            <Header>Recommendations</Header>
            {createRecommendationLink}
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Recommendation</Table.HeaderCell>
                  <Table.HeaderCell>Create Date</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {rows.length ? rows : (<Table.Row><Table.Cell>Nothing to show</Table.Cell></Table.Row>)}
              </Table.Body>
            </Table>
          </div>
        </Segment>
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    organizations: state.admin.organizations,
    recommendations: state.admin.recommendations,
    selectedOrganizationId: state.admin.selectedOrganizationId,
    tags: state.admin.recommendationTags,
    isTagsLoaded: state.admin.isTagsLoaded,
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(RecommendationAdmin);
