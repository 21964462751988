import { useTeamComparisonBodyChartContext } from 'components/TeamComparisonChart/Body/BodyContext';
import { ColumnHeader } from 'components/TeamComparisonChart/Body/Columns/ColumnHeader';
import React, { FC } from 'react';
import css from '../Body.module.css';

export interface ValueColumnProps {
  title?: string;
}

export const ValueColumn: FC<ValueColumnProps> = ({
  title = 'Value',
}) => {
  const {
    data,
    columnHeaders: {
      visible: showHeaders,
    },
  } = useTeamComparisonBodyChartContext();
  return (
    <section className={`${css.column} ${css.values}`}>
      { showHeaders
        ? <ColumnHeader columnCategory="values" title={title} />
        : <></> }
      <ol>
        {data.map(
          ({ userName, value: { displayValue } }): React.ReactNode => (
            <li key={userName}>
              {displayValue}
            </li>
          ),
        )}
      </ol>
    </section>
  );
};
