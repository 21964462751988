import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Activity, Tag } from 'models/activity';
import { makeColorGenerator } from 'lib/util';
import BSChart from 'components/BSChart';
import { DataSet } from 'components/BSChart/BSChart';
import { SimpleJSDateTimePeriod } from 'features/DatePicker/datePickerTypes';
import { selectAppStartAndEndDates } from 'features/App/appSlice';

interface Props {
  activities: Array<Activity>;
  tags: Array<Tag>;
  userInfo: any;
  isLoading: boolean;
  timePeriod: SimpleJSDateTimePeriod;
}

interface State { }

class UserTagsOverTimeGraph extends Component<Props, State> {
  getChartData(): object {
    if (!this.props) return { datasets: {}, labels: [] };
    const { activities, timePeriod: { start, end }, tags } = this.props;
    const topFiveTags: string[] = tags.slice(0, 5).map(tag => tag.tagName);
    const eventsByTagAndDate = {};
    const dateFormat = 'MM-DD-YYYY';
    activities.forEach(activity => {
      const activityDate = moment(activity.activityDate).startOf('day');
      const key = activityDate.format(dateFormat);
      if (activity.tags) {
        activity.tags.forEach(tag => {
          if (!topFiveTags.includes(tag)) return;
          if (!eventsByTagAndDate[tag]) eventsByTagAndDate[tag] = {};
          if (!eventsByTagAndDate[tag][key]) {
            eventsByTagAndDate[tag][key] = [];
          }
          eventsByTagAndDate[tag][key].push(activity);
        });
      }
    });
    const startDate = moment(start).startOf('day');
    const endDate = moment.min(moment(end), moment()).endOf('day');
    const data: object[] = [];
    const chartTags = Object.keys(eventsByTagAndDate);
    const allDates: string[] = [];
    const colorGenerator = makeColorGenerator();
    chartTags.forEach((tag, i) => {
      const iterator = moment(startDate).clone();
      const activitiesByDate = {};
      while (iterator.isSameOrBefore(endDate)) {
        const current = iterator.format(dateFormat);
        if (eventsByTagAndDate[tag][current]) {
          activitiesByDate[current] = {
            x: current,
            y: eventsByTagAndDate[tag][current].length,
          };
        } else activitiesByDate[current] = { x: current, y: 0 };
        if (i === 0) allDates.push(iterator.format(dateFormat));
        iterator.add(1, 'day');
      }
      const color = colorGenerator();
      data.push({
        label: tag,
        data: Object.values(activitiesByDate),
        backgroundColor: color,
        borderColor: color,
        fill: false,
      });
    });
    return data;
  }

  render(): React.ReactNode {
    const { timePeriod: { start, end }, isLoading, userInfo } = this.props;
    const { id } = userInfo;
    const datasets = this.getChartData();
    return (
      <Segment>
        <BSChart
          startDate={start}
          endDate={end}
          datasets={datasets as DataSet[]}
          title="Activity Tags Over Time"
          titleLink={`/app/team/${id}/activityFeed`}
          isLoading={isLoading}
        />
      </Segment>
    );
  }
}

export default connect(
  (state: any) => ({
    activities: state.activity.activities,
    tags: state.activity.activityTags,
    userInfo: state.user.userInfo,
    isLoading: state.activity.isLoadingActivities,
    timePeriod: selectAppStartAndEndDates(state),
  }),
)(UserTagsOverTimeGraph);
