import { AccountApi } from 'features/Api';
import { handleActions } from 'redux-actions';

import actions from 'actions';
import {
  ActivitiesByWeekDataPoint,
  NotableActivityDataPoint,
} from 'App/Main/AccountHealthRoute/components/AccountTimeline/AccountTimeline';
import { AccountWithStats, Account, AccountHealthThresholds } from 'models/account';

import { Reducer } from 'redux';

export interface AccountState {
  // accounts
  accounts: Array<AccountWithStats>;
  isLoadingAccounts: boolean;
  accountErrorMsg: string;
  // account info
  accountInfo: Account | {};
  isLoadingAccountInfo: boolean;
  // account health
  activeAccounts: Array<any>;
  inactiveAccounts: Array<any>;
  inactivePage: number;
  isLoadingMore: boolean;
  idealTimeline: AccountApi.getAccountTimeline.TimelineDataPoint[];
  activitiesByWeek: ActivitiesByWeekDataPoint[];
  accountTimeline: AccountApi.getAccountTimeline.TimelineDataPoint[];
  notableActivities: NotableActivityDataPoint[];
  // account health thresholds
  isLoadingThresholds: boolean;
  thresholds?: AccountHealthThresholds;
  isSyncing: boolean;
}

export const initialState: AccountState = {
  // accounts
  accounts: [],
  isLoadingAccounts: false,
  accountErrorMsg: '',
  // account info
  accountInfo: {},
  isLoadingAccountInfo: false,
  // account health
  activeAccounts: [],
  inactiveAccounts: [],
  inactivePage: 0,
  isLoadingMore: false,
  idealTimeline: [],
  activitiesByWeek: [],
  accountTimeline: [],
  notableActivities: [],
  // account health thresholds
  isLoadingThresholds: false,
  thresholds: undefined,
  isSyncing: false,
};

const reducer: Reducer<AccountState, any> = handleActions(
  {
    [actions.account.addToState]: (state, { payload: { account } }) => {
      const { inactiveAccounts } = state;
      const newInactive = inactiveAccounts.concat([account]);
      return { ...state, inactiveAccounts: newInactive };
    },
    [actions.account.incrementPage]: (state, { payload: { pageNum } }) => {
      const page = parseInt(pageNum, 10) || 0;
      const inactivePage = page + 1;
      return { ...state, inactivePage };
    },
    [actions.account.fetchAccountHealthThresholdsRequest]: state => ({ ...state, isLoadingThresholds: true }),
    [actions.account.fetchAccountHealthThresholdsSuccess]: (state, { payload: { thresholds } }) => ({
      ...state,
      isLoadingThresholds: false,
      thresholds,
    }),
    [actions.account.fetchAccountHealthThresholdsFailure]: state => ({ ...state, isLoadingThresholds: false }),
    [actions.account.fetchAccountInfoRequest]: state => ({ ...state, isLoadingAccountInfo: true, accountInfo: {} }),
    [actions.account.fetchAccountInfoSuccess]: (state, { payload: { accountInfo } }) => ({
      ...state,
      isLoadingAccountInfo: false,
      accountInfo,
    }),
    [actions.account.fetchAccountInfoFailure]: state => ({ ...state, isLoadingAccountInfo: false }),
    [actions.account.fetchAccountsRequest]: state => ({
      ...state,
      isLoadingAccounts: true,
      accounts: [],
    }),
    [actions.account.fetchAccountsSuccess]: (state, { payload: { accounts } }) => ({
      ...state,
      isLoadingAccounts: false,
      accounts,
    }),
    [actions.account.fetchAccountsFailure]: (state, { payload: { msg } }) => ({
      ...state,
      isLoadingAccounts: false,
      accountErrorMsg: msg,
    }),
    [actions.account.fetchMoreInactiveRequest]: state => ({ ...state, isLoadingMore: true }),
    [actions.account.fetchMoreInactiveSuccess]: (state, { payload: { inactive } }) => {
      const { inactiveAccounts } = state;
      const filtered = inactive.filter(acc => !inactiveAccounts.find(ia => ia.accountId === acc.accountId));
      const newInactiveAccounts = inactiveAccounts.concat(filtered);
      return {
        ...state,
        isLoadingMore: false,
        inactiveAccounts: newInactiveAccounts,
      };
    },
    [actions.account.fetchMoreInactiveFailure]: state => ({ ...state, isLoadingMore: false }),
    [actions.account.fetchAccountTimelineSuccess]: (state, {
      payload: {
        data: {
          activitiesByWeek,
          accountTimeline,
          notableActivities,
        },
      },
    }) => ({
      ...state,
      activitiesByWeek,
      accountTimeline,
      notableActivities,
    }),
    [actions.app.logoutSuccess]: () => initialState,
    [actions.app.logoutFailure]: () => initialState,
  },
  initialState,
);

export default reducer;
