import { TeamComparisonChartBodyProvider } from 'components/TeamComparisonChart/Body/BodyContext';
import {
  BarColumn,
  BarColumnComposition,
} from 'components/TeamComparisonChart/Body/Columns/BarColumn/BarColumn';
import { NameColumn, NameColumnProps } from 'components/TeamComparisonChart/Body/Columns/NameColumn';
import { PercentColumn, PercentColumnProps } from 'components/TeamComparisonChart/Body/Columns/PercentColumn';
import { ValueColumn, ValueColumnProps } from 'components/TeamComparisonChart/Body/Columns/ValueColumn';
import { useTeamComparisonChartContext } from 'components/TeamComparisonChart/TeamComparisonChartContext';
import React, { FC } from 'react';
import { Loader, Segment } from 'semantic-ui-react';
import css from './Body.module.css';

interface ChartBodyProps {
  showHeaders?: boolean;
  sortable?: boolean;
  className?: string;
  loading?: boolean;
  size?: 'regular' | 'big';
}

export interface ChartBodyComposition extends FC<ChartBodyProps> {
  NameColumn: FC<NameColumnProps>;
  ValueColumn: FC<ValueColumnProps>;
  PercentColumn: FC<PercentColumnProps>;
  BarColumn: BarColumnComposition;
}

const TeamComparisonChartBody: ChartBodyComposition = ({
  showHeaders = false,
  sortable = false,
  children,
  className = '',
  loading = false,
  size = 'regular',
}) => {
  const { data, maxValue, sorting } = useTeamComparisonChartContext();
  return (
    <TeamComparisonChartBodyProvider
      value={{
        data,
        maxValue,
        sorting,
        columnHeaders: {
          visible: showHeaders,
          sortable,
        },
        size,
      }}
    >
      <Segment basic className={`${css.table} ${css[size]} ${className} ${sortable ? css.sortable : ''}`}>
        { loading ? (
          <Loader active className={css.loader} />
        ) : children }
      </Segment>
    </TeamComparisonChartBodyProvider>
  );
};

TeamComparisonChartBody.NameColumn = NameColumn;
TeamComparisonChartBody.ValueColumn = ValueColumn;
TeamComparisonChartBody.PercentColumn = PercentColumn;
TeamComparisonChartBody.BarColumn = BarColumn;

export { TeamComparisonChartBody };
