import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Menu } from 'semantic-ui-react';

import { formatDate } from 'lib/util';

import css from './AlertNotificationSidebarItem.module.css';
import { Recommendation } from '../../../../models/recommendation';

interface Props {
  alertData: Recommendation;
  onClick?: Function;
}

type State = any;

const AlertRecommendationSidebarItem: React.FC<Props> = ({ alertData, onClick }): React.ReactElement => {
  const history = useHistory();

  // const [setHover] = useState(false);

  const errorMessage = useSelector((state: State) => state.alert.notificationError);

  const handleToast = (message: string): void => {
    toast(message, {
      position: 'top-center',
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const handleRedirectToRule = (): void => {
    history.push(`/app/recommendations/${alertData.recommendationId}`);
    if (onClick) onClick(alertData.notificationId);
  };

  useEffect(() => {
    if (errorMessage) {
      handleToast(errorMessage);
    }
  }, [errorMessage]);


  const menuItemClassName = `
    ${css.menuItem} ${!alertData.seenAt && css.unseen} ${css.alert}
  `;

  return (
    <Menu.Item onClick={handleRedirectToRule} className={menuItemClassName}>
      <div
        className={css.line}
      >
        <div className={css.leftItems}>
          <div className={css.detail}>
            <div>
              {alertData.title}
            </div>
            <div className={css.date}>
              {formatDate(alertData.notificationDate)}
            </div>
          </div>
        </div>
      </div>
    </Menu.Item>
  );
};

export default AlertRecommendationSidebarItem;
