import React, { FC, useEffect, useState } from 'react';
import { TimePeriod } from 'features/DatePicker/datePickerTypes';
import { Grid, Dropdown, DropdownProps } from 'semantic-ui-react';
import activityApi, { ActivityMetricName, MetricGroupByOptions, MetricOptions } from 'features/Api/Activity';
import { getApiDatesFromTimePeriod } from 'features/DatePicker/datePickerFunctions';

import MetricOverTimeChart from '../MetricOverTimeChart';

const groupByDefaultOptions = [
  { key: 'daily', text: 'daily', value: 'day' },
  { key: 'weekly', text: 'weekly', value: 'week' },
  { key: 'monthly', text: 'monthly', value: 'month' },
];

const DEFAULT_GROUP_BY = 'week';

const TeamComparisonChartHistoric: FC<{
  modalMetric: ActivityMetricName;
  timePeriod: TimePeriod;
  teamId: number;
  userId?: number;
}> = ({ modalMetric: codename, timePeriod, teamId, userId }) => {
  const [groupBy, setGroupBy] = useState<MetricGroupByOptions>(DEFAULT_GROUP_BY);
  const [chart, setChart] = useState<React.ReactElement>(<>Loading...</>);
  const [getMetricOverTimeTrigger, motResult] = activityApi.endpoints.getMetricOverTime.useLazyQuery();

  const onChangeGroupBy = (_, selectedItem: DropdownProps): void => {
    const { value } = selectedItem;
    if (typeof value !== 'string') return;
    setGroupBy((value as MetricGroupByOptions));
  };
  const { start: startDate, end: endDate } = getApiDatesFromTimePeriod(timePeriod);

  useEffect(() => {
    const opts: MetricOptions = { teamId, groupBy };
    if (userId) {
      opts.userId = userId;
    }
    getMetricOverTimeTrigger({ metricName: codename, startDate, endDate, opts });
  }, [teamId, startDate, endDate, groupBy]);

  const { isFetching, data: responseData } = motResult;

  useEffect(() => {
    if (!isFetching && responseData) {
      const { data } = responseData;

      setChart(<MetricOverTimeChart data={data} groupBy={groupBy} />);
    }
  }, [isFetching, responseData]);
  return (
    <>
      <Grid>
        <Grid.Row textAlign="right">
          <Grid.Column>
            <Dropdown
              placeholder="Group By"
              options={groupByDefaultOptions}
              onChange={onChangeGroupBy}
              defaultValue={groupBy}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {chart}
        </Grid.Row>
      </Grid>
    </>
  );
};

export default TeamComparisonChartHistoric;
