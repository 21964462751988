import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { Segment, Table } from 'semantic-ui-react';
import _ from 'lodash';

import { hasPermission } from 'components/AccessControl';
import { RulesNotifications } from 'models/alert';

import css from './RulesTable.module.css';
import RulesHeader from './RulesHeader';
import RulesRow from './RulesRow';

type State = any;

interface RulesTableProps {
  isLoading: boolean;
  data: Array<RulesNotifications>;
}

const RulesTable: React.FC<RulesTableProps> = ({
  data,
  isLoading,
}) => {
  const history = useHistory();
  const urlSearch: any = history.location?.search;
  const params = qs.parse(urlSearch);
  const highlightedRuleId = Number(params?.ruleId);

  const loggedInUserId = useSelector((state: any) => state.app.id);
  const permission = useSelector((state: any) => state.app.permission);
  const [activeAlertRuleIndex, setActiveAlertRuleIndex] = useState<number | null>(null);
  const [column, setColumn] = useState<string | null>(null);
  const [direction, setDirection] = useState<'ascending' | 'descending'>('descending');
  const [rowHoverIndex, setRowHoverIndex] = useState<number | null>(null);
  const [parsedData, setparsedData] = useState<Array<RulesNotifications>>(data);

  useEffect(() => {
    if (highlightedRuleId) {
      setActiveAlertRuleIndex(highlightedRuleId);
    }
  }, [highlightedRuleId]);

  useEffect(() => {
    setparsedData(data);
  }, [data]);

  const handleOpen = (id: number): void => {
    const newIndex = activeAlertRuleIndex === id ? null : id;
    setActiveAlertRuleIndex(newIndex);
  };

  const handleHover = (id: number | null): void => setRowHoverIndex(id);

  const handleSort = (clickedColumn: string): void => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setparsedData(_.orderBy(data, [clickedColumn], ['asc']));
      setDirection('ascending');
    } else {
      if (direction === 'descending') {
        setparsedData(data);
        setColumn(null);
        setDirection('descending');
        return;
      }
      let newDirection;
      let fnDirection;
      if (direction === 'ascending') {
        newDirection = 'descending';
        fnDirection = 'desc';
      } else {
        newDirection = 'ascending';
        fnDirection = 'asc';
      }
      setparsedData(_.orderBy(data, [clickedColumn], [fnDirection]));
      setDirection(newDirection);
    }
  };

  const renderNotifications = (): React.ReactNode => (
    <Table.Body>
      {_.map(parsedData, alertRule => {
        const canSeeRule = hasPermission(permission, 'manager') || alertRule.deliveryTo.includes(loggedInUserId);
        if (!canSeeRule) return null;
        return (
          <RulesRow
            activeAlertRuleIndex={activeAlertRuleIndex}
            alertRule={alertRule}
            highlighted={highlightedRuleId === alertRule.id}
            key={alertRule.id}
            onOpen={handleOpen}
            onHover={handleHover}
            rowHoverIndex={rowHoverIndex}
          />
        );
      })}
    </Table.Body>
  );

  return (
    <Segment className={css.container} loading={isLoading}>
      <Table className={css.table} collapsing sortable selectable>
        <RulesHeader column={column} direction={direction} onSort={handleSort} />
        { renderNotifications() }
      </Table>
    </Segment>
  );
};

export default RulesTable;
