import { useTeamComparisonBodyChartContext } from 'components/TeamComparisonChart/Body/BodyContext';
import React, { FC } from 'react';
import { Value } from 'components/TeamComparisonChart/TeamComparisonChart';
import css from './BarColumn.module.css';

export interface BenchmarkLineProps {
  value: Value;
  title?: string;
  color?: string;
}

export const BenchmarkLine: FC<BenchmarkLineProps> = ({
  value,
  title,
  color,
}) => {
  const {
    maxValue: { sortValue: maxVal },
    columnHeaders: { visible: showHeaders },
  } = useTeamComparisonBodyChartContext();
  const { sortValue: benchmarkSortVal, displayValue: benchmarkDisplayVal } = value;
  if (maxVal === null || benchmarkSortVal === null) return <></>;

  let leftPercent = (benchmarkSortVal / maxVal) * 100;
  if (leftPercent > 100) leftPercent = 100;
  if (leftPercent < 0) leftPercent = 0;

  const lineColor = color || '#333333';

  const left = `${leftPercent}%`;
  return (
    <div
      className={showHeaders ? css.benchmarkLine : css.benchmarkLineFull}
      style={{ left, color: lineColor }}
    >
      <div className={css.benchmarkLabel}>
        {title ? `${title}: ${benchmarkDisplayVal}` : ''}
      </div>
    </div>
  );
};
