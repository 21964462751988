export type MetricType = 'AccountsThatNeedAttention' | 'AccountsTouched' | 'AvgActivitiesPerAccount' |
  'AvgEmailLength' | 'AvgEmailReadability' | 'InboundSentiment' | 'NumberActiveAccounts' |
  'NumberActiveContacts' | 'ReplyRate' | 'SalesforceReport' | 'TotalActivities' | 'TotalCalls' | 'TotalEmails' | 'TotalEvents' |
  'TotalTasks';

type ItemString = {
  one: string;
  many: string;
};

export interface AlertRuleLanguagePack {
  // Example Subject: Henry's average words per email
  // Example Body: Henry has an average of less than 5 words per email
  subjectTopic: string; // ex. Henry's [average words per email]
  countable: 'countable' | 'notCountable'; // determines whether to use less than/fewer than
  preValueDescription?: string; // words before the value and after "has". Ex. "..Henry has [an average of] less than"
  postValueDescription?: ItemString; // if thresholdValue is 1, use one key, else use many key.
                                     // Ex. "..less than 5 [this.many = words per email]"
  unit?: string; // unit appended to the value. Ex. "Henry has a negative response rate of 5[%]"
  dropdownGroup: 'buyersight' | 'custom';
}

type MetricDefinitions = {
  [K in MetricType]?: AlertRuleLanguagePack;
};

/* eslint-disable max-len */
const mertricDefinitions: MetricDefinitions = {
  AccountsThatNeedAttention: {
    subjectTopic: 'accounts that need attention',
    countable: 'countable',
    description: 'Accounts with open Opportunities that have not had an activity within the "Active Period" defined in Settings (default of 14 days).',
    postValueDescription: {
      one: 'account that needs attention',
      many: 'accounts that need attention',
    },
    dropdownGroup: 'buyersight',
  },
  AccountsTouched: {
    subjectTopic: 'accounts touched',
    countable: 'countable',
    description: null,
    postValueDescription: {
      one: 'account touched',
      many: 'accounts touched',
    },
    dropdownGroup: 'buyersight',
  },
  AvgActivitiesPerAccount: {
    subjectTopic: 'average activities per account',
    countable: 'countable',
    description: null,
    preValueDescription: 'an average of',
    postValueDescription: {
      one: 'activity per account',
      many: 'activities per account',
    },
    dropdownGroup: 'buyersight',
  },
  AvgEmailLength: {
    subjectTopic: 'words per email',
    countable: 'countable',
    description: 'Average outbound email word length. Sales emails between 75-125 words have been shown to receive the highest reply rates',
    preValueDescription: 'an average of',
    postValueDescription: {
      one: 'word per email',
      many: 'words per email',
    },
    dropdownGroup: 'buyersight',
  },
  AvgEmailReadability: {
    subjectTopic: 'email grade level',
    countable: 'notCountable',
    description: 'Average outbound email readability, as measured by Flesch-Kincaid grade level. A grade level higher than 9 is considered difficult to read, while 3-4 is optimal for most sales situations',
    preValueDescription: 'an average email grade level of',
    dropdownGroup: 'buyersight',
  },
  // InboundSentiment: {
  //   subjectTopic: 'inbound sentiment',
  //   countable: 'notCountable',
  //   description: 'Average sentiment of inbound emails from prospects and customers. BuyerSight’s AI identifies when deals are moving forward and when they are not, giving a clear range of “hot” (+1) to “cold” (-1) responses.',
  //   preValueDescription: 'an inbound sentiment score of',
  //   dropdownGroup: 'buyersight',
  // },
  NumberActiveAccounts: {
    subjectTopic: 'number of active accounts',
    countable: 'countable',
    description: 'The number of Accounts contacted by the rep within the "Active Period" defined in Settings (default of 14 days).',
    postValueDescription: {
      one: 'active account',
      many: 'active accounts',
    },
    dropdownGroup: 'buyersight',
  },
  NumberActiveContacts: {
    subjectTopic: 'number of active contacts',
    countable: 'countable',
    description: 'The number of Contacts contacted by the rep within the "Active Period" defined in Settings (default of 14 days).',
    postValueDescription: {
      one: 'active contact',
      many: 'active contacts',
    },
    dropdownGroup: 'buyersight',
  },
  ReplyRate: {
    subjectTopic: 'reply rate',
    countable: 'notCountable',
    description: 'The percentage of inbound emails received by the rep over the outbound emails sent by the rep',
    preValueDescription: 'a reply rate of',
    unit: '%',
    dropdownGroup: 'buyersight',
  },
  SalesforceReport: {
    subjectTopic: 'salesforce report',
    countable: 'notCountable',
    description: 'Custom metric based off of a Salesforce report',
    dropdownGroup: 'custom',
    source: 'salesforce',
  },
  TotalActivities: {
    subjectTopic: 'number of total activities',
    countable: 'countable',
    description: null,
    postValueDescription: {
      one: 'activity',
      many: 'activities',
    },
    dropdownGroup: 'buyersight',
  },
  TotalCalls: {
    subjectTopic: 'number of calls',
    countable: 'countable',
    description: null,
    postValueDescription: {
      one: 'call',
      many: 'calls',
    },
    dropdownGroup: 'buyersight',
  },
  TotalEmails: {
    subjectTopic: 'number of emails',
    countable: 'countable',
    description: null,
    postValueDescription: {
      one: 'email',
      many: 'emails',
    },
    dropdownGroup: 'buyersight',
  },
  TotalEvents: {
    subjectTopic: 'number of events',
    countable: 'countable',
    description: null,
    postValueDescription: {
      one: 'event',
      many: 'events',
    },
    dropdownGroup: 'buyersight',
  },
  TotalTasks: {
    subjectTopic: 'number of tasks',
    countable: 'countable',
    description: null,
    postValueDescription: {
      one: 'task',
      many: 'tasks',
    },
    dropdownGroup: 'buyersight',
  },
};
/* eslint-enable max-len */

export default mertricDefinitions;
