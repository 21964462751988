import { handleActions } from 'redux-actions';
import actions from 'actions';

import { Recommendation } from 'models/recommendation';

interface RecommendationState {
  recommendationList: Array<Recommendation>;
  isLoadingRecommendationList: boolean;
  currentRecommendation?: Recommendation;
  isLoadingRecommendation: boolean;
  sidebar: Array<Recommendation>;
  tags: Array<string>;
  isLoadingTags: boolean;
}

const initialState: RecommendationState = {
  recommendationList: [],
  isLoadingRecommendationList: false,
  currentRecommendation: undefined,
  isLoadingRecommendation: false,
  sidebar: [],
  tags: [],
  isLoadingTags: false,
};

const reducer = handleActions(
  {
    [actions.recommendation.fetchRecommendationListSuccess]: (state, { payload: { recommendationList } }) => ({
      ...state,
      recommendationList,
    }),
    [actions.recommendation.fetchRecommendationRequest]: state => ({
      ...state,
      isLoadingRecommendation: true,
    }),
    [actions.recommendation.fetchRecommendationSuccess]: (state, { payload: { recommendation } }) => ({
      ...state,
      currentRecommendation: recommendation,
      isLoadingRecommendation: false,
    }),
    [actions.recommendation.fetchRecommendationFailure]: state => ({
      ...state,
      isLoadingRecommendation: false,
    }),
    [actions.recommendation.fetchSidebarSuccess]: (state, { payload: { sidebar } }) => ({
      ...state,
      sidebar,
    }),
    [actions.recommendation.fetchTagsRequest]: state => ({
      ...state,
      isLoadingTags: true,
    }),
    [actions.recommendation.fetchTagsSuccess]: (state, { payload: { tags } }) => ({
      ...state,
      tags,
      isLoadingTags: false,
    }),
    [actions.recommendation.fetchTagsFailure]: state => ({
      ...state,
      isLoadingTags: false,
    }),
  },
  initialState,
);

export default reducer;
