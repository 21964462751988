import ProspectingContactDetailModal from 'App/Main/ProspectingHealthRoute/ProspectingContactDetailModal';
import ProspectingContactList from 'App/Main/ProspectingHealthRoute/ProspectingContactList';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';
import { RootState } from 'store';

import StrategyInsights from './StrategyInsights';
import ProspectingAccountList from './ProspectingAccountList';

interface Props {
  selectedTeamId: number | undefined;
}

interface State {}

class AccountHealthRoute extends Component<Props, State> {
  render(): React.ReactNode {
    const { selectedTeamId } = this.props;
    if (!selectedTeamId) {
      return (<Segment>Please select a team to view</Segment>);
    }
    return (
      <>
        <StrategyInsights />
        <Switch>
          <Route path="/app/prospecting/contacts">
            <ProspectingContactList teamId={selectedTeamId} />
          </Route>
          <Route path="/app/prospecting/:accountId/contacts">
            <ProspectingContactList teamId={selectedTeamId} />
          </Route>
          <Route path="/app/prospecting/:accountId" component={StrategyInsights} />
          <Route path="/app/prospecting" component={ProspectingAccountList} />
        </Switch>
        <Route path="/app/prospecting/:accountId/contacts/:contactId">
          <ProspectingContactDetailModal />
        </Route>
        <Route path="/app/prospecting/contacts/:contactId">
          <ProspectingContactDetailModal />
        </Route>
      </>
    );
  }
}

export default connect(
  (state: RootState): Partial<Props> => ({ selectedTeamId: state.team.selectedTeamId }),
  null,
)(AccountHealthRoute);
