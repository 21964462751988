import React from 'react';
import { Accordion } from 'semantic-ui-react';
import { TeamWithMembers } from 'models/team';
import { CollapsedTeamCard } from './CollapsedTeamCard';
import { ExpandedTeamCard } from './ExpandedTeamCard';

import css from './TeamCard.module.css';

interface TeamCardProps {
  team: TeamWithMembers;
  active: boolean;
  index: number;
  handleToggleActive: () => void;
  handleOpenAddTeamMemberModal: () => void;
}

export const TeamCard: React.FC<TeamCardProps> = ({
  index,
  handleToggleActive,
  active,
  team,
  handleOpenAddTeamMemberModal,
}) => (
  <>
    <Accordion.Title
      className={`${css.teamCardContainer} ${active ? css.active : ''}`}
      index={index}
      active={active}
      onClick={handleToggleActive}
    >
      <CollapsedTeamCard active={active} team={team} />
    </Accordion.Title>
    <Accordion.Content active={active} index={index}>
      <ExpandedTeamCard
        handleOpenAddTeamMemberModal={handleOpenAddTeamMemberModal}
        team={team}
      />
    </Accordion.Content>
  </>
);
