import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Dropdown, Popup, DropdownItemProps } from 'semantic-ui-react';
import moment, { Moment } from 'moment';
import { FaCompressAlt, FaExpandAlt } from 'react-icons/fa';

import allActions, { bindCombinedActions } from 'actions';
import { getTrackedSelectedTeamMembersForDropdown } from 'selectors/team';
import api from 'lib/api';
import { formatDate } from 'lib/util';
import { getPipelineSettings } from 'selectors/organization';

import { hasPermission, Permission } from 'components/AccessControl';
import DashboardTile from 'components/DashboardTile';

import WaterfallChart from '../../../components/WaterfallChart';
import css from './PipelineWaterfall.module.css';
import PipelineFilterModal from '../PipelineFilterModal';

interface WaterfallFilterOptions {
  ownerIds?: number[];
  opportunityTypes?: string[];
  custom?: { [key: string]: Array<string|number> };
}

interface Props {
  actions: any;
  opportunityTypes: string[];
  permission: Permission;
  pipelineSettings: any;
  selectedTeamId?: number;
  teamMembersForDropdown: DropdownItemProps[];
}

interface State {
  isLoading: boolean;
  isMinimized: boolean;
  waterfallData: any;
  underlyingData: any;
  startDate: any;
  endDate: any;
  filters: WaterfallFilterOptions;
  calendarActive?: boolean;
  calendarActiveOption: CalendarKey;
}

type CalendarKey = 'prevQuarter' | 'prevMonth' | 'quarter' | 'month';
type CalendarOptionProps = {
  label: string;
  start: Moment;
  end: Moment;
}

const calendarOptionsList: { [k in CalendarKey]: CalendarOptionProps } = {
  prevQuarter: {
    label: 'Previous Quarter',
    end: moment().subtract(1, 'quarter').endOf('quarter'),
    start: moment().subtract(1, 'quarter').startOf('quarter'),
  },
  prevMonth: {
    label: 'Previous Month',
    end: moment().subtract(1, 'month').endOf('month'),
    start: moment().subtract(1, 'month').startOf('month'),
  },
  quarter: {
    label: 'Current Quarter',
    end: moment().endOf('quarter'),
    start: moment().startOf('quarter'),
  },
  month: {
    label: 'Current Month',
    end: moment().endOf('month'),
    start: moment().startOf('month'),
  },
};

const CALENDAR_OPTION_KEY = 'pipelineWaterfallCalendarOption';
const CHART_FILTER_KEY = 'waterfallFilters';
class PipelineWaterfall extends Component<Props, State> {
  constructor(props) {
    super(props);

    const calendarActiveOption = localStorage.getItem(CALENDAR_OPTION_KEY) as CalendarKey
      || 'prevQuarter';
    const calendarOptionProps = calendarOptionsList[calendarActiveOption];

    const filterStr = localStorage.getItem(CHART_FILTER_KEY);
    const filters = filterStr ? JSON.parse(filterStr) : { groupBy: 'stageName' };

    this.state = {
      isLoading: true,
      isMinimized: true,
      waterfallData: [],
      underlyingData: [],
      startDate: calendarOptionProps.start,
      endDate: calendarOptionProps.end,
      filters,
      calendarActive: false,
      calendarActiveOption,
    };
  }

  componentDidMount(): void {
    this.fetchPipelineData();
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const { selectedTeamId } = this.props;
    const { startDate, endDate, filters } = this.state;
    const teamDiff = selectedTeamId !== prevProps.selectedTeamId;
    const startDateDiff = startDate !== prevState.startDate;
    const endDateDiff = endDate !== prevState.endDate;
    const filterDiff = filters !== prevState.filters;
    if (teamDiff || startDateDiff || endDateDiff || filterDiff) {
      this.fetchPipelineData();
      ((): void => {
        this.setState({ calendarActive: false });
      })();
    }
  }

  // TODO: Set a teamId param when fetching pipeline waterfall data
  // get from selectedTeamId in team state
  fetchPipelineData = async (): Promise<void> => {
    const { selectedTeamId } = this.props;
    const { startDate, endDate, filters } = this.state;
    this.setState({ waterfallData: [], isLoading: true });
    const opts: any = { startDate, endDate, ...filters };
    if (!filters.ownerIds?.length) {
      opts.teamId = selectedTeamId;
    }
    if (filters.custom) {
      opts.custom = JSON.stringify(filters.custom);
    }
    const resp = await api.getPipelineWaterfall(opts);
    if (!resp.status) {
      // eslint-disable-next-line
      console.error('Error fetching pipeline waterfall');
    }
    this.setState({
      waterfallData: resp.data,
      underlyingData: resp.opportunities,
      isLoading: false,
    });
  };

  handleExpand = (): void => {
    this.setState((prevState: State) => ({ isMinimized: !prevState.isMinimized }));
  };

  handleCalendarChange = (value): void => {
    const newStart = moment(value[0]).format('YYYY-MM-DD');
    const newEnd = moment(value[1]).format('YYYY-MM-DD');

    this.setState({ calendarActive: false, startDate: newStart, endDate: newEnd });
  };

  handleDownloadChart = (): void => {
    const { underlyingData: rawData, startDate, endDate } = this.state;
    const title = 'Waterfall Data';
    const header: string[] = [];
    const data: any[][] = [];
    if (rawData.length === 0) return;

    Object.keys(rawData[0]).forEach((key: string): void => {
      header.push(key);
    });

    rawData.forEach(rowData => {
      data.push(Object.values(rowData).map(d => `" ${d} "`));
    });

    const fileName = `${startDate.format('YYYYMMDD')}_${endDate.format('YYYYMMDD')}_${title}`;

    const rows = [header, ...data];
    const csvContent = `data:text/csv;charset=utf-8,${rows.map(e => e.join(',')).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  handleDateClick = key => (): void => {
    const buttonProps: CalendarOptionProps = calendarOptionsList[key];
    localStorage.setItem(CALENDAR_OPTION_KEY, key);
    this.setState({
      startDate: buttonProps.start,
      endDate: buttonProps.end,
      calendarActive: false,
      calendarActiveOption: key,
    });
  };

  setMenuState = (): void => {
    this.setState(prevState => ({ calendarActive: !prevState.calendarActive }));
  };

  handleFilterChange = (_, data): void => {
    this.setState(prevState => {
      const newFilters = {
        ...prevState.filters,
        ownerIds: data?.owners,
        stageName: data?.stages,
        opportunityTypes: data?.types,
        custom: data?.custom,
      };
      Object.keys(newFilters).forEach(key => newFilters[key] === undefined && delete newFilters[key]);
      localStorage.setItem(CHART_FILTER_KEY, JSON.stringify(newFilters));
      return { filters: newFilters };
    });
  };

  render(): React.ReactNode {
    const { opportunityTypes, permission, pipelineSettings, teamMembersForDropdown } = this.props;
    const {
      calendarActiveOption,
      waterfallData,
      isLoading,
      isMinimized,
      startDate,
      endDate,
      filters,
    } = this.state;

    const format = 'MMM DD, YYYY';
    const label = `${formatDate(startDate, format)} - ${formatDate(endDate, format)}`;

    const buttons = Object.keys(calendarOptionsList)
      .map((key): React.ReactElement => (
        <Dropdown.Item
          key={key}
          active={key === calendarActiveOption}
          className={css.presetButtons}
          text={calendarOptionsList[key].label}
          onClick={this.handleDateClick(key)}
        />
      ));

    const clearFilters = (): void => {
      this.handleFilterChange(undefined, {
        ownerIds: [],
        stageName: [],
        opportunityTypes: [],
        custom: [],
      });
    };
    const content: JSX.Element = (
      <>
        <div className={css.chartContainer}>
          <div>
            <Popup
              content="The Pipeline Waterfall shows what happened to your pipeline that was originally expected to close in this period."
              trigger={(<div className={css.chartTitle}>Pipeline Waterfall</div>)}
            />
            <div className={css.chartSubTitle}>
              <Dropdown className={css.calendarDropdown} item text={label} clearable>
                <Dropdown.Menu>
                  {buttons}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {
              waterfallData?.length !== 0 ? (
                <WaterfallChart
                  pipelineData={waterfallData}
                  isLoading={isLoading}
                  isMinimized={isMinimized}
                  tooltipTitle={label}
                />
              ) : (
                <div>
                  <div>{`There is no opportunity data for the selected time range From ${label}`}</div>
                  <div className="link" onClick={clearFilters}>Perhaps clear your filters?</div>
                </div>
              )
            }
          </div>
        </div>
      </>
    );
    const opportunityTypeOptions = opportunityTypes.map(o => ({ key: o, text: o, value: o }));
    const ownerOptions = hasPermission(permission, 'manager') ? teamMembersForDropdown : undefined;
    const cornerIcon = isMinimized
      ? (<FaExpandAlt onClick={this.handleExpand} />)
      : (<FaCompressAlt onClick={this.handleExpand} />);

    return (
      <DashboardTile onClose={this.handleExpand} isLoading={isLoading} isMinimized={isMinimized}>
        <div className={css.actionsContainer}>
          <PipelineFilterModal
            filters={filters}
            onChange={this.handleFilterChange}
            opportunityTypeOptions={opportunityTypeOptions}
            ownerOptions={ownerOptions}
            customFilters={pipelineSettings?.customFilters}
          />
          <div className="link brand">
            <Popup
              inverted
              basic
              size="mini"
              position="bottom center"
              style={{ backgroundColor: '#5A5B5D' }}
              content="Download"
              trigger={<Icon name="download" title="Download as CSV" onClick={this.handleDownloadChart} />}
            />
          </div>
          <div className="link brand">
            <Popup
              inverted
              basic
              size="mini"
              position="bottom center"
              style={{ backgroundColor: '#5A5B5D' }}
              content={isMinimized ? 'Expand' : 'Collapse'}
              trigger={cornerIcon}
            />
          </div>
        </div>
        {content}
      </DashboardTile>
    );
  }
}
export default connect(
  (state: any) => ({
    opportunityTypes: state.organization.opportunityTypes,
    permission: state.app.permission,
    pipelineSettings: getPipelineSettings(state),
    selectedTeamId: state.team.selectedTeamId,
    teamMembersForDropdown: getTrackedSelectedTeamMembersForDropdown(state),
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(PipelineWaterfall);
