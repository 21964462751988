import { TeamApi } from 'features/Api';
import { DateTime } from 'luxon';
import { ActivityCount } from 'models/activity';
import React, { ReactElement, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Chart, ChartConfiguration, ChartData, registerables } from 'chart.js';
import { sum } from 'lodash';
import { Grid, GridColumn, GridRow, Icon, List, ListContent, ListHeader, ListItem, Loader, Popup, Segment } from 'semantic-ui-react';
import { getColorRgb } from 'lib/util';
import css from './styles.module.css';
import BSIcon from '../../../../../components/BSIcon/BSIcon';

type EmptyDataset = false;
const isEmptyDataset = (something: any): something is EmptyDataset => something === false;

function getDataset(teamStatsApiResponse: ActivityCount): ChartData<'doughnut'> | EmptyDataset {
  const {
    types: {
      email: {
        types: { inbound: inboundCount, outbound: outboundCount },
      },
      event: { total: meetingCount },
      call: { total: callCount },
      task: {
        total: tasksCount,
        types: { sms: smsCount, linkedin: linkedinCount },
      },
    },
  } = teamStatsApiResponse;

  const data = [
    inboundCount,
    outboundCount,
    meetingCount,
    callCount,
    tasksCount,
    smsCount,
    linkedinCount,
  ];
  if (sum(data) === 0) return false as EmptyDataset;
  return {
    labels: [
      'Inbound Emails',
      'Outbound Emails',
      'Meetings',
      'Calls',
      'Tasks',
      'SMS',
      'LinkedIn',
    ],
    datasets: [{
      data,
      backgroundColor: [
        getColorRgb('mail'),
        getColorRgb('mail'),
        getColorRgb('calendar'),
        getColorRgb('call'),
        getColorRgb('tasks'),
        getColorRgb('sms'),
        getColorRgb('linkedin'),
      ],
    }],
  };
}

function getChartConfig(teamStatsApiResponse: ActivityCount): ChartConfiguration | EmptyDataset {
  const data = getDataset(teamStatsApiResponse);
  if (isEmptyDataset(data)) return data;
  return {
    type: 'doughnut',
    data,
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  };
}

function ActivityCounts(props: {
  userId: number;
  teamId: number;
  startDateIso: string;
  endDateIso: string;
}): ReactElement {
  const { userId, teamId, startDateIso, endDateIso } = props;

  const { data: teamStatsApiResponse, isLoading, isFetching } = TeamApi.getActivityStats.useActivityStatsQuery({
    teamId,
    startDate: DateTime.fromISO(startDateIso).toFormat('yyyyMMdd'),
    endDate: DateTime.fromISO(endDateIso).toFormat('yyyyMMdd'),
  });

  let mailCount: string | number = 'Error';
  let meetingCount: string | number = 'Error';
  let callCount: string | number = 'Error';
  let tasksCount: string | number = 'Error';
  let smsCount: string | number = 'Error';
  let linkedinCount: string | number = 'Error';
  let inboundCount: string | number = 'Error';
  let outboundCount: string | number = 'Error';

  if (isLoading || isFetching) {
    mailCount = 'Loading';
    meetingCount = 'Loading';
    callCount = 'Loading';
    tasksCount = 'Loading';
    linkedinCount = 'Loading';
    smsCount = 'Loading';
    inboundCount = 'Loading';
    outboundCount = 'Loading';
  } else if (teamStatsApiResponse && teamStatsApiResponse.activityCounts.members[userId]) {
    const userstats = teamStatsApiResponse.activityCounts.members[userId];
    ({
      types: {
        email: {
          total: mailCount,
          types: {
            inbound: inboundCount,
            outbound: outboundCount,
          },
        },
        call: { total: callCount },
        event: { total: meetingCount },
        task: {
          total: tasksCount,
          types: {
            sms: smsCount,
            linkedin: linkedinCount,
          },
        },
      },
    } = userstats);
    tasksCount = tasksCount + linkedinCount + smsCount;
  }
  return (
    <List className={css.ActivityCounts} relaxed="very">
      <ListItem>
        <ListHeader>
          <Popup
            basic
            trigger={(<BSIcon activityIcon="mail" circular bordered />)}
          >
            <Popup.Content className={css.popupContent}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width="10" className={css.header}>
                    <BSIcon
                      activityIcon="mail"
                      circular
                      bordered
                      cornerIcon={(<Icon corner name="arrow left" />)}
                    />
                    Inbound
                  </Grid.Column>
                  <Grid.Column width="6">
                    {inboundCount}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width="10" className={css.header}>
                    <BSIcon
                      activityIcon="mail"
                      circular
                      bordered
                      cornerIcon={(<Icon corner name="arrow right" />)}
                    />
                    Outbound
                  </Grid.Column>
                  <Grid.Column width="6">
                    {outboundCount}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width="16">
                    <i>Automatic responses are filtered from the scorecard metrics</i>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Popup.Content>
          </Popup>
          Emails
        </ListHeader>
        <ListContent>{mailCount}</ListContent>
      </ListItem>
      <ListItem>
        <ListHeader>
          <BSIcon activityIcon="call" circular bordered />
          Calls
        </ListHeader>
        {callCount}
      </ListItem>
      <ListItem>
        <ListHeader>
          <BSIcon activityIcon="calendar" circular bordered />
          Meetings
        </ListHeader>
        {meetingCount}
      </ListItem>
      <ListItem>
        <ListHeader>
          <Popup
            basic
            trigger={(<BSIcon activityIcon="tasks" circular bordered />)}
          >
            <Popup.Content className={css.popupContent}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width="10" className={css.header}>
                    <BSIcon activityIcon="tasks" circular bordered />
                    Tasks
                  </Grid.Column>
                  <Grid.Column width="6">
                    {tasksCount}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width="10" className={css.header}>
                    <BSIcon activityIcon="sms" circular bordered />
                    SMS
                  </Grid.Column>
                  <Grid.Column width="6">
                    {smsCount}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width="10" className={css.header}>
                    <BSIcon activityIcon="linkedin" circular bordered />
                    LinkedIn
                  </Grid.Column>
                  <Grid.Column width="6">
                    {linkedinCount}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Popup.Content>
          </Popup>
          Tasks
        </ListHeader>
        {tasksCount}
      </ListItem>
    </List>
  );
}

function ActivityChart(props: {
  userId: number;
  teamId: number;
  startDateIso: string;
  endDateIso: string;
}): ReactElement {
  let chart;
  const { userId, teamId, startDateIso, endDateIso } = props;
  const { data: teamStatsApiResponse, isFetching } = TeamApi.getActivityStats.useActivityStatsQuery({
    teamId,
    startDate: DateTime.fromISO(startDateIso).toFormat('yyyyMMdd'),
    endDate: DateTime.fromISO(endDateIso).toFormat('yyyyMMdd'),
  });

  const canvasRef = useCallback(canvasNode => {
    if (chart) {
      // destroy the old chart if it exists before making a new one
      chart.destroy();
    }
    const userCounts = teamStatsApiResponse?.activityCounts.members[userId];
    if (canvasNode && userCounts) {
      const config = getChartConfig(userCounts);
      if (!isEmptyDataset(config)) {
        const ctx = canvasNode.getContext('2d');
        if (ctx) {
          Chart.register(...registerables);
          chart = new Chart(ctx, config);
        }
      }
    }
  }, [teamStatsApiResponse, userId]);

  if (isFetching) {
    return <>Loading...</>;
  }
  return (
    <canvas ref={canvasRef} />
  );
}

export function ActivityWidget(props: {
  userId: number;
  teamId: number;
  startDateIso: string;
  endDateIso: string;
  isLoading?: boolean;
}): ReactElement {
  const { userId, teamId, startDateIso, endDateIso, isLoading } = props;

  const { data: teamActivityStats, isFetching } = TeamApi.getActivityStats.useActivityStatsQuery({
    teamId,
    startDate: DateTime.fromISO(startDateIso).toFormat('yyyyMMdd'),
    endDate: DateTime.fromISO(endDateIso).toFormat('yyyyMMdd'),
  });

  if (isLoading) {
    return (<Segment><Loader active /></Segment>);
  }

  let columnCount: 1 | 2 = 1;

  if (isFetching) columnCount = 2;
  else if (teamActivityStats && teamActivityStats.activityCounts.members[userId]) {
    const { activityCounts: { members } } = teamActivityStats;
    const userCounts = members[userId];
    const dataset = getDataset(userCounts);
    if (!isEmptyDataset(dataset)) {
      columnCount = 2;
    }
  }

  return (
    <Segment className={css.ActivityChart}>
      <Grid>
        <GridRow columns={2}>
          <GridColumn>
            <h4>Activities</h4>
          </GridColumn>
          <GridColumn textAlign="right">
            <Link to={`/app/team/${userId}/activityFeed`} className="link brand">
              <BSIcon name="long arrow alternate right" />
            </Link>
          </GridColumn>
        </GridRow>
        <GridRow columns={columnCount} divided={columnCount === 2}>
          <GridColumn className={(columnCount === 1 ? 'hidden' : '')}>
            <ActivityChart userId={userId} teamId={teamId} startDateIso={startDateIso} endDateIso={endDateIso} />
          </GridColumn>
          <GridColumn>
            <ActivityCounts userId={userId} teamId={teamId} startDateIso={startDateIso} endDateIso={endDateIso} />
          </GridColumn>
        </GridRow>
      </Grid>
    </Segment>
  );
}
