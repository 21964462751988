import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Header, Input, Segment, TextArea } from 'semantic-ui-react';

import api from 'lib/api';
import allActions, { bindCombinedActions } from 'actions';

interface Props {
  actions: any;
}
interface State {
  input: string;
  query: string;
  result: string;
  objectName: string;
  describeResult: string;
}

class Super extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      input: '',
      query: '',
      result: '',
      objectName: '',
      describeResult: '',
    };
  }

  handleSalesforceClick = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    const { actions } = this.props;
    const { input } = this.state;
    sessionStorage.removeItem('selectedTeamId');
    actions.app.authAs(input, 'salesforce');
  };

  handleHubSpotClick = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    const { actions } = this.props;
    const { input } = this.state;
    sessionStorage.removeItem('selectedTeamId');
    actions.app.authAs(input, 'hubspot');
  };

  handleInputChange = (_, data): void => {
    const input = data.value.trim();
    this.setState({ input });
  };

  handleQueryInputChange = (_, data): void => {
    const query = data.value.trim();
    this.setState({ query });
  };

  handleQueryClick = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault();
    const { query } = this.state;
    const response = await api.salesforceQuery(query);
    this.setState({ result: JSON.stringify(response, null, 2) });
  };

  handleDescribeInputChange = (_, data): void => {
    const objectName = data.value.trim();
    this.setState({ objectName });
  };

  handleDescribeClick = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault();
    const { objectName } = this.state;
    const response = await api.describeSalesforceObject(objectName);
    const fieldNames = response.meta.fields.map(o => o.name);
    const describeResult = JSON.stringify(fieldNames, null, 2);
    this.setState({ describeResult });
  };

  render(): React.ReactNode {
    const { result, describeResult } = this.state;
    return (
      <>
        <Segment>
          <Form>
            <Form.Group>
              <Form.Field width="4">
                <Input
                  onChange={this.handleInputChange}
                  placeholder="Salesforce UserId"
                />
              </Form.Field>
              <Form.Field>
                <Button onClick={this.handleSalesforceClick}>Login As</Button>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width="4">
                <Input
                  onChange={this.handleInputChange}
                  placeholder="HubSpot UserId"
                />
              </Form.Field>
              <Form.Field>
                <Button onClick={this.handleHubSpotClick}>Login As</Button>
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>
        <Segment>
          <Header>Query (be careful)</Header>
          <Form>
            <Form.Group>
              <Form.Field width="8">
                <TextArea
                  onChange={this.handleQueryInputChange}
                  rows="10"
                />
              </Form.Field>
              <Form.Field>
                <Button onClick={this.handleQueryClick}>Query Salesforce Instance</Button>
              </Form.Field>
            </Form.Group>
          </Form>
          <pre>
            {result}
          </pre>
        </Segment>
        <Segment>
          <Header>Describe Object</Header>
          <Form>
            <Form.Group>
              <Form.Field width="4">
                <Form.Input onChange={this.handleDescribeInputChange} />
              </Form.Field>
              <Form.Field>
                <Button onClick={this.handleDescribeClick}>Describe Object</Button>
              </Form.Field>
            </Form.Group>
          </Form>
          <pre>
            {describeResult}
          </pre>
        </Segment>
      </>
    );
  }
}

export default connect(
  null,
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(Super);
