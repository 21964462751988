import { TeamApi } from 'features/Api';
import { EmailMetrics } from 'models/metric/activityMetrics';
import React, { ReactElement, useState } from 'react';
import { Grid, GridColumn, GridRow, Segment } from 'semantic-ui-react';
import { DateTime, Duration } from 'luxon';

import BehaviorMetric from 'components/BehaviorMetric/BehaviorMetric';
import { MetricName } from 'models/metric';

import MetricOverTimeChartModal from '../components/MetricOverTimeChartModal';

import css from './styles.module.css';

export function BasicStats(props: {
  userId: number;
  teamId: number;
  startDateIso: string;
  endDateIso: string;
}): ReactElement {
  const { userId, teamId, startDateIso, endDateIso } = props;
  const [isMetricOverTimeChartModalOpen, setIsMetricOverTimeChartModalOpen] = useState(false);
  const [statNameInModal, setStatNameInModal] = useState<MetricName | undefined>(undefined);
  const {
    data: teamStatsApiResponse,
    isLoading,
    isFetching,
    isError,
  } = TeamApi.getActivityStats.useActivityStatsQuery({
    teamId,
    startDate: DateTime.fromISO(startDateIso).toFormat('yyyyMMdd'),
    endDate: DateTime.fromISO(endDateIso).toFormat('yyyyMMdd'),
  });

  let userStats: EmailMetrics;
  let avgActivitiesPerDayStr = 'Error';
  let prospectingPercentageStr = 'Error';
  let avgWordCountStr = 'Error';
  let avgGradeLevelStr = 'Error';
  let accountsEngagedStr = 'Error';
  let contactsEngagedStr = 'Error';
  let avgResponseDaysStr = 'Error';
  let avgFollowupDaysStr = 'Error';

  if (!isLoading && !isFetching && !isError
    && teamStatsApiResponse && teamStatsApiResponse.emailStats && teamStatsApiResponse.emailStats.members[userId]) {
    userStats = teamStatsApiResponse.emailStats.members[userId];
    const {
      avgActivitiesPerDay, prospectingPercentage, avgWordCount, avgGradeLevel,
      accountsEngaged, contactsEngaged, avgRepResponse: avgResponseSeconds, avgRepFollowUp: avgFollowupSeconds,
    } = userStats;

    avgResponseDaysStr = avgResponseSeconds !== null
      ? `${Duration.fromObject({ seconds: avgResponseSeconds }).as('days').toFixed(2)} days`
      : '--';
    avgFollowupDaysStr = avgFollowupSeconds !== null
      ? `${Duration.fromObject({ seconds: avgFollowupSeconds }).as('days').toFixed(2)} days`
      : '--';

    avgWordCountStr = avgWordCount !== null
      ? avgWordCount.toFixed(1).toString()
      : '--';
    avgGradeLevelStr = avgGradeLevel !== null
      ? avgGradeLevel.toFixed(1).toString()
      : '--';

    prospectingPercentageStr = prospectingPercentage !== null
      ? `${(prospectingPercentage * 100).toFixed(1)} %`
      : '--';

    avgActivitiesPerDayStr = avgActivitiesPerDay.toFixed(1).toString();
    accountsEngagedStr = accountsEngaged.toFixed(0).toString();
    contactsEngagedStr = contactsEngaged.toFixed(0).toString();
  } else if (isLoading || isFetching) {
    avgActivitiesPerDayStr = 'Loading...';
    prospectingPercentageStr = 'Loading...';
    avgWordCountStr = 'Loading...';
    avgGradeLevelStr = 'Loading...';
    accountsEngagedStr = 'Loading...';
    contactsEngagedStr = 'Loading...';
    avgResponseDaysStr = 'Loading...';
    avgFollowupDaysStr = 'Loading...';
  }

  const openMetricOverTimeChartModal = (statName: MetricName): void => {
    setIsMetricOverTimeChartModalOpen(true);
    setStatNameInModal(statName);
  };

  const metricOverTimeChartModal: ReactElement = (
    <MetricOverTimeChartModal
      open={isMetricOverTimeChartModalOpen}
      onClose={(): void => setIsMetricOverTimeChartModalOpen(false)}
      modalMetric={statNameInModal as MetricName}
      teamId={teamId}
      userId={userId}
    />
  );

  return (
    <Segment>
      {metricOverTimeChartModal}
      <Grid padded className={css.metricGrid}>
        <GridRow columns={4}>
          <GridColumn
            stretched
            onClick={(): void => openMetricOverTimeChartModal('avgActivitiesPerDay')}
          >
            <BehaviorMetric
              icon="briefcase"
              label="Average Daily Activities"
              value={avgActivitiesPerDayStr}
              popupText="Automatic responses are filtered from the scorecard metrics"
            />
          </GridColumn>
          <GridColumn stretched>
            <BehaviorMetric
              icon="running"
              label="Prospecting Activities"
              value={prospectingPercentageStr}
              popupText="The percentage of activities that have been intelligently tagged as “prospecting”
                by BuyerSight. This intelligent tag takes into account the content of the activity as well
                the context of surrounding activities."
            />
          </GridColumn>
          <GridColumn
            stretched
            onClick={(): void => openMetricOverTimeChartModal('avgWordCount')}
          >
            <BehaviorMetric
              icon="envelope-open-text"
              label="Email Length"
              value={avgWordCountStr}
              popupText="Average outbound email word length, which is often correlated with response rates."
            />
          </GridColumn>
          <GridColumn
            stretched
            onClick={(): void => openMetricOverTimeChartModal('avgGradeLevel')}
          >
            <BehaviorMetric
              icon="book"
              label="Email Grade Level"
              value={avgGradeLevelStr}
              popupText="Average outbound email readability, as measured by Flesch-Kincaid grade level. A higher grade level higher is considered difficult to read."
            />
          </GridColumn>
        </GridRow>
        <GridRow columns={4}>
          <GridColumn
            stretched
            onClick={(): void => openMetricOverTimeChartModal('accountsEngaged')}
          >
            <BehaviorMetric
              icon="building"
              label="Accounts Engaged"
              value={accountsEngagedStr}
            />
          </GridColumn>
          <GridColumn
            stretched
            onClick={(): void => openMetricOverTimeChartModal('contactsEngaged')}
          >
            <BehaviorMetric
              icon="users"
              label="Contacts Engaged"
              value={contactsEngagedStr}
            />
          </GridColumn>
          <GridColumn
            stretched
            onClick={(): void => openMetricOverTimeChartModal('avgRepResponse')}
          >
            <BehaviorMetric
              icon="stop-watch"
              label="Time to Respond"
              value={avgResponseDaysStr}
              popupText="Average time it takes the rep to respond to inbound emails from prospects and customers."
            />
          </GridColumn>
          <GridColumn
            stretched
            onClick={(): void => openMetricOverTimeChartModal('avgRepFollowUp')}
          >
            <BehaviorMetric
              icon="stop-watch"
              label="Time Between Followups"
              value={avgFollowupDaysStr}
              popupText="Average time between a rep’s outbound follow up emails, which have been
                identified by BuyerSight’s intelligent tagging."
            />
          </GridColumn>
        </GridRow>
      </Grid>
    </Segment>
  );
}
