import { handleActions } from 'redux-actions';

import actions from 'actions/app-actions';
import { User } from 'models/user';
import { Reducer } from 'redux';

export interface AppState extends User {
  hasLoaded: boolean;
  isLoggedIn: boolean;
  consentRequest: boolean;
  errorMsg?: string;
  loadingMessage: string;
  originalUserEmail: string | null;
  instanceUrl: string;
}

export const initialUserState: User = {
  id: null,
  active: false,
  deleted: false,
  sfId: '',
  extId: '',
  extSource: '',
  organizationId: -1,
  managerId: null,
  firstName: '',
  lastName: '',
  name: '',
  email: '',
  companyName: '',
  department: '',
  role: '',
  title: '',
  photoUrl: '',
  fullPhotoUrl: '',
  tosConsented: false,
  isSuperAdmin: false,
  isTrackedUser: false,
  permission: 'rep',
};

export const initialState: AppState = {
  hasLoaded: false,
  isLoggedIn: false,
  consentRequest: false,
  errorMsg: undefined,
  loadingMessage: '',
  originalUserEmail: localStorage.getItem('original-user'),
  instanceUrl: '',
  ...initialUserState,
};

const reducer: Reducer<AppState, any> = handleActions(
  {
    [actions.app.hasLoaded]: state => ({ ...state, hasLoaded: true }),
    [actions.app.currentUserSuccess]: (state, { payload: { user, permissions } }) => ({
      ...state,
      hasLoaded: true,
      ...permissions,
      ...user,
    }),
    [actions.app.currentUserFailure]: state => ({
      ...state,
      hasLoaded: true,
      ...initialUserState,
    }),
    [actions.app.consentRequest]: state => ({ ...state, consentRequest: true }),
    [actions.app.consentSuccess]: state => ({ ...state, consentRequest: false, tosConsented: true }),
    [actions.app.consentFailure]: state => ({ ...state, consentRequest: false }),
    [actions.app.loginRequest]: state => ({ ...state, isLoggedIn: false, loadingMessage: 'Logging in...' }),
    [actions.app.loginSuccess]: (state, { payload: { userId, instanceUrl } }) => ({
      ...state,
      id: userId,
      instanceUrl,
      isLoggedIn: true,
      errorMsg: undefined,
      loadingMessage: 'Login successful!',
    }),
    [actions.app.loginFailure]: (state, { payload: { msg } }) => {
      const { hasLoaded } = state;
      return { ...initialState, hasLoaded, errorMsg: msg };
    },
    [actions.app.userLoggedOut]: (state, { payload: { msg } }) => {
      const { hasLoaded } = state;
      sessionStorage.removeItem('selectedTeamId');
      return { ...initialState, hasLoaded, errorMsg: msg };
    },
    [actions.app.logoutSuccess]: state => {
      const { hasLoaded } = state;
      sessionStorage.removeItem('selectedTeamId');
      return { ...initialState, hasLoaded };
    },
    [actions.app.logoutFailure]: state => {
      const { hasLoaded } = state;
      return { ...initialState, hasLoaded };
    },
    [actions.app.setInstanceUrl]: (state, { payload: { instanceUrl } }) => ({ ...state, instanceUrl }),
    [actions.app.setOriginalUserEmail]: (state, { payload: { originalUserEmail } }) => {
      localStorage.setItem('original-user', originalUserEmail);
      return { ...state, originalUserEmail };
    },
    [actions.app.syncActivityRequest]: state => ({ ...state, loadingMessage: 'Syncing data with Salesforce...' }),
    [actions.app.syncActivitySuccess]: state => ({ ...state, loadingMessage: 'Sync complete!' }),
    [actions.app.syncActivityFailure]: state => ({ ...state, loadingMessage: 'Sync failed' }),
  },
  initialState,
);

export default reducer;
