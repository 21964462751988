import React from 'react';
import { Label } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';

import css from './RecommendationRenderer.module.css';


interface Props {
  body: string;
  tags: string[];
}

const RecommendationRenderer: React.FC<Props> = ({ body, tags }): React.ReactElement => (
  <>
    {tags?.length ? (
      <div className={css.tags}>
        {tags.map(tag => (<Label key={tag}>{tag}</Label>))}
      </div>
    ) : null}
    <ReactMarkdown
      className={css.recommendationMarkdown}
      allowDangerousHtml
      linkTarget="_blank"
    >
      {body || ''}
    </ReactMarkdown>
  </>
);

export default RecommendationRenderer;
