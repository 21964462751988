import { TimelineDataPoint } from 'features/Api/Account/getAccountTimeline';
import baseApi from '../apiSlice';
import { ApiResponse } from '../apiTypes';

export interface Query {
  teamId?: number;
}

interface RawResponse extends ApiResponse {
  data: TimelineDataPoint[];
}

const accountApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getIdealAccountTimeline: builder.query<TimelineDataPoint[], Query>({
      query: ({ teamId }) => `/account/health/timeline/ideal${teamId ? `?teamId=${teamId}` : ''}`,
      transformResponse: (response: RawResponse) => response.data,
    }),
  }),
});

export const useGetIdealAccountTimeline = accountApi.endpoints.getIdealAccountTimeline.useQuery;
