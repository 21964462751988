import React, { Component } from 'react';
import { Label, Popup, Icon } from 'semantic-ui-react';
import ExternalLink from 'components/ExternalLink';

import css from './OpportunityTag.module.css';

interface Props {
  id: number;
  active: boolean;
  accountId?: number | null;
  amount?: number | null;
  closeDate?: Date | null;
  description?: string | null;
  expectedRevenue?: string | null;
  isUpdatingOpportunity: boolean;
  modifiedDate?: Date | null;
  name?: string | null;
  onClick: () => void;
  opportunityHistoryId?: number | null;
  ownerId?: number | null;
  probability?: number | null;
  extId: string;
  extSource: string;
  stageName?: string | null;
}

interface State {}

export default class OpportunityTag extends Component<Props, State> {
  renderIcon = (): React.ReactNode => {
    const { active, onClick } = this.props;
    const body = active ? (
      <Icon name="delete" onClick={onClick} />
    ) : (
      <Icon className={css.actionIcon} name="plus" onClick={onClick} />
    );
    return body;
  };

  render(): React.ReactNode {
    const { extId, extSource, id, active, name, stageName, isUpdatingOpportunity } = this.props;

    return (
      <Popup
        hoverable
        mouseLeaveDelay={500}
        content={(
          <>
            {name}
            <ExternalLink extId={extId} extSource={extSource} type="opportunity" />
          </>
          )}
        trigger={(
          <Label
            key={id}
            className={active ? css.activeOpportunityTag : css.inactiveOpportunityTag}
          >
            {stageName}
            {
              isUpdatingOpportunity
                ? <Icon className={css.actionIcon} name="circle notched" loading />
                : this.renderIcon()
            }
          </Label>
        )}
      />
    );
  }
}
