import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Container,
  Form,
  Icon,
  Modal,
  Segment,
  TextArea,
} from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router-dom';

import allActions, { bindCombinedActions } from 'actions';
import { Recommendation } from 'models/recommendation';
import { formatDate } from 'lib/util';

import RecommendationRenderer from '../components/RecommendationRenderer';
import css from './Recommendation.module.css';

interface MatchParams {
  recommendationId: string;
}

interface Props extends RouteComponentProps<MatchParams> {
  actions: any;
  history: any;
  recommendation: Recommendation;
  isLoading: boolean;
}

interface State {
  userHelpful?: boolean;
  userFeedback?: string | number;
  showFeedbackModal: boolean;
}

class RecommendationsList extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      userHelpful: undefined,
      userFeedback: '',
      showFeedbackModal: false,
    };
  }

  componentDidMount(): void {
    const { actions, match } = this.props;
    const recommendationId = parseInt(match.params.recommendationId, 10);
    actions.recommendation.fetchRecommendation(recommendationId);
  }

  componentDidUpdate(): void {
    const { actions, recommendation, match } = this.props;
    const recommendationId = parseInt(match.params.recommendationId, 10);
    if (recommendation?.recommendationId !== recommendationId) {
      actions.recommendation.fetchRecommendation(recommendationId);
    } else if (recommendation && !recommendation.seenAt) {
      const { notificationId } = recommendation;
      (async (): Promise<void> => {
        await actions.recommendation.markSeen(notificationId);
        await actions.recommendation.fetchRecommendationSidebar({ limit: 20, offset: 0 });
      })();
    }
  }

  handleResolve = async (e: React.MouseEvent): Promise<void> => {
    e.stopPropagation();
    const { actions, history, recommendation } = this.props;
    const { userHelpful, userFeedback } = this.state;
    const { notificationId, resolvedAt } = recommendation;
    if (resolvedAt) {
      await actions.recommendation.markUnresolved(notificationId);
    } else {
      await actions.recommendation.saveFeedback({
        notificationId,
        helpful: userHelpful,
        feedback: userFeedback,
        resolve: true,
      });
    }
    history.push('/app/recommendations');
  };

  setOpen = (showFeedbackModal): void => {
    this.setState({ showFeedbackModal });
  };

  render(): React.ReactNode {
    const { history, recommendation, isLoading } = this.props;
    if (!recommendation) return null;
    const { title, body, tags, resolvedAt, notificationDate } = recommendation;
    const { userHelpful, userFeedback, showFeedbackModal } = this.state;

    const unresolveButton = (
      <Button basic className={css.resolveButton} onClick={this.handleResolve}>
        <Icon name="undo" color="black" />
        Unarchive
      </Button>
    );

    const resolveButton = (
      <Button basic className={css.resolveButton}>
        <Icon name="archive" color="black" />
        Archive
      </Button>
    );

    const feedbackForm = (
      <Modal
        onOpen={(): void => this.setOpen(true)}
        onClose={(): void => this.setOpen(false)}
        open={showFeedbackModal}
        size="small"
        trigger={resolveButton}
      >
        <Modal.Header className={css.feedbackFormHeader}>
          <Icon name="chat" />
          Was this recommendation helpful?
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field inline>
              <Button
                className="brand"
                primary={userHelpful === true}
                onClick={(): void => { this.setState({ userHelpful: true }); }}
              >
                <Icon name="thumbs up" />
                Helpful
              </Button>
              <Button
                className="brand"
                primary={userHelpful === false}
                onClick={(): void => { this.setState({ userHelpful: false }); }}
              >
                <Icon name="thumbs down" />
                Not Helpful
              </Button>
            </Form.Field>
            {userHelpful === !!userHelpful ? (
              <Form.Field>
                <label>{`Please provide some feedback on why this was ${userHelpful ? 'helpful' : 'unhelpful'}`}</label>
                <TextArea
                  value={userFeedback}
                  onChange={(_, data): void => { this.setState({ userFeedback: data.value }); }}
                />
              </Form.Field>
            ) : null}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button basic className={css.resolveButton} onClick={this.handleResolve}>
            <Icon name="archive" color="black" />
            Archive
          </Button>
        </Modal.Actions>
      </Modal>
    );

    const actionButton = resolvedAt ? unresolveButton : feedbackForm;

    return (
      <Container className={css.recommendation}>
        <Segment className={css.segment} loading={isLoading}>
          <div className={css.header}>
            <div>
              <div className="link brand" onClick={(): void => history.goBack()}>
                <Icon name="arrow left" />
              </div>
              <h1 title={title}>{title}</h1>
            </div>
            <div className={css.controls}>
              <div>
                {formatDate(notificationDate)}
              </div>
              <div>
                {actionButton}
              </div>
            </div>
          </div>
          <RecommendationRenderer
            body={body || ''}
            tags={tags}
          />
        </Segment>
      </Container>
    );
  }
}

export default connect(
  (state: any) => ({
    isLoading: state.recommendation.isLoadingRecommendation,
    recommendation: state.recommendation.currentRecommendation,
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(RecommendationsList);
