import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal, Select, DropdownProps, Button } from 'semantic-ui-react';
import allActions, { bindCombinedActions } from 'actions';
import { hasPermission, Permission, permissionOptions } from 'components/AccessControl';
import PermissionsInfo from 'components/PermissionsInfo';
import { User } from 'models/user';
import { getNumOfTrackedUsers } from 'selectors/user';
import SimpleUserTable, { SimpleUser } from '../SimpleUserTable/SimpleUserTable';
import TrackedSeats from './TrackedSeats';

import css from './EditUserModal.module.css';

interface EditUserModalProps {
  open: boolean;
  onClose: () => void;
  user: User;
  actions: any;
  actingUser: User;
  maxTrackedSeats: number;
  numOfTrackedUsers: number;
}

const EditUserModalBase: React.FC<EditUserModalProps> = ({
  open,
  onClose,
  user,
  actions,
  actingUser,
  maxTrackedSeats,
  numOfTrackedUsers,
}: EditUserModalProps): React.ReactElement => {
  const [selectedPermission, setSelectedPermission] = React.useState(user.permission);
  const [selectedTracked, setSelectedTracked] = React.useState(user.isTracked);
  const [isEditing, setEditing] = React.useState(false);

  const handleSelectedPermissionChange = (_: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void => {
    setSelectedPermission(data.value as Permission);
  };

  const handleEditUser = (): void => {
    setEditing(true);
    Promise.all([
      actions.user.trackUser({ ...user, isTracked: selectedTracked }, true),
      actions.user.setUserPermissions(user.id, selectedPermission),
    ]).then(() => {
      setEditing(false);
      onClose();
    }).catch(() => {
      setEditing(false);
      toast.error('There was an error updating the user');
    });
  };

  const users: SimpleUser[] = user ? [user] : [];
  const canEdit = (actingUser.id !== user.id
    && hasPermission(actingUser.permission, user.permission));
  return (
    <Modal closeIcon closeOnDimmerClick open={open} onClose={onClose}>
      <Modal.Header>Editing User</Modal.Header>
      <Modal.Content>
        <SimpleUserTable users={users} />
        <div className={css.modalActionsContainer}>
          <div>
            <Select
              disabled={!canEdit}
              onChange={handleSelectedPermissionChange}
              options={permissionOptions}
              value={selectedPermission}
            />
            {' '}
            <PermissionsInfo permission={selectedPermission} />
          </div>
          <TrackedSeats
            selected={selectedTracked}
            disabled={isEditing || (numOfTrackedUsers >= maxTrackedSeats && !user.isTracked)}
            maxTrackedSeats={maxTrackedSeats}
            numOfTrackedUsers={numOfTrackedUsers}
            onChange={(selected: boolean): void => setSelectedTracked(selected)}
          />
          <Button loading={isEditing} onClick={handleEditUser}>Save</Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default connect(
  (state: any) => ({
    actingUser: {
      id: state.app.id,
      permission: state.app.permission,
      isSuperAdmin: state.app.isSuperAdmin,
    } as User,
    maxTrackedSeats: state.organization.maxTrackedSeats,
    numOfTrackedUsers: getNumOfTrackedUsers(state),
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(EditUserModalBase);
