import React, { FC, ReactElement, useState } from 'react';
import {
  Accordion, AccordionContent, AccordionTitle, Grid, GridColumn, GridRow, Header, HeaderSubheader, Icon, Popup, Segment,
} from 'semantic-ui-react';

import { Indicator as AccountHealthIndicator } from 'models/account/health/indicator';
import { langMetric } from 'models/account/metric';
import { MetricLanguageKit } from 'models/account/MetricLanguageKit';

import css from '../styles.module.css';

const PopupContent: FC<{
  indicator: AccountHealthIndicator;
  metricLanguageKit: MetricLanguageKit;
}> = ({ indicator, metricLanguageKit }) => {
  const {
    status: indicatorStatus,
    value: currentValue,
    meanWon,
    warningThreshold,
    criticalThreshold,
    isPositive,
  } = indicator;
  const { name: metricTitle, description: metricDescription } = metricLanguageKit;

  const thresholdSection = isPositive ? (
    <>
      <GridRow className={css.healthy}>
        <GridColumn>
          Threshold
        </GridColumn>
        <GridColumn className={css.metricValue}>
          {metricLanguageKit.getDisplayValue(criticalThreshold)}
        </GridColumn>
      </GridRow>
    </>
  ) : (
    <>
      <GridRow className={css.warning}>
        <GridColumn>
          Warning Threshold
        </GridColumn>
        <GridColumn className={css.metricValue}>
          {metricLanguageKit.getDisplayValue(warningThreshold)}
        </GridColumn>
      </GridRow>
      <GridRow className={css.critical}>
        <GridColumn>
          Critical Threshold
        </GridColumn>
        <GridColumn className={css.metricValue}>
          {metricLanguageKit.getDisplayValue(criticalThreshold)}
        </GridColumn>
      </GridRow>
    </>
  );

  return (
    <Grid columns="equal" className={`${css.MetricGrid} ${css.MetricPopup}`}>
      <GridRow>
        <GridColumn>
          <Header>
            {metricTitle}
          </Header>
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn>
          <HeaderSubheader>
            {metricDescription}
          </HeaderSubheader>
        </GridColumn>
      </GridRow>
      <GridRow className={`${css[indicatorStatus]} ${css.CurrentValue}`}>
        <GridColumn>
          Current Value
        </GridColumn>
        <GridColumn className={css.metricValue}>
          {metricLanguageKit.getDisplayValue(currentValue)}
        </GridColumn>
      </GridRow>
      <GridRow className={css.healthy}>
        <GridColumn>
          Avg Won Value
        </GridColumn>
        <GridColumn className={css.metricValue}>
          {metricLanguageKit.getDisplayValue(meanWon)}
        </GridColumn>
      </GridRow>
      {thresholdSection}
    </Grid>
  );
};

interface HealthIndicatorRowProps {
  indicator: AccountHealthIndicator;
}

const HealthIndicatorRow = ({ indicator }: HealthIndicatorRowProps): ReactElement => {
  const {
    metricName,
    status: indicatorStatus,
    importanceRank,
  } = indicator;
  let { value: currentValue } = indicator;

  if (!currentValue) currentValue = 0;

  const metricTextPack = langMetric[metricName];

  const { name: metricTitle } = metricTextPack;

  return (
    <Popup
      trigger={(
        <GridRow key={metricName} className={`${css.healthIndicatorRow} ${css[indicatorStatus]}`}>
          <GridColumn width="1" className={css.metricRank}>
            {importanceRank}
          </GridColumn>
          <GridColumn width="9" className={css.metricTitle}>
            {metricTitle}
          </GridColumn>
          <GridColumn width="3" className={css.metricValue}>
            {metricTextPack.getValue(currentValue)}
          </GridColumn>
          <GridColumn width="3" className={css.metricUnit}>
            {metricTextPack.getUnitFromValue(currentValue)}
          </GridColumn>
        </GridRow>
      )}
      position="right center"
      style={{ maxWidth: 350 }}
    >
      <PopupContent indicator={indicator} metricLanguageKit={metricTextPack} />
    </Popup>
  );
};

export const AccountHealthMetrics: React.FC<{
  isLoading: boolean;
  notableIndicators: AccountHealthIndicator[];
  notNotableIndicators: AccountHealthIndicator[];
}> = ({ isLoading, notableIndicators, notNotableIndicators }) => {
  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = (): void => setShowAll(value => !value);

  if (isLoading) {
    return <Segment>Loading...</Segment>;
  }

  const notableIndicatorComponents = notableIndicators.map(
    (indicator: AccountHealthIndicator) => <HealthIndicatorRow indicator={indicator} key={indicator.metricName} />,
  );

  const notNotableIndicatorComponents = notNotableIndicators.map(
    indicator => <HealthIndicatorRow indicator={indicator} key={indicator.metricName} />,
  );

  return (
    <Segment>
      <Popup
        basic
        inverted
        content="These metrics have been determined by our AI to be significant indicators of deal success.
          Metrics are listed in their order of importance according to our AI."
        trigger={(
          <Header>
            {'Highlighted Metrics '}
            <Icon name="question circle outline" className={css.icon} />
          </Header>
        )}
      />
      <Grid columns="equal" className={css.MetricGrid}>
        {notableIndicatorComponents}
      </Grid>
      <Accordion>
        <AccordionTitle
          style={{ display: showAll ? 'block' : 'none' }}
          as={Header}
          className={css.AdditionalMetricsTitle}
        >
          Additional Metrics
        </AccordionTitle>
        <AccordionContent active={showAll}>
          <Grid columns="equal" className={css.MetricGrid}>
            {notNotableIndicatorComponents}
          </Grid>
        </AccordionContent>
      </Accordion>
      <div onClick={toggleShowAll} className={css.ShowAllMetricsButton}>
        {!showAll
          ? 'Show all metrics'
          : 'Hide additional metrics'}
      </div>
    </Segment>
  );
};
