import React, { useState, useEffect } from 'react';
import { Icon, Label } from 'semantic-ui-react';
import OpportunityTag from 'components/Opportunity';

import { Opportunity } from 'models/opportunity';
import css from './OpportunitiesSection.module.css';

interface OpportunitiesSectionProps {
  onUpdateOpportunityStatus: (opportunity: Opportunity, opts: any) => void;
  opportunities: Array<Opportunity>;
}

const OpportunitiesSection: React.FC<OpportunitiesSectionProps> = ({ opportunities, onUpdateOpportunityStatus }) => {
  const [expanded, setExpanded] = useState(false);
  const [isUpdatingOpportunity, setIsUpdatingOpportunity] = useState<Opportunity | null>(null);
  const [activeOpportunities, setActiveOpportunities] = useState<Opportunity[]>([]);
  const [inactiveOpportunities, setInactiveOpportunities] = useState<Opportunity[]>([]);

  useEffect(() => {
    const activeOps = opportunities.filter((opportunity: Opportunity) => opportunity?.active);
    const inactiveOps = opportunities.filter((opportunity: Opportunity) => !opportunity?.active);
    setActiveOpportunities(activeOps);
    setInactiveOpportunities(inactiveOps);
  }, [opportunities]);

  const expandInactive = (event: React.SyntheticEvent, expand: boolean): void => {
    event.stopPropagation();
    setExpanded(expand);
  };

  const switchOpportunityState = async (opportunity: Opportunity, activate: boolean): Promise<void> => {
    const opts = { active: activate };
    setIsUpdatingOpportunity(opportunity);
    onUpdateOpportunityStatus(opportunity, opts);
  };

  const hasSomeInactive = opportunities.some(opportunity => !opportunity.active);

  const renderOpportunityTags = (active: boolean): React.ReactNode => {
    const renderedActive = activeOpportunities.length > 0
      ? activeOpportunities.map(opportunity => (
        <OpportunityTag
          id={opportunity.opportunityId}
          active
          key={opportunity.opportunityId}
          isUpdatingOpportunity={isUpdatingOpportunity === opportunity}
          name={opportunity.name}
          onClick={(): Promise<void> => switchOpportunityState(opportunity, false)}
          extId={opportunity.extId}
          extSource={opportunity.extSource}
          stageName={opportunity.stageName}
        />
      )) : 'No associated opportunities ';

    const renderedInactive = inactiveOpportunities.map(opportunity => (
      <OpportunityTag
        id={opportunity.opportunityId}
        active={false}
        key={opportunity.opportunityId}
        isUpdatingOpportunity={isUpdatingOpportunity === opportunity}
        name={opportunity.name}
        onClick={(): Promise<void> => switchOpportunityState(opportunity, true)}
        extId={opportunity.extId}
        extSource={opportunity.extSource}
        stageName={opportunity.stageName}
      />
    ));

    const body = active ? renderedActive : renderedInactive;
    return body;
  };

  const renderIcon = (expand: boolean): React.ReactNode => {
    const body = expand ? (
      <Label
        key="add button"
        as="a"
        basic
        onClick={(event): void => expandInactive(event, true)}
        className={css.extraButtonLabel}
      >
        <Icon
          className={css.toggleExpandIcon}
          name="plus"
          title="See more"
        />
      </Label>
    ) : (
      <Label
        key="add button"
        as="a"
        basic
        onClick={(event): void => expandInactive(event, false)}
        className={css.extraButtonLabel}
      >
        <Icon
          className={css.toggleExpandIcon}
          name="minus"
          title="See less"

        />
      </Label>
    );
    return body;
  };

  return (
    <div>
      {renderOpportunityTags(true)}
      {hasSomeInactive && !expanded && renderIcon(true)}
      {expanded && renderOpportunityTags(false)}
      {hasSomeInactive && expanded && renderIcon(false)}
    </div>
  );
};

export default OpportunitiesSection;
