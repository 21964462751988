import { useEffect } from 'react';

function useClickAwayListener(ref: any, handler: (event: MouseEvent) => void): void {
  useEffect(() => {
    const listener = (event: MouseEvent): void => {
      if (!ref.current || (ref.current && ref.current === event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('click', listener);
    return (): void => {
      document.removeEventListener('click', listener);
    };
  }, [ref, handler]);
}

export default useClickAwayListener;
