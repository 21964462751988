import { createSelector } from 'reselect';

import { Recommendation } from 'models/recommendation';

const recommendationListSelector = (state): number => state.recommendation.recommendationList;

export const getResolvedNotifications = createSelector<any, any, any>(
  [recommendationListSelector],
  (recommendationList: Array<Recommendation>) => recommendationList.filter(r => r.resolvedAt),
);

export const getUnresolvedNotifications = createSelector<any, any, any>(
  [recommendationListSelector],
  (recommendationList: Array<Recommendation>) => recommendationList.filter(r => !r.resolvedAt),
);
