import { Classification } from 'components/TeamComparisonChart/TeamComparisonChart';
import { useTeamComparisonChartContext } from 'components/TeamComparisonChart/TeamComparisonChartContext';
import React, { FC } from 'react';
import css from './Legend.module.css';

const LegendItem: FC<Classification> = ({ name, color }) => (
  <article className={css.item}>
    <div style={{ backgroundColor: color }} className={css.icon} />
    {name}
  </article>
);

export const TeamComparisonChartLegend: FC = () => {
  const { legend } = useTeamComparisonChartContext();
  return (
    <section className={css.legend}>
      {legend.map(({ name, color }) => <LegendItem key={name} name={name} color={color} />)}
    </section>
  );
};
