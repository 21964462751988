import { useTeamComparisonBodyChartContext } from 'components/TeamComparisonChart/Body/BodyContext';
import { SortColumn, SortSettings } from 'components/TeamComparisonChart/TeamComparisonChart';
import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';
import css from '../Body.module.css';

type OnClickFn = () => void;

const createOnClickFn = (
  columnCategory: SortColumn,
  currentSortSettings: SortSettings,
  setSortSettings: (value: SortSettings) => void,
): OnClickFn => (): void => {
  const { direction: sortDirection } = currentSortSettings;
  const newDirection = sortDirection === 'ascending' ? 'descending' : 'ascending';
  setSortSettings({ column: columnCategory, direction: newDirection });
};

interface SortIconProps {
  sortable: boolean;
  columnCategory: SortColumn;
  activeSortSettings: SortSettings;
}

const SortIcon: FC<SortIconProps> = ({ sortable, columnCategory, activeSortSettings }) => {
  if (!sortable) return <></>;

  const { column, direction } = activeSortSettings;
  if (column !== columnCategory) return <></>;

  return direction === 'descending' ? <Icon name="chevron down" /> : <Icon name="chevron up" />;
};

/**
 * @property placeHolder {boolean}
 * If one column in the chart has a header, then all need headers to make sure spacing of the rows stays consistent.
 * This prop allows you to add the column header to ensure spacing, but no title or sort icon will be visible.
 * No sorting will happen when the header is clicked.
 * In other words, the header will be a placeholder!
 */
interface ColumnHeaderProps {
  columnCategory: SortColumn;
  title?: string;
  placeHolder?: boolean;
}

export const ColumnHeader: FC<ColumnHeaderProps> = ({
  columnCategory,
  title = '',
  placeHolder = false,
}) => {
  const {
    columnHeaders: { sortable },
    sorting: { sortSettings, setSortSettings },
  } = useTeamComparisonBodyChartContext();
  if (placeHolder) return <header className={css.disabled} />;

  const onClickFn = sortable ? createOnClickFn(columnCategory, sortSettings, setSortSettings) : undefined;
  return (
    <header onClick={onClickFn}>
      {title}
      <SortIcon sortable={sortable} columnCategory={columnCategory} activeSortSettings={sortSettings} />
    </header>
  );
};
