import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Grid, GridColumn, GridRow } from 'semantic-ui-react';

import { selectAppDateSelection } from 'features/App/appSlice';
import { getSelectedTeamId } from 'selectors/team';
import { getIsoDatesFromTimePeriod } from 'features/DatePicker/datePickerFunctions';
import { selectUserInfoId } from 'selectors/user';
import { RootState } from 'store';

import { ActivityWidget } from './ActivityWidget/ActivityWidget';
import { Notifications } from './Notifications/Notifications';
import { FunnelWidget } from './FunnelWidget/FunnelWidget';
import { GoalTracking } from './GoalTracking/GoalTracking';

import ProspectingBasicStats from './BasicStats/ProspectingBasicStats';

export function ProspectingScorecard(): ReactElement {
  const timePeriod = useSelector(selectAppDateSelection);
  const { start: startDateIso, end: endDateIso } = getIsoDatesFromTimePeriod(timePeriod);

  const userId = useSelector(selectUserInfoId) || 0;
  const teamId = useSelector(getSelectedTeamId) || 0;
  const isLoadingTeamSettings = useSelector((state: RootState) => state.team.isLoadingTeamSettings);

  if (!userId) return <>Unable to get userID</>;
  if (!teamId) return <>Unable to get teamID</>;

  return (
    <Grid>
      <GridRow stretched>
        <GridColumn width={12}>
          <GoalTracking userId={userId} teamId={teamId} />
        </GridColumn>
        <GridColumn width={4}>
          <Notifications
            userId={userId}
            teamId={teamId}
            startDateIso={startDateIso}
            endDateIso={endDateIso}
          />
        </GridColumn>
      </GridRow>
      <GridRow columns={1}>
        <GridColumn>
          <ProspectingBasicStats userId={userId} teamId={teamId} startDateIso={startDateIso} endDateIso={endDateIso} />
        </GridColumn>
      </GridRow>
      <GridRow columns={2} stretched>
        <GridColumn width={10}>
          <FunnelWidget
            userId={userId}
            teamId={teamId}
            startDateIso={startDateIso}
            endDateIso={endDateIso}
          />
        </GridColumn>
        <GridColumn width={6}>
          <ActivityWidget
            userId={userId}
            teamId={teamId}
            startDateIso={startDateIso}
            endDateIso={endDateIso}
            isLoading={isLoadingTeamSettings}
          />
        </GridColumn>
      </GridRow>
    </Grid>
  );
}

export default ProspectingScorecard;
