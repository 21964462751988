import { AccountApi } from 'features/Api';
import React, { createRef, FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { match } from 'react-router-dom';
import { Grid, GridColumn, GridRow, Ref, Sticky } from 'semantic-ui-react';

import { getSelectedTeamId } from 'selectors/team';

import css from './styles.module.css';
import { AccountHealthHeader } from './components/AccountHealthHeader';
import { AccountHealthMetrics } from './components/AccountHealthMetrics';
import { AccountHealthTimeline } from './components/AccountHealthTimeline';
import { Indicator as AccountHealthIndicator } from '../../../../models/account/health/indicator';

export const AccountHealthPage: FC<{ match: match<{ accountId: string }>}> = props => {
  const { match: { params: { accountId: accountIdStr } } } = props;
  const teamId: number = useSelector(getSelectedTeamId) || 0;
  const accountId = parseInt(accountIdStr, 10);
  const [opportunityId, setOpportunityId] = useState<number>(-1);
  // using -1 to indicate no opp, because undefined didn't work with the dropdown
  const contextRef = createRef();

  const {
    data: accountData,
    isFetching: accountIsFetching,
  } = AccountApi.getAccount.useGetAccountHealthQuery({ accountId, teamId });

  let [notableIndicators, notNotableIndicators]: [AccountHealthIndicator[], AccountHealthIndicator[]] = [[], []];
  let accountIsLoading = true;
  let showAccountMetrics = false;
  let accountMetricsComponent = <></>;
  if (accountData && !accountIsFetching) {
    accountIsLoading = false;
    const { account: { opportunities } } = accountData;
    let opportunity = opportunities.find(({ id }) => id === opportunityId);
    if (!opportunity && opportunities[0]) opportunity = opportunities[0];

    if (opportunity) {
      if (opportunity.id !== opportunityId) setOpportunityId(opportunity?.id);

      const { metrics } = opportunity;
      if (metrics.length > 0) {
        showAccountMetrics = true;

        [notableIndicators, notNotableIndicators] = metrics.reduce(
          (result, indicator) => {
            result[indicator.isImportant ? 0 : 1].push(indicator);
            return result;
          },
          [[], []] as [AccountHealthIndicator[], AccountHealthIndicator[]],
        );
        accountMetricsComponent = (
          <GridColumn width={6}>
            <AccountHealthMetrics
              isLoading={accountIsLoading}
              notableIndicators={notableIndicators}
              notNotableIndicators={notNotableIndicators}
            />
          </GridColumn>
        );
      }
    }
  }

  return (
    <Grid className={css.AccountHealthPage}>
      <GridRow>
        <GridColumn width={16}>
          <AccountHealthHeader
            accountId={accountId}
            selectedOpportunityId={opportunityId}
            setSelectedOpportunityId={setOpportunityId}
          />
        </GridColumn>
      </GridRow>
      <GridRow>
        <Ref innerRef={contextRef}>
          {accountMetricsComponent}
        </Ref>
        <GridColumn width={showAccountMetrics ? 10 : 16}>
          <Sticky context={showAccountMetrics ? contextRef : null}>
            <AccountHealthTimeline accountId={accountId} opportunityId={opportunityId} />
          </Sticky>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
