import React from 'react';
import {
  Button,
  ButtonProps,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Form,
  Icon,
  Label,
  Modal,
  Popup,
} from 'semantic-ui-react';

interface ChartFilter {
  ownerIds?: number[];
  stageNames?: string[];
  opportunityTypes?: string[];
  lookback?: number;
  [key: string]: any;
}

interface CustomFilter {
  field: string;
  label: string;
  values: string[];
}

interface Props {
  customFilters?: Array<CustomFilter>;
  filters?: ChartFilter;
  opportunityStageOptions?: DropdownItemProps[];
  opportunityTypeOptions?: DropdownItemProps[];
  ownerOptions?: DropdownItemProps[];
  lookbackOptions?: DropdownItemProps[];
  onChange?: (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => void;
}

const PipelineFilterModal: React.FC<Props> = ({
  customFilters,
  filters,
  opportunityStageOptions,
  opportunityTypeOptions,
  ownerOptions,
  lookbackOptions,
  onChange,
}) => {
  const [open, setOpen] = React.useState(false);
  const [lookback, setLookback] = React.useState(filters?.lookback);
  const [owners, setOwners] = React.useState(filters?.ownerIds || []);
  const [stages, setStages] = React.useState(filters?.stageNames || []);
  const [types, setTypes] = React.useState(filters?.opportunityTypes || []);
  const [custom, setCustom] = React.useState(filters?.custom || {});

  React.useEffect(() => {
    if (filters) {
      setLookback(filters.lookback);
      setOwners(filters.ownerIds || []);
      setStages(filters.stageNames || []);
      setTypes(filters.opportunityTypes || []);
      setCustom(filters.custom || {});
    }
  }, [filters]);

  const customFilterDropdowns = customFilters?.map(f => {
    const options: DropdownItemProps[] = f.values.map((v): DropdownItemProps => ({ key: v, text: v, value: v }));

    const handleCustomChange = (_, data): void => {
      if (data.value?.length) {
        setCustom({
          ...custom,
          [f.field]: data.value,
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [f.field]: throwAway, ...newCustom } = custom;
        setCustom(newCustom);
      }
    };
    return (
      <ModalFormField
        key={f.field}
        title={f.label}
        options={options}
        placeholder={`Select ${f.label}`}
        handleChange={handleCustomChange}
        multiple
        value={custom[f.field] || []}
      />
    );
  });

  const handleChange = stateFn => (_, data): void => {
    stateFn(data.value);
  };
  const handleSave = (event): void => {
    setOpen(false);
    const data = { lookback, owners, stages, types, custom };
    if (onChange) onChange(event, data);
  };
  const handleClear = (event): void => {
    setLookback(undefined);
    setOwners([]);
    setStages([]);
    setTypes([]);
    setCustom({});
    setOpen(false);
    if (onChange) onChange(event, {});
  };

  let activeFilters = 0;
  if (owners.length) activeFilters += 1;
  if (stages.length) activeFilters += 1;
  if (types.length) activeFilters += 1;
  Object.values(custom).forEach((v: any): void => {
    if (v?.length) activeFilters += 1;
  });

  const indicator = activeFilters ? (
    <Label
      floating
      circular
      color="pink"
      style={{ fontSize: '.3rem' }}
    />
  ) : null;

  return (
    <Modal
      onClose={(): void => setOpen(false)}
      onOpen={(): void => setOpen(true)}
      open={open}
      closeIcon
      trigger={(
        <div className="link brand" style={{ position: 'relative' }}>
          <Popup
            inverted
            basic
            size="mini"
            position="bottom center"
            style={{ backgroundColor: '#5A5B5D' }}
            content="Filters"
            trigger={(<Icon name="filter" />)}
          />
          {indicator}
        </div>
      )}
      size="small"
    >
      <Modal.Header>
        <h1>Filters</h1>
      </Modal.Header>
      <Modal.Content>
        <Form>
          <ModalFormField
            title="Time Period"
            options={lookbackOptions}
            placeholder="Select time period"
            handleChange={handleChange(setLookback)}
            value={lookback}
          />
          <ModalFormField
            title="Opportunity Type"
            options={opportunityTypeOptions}
            placeholder="Select type"
            handleChange={handleChange(setTypes)}
            multiple
            value={types}
          />
          <ModalFormField
            title="Opportunity Stage"
            options={opportunityStageOptions}
            placeholder="Select stage"
            handleChange={handleChange(setStages)}
            multiple
            value={stages}
          />
          <ModalFormField
            title="Opportunity Owner"
            options={ownerOptions}
            placeholder="Select owner"
            handleChange={handleChange(setOwners)}
            multiple
            value={owners}
          />
          {customFilterDropdowns}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={handleClear}>Clear All Filters</Button>
        <Button color="grey" onClick={(): void => setOpen(false)}>Cancel</Button>
        <Button color="pink" onClick={handleSave}>Save</Button>
      </Modal.Actions>
    </Modal>
  );
};

interface FormFieldProps extends DropdownProps {
  handleChange: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  title: string;
  options?: DropdownItemProps[];
}
/* eslint-disable react/jsx-props-no-spreading */
const ModalFormField: React.FC<FormFieldProps> = ({ handleChange, title, options, ...dropdownProps }) => {
  if (!options) return null;
  return (
    <Form.Field>
      <div>{title}</div>
      <Dropdown
        onChange={handleChange}
        fluid
        selection
        options={options}
        {...dropdownProps}
      />
    </Form.Field>
  );
};
/* esline-enable */

export default PipelineFilterModal;
