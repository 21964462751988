import { handleActions } from 'redux-actions';
import actions from 'actions';
import _ from 'lodash';
import { Notification, AlertRule } from 'models/alert';

interface AlertState {
  currentRule: AlertRule | null;
  isLoadingNotifications: boolean;
  notificationError: string;
  notifications: Array<Notification>;
  isLoadingRule: boolean;
  isLoadingRules: boolean;
  sidebarNotifications: Array<Notification>;
  isLoadingSidebarNotifications: boolean;
  sidebarNotificationError: string;
  rules: Array<AlertRule>;
}

const initialState: AlertState = {
  currentRule: null,
  isLoadingNotifications: false,
  notificationError: '',
  notifications: [],
  isLoadingRule: false,
  isLoadingRules: false,
  sidebarNotifications: [],
  isLoadingSidebarNotifications: false,
  sidebarNotificationError: '',
  rules: [],
  ruleErrors: [],
};

const reducer = handleActions(
  {
    [actions.alert.clearAlertRule]: state => ({
      ...state,
      currentRule: null,
      isLoadingRule: false,
    }),
    [actions.alert.updateAlertNotificationSuccess]: (state, { payload: { notification } }) => ({
      ...state,
      notifications: [
        ...state.notifications.filter(n => n.id !== notification.id),
        notification,
      ],
    }),
    [actions.alert.updateAlertNotificationFailure]: (state, { payload: { message } }) => ({
      ...state,
      notificationError: message,
    }),
    [actions.alert.deleteAlertRuleSuccess]: (state, { payload: { id } }) => ({
      ...state,
      rules: [
        ...state.rules.filter(a => a.id !== Number(id)),
      ],
    }),
    [actions.alert.deleteAlertRuleFailure]: (state, { payload: { message } }) => ({
      ...state,
      ruleErrors: message,
    }),
    [actions.alert.pauseAlertRuleSuccess]: (state, { payload: { rule } }) => ({
      ...state,
      rules: [
        ...state.rules.filter(a => a.id !== Number(rule.id)),
        rule,
      ],
    }),
    [actions.alert.pauseAlertRuleFailure]: (state, { payload: { message } }) => ({
      ...state,
      ruleErrors: message,
    }),
    [actions.alert.pauseAlertRuleSuccess]: (state, { payload: { rule } }) => ({
      ...state,
      rules: [
        ...state.rules.filter(a => a.id !== Number(rule.id)),
        rule,
      ],
    }),
    [actions.alert.pauseAlertRuleFailure]: (state, { payload: { message } }) => ({
      ...state,
      ruleErrors: message,
    }),
    [actions.alert.fetchAlertNotificationsRequest]: state => ({ ...state, isLoadingNotifications: true }),
    [actions.alert.fetchAlertNotificationsSuccess]: (state, { payload: { notifications } }) => ({
      ...state,
      notifications,
      isLoadingNotifications: false,
    }),
    [actions.alert.fetchAlertNotificationsFailure]: state => ({ ...state, isLoadingNotifications: false }),
    [actions.alert.newSidebarAlertNotifications]: (state, { payload: { notifications } }) => ({
      ...state,
      sidebarNotifications: notifications,
      isLoadingSidebarNotifications: false,
    }),
    [actions.alert.fetchSidebarAlertNotificationsRequest]: state => ({ ...state, isLoadingSidebarNotifications: true }),
    [actions.alert.fetchSidebarAlertNotificationsSuccess]: (state, { payload: { notifications } }) => ({
      ...state,
      sidebarNotifications: [
        ...state.sidebarNotifications,
        ...notifications,
      ],
      isLoadingSidebarNotifications: false,
    }),
    [actions.alert.fetchSidebarAlertNotificationsFailure]: state => ({
      ...state,
      isLoadingSidebarNotifications: false,
    }),

    [actions.alert.fetchAlertRulesRequest]: state => ({ ...state, isLoadingRules: true }),
    [actions.alert.fetchAlertRulesSuccess]: (state, { payload: { rules } }) => ({
      ...state,
      rules,
      isLoadingRules: false,
    }),
    [actions.alert.fetchAlertRulesFailure]: state => ({ ...state, isLoadingRules: false }),
    [actions.alert.fetchAlertRuleRequest]: state => ({ ...state, isLoadingRule: true }),
    [actions.alert.fetchAlertRuleSuccess]: (state, { payload: { rule } }) => ({
      ...state,
      currentRule: rule,
      isLoadingRule: false,
    }),
    [actions.alert.fetchAlertRuleFailure]: state => ({ ...state, isLoadingRule: false }),
    [actions.alert.saveAlertRuleRequest]: state => ({ ...state, isLoadingRules: true }),
    [actions.alert.saveAlertRuleSuccess]: (state, { payload: { rule } }) => {
      const rules = _.cloneDeep(state.rules);
      const idx = rules.findIndex(r => (r.id === rule.id));
      if (idx === -1) {
        rules.push(rule);
      } else {
        rules.splice(idx, 1, rule);
      }
      return {
        ...state,
        rules,
        isLoadingRules: false,
      };
    },
    [actions.alert.saveAlertRuleFailure]: (state, { payload: { errors } }) => ({
      ...state,
      isLoadingRules: false,
      ruleErrors: errors,
    }),
  },
  initialState,
);

export default reducer;
