import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from 'components/PrivateRoute';

import Recommendation from './Recommendation';
import RecommendationsDash from './RecommendationsDash';
import Admin from './Admin';
import AdminEdit from './Admin/edit';

interface Props {
  isSuperAdmin: boolean;
}

class RecommendationsRoute extends Component<Props, {}> {
  render(): React.ReactNode {
    const { isSuperAdmin } = this.props;
    return (
      <div>
        <Switch>
          <PrivateRoute
            isAuthenticated={isSuperAdmin}
            path="/app/recommendations/admin/edit/:recommendationId"
            component={AdminEdit}
          />
          <PrivateRoute isAuthenticated={isSuperAdmin} path="/app/recommendations/admin/edit" component={AdminEdit} />
          <PrivateRoute isAuthenticated={isSuperAdmin} path="/app/recommendations/admin" component={Admin} />
          <Route path="/app/recommendations/:recommendationId" component={Recommendation} />
          <Route path="/app/recommendations" component={RecommendationsDash} />
        </Switch>
      </div>
    );
  }
}

export default connect(
  (state: any) => ({ isSuperAdmin: state.app.isSuperAdmin }),
)(RecommendationsRoute);
