import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { isUserInATeam as isUserInATeamFn } from 'selectors/team';

interface Props {
  userId: number;
  isUserInATeam: boolean;
}

const UserNotInATeamIndicator: React.FC<Props> = ({ isUserInATeam }) => (
  <>
    {!isUserInATeam

      ? (
        <Popup
          hoverable
          mouseLeaveDelay={500}
          content="User is not a part of any teams. Please add this user to a team on the Manage Team tab."
          trigger={(
            <Icon color="red" name="exclamation circle" />
          )}
        />
      )

      : null}
  </>
);

export default connect(
  (state: any, ownProps: Omit<Props, 'isUserInATeam'>) => ({ isUserInATeam: isUserInATeamFn(ownProps.userId)(state) }),
)(UserNotInATeamIndicator);
