import { createActions } from 'redux-actions';

import api from '../lib/api';
import { handleError } from '.';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const actions = createActions({
  ACCOUNT: {
    ADD_TO_STATE: account => ({ account }),
    INCREMENT_PAGE: pageNum => ({ pageNum }),
    FETCH_ACCOUNT_HEALTH_THRESHOLDS_REQUEST: undefined,
    FETCH_ACCOUNT_HEALTH_THRESHOLDS_SUCCESS: thresholds => ({ thresholds }),
    FETCH_ACCOUNT_HEALTH_THRESHOLDS_FAILURE: undefined,
    FETCH_ACCOUNT_INFO_REQUEST: undefined,
    FETCH_ACCOUNT_INFO_SUCCESS: accountInfo => ({ accountInfo }),
    FETCH_ACCOUNT_INFO_FAILURE: msg => ({ msg }),
    FETCH_ACCOUNTS_REQUEST: undefined,
    FETCH_ACCOUNTS_SUCCESS: accounts => ({ accounts }),
    FETCH_ACCOUNTS_FAILURE: msg => ({ msg }),
    FETCH_ACCOUNT_HEALTH_REQUEST: undefined,
    FETCH_ACCOUNT_HEALTH_SUCCESS: (active, inactive) => ({ active, inactive }),
    FETCH_ACCOUNT_HEALTH_FAILURE: undefined,
    FETCH_IDEAL_TIMELINE_REQUEST: undefined,
    FETCH_IDEAL_TIMELINE_SUCCESS: idealTimeline => ({ idealTimeline }),
    FETCH_IDEAL_TIMELINE_FAILURE: undefined,
    FETCH_ACCOUNT_TIMELINE_REQUEST: undefined,
    FETCH_ACCOUNT_TIMELINE_SUCCESS: data => ({ data }),
    FETCH_ACCOUNT_TIMELINE_FAILURE: undefined,
    FETCH_MORE_INACTIVE_REQUEST: undefined,
    FETCH_MORE_INACTIVE_SUCCESS: inactive => ({ inactive }),
    FETCH_MORE_INACTIVE_FAILURE: undefined,
    SYNC_ACCOUNT_REQUEST: undefined,
    SYNC_ACCOUNT_COMPLETE: undefined,
  },
});
/* eslint-enable @typescript-eslint/explicit-function-return-type */

export const fetchAccountHealthThresholds = (): Function => async (
  dispatch: Function,
  getState: Function,
): Promise<any> => {
  dispatch(actions.account.fetchAccountHealthThresholdsRequest());
  const state = getState();
  const { selectedTeamId } = state.team;
  const opts: any = {};
  if (selectedTeamId) {
    opts.teamId = selectedTeamId;
  }
  const response = await api.getAccountHealthThresholds(opts);
  if (response.status) {
    dispatch(actions.account.fetchAccountHealthThresholdsSuccess(response.thresholds));
  } else {
    handleError(response, dispatch);
    dispatch(actions.account.fetchAccountHealthThresholdsFailure('There was an error fetching account health thresholds'));
  }
  return response;
};

// eslint-disable-next-line max-len
export const fetchAccounts = (startDateIso: string, endDateIso: string): Function => async (dispatch: Function): Promise<any> => {
  dispatch(actions.account.fetchAccountsRequest());
  const response = await api.getAccountsWithStats({ startDateIso, endDateIso });
  if (response.status) {
    dispatch(actions.account.fetchAccountsSuccess(response.accounts));
  } else {
    handleError(response, dispatch);
    dispatch(actions.account.fetchAccountsFailure('There was an error fetching accounts'));
  }
  return response;
};

export const fetchAccountInfo = (accountId): Function => async (dispatch: Function): Promise<any> => {
  dispatch(actions.account.fetchAccountInfoRequest());
  const response = await api.getAccount(accountId);
  if (response.status) {
    dispatch(actions.account.fetchAccountInfoSuccess(response.account));
  } else {
    handleError(response, dispatch);
    dispatch(actions.account.fetchAccountInfoFailure('There was an error fetching account info'));
  }
  return response;
};

export const fetchMoreInactive = (): Function => async (dispatch: Function, getState: Function): Promise<any> => {
  const state = getState();
  const { selectedTeamId } = state.team;
  const { inactivePage } = state.account;
  if (!selectedTeamId) {
    return undefined;
  }
  dispatch(actions.account.fetchMoreInactiveRequest());
  const response = await api.loadMoreInactiveAccounts({ page: inactivePage, teamId: selectedTeamId });
  if (response.status) {
    dispatch(actions.account.fetchMoreInactiveSuccess(response.inactive));
    dispatch(actions.account.incrementPage(response.page));
  } else {
    handleError(response, dispatch);
    dispatch(actions.account.fetchMoreInactiveFailure());
  }
  return response;
};

/* note: not currently used. we're not saving this data to the global state */
export const fetchAccountTimeline = (accountId: number): Function => async (dispatch: Function): Promise<any> => {
  dispatch(actions.account.fetchAccountTimelineRequest());
  const response = await api.getAccountTimeline(accountId);
  if (response.status) {
    dispatch(actions.account.fetchAccountTimelineSuccess(response.data));
  } else {
    handleError(response, dispatch);
    dispatch(actions.account.fetchAccountTimelineFailure());
  }
  return response;
};

const accountActions = {
  account: {
    ...actions.account,
    fetchAccountHealthThresholds,
    fetchAccountInfo,
    fetchAccounts,
    fetchAccountTimeline,
    fetchMoreInactive,
  },
};

export default accountActions;
