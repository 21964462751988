import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Dropdown, DropdownProps, DropdownItemProps, Loader } from 'semantic-ui-react';

import { User } from 'models/user';
import teamGoalAttainmentApi, { GoalAttainment, HistoricalGoalQueryOptions, TeamGoalData } from 'features/Api/Goals';
import { MappedDataSet } from 'features/Api/Activity';
import { getTrackedSelectedTeamMembers } from 'selectors/team';

import MetricOverTimeChart from '../MetricOverTimeChart';

const generateLookbackOptions = (label: string, stepValue = 4, numberOfOptions = 3): DropdownItemProps[] => {
  const items: DropdownItemProps[] = [];
  for (let i = 1; i <= numberOfOptions; i += 1) {
    const value = i * stepValue;
    items.push({
      key: value,
      text: `${value} ${label}s`,
      value,
    });
  }
  return items;
};

const GoalChartHistoric: FC<{
  goalId: number;
  periodIndex: number;
  teamId: number;
  userId?: number;
}> = ({ goalId, periodIndex = 0, teamId, userId }) => {
  const teamMembers = useSelector(getTrackedSelectedTeamMembers);
  const [lookback, setLookback] = useState<number>(8);
  const [chart, setChart] = useState<React.ReactElement>(<>Loading...</>);
  const [getGoalOverTimeTrigger, goalResult] = teamGoalAttainmentApi.endpoints.getGoalOverTime.useLazyQuery();

  const onChangeLookback = (_, selectedItem: DropdownProps): void => {
    const { value } = selectedItem;
    if (typeof value !== 'number') return;
    setLookback(value);
  };

  useEffect(() => {
    const opts: HistoricalGoalQueryOptions = { periodIndex, lookback };
    if (userId) {
      opts.userId = userId;
    }
    getGoalOverTimeTrigger({ goalId, teamId, opts });
  }, [teamId, periodIndex, lookback]);

  const { isLoading, isFetching, data: responseData } = goalResult;
  let lookbackDefaultOptions: DropdownItemProps[] = [];
  if (responseData) {
    const { goal } = responseData;

    const { timePeriod: lookbackLabel } = goal;
    const stepValue = lookbackLabel === 'day' ? 7 : 4;
    const numberOfOptions = lookbackLabel === 'day' ? 5 : 3;

    lookbackDefaultOptions = generateLookbackOptions(
      lookbackLabel,
      stepValue,
      numberOfOptions,
    );
  }

  useEffect(() => {
    if (!isLoading && teamMembers && responseData?.goalResponses) {
      const { goalResponses } = responseData;

      const data: MappedDataSet = {};
      // // initialize the mapped dataset
      teamMembers.forEach((user: User) => {
        const { id, name } = user;
        if (userId && userId !== id) return;
        if (id) {
          data[id] = {
            label: name,
            data: [],
          };
        }
      });
      data.target = {
        label: 'Target',
        data: [],
        borderColor: '#777777',
        backgroundColor: '#777777',
        borderDash: [6, 6],
        stepped: true,
        pointRadius: 0,
      };
      // convert goal responses to a mapped dataset
      let groupBy;
      goalResponses.forEach((response: TeamGoalData): void => {
        const { goal, goalAttainments, period } = response;
        const { target, timePeriod } = goal;
        const { start } = period;
        const xValue = new Date(start).getTime();
        if (data.target) data.target.data.push({ x: xValue, y: target });
        Object.values(goalAttainments).forEach((attainment: GoalAttainment): void => {
          const { userId: key, value } = attainment;
          data[key].data.push({ x: xValue, y: value });
        });
        groupBy = timePeriod;
      });

      setChart(<MetricOverTimeChart data={data} groupBy={groupBy} />);
    }
  }, [isFetching, responseData]);

  return (
    <>
      <Grid>
        <Loader active />
        <Grid.Row textAlign="right">
          <Grid.Column>
            <Dropdown
              placeholder="Time Period"
              options={lookbackDefaultOptions}
              onChange={onChangeLookback}
              defaultValue={lookback}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {chart}
        </Grid.Row>
      </Grid>
    </>
  );
};

export default GoalChartHistoric;
