import React from 'react';
import {
  Button,
  Popup,
} from 'semantic-ui-react';

type GroupBy = 'stageName' | 'ownerId';

interface Props {
  groupBy?: GroupBy;
  onClick: (groupBBy: GroupBy) => void;
}

const StageRepSelect: React.FC<Props> = ({
  groupBy,
  onClick,
}) => {
  const handleClick = (group: GroupBy) => (event): void => {
    event.preventDefault();
    onClick(group);
  };
  return (
    <div style={{ margin: '0 10px 0 10px' }}>
      <Popup
        content="View by Stage"
        inverted
        basic
        size="mini"
        position="bottom center"
        style={{ backgroundColor: '#5A5B5D' }}
        trigger={(
          <Button
            active={groupBy === 'stageName'}
            attached="left"
            size="mini"
            onClick={handleClick('stageName')}
            className="brand"
          >
            Stage
          </Button>
        )}
      />
      <Popup
        inverted
        basic
        size="mini"
        content="View by Rep"
        position="bottom center"
        style={{ backgroundColor: '#5A5B5D' }}
        trigger={(
          <Button
            active={groupBy === 'ownerId'}
            attached="right"
            size="mini"
            onClick={handleClick('ownerId')}
            className="brand"
          >
            Rep
          </Button>
        )}
      />
    </div>
  );
};

export default StageRepSelect;
