import { ApiResponse } from '../apiTypes';
import baseApi from '../apiSlice';
import { NotableActivityType } from '../../../models/account/timeline/notableActivity';

export interface Query {
  accountId: number;
  opportunityId?: number;
}

export interface ActivityBreakdown {
  total: number;
  email: {
    all: number;
    inbound: number;
    outbound: number;
  };
  answeredCall: number;
  meeting: number;
}

export interface ActivitiesByWeekDataPoint {
  x: number;
  y: number;
  date: string;
  weekStart: string;
  weekEnd: string;
  activityBreakdown: ActivityBreakdown;
}

export interface TimelineDataPoint {
  stageName: string;
  masterLabel: string;
  sortOrder: number;
  daysSinceTimelineStart?: number;
  daysInStage?: number;
  dateStart?: string;
  totalActivities: number;
  activitiesPerWeek: number;
  totalEmails: number;
  emailsPerWeek: number;
  totalAnsweredCalls: number;
  answeredCallsPerWeek: number;
  totalMeetings: number;
  meetingsPerWeek: number;
}

export interface NotableActivity {
  activityId: number;
  type: NotableActivityType;
  date: string;
  x: number;
}

export interface Response {
  activitiesByWeek: ActivitiesByWeekDataPoint[];
  accountTimeline: TimelineDataPoint[];
  notableActivities: NotableActivity[];
}

interface RawResponse extends ApiResponse {
  data: Response;
}

const accountApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getAccountTimeline: builder.query<Response, Query>({
      query: ({ accountId, opportunityId }) => {
        const params = opportunityId ? `?opportunityId=${opportunityId}` : '';
        return `account/health/timeline/${accountId}${params}`;
      },
      transformResponse: (response: RawResponse) => response.data,
    }),
  }),
});

export const useGetAccountTimelineQuery = accountApi.endpoints.getAccountTimeline.useQuery;
