import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

import { getIsProspecting } from 'selectors/team';
import { selectAppDateSelection, setAppDateSelection } from 'features/App/appSlice';
import DatePicker from 'features/DatePicker/DatePicker';

import css from './NavHeader.module.css';

interface NavHeaderProps {
  children?: React.ReactNode;
}

export const NavHeader: React.FC<NavHeaderProps> = ({ children }) => {
  const history = useHistory();
  const { location: { pathname } } = history;

  const timePeriod = useSelector(selectAppDateSelection);
  const isProspecting = useSelector(getIsProspecting);

  const navigate = (location: string): void => history.push(location);

  const funnelItem = isProspecting ? (
    <Menu.Item
      active={pathname === '/app/funnel'}
      className={css.brand}
      name="Funnel"
      onClick={(): void => navigate('/app/funnel')}
    />
  ) : undefined;

  return (
    <Menu>
      <Menu.Item
        active={pathname === '/app'}
        className={css.brand}
        name="Rep List"
        onClick={(): void => navigate('/app')}
      />
      <Menu.Item
        active={pathname === '/app/comparison'}
        className={css.brand}
        name="Comparison"
        onClick={(): void => navigate('/app/comparison')}
      />
      {funnelItem}
      <Menu.Menu position="right">
        <Menu.Item>
          <DatePicker reduxAction={setAppDateSelection} timePeriod={timePeriod} />
        </Menu.Item>
        {children}
      </Menu.Menu>
    </Menu>
  );
};

export default NavHeader;
