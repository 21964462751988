interface WithId {
  id: string;
}

export interface Normalized<T> {
  byIds: {
    [tId: string]: T;
  };
  allIds: string[];
}

export function normalize<T extends WithId>(entities: T[]): Normalized<T> {
  const byIds = {};
  const allIds: string[] = [];
  entities.forEach(e => {
    byIds[e.id] = e;
    allIds.push(e.id);
  });
  return {
    byIds,
    allIds,
  };
}
