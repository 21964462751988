import React, { useState, useEffect } from 'react';

import { Dropdown, DropdownProps } from 'semantic-ui-react';
import css from './SyncCronSelector.module.css';

interface SyncCronSelectorProps{
    onChange?: (cron: string) => void;
    initialValue: string | undefined;
}

interface CronValues{
  dayRange: string;
  hourRange: {
    startTime: number;
    endTime: number;
  };
  minutesFrecuency: number;
}

const SyncCronSelector: React.FC<SyncCronSelectorProps> = ({ onChange, initialValue }) => {
  const daysRangeOptions = [
    {
      key: 'weekdays',
      value: '1-5',
      text: 'Weekdays',
    },
    {
      key: 'everyday',
      value: '*',
      text: 'Every day',
    },
  ];

  const getIntialValuesFromString = (cron: string): CronValues => {
    const cronArray = cron.split(' ');
    const dayRange = cronArray[cronArray.length - 1];
    const hourRange = {
      startTime: parseInt(cronArray[1].split('-')[0], 10),
      endTime: parseInt(cronArray[1].split('-')[1], 10),
    };
    const minutesFrecuency = parseInt(cronArray[0].split('/')[1], 10);

    return {
      dayRange,
      hourRange,
      minutesFrecuency,
    };
  };

  const formatedIntialValues: CronValues = initialValue
    ? getIntialValuesFromString(initialValue)
    : {
      dayRange: daysRangeOptions[0].value,
      hourRange: { startTime: 8, endTime: 17 },
      minutesFrecuency: 10,
    };

  const [daysRange, setDaysRange] = useState(formatedIntialValues.dayRange);
  const [hourRange, setHourRange] = useState(formatedIntialValues.hourRange);
  const [minutesFrecuency, setMinutesFrecuency] = useState(formatedIntialValues.minutesFrecuency);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    const buildCronString = (): string => {
      const cronValuesArray = [`0/${minutesFrecuency}`, `${hourRange.startTime}-${hourRange.endTime}`, '*', '*', daysRange];
      return cronValuesArray.join(' ');
    };

    if (onChange && hasChanged) {
      onChange(buildCronString());
    }
  }, [daysRange, hourRange, minutesFrecuency, hasChanged, onChange]);


  const handleDayChange = (_: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void => {
    setDaysRange(data.value as string);
    setHasChanged(true);
  };

  const handleMinutesFrecuency = (_: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void => {
    const selectedMinutesFrecuecny = data.value as number;
    setMinutesFrecuency(selectedMinutesFrecuecny);
    setHasChanged(true);
  };

  const handleHourRangeChange = (type: 'startTime' | 'endTime') => (hour: number): void => {
    const newHourRange = { ...hourRange };
    newHourRange[type] = hour;
    setHourRange(newHourRange);
    setHasChanged(true);
  };

  const minFrecuencyOptions = [
    { key: '10', value: 10, text: '10 min' },
    { key: '15', value: 15, text: '15 min' },
    { key: '30', value: 30, text: '30 min' },
    { key: '60', value: 60, text: '1 hour' },
  ];

  return (
    <div className={css.container}>
      <div className={css.inputElement}>
        <label>Business days</label>
        <Dropdown
          placeholder="Days of Week"
          fluid
          selection
          options={daysRangeOptions}
          value={daysRange}
          onChange={handleDayChange}
        />
      </div>
      <div className={css.inputElement}>
        <label>Start of business</label>
        <div className={css.hourRangeContainer}>
          <HourDropdown
            placeholder="Start Time"
            value={hourRange.startTime}
            onChange={handleHourRangeChange('startTime')}
          />
        </div>
      </div>
      <div className={css.inputElement}>
        <label>End of business</label>
        <HourDropdown
          placeholder="End Time"
          value={hourRange.endTime}
          onChange={handleHourRangeChange('endTime')}
        />
      </div>
      <div className={css.inputElement}>
        <label>Sync frequency</label>
        <Dropdown
          placeholder="Frecuency"
          fluid
          selection
          options={minFrecuencyOptions}
          value={minutesFrecuency}
          onChange={handleMinutesFrecuency}
        />
      </div>
    </div>
  );
};

export default SyncCronSelector;

interface HourDropdownProps{
    placeholder: string;
    value: number;
    onChange: (hour: number) => void;
}

const HourDropdown: React.FC<HourDropdownProps> = ({ placeholder, value, onChange }) => {
  const hoursOptions: any = [];

  for (let i = 0; i < 24; i += 1) {
    let label = i.toString().length === 1 ? `0${i.toString()}` : i.toString();
    label += ':00';
    hoursOptions.push({ key: i, value: i, text: label });
  }


  const handleChange = (_: any, data: DropdownProps): void => {
    onChange(data.value as number);
  };

  return (
    <Dropdown
      className={css.hourDropdown}
      placeholder={placeholder}
      fluid
      selection
      options={hoursOptions}
      value={value}
      onChange={handleChange}
    />
  );
};
