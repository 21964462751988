import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { getIsProspecting } from 'selectors/team';

import UserScorecard from './UserScorecard';
import ProspectingScorecard from './ProspectingScorecard';

export function Scorecard(): ReactElement {
  const isProspecting = useSelector(getIsProspecting);

  if (isProspecting) {
    return ProspectingScorecard();
  }

  return UserScorecard();
}

export default Scorecard;
