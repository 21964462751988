import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import allActions, { bindCombinedActions } from '../actions';

import PrivateRoute from '../components/PrivateRoute';

// private pages
import Callback from './Callback';
import Consent from './Consent';
import Main from './Main';

// public pages
import Home from './public/Home';
import TermsOfService from './public/TermsOfService';

import css from './App.module.css';

interface Props {
  actions: any;
  hasLoaded: boolean;
  isLoggedIn: boolean;
  location: any;
}

interface State {
  pathname: string;
}

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // save the location that we initially navigated to
      pathname: `${props.location.pathname}${props.location.search}`,
    };
  }

  componentDidMount(): void {
    const { actions } = this.props;
    const { pathname } = this.state;
    actions.app.checkLoggedIn(pathname);
  }

  render(): React.ReactNode {
    const { hasLoaded, isLoggedIn } = this.props;

    return (
      <div className={css.App}>
        {hasLoaded ? (
          <Switch>
            <PrivateRoute isAuthenticated={isLoggedIn} path="/consent" component={Consent} />
            <PrivateRoute isAuthenticated={isLoggedIn} path="/app" component={Main} />
            <Route path="/cb" component={Callback} />
            <Route path="/tos" component={TermsOfService} />
            <Route path="/" component={Home} />
          </Switch>
        ) : null}
        <ToastContainer
          toastClassName={css.toastContainer}
          bodyClassName={css.toastBody}
          progressClassName={css.toastProgress}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state: any) => ({
      hasLoaded: state.app.hasLoaded,
      isLoggedIn: state.app.isLoggedIn,
    }),
    dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
  )(App),
);
