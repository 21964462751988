import { useTeamComparisonBodyChartContext } from 'components/TeamComparisonChart/Body/BodyContext';
import { ColumnHeader } from 'components/TeamComparisonChart/Body/Columns/ColumnHeader';
import { BenchmarkLine, BenchmarkLineProps } from 'components/TeamComparisonChart/Body/Columns/BarColumn/Benchmark';
import { ActivityBreakdownProgressBar } from 'components/TeamComparisonChart/Body/Columns/BarColumn/BreakdownProgressBar';
import { SimpleProgressBar } from 'components/TeamComparisonChart/Body/Columns/BarColumn/SimpleProgressBar';
import React, { FC, ReactElement } from 'react';
import css from '../../Body.module.css';

interface BarColumnProps {
  title?: string;
  enablePopup?: boolean;
}

export interface BarColumnComposition extends FC<BarColumnProps> {
  BenchmarkLine: FC<BenchmarkLineProps>;
}

const BarColumn: BarColumnComposition = ({
  title = '',
  enablePopup = false,
  children,
}) => {
  const {
    data,
    maxValue,
    columnHeaders: {
      visible: showHeaders,
    },
    size,
  } = useTeamComparisonBodyChartContext();
  const height = size === 'big' ? 20 : undefined;
  return (
    <section className={`${css.column} ${css.charts}`}>
      { showHeaders
        ? <ColumnHeader columnCategory="optimality" title={title} placeHolder={title === ''} />
        : <></> }
      <ol>
        {data.map(
          ({
            userName,
            value: {
              sortValue,
              classification: { color },
            },
            breakdown,
          }): React.ReactNode => {
            let bar: ReactElement;
            if (breakdown) {
              bar = (
                <ActivityBreakdownProgressBar
                  value={sortValue}
                  breakdown={breakdown}
                  max={maxValue.sortValue || 1}
                  enablePopup={enablePopup}
                  height={height}
                />
              );
            } else {
              bar = (
                <SimpleProgressBar
                  value={sortValue}
                  max={maxValue.sortValue || 1}
                  color={color}
                  height={height}
                />
              );
            }
            return (
              <li key={userName}>
                {bar}
              </li>
            );
          },
        )}
      </ol>
      {children}
    </section>
  );
};

BarColumn.BenchmarkLine = BenchmarkLine;

export { BarColumn };
