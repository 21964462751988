import React, { Component } from 'react';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export interface DataSet {
  label?: string;
  data: any;
  backgroundColor: any;
  stack?: string;
  waterfall?: { dummyStack: boolean };
}

interface Props {
  pipelineData: any;
  isLoading: boolean;
  isMinimized: boolean;
  tooltipTitle?: string;
}
interface State {
  datasets: DataSet[];
}

const chartLabels = [
  'Starting Pipeline',
  'Net Delta',
  'Created',
  'Pulled In',
  'Pushed Out',
  'Closed Won',
  'Closed Lost',
  'Remaining',
];

/* eslint-disable max-len */
const descriptions = {
  'Starting Pipeline': 'At the start of the period, opportunities that had close dates in the period.',
  'Net Delta': 'Any change in the value of the opportunities that were in the Starting Pipeline over the period.',
  Created: 'New opportunities created in the period that also had close dates in the period.',
  'Pulled In': 'Opportunities with close dates originally after the period that were changed to be in period ("pulled in" to period).',
  'Pushed Out': 'Opportunities with close dates originally in the period that were changed to be after period ("pushed out" to a future time).',
  'Closed Won': 'Opportunities won in the period.',
  'Closed Lost': 'Opportunities lost in the period.',
  Remaining: 'Pipeline with close dates still in the period after the end of the period (out of date at that time).',
};
/* eslint-enable max-len */

const CHART_WHITE = '#ffffff';
const CHART_GREY = '#5a5a5a';
const CHART_GREEN = '#15AB39';
const CHART_RED = '#e7130c';

const formatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export default class WaterfallChart extends Component<Props, State> {
  canvas;

  chart;

  constructor(props) {
    super(props);
    this.canvas = React.createRef();
    this.state = { datasets: this.convertPipelineDataToDataSets(props.pipelineData) };
  }

  componentDidMount(): void {
    this.renderChart();
  }

  componentDidUpdate(prevProps): void {
    const { pipelineData, isMinimized } = this.props;
    if (prevProps.pipelineData !== pipelineData || prevProps.isMinimized !== isMinimized) {
      ((): void => {
        this.setState({ datasets: this.convertPipelineDataToDataSets(pipelineData) });
      })();
    }
    this.renderChart();
  }

  convertPipelineDataToDataSets = (pipelineData): DataSet[] => {
    const { isMinimized } = this.props;
    const pipelineNumbers = {
      pipelineStart: 0,
      pipelineDelta: 0,
      created: 0,
      pulledIn: 0,
      pushedOut: 0,
      closedWon: 0,
      closedLost: 0,
      openPipeline: 0,
    };
    pipelineData.forEach(userData => {
      pipelineNumbers.pipelineStart += userData.pipelineStartAmount;
      pipelineNumbers.pipelineDelta += userData.existingPipelineDelta;
      pipelineNumbers.created += userData.newOpportunityAmount;
      pipelineNumbers.pulledIn += userData.pulledInAmount;
      pipelineNumbers.pushedOut -= userData.pushedOutAmount;
      pipelineNumbers.closedWon -= userData.wonAmount;
      pipelineNumbers.closedLost -= userData.lostAmount;
      pipelineNumbers.openPipeline += userData.openAmount;
    });
    const colorValues: string[] = [];
    const borderValues: string[] = [];

    const data: Array<number[]> = [];
    let floatDelta = 0;

    Object.keys(pipelineNumbers).forEach((key): void => {
      const value = pipelineNumbers[key];
      if (key === 'pipelineStart' || key === 'openPipeline') {
        colorValues.push(CHART_GREY);
        borderValues.push(CHART_GREY);
        data.push([0, value]);
        floatDelta += value;
      } else {
        if (value > 0) {
          colorValues.push(CHART_WHITE);
          borderValues.push(CHART_GREEN);
        } else {
          colorValues.push(CHART_RED);
          borderValues.push(CHART_RED);
        }
        data.push([floatDelta, floatDelta + value]);
        floatDelta += value;
      }
    });

    const datalabels = isMinimized
      ? { display: false }
      : {
        color: 'white',
        borderRadius: 3,
        backgroundColor: 'rgba(73, 70, 72, 0.596)',
        clip: true,
        anchor: 'end',
        formatter(value): string {
          const v = value[1] - value[0];
          const formatted = formatter.format(Math.abs(v));
          const str = v < 0 ? `(${formatted})` : formatted;
          return str;
        },
        description: 'Hello World',
      };

    return [
      {
        labels: chartLabels,
        data,
        borderColor: borderValues,
        borderSkipped: false,
        borderRadius: 2.5,
        borderWidth: 2,
        backgroundColor: colorValues,
        datalabels,
      },
    ];
  };

  renderChart = (): void => {
    const { datasets } = this.state;
    const { isLoading, isMinimized, tooltipTitle } = this.props;
    Chart.register(...registerables);

    if (this.chart) this.chart.destroy();

    if (isLoading) return;

    const ctx = this.canvas.current.getContext('2d');
    /* eslint-disable object-curly-newline */
    const chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: chartLabels,
        datasets,
        borderRadius: 4,
        active: false,
      },
      plugins: [ChartDataLabels],
      options: {
        interaction: {
          mode: 'point',
        },
        animation: false,
        responsive: true,
        scales: {
          xAxis: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              labelOffset: isMinimized ? 15 : 0,
            },
          },
          yAxis: {
            beginAtZero: true,
            ticks: {
              // Include a dollar sign in the ticks
              callback(label: any): string {
                if (label >= 1000000) {
                  return `$${label / 1000000}M`;
                } if (label >= 1000) {
                  return `$${label / 1000}k`;
                }
                return `$${label}`;
              },
            },
          },
        },
        plugins: {
          title: {
            display: false,
            text: 'Waterfall',
            align: 'start',
            position: 'top',
          },
          legend: {
            display: false,
            position: 'right',
            align: 'center',
            bodySpacing: 3,
          },
          tooltip: {
            bodySpacing: 5,
            mode: 'nearest',
            intersect: true,
            position: 'nearest',
            padding: 7,
            backgroundColor: '#5A5B5D',
            callbacks: {
              title(tooltipItems): string {
                return tooltipTitle || tooltipItems[0].label;
              },
              label(context): string {
                const { raw } = context;
                let { label } = context;
                if (Array.isArray(raw)) {
                  const v = raw[1] - raw[0];
                  const formatted = formatter.format(Math.abs(v));
                  const str = v < 0 ? `(${formatted})` : formatted;
                  label = `${label}: ${str}`;
                }
                return label;
              },
              afterBody(tooltipItems): string {
                const { label } = tooltipItems[0];
                const description = descriptions[label];
                return description;
              },
            },
          },
        },
        elements: { line: { tension: 0 } },
      },
    });
    /* eslint-enable object-curly-newline */
    this.chart = chart;
  };

  render(): React.ReactNode {
    return <canvas ref={this.canvas} style={{ width: '100%' }} />;
  }
}
