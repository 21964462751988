import React from 'react';
import { Dimmer, Loader, Modal, Segment } from 'semantic-ui-react';

import css from './DashboardTile.module.css';

interface DashboardTileProps {
  children: React.ReactNode;
  isLoading?: boolean;
  isMinimized?: boolean;
  onClose?: any;
}

const DashboardTile: React.FC<DashboardTileProps> = ({
  children,
  isLoading,
  isMinimized,
  onClose,
}: DashboardTileProps): React.ReactElement => (
  <div className={css.tile}>
    <Segment className={css.tileSegment}>
      <Dimmer active={isLoading} inverted>
        <Loader />
      </Dimmer>
      {isMinimized ? children : null}
    </Segment>
    <Modal open={!isMinimized} onClose={onClose} size="large">
      <Modal.Content>
        {children}
      </Modal.Content>
    </Modal>
  </div>
);

export default DashboardTile;
