import React, { useState } from 'react';
import { Table, Button, Icon, Loader, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import allActions, { bindCombinedActions } from 'actions';
import { connect } from 'react-redux';
import _remove from 'lodash/remove';
import PermissionsInfo from 'components/PermissionsInfo';

import { permissionToLabel } from '../AccessControl';

import css from './TeamUsersManageList.module.css';

interface TeamUsersManageListProps {
  isLoadingUsers: boolean;
  users: any[];
  teamId: number;
  actions: any;
  handleOpenAddTeamMemberModal: () => void;
}

const TeamUsersManageList: React.FC<TeamUsersManageListProps> = ({
  isLoadingUsers,
  users,
  teamId,
  actions,
  handleOpenAddTeamMemberModal,
}) => {
  const [isRemovingUserId, setIsRemoveUserId] = useState<string[]>([]);
  const handleDeleteUserFromTeam = (userId: string): void => {
    setIsRemoveUserId([...isRemovingUserId, userId]);
    actions.team.removeUserFromTeam(userId, teamId).then(() => {
      const tmpRemovingUsers = [...isRemovingUserId];
      _remove(tmpRemovingUsers, id => id === userId);
      setIsRemoveUserId(tmpRemovingUsers);
    });
  };

  const userNodes = users.map(user => (
    <Table.Row key={user.sfId}>
      <Table.Cell width="4"><Link to={`/app/team/${user.id}`}>{user.name}</Link></Table.Cell>
      <Table.Cell width="4">{user.role}</Table.Cell>
      <Table.Cell width="4">
        {user ? permissionToLabel(user.permission) : null}
        {' '}
        <PermissionsInfo permission={user.permission} />
      </Table.Cell>
      <Table.Cell textAlign="center" width="2">{user.isTracked ? <Icon name="check" /> : null}</Table.Cell>
      <Table.Cell className={css.buttonCell} textAlign="center" width="2">
        <Popup
          position="top center"
          trigger={(
            <Button
              basic
              icon="remove user"
              loading={isRemovingUserId.includes(user.id)}
              onClick={(): void => handleDeleteUserFromTeam(user.id)}
            />
          )}
          content="Remove user from team"
          inverted
        />
      </Table.Cell>
    </Table.Row>
  ));

  return (
    <>
      <Loader active={isLoadingUsers} />
      <Table basic="very" selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Permission Level</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Tracked</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {userNodes}
        </Table.Body>
      </Table>
      <div className={css.actionsBtnContainer}>
        <Button onClick={handleOpenAddTeamMemberModal}>+ Add User</Button>
      </div>
    </>
  );
};

export default connect(
  (state: any) => ({ isLoadingUsers: state.user.isLoadingUsers }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(TeamUsersManageList);
