import { createActions } from 'redux-actions';

import { Team } from 'models/team';
import api from '../lib/api';
import { handleError } from '.';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const actions = createActions({
  TEAM: {
    FETCH_TEAMS_REQUEST: undefined,
    FETCH_TEAMS_SUCCESS: teams => ({ teams }),
    FETCH_TEAMS_FAILURE: msg => ({ msg }),

    FETCH_TEAMS_WITH_MEMBERS_REQUEST: undefined,
    FETCH_TEAMS_WITH_MEMBERS_SUCCESS: teams => ({ teams }),
    FETCH_TEAMS_WITH_MEMBERS_FAILURE: msg => ({ msg }),

    FETCH_USER_TEAMS_REQUEST: undefined,
    FETCH_USER_TEAMS_SUCCESS: teams => ({ teams }),
    FETCH_USER_TEAMS_FAILURE: msg => ({ msg }),

    FETCH_TEAM_SETTINGS_REQUEST: undefined,
    FETCH_TEAM_SETTINGS_SUCCESS: settings => ({ settings }),
    FETCH_TEAM_SETTINGS_FAILURE: msg => ({ msg }),

    ADD_TEAM_MEMBER_REQUEST: undefined,
    ADD_TEAM_MEMBER_SUCCESS: (userIds, teamId) => ({ userIds, teamId }),
    ADD_TEAM_MEMBER_FAILURE: msg => ({ msg }),

    REMOVE_USER_FROM_TEAM_REQUEST: undefined,
    REMOVE_USER_FROM_TEAM_SUCCESS: (userId, teamId) => ({ userId, teamId }),
    REMOVE_USER_FROM_TEAM_FAILURE: msg => ({ msg }),

    SET_SELECTED_TEAM_ID_REQUEST: selectedTeamId => ({ selectedTeamId }),

    EDIT_TEAM_REQUEST: undefined,
    EDIT_TEAM_SUCCESS: (team: Team) => ({ team }),
    EDIT_TEAM_FAILURE: msg => ({ msg }),

    DELETE_TEAM_REQUEST: undefined,
    DELETE_TEAM_SUCCESS: (teamId: string) => ({ teamId }),
    DELETE_TEAM_FAILURE: msg => ({ msg }),
  },
});

/* eslint-enable @typescript-eslint/explicit-function-return-type */
export const fetchTeams = () => async (dispatch: Function): Promise<any> => {
  dispatch(actions.team.fetchTeamsRequest());
  const response = await api.getTeams();
  if (response.status) {
    dispatch(actions.team.fetchTeamsSuccess(response.teams));
  } else {
    handleError(response, dispatch);
    dispatch(actions.team.fetchTeamsFailure('There was an error fetching teams'));
  }
  return response;
};

export const fetchTeamsWithMembers = () => async (dispatch: Function): Promise<any> => {
  dispatch(actions.team.fetchTeamsWithMembersRequest());
  const response = await api.getTeamWithMembers();
  if (response.status) {
    dispatch(actions.team.fetchTeamsWithMembersSuccess(response.teams));
  } else {
    handleError(response, dispatch);
    dispatch(actions.team.fetchTeamsWithMembersFailure('There was an error fetching teams with members'));
  }
  return response;
};

export const fetchUserTeams = () => async (dispatch: Function): Promise<any> => {
  dispatch(actions.team.fetchUserTeamsRequest());
  const response = await api.getUserTeams();
  if (response.status) {
    dispatch(actions.team.fetchUserTeamsSuccess(response.teams));
  } else {
    handleError(response, dispatch);
    dispatch(actions.team.fetchUserTeamsFailure('There was an error fetching user teams'));
  }
  return response;
};

export const fetchTeamSettings = (teamId: number) => async (dispatch: Function): Promise<any> => {
  dispatch(actions.team.fetchTeamSettingsRequest());
  const response = await api.getTeamSettings(teamId);
  if (response.status) {
    dispatch(actions.team.fetchTeamSettingsSuccess(response.settings));
  } else {
    handleError(response, dispatch);
    dispatch(actions.team.fetchTeamSettingsFailure('There was an error fetching team settings'));
  }
  return response;
};

export const addTeamMember = (userIds: string[], teamId) => async (dispatch: Function): Promise<any> => {
  dispatch(actions.team.addTeamMemberRequest());
  const response = await api.addTeamMember(userIds, teamId);
  if (response.status) {
    dispatch(actions.team.addTeamMemberSuccess(userIds, teamId));
  } else {
    handleError(response, dispatch);
    dispatch(actions.team.addTeamMemberFailure('There was an error adding team member'));
  }
};

export const removeUserFromTeam = (userId, teamId) => async (dispatch: Function): Promise<any> => {
  dispatch(actions.team.removeUserFromTeamRequest());
  const response = await api.removeUserFromTeam(userId, teamId);
  if (response.status) {
    dispatch(actions.team.removeUserFromTeamSuccess(userId, teamId));
  } else {
    handleError(response, dispatch);
    dispatch(actions.team.removeUserFromTeamFailure('There was an error remove a team member'));
  }
};

export const setSelectedTeamId = (selectedTeamId: string) => async (dispatch: Function): Promise<any> => {
  dispatch(actions.team.setSelectedTeamIdRequest(selectedTeamId));
};

export const editTeam = (team: Team) => async (dispatch: Function): Promise<any> => {
  dispatch(actions.team.editTeamRequest());
  const response = await api.editTeam(team);
  if (response.status) {
    dispatch(actions.team.editTeamSuccess(team));
  } else {
    handleError(response, dispatch);
    dispatch(actions.team.editTeamFailure('There was an error editing the team'));
  }
};

export const deleteTeam = (teamId: string) => async (dispatch: Function): Promise<any> => {
  dispatch(actions.team.deleteTeamRequest());
  const response = await api.deleteTeam(teamId);
  if (response.status) {
    dispatch(actions.team.deleteTeamSuccess(teamId));
  } else {
    handleError(response, dispatch);
    dispatch(actions.team.deleteTeamFailure('There was an error deleting the team'));
  }
};


const teamActions = {
  team: {
    ...actions.team,
    fetchTeams,
    fetchTeamsWithMembers,
    fetchUserTeams,
    fetchTeamSettings,
    addTeamMember,
    removeUserFromTeam,
    setSelectedTeamId,
    editTeam,
    deleteTeam,
  },
};

export default teamActions;
