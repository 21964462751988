import React, { ReactElement, useEffect, useState } from 'react';
import { Grid, GridColumn, Header, Modal, Icon, Tab } from 'semantic-ui-react';

import { GoalAccount, GoalActivity, GoalContact, GoalOpportunity } from 'models/goal';
import teamGoalAttainmentApi, { GoalAttainment } from 'features/Api/Goals';
import { getGoalHeaderText, round } from 'lib/util';

import GoalChartHistoric from 'App/Main/Dashboard/components/Comparison/components/GoalChartHistoric';

import GoalTrackingAccount from './components/GoalTrackingAccount';
import GoalTrackingActivity from './components/GoalTrackingActivity';
import GoalTrackingContact from './components/GoalTrackingContact';
import GoalTrackingOpportunity from './components/GoalTrackingOpportunity';

import css from './GoalTrackingDetailsModal.module.css';

const GoalDetailsWithNavigation = (props: {
  goalAttainment: GoalAttainment | undefined;
  closeIcon: ReactElement;
  teamId: number;
  userId: number;
}): ReactElement => {
  const { goalAttainment: initialAttainment, closeIcon, teamId, userId } = props;
  const [goalAttainment, setGoalAttainment] = useState(initialAttainment);

  if (typeof goalAttainment === 'undefined') { return <></>; }

  const { period: { index: initialIndex } } = goalAttainment;
  const [periodIndex, setPeriodIndex] = useState(initialIndex);
  const { goalId } = goalAttainment;
  const [getGoalTrigger, goalResult] = teamGoalAttainmentApi
    .endpoints
    .getMemberGoal
    .useLazyQuery();

  useEffect(() => {
    getGoalTrigger({ teamId, userId, goalId, periodIndex });
  }, [periodIndex]);

  useEffect(() => {
    const attainment = goalResult.data?.goalAttainments[userId];
    if (attainment) {
      setGoalAttainment(attainment);
    }
  }, [goalResult]);

  const handlePeriodChange = (num: number) => (): void => {
    const newperiod = Math.max(periodIndex + num, 0);
    setPeriodIndex(newperiod);
  };

  const iconStyle = { cursor: 'pointer' };
  const previousGoalBtn = <Icon style={iconStyle} name="chevron left" onClick={handlePeriodChange(1)} />;
  const nextGoalBtn = periodIndex === 0
    ? undefined
    : <Icon style={iconStyle} name="chevron right" onClick={handlePeriodChange(-1)} />;

  return (
    <GoalDetails
      goalAttainment={goalAttainment}
      closeIcon={closeIcon}
      previousGoalBtn={previousGoalBtn}
      nextGoalBtn={nextGoalBtn}
    />
  );
};

const GoalDetails = (props: {
  goalAttainment: GoalAttainment | undefined;
  closeIcon: ReactElement;
  previousGoalBtn?: ReactElement;
  nextGoalBtn?: ReactElement;
}): ReactElement => {
  const { goalAttainment, previousGoalBtn, nextGoalBtn } = props;
  if (typeof goalAttainment === 'undefined') { return <></>; }

  const {
    target,
    value,
    projectedAttainment,
    goalMet,
    period,
  } = goalAttainment;
  const { index } = period;
  const currentPeriod = index === 0;
  const columns = currentPeriod ? 3 : 2;

  let attainmentStyle = css.boxContentGray;
  if (!currentPeriod) { attainmentStyle = goalMet ? css.boxContentGreen : css.boxContentRed; }

  const { subHeaderText } = getGoalHeaderText(period);

  const subHeader = (
    <Header as="h4" className={css.goalSubHeader}>
      {previousGoalBtn}
      {subHeaderText}
      {nextGoalBtn}
    </Header>
  );

  return (
    <>
      <Grid columns={columns}>
        <Grid.Column>
          <div className={css.boxContentGray}>
            <span className={css.boxContentValue}>{target}</span>
            <span className={css.boxContentField}>Target</span>
          </div>
        </Grid.Column>
        <GridColumn>
          <div className={attainmentStyle}>
            <span className={css.boxContentValue}>{value}</span>
            <span className={css.boxContentField}>Attainment</span>
          </div>
        </GridColumn>
        {currentPeriod && (
        <GridColumn>
          <div className={goalMet ? css.boxContentGreen : css.boxContentRed}>
            <span className={css.boxContentValue}>{round(projectedAttainment)}</span>
            <span className={css.boxContentField}>Projected Attainment</span>
          </div>
        </GridColumn>
        )}
      </Grid>
      <Grid>
        <Grid.Column textAlign="right">
          {subHeader}
        </Grid.Column>
      </Grid>
      <GoalDetailsBreakdown
        goalAttainment={goalAttainment}
      />
    </>
  );
};

const GoalDetailsBreakdown = (props: {
  goalAttainment: GoalAttainment | undefined;
}): ReactElement => {
  const { goalAttainment } = props;
  if (typeof goalAttainment === 'undefined' || !goalAttainment.breakdown?.data) { return <></>; }

  const { breakdown: { data: tableData, type } } = goalAttainment;

  let BreakdownComponent;
  switch (type) {
    case 'activity': BreakdownComponent = <GoalTrackingActivity data={tableData as GoalActivity[]} />; break;
    case 'account': BreakdownComponent = <GoalTrackingAccount data={tableData as GoalAccount[]} />; break;
    case 'contact': BreakdownComponent = <GoalTrackingContact data={tableData as GoalContact[]} />; break;
    case 'opportunity': BreakdownComponent = <GoalTrackingOpportunity data={tableData as GoalOpportunity[]} />; break;
    default: BreakdownComponent = <></>;
  }

  return BreakdownComponent;
};

const GoalTrackingDetailsModal = (props: {
  isOpen: boolean;
  goalAttainment: GoalAttainment | undefined;
  handleGoalDetailsModal: Function;
  userId: number;
  teamId: number;
}): ReactElement => {
  const { goalAttainment, isOpen, handleGoalDetailsModal, teamId, userId } = props;
  let closeIcon = <></>;
  if (handleGoalDetailsModal !== undefined) {
    closeIcon = <Icon name="x" onClick={handleGoalDetailsModal} className={`${css.floatRight} ${css.pointer}`} />;
  }

  const breakdownContent = (
    <GoalDetailsWithNavigation
      goalAttainment={goalAttainment}
      closeIcon={closeIcon}
      teamId={teamId}
      userId={userId}
    />
  );

  const historicalContent = goalAttainment ? (
    <GoalChartHistoric
      teamId={teamId}
      userId={userId}
      periodIndex={goalAttainment.period?.index}
      goalId={goalAttainment.goalId}
    />
  ) : (<>Loading...</>);

  const panes = [
    {
      menuItem: 'Overview',
      render: (): ReactElement => breakdownContent,
    },
    {
      menuItem: 'Historical',
      render: (): ReactElement => historicalContent,
    },
  ];

  return (
    <Modal open={isOpen} onClose={(): void => handleGoalDetailsModal()} size="large">
      <Modal.Header>
        {goalAttainment?.goalName}
        {closeIcon}
      </Modal.Header>
      <Modal.Content>
        <Tab panes={panes} menu={{ secondary: true, pointing: true }} />
      </Modal.Content>
    </Modal>
  );
};

export default GoalTrackingDetailsModal;
