import { round } from 'lib/util';

interface MetricLanguageKitInterface {
  name: string;

  unit: {
    zero: string;
    one: string;
    other: string;
  };

  defaultValue?: number;
  description: string;
  valueFn?: (value: number) => number;
}

export class MetricLanguageKit implements MetricLanguageKitInterface {
  name: string;

  unit: {
    zero: string;
    one: string;
    other: string;
  };

  defaultValue?: number;

  description: string;

  valueFn?: (value: number) => number;

  constructor(props: MetricLanguageKitInterface) {
    const { name, unit, description, defaultValue, valueFn } = props;
    this.name = name;
    this.unit = unit;
    this.defaultValue = defaultValue;
    this.description = description;
    this.valueFn = valueFn;
  }

  getDisplayValue(value: number | null): string {
    return `${this.getValue(value)} ${this.getUnitFromValue(value)}`;
  }

  getValue(value: number | null): string {
    const v = value || this.defaultValue;
    if (!v && v !== 0) return '--';
    const finalVal = this.valueFn ? this.valueFn(v) : round(v);
    return `${finalVal}`;
  }

  getUnitFromValue(value: number | null): string {
    const v = value || this.defaultValue;
    if (!v && v !== 0) return '';
    const finalVal = this.valueFn ? this.valueFn(v) : round(v);
    if (finalVal === 0) return this.unit.zero;
    if (finalVal === 1) return this.unit.one;
    return this.unit.other;
  }
}

export default {
  MetricLanguageKit,
};
