import React, { useRef, useEffect, CSSProperties, ReactElement, ReactNode } from 'react';
import tinycolor from 'tinycolor2';

interface AlphaProps {
  alpha: number;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

const Alpha = ({
  children,
  className,
  alpha,
  style,
}: AlphaProps): ReactElement => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (elementRef.current) {
      const bgColor = getComputedStyle(elementRef.current).getPropertyValue('color');
      const newBgColor = tinycolor(bgColor).setAlpha(alpha).toString();
      elementRef.current.style.backgroundColor = newBgColor;
    }
  }, [elementRef, alpha]);

  return (
    <div ref={elementRef} className={className} style={style}>
      {children}
    </div>
  );
};

export default Alpha;
