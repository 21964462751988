import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Segment, Icon } from 'semantic-ui-react';

import allActions, { bindCombinedActions } from 'actions';
import { Activity } from 'models/activity';

import ActivityPanel from './components/ActivityPanel';

import css from './ActivityDetails.module.css';

interface ActivityDetailsProps {
  actions: any;
  activity: Activity;
  accountId?: number;
  ownerId?: number;
  as: any;
  onTagAdd: Function;
  onTagRemove: Function;
  onUpdateOpportunity: Function;
}

interface ActivityDetailsState {
  additionalActivities: Array<any>;
  isLoadingActivities: boolean;
}

class ActivityDetails extends Component<ActivityDetailsProps, ActivityDetailsState> {
  static defaultProps = {
    as: Segment,
    onTagAdd: (): void => { /* do nothing */ },
    onTagRemove: (): void => { /* do nothing */ },
  };

  constructor(props) {
    super(props);

    this.state = {
      additionalActivities: [],
      isLoadingActivities: false,
    };
  }

  componentDidMount(): void {
    this.fetchGroupedActivities();
  }

  componentDidUpdate(prevProps: ActivityDetailsProps): void {
    const { activity } = this.props;
    if (prevProps.activity.id !== activity.id) {
      ((): void => {
        this.setState({ additionalActivities: [], isLoadingActivities: false });
        this.fetchGroupedActivities();
      })();
    }
  }

  fetchGroupedActivities = async (noCache = false): Promise<void> => {
    const { actions, activity, accountId, ownerId } = this.props;
    if (activity.groupCnt < 2) return;
    const date = moment(activity.activityDate).format('YYYY-MM-DD');
    this.setState({ isLoadingActivities: true });
    const opts: any = {};
    if (accountId) {
      opts.accountId = accountId;
    }
    if (ownerId) {
      opts.ownerId = ownerId;
    }
    const response = await actions.activity.getGroupedActivities(activity.groupId, date, date, noCache, opts);
    if (response.status) {
      const activities = response.activities.filter(a => (a.id !== activity.id));
      this.setState({ additionalActivities: activities, isLoadingActivities: false });
    }
  };

  handleTagAdd = async (id: number, tagValue: string): Promise<void> => {
    const { onTagAdd } = this.props;
    const response = await onTagAdd(id, tagValue);
    await this.fetchGroupedActivities(true);
    return response;
  }

  handleTagRemove = async (id: number, tagValue: string): Promise<void> => {
    const { onTagRemove } = this.props;
    const response = await onTagRemove(id, tagValue);
    await this.fetchGroupedActivities(true);
    return response;
  }

  renderAdditionalActivities = (): React.ReactNode => {
    const { as, activity, onUpdateOpportunity } = this.props;
    const { additionalActivities, isLoadingActivities } = this.state;

    if (activity.groupCnt < 2) return null;
    if (isLoadingActivities) return (<Icon name="spinner" loading />);
    if (additionalActivities && additionalActivities.length > 0) {
      return additionalActivities.map((a: Activity): React.ReactNode => (
        <ActivityPanel
          as={as}
          activity={a}
          defaultActive={false}
          key={a.id}
          onTagAdd={this.handleTagAdd}
          onTagRemove={this.handleTagRemove}
          onUpdateOpportunity={onUpdateOpportunity}
        />
      ));
    }
    return null;
  };

  render(): React.ReactNode {
    const { as, activity, onTagAdd, onTagRemove, onUpdateOpportunity } = this.props;
    return (
      <div className={css.activityDetailContainer}>
        <ActivityPanel
          as={as}
          activity={activity}
          onTagAdd={onTagAdd}
          onTagRemove={onTagRemove}
          onUpdateOpportunity={onUpdateOpportunity}
        />
        {this.renderAdditionalActivities()}
      </div>
    );
  }
}

export default connect(
  null,
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(ActivityDetails);
