import { round } from 'lib/util';

import { MetricLanguageKit } from './MetricLanguageKit';

export class ResponseTimeLanguageKit extends MetricLanguageKit {
  getDisplayValue(value: number | null): string {
    const v = value || this.defaultValue;
    if (!v && v !== 0) return '--';
    const ONE_DAY = 60 * 60 * 24;
    const valueInSec = v * ONE_DAY;
    if (valueInSec < 1) return '<1';
    if (valueInSec < ONE_DAY) return `${round(valueInSec / 60 / 60)}`;
    const finalVal = this.valueFn ? this.valueFn(v) : round(v);
    return `${finalVal}`;
  }

  getUnitFromValue(value: number | null): string {
    const v = value || this.defaultValue;
    if (!v && v !== 0) return '';
    const ONE_DAY = 60 * 60 * 24;
    const valueInSec = v * ONE_DAY;
    if (valueInSec < 1) return 'seconds';
    if (v < 1) return 'hours';
    const finalVal = this.valueFn ? this.valueFn(v) : round(v);
    if (finalVal === 0) return this.unit.zero;
    if (finalVal === 1) return this.unit.one;
    return this.unit.other;
  }
}

export default { ResponseTimeLanguageKit };
