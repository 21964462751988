import { handleActions } from 'redux-actions';
import actions from 'actions';
import { SalesforceUser, User } from 'models/user';
import { Reducer } from 'redux';

export interface UserState {
  userInfo: any;
  isLoadingUserInfo: boolean;
  isLoadingUsers: boolean;
  users: User[];
  isLoadingSfUsers: boolean;
  sfUsers: SalesforceUser[];
  isSyncing: boolean;
  userErrorMsg: string;
}

export const initialState: UserState = {
  // user info
  userInfo: {},
  isLoadingUserInfo: false,
  // users
  isLoadingUsers: false,
  users: [],
  // users
  isLoadingSfUsers: false,
  sfUsers: [],
  // user syncs
  isSyncing: false,
  userErrorMsg: '',
};

const reducer: Reducer<UserState, any> = handleActions(
  {
    // USER INFO
    [actions.user.fetchUserInfoRequest]: state => ({ ...state, isLoadingUserInfo: true, userInfo: {} }),
    [actions.user.fetchUserInfoSuccess]: (state, { payload: { userInfo } }) => ({
      ...state,
      isLoadingUserInfo: false,
      userInfo,
    }),
    [actions.user.fetchUserInfoFailure]: state => ({ ...state, isLoadingUserInfo: false }),
    // SALESFORCE USERS
    [actions.user.fetchSalesforceUsersRequest]: state => ({ ...state, isLoadingSfUsers: true }),
    [actions.user.fetchSalesforceUsersSuccess]: (state, { payload: { users } }) => ({
      ...state,
      isLoadingSfUsers: false,
      sfUsers: users,
    }),
    [actions.user.fetchSalesforceUsersFailure]: state => ({ ...state, isLoadingSfUsers: false }),
    // FETCH USERS
    [actions.user.fetchUsersRequest]: state => ({ ...state, isLoadingUsers: true }),
    [actions.user.fetchUsersSuccess]: (state, { payload: { users } }) => ({
      ...state,
      isLoadingUsers: false,
      users: users.filter(user => user.active),
    }),
    [actions.user.fetchUsersFailure]: state => ({ ...state, isLoadingUsers: false }),

    [actions.user.syncUserRequest]: state => ({ ...state, isSyncing: true }),
    [actions.user.syncUserComplete]: state => ({ ...state, isSyncing: false }),

    [actions.app.logoutSuccess]: () => initialState,
    [actions.app.logoutFailure]: () => initialState,
    [actions.user.clearUser]: state => ({ ...state, userInfo: {} }),
  },
  initialState,
);

export default reducer;
