import { Icon, Label } from 'semantic-ui-react';
import React, { useState, SyntheticEvent } from 'react';
import css from './ActivityPanel.module.css';
import { ClickAwayListener } from '../../../ClickAwayListener/ClickAwayListener';
import SelectTag from '../../../SelectTag/SelectTag';
import { Activity } from '../../../../models/activity';

interface TagSectionProps {
  activity: Activity;
  onTagAdd: Function;
  onTagRemove: Function;
  tags: string[];
}

export const TagSection: React.FC<TagSectionProps> = props => {
  const { activity, onTagAdd, onTagRemove, tags } = props;
  const [isEditingTag, setIsEditingTag] = useState<boolean>(false);
  const [isSavingTag, setIsSavingTag] = useState<boolean>(false);
  const [isRemovingTag, setIsRemovingTag] = useState<string | undefined>(undefined);
  const [tagError, setTagError] = useState<string | undefined>(undefined);
  const { id } = activity;

  const handleAdd = async (addedId: number, tagValue: string): Promise<void> => {
    setIsSavingTag(true);
    const response = await onTagAdd(addedId, tagValue);
    if (response.status) {
      setIsSavingTag(false);
      setIsEditingTag(false);
      setTagError(undefined);
    } else {
      setIsSavingTag(false);
      setTagError(response.msg);
    }
  };

  const handleEdit = (e: SyntheticEvent): void => {
    e.stopPropagation();
    setIsEditingTag(true);
  };

  const handleEditCancel = (): void => {
    setIsEditingTag(false);
    setTagError(undefined);
  };

  const tagSection = activity.tags ? activity.tags.map(tag => {
    const handleRemove = async (): Promise<void> => {
      setIsRemovingTag(tag);
      await onTagRemove(id, tag);
      setIsRemovingTag(undefined);
    };

    return (
      <Label
        key={tag}
        className={css.tag}
      >
        {tag}
        {
          isRemovingTag === tag
            ? <Icon className={css.loadingIcon} name="circle notched" loading />
            : <Icon name="delete" onClick={handleRemove} />
        }
      </Label>
    );
  }) : [];
  const newTag = isEditingTag ? (
    <div key="tag edit" className={css.newTagContainer}>
      <ClickAwayListener onClickAway={isSavingTag ? undefined : (): void => { handleEditCancel(); }}>
        <SelectTag tags={tags} loading={isSavingTag} onSelect={(tag: string): void => { handleAdd(id, tag); }} />
      </ClickAwayListener>
    </div>

  ) : (
    <Label key="add button" as="a" basic onClick={handleEdit} className={css.addTagLabel}>
      <Icon name="plus" className={css.addTag} />
    </Label>
  );
  tagSection.push(newTag);
  if (tagError) {
    tagSection.push(<Label key="error label" basic color="red">{tagError}</Label>);
  }
  return (
    <div className={css.tags}>
      <Icon name="tags" />
      {tagSection}
    </div>
  );
};
