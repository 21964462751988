import { createActions } from 'redux-actions';
import api from 'lib/api';
import { RecommendationFeedback } from 'models/recommendation';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const actions = createActions({
  RECOMMENDATION: {
    FETCH_RECOMMENDATION_LIST_REQUEST: undefined,
    FETCH_RECOMMENDATION_LIST_SUCCESS: recommendationList => ({ recommendationList }),
    FETCH_RECOMMENDATION_LIST_FAILURE: undefined,
    FETCH_SIDEBAR_REQUEST: undefined,
    FETCH_SIDEBAR_SUCCESS: sidebar => ({ sidebar }),
    FETCH_SIDEBAR_FAILURE: undefined,
    FETCH_RECOMMENDATION_REQUEST: undefined,
    FETCH_RECOMMENDATION_SUCCESS: recommendation => ({ recommendation }),
    FETCH_RECOMMENDATION_FAILURE: undefined,
    FETCH_TAGS_REQUEST: undefined,
    FETCH_TAGS_SUCCESS: tags => ({ tags }),
    FETCH_TAGS_FAILURE: undefined,
  },
});
/* eslint-enable @typescript-eslint/explicit-function-return-type */

export const fetchRecommendationList = (opts): Function => async (dispatch): Promise<any> => {
  dispatch(actions.recommendation.fetchRecommendationListRequest());
  const response = await api.getUserRecommendations(opts);
  if (response.status) {
    dispatch(actions.recommendation.fetchRecommendationListSuccess(response.recommendations));
  } else {
    dispatch(actions.recommendation.fetchRecommendationListFailure());
  }
  return response;
};

export const fetchRecommendation = (
  recommendationId: number,
  options: any = {},
): Function => async (dispatch): Promise<any> => {
  dispatch(actions.recommendation.fetchRecommendationRequest());
  const response = await api.getRecommendation(recommendationId, options);
  if (response.status) {
    dispatch(actions.recommendation.fetchRecommendationSuccess(response.recommendation));
  } else {
    dispatch(actions.recommendation.fetchRecommendationFailure());
  }
  return response;
};

export const fetchRecommendationSidebar = (opts): Function => async (dispatch): Promise<any> => {
  dispatch(actions.recommendation.fetchSidebarRequest());
  const response = await api.getUserRecommendations({ ...opts, unseenOnly: true });
  if (response.status) {
    dispatch(actions.recommendation.fetchSidebarSuccess(response.recommendations));
  } else {
    dispatch(actions.recommendation.fetchSidebarFailure());
  }
  return response;
};

export const fetchRecommendationTags = (): Function => async (dispatch): Promise<any> => {
  dispatch(actions.recommendation.fetchTagsRequest());
  const response = await api.getRecommendationTags();
  if (response.status) {
    dispatch(actions.recommendation.fetchTagsSuccess(response.tags));
  } else {
    dispatch(actions.recommendation.fetchTagsFailure());
  }
  return response;
};

export const markSeen = (notificationId: number | number[]): Function => async (): Promise<any> => {
  const response = await api.markRecommendationSeen(notificationId);
  return response;
};

export const markResolved = (notificationId: number | number[]): Function => async (): Promise<any> => {
  const response = await api.markRecommendationResolved(notificationId);
  return response;
};

export const markUnresolved = (notificationId: number | number[]): Function => async (): Promise<any> => {
  const response = await api.markRecommendationUnresolved(notificationId);
  return response;
};

export const deleteRecommendation = (notificationId: number): Function => async (): Promise<any> => {
  const response = await api.deleteRecommendation(notificationId);
  return response;
};

export const saveFeedback = (feedback: RecommendationFeedback): Function => async (): Promise<any> => {
  const response = await api.saveRecommendationFeedback(feedback);
  return response;
};

const recommendationActions = {
  recommendation: {
    ...actions.recommendation,
    fetchRecommendation,
    fetchRecommendationList,
    fetchRecommendationSidebar,
    fetchRecommendationTags,
    markSeen,
    markResolved,
    markUnresolved,
    deleteRecommendation,
    saveFeedback,
  },
};

export default recommendationActions;
