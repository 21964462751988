/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, SemanticICONS, StrictIconProps } from 'semantic-ui-react';

import css from './BSIcon.module.css';
import { ActivityIcons } from '../../models/activity';
import { TaskTypeIconMapping } from '../../reducers/settings';

interface Props extends StrictIconProps {
  /**
   * Additional classes to be added to the Icon component
   * @abstract
   */
  className?: string;
  /**
   * Activity Icon, if this icon is for an activity
   */
  activityIcon?: ActivityIcons;
  /**
   * Activity Type, if this icon is for an activity
   */
  activityType?: string;

  taskmap: TaskTypeIconMapping;
  isLoadingTaskmap: boolean;
  cornerIcon?: React.ReactElement<StrictIconProps>;
}

class BSIcon extends Component<Props, {}> {
  activityIconToName: { [key in ActivityIcons]: SemanticICONS } = {
    mail: 'mail outline',
    calendar: 'calendar outline',
    sms: 'comment alternate',
    linkedin: 'linkedin',
    call: 'call',
    tasks: 'tasks',
  }

  render(): React.ReactNode {
    const {
      name,
      activityIcon,
      activityType,
      className,
      isLoadingTaskmap,
      taskmap,
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      dispatch, // eslint-disable-line @typescript-eslint/no-unused-vars
      cornerIcon,
      ...iconProps
    } = this.props;

    const classes = [css.icon];

    Object.keys(iconProps).forEach(iconPropsKey => {
      if (iconPropsKey in css) classes.push(css[iconPropsKey]);
    });

    if (className) classes.push(className);
    if (name && name in css) classes.push(css[name]);
    if (activityIcon && activityIcon in css) classes.push(css[activityIcon]);

    if (isLoadingTaskmap) {
      classes.push(css.loading);
      return (
        <Icon name="circle notch" loading className={classes.join(' ')} {...iconProps} />
      );
    }

    let finalName: SemanticICONS;
    const { activityIconToName } = this;
    if (!name) {
      if (activityType && taskmap[activityType] && activityIconToName[taskmap[activityType]]) {
        const icon = taskmap[activityType];
        finalName = activityIconToName[icon];
      } else if (activityIcon && activityIconToName[activityIcon]) finalName = activityIconToName[activityIcon];
      else finalName = 'question';
    } else finalName = name;

    let finalIcon = (
      <Icon
        name={finalName}
        className={classes.join(' ')}
        {...iconProps}
      />
    );
    if (cornerIcon) {
      finalIcon = (
        <Icon.Group>
          {finalIcon}
          {cornerIcon}
        </Icon.Group>
      );
    }

    return finalIcon;
  }
}

export default connect((state: any) => ({
  isLoadingTaskmap: state.settings.isLoadingTaskmap,
  taskmap: state.settings.taskmap,
}))(BSIcon);
