import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Dropdown, Icon, Image, Menu } from 'semantic-ui-react';

import allActions, { bindCombinedActions } from 'actions';

import Breadcrumbs from 'App/Main/components/AppHeader/components';
import PageActionsHeader from '../PageActionsHeader/PageActionsHeader';
import NotificationsBadge from './components/NotificationsBadge';

import css from './AppHeader.module.css';

interface Props {
  actions: any;
  endDate?: Date;
  firstName: string;
  isLoggedIn: boolean;
  location: any;
  onNotificationToggle: (event: React.SyntheticEvent) => void;
  photoUrl: string;
  startDate?: Date;
  navigate: Function;
  visibleNotificationSidebar: boolean;
}
interface State {}

class AppHeader extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleLogout = (): void => {
    const { actions } = this.props;
    actions.app.logout();
  };

  render(): React.ReactNode {
    const { firstName, photoUrl, navigate, location, onNotificationToggle, visibleNotificationSidebar } = this.props;
    const avatar = photoUrl ? (<Image src={photoUrl} avatar />) : (<Icon name="user" />);
    const trigger = (
      <>
        {avatar}
        {' '}
        {firstName}
      </>
    );
    return (
      <div className={css.header}>
        <Menu text>
          <Breadcrumbs />
          <Menu.Menu position="right">
            <NotificationsBadge
              visible={visibleNotificationSidebar}
              onNotificationToggle={onNotificationToggle}
            />
            <PageActionsHeader location={location} />
            <Dropdown item trigger={trigger} className={css.HeaderDropdown}>
              <Dropdown.Menu>
                <Dropdown.Item onClick={(): void => { navigate('/app/settings'); }}>Settings</Dropdown.Item>
                <Dropdown.Item onClick={this.handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    firstName: state.app.firstName,
    photoUrl: state.app.photoUrl,
    isLoggedIn: state.app.isLoggedIn,
  }),
  dispatch => ({
    actions: bindCombinedActions(allActions, dispatch),
    navigate: bindActionCreators((path: string) => push(path), dispatch),
  }),
)(AppHeader);
