import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Label, Modal, Popup } from 'semantic-ui-react';

import { Recommendation } from 'models/recommendation';
import { formatDate, stripMarkdown } from 'lib/util';
import { Permission, hasPermission } from 'components/AccessControl';

import css from './RecommendationRow.module.css';

interface Props {
  recommendation: Recommendation;
  permission: Permission;
  onArchive: () => void;
  onDelete: () => void;
}

interface State {
  strippedBody: string;
  showModal: boolean;
}

class RecommendationRow extends Component<Props, State> {
  static defaultProps = {
    onArchive: (): void => { /* do nothing */ },
    onDelete: (): void => { /* do nothing */ },
  }

  constructor(props) {
    super(props);

    this.state = {
      strippedBody: '',
      showModal: false,
    };
  }

  componentDidMount(): void {
    const { recommendation } = this.props;
    const { body } = recommendation;
    stripMarkdown(body)
      .then((strippedBody: string): void => {
        this.setState({ strippedBody: strippedBody.substring(0, 200) });
      });
  }

  openModal = (): void => {
    this.setState({ showModal: true });
  }

  closeModal = (): void => {
    this.setState({ showModal: false });
  }

  render(): React.ReactNode {
    const { strippedBody, showModal } = this.state;
    const { recommendation, permission, onArchive, onDelete } = this.props;
    const { recommendationId, title, tags, notificationDate, seenAt, resolvedAt } = recommendation;

    const isNew = !seenAt;
    const archiveIconName = !resolvedAt ? 'archive' : 'undo';
    const deleteIconName = 'trash';

    const tagsEl = tags ? tags.map(tag => (<Label key={tag}>{tag}</Label>)) : null;

    return (
      <div className={`${css.recommendationRow} ${isNew ? css.new : ''}`}>
        <div className={css.content}>
          <Link to={`/app/recommendations/${recommendationId}`} className={css.linkRow}>
            <div className={`${css.title}`} title={title}>
              {title}
            </div>
            <div className={css.tags} title={strippedBody}>
              {tagsEl}
            </div>
            <div className={css.date}>
              {formatDate(notificationDate)}
            </div>
          </Link>
          <div className={`link brand ${css.button}`} onClick={onArchive}>
            <Popup
              content={archiveIconName === 'archive' ? 'Archive' : 'Unarchive'}
              hoverable
              inverted
              basic
              size="mini"
              position="bottom center"
              trigger={(<Icon name={archiveIconName} />)}
            />
          </div>
          <>
            {hasPermission(permission, 'admin') && (
              <div className={`link brand ${css.button}`} onClick={this.openModal}>
                <Popup
                  content="Delete"
                  hoverable
                  inverted
                  basic
                  size="mini"
                  position="bottom center"
                  trigger={(<Icon name={deleteIconName} />)}
                />
              </div>
            )}
          </>
        </div>
        <Modal open={showModal} onClose={this.closeModal}>
          <Modal.Header>Confirm Deletion</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete this recommendation?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={this.closeModal}>No</Button>
            <Button positive onClick={onDelete}>Yes</Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default RecommendationRow;
