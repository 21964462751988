import React, { Component } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { Link, Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Menu } from 'semantic-ui-react';

import { Permission, hasPermission } from 'components/AccessControl';
import PrivateRoute from 'components/PrivateRoute';

import Info from './Info';
import TeamManage from './TeamManage';
import UserManage from './UserManage';
import SalesforceSync from './SalesforceSync';
import Super from './Super';

import css from './Settings.module.css';

interface Props extends RouteComponentProps {
  extSource: string;
  isSuperAdmin: boolean;
  permission: Permission;
  isLoggedIn: boolean;
  navigate: Function;
}
interface State { }

interface MenuItem {
  component: any;
  label: string;
  path: string;
  show: boolean;
}

const PATHS = {
  info: '/app/settings',
  teamManage: '/app/settings/teams',
  userManage: '/app/settings/users',
  salesforceSync: '/app/settings/salesforceSync',
  super: '/app/settings/super',
  tags: '/app/settings/tags',
};

class Settings extends Component<Props, State> {
  renderMenuItemsAndRoutes = (): { menuItems: React.ReactNode; routes: React.ReactNode } => {
    const { extSource, permission, isSuperAdmin, isLoggedIn, location } = this.props;
    const items: Array<MenuItem> = [
      {
        component: Info,
        label: 'Account Info',
        path: PATHS.info,
        show: true,
      },
      {
        component: UserManage,
        label: 'Manage User',
        path: PATHS.userManage,
        show: hasPermission(permission, 'admin'),
      },
      {
        component: TeamManage,
        label: 'Manage Team',
        path: PATHS.teamManage,
        show: hasPermission(permission, 'admin'),
      },
      {
        component: SalesforceSync,
        label: 'Salesforce Sync',
        path: PATHS.salesforceSync,
        show: isSuperAdmin && extSource === 'salesforce',
      },
      {
        component: Super,
        label: 'Super',
        path: PATHS.super,
        show: isSuperAdmin && extSource === 'salesforce',
      },
    ];
    const menuItems: Array<any> = [];
    const routes: Array<any> = [];
    items.forEach((menuItem: MenuItem): void => {
      const { component, label, path, show } = menuItem;
      if (!show) return;
      const active = (path === location.pathname);

      menuItems.push(
        <Menu.Item key={label} as={Link} to={path} active={active}>
          {label}
        </Menu.Item>,
      );
      routes.push(
        <PrivateRoute exact key={label} isAuthenticated={isLoggedIn && show} path={path} component={component} />,
      );
    });
    return { menuItems, routes };
  }

  render(): React.ReactNode {
    const { menuItems, routes } = this.renderMenuItemsAndRoutes();
    return (
      <div className={css.settings}>
        <Menu pointing secondary className={css.menu}>
          {menuItems}
        </Menu>
        <div className={css.content}>
          <Switch>
            {routes}
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  (state: any) => ({
    extSource: state.app.extSource,
    isSuperAdmin: state.app.isSuperAdmin,
    permission: state.app.permission,
    isLoggedIn: state.app.isLoggedIn,
  }),
  dispatch => ({ navigate: bindActionCreators((path: string) => push(path), dispatch) }),
)(Settings));
