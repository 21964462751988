import { createActions } from 'redux-actions';

import api from '../lib/api';
import { handleError } from '.';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const actions = createActions({
  SETTINGS: {
    GET_SETTINGS_TASKMAP_REQUEST: null,
    GET_SETTINGS_TASKMAP_SUCCESS: taskmap => ({ taskmap }),
    GET_SETTINGS_TASKMAP_FAILURE: null,
  },
});
/* eslint-enable @typescript-eslint/explicit-function-return-type */

export const getSettingsTaskmap = () => async (dispatch: Function): Promise<any> => {
  dispatch(actions.settings.getSettingsTaskmapRequest());
  const response = await api.getSettingsTaskmap();
  if (response.status) {
    dispatch(actions.settings.getSettingsTaskmapSuccess(response.taskmap));
  } else {
    handleError(response, dispatch);
    dispatch(actions.settings.getSettingsTaskmapFailure());
  }
  return response;
};

const settingsActions = {
  settings: {
    ...actions.settings,
    getSettingsTaskmap,
  },
};

export default settingsActions;
