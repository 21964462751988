import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import {
  Button,
  Checkbox, CheckboxProps,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Form,
  Icon,
  Label,
  Modal,
  Popup,
} from 'semantic-ui-react';
import {
  DEFAULT_CONTACT_FILTER_SETTINGS,
  ProspectingContactFilters,
} from 'App/Main/ProspectingHealthRoute/ProspectingContactList/ProspectingContactList';

import css from './styles.module.css';

const areFiltersActive = (
  currentFilters: ProspectingContactFilters,
  defaultFilters: ProspectingContactFilters,
): boolean => {
  const { contactNameSearch: _currentNameSearch, ...currentWithoutNameSearch } = currentFilters;
  const { contactNameSearch: _defaultNameSearch, ...defaultWithoutNameSearch } = defaultFilters;
  return JSON.stringify(currentWithoutNameSearch) !== JSON.stringify(defaultWithoutNameSearch);
};

type CheckboxHandlerGenerator = (fn: Dispatch<SetStateAction<boolean | undefined>>) => CheckboxProps['onChange'];

const checkboxHandlerGen: CheckboxHandlerGenerator = stateFn => (_, data): void => {
  let newValue: boolean | undefined;
  if (data.checked) newValue = true;
  stateFn(newValue);
};

type DropdownHandlerGenerator = (fn: Dispatch<SetStateAction<any>>) => DropdownProps['onChange'];

const dropdownHandlerGen:
  DropdownHandlerGenerator = stateFn => (_, data): void => stateFn(data.value);

const ProspectingContactListFilterModal = (props: {
  filters: ProspectingContactFilters;
  setFilters: React.Dispatch<React.SetStateAction<ProspectingContactFilters>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ownerOptions: DropdownItemProps[];
  activityOwnerOptions: DropdownItemProps[];
}): ReactElement => {
  const DEFAULT_SETTINGS = DEFAULT_CONTACT_FILTER_SETTINGS;

  const { filters, setFilters, open, setOpen, ownerOptions, activityOwnerOptions } = props;
  const [ownerIds, setOwnerIds] = useState(filters?.ownerIds || DEFAULT_SETTINGS.ownerIds);
  const [
    hasActivityWithOwnerIds,
    setHasActivityWithOwnerIds,
  ] = useState(filters?.ownerIds || DEFAULT_SETTINGS.hasActivityWithOwnerIds);
  const [phases, setPhases] = useState(filters?.phases || DEFAULT_SETTINGS.phases);
  const [
    recommendations,
    setRecommendations,
  ] = useState(filters?.recommendations || DEFAULT_SETTINGS.recommendations);
  const [
    hasRecommendation,
    setHasRecommendation,
  ] = useState<boolean | undefined>(filters?.hasRecommendation || DEFAULT_SETTINGS.hasRecommendation);
  const [isActive, setIsActive] = useState<boolean | undefined>(filters?.isActive || DEFAULT_SETTINGS.isActive);

  const activeFilters = areFiltersActive(filters, DEFAULT_SETTINGS);

  const indicator = activeFilters ? (
    <Label
      floating
      circular
      color="pink"
      style={{ fontSize: '.3rem' }}
    />
  ) : null;

  const handleSave = (): void => {
    setOpen(false);
    setFilters({
      ownerIds,
      hasActivityWithOwnerIds,
      recommendations,
      hasRecommendation,
      phases,
    });
  };
  const handleClear = (): void => {
    setOpen(false);
    setOwnerIds(DEFAULT_SETTINGS.ownerIds);
    setHasRecommendation(DEFAULT_SETTINGS.hasRecommendation);
    setPhases(DEFAULT_SETTINGS.phases);
    setIsActive(DEFAULT_SETTINGS.isActive);
    setFilters(DEFAULT_SETTINGS);
  };

  const phaseOptions: DropdownItemProps[] = [
    { value: 'pre conversation', text: 'Pre Conversation' },
    { value: 'post conversation', text: 'Conversation' },
  ];

  const recommendationOptions: DropdownItemProps[] = [
    { value: 'activity_velocity', text: 'Activity velocity' },
    { value: 'big_gap_count', text: 'Activity gap count' },
    { value: 'is_multi_channel', text: 'Multi channel prospecting' },
    { value: 'prospect_response_time_mean', text: 'Prospect has gone dark' },
    { value: 'needs_response', text: 'Response recommended' },
    { value: 'rep_followup_time_mean', text: 'Rep followup time' },
    { value: 'rep_response_time_mean', text: 'Rep response time' },
    { value: 'flesch_kincaid_grade_mean', text: 'Email grade level' },
    { value: 'word_count_mean', text: 'Email word count' },
  ];

  const ownerIdChangeHandler: DropdownProps['onChange'] = (_, data): void => {
    const newVal: number[] = [];
    const { value } = data;
    if (Array.isArray(value)) {
      value.forEach(v => {
        if (typeof v === 'number') newVal.push(v);
      });
    }
    setOwnerIds(newVal);
  };

  const hasActivityWithOwnerIdChangeHandler: DropdownProps['onChange'] = (_, data): void => {
    const newVal: number[] = [];
    const { value } = data;
    if (Array.isArray(value)) {
      value.forEach(v => {
        if (typeof v === 'number') newVal.push(v);
      });
    }
    setHasActivityWithOwnerIds(newVal);
  };

  return (
    <Modal
      onClose={(): void => setOpen(false)}
      onOpen={(): void => setOpen(true)}
      open={open}
      closeIcon
      trigger={(
        <div className={`link brand ${css.modalTrigger}`} style={{ position: 'relative' }}>
          <Popup
            inverted
            basic
            size="mini"
            position="bottom center"
            style={{ backgroundColor: '#5A5B5D' }}
            content="Filters"
            trigger={(<Icon name="filter" />)}
          />
          {indicator}
        </div>
      )}
      size="small"
    >
      <Modal.Header>
        <h1>Filters</h1>
      </Modal.Header>
      <Modal.Content>
        <Form>
          <ModalFormField
            title="Contact Owner"
            options={ownerOptions}
            placeholder="Select owner"
            handleChange={ownerIdChangeHandler}
            multiple
            value={ownerIds}
          />
          <ModalFormField
            title="Activity Owners"
            options={activityOwnerOptions}
            placeholder="Select owner"
            handleChange={hasActivityWithOwnerIdChangeHandler}
            multiple
            value={hasActivityWithOwnerIds}
          />
          <ModalFormField
            title="Phases"
            options={phaseOptions}
            placeholder="Select stage"
            handleChange={dropdownHandlerGen(setPhases)}
            multiple
            value={phases}
          />
          <ModalFormField
            title="Recommendations"
            options={recommendationOptions}
            placeholder="Select recomendation"
            handleChange={dropdownHandlerGen(setRecommendations)}
            multiple
            value={recommendations}
          />
          <Form.Field>
            <Checkbox
              checked={hasRecommendation === true}
              onChange={checkboxHandlerGen(setHasRecommendation)}
              label="Has Recommendation"
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              checked={isActive === true}
              onChange={checkboxHandlerGen(setIsActive)}
              label="Is Active"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={handleClear}>Clear All Filters</Button>
        <Button color="grey" onClick={(): void => setOpen(false)}>Cancel</Button>
        <Button color="pink" onClick={handleSave}>Save</Button>
      </Modal.Actions>
    </Modal>
  );
};

interface FormFieldProps extends DropdownProps {
  handleChange: DropdownProps['onChange'];
  title: string;
  options?: DropdownItemProps[];
}
/* eslint-disable react/jsx-props-no-spreading */
const ModalFormField: React.FC<FormFieldProps> = ({ handleChange, title, options, value, ...dropdownProps }) => {
  if (!options) return null;
  let dropdownValue = value;
  if (value === undefined) dropdownValue = [];
  return (
    <Form.Field>
      <div>{title}</div>
      <Dropdown
        onChange={handleChange}
        fluid
        selection
        options={options}
        value={dropdownValue}
        {...dropdownProps}
      />
    </Form.Field>
  );
};
/* esline-enable */

export default ProspectingContactListFilterModal;
