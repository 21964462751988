import moment from 'moment';

import { Permission } from 'components/AccessControl';
import { Team } from 'models/team';
import { RecommendationFeedback } from 'models/recommendation';

const baseUrl = process.env.REACT_APP_API;

export class Api {
  base: string;

  constructor(base: string) {
    this.base = base;
  }

  // helper wrappers
  getSalesforceLoginUrl(): string {
    return this.fullURL(`/sf/auth?redirectUri=${encodeURIComponent(`${window.location.origin}/cb`)}`);
  }

  getHubSpotLoginUrl(): string {
    return this.fullURL('/hubspot/login/auth');
  }

  async logout(): Promise<any> {
    const res = await this.get('/user/logout');
    localStorage.removeItem('original-user');
    localStorage.removeItem('original-user');
    return res;
  }

  async checkStatus(): Promise<any> {
    return this.get('/status');
  }

  async addTag(activityId, tag): Promise<any> {
    return this.put(`/activity/${activityId}/tags`, { tag });
  }

  async removeTag(activityId, tag): Promise<any> {
    return this.delete(`/activity/${activityId}/tags`, { tag });
  }

  async removeTagFromAll(tag): Promise<any> {
    return this.delete('/activity/tags', { tag });
  }

  async getAuthToken(code: string): Promise<any> {
    return this.get(`/sf/callback?code=${code}`);
  }

  // contact

  async getContact(contactId): Promise<any> {
    return this.get(`/contact/${contactId}`);
  }

  async getContactActivityInfo(contactId: number): Promise<any> {
    return this.get(`/contact/activity/info/${contactId}`);
  }

  // account

  async getAccount(accountId): Promise<any> {
    return this.get(`/account/${accountId}`);
  }

  async getAccountsWithStats(opts = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/account/stats/all${queryString}`);
  }

  async getAccountsOfUser(userId, opts = { includeNotOwned: true }): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/account/stats/user/${userId}${queryString}`);
  }

  async getAccountActivityInfo(accountId: number): Promise<any> {
    return this.get(`/account/activity/info/${accountId}`);
  }

  async searchAccounts(body): Promise<any> {
    return this.post('/account/search', body);
  }

  async searchActivities(opts = {}): Promise<any> {
    return this.post('/activity/search', opts);
  }

  async getActivityOpportunity(activityId): Promise<any> {
    return this.get(`/activity/${activityId}/opportunity`);
  }

  // account health

  async getAccountHealthThresholds(opts = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/account/health/thresholds${queryString}`);
  }

  async getAccountHealth(opts = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/account/health/all${queryString}`);
  }

  async getAccountTimeline(accountId, opts = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/account/health/timeline/${accountId}${queryString}`);
  }

  async loadMoreInactiveAccounts(opts: { page: number; teamId?: number } = { page: 0 }): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/account/health/inactive${queryString}`);
  }

  async getAccountHealthStats(accountId): Promise<any> {
    return this.get(`/account/health/details/${accountId}`);
  }

  // Recommendation
  async getUserRecommendations(opts = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/recommendation/all${queryString}`);
  }

  async getRecommendation(recommendationId: number, opts = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/recommendation/${recommendationId}${queryString}`);
  }

  async markRecommendationSeen(notificationIds: number | number[]): Promise<any> {
    return this.put('/recommendation/seen', { notificationIds });
  }

  async markRecommendationResolved(notificationIds: number | number[]): Promise<any> {
    return this.put('/recommendation/resolve', { notificationIds });
  }

  async markRecommendationUnresolved(notificationIds: number | number[]): Promise<any> {
    return this.put('/recommendation/unresolve', { notificationIds });
  }

  async deleteRecommendation(notificationId: number): Promise<any> {
    return this.delete(`/recommendation/delete/${notificationId}`);
  }

  async saveRecommendationFeedback(feedback: RecommendationFeedback): Promise<any> {
    return this.put('/recommendation/feedback', feedback);
  }

  async getRecommendationTags(): Promise<any> {
    return this.get('/recommendation/tags');
  }

  // Alert endpoints
  async getAlertNotifications(teamId, opts = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/alert/notification/team/${teamId}${queryString}`);
  }

  async updateAlertNotification(notificationId, notificationObj): Promise<any> {
    return this.put(`/alert/notification/${notificationId}`, notificationObj);
  }

  async batchUpdateNotifications(action: string, notificationIds: Array<number>): Promise<any> {
    return this.put(`/alert/notification/batch/${action}`, { notificationIds });
  }

  async batchUpdateNotificationsByRuleId(action: string, ruleId: number): Promise<any> {
    return this.put(`/alert/notification/batch/${action}`, { ruleId });
  }

  async getAlertRules(teamId): Promise<any> {
    return this.get(`/alert/rule/team/${teamId}`);
  }

  async getAlertRule(alertRuleId): Promise<any> {
    return this.get(`/alert/rule/${alertRuleId}`);
  }

  async saveAlertRule(teamId, alertRule): Promise<any> {
    return this.post(`/alert/rule/team/${teamId}`, alertRule);
  }

  async pauseAlertRule(ruleId, paused): Promise<any> {
    return this.put(`/alert/rule/pause/${ruleId}`, { paused });
  }

  async deleteAlertRule(ruleId): Promise<any> {
    return this.delete(`/alert/rule/${ruleId}`);
  }

  async getSalesforceReports(): Promise<any> {
    return this.get('/sf/report');
  }

  async getSalesforceReport(reportId, opts = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/sf/report/${reportId}${queryString}`);
  }

  // App endpoints

  async getCurrentUser(): Promise<any> {
    return this.get('/user/current');
  }

  async getUser(userId): Promise<any> {
    return this.get(`/user/${userId}`);
  }

  async getUserStats(userId, opts = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/user/${userId}/stats${queryString}`);
  }

  async getSalesforceUsers(opts = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/sf/user${queryString}`);
  }

  async getSettingsTaskmap(): Promise<any> {
    return this.get('/settings/taskmap');
  }

  async getOrganization(): Promise<any> {
    return this.get('/organization');
  }

  async getOpportunityStages(): Promise<any> {
    return this.get('/opportunity/stages');
  }

  async getOpportunityTypes(): Promise<any> {
    return this.get('/opportunity/types');
  }

  async getTags(opts: any = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/activity/tags${queryString}`);
  }

  async getTagRules(opts: { tagName?: string } = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/activity/tags/rules${queryString}`);
  }

  async saveTagRule(rule): Promise<any> {
    return this.post('/activity/tags/rules', rule);
  }

  async updateActivityOpportunity(opportunityId, activityId, opts = {}): Promise<any> {
    return this.put(`/activity/${activityId}/opportunity/${opportunityId}`, opts);
  }

  async deleteTagRule(ruleId): Promise<any> {
    return this.delete(`/activity/tags/rules/${ruleId}`);
  }

  async applyAllTagRules(): Promise<any> {
    return this.post('/activity/tags/apply');
  }

  async applyTagRule(ruleId, opts = {}): Promise<any> {
    return this.post(`/activity/tags/apply/${ruleId}`, opts);
  }

  // pipeline
  async getPipelineOpen(opts = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/pipeline/open${queryString}`);
  }

  async getPipelineOpenUnderlying(date, opts = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/pipeline/open/underlying/${date}${queryString}`);
  }

  async getPipelineCreated(opts = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/pipeline/created${queryString}`);
  }

  async getPipelineClosed(opts = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/pipeline/closed${queryString}`);
  }

  async getPipelineWaterfall(opts = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/pipeline/waterfall${queryString}`);
  }

  async getPipelineStages(opts = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/pipeline/stages${queryString}`);
  }

  async getUsers(): Promise<any> {
    return this.get('/user/all');
  }

  async deactivateUser(userId: string): Promise<any> {
    return this.post('/user/deactivate', { id: userId });
  }

  async setConsent(): Promise<any> {
    return this.post('/user/consent', { tosConsented: true });
  }

  async setOrganizationSettings(settings: any = {}): Promise<any> {
    return this.post('/organization/update/settings', settings);
  }

  async setTrackedUser(user: any): Promise<any> {
    const { id, extId, extSource, isTracked } = user;
    return this.post('/user/tracked', { id, extId, extSource, isTracked });
  }

  async setUserPermissions(id: string, permission: Permission): Promise<any> {
    return this.post('/user/permission', { id, permission });
  }

  async saveUserFromSalesforce(sfUserId: string, opts: any = { syncActivities: false }): Promise<any> {
    return this.post(`/sf/user/${sfUserId}`, opts);
  }

  async uploadImage(file): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.postForm('/image', formData);
  }

  async uploadFile(file): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.postForm('/file', formData);
  }

  // super admin

  async getAuthAs(userId: string, source: string): Promise<any> {
    return this.get(`/user/switch/${source}/${userId}`);
  }

  async salesforceQuery(query: string): Promise<any> {
    return this.post('/sf/query', { query });
  }

  async describeSalesforceObject(objectName): Promise<any> {
    return this.post('/sf/describe', { objectName });
  }

  async getAllOrganizations(): Promise<any> {
    return this.get('/organization/all');
  }

  async getUsersByOrg(orgId): Promise<any> {
    return this.get(`/user/organization/${orgId}/all`);
  }

  async getRecommendationsByOrg(orgId, opts = {}): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/recommendation/admin/list/${orgId}${queryString}`);
  }

  async getRecommendationForAdmin(recommendationId, opts): Promise<any> {
    const queryString = this.constructQueryString(opts);
    return this.get(`/recommendation/admin/${recommendationId}${queryString}`);
  }

  async getRecommendationTagsForAdmin(organizationId): Promise<any> {
    return this.get(`/recommendation/admin/tags/${organizationId}`);
  }

  async saveRecommendation(body: any): Promise<any> {
    return this.post('/recommendation/admin/save', body);
  }

  async deleteRecommendationNotification(notificationId, organizationId): Promise<any> {
    return this.delete('/recommendation/admin/notification', { notificationId, organizationId });
  }

  // team
  async getTeams(): Promise<any> {
    return this.get('/team');
  }

  async getTeamWithMembers(): Promise<any> {
    return this.get('/team/members');
  }

  async getUserTeams(): Promise<any> {
    return this.get('/team/user');
  }

  async getTeamSettings(teamId: number): Promise<any> {
    return this.get(`/team/${teamId}/settings`);
  }

  async createTeam(name: string): Promise<any> {
    return this.post('/team', { name });
  }

  async addTeamMember(userIds: string[], teamId: string): Promise<any> {
    return this.post(`/team/${teamId}/add`, { userIds });
  }

  async removeUserFromTeam(userId: string, teamId: string): Promise<any> {
    return this.post(`/team/${teamId}/remove`, { userIds: [userId] });
  }

  async editTeam(team: Team): Promise<any> {
    return this.post(`/team/${team.id}`, { name: team.name });
  }

  async deleteTeam(teamId: string): Promise<any> {
    return this.delete(`/team/${teamId}`);
  }

  // The base request functions
  async get(endpoint): Promise<{ status: boolean;[x: string]: any }> {
    try {
      const headers = this.getHeaders();
      headers.append('Content-Type', 'application/json');
      const response = await fetch(this.fullURL(endpoint), {
        headers,
        credentials: 'include',
      });
      if (response.ok) {
        return response.json();
      }
      // TODO: error handling
      if (response.status === 401) {
        return { status: false, error: 'noauth' };
      }
      return response.json();
    } catch (e) {
      return {
        status: false,
        error: 'applicationError',
        msg: e,
      };
    }
  }

  async post(endpoint, requestBody = {}): Promise<{ status: boolean;[x: string]: any }> {
    try {
      const headers = this.getHeaders();
      headers.append('Content-Type', 'application/json');
      const response = await fetch(this.fullURL(endpoint), {
        method: 'POST',
        headers,
        credentials: 'include',
        body: this.formatRequestBody(requestBody),
      });
      if (response.ok) {
        return response.json();
      }
      // TODO: error handling
      if (response.status === 401) {
        return { status: false, error: 'noauth' };
      }
      return response.json();
    } catch (e) {
      return {
        status: false,
        error: 'applicationError',
        msg: e,
      };
    }
  }

  async postForm(endpoint, formData): Promise<{ status: boolean;[x: string]: any }> {
    try {
      const headers = this.getHeaders();
      const response = await fetch(this.fullURL(endpoint), {
        method: 'POST',
        headers,
        credentials: 'include',
        body: formData,
      });
      if (response.ok) {
        return response.json();
      }
      // TODO: error handling
      if (response.status === 401) {
        return { status: false, error: 'noauth' };
      }
      return response.json();
    } catch (e) {
      return {
        status: false,
        error: 'applicationError',
        msg: e,
      };
    }
  }

  async put(endpoint, requestBody = {}): Promise<{ status: boolean;[x: string]: any }> {
    try {
      const headers = this.getHeaders();
      headers.append('Content-Type', 'application/json');
      const response = await fetch(this.fullURL(endpoint), {
        method: 'PUT',
        headers,
        credentials: 'include',
        body: this.formatRequestBody(requestBody),
      });
      if (response.ok) {
        return response.json();
      }
      // TODO: error handling
      if (response.status === 401) {
        return { status: false, error: 'noauth' };
      }
      return response.json();
    } catch (e) {
      return {
        status: false,
        error: 'applicationError',
        msg: e,
      };
    }
  }

  async delete(endpoint, requestBody = {}): Promise<{ status: boolean;[x: string]: any }> {
    try {
      const headers = this.getHeaders();
      headers.append('Content-Type', 'application/json');
      const response = await fetch(this.fullURL(endpoint), {
        method: 'DELETE',
        headers,
        credentials: 'include',
        body: this.formatRequestBody(requestBody),
      });
      if (response.ok) {
        return response.json();
      }
      // TODO: error handling
      if (response.status === 401) {
        return { status: false, error: 'noauth' };
      }
      return response.json();
    } catch (e) {
      return {
        status: false,
        error: 'applicationError',
        msg: e,
      };
    }
  }

  formatRequestBody(body: any): any {
    return JSON.stringify(body);
  }

  fullURL(endpoint: string): string {
    return `${this.base}${endpoint}`;
  }

  getHeaders(): Headers {
    const headers = new Headers();
    return headers;
  }

  constructQueryString(opts: any = {}): string {
    const keys = Object.keys(opts);
    if (keys.length === 0) return '';
    const optsArray: Array<string> = [];
    Object.entries(opts).forEach(([key, v]: [string, any]): void => {
      let value = v;
      if (value instanceof Boolean && value) {
        optsArray.push(`${key}`);
        return;
      }
      if (value instanceof Date || moment(value, 'YYYY-MM-DD', true).isValid()) {
        value = moment(value).format('YYYY-MM-DDTHH:mm:ss');
      }
      optsArray.push(`${key}=${encodeURIComponent(value)}`);
    });
    return `?${optsArray.join('&')}`;
  }
}

if (!baseUrl) {
  throw new Error('Env var REACT_APP_API is not set');
}

const api = new Api(baseUrl);

export default api;
