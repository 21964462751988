import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { DropdownItemProps, Segment, Tab, TabPaneProps } from 'semantic-ui-react';
import { skipToken } from '@reduxjs/toolkit/query';
import qs from 'query-string';

import { prospectingApi } from 'features/Api';
import { ProspectingAccount, ProspectingPhase, ProspectingRecommendation } from 'features/Api/Prospecting';
import { getSelectedTeamId } from 'selectors/team';
import { User } from 'models/user';

import ProspectingAccountListFilterModal from './FilterModal';
import ProspectingAccountTable from './Table';

import ProspectingAccountListHeader from './ProspectingAccountListHeader';
import ProspectingSearchBar from './ProspectingSearchBar';

import css from './styles.module.css';

export type ProspectingAccountFilters = {
  ownerIds: number[];
  hasActivityWithOwnerIds: number[];
  phases: ProspectingPhase[];
  recommendations: ProspectingRecommendation[];
  hasRecommendation?: boolean;
  accountNameSearch?: string;
};

const PROSPECTING_FILTER_KEY = 'prospectingFilters';

export const DEFAULT_ACCOUNT_FILTER_SETTINGS = {
  ownerIds: [],
  hasActivityWithOwnerIds: [],
  phases: [],
  recommendations: [],
  hasRecommendation: undefined,
  accountNameSearch: undefined,
};

export const getDefaultProspectingAccountFilterSettings = (): ProspectingAccountFilters => {
  const savedFiltersString = sessionStorage.getItem(PROSPECTING_FILTER_KEY);
  if (savedFiltersString) {
    const savedFilters = JSON.parse(savedFiltersString);
    return savedFilters;
  }
  return DEFAULT_ACCOUNT_FILTER_SETTINGS;
};

interface QueryParams {
  pane?: 'active' | 'inactive';
  owners?: string;
  hasRecommendation?: string | null;
  accountPhases?: string;
  contactPhases?: string;
}

const getInitialFilterSettings = (params: QueryParams): ProspectingAccountFilters => {
  const settings = getDefaultProspectingAccountFilterSettings();
  const {
    owners,
    hasRecommendation: hasRecQueryParam,
    accountPhases,
  } = params;
  let ownerIds: number[] = [];
  if (owners !== undefined) {
    const parsedOwners = JSON.parse(owners);
    if (Array.isArray(parsedOwners) && parsedOwners.every(oId => typeof oId === 'number')) {
      ownerIds = parsedOwners;
    } else if (typeof parsedOwners === 'number') {
      ownerIds = [parsedOwners];
    }
    settings.ownerIds = ownerIds;
  }
  let { phases } = settings;
  if (accountPhases !== undefined) {
    if (accountPhases.slice(0, 1) === '[' && accountPhases.slice(-1) === ']') {
      phases = accountPhases.slice(1, -1).split(',') as ProspectingPhase[];
    } else {
      phases = [accountPhases as ProspectingPhase];
    }
    settings.phases = phases;
  }
  if (hasRecQueryParam !== undefined) {
    settings.hasRecommendation = true;
  }
  return settings;
};

const getInitialActivePane = (params: QueryParams): number => {
  const { pane } = params;
  if (pane === 'inactive') return 1;
  return 0;
};

const accountsToOwnerOptions = (accounts: ProspectingAccount[]): DropdownItemProps[] => {
  const ownerDupeDetect: Record<number, boolean> = {};

  const accountToOwnerReduceFn = (prev: DropdownItemProps[], curr: ProspectingAccount): DropdownItemProps[] => {
    const { ownerId, ownerName } = curr;
    if (typeof ownerDupeDetect[ownerId] === 'undefined') {
      prev.push({ value: ownerId, text: ownerName });
      ownerDupeDetect[ownerId] = true;
    }
    return prev;
  };
  const ownerSortFn = (
    a: DropdownItemProps,
    b: DropdownItemProps,
  ): number => (a.text as string).localeCompare(b.text as string);
  return accounts.reduce(accountToOwnerReduceFn, []).sort(ownerSortFn);
};

const accountsToActivityOwnerOptions = (owners: User[]): DropdownItemProps[] => owners?.map(o => ({
  value: (o.id as number),
  text: o.name,
}));

const ProspectingAccountList = (): ReactElement => {
  const teamId = useSelector(getSelectedTeamId);
  const location = useLocation();
  const queryParams: QueryParams = qs.parse(location.search);
  const history = useHistory();
  const [filters, setFilters] = useState<ProspectingAccountFilters>(getInitialFilterSettings(queryParams));
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [activePaneIndex, setActivePaneIndex] = useState(getInitialActivePane(queryParams));

  const handleFilterChange = (f: React.SetStateAction<ProspectingAccountFilters>): void => {
    setFilters(f);
    const serializedFilters = JSON.stringify(f);
    sessionStorage.setItem('prospectingFilters', serializedFilters);
  };

  const status = activePaneIndex === 0 ? 'active' : 'inactive';
  const { pane } = queryParams;
  useEffect(() => {
    if (pane === undefined || pane !== status) {
      const newSearchParams = qs.stringify({
        ...queryParams,
        pane: status,
      });
      history.replace(`${location.pathname}?${newSearchParams}`);
    }
  }, [queryParams, location, history]);

  const tabChangeCallback = (_e, data): void => {
    setActivePaneIndex(data.activeIndex);
    const newSearchParams = qs.stringify({
      ...queryParams,
      pane: status,
    });
    history.replace(`${location.pathname}?${newSearchParams}`);
  };

  const activeQueryParams = teamId ? { teamId } : skipToken;
  const inactiveQueryParams = teamId ? { teamId } : skipToken;
  const {
    data: activeData,
    isFetching: activeIsLoading,
  } = prospectingApi.endpoints.getProspectingAccounts.useQuery(activeQueryParams);

  const {
    data: inactiveData,
    isFetching: inactiveIsLoading,
  } = prospectingApi.endpoints.getProspectingInactiveAccounts.useQuery(inactiveQueryParams);

  const data = status === 'active' ? activeData : inactiveData;
  const isLoading = status === 'active' ? activeIsLoading : inactiveIsLoading;

  if (!teamId) {
    return (<Segment className={css.strategyInsights}>No team selected</Segment>);
  }

  let accounts: ProspectingAccount[] = [];
  let activityOwners: User[] = [];
  let ownerOptions: DropdownItemProps[] = [];
  let activityOwnerOptions: DropdownItemProps[] = [];
  if (!isLoading && data) {
    ({ accounts, activityOwners } = data);
    accounts = accounts || [];
    ownerOptions = accountsToOwnerOptions(accounts);
    activityOwnerOptions = accountsToActivityOwnerOptions(activityOwners);
  }

  const panes: TabPaneProps['panes'] = [
    {
      menuItem: 'Active',
      render: (): ReactElement => (
        <Tab.Pane loading={isLoading} className={css.pane}>
          <ProspectingAccountTable teamId={teamId} status="active" filters={filters} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Inactive',
      render: (): ReactElement => (
        <Tab.Pane loading={isLoading} className={css.pane}>
          <ProspectingAccountTable teamId={teamId} status="inactive" filters={filters} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Segment className={css.accountList}>
      <ProspectingAccountListHeader active={activeData?.accounts} inactive={inactiveData?.accounts} />
      <div className={css.prospectingSettingsBar}>
        <ProspectingSearchBar />
        <ProspectingAccountListFilterModal
          filters={filters}
          setFilters={handleFilterChange}
          open={filterModalOpen}
          setOpen={setFilterModalOpen}
          ownerOptions={ownerOptions}
          activityOwnerOptions={activityOwnerOptions}
        />
      </div>
      <Tab
        panes={panes}
        menu={{
          compact: true,
          pointing: true,
          secondary: true,
        }}
        onTabChange={tabChangeCallback}
        activeIndex={activePaneIndex}
      />
    </Segment>
  );
};

export default ProspectingAccountList;
