import { PipelineStats } from 'models/pipeline';
import baseApi from '../apiSlice';
import { ApiResponse } from '../apiTypes';

export interface Query {
  teamId: number;
  startDate: string;
  endDate: string;
}

export interface Response extends ApiResponse {
  pipeline: {
    teamAvg: PipelineStats;
    members: {
      [userId: number]: PipelineStats;
    };
  };
}

export const isPipelineStatsApiResponse = (x: any): x is Response => typeof x.pipeline !== 'undefined';

const teamPipelineApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTeamPipelineStats: builder.query<Response, Query>({
      query: ({ teamId, startDate, endDate }) => `team/${teamId}/pipeline/${startDate}-${endDate}`,
    }),
  }),
});

export const { useQuery } = teamPipelineApi.endpoints.getTeamPipelineStats;
