import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Dimmer, Loader, Menu, Placeholder, Segment } from 'semantic-ui-react';
import { DateTime } from 'luxon';

import { funnelStatsApi } from 'features/Api';
import Funnel from 'components/Funnel';

import { getApiDatesFromTimePeriod } from 'features/DatePicker/datePickerFunctions';
import { getSelectedTeamId } from 'selectors/team';
import { selectAppDateSelection } from 'features/App/appSlice';
import { RootState } from 'store';

import FunnelFilter from 'components/FunnelFilter';

import css from './styles.module.css';
import NavHeader from '../NavHeader';

function TeamFunnel(): ReactElement {
  const teamId = useSelector(getSelectedTeamId);
  const timePeriod = useSelector(selectAppDateSelection);
  const { start, end } = getApiDatesFromTimePeriod(timePeriod);

  const funnel = teamId ? (<TeamFunnelComponent teamId={teamId} start={start} end={end} />) : (
    <Placeholder>
      <Placeholder.Paragraph />
    </Placeholder>
  );

  return (
    <>
      <NavHeader>
        <Menu.Item className={css.funnelTrigger}>
          <FunnelFilter showOwnerFilters />
        </Menu.Item>
      </NavHeader>
      <Segment>
        {funnel}
      </Segment>
    </>
  );
}

function TeamFunnelComponent(props: {
  teamId: number;
  start: string;
  end: string;
}): ReactElement {
  const { teamId, start, end } = props;

  const ownerId = useSelector((state: RootState) => state.funnel.ownerId);
  const filter = useSelector((state: RootState) => state.funnel.filter);

  const {
    data: funnelStatsApiResponse,
    isFetching,
  } = funnelStatsApi.endpoints.getFunnelStats.useQuery({
    teamId,
    startDate: DateTime.fromISO(start).toFormat('yyyyMMdd'),
    endDate: DateTime.fromISO(end).toFormat('yyyyMMdd'),
    filter: { ownerId, ...filter },
  });

  if (!isFetching && !funnelStatsApiResponse?.stats) {
    return (<Segment>Error Retrieving funnel</Segment>);
  }

  const stats = funnelStatsApiResponse?.stats || [];

  return (
    <>
      <div className={css.funnelHeader}>
        <Loader active={isFetching} />
        <Dimmer active={isFetching} inverted />
        <div className={css.header}>
          <h4>
            Prospecting Funnel
          </h4>
        </div>
      </div>
      <Funnel stats={stats} expanded />
    </>
  );
}

export default TeamFunnel;
