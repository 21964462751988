import { Datum, SortSettings, Value } from 'components/TeamComparisonChart/TeamComparisonChart';
import { createContext, useContext } from 'react';

export interface TeamComparisonChartBodyContextProps {
  data: Datum[];
  maxValue: Value;
  columnHeaders: {
    visible: boolean;
    sortable: boolean;
  };
  sorting: {
    sortSettings: SortSettings;
    setSortSettings: (s: SortSettings) => void;
  };
  size: 'regular' | 'big';
}

const TeamComparisonChartBodyContext = createContext<TeamComparisonChartBodyContextProps | undefined>(undefined);

export const TeamComparisonChartBodyProvider = TeamComparisonChartBodyContext.Provider;

export const useTeamComparisonBodyChartContext = (): TeamComparisonChartBodyContextProps => {
  const context = useContext(TeamComparisonChartBodyContext);
  if (!context) {
    throw new Error(
      "You must've used a TeamComparisonChartBody subcomponent outside of its parent",
    );
  }
  return context;
};
