/* eslint-disable import/no-extraneous-dependencies */
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { configureStore } from '@reduxjs/toolkit';
import { appSlice } from 'features/App/appSlice';
import { accountSlice } from 'features/Account/accountSlice';
import { contactSlice } from 'features/Contact/contactSlice';
import { funnelSlice } from 'features/Funnel/funnelSlice';
import baseApi from 'features/Api/apiSlice';
import createRootReducer from './reducers';

export const history = createBrowserHistory();

const middlewares = [routerMiddleware(history), baseApi.middleware];

if (
  process.env.REACT_APP_SENTRY_ENVIRONMENT
  && ['Staging', 'Production'].includes(process.env.REACT_APP_SENTRY_ENVIRONMENT)
) {
  // when we do sentry logging
  // setupSentry();
  // middlewares.push(sentryMiddleware);
}

/* eslint-disable-next-line no-underscore-dangle */
if (process.env.NODE_ENV === 'development' && !(window as any).__REDUX_DEVTOOLS_EXTENSION__) {
  middlewares.push(
    createLogger({ level: 'log' }),
  );
}

const rootReducer = createRootReducer(history);

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [
    contactSlice.name,
    accountSlice.name,
    appSlice.name,
    funnelSlice.name,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(middlewares),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
