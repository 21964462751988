/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSearchable } from 'hooks/useSearchable';

export interface WithSearchable<E> {
  searchedEntities: E[];
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}

export default function withSearchable<E, P extends WithSearchable<E>>(
  WrappedComponent: React.ComponentType<P>,
  entitisPropKey: string,
  searchableKey: string,
  debounce = true,
): React.ComponentType<Omit<P, keyof WithSearchable<E>>> {
  const Component: React.FC<Omit<P, keyof WithSearchable<E>>> = (props: Omit<P, keyof WithSearchable<E>>) => {
    // eslint-disable-next-line react/destructuring-assignment
    const searchable = useSearchable<E>(props[entitisPropKey], searchableKey, debounce);
    const searchableProps: WithSearchable<E> = {
      searchedEntities: searchable[0],
      searchQuery: searchable[1],
      setSearchQuery: searchable[2],
    };

    // typescript hack
    const allProps: P = {
      ...searchableProps,
      ...props,
    } as unknown as P;

    return (
      <WrappedComponent {...allProps} />
    );
  };
  return Component;
}
