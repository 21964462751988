import { Classification, Datum, SortSettings, Value } from 'components/TeamComparisonChart/TeamComparisonChart';
import { TeamApi } from 'features/Api';
import { isActivityStatsApiResponse } from 'features/Api/Team/getActivityStats';
import { isPipelineStatsApiResponse } from 'features/Api/Team/getPipelineStats';
import { round } from 'lib/util';
import { parseInt } from 'lodash';
import { getMetricDisplayInfo, MetricName } from 'models/metric';
import { EmailMetrics, isEmailMetricName } from 'models/metric/activityMetrics';
import { isPipelineMetricName, PipelineMetricName } from 'models/metric/pipelineMetrics';
import { PipelineStats } from 'models/pipeline';

const sortData:
  (data: Datum[], sortSettings: SortSettings, higherIsBetter: boolean) => Datum[] = (
    data,
    sortSettings,
    higherIsBetter,
  ) => {
    const { column: primarySortField, direction } = sortSettings;
    const primaryFieldGetter: (d: Datum) => string | number = primarySortField === 'names'
      ? (d): string => d.userName
      : (d): number => d.value.sortValue || 0;
    const primaryMultiplier = primarySortField !== 'names' && !higherIsBetter ? -1 : 1;

    const secondaryFieldGetter: (d: Datum) => string | number = primarySortField === 'names'
      ? (d): number => d.value.sortValue || 0
      : (d): string => d.userName;
    const secondaryMultiplier = primarySortField === 'names' ? 1 : -1;

    const memberValuesNoNulls = data.filter(({ value: { sortValue } }) => sortValue !== null);
    const memberValuesNulls = data.filter(({ value: { sortValue } }) => sortValue === null);
    const sortedNullValues = memberValuesNulls.sort((a, b) => {
      if (a.userName.toLowerCase() < b.userName.toLowerCase()) return -1;
      if (a.userName.toLowerCase() > b.userName.toLowerCase()) return 1;
      return 0;
    });
    let ret = memberValuesNoNulls.sort(
      (a, b) => {
        let aPrimary = primaryFieldGetter(a);
        if (typeof aPrimary === 'string') aPrimary = aPrimary.toLowerCase();

        let bPrimary = primaryFieldGetter(b);
        if (typeof bPrimary === 'string') bPrimary = bPrimary.toLowerCase();

        if (aPrimary === null || bPrimary === null) return 0;

        if (aPrimary < bPrimary) return -1 * primaryMultiplier;
        if (aPrimary > bPrimary) return 1 * primaryMultiplier;
        if (aPrimary === bPrimary) {
          let aSecondary = secondaryFieldGetter(a);
          if (typeof aSecondary === 'string') aSecondary = aSecondary.toLowerCase();

          let bSecondary = secondaryFieldGetter(b);
          if (typeof bSecondary === 'string') bSecondary = bSecondary.toLowerCase();

          if (aSecondary === null || bSecondary === null) return 0;
          if (aSecondary < bSecondary) return -1 * secondaryMultiplier;
          if (aSecondary > bSecondary) return 1 * secondaryMultiplier;
          return 0;
        }
        return 0;
      },
    );
    if (direction === 'descending') ret = ret.reverse();
    return ret.concat(sortedNullValues);
  };

const getMaxValue: (data: Datum[]) => Value = data => {
  if (data.length === 0) {
    return {
      sortValue: null,
      displayValue: 'N/A',
    };
  }
  let max = data[0].value;
  let { sortValue: maxSortValue } = max;

  data.forEach(({ value }) => {
    const { sortValue } = value;
    if (sortValue !== null) {
      if (maxSortValue === null || sortValue > maxSortValue) {
        maxSortValue = sortValue;
        max = value;
      }
    }
  });
  return max;
};

type Indication = 'veryBad' | 'bad' | 'neutral' | 'good' | 'veryGood';

const getIndicationValue = (
  value: number | null,
  averageValue: number | null,
  higherIsBetter: boolean,
): Indication => {
  // if value is >/< avg * multiplier, it is "very over/under" and color should be dark red/green
  const VERY_MULTIPLIER = 1.5;
  if (value === null || averageValue === null) return 'neutral';
  if (value === averageValue) return 'neutral';
  if (value > averageValue) {
    if (value > averageValue * VERY_MULTIPLIER) {
      return higherIsBetter ? 'veryGood' : 'veryBad';
    }
    return higherIsBetter ? 'good' : 'bad';
  }
  // value < averageValue === true
  if (value < averageValue * (VERY_MULTIPLIER - 1)) {
    return higherIsBetter ? 'veryBad' : 'veryGood';
  }
  return higherIsBetter ? 'bad' : 'good';
};

const classificationsMap: Record<Indication, Classification> = {
  veryBad: {
    name: 'Very Below Avg',
    color: '#da2928ff',
  },
  bad: {
    name: 'Below Avg',
    color: '#ff6252',
  },
  neutral: {
    name: 'Average',
    color: '#a5a5a5',
  },
  good: {
    name: 'Above Avg',
    color: '#65ee74',
  },
  veryGood: {
    name: 'Very Above Avg',
    color: '#21ba45ff',
  },
};

const convertApiDataToChartData = (
  membersApiResponse: TeamApi.getMembers.Response,
  statsApiResponse: TeamApi.getActivityStats.Response | TeamApi.getPipelineStats.Response,
  metricName: MetricName,
  metricKey: keyof TeamApi.getActivityStats.Response['emailStats']['teamAvg']
    | keyof TeamApi.getPipelineStats.Response['pipeline']['teamAvg'],
): {
  memberValues: Datum[];
  average: number | null;
} => {
  let teamAvg: EmailMetrics | PipelineStats;
  let eachMembersStats: Record<number, EmailMetrics | PipelineStats>;
  if (isActivityStatsApiResponse(statsApiResponse)) {
    const { emailStats } = statsApiResponse;
    ({ teamAvg, members: eachMembersStats } = emailStats);
  } else if (isPipelineStatsApiResponse(statsApiResponse)) {
    const { pipeline } = statsApiResponse;
    ({ teamAvg, members: eachMembersStats } = pipeline);
  } else throw Error('Wrong response type given');

  const { members } = membersApiResponse;

  const data: Datum[] = [];
  const average = teamAvg[metricKey];
  Object.entries(eachMembersStats).forEach(([userIdStr, memberStats]) => {
    const userId = parseInt(userIdStr, 10);
    const member = members[userId];
    if (member) {
      const name = `${member.firstName} ${member.lastName[0]}.`;
      const value = memberStats[metricKey];
      if (value === null) {
        const percentOfAvg = 'N/A';
        const displayValue = 'N/A';
        data.push({
          userId,
          userName: name,
          value: {
            sortValue: value,
            displayValue,
            percentOfBenchmarkDisplayValue: percentOfAvg,
            classification: {
              name: 'N/A',
              color: '',
            },
          },
        });
      } else {
        let percentOfAvg = 'N/A';
        if (average !== null) {
          const percentOfAvgNum = (value / average) * 100;
          percentOfAvg = `${round(percentOfAvgNum, 1)}%`;
        }
        const { displayValueFn, higherIsBetter } = getMetricDisplayInfo(metricName);
        const displayValue = displayValueFn(value);
        data.push({
          userId,
          userName: name,
          value: {
            sortValue: value,
            displayValue,
            percentOfBenchmarkDisplayValue: percentOfAvg,
            classification: classificationsMap[getIndicationValue(value, average, higherIsBetter)],
          },
        });
      }
    }
  });

  return {
    memberValues: data,
    average,
  };
};

const convertActivityCountToChartData = (
  membersData: TeamApi.getMembers.Response,
  activityData: TeamApi.getActivityStats.Response,
): {
  memberValues: Datum[];
  average: number | null;
} => {
  const {
    activityCounts: {
      members: memberActivityCounts,
      teamAvg: { total: teamAvgActivities },
    },
  } = activityData;
  const { members } = membersData;

  const memberValues: Datum[] = [];
  Object.entries(memberActivityCounts).forEach(([userIdStr, memberCounts]) => {
    const userId = parseInt(userIdStr, 10);
    const member = members[userId];
    if (member) {
      const name = `${member.firstName} ${member.lastName[0]}.`;
      const value = memberCounts.total;
      if (value === null) {
        const percentOfAvg = 'N/A';
        const displayValue = 'N/A';
        memberValues.push({
          userId,
          userName: name,
          value: {
            sortValue: value,
            displayValue,
            percentOfBenchmarkDisplayValue: percentOfAvg,
            classification: {
              name: 'N/A',
              color: '',
            },
          },
          breakdown: memberCounts,
        });
      } else {
        let percentOfAvg = 'N/A';
        if (teamAvgActivities !== null) {
          const percentOfAvgNum = (value / teamAvgActivities) * 100;
          percentOfAvg = `${round(percentOfAvgNum, 1)}%`;
        }
        const displayValue = value.toString(10);
        memberValues.push({
          userId,
          userName: name,
          value: {
            sortValue: value,
            displayValue,
            percentOfBenchmarkDisplayValue: percentOfAvg,
            classification: {
              name: 'N/A',
              color: '',
            },
          },
          breakdown: memberCounts,
        });
      }
    }
  });
  return {
    memberValues,
    average: teamAvgActivities,
  };
};

const getLegend: (metricName: MetricName) => Classification[] = metricName => {
  if (metricName === 'activityBreakdown') {
    return [
      { name: 'Emails', color: '#2185d0' },
      { name: 'Calls', color: '#00b5ad' },
      { name: 'Meetings', color: '#f59ca9' },
      { name: 'Tasks', color: '#44af69' },
    ];
  }
  return [
    { name: 'Below Average', color: '#da2928ff' },
    { name: 'Above Average', color: '#21ba45ff' },
  ];
};

type MetricApiDataHook<MetricType extends MetricName> = (
  metric: MetricType,
  teamId: number,
  startDate: string,
  endDate: string,
  includedRepIds?: number[],
  ) => {
  isFetching: boolean;
  data: Datum[] | undefined;
  averageValue: Value | undefined;
  legend: Classification[];
  higherIsBetter: boolean;
};

export const useGetTeamComparisonData: (
  metricName: MetricName,
  teamId: number,
  startDate: string,
  endDate: string,
  includedRepIds?: number[],
  ) => {
  isFetching: boolean;
  data: Datum[] | undefined;
  averageValue: Value | undefined;
  legend: Classification[];
  higherIsBetter: boolean;
} = (metricName, teamId, startDate, endDate, includedRepIds = []) => {
  const {
    isFetching: membersIsFetching,
    data: membersData,
  } = TeamApi.getMembers.useQuery({ teamId });
  const {
    isFetching: activityDataIsFetching,
    data: activityData,
  } = TeamApi.getActivityStats.useActivityStatsQuery({ teamId, startDate, endDate });
  const {
    isFetching: pipelineDataIsFetching,
    data: pipelineData,
  } = TeamApi.getPipelineStats.useQuery({ teamId, startDate, endDate });
  const averageValue: Value = {
    displayValue: '',
    sortValue: null,
  };
  let isFetching = membersIsFetching || pipelineDataIsFetching || activityDataIsFetching;
  const legend = getLegend(metricName);
  const { displayValueFn, higherIsBetter } = getMetricDisplayInfo(metricName);
  let data: Datum[] | undefined;

  if (isEmailMetricName(metricName)) {
    if (!membersIsFetching && membersData && !activityDataIsFetching && activityData) {
      isFetching = false;
      let converted: { memberValues: Datum[]; average: number | null };
      if (metricName === 'activityBreakdown') {
        converted = convertActivityCountToChartData(membersData, activityData);
      } else {
        converted = convertApiDataToChartData(
          membersData,
          activityData,
          metricName,
          metricName,
        );
      }
      const { memberValues, average: convertedAvg } = converted;
      data = memberValues;
      if (convertedAvg !== null) {
        averageValue.sortValue = convertedAvg;
        averageValue.displayValue = displayValueFn(convertedAvg);
      }
    }
  }
  if (isPipelineMetricName(metricName)) {
    const metricNameToApiResponseKeyMap: Record<PipelineMetricName, keyof PipelineStats> = {
      pipelineCreated: 'created',
      pipelineLost: 'lost',
      pipelineOpen: 'open',
      pipelineWon: 'won',
    };

    if (!membersIsFetching && membersData && !pipelineDataIsFetching && pipelineData) {
      const { memberValues, average: convertedAvg } = convertApiDataToChartData(
        membersData,
        pipelineData,
        metricName,
        metricNameToApiResponseKeyMap[metricName],
      );
      data = memberValues;
      if (convertedAvg !== null) {
        averageValue.sortValue = convertedAvg;
        averageValue.displayValue = displayValueFn(convertedAvg);
      }
    }
  }

  if (data !== undefined && includedRepIds && includedRepIds.length) {
    data = data.filter(d => includedRepIds.includes(d.userId));
  }

  return {
    isFetching,
    data,
    averageValue,
    legend,
    higherIsBetter,
  };
};

export {
  sortData,
  getMaxValue,
  convertApiDataToChartData,
  convertActivityCountToChartData,
  classificationsMap,
};
