import React, { FC, ReactElement } from 'react';
import { SortSettings } from 'components/TeamComparisonChart/TeamComparisonChart';
import { Tab } from 'semantic-ui-react';
import { TeamGoalData } from 'features/Api/Goals';
import GoalTeamComparison from '../GoalTeamComparison';
import GoalChartHistoric from '../GoalChartHistoric';


const GoalModalContent: FC<{
  goalData: TeamGoalData;
  teamId: number;
  includedRepIds?: number[];
  sortSettings: SortSettings | undefined;
  displayAsPercentage: boolean;
  setDisplayAsPercentage: (val: boolean) => void;
}> = ({
  goalData: initialGoalData,
  teamId,
  includedRepIds,
  sortSettings,
  displayAsPercentage,
  setDisplayAsPercentage,
}): ReactElement => {
  const overviewContent = (
    <GoalTeamComparison
      goalData={initialGoalData}
      teamId={teamId}
      includedRepIds={includedRepIds}
      sortSettings={sortSettings}
      displayAsPercentage={displayAsPercentage}
      setDisplayAsPercentage={setDisplayAsPercentage}
    />
  );

  const panes = [
    {
      menuItem: 'Overview',
      render: (): ReactElement => overviewContent,
    },
    {
      menuItem: 'Historical',
      render: (): ReactElement => (
        <GoalChartHistoric
          goalId={initialGoalData.goal.id}
          teamId={teamId}
          periodIndex={initialGoalData.period.index}
        />
      ),
    },
  ];

  return <Tab panes={panes} menu={{ secondary: true, pointing: true }} />;
};

export default GoalModalContent;
