import React, { ReactElement, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { Popup, SemanticWIDTHS, Table, Menu, Pagination, PaginationProps, Icon } from 'semantic-ui-react';
import { capitalize } from 'lodash';

import { ProspectingContact } from 'features/Api/Prospecting';
import { User } from 'models/user';

import { recommendationDetailsConfig } from '../ProspectingContactDetailModal/ContactRecommendations/RecommendationCard';
import css from './styles.module.css';

type SomeKeysOfObject<T, U extends keyof T> = U;

type Column = SomeKeysOfObject<
  ProspectingContact,
  'name' |
  'ownerId' |
  'numRecommendations' |
  'prospectingPhase' |
  'isActive' |
  'isNew' |
  'activityOwnerIds'
>;

const initialSortDirection: Record<Column, SortDirection> = {
  name: 'ascending',
  ownerId: 'ascending',
  activityOwnerIds: 'descending',
  numRecommendations: 'descending',
  prospectingPhase: 'ascending',
  isNew: 'descending',
  isActive: 'descending',
} as const;

type SortDirection = 'ascending' | 'descending';

type SortSettings = {
  column: Column;
  direction: SortDirection;
};

const getInitialSortSettings = (): SortSettings => ({
  column: 'numRecommendations',
  direction: 'descending',
});

const reverseDirection = (direction: SortDirection): SortDirection => {
  if (direction === 'ascending') return 'descending';
  return 'ascending';
};

const getNewSortSettings = (prev: SortSettings, columnClicked: Column): SortSettings => {
  const { column, direction } = prev;

  let newDirection = initialSortDirection[columnClicked];
  if (column === columnClicked) newDirection = reverseDirection(direction);

  return {
    column: columnClicked,
    direction: newDirection,
  };
};

interface HeaderCallback {
  (prev: SortSettings): void;
}

interface CallbackConstructor {
  (column: Column): HeaderCallback;
}

interface SortFn {
  (contacts: ProspectingContact[], sortSettings: SortSettings): ProspectingContact[];
}

const sortContacts: SortFn = (contacts, sortSettings) => {
  const { column, direction } = sortSettings;
  const sorted = contacts.slice().sort((a, b) => {
    let valA = a[column];
    let valB = b[column];
    if (column === 'numRecommendations') {
      valA = a.recommendations?.length || 0;
      valB = b.recommendations?.length || 0;
    }
    if (typeof valA === 'number' && typeof valB === 'number') return valA - valB;
    if (typeof valA === 'string' && typeof valB === 'string') return valA.localeCompare(valB);
    if (typeof valA === 'boolean' && typeof valB === 'boolean') {
      const isSame = (valA === valB);
      const trueFirst = (valA ? -1 : 1);
      return isSame ? 0 : trueFirst;
    }
    /**
     * Code will never reach this point, because (at least at time of commit):
     *   - valA & valB will always be the same type
     *   - valA & valB are only of type number or string
     */
    return 0;
  });
  if (direction === 'descending') return sorted.reverse();
  return sorted;
};

const TableHeaderCell = (props: {
  title: string;
  column: Column;
  sortSettings: SortSettings;
  callbackConstructor: CallbackConstructor;
  width?: SemanticWIDTHS;
  className?: string;
}): ReactElement => {
  const {
    title,
    column,
    sortSettings,
    callbackConstructor,
    width,
    className,
  } = props;
  const {
    column: sortedColumn,
    direction,
  } = sortSettings;

  const sorted = sortedColumn === column ? direction : undefined;

  return (
    <Table.HeaderCell
      onClick={callbackConstructor(column)}
      width={width}
      className={className}
      sorted={sorted}
    >
      {title}
    </Table.HeaderCell>
  );
};

TableHeaderCell.defaultProps = {
  width: undefined,
  className: undefined,
};

export interface ProspectingContactTableProps {
  contacts: ProspectingContact[];
  owners: User[];
}

const getPagination = (onPageChange, totalPages, activePage): React.ReactNode => {
  if (totalPages <= 1) { return null; }
  return (
    <Menu pagination>
      <Pagination
        firstItem={{ content: <Icon name="angle double left" />, icon: true }}
        lastItem={{ content: <Icon name="angle double right" />, icon: true }}
        prevItem={{ content: <Icon name="angle left" />, icon: true }}
        nextItem={{ content: <Icon name="angle right" />, icon: true }}
        totalPages={totalPages}
        activePage={activePage}
        onPageChange={onPageChange}
      />
    </Menu>
  );
};

export const ProspectingContactTable = (props: ProspectingContactTableProps): ReactElement => {
  const { contacts, owners } = props;
  const [sortSettings, setSort] = useState(getInitialSortSettings());
  const callbackConstructor: CallbackConstructor = column => (): void => {
    setSort(prev => getNewSortSettings(prev, column));
  };
  const [activePage, setActivePage] = useState<number>(1);
  const ROWS_PER_PAGE = 100;
  const tableHeader = (
    <Table.Header className={css.tableHeader}>
      <Table.Row>
        <TableHeaderCell
          column="name"
          title="Name"
          sortSettings={sortSettings}
          callbackConstructor={callbackConstructor}
        />
        <TableHeaderCell
          column="ownerId"
          title="Owner"
          sortSettings={sortSettings}
          callbackConstructor={callbackConstructor}
        />
        <TableHeaderCell
          column="activityOwnerIds"
          title="Reps with activity"
          sortSettings={sortSettings}
          callbackConstructor={callbackConstructor}
        />
        <TableHeaderCell
          column="numRecommendations"
          title="Recommendations"
          sortSettings={sortSettings}
          callbackConstructor={callbackConstructor}
        />
        <TableHeaderCell
          column="prospectingPhase"
          title="Phase"
          sortSettings={sortSettings}
          callbackConstructor={callbackConstructor}
        />
        <TableHeaderCell
          column="isNew"
          title="New/Existing"
          sortSettings={sortSettings}
          callbackConstructor={callbackConstructor}
        />
        <TableHeaderCell
          column="isActive"
          title="Status"
          sortSettings={sortSettings}
          callbackConstructor={callbackConstructor}
        />
      </Table.Row>
    </Table.Header>
  );
  const sortedContacts = sortContacts(contacts, sortSettings);

  if (sortedContacts.length === 0) {
    return (
      <Table sortable compact basic="very">
        {tableHeader}
        <Table.Body>
          <Table.Row>
            <Table.HeaderCell colSpan="7" style={{ textAlign: 'center' }}>
              no rows found
            </Table.HeaderCell>
          </Table.Row>
        </Table.Body>
        <Table.Footer className={css.containerPagination} />
      </Table>
    );
  }

  const startSlice = ROWS_PER_PAGE * (activePage - 1);
  const endSlice = ROWS_PER_PAGE * activePage;
  const tableData: ProspectingContact[] = sortedContacts.slice(startSlice, endSlice);
  let totalPages = 0;
  if (sortedContacts && tableData[0]) {
    totalPages = Math.ceil(sortedContacts.length / ROWS_PER_PAGE);
  }
  if (totalPages && activePage > totalPages) {
    setActivePage(1);
  }
  const onPageChange = (_, paginationData: PaginationProps): void => {
    let pageNum: number;
    if (typeof paginationData.activePage === 'string') {
      pageNum = Number.parseInt(paginationData.activePage, 10);
    } else {
      pageNum = paginationData.activePage || 1;
    }
    setActivePage(pageNum);
  };
  const pagination = getPagination(onPageChange, totalPages, activePage);
  const rows = tableData.map(a => {
    const {
      id,
      name,
      recommendations,
      ownerId,
      activityOwnerIds,
      prospectingPhase,
      isNew,
      isActive,
    } = a;
    const owner = owners.find(o => o.id === ownerId);
    let ownerNode: ReactNode = null;
    if (owner) {
      if (owner.isTracked) {
        ownerNode = (<Link to={`/app/team/${owner.id}`}>{owner.name}</Link>);
      } else {
        ownerNode = owner.name;
      }
    }
    const qs = isActive ? '?status=active' : '?status=inactive';

    let recommendationNode: React.ReactNode = 0;
    if (recommendations?.length > 0) {
      const recommendationPopupContent = capitalize(
        recommendations?.map(r => recommendationDetailsConfig[r]?.name).join(', '),
      );
      recommendationNode = (
        <Popup
          basic
          content={recommendationPopupContent}
          trigger={(<div>{recommendations.length}</div>)}
        />
      );
    }

    const displayPhase = prospectingPhase === 'pre conversation' ? 'Pre Conversation' : 'Conversation';

    return (
      <Table.Row key={`contact-row-${id}`}>
        <Table.Cell className={css.name}>
          <Link to={(location): string => `${location.pathname}/${id}${qs}`}>
            {name}
          </Link>
        </Table.Cell>
        <Table.Cell className={css.owner}>
          {ownerNode}
        </Table.Cell>
        <Table.Cell className={css.ownerIds}>
          {activityOwnerIds?.length || 0}
        </Table.Cell>
        <Table.Cell className={css.recommendations}>
          {recommendationNode}
        </Table.Cell>
        <Table.Cell className={css.prospectingPhase}>
          {displayPhase}
        </Table.Cell>
        <Table.Cell className={css.isNew}>
          {isNew ? 'new' : 'existing'}
        </Table.Cell>
        <Table.Cell className={css.isNew}>
          {isActive ? 'working' : 'inactive'}
        </Table.Cell>
      </Table.Row>
    );
  });
  return (
    <Table sortable compact basic="very">
      {tableHeader}
      <Table.Body>
        {rows}
      </Table.Body>
      <Table.Footer className={css.containerPagination}>
        <Table.Row>
          <Table.HeaderCell colSpan="7" style={{ textAlign: 'center' }}>
            {pagination}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default ProspectingContactTable;
