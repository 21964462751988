import { constructQueryString } from 'lib/util';
import { MetricName } from 'models/metric';

import baseApi from '../apiSlice';
import { ApiResponse } from '../apiTypes';

export type ActivityMetricName = 'totalActivityCount' | 'inbountCount' | 'outboundCount' | 'mailCount' |
  'meetingCount' | 'callCount' | 'tasksCount' | 'smsCount' | 'linkedinCount' | 'prospectingCount' |
  'accountsEngaged' | 'contactsEngaged' | 'newAccounts' | 'newContacts' | 'newConversations' |
  'avgDailyActivities' | 'avgWordCount' | 'avgGradeLevel' | 'avgRepFollowUp' | 'avgRepResponse' | 'avgProspectResponse';

export const activityMetricNames: Array<ActivityMetricName> = [
  'totalActivityCount',
  'inbountCount',
  'outboundCount',
  'mailCount',
  'meetingCount',
  'callCount',
  'tasksCount',
  'smsCount',
  'linkedinCount',
  'prospectingCount',
  'accountsEngaged',
  'contactsEngaged',
  'newAccounts',
  'newContacts',
  'newConversations',
  'avgDailyActivities',
  'avgWordCount',
  'avgGradeLevel',
  'avgRepFollowUp',
  'avgRepResponse',
  'avgProspectResponse',
];

type MetricToStatMap = {
  [x in MetricName]?: ActivityMetricName;
};

export const metricnameToActivityStatnameMap: MetricToStatMap = {
  activityBreakdown: 'totalActivityCount',
  avgActivitiesPerDay: 'avgDailyActivities',
  accountsEngaged: 'accountsEngaged',
  contactsEngaged: 'contactsEngaged',
  avgWordCount: 'avgWordCount',
  avgGradeLevel: 'avgGradeLevel',
  avgRepFollowUp: 'avgRepFollowUp',
  avgRepResponse: 'avgRepResponse',
  avgProspectResponse: 'avgProspectResponse',
};

export interface DataPoint {
  x: number;
  y: number;
}
export interface MappedDataSet {
  [ownerId: number]: {
    label: string;
    data: Array<DataPoint>;
  };
  target?: {
    label: string;
    data: Array<DataPoint>;
  };
}

export type MetricGroupByOptions = 'day' | 'week' | 'month' | 'quarter';

export interface MetricOptions {
  userId?: number | number[];
  teamId?: number;
  groupBy?: MetricGroupByOptions;
}

export interface MetricQuery {
  metricName: ActivityMetricName;
  startDate: string;
  endDate: string;
  opts: MetricOptions;
}
export interface MetricResponse extends ApiResponse {
  metricName: ActivityMetricName;
  data: MappedDataSet;
}

const activityApi = baseApi.injectEndpoints({
  refetchOnMountOrArgChange: true,
  endpoints: builder => ({
    getMetricOverTime: builder.query<MetricResponse, MetricQuery>({
      query: ({ metricName, startDate, endDate, opts }) => {
        const queryString = constructQueryString(opts);
        return `activity/stats/${metricName}/${startDate}-${endDate}${queryString}`;
      },
    }),
  }),
});

export default activityApi;
