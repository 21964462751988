import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { push } from 'connected-react-router';
import { motion, Variants } from 'framer-motion';
import { Icon, Image, Label, Menu, SemanticICONS } from 'semantic-ui-react';

import AccessControl, { Permission } from 'components/AccessControl';
import { Recommendation } from 'models/recommendation';
import { getIsProspecting } from 'selectors/team';

import allActions, { bindCombinedActions } from '../../../../actions';
import logo from '../../../../assets/logo.svg';
import logogram from '../../../../assets/logogram.svg';

import css from './AppMenu.module.css';

const menuAnimation: Variants = {
  closed: {
    width: 86,
    transition: {
      when: 'beforeChildren',
      duration: 0.1,
    },
  },
  open: {
    width: 250,
    transition: {
      when: 'beforeChildren',
      duration: 0.1,
    },
  },
};

const logoAnimation: Variants = {
  show: {
    opacity: 1,
    display: 'flex',
    transition: { duration: 0.2 },
  },
  hide: {
    opacity: 1,
    display: 'none',
    transition: { duration: 0.2 },
  },
};

interface Props {
  actions: any;
  features: any;
  navigate: Function;
  onMinimizeToggle: () => void;
  minimized: boolean;
  isMobile: boolean;
  isLoadingTeamSettings: boolean;
  isSuperAdmin: boolean;
  selectedTeamId: number;
  recommendationList: Array<Recommendation>;
  isProspecting: boolean;
}

interface State {}

interface MenuItem {
  icon?: any;
  label: string;
  path: string;
  exact?: boolean;
  permission?: Permission;
  beta?: boolean;
}

const PATHS = {
  account: '/app/account',
  alerts: '/app/alerts',
  home: '/app',
  pipeline: '/app/pipeline',
  prospecting: '/app/prospecting',
  recommendations: '/app/recommendations',
  tags: '/app/tags',
  team: '/app/team',
};

class AppMenu extends Component<Props, State> {
  handleLogout = (): void => {
    const { actions } = this.props;
    actions.app.logout();
  };

  handleRedirectHome = (): void => {
    const { navigate } = this.props;
    navigate('/');
  }

  toggleExpand = (): void => {
    const { onMinimizeToggle } = this.props;
    onMinimizeToggle();
  };

  renderMenuLink = (menuItem: MenuItem): React.ReactNode => {
    const { minimized } = this.props;
    const { icon, label, path, exact, permission, beta } = menuItem;
    const menuComponent = (
      <Menu.Item key={label} className={css.menuItem} as={NavLink} to={path} exact={exact}>
        {icon ? (<Icon color="grey" name={icon} />) : null}
        {!minimized && label}
        {beta ? (<Label className={minimized ? css.min : ''}>beta</Label>) : null}
      </Menu.Item>
    );
    if (permission) {
      return (
        <AccessControl key={label} accessingTo={permission}>
          {menuComponent}
        </AccessControl>
      );
    }
    return menuComponent;
  };

  render(): React.ReactNode {
    const {
      features,
      minimized,
      isMobile,
      recommendationList,
      isProspecting,
      isLoadingTeamSettings,
      selectedTeamId,
    } = this.props;

    const menuLinks: MenuItem[] = [
      { icon: 'home', label: 'Home', path: PATHS.home, exact: true },
      { icon: 'lightbulb', label: 'Recommendations', path: PATHS.recommendations },
      { icon: 'alarm', label: 'Alerts', path: PATHS.alerts },
      { icon: 'building', label: 'Account Health', beta: true, path: PATHS.account },
      { icon: 'chart bar', label: 'Pipeline', path: PATHS.pipeline },
      { icon: 'tag', label: 'Tag Management', path: PATHS.tags, permission: 'manager' },
    ];

    // must remove pipeline & accountHealth before removing recommendation
    if (isProspecting) {
      const prospectingMenuItem = { icon: 'binoculars', label: 'Prospecting Health', beta: true, path: PATHS.prospecting };
      menuLinks.splice(3, 2, prospectingMenuItem);
    } else if (!isProspecting && (features?.accountHealthPage !== true)) {
      menuLinks.splice(3, 1);
    }
    if (!(recommendationList?.length > 0)) {
      menuLinks.splice(1, 1); // remove recommendation
    }
    if (!selectedTeamId || isLoadingTeamSettings) {
      menuLinks.splice(1, menuLinks.length - 1);
    }

    const className = minimized ? `${css.container} ${css.minimized}` : `${css.container} ${css.expanded}`;

    return (
      <motion.div
        initial={minimized ? 'closed' : 'open'}
        animate={minimized ? 'closed' : 'open'}
        variants={menuAnimation}
        className={className}
      >
        <Menu fluid borderless compact vertical className={css.menu} icon={minimized ? 'labeled' : undefined}>
          <Menu.Item header className={css.header} onClick={this.handleRedirectHome}>
            <motion.div
              variants={logoAnimation}
              animate={minimized ? 'show' : 'hide'}
            >
              <Image src={logogram} className={css.appLogogram} />
            </motion.div>
            <motion.div
              variants={logoAnimation}
              animate={minimized ? 'hide' : 'show'}
            >
              <Image src={logo} className={css.appLogo} />
            </motion.div>
          </Menu.Item>
          {menuLinks.map(menuItem => (this.renderMenuLink(menuItem)))}
        </Menu>
        {!isMobile && <AppMenuMinimizeContainer handleToggleMinimize={this.toggleExpand} minimized={minimized} />}
      </motion.div>
    );
  }
}

export default connect(
  (state: any) => ({
    isSuperAdmin: state.app.isSuperAdmin,
    isLoadingTeamSettings: state.team.isLoadingTeamSettings,
    selectedTeamId: state.team.selectedTeamId,
    recommendationList: state.recommendation.recommendationList,
    features: state.organization.features,
    isProspecting: getIsProspecting(state),
  }),
  dispatch => ({
    actions: bindCombinedActions(allActions, dispatch),
    navigate: bindActionCreators((path: string) => push(path), dispatch),
  }),
)(AppMenu);

interface AppMenuMinimizeButtonProps {
  handleToggleMinimize: () => void;
  minimized: boolean;
}

const AppMenuMinimizeButton: React.FC<AppMenuMinimizeButtonProps> = ({ handleToggleMinimize, minimized }) => {
  const iconName = `chevron ${minimized ? 'right' : 'left'}` as SemanticICONS;

  return (
    <div className={`${css.minimizeButton}`} onClick={handleToggleMinimize}>
      <Icon size="small" className={css.minimizeIcon} name={iconName} title="minimize" />
    </div>
  );
};

const AppMenuMinimizeContainer: React.FC<AppMenuMinimizeButtonProps> = ({ handleToggleMinimize, minimized }) => (
  <div className={`${css.appMenuminimizeContainer} ${minimized ? css.appMenuminimizeContainerMinizied : ''}`}>
    <AppMenuMinimizeButton {...{ handleToggleMinimize, minimized }} />
  </div>
);
