import baseApi from '../apiSlice';
import { ApiResponse } from '../apiTypes';

export interface Query {
  teamId: number;
}

export interface Response extends ApiResponse {
  members: {
    [userId: number]: {
      id: number;
      organizationId: number;
      teamId: number;
      firstName: string;
      lastName: string;
    } | undefined;
  };
}

const teamActivitiesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTeamMembers: builder.query<Response, Query>({
      query: ({ teamId }) => `team/${teamId}/members`,
      keepUnusedDataFor: 3600, // 1 hour
    }),
  }),
});

export const { useQuery } = teamActivitiesApi.endpoints.getTeamMembers;
