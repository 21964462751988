import { createActions } from 'redux-actions';

import api from '../lib/api';
import { handleError } from '.';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const actions = createActions({
  ALERT: {
    CLEAR_ALERT_RULE: null,
    FETCH_ALERT_NOTIFICATIONS_REQUEST: null,
    FETCH_ALERT_NOTIFICATIONS_SUCCESS: notifications => ({ notifications }),
    FETCH_ALERT_NOTIFICATIONS_FAILURE: null,
    NEW_SIDEBAR_ALERT_NOTIFICATIONS: notifications => ({ notifications }),
    FETCH_SIDEBAR_ALERT_NOTIFICATIONS_REQUEST: null,
    FETCH_SIDEBAR_ALERT_NOTIFICATIONS_SUCCESS: notifications => ({ notifications }),
    FETCH_SIDEBAR_ALERT_NOTIFICATIONS_FAILURE: null,
    FETCH_ALERT_RULES_REQUEST: null,
    FETCH_ALERT_RULES_SUCCESS: rules => ({ rules }),
    FETCH_ALERT_RULES_FAILURE: null,
    FETCH_ALERT_RULE_REQUEST: null,
    FETCH_ALERT_RULE_SUCCESS: rule => ({ rule }),
    FETCH_ALERT_RULE_FAILURE: null,
    SAVE_ALERT_RULE_REQUEST: null,
    SAVE_ALERT_RULE_SUCCESS: rule => ({ rule }),
    SAVE_ALERT_RULE_FAILURE: errors => ({ errors }),
    PAUSE_ALERT_RULE_REQUEST: null,
    PAUSE_ALERT_RULE_SUCCESS: rule => ({ rule }),
    PAUSE_ALERT_RULE_FAILURE: errors => ({ errors }),
    UPDATE_ALERT_NOTIFICATION_REQUEST: null,
    UPDATE_ALERT_NOTIFICATION_SUCCESS: notification => ({ notification }),
    UPDATE_ALERT_NOTIFICATION_FAILURE: msj => ({ msj }),
    DELETE_ALERT_RULE_REQUEST: null,
    DELETE_ALERT_RULE_SUCCESS: id => ({ id }),
    DELETE_ALERT_RULE_FAILURE: msj => ({ msj }),
  },
});
/* eslint-enable @typescript-eslint/explicit-function-return-type */

export const fetchSidebarAlertNotifications = (opts: any = {}):
  Function => async (dispatch, getState): Promise<any> => {
  const state = getState();
  const { selectedTeamId } = state.team;
  if (!selectedTeamId) {
    return undefined;
  }

  dispatch(actions.alert.fetchSidebarAlertNotificationsRequest());
  const response = await api.getAlertNotifications(selectedTeamId, opts);

  if (response.status) {
    if (!opts.offset || opts.offset === 0) {
      dispatch(actions.alert.newSidebarAlertNotifications(response.notifications, opts));
    } else {
      dispatch(actions.alert.fetchSidebarAlertNotificationsSuccess(response.notifications, opts));
    }
  } else {
    handleError(response, dispatch);
    dispatch(actions.alert.fetchSidebarAlertNotificationsFailure('There was an error fetching alert notifications'));
  }
  return response;
};

export const fetchAlertNotifications = (): Function => async (dispatch, getState): Promise<any> => {
  const state = getState();
  const { selectedTeamId } = state.team;
  if (!selectedTeamId) {
    return undefined;
  }
  dispatch(actions.alert.fetchAlertNotificationsRequest());
  const response = await api.getAlertNotifications(selectedTeamId);
  if (response.status) {
    dispatch(actions.alert.fetchAlertNotificationsSuccess(response.notifications));
  } else {
    handleError(response, dispatch);
    dispatch(actions.alert.fetchAlertNotificationsFailure('There was an error fetching alert notifications'));
  }
  return response;
};

export const fetchAlertRule = (alertRuleId: string): Function => async (dispatch, getState): Promise<any> => {
  const state = getState();
  const { selectedTeamId } = state.team;
  if (!selectedTeamId) {
    return undefined;
  }
  dispatch(actions.alert.fetchAlertRuleRequest());
  const response = await api.getAlertRule(alertRuleId);
  if (response.status) {
    dispatch(actions.alert.fetchAlertRuleSuccess(response.rule));
  } else {
    handleError(response, dispatch);
    dispatch(actions.alert.fetchAlertRuleFailure('There was an error fetching the alert rule'));
  }
  return response;
};

export const fetchAlertRules = (): Function => async (dispatch, getState): Promise<any> => {
  const state = getState();
  const { selectedTeamId } = state.team;
  if (!selectedTeamId) {
    return undefined;
  }
  dispatch(actions.alert.fetchAlertRulesRequest());
  const response = await api.getAlertRules(selectedTeamId);
  if (response.status) {
    dispatch(actions.alert.fetchAlertRulesSuccess(response.rules));
  } else {
    handleError(response, dispatch);
    dispatch(actions.alert.fetchAlertRulesFailure('There was an error fetching alert rules'));
  }
  return response;
};

export const saveAlertRule = (alertToSave): Function => async (dispatch, getState): Promise<any> => {
  const state = getState();
  const { selectedTeamId } = state.team;
  if (!selectedTeamId) {
    return undefined;
  }
  dispatch(actions.alert.saveAlertRuleRequest());
  const response = await api.saveAlertRule(selectedTeamId, alertToSave);
  if (response.status) {
    dispatch(actions.alert.saveAlertRuleSuccess(response.rule));
  } else {
    handleError(response, dispatch);
    dispatch(actions.alert.saveAlertRuleFailure(response.errors));
  }
  return response;
};

export const pauseAlertRule = (ruleId, paused): Function => async (dispatch): Promise<any> => {
  dispatch(actions.alert.pauseAlertRuleRequest());
  const response = await api.pauseAlertRule(ruleId, paused);
  if (response.status) {
    dispatch(actions.alert.pauseAlertRuleSuccess(response.rule));
  } else {
    handleError(response, dispatch);
    dispatch(actions.alert.pauseAlertRuleFailure(response.errors));
  }
};

export const deleteAlertRule = (id): Function => async (dispatch): Promise<void> => {
  try {
    const response = await api.deleteAlertRule(id);
    if (response.status) {
      dispatch(actions.alert.deleteAlertRuleSuccess(response.id));
    } else {
      handleError(response.msg, dispatch);
      dispatch(actions.alert.deleteAlertRuleFailure(response.msg));
    }
  } catch (error) {
    handleError(error, dispatch);
    dispatch(actions.alert.deleteAlertRuleFailure((error as Error).message));
  }
};

export const markSeenNotifications = (ids: Array<number>): Function => async (dispatch): Promise<any> => {
  const response = await api.batchUpdateNotifications('seen', ids);
  if (response.status) {
    dispatch(fetchAlertNotifications());
  } else {
    handleError(response, dispatch);
  }
};

export const batchUpdateAllNotificationsInAlertRule = (id, action): Function => async (dispatch): Promise<any> => {
  dispatch(actions.alert.saveAlertRuleRequest());
  const response = await api.batchUpdateNotificationsByRuleId(action, id);
  if (response.status) {
    dispatch(fetchAlertNotifications());
  } else {
    handleError(response, dispatch);
    dispatch(actions.alert.saveAlertRuleFailure(response.errors));
  }
};

export const updateAlertNotification = (id: number, body: any): Function => async (dispatch): Promise<any> => {
  try {
    const response = await api.updateAlertNotification(id, body);
    if (response.status) {
      dispatch(actions.alert.updateAlertNotificationSuccess(response.notification));
    } else {
      handleError(response.msg, dispatch);
      dispatch(actions.alert.updateAlertNotificationFailure(response.msg));
    }
  } catch (error) {
    handleError(error, dispatch);
    dispatch(actions.alert.updateAlertNotificationFailure((error as Error).message));
  }
};

const alertActions = {
  alert: {
    ...actions.alert,
    deleteAlertRule,
    fetchAlertNotifications,
    fetchSidebarAlertNotifications,
    fetchAlertRule,
    fetchAlertRules,
    pauseAlertRule,
    batchUpdateAllNotificationsInAlertRule,
    saveAlertRule,
  },
};

export default alertActions;
