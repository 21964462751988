import { createActions } from 'redux-actions';
import { push, replace } from 'connected-react-router';

import { setFunnelFilter, setFunnelOwnerIds } from 'features/Funnel/funnelSlice';
import api from '../lib/api';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const actions = createActions({
  APP: {
    HAS_LOADED: undefined,
    CURRENT_USER_SUCCESS: user => ({ user }),
    CURRENT_USER_FAILURE: undefined,
    CONSENT_REQUEST: undefined,
    CONSENT_SUCCESS: undefined,
    CONSENT_FAILURE: undefined,
    LOGIN_REQUEST: undefined,
    LOGIN_SUCCESS: (userId: string, instanceUrl: string) => ({ userId, instanceUrl }),
    LOGIN_FAILURE: (msg: string) => ({ msg }),
    USER_LOGGED_OUT: (msg: string) => ({ msg }),
    LOGOUT_SUCCESS: undefined,
    LOGOUT_FAILURE: undefined,
    SET_INSTANCE_URL: (instanceUrl: string) => ({ instanceUrl }),
    SET_ORIGINAL_USER_EMAIL: (originalUserEmail: string) => ({ originalUserEmail }),
    SYNC_ACTIVITY_REQUEST: undefined,
    SYNC_ACTIVITY_SUCCESS: undefined,
    SYNC_ACTIVITY_FAILURE: undefined,
  },
});
/* eslint-enable @typescript-eslint/explicit-function-return-type */

export const login = () => async (): Promise<void> => {
  window.location.href = api.getSalesforceLoginUrl();
};

export const logout = (message = '') => async (dispatch: Function): Promise<void> => {
  const response = await api.logout();
  if (response.status) {
    dispatch(actions.app.logoutSuccess());
    if ((window as any).heap) {
      (window as any).heap.resetIdentity();
    }
  } else {
    // TODO: log an error here
    dispatch(actions.app.logoutFailure());
  }
  if (message) {
    dispatch(actions.app.userLoggedOut(message));
  }
  // navigate back home regardless
  dispatch(push('/'));
};

export const getCurrentUser = (path = '') => async (dispatch: Function): Promise<any> => {
  const response = await api.getCurrentUser();
  if (response.status) {
    dispatch(actions.app.currentUserSuccess(response.user));
    const { tosConsented } = response.user;
    if (!tosConsented) {
      dispatch(replace('/consent'));
    } else if (path === '' || path === '/') {
      dispatch(replace('/app'));
    } else {
      dispatch(replace(path));
    }
  } else {
    dispatch(actions.app.currentUserFailure());
    dispatch(logout());
  }
  return response;
};

export const auth = (code: string) => async (dispatch: Function): Promise<any> => {
  dispatch(actions.app.loginRequest());
  const response = await api.getAuthToken(code);
  if (response.status) {
    dispatch(actions.app.loginSuccess(response.userId, response.instanceUrl));
    dispatch(getCurrentUser());
    // apply all tag rules
    api.applyAllTagRules();
  } else {
    dispatch(actions.app.loginFailure('There was an error in verifying your account.'));
    dispatch(replace('/'));
  }
  return response;
};

export const checkLoggedIn = (path: string) => async (dispatch: Function): Promise<any> => {
  dispatch(actions.app.loginRequest());
  const response = await api.checkStatus();
  if (response.status) {
    dispatch(actions.app.loginSuccess(response.userId));
    dispatch(getCurrentUser(path));
    dispatch(actions.app.setInstanceUrl(response.instanceUrl));
  } else {
    dispatch(logout);
  }
  dispatch(actions.app.hasLoaded());
  return response;
};

export const consent = () => async (dispatch: Function): Promise<void> => {
  const response = await api.setConsent();
  if (response) {
    dispatch(actions.app.consentSuccess());
    dispatch(replace('/app'));
  } else {
    dispatch(actions.app.consentFailure());
  }
};

export const authAs = (userId: string, source: string) => async (
  dispatch: Function,
  getState: Function,
): Promise<void> => {
  const originalUserEmail = getState().app.email;
  const response = await api.getAuthAs(userId, source);
  if (response.status) {
    dispatch(actions.app.logoutSuccess());
    if ((window as any).heap) {
      (window as any).heap.resetIdentity();
    }
    dispatch(actions.app.loginSuccess(response.userId, response.instanceUrl));
    dispatch(actions.app.setOriginalUserEmail(originalUserEmail));
    dispatch(getCurrentUser());
    dispatch(setFunnelOwnerIds([]));
    dispatch(setFunnelFilter({
      type: [],
      industry: [],
      country: [],
      state: [],
      leadSource: [],
      custom: [],
    }));
  } else {
    dispatch(actions.app.loginFailure('There was an error in verifying your account.'));
    dispatch(replace('/'));
  }
};

const appActions = {
  app: {
    ...actions.app,
    auth,
    checkLoggedIn,
    consent,
    login,
    logout,
    authAs,
  },
};

export default appActions;
