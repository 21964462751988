import React, { ReactElement, ReactNode } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CallHistoryMethodAction, push } from 'connected-react-router';
import { Icon, Loader, Modal, StrictModalProps } from 'semantic-ui-react';

import ContactRecommendationsGroup
  from 'App/Main/ProspectingHealthRoute/ProspectingContactDetailModal/ContactRecommendations';
import { ProspectingContact, ProspectingMetric } from 'features/Api/Prospecting';
import { User } from 'models/user';
import { getSelectedTeamId } from 'selectors/team';
import { AccountApi, prospectingApi } from 'features/Api';
import { Response } from 'features/Api/Account/getAccount';

import css from './styles.module.css';

interface QueryParams {
  status?: 'active' | 'inactive';
}

const ModalInner = (props: {
  contact: ProspectingContact;
  account?: Response['account'];
  metrics: ProspectingMetric[];
  owners: User[];
  closeIconOnClick?: (...args: any[]) => void;
}): ReactElement => {
  const { account, contact, owners, metrics, closeIconOnClick } = props;
  const { name, prospectingPhase, isActive, isNew, recommendations, activityOwnerIds } = contact;

  let closeIcon = <></>;
  if (closeIconOnClick !== undefined) {
    closeIcon = <Icon name="x" onClick={closeIconOnClick} className={`${css.floatRight} ${css.pointer}`} />;
  }

  const ownerNodes = activityOwnerIds?.map(oId => {
    const owner = owners.find(o => o.id === oId);
    let ownerNode: ReactNode = null;
    if (owner?.isTracked) {
      ownerNode = (<Link to={`/app/team/${owner.id}`}>{owner.name}</Link>);
    } else {
      ownerNode = owner?.name;
    }
    return (<li key={`owner-li-${oId}`}>{ownerNode}</li>);
  }) || 'None';

  const accountNode = account ? (
    <p>
      <b>Account: </b>
      <Link to={`/app/account/${account.id}/activity`}>
        {account.name}
      </Link>
    </p>
  ) : null;

  const partition = prospectingPhase === 'post conversation' ? 'postConversation' : 'preConversation';
  const phase = prospectingPhase === 'post conversation' ? 'Conversation' : 'Pre Conversation';

  return (
    <>
      <Modal.Header>
        <Link className="brand" to={`/app/contact/${contact.id}/activity`}>
          <Icon name="address card outline" />
          {name}
        </Link>
        {closeIcon}
      </Modal.Header>
      <Modal.Content>
        {accountNode}
        <b>Reps with activity: </b>
        <ul className={css.ownerList}>
          {ownerNodes}
        </ul>
        <p>
          <b>Phase: </b>
          <span className={css.capitalized}>
            {phase}
          </span>
        </p>
        <p>
          <b>Status: </b>
          {isActive ? 'Working' : 'Inactive'}
        </p>
        <p>
          <b>New/Existing: </b>
          <span className={css.capitalized}>
            {isNew ? 'new' : 'existing'}
          </span>
        </p>
        <ContactRecommendationsGroup
          partition={partition}
          metrics={metrics}
          recommendations={recommendations}
        />
      </Modal.Content>
    </>
  );
};

const WithAccount = (props: {
  teamId: number;
  contactId: number;
  onClose: StrictModalProps['onClose'];
  accountId: number;
}): ReactElement => {
  const { teamId, contactId, onClose, accountId } = props;
  const { data: accountData } = AccountApi.getAccount.useGetAccountQuery({ teamId, accountId });
  const {
    data,
    isLoading,
  } = prospectingApi.endpoints.getProspectingContact.useQuery({ teamId, contactId });

  let inner = <Loader active />;
  if (!isLoading) {
    if (!data?.status) {
      return <></>;
    }

    const { contact, owners, metrics } = data;
    const account = accountData?.account;
    if (contact === undefined) return <></>;
    inner = (
      <ModalInner
        contact={contact}
        account={account}
        owners={owners}
        metrics={metrics}
        closeIconOnClick={onClose}
      />
    );
  }

  return (
    <Modal open onClose={onClose} size="small">
      {inner}
    </Modal>
  );
};

const WithoutAccount = (props: {
  teamId: number;
  contactId: number;
  onClose: StrictModalProps['onClose'];
}): ReactElement => {
  const { teamId, contactId, onClose } = props;
  const {
    data,
    isLoading,
  } = prospectingApi.endpoints.getProspectingContact.useQuery({ teamId, contactId });

  let inner = <Loader active />;
  if (!isLoading) {
    if (!data?.status) {
      return <></>;
    }

    const { contact, owners, metrics } = data;
    if (contact === undefined) return <></>;
    inner = (
      <ModalInner
        contact={contact}
        owners={owners}
        metrics={metrics}
        closeIconOnClick={onClose}
      />
    );
  }

  return (
    <Modal open onClose={onClose} size="small">
      {inner}
    </Modal>
  );
};

const ProspectingContactDetailModal = (props: { onClose?: StrictModalProps['onClose'] }): React.ReactElement => {
  const dispatch = useDispatch();
  const teamId = useSelector(getSelectedTeamId);
  const { accountId: accountIdStr, contactId: contactIdStr } = useParams<{ accountId?: string; contactId?: string }>();
  const navigate = bindActionCreators((path: string) => push(path), dispatch);

  const location = useLocation();

  let { onClose } = props;
  if (onClose === undefined) {
    const accountPageUrl = accountIdStr
      ? `/app/prospecting/${accountIdStr}/contacts`
      : `/app/prospecting/contacts${location.search}`;
    onClose = (): CallHistoryMethodAction => navigate(accountPageUrl);
  }

  if (!teamId || !contactIdStr) return <></>;
  const contactId = Number.parseInt(contactIdStr, 10);
  if (Number.isNaN(contactId)) return <></>;
  if (accountIdStr) {
    const accountId = Number.parseInt(accountIdStr, 10);
    if (Number.isNaN(accountId)) return <></>;
    return <WithAccount onClose={onClose} teamId={teamId} contactId={contactId} accountId={accountId} />;
  }
  return <WithoutAccount onClose={onClose} teamId={teamId} contactId={contactId} />;
};

export default ProspectingContactDetailModal;
