import { ActivityCount, ProspectingStats } from 'models/activity';
import { EmailMetrics } from 'models/metric/activityMetrics';
import baseApi from '../apiSlice';
import { ApiResponse } from '../apiTypes';

export interface Query {
  teamId: number;
  startDate: string;
  endDate: string;
}

export interface Response extends ApiResponse {
 activityCounts: {
    teamTotal: ActivityCount;
    teamAvg: ActivityCount;
    members: {
      [userId: number]: ActivityCount;
    };
  };
  emailStats: {
    teamAvg: EmailMetrics;
    members: {
      [userId: number]: EmailMetrics;
    };
  };
}

export interface ProspectingResponse extends ApiResponse {
 prospectingStats: {
    team: ProspectingStats;
    members: {
      [userId: number]: ProspectingStats;
    };
  };
}

export const isActivityStatsApiResponse = (x: any): x is Response => typeof x.emailStats !== 'undefined';

const teamActivitiesApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTeamActivityStats: builder.query<Response, Query>({
      query: ({ teamId, startDate, endDate }) => `team/${teamId}/activity/${startDate}-${endDate}`,
    }),
  }),
});

export const useActivityStatsQuery = teamActivitiesApi.endpoints.getTeamActivityStats.useQuery;
