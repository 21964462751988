import React from 'react';
import { TeamWithMembers } from 'models/team';
import TeamUsersManageList from 'components/UserList/TeamUsersManageList';
import css from './ExpandedTeamCard.module.css';

interface ExpandedTeamCardProps {
  team: TeamWithMembers;
  handleOpenAddTeamMemberModal: () => void;
}

export const ExpandedTeamCard: React.FC<ExpandedTeamCardProps> = ({
  team,
  handleOpenAddTeamMemberModal,
}) => (
  <div className={css.expandedCardContentContainer}>
    <TeamUsersManageList
      handleOpenAddTeamMemberModal={handleOpenAddTeamMemberModal}
      teamId={team.id}
      users={team.members}
    />
  </div>
);
