import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import PipelineDash from './PipelineDash';

interface Props {}
interface State {}

class PipelineRoute extends Component<Props, State> {
  render(): React.ReactNode {
    return (
      <div>
        <Switch>
          <Route path="/app/pipeline" component={PipelineDash} />
        </Switch>
      </div>
    );
  }
}

export default PipelineRoute;
