import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, InputOnChangeData, Dropdown, Icon, Button } from 'semantic-ui-react';

import ClearableInput from 'components/Input/ClearableInput';

import { DisplayOptions } from '../AlertsDash';

import css from './SearchBar.module.css';

interface SearchBarProps {
  displayOptions: DisplayOptions;
  metrics: Array<string>;
  onChange: Function;
  users: Array<any>;
}

const SearchBar: React.FC<SearchBarProps> = ({ displayOptions, metrics, onChange, users }) => {
  const handleSearch = (_, data: InputOnChangeData): void => {
    const searchTerm = data.value.trim();
    onChange({ searchTerm });
  };

  const handleSelectAllList = (optionName): void => {
    onChange({ [optionName]: [] });
  };

  const handleToggleUserSelection = (id: number): void => {
    const { userWhitelist } = displayOptions;

    if (userWhitelist) {
      const newWhitelist = userWhitelist.includes(id)
        ? userWhitelist.filter(optionId => (optionId !== id))
        : userWhitelist.concat(id);

      onChange({ userWhitelist: newWhitelist });
    }
  };

  const handleToggleMetricSelection = (metric: string): void => {
    const { metricsWhiteList } = displayOptions;
    if (metricsWhiteList) {
      const newWhitelist = metricsWhiteList.includes(metric)
        ? metricsWhiteList.filter(optionId => (optionId !== metric))
        : metricsWhiteList.concat(metric);

      onChange({ metricsWhiteList: newWhitelist });
    }
  };

  const renderUserDropdown = (): React.ReactNode => {
    const { userWhitelist } = displayOptions;
    if (!users || !userWhitelist) return null;

    const isAllSelected = userWhitelist.length === 0;
    const numSelected = isAllSelected ? users.length : userWhitelist.length;
    const iconStyle = isAllSelected ? {} : { visibility: 'hidden' };

    return (
      <Dropdown closeOnChange={false} text={`Users (${numSelected})`} pointing item>
        <Dropdown.Menu>
          <Dropdown.Item onClick={(): void => handleSelectAllList('userWhitelist')}>
            <Icon name="check" style={iconStyle} />
            Show All
          </Dropdown.Item>
          {users.map(item => {
            const checkStyle = userWhitelist.includes(item.id) ? {} : { visibility: 'hidden' };
            return (
              <Dropdown.Item
                key={item.id}
                onClick={(): void => { handleToggleUserSelection(item.id); }}
              >
                <Icon name="check" style={checkStyle} />
                {item.name}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderMetricsDropdown = (): React.ReactNode => {
    const { metricsWhiteList } = displayOptions;
    if (!metrics || !metricsWhiteList) return null;

    const isAllSelected = metricsWhiteList.length === 0;
    const numSelected = isAllSelected ? metrics.length : metricsWhiteList.length;
    const iconStyle = isAllSelected ? {} : { visibility: 'hidden' };

    return (
      <Dropdown closeOnChange={false} text={`Metric (${numSelected})`} pointing item>
        <Dropdown.Menu>
          <Dropdown.Item onClick={(): void => handleSelectAllList('metricsWhiteList')}>
            <Icon name="check" style={iconStyle} />
            Show All
          </Dropdown.Item>
          {metrics.map(item => {
            const checkStyle = metricsWhiteList.includes(item) ? {} : { visibility: 'hidden' };
            return (
              <Dropdown.Item
                key={item}
                onClick={(): void => { handleToggleMetricSelection(item); }}
              >
                <Icon name="check" style={checkStyle} />
                {item}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <Menu attached="top">
      <div className={css.menu}>
        <div className={css.leftMenu}>
          <Menu.Item>
            <ClearableInput
              className={css.searchInput}
              onChange={handleSearch}
              placeholder="Search..."
            />
          </Menu.Item>
          {renderUserDropdown()}
          {renderMetricsDropdown()}
        </div>
        <div>
          <Button className={css.newAlertButton} as={Link} to="/app/alert/new">+ New Alert</Button>
        </div>
      </div>
    </Menu>
  );
};

export default SearchBar;
