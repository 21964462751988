import { handleActions } from 'redux-actions';

import { OpportunityStage } from 'models/opportunity';
import { makeColorGenerator } from 'lib/util';

import { Reducer } from 'redux';
import actions from '../actions';

export interface OrganizationState {
  name: string;
  active: boolean;
  opportunityStages: Array<OpportunityStage>;
  isLoadingOpportunityStages: boolean;
  opportunityTypes: Array<string>;
  isLoadingOpportunityTypes: boolean;
  isLoadingOrganization: boolean;
  settings: { excludeWeekends: boolean;[key: string]: any };
  features: any;
  isUpdatingSettings: false;
  errorUpdatingSettings?: string;
  maxTrackedSeats: number;
}

export const initialState: OrganizationState = {
  name: '',
  active: true,
  opportunityStages: [],
  isLoadingOpportunityStages: false,
  opportunityTypes: [],
  isLoadingOpportunityTypes: false,
  isLoadingOrganization: false,
  settings: { excludeWeekends: false },
  features: {},
  dataSettings: {},
  isUpdatingSettings: false,
  errorUpdatingSettings: undefined,
  maxTrackedSeats: 10,
};

const reducer: Reducer<any, any> = handleActions(
  {
    // GET ORGANIZATION
    [actions.organization.fetchOrganizationInfoRequest]: state => ({ ...state, isLoadingOrganization: true }),
    [actions.organization.fetchOrganizationInfoSuccess]: (
      state,
      { payload: { organization, features, dataSettings } },
    ) => ({
      ...state,
      ...organization,
      isLoadingOrganization: false,
      features: features || {},
      dataSettings: dataSettings || {},
    }),
    [actions.organization.fetchOrganizationInfoFailure]: state => ({ ...state, isLoadingOrganization: false }),
    // GET OPPORTUNITY STAGES
    [actions.organization.fetchOpportunityStagesRequest]: state => ({ ...state, isLoadingOpportunityStages: true }),
    [actions.organization.fetchOpportunityStagesSuccess]: (state, { payload: { opportunityStages } }) => {
      const colorGenerator = makeColorGenerator();
      return {
        ...state,
        isLoadingOpportunityStages: false,
        opportunityStages: opportunityStages.map(stage => ({
          ...stage,
          color: colorGenerator(),
        })),
      };
    },
    [actions.organization.fetchOpportunityStagesFailure]: state => ({ ...state, isLoadingOpportunityStages: false }),
    // GET OPPORTUNITY TYPES
    [actions.organization.fetchOpportunityTypesRequest]: state => ({ ...state, isLoadingOpportunityTypes: true }),
    [actions.organization.fetchOpportunityTypesSuccess]: (state, { payload: { opportunityTypes } }) => ({
      ...state,
      isLoadingOpportunityTypes: false,
      opportunityTypes,
    }),
    [actions.organization.fetchOpportunityTypesFailure]: state => ({ ...state, isLoadingOpportunityTypes: false }),
    // UPDATING SETTINGS
    [actions.organization.setOrganizationSettingsRequest]: state => ({ ...state, isUpdatingSettings: true }),
    [actions.organization.setOrganizationSettingsSuccess]: (state, action) => ({
      ...state,
      isUpdatingSettings: false,
      settings: { ...state.settings, ...action.payload.settings },
    }),
    [actions.organization.setOrganizationSettingsFailure]: (state, error) => ({
      ...state,
      isUpdatingSettings: false,
      errorUpdatingSettings: error.payload.msg,
    }),
    [actions.organization.clearOrganizationSettingsError]: state => ({ ...state, errorUpdatingSettings: undefined }),
  },
  initialState,
);

export default reducer;
