import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';

interface Props {
  instanceUrl?: string;
  salesforceId: string;
}

class SalesforceLink extends Component<Props> {
  render(): React.ReactNode {
    const { instanceUrl, salesforceId } = this.props;
    if (!instanceUrl || !salesforceId) return null;
    return (
      <sup>
        <a href={`${instanceUrl}/${salesforceId}`} target="_blank" rel="noopener noreferrer">
          <Icon name="external" size="small" />
        </a>
      </sup>
    );
  }
}
export default connect(
  (state: any) => ({ instanceUrl: state.app.instanceUrl }),
  null,
)(SalesforceLink);
