import { Icon, Label } from 'semantic-ui-react';
import React from 'react';
import { Activity } from '../../../../models/activity';
import css from './ActivityPanel.module.css';

interface ClassificationSectionProps {
  activity: Activity;
}

export const ClassificationSection: React.FC<ClassificationSectionProps> = props => {
  const { activity } = props;
  const classificationList = {
    isAutomatic: 'Automatic',
    isPricing: 'Pricing',
    isProspecting: 'Prospecting',
    isFollowup: 'Followup',
  };
  const elements: JSX.Element[] = [];
  Object.keys(classificationList).forEach(classification => {
    if (activity[classification]) {
      elements.push(
        <Label
          key={classificationList[classification]}
          className={css.classification}
        >
          {classificationList[classification]}
        </Label>,
      );
    }
  });
  if (elements.length > 0) {
    return (
      <div className={css.tags}>
        <Icon name="lightbulb" />
        {elements}
      </div>
    );
  }
  return <></>;
};
