import React, { Component } from 'react';
import {
  Dropdown,
  Icon,
  InputOnChangeData,
  Label,
  Loader,
  Menu,
  Segment,
  Header,
  Popup,
  Checkbox,
  Form,
} from 'semantic-ui-react';
import { Tag, FilterEmailDirection, ActivityIcons } from 'models/activity';
import BSIcon from 'components/BSIcon';
import BSDropdown from 'components/BSDropdown/BSDropdown';
import ClearableInput from 'components/Input/ClearableInput';

import css from './ActivitySearchBar.module.css';

export interface EmailPredictionFilter {
  automatic: boolean;
  followup: boolean;
  prospecting: boolean;
  pricing: boolean;
  all: boolean;
}

export interface DisplayOptions {
  accountWhitelist?: Array<number>;
  contactWhitelist?: Array<number>;
  opportunityStageWhitelist?: Array<string>;
  showEmail: boolean;
  showCall: boolean;
  showEvent: boolean;
  showTask: boolean;
  showSms: boolean;
  showLinkedIn: boolean;
  showAutomatic: boolean;
  searchTerm: string;
  tagWhitelist?: Array<string>;
  userWhitelist?: Array<number>;
  emailPredictionFilters: EmailPredictionFilter;
  filterEmailByDirection: FilterEmailDirection;
}

interface Props {
  accounts?: Array<any>;
  contacts?: Array<any>;
  datePicker: React.ReactNode;
  displayOptions: DisplayOptions;
  isLoadingTags?: boolean;
  onChange: Function;
  opportunityStages?: Array<any>;
  tags?: Array<Tag>;
  users?: Array<any>;
}

interface State {
  showAdvanced: boolean;
  showTags: boolean;
}

export default class ActivitySearchBar extends Component<Props, State> {
  static defaultProps = { onChange: (): void => { /* do nothing */ } }

  constructor(props) {
    super(props);

    const showAdvanced = localStorage.getItem('showAdvanced') === 'true';
    const showTags = localStorage.getItem('showTags') === 'true';

    this.state = {
      showAdvanced,
      showTags,
    };
  }

  handleSearch = (_, data: InputOnChangeData): void => {
    const { onChange } = this.props;
    const searchTerm = data.value.trim();
    onChange({ searchTerm });
  }

  toggleDisplayOption = (option: string): void => {
    const { displayOptions, onChange } = this.props;
    onChange({ [option]: !displayOptions[option] });
  };

  toggleAccount = (accountId: number): void => {
    const { displayOptions, onChange } = this.props;
    const { accountWhitelist } = displayOptions;
    if (!accountWhitelist) return;
    const whitelist = accountWhitelist.includes(accountId)
      ? accountWhitelist.filter(id => (id !== accountId))
      : accountWhitelist.concat(accountId);
    onChange({ accountWhitelist: whitelist });
  };

  toggleContact = (contactId: number): void => {
    const { displayOptions, onChange } = this.props;
    const { contactWhitelist } = displayOptions;
    if (!contactWhitelist) return;
    const whitelist = contactWhitelist.includes(contactId)
      ? contactWhitelist.filter(id => (id !== contactId))
      : contactWhitelist.concat(contactId);
    onChange({ contactWhitelist: whitelist });
  };

  toggleOpportunityStage = (stageName: string): void => {
    const { displayOptions, onChange } = this.props;
    const { opportunityStageWhitelist } = displayOptions;
    if (!opportunityStageWhitelist) return;
    const whitelist = opportunityStageWhitelist.includes(stageName)
      ? opportunityStageWhitelist.filter(s => (s !== stageName))
      : opportunityStageWhitelist.concat(stageName);
    onChange({ opportunityStageWhitelist: whitelist });
  };

  toggleTag = (tagName: string): void => {
    const { displayOptions, onChange } = this.props;
    const { tagWhitelist } = displayOptions;
    if (!tagWhitelist) return;
    const index = tagWhitelist.indexOf(tagName);
    const whitelist = (index === -1) ? tagWhitelist.concat(tagName) : tagWhitelist.filter(id => (id !== tagName));
    onChange({ tagWhitelist: whitelist });
  };

  toggleTags = (): void => {
    const sessionVal = localStorage.getItem('showTags');
    let previousValue;
    if (typeof sessionVal === 'string') previousValue = sessionVal === 'true';
    else previousValue = true;
    localStorage.setItem('showTags', (previousValue ? 'false' : 'true'));
    this.setState({ showTags: !previousValue });
  };

  toggleAdvanced = (): void => {
    const sessionVal = localStorage.getItem('showAdvanced');
    let previousValue;
    if (typeof sessionVal === 'string') previousValue = sessionVal === 'true';
    else previousValue = true;
    localStorage.setItem('showAdvanced', (previousValue ? 'false' : 'true'));
    this.setState({ showAdvanced: !previousValue });
  };

  toggleUser = (userId: number): void => {
    const { displayOptions, onChange } = this.props;
    const { userWhitelist } = displayOptions;
    if (!userWhitelist) return;
    const whitelist = userWhitelist.includes(userId)
      ? userWhitelist.filter(id => (id !== userId))
      : userWhitelist.concat(userId);
    onChange({ userWhitelist: whitelist });
  };

  renderIcon = (active: boolean, iconName: ActivityIcons): React.ReactNode => {
    if (active) {
      return (<BSIcon activityIcon={iconName} bordered />);
    }
    return (<BSIcon activityIcon={iconName} bordered color="grey" className={css.inactiveIcon} />);
  };

  renderTypeDropdown = (): React.ReactNode => {
    const {
      displayOptions: { showEmail, showCall, showEvent, showTask, showSms, showLinkedIn },
      onChange,
    } = this.props;
    const selectedArray = [showEmail, showCall, showEvent, showTask, showSms, showLinkedIn];
    const numSelected = selectedArray.filter(Boolean).length;


    const showAll = numSelected === selectedArray.length;
    const newOptions = showAll ? {
      showEmail: false,
      showCall: false,
      showEvent: false,
      showTask: false,
      showSms: false,
      showLinkedIn: false,
    } : {
      showEmail: true,
      showCall: true,
      showEvent: true,
      showTask: true,
      showSms: true,
      showLinkedIn: true,
    };

    const handleToggleAll = (e: React.MouseEvent<HTMLDivElement>): void => {
      e.stopPropagation();
      onChange(newOptions);
    };

    const handleActivityToggle = (e: React.MouseEvent<HTMLDivElement>, activity: string): void => {
      e.stopPropagation();
      this.toggleDisplayOption(activity);
    };

    return (
      <Dropdown closeOnChange={false} text={`Activity Type (${numSelected})`} pointing item>
        <Dropdown.Menu>
          <Dropdown.Item onClick={handleToggleAll}>
            <div className="link">{showAll ? 'Unselect All' : 'Show All'}</div>
          </Dropdown.Item>
          <Dropdown.Item onClick={(e): void => handleActivityToggle(e, 'showEmail')}>
            {this.renderIcon(showEmail, 'mail')}
            {' '}
            Email
          </Dropdown.Item>
          <Dropdown.Item onClick={(e): void => handleActivityToggle(e, 'showCall')}>
            {this.renderIcon(showCall, 'call')}
            {' '}
            Phone Call
          </Dropdown.Item>
          <Dropdown.Item onClick={(e): void => handleActivityToggle(e, 'showEvent')}>
            {this.renderIcon(showEvent, 'calendar')}
            {' '}
            Event/Meeting
          </Dropdown.Item>
          <Dropdown.Item onClick={(e): void => handleActivityToggle(e, 'showSms')}>
            {this.renderIcon(showSms, 'sms')}
            {' '}
            SMS
          </Dropdown.Item>
          <Dropdown.Item onClick={(e): void => handleActivityToggle(e, 'showLinkedIn')}>
            {this.renderIcon(showLinkedIn, 'linkedin')}
            {' '}
            LinkedIn
          </Dropdown.Item>
          <Dropdown.Item onClick={(e): void => handleActivityToggle(e, 'showTask')}>
            {this.renderIcon(showTask, 'tasks')}
            {' '}
            Task
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  renderAccountDropdown = (): React.ReactNode => {
    const { accounts, displayOptions, onChange } = this.props;
    const { accountWhitelist } = displayOptions;

    if (!accounts || !accountWhitelist) return null;

    const isAllSelected = accountWhitelist.length === 0;

    const handleOnSelectAll = (): void => {
      onChange({ accountWhitelist: [] });
    };

    const handleOnChange = (value: string): void => {
      this.toggleAccount(value as unknown as number);
    };

    const items = accounts.map(os => ({
      key: os.id,
      value: os.id,
      label: os.name,
      selected: accountWhitelist.includes(os.id) || isAllSelected,
    }));

    return (
      <BSDropdown
        onChange={handleOnChange}
        onSelectAll={handleOnSelectAll}
        title="Accounts"
        items={items}
      />
    );
  };

  renderContactDropdown = (): React.ReactNode => {
    const { contacts, displayOptions, onChange } = this.props;
    const { contactWhitelist } = displayOptions;

    if (!contacts || !contactWhitelist) return null;

    const isAllSelected = contactWhitelist.length === 0;

    const handleOnSelectAll = (): void => {
      onChange({ contactWhitelist: [] });
    };

    const handleOnChange = (value: string): void => {
      this.toggleContact(value as unknown as number);
    };

    const items = contacts.filter(c => (c.name || c.email)).map(c => ({
      key: c.id,
      value: c.id,
      label: c.name || c.email,
      selected: contactWhitelist.includes(c.id) || isAllSelected,
    }));

    return (
      <BSDropdown
        onChange={handleOnChange}
        onSelectAll={handleOnSelectAll}
        title="Contacts"
        items={items}
      />
    );
  };

  renderOpportunityStageDropdown = (): React.ReactNode => {
    const { opportunityStages, displayOptions, onChange } = this.props;
    const { opportunityStageWhitelist } = displayOptions;

    if (!opportunityStages || !opportunityStageWhitelist) return null;

    const handleOnSelectAll = (): void => {
      onChange({ opportunityStageWhitelist: [] });
    };

    const handleOnChange = (value: string): void => {
      this.toggleOpportunityStage(value);
    };

    const isSelectAllSelected = opportunityStageWhitelist.length === 0;

    const items = opportunityStages.map(os => ({
      key: os.masterLabel,
      value: os.masterLabel,
      selected: opportunityStageWhitelist.includes(os.masterLabel) || isSelectAllSelected,
    }));

    return (
      <BSDropdown
        onChange={handleOnChange}
        onSelectAll={handleOnSelectAll}
        title="Opportunity Stage"
        items={items}
      />
    );
  };

  renderUserDropdown = (): React.ReactNode => {
    const { users, displayOptions, onChange } = this.props;
    const { userWhitelist } = displayOptions;

    if (!users || !userWhitelist) return null;

    const isAllSelected = userWhitelist.length === 0;
    const numSelected = isAllSelected ? users.length : userWhitelist.length;

    const iconStyle = isAllSelected ? {} : { visibility: 'hidden' };
    const clickFn = (): void => {
      onChange({ userWhitelist: [] });
    };

    const items = users.map(user => {
      const checkStyle = userWhitelist.includes(user.id) ? {} : { visibility: 'hidden' };
      return (
        <Dropdown.Item key={user.id} onClick={(): void => { this.toggleUser(user.id); }}>
          <Icon name="check" style={checkStyle} />
          {user.name}
        </Dropdown.Item>
      );
    });
    return (
      <Dropdown closeOnChange={false} text={`Owner (${numSelected})`} pointing item>
        <Dropdown.Menu>
          <Dropdown.Item onClick={clickFn}>
            <Icon name="check" style={iconStyle} />
            Show All
          </Dropdown.Item>
          {items}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  renderEmailFilterByDirection = (): React.ReactNode => {
    const { onChange, displayOptions: { filterEmailByDirection } } = this.props;

    const handleDirectionChange = (direction: string): void => {
      onChange({ filterEmailByDirection: direction });
    };

    const directionOptions = ['both', 'inbound', 'outbound'];

    const items = directionOptions.map(direction => ({
      key: direction,
      value: direction,
      label: direction[0].toUpperCase() + direction.slice(1),
      selected: filterEmailByDirection === direction,
    }));

    return (
      <BSDropdown
        onChange={handleDirectionChange}
        title="Email Direction"
        items={items}
        sortOnChange={false}
      />
    );
  };

  renderTags = (): React.ReactNode => {
    const { displayOptions, isLoadingTags, onChange, tags } = this.props;
    const { showAutomatic, tagWhitelist } = displayOptions;
    const { showTags } = this.state;
    if (!showTags || !tagWhitelist) return null;

    if (!tags) {
      return (<Segment className={css.tags}>Displayed activities have not been tagged yet</Segment>);
    }

    const isAllSelected = tagWhitelist.length === 0;
    const className = isAllSelected ? css.activeLabel : '';

    const tagElements = tags.map((t): React.ReactNode => {
      const { id, tagName, count } = t;
      const tagClassName = tagWhitelist.includes(tagName) ? css.activeLabel : '';
      return (
        <Label
          as="a"
          className={tagClassName}
          key={id}
          onClick={(): void => { this.toggleTag(tagName); }}
        >
          {tagName}
          <Label.Detail>{count}</Label.Detail>
        </Label>
      );
    });
    tagElements.unshift(
      <Label key="select_all" as="a" onClick={(): void => { onChange({ tagWhitelist: [] }); }} className={className}>
        Select All
      </Label>,
    );

    const handleAutomaticClick = (): void => { onChange({ showAutomatic: !showAutomatic }); };

    return (
      <Segment className={css.tags} attached="bottom">
        {isLoadingTags && <Loader active={isLoadingTags} />}
        <div>
          <Header as="h5" className={css.tagHeader}>BuyerSight Tags</Header>
          {tagElements}
        </div>
        {this.renderEmailPredictionsFilter()}
        <Checkbox onChange={handleAutomaticClick} label="Hide Automated Responses" checked={!showAutomatic} />
      </Segment>
    );
  };

  renderEmailPredictionsFilter = (): React.ReactNode => {
    const { displayOptions: { emailPredictionFilters }, onChange } = this.props;

    const selectAllPredicted = (): void => {
      onChange({
        emailPredictionFilters: {
          followup: false,
          pricing: false,
          prospecting: false,
          all: true,
        },
      });
    };

    const togglePredicted = (key: string): void => {
      if (key === 'all') {
        selectAllPredicted();
        return;
      }

      const newPredictedFilters = { ...emailPredictionFilters };
      newPredictedFilters[key] = !newPredictedFilters[key];
      newPredictedFilters.all = false;

      // reset to all if all are false.
      if (
        !newPredictedFilters.followup
        && !newPredictedFilters.prospecting
        && !newPredictedFilters.pricing
      ) {
        newPredictedFilters.all = true;
      }

      onChange({ emailPredictionFilters: newPredictedFilters });
    };

    const emailPredictedValues = [
      { value: 'all', label: 'Select All' },
      { value: 'automatic', label: 'Automatic' },
      { value: 'followup', label: 'Follow up' },
      { value: 'pricing', label: 'Pricing' },
      { value: 'prospecting', label: 'Prospecting' },
    ];

    const popup = (
      <Popup
        content="Beta: this feature is in beta and results may not yet be fully consistent with actual data."
        trigger={(<Icon name="question circle" />)}
      />
    );

    return (
      <Segment style={{ display: 'none' }}>
        <Header as="h5" className={css.tagHeader}>
          {'Intelligent Tags '}
          {popup}
        </Header>
        {emailPredictedValues.map(epv => (
          <Label
            as="a"
            key={epv.value}
            className={emailPredictionFilters[epv.value] ? css.emailPredictionActiveLabel : ''}
            onClick={(): void => { togglePredicted(epv.value); }}
          >
            {epv.label}
          </Label>
        ))}
      </Segment>
    );
  };

  render(): React.ReactNode {
    const { datePicker } = this.props;
    const { showAdvanced, showTags } = this.state;

    const showAdvancedStyle = showAdvanced ? {} : { display: 'none' };

    return (
      <>
        <Form>
          <Segment attached>
            <Form.Group>
              <Form.Field width="4">
                <label>Activity Date Range</label>
                {datePicker}
              </Form.Field>
              <Form.Field width="10">
                <label>Activity Search</label>
                <ClearableInput
                  className={css.searchInput}
                  onChange={this.handleSearch}
                  placeholder="Search..."
                />
              </Form.Field>
              <Form.Field width="2">
                <label>Show Advanced Filters</label>
                <Form.Checkbox toggle onChange={this.toggleAdvanced} checked={showAdvanced} />
              </Form.Field>
            </Form.Group>
          </Segment>
          <div style={showAdvancedStyle}>
            <Menu attached={showTags}>
              {this.renderTypeDropdown()}
              {this.renderAccountDropdown()}
              {this.renderUserDropdown()}
              {this.renderContactDropdown()}
              {this.renderOpportunityStageDropdown()}
              {this.renderEmailFilterByDirection()}
              <Menu.Item onClick={this.toggleTags} position="right">
                {showTags ? 'Hide tags' : 'Show tag filters'}
              </Menu.Item>
            </Menu>
            {this.renderTags()}
          </div>
        </Form>
      </>
    );
  }
}
