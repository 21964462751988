/* eslint-disable */
import * as React from 'react';

function SvgUserClockLight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fal"
      data-icon="user-clock"
      className="user-clock-light_svg__svg-inline--fa user-clock-light_svg__fa-user-clock user-clock-light_svg__fa-w-20"
      viewBox="0 0 640 512"
      {...props}
    >
      <path
        fill="currentColor"
        d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm0-224c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96zm272 192c-79.6 0-144 64.4-144 144s64.4 144 144 144 144-64.4 144-144-64.4-144-144-144zm0 256c-61.8 0-112-50.2-112-112s50.2-112 112-112 112 50.2 112 112-50.2 112-112 112zm-135.8 0H48c-8.8 0-16-7.2-16-16v-41.6C32 365.9 77.9 320 134.4 320c19.6 0 39.1 16 89.6 16 50.4 0 70-16 89.6-16 4.4 0 8.6.8 12.9 1.3 2.9-10.7 6.9-21 11.7-30.8-8-1.5-16.1-2.5-24.5-2.5-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h346.9c-12.9-9.1-24.7-19.8-34.7-32zm190.1-128H512v-54.3c0-5.3-4.4-9.7-9.7-9.7h-12.6c-5.3 0-9.7 4.4-9.7 9.7v76.6c0 5.3 4.4 9.7 9.7 9.7h60.6c5.3 0 9.7-4.4 9.7-9.7v-12.6c0-5.3-4.4-9.7-9.7-9.7z"
      />
    </svg>
  );
}

export default SvgUserClockLight;
