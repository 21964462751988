import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';

import { appSlice } from 'features/App/appSlice';
import { accountSlice } from 'features/Account/accountSlice';
import { contactSlice } from 'features/Contact/contactSlice';
import funnel from 'features/Funnel/funnelSlice';
import baseApi from 'features/Api/apiSlice';
import account from './account';
import activity from './activity';
import admin from './admin';
import alert from './alert';
import app from './app';
import organization from './organization';
import recommendation from './recommendation';
import settings from './settings';
import team from './team';
import user from './user';

const slices = [
  accountSlice,
  contactSlice,
  appSlice,
];

const slicesObj = slices.reduce((a, v) => ({ ...a, [v.name]: v.reducer }), {});

const createRootReducer = (history): Reducer => combineReducers({
  router: connectRouter(history),
  account,
  activity,
  admin,
  alert,
  app,
  organization,
  recommendation,
  settings,
  team,
  user,
  [baseApi.reducerPath]: baseApi.reducer,
  ...slicesObj,
  funnel,
});

export default createRootReducer;
