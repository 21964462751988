import { DateTime } from 'luxon';
import humanizeDuration from 'humanize-duration';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { Link } from 'react-router-dom';

import { Activity, ActivityContact } from 'models/activity';
import BSIcon from 'components/BSIcon';
import ExternalLink from 'components/ExternalLink';
import { stripHTML } from 'lib/util';

import css from './ActivityPanel.module.css';

interface ActivityPanelBodyProps {
  activity: Activity;
  activityContacts: Array<ActivityContact>;
  active: boolean | undefined;
}

export const ActivityPanelBody: React.FC<ActivityPanelBodyProps> = props => {
  const { activity, active, activityContacts } = props;
  const {
    id,
    accountId,
    contactId,
    accountName,
    activityDate,
    additionalContactIds,
    contactName,
    contactExtId,
    contactExtSource,
    customData,
    description,
    signature,
    subject,
    toAddress,
    fromAddress,
    fleschKincaidGrade,
    wordCount,
  } = activity;

  const date = DateTime.fromISO(activityDate).toFormat('YYYY-MM-DD');

  const accountNode = accountName ? (
    <div>
      <BSIcon name="building" title="Account" />
      <b>Account:</b>
      <Link to={`/app/account/${accountId}/activity?activity=${id}&date=${date}`}>{accountName}</Link>
    </div>
  ) : null;

  const activityContactMap = {};
  activityContacts.forEach(contact => {
    activityContactMap[contact.id] = contact;
  });
  const additionalContacts = additionalContactIds?.map(cid => {
    const contact = activityContactMap[cid];
    if (!contact) return null;
    const displayName = contact.name || contact.email;
    if (!displayName) return null;
    return (
      <span key={contact.id}>
        {displayName}
        <ExternalLink extId={contact.extId} extSource={contact.extSource} type="contact" />
      </span>
    );
  });
  const allContactsNode = contactName ? (
    <div>
      <BSIcon name="users" title="Contact" />
      <b>All Contacts: </b>
      {additionalContacts}
    </div>
  ) : null;

  const contactNode = contactName ? (
    <div>
      <BSIcon name="users" title="Contact" />
      <b>Primary Contact: </b>
      <Link to={`/app/contact/${contactId}/activity?activity=${id}&date=${date}`}>{contactName}</Link>
      {(contactExtId && contactExtSource)
        ? (<ExternalLink extId={contactExtId} extSource={contactExtSource} type="contact" />) : null}
    </div>
  ) : null;

  const callDurationNode = customData?.callDuration ? (
    <div>
      <BSIcon name="clock" title="Call Duration" />
      <b>Call Duration: </b>
      {humanizeDuration(customData.callDuration)}
    </div>
  ) : null;

  const fleschKincaidGradeNode = fleschKincaidGrade ? (
    <div>
      <BSIcon name="book" title="Flesch Kincaid Grade Level" />
      <b>Flesch Kincaid Grade Level: </b>
      {fleschKincaidGrade}
    </div>
  ) : null;

  const wordCountNode = wordCount ? (
    <div>
      <BSIcon name="chart line" title="Word Count" />
      <b>Word Count: </b>
      {wordCount}
    </div>
  ) : null;

  const subjectNode = (
    <div className={css.subject}>
      <b>Subject: </b>
      {subject}
    </div>
  );

  const toNode = toAddress ? (
    <div className={css.to}>
      <b>To: </b>
      {toAddress}
    </div>
  ) : null;

  const fromNode = toAddress ? (
    <div className={css.to}>
      <b>From: </b>
      {fromAddress}
    </div>
  ) : null;

  return (
    <>
      {accountNode}
      {contactNode}
      {allContactsNode}
      <AnimatePresence>
        {active && (
          <motion.div
            animate={{ height: 'auto', opacity: 1 }}
            initial={{ height: active ? 'auto' : 0, opacity: 0 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {callDurationNode}
            {fleschKincaidGradeNode}
            {wordCountNode}
          </motion.div>
        )}
      </AnimatePresence>
      {toNode}
      {fromNode}
      {subjectNode}
      <div className={css.description}>
        <AnimatePresence>
          {active && (
            <motion.div
              animate={{ height: 'auto', opacity: 1 }}
              initial={{ height: active ? 'auto' : 0, opacity: 0 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              {stripHTML(description)}
              {signature}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};
