import React, { CSSProperties, FC, ReactElement, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ScriptableTooltipContext, TooltipItem } from 'chart.js';
import moment, { Moment } from 'moment';

import {
  setAccountDateSelectionAroundDate, setAccountDateSelectionWithStartEndDates,
} from 'features/Account/accountSlice';
import { Popup, StrictPopupProps } from 'semantic-ui-react';
import combinedActions from 'actions';
import BSIcon from 'components/BSIcon/BSIcon';
import { IdealDataPoint } from '../Timeline';
import { ActualDataPoint, NotableActivityDataPoint, StageChangeDataPoint } from './AccountTimeline';
import css from './AccountTimeline.module.css';


const IdealTooltipContent = (props: {
  datapoint: IdealDataPoint;
  dataIndex: number;
}): JSX.Element => {
  const { datapoint: { stageName, masterLabel, stageStartOrEnd, daysInStage, activitiesPerWeek } } = props;
  let title: string;
  const stageLabel = masterLabel || stageName;
  if (stageStartOrEnd === 'start') title = `Stage "${stageLabel}" Begins`;
  else title = `Stage "${stageLabel}" Ends`;
  return (
    <>
      <h1>
        {`Ideal Timeline: ${title}`}
      </h1>
      <table>
        <tbody>
          <tr>
            <td>Ideal Opportunity Stage:</td>
            <td>{stageLabel}</td>
          </tr>
          <tr>
            <td>Ideal Time in Stage (days):</td>
            <td>{daysInStage.toFixed(1)}</td>
          </tr>
          <tr>
            <td>Ideal Activities per Week:</td>
            <td>{activitiesPerWeek.toFixed(1)}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const ActualTooltipContent = (props: {
  datapoint: ActualDataPoint;
  accountId: number;
}): JSX.Element => {
  const { datapoint, accountId } = props;
  const dateFormat = 'MMM Do YYYY';
  const { weekStart, weekEnd } = datapoint;
  const WeeklySummaryLink = (p: {
    startDate: Moment;
    endDate: Moment;
    children: React.ReactNode;
  }): JSX.Element => {
    const { startDate, endDate, children } = p;
    const dispatch = useDispatch();

    function handleClick(): void {
      dispatch(setAccountDateSelectionWithStartEndDates({
        start: startDate.toISOString(),
        end: endDate.toISOString(),
      }));
    }

    return (
      <Link to={`/app/account/${accountId}/activity`} onClick={handleClick}>
        {children}
      </Link>
    );
  };
  return (
    <>
      <h1>
        {datapoint.weekStart.format(dateFormat)}
        {' '}
        -
        {' '}
        {datapoint.weekEnd.format(dateFormat)}
      </h1>
      <table>
        <tbody>
          <tr>
            <td>Opportunity Stage:</td>
            <td>{datapoint.stageName}</td>
          </tr>
          <tr>
            <td>Time in Stage (days):</td>
            <td>{datapoint.daysInStage.toFixed(1)}</td>
          </tr>
          <tr>
            <td>Activities this Week:</td>
            <td>{datapoint.activitiesPerWeek}</td>
          </tr>
          <tr>
            <td>Emails this Week:</td>
            <td>{datapoint.activityBreakdown.email.all}</td>
          </tr>
          <tr>
            <td>Meetings this Week:</td>
            <td>{datapoint.activityBreakdown.meeting}</td>
          </tr>
          <tr>
            <td>Calls this Week:</td>
            <td>{datapoint.activityBreakdown.answeredCall}</td>
          </tr>
          <tr>
            <td />
            <td>
              <WeeklySummaryLink accountId={accountId} startDate={weekStart} endDate={weekEnd}>
                {'Go to account timeline '}
                <BSIcon name="arrow right" />
              </WeeklySummaryLink>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const NotableActivityTooltipContent = (props: {
  datapoint: NotableActivityDataPoint;
  accountId: number;
}): JSX.Element => {
  const { datapoint, accountId } = props;
  const { activityId, type, date: dateString } = datapoint;
  const date = moment(dateString);
  const dateFormat = 'MMM Do YYYY, h:mm a';
  let title: string | JSX.Element = '';
  let subtitle: string | JSX.Element = date.format(dateFormat);
  const titleIconStyles: CSSProperties = {
    margin: '0 .25rem 0 0',
    fontSize: '1rem',
  };

  const NotableActivityLink = (p: { children: React.ReactNode }): JSX.Element => {
    const { children } = p;
    const dispatch = useDispatch();

    function handleClick(): void {
      dispatch(setAccountDateSelectionAroundDate(date.toISOString()));
      dispatch(combinedActions.activity.selectActivity(activityId));
    }

    return (
      <Link to={`/app/account/${accountId}/activity`} onClick={handleClick}>
        {children}
      </Link>
    );
  };

  switch (type) {
    case 'answeredCall':
      title = (
        <>
          <BSIcon activityIcon="call" bordered circular style={titleIconStyles} />
          <NotableActivityLink accountId={accountId} activityId={activityId} activityDate={date}>
            Answered Call
          </NotableActivityLink>
        </>
      );
      break;
    case 'meeting':
      title = (
        <>
          <BSIcon activityIcon="calendar" bordered circular style={titleIconStyles} />
          <NotableActivityLink accountId={accountId} activityId={activityId} activityDate={date}>
            Meeting
          </NotableActivityLink>
        </>
      );
      break;
    case 'stageChange':
      if (datapoint instanceof StageChangeDataPoint) {
        const { stageFrom, stageTo } = datapoint;
        titleIconStyles.color = '#f59ca9';
        titleIconStyles.background = '#fef0f2';
        if (stageFrom === 'No Opportunity') {
          title = (
            <>
              <BSIcon name="info" bordered circular style={titleIconStyles} />
              Opportunity Opened
            </>
          );
          subtitle = (
            <>
              {stageTo}
              <br />
              {date.format(dateFormat)}
            </>
          );
        } else {
          title = (
            <>
              <BSIcon name="info" bordered circular style={titleIconStyles} />
              Stage Change
            </>
          );
          subtitle = (
            <>
              {stageFrom}
              <BSIcon name="long arrow alternate right" style={{ margin: '0 .25rem' }} />
              {stageTo}
              <br />
              {date.format(dateFormat)}
            </>
          );
        }
      }
      break;
    default:
      return <></>;
  }
  return (
    <>
      <h1>
        {title}
      </h1>
      <h2>
        {subtitle}
      </h2>
    </>
  );
};

export const TooltipInner = (props: { dataPoints: TooltipItem<'line' | 'scatter'>[]; accountId?: number }): ReactElement => {
  const { dataPoints, accountId } = props;
  const idealDupeDict = {};
  const inner = dataPoints.map(tooltipItem => {
    const { raw, dataIndex } = tooltipItem;
    if (raw instanceof IdealDataPoint) {
      const { stageName } = raw;
      if (!idealDupeDict[stageName]) {
        idealDupeDict[stageName] = true;
        return <IdealTooltipContent key={dataIndex} datapoint={raw} dataIndex={dataIndex} />;
      }
    } else if (raw instanceof ActualDataPoint && accountId) {
      return <ActualTooltipContent key={dataIndex} datapoint={raw} accountId={accountId} />;
    } else if (raw instanceof NotableActivityDataPoint && accountId) {
      return <NotableActivityTooltipContent key={dataIndex} datapoint={raw} accountId={accountId} />;
    }
    return <></>;
  });
  return <>{inner}</>;
};

export const AccountTimelineTooltip: FC<{
  ctx: null | ScriptableTooltipContext<'line' | 'scatter'>;
  accountId?: number;
}> = ({ ctx, accountId }) => {
  const defaultDataPoints: TooltipItem<'line' | 'scatter'>[] = [];
  let newDataPoints: TooltipItem<'line' | 'scatter'>[] = [];

  const [dataPoints, setDataPoints] = useState(defaultDataPoints);
  const [tooltipBeingHoveredOn, setTooltipBeingHoveredOn] = useState(false);
  const pointRef = useRef(null);

  function delayedClearDatapoints(timeoutMs = 350): number {
    return window.setTimeout(() => {
      setDataPoints([]);
    }, timeoutMs);
  }
  if (ctx) ({ tooltip: { dataPoints: newDataPoints } } = ctx);
  useEffect(() => {
    if (ctx) {
      setDataPoints(newDataPoints);
      const { tooltip: { opacity } } = ctx;
      if (opacity === 0 && !tooltipBeingHoveredOn) {
        const timeout = delayedClearDatapoints();
        return (): void => { clearTimeout(timeout); };
      }
    }
    return (): void => { /* do nothing */ };
  }, [tooltipBeingHoveredOn, ctx, newDataPoints]);
  if (!ctx || !dataPoints) {
    return <></>;
  }
  const { tooltip: { xAlign, yAlign, caretX, caretY } } = ctx;
  const inner = <TooltipInner dataPoints={dataPoints} accountId={accountId} />;
  const xAlignCssProp: 'left' | 'right' = xAlign === 'center' ? 'left' : xAlign;
  const yAlignCssProp: 'top' | 'bottom' = yAlign === 'center' ? 'top' : yAlign;
  const position = `${yAlignCssProp} ${xAlignCssProp}` as StrictPopupProps['position'];
  const triggerStyles: CSSProperties = {
    position: 'absolute',
    left: `${window.scrollX + caretX}px`,
    top: `${-10 + window.scrollY + caretY}px`,
    width: '1px',
    height: '1px',
    opacity: 0,
    backgroundColor: '#FFFFFF',
  };
  return (
    <>
      <Popup
        position={position}
        open={dataPoints.length > 0}
        positionFixed={false}
        content={inner}
        context={pointRef}
        basic
        onMouseEnter={(): void => { setTooltipBeingHoveredOn(true); }}
        onMouseLeave={(): void => { setTooltipBeingHoveredOn(false); }}
        className={css.tooltip}
      />
      <div style={triggerStyles} ref={pointRef} />
    </>
  );
};
