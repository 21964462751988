import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'query-string';
import { Grid, Ref } from 'semantic-ui-react';

import allActions, { bindCombinedActions } from 'actions';
import { getSelectedActivity } from 'selectors/activity';
import { getTrackedUsers } from 'selectors/user';

import { Permission } from 'components/AccessControl';
import ActivityInfoBar from 'components/ActivityInfoBar';

import { Activity, ActivityAccount, ActivityContact, Tag } from 'models/activity';
import { OpportunityStage } from 'models/opportunity';
import { User } from 'models/user';

import { TimePeriod } from 'features/DatePicker/datePickerTypes';
import { selectAppDateSelection } from 'features/App/appSlice';
import { getIsoDatesFromTimePeriod } from 'features/DatePicker/datePickerFunctions';
import UserActivityTypesOverTimeGraph from './UserActivityTypesOverTimeGraph';
import UserAccountsContactedOverTime from './UserAccountsContactedOverTimeGraph/UserAccountsContactedOverTime';
import UserTagsOverTimeGraph from './UserTagsOverTimeGraph/UserTagsOverTimeGraph';

interface Props {
  actions: any;
  activities: Array<Activity>;
  activityAccounts: Array<ActivityAccount>;
  activityContacts: Array<ActivityContact>;
  activityTags: Array<Tag>;
  isLoadingActivities: boolean;
  isLoadingUserInfo: boolean;
  isLoadingTrackedUsers: boolean;
  isSyncing: boolean;
  location: any;
  match: { params: { userId: string } };
  opportunityStages: Array<OpportunityStage>;
  permission: Permission;
  selectedActivity: any;
  selectedId?: number;
  trackedUsers: Array<User>;
  userInfo: any;
  timePeriod: TimePeriod;
}
interface DisplayOptions {
  showEmail: boolean;
  showCall: boolean;
  showEvent: boolean;
  showTask: boolean;
  searchTerm: string;
  accountWhitelist: Array<number>;
  opportunityStageWhitelist: Array<string>;
  tagWhitelist: Array<string>;
}
interface State {
  displayOptions: DisplayOptions;
}

class UserOverview extends Component<Props, State> {
  contextRef = React.createRef();

  componentDidMount(): void {
    const { actions, location } = this.props;

    const { search } = location;
    const params = qs.parse(search);
    if (params.activity) {
      const selectedId = parseInt(params.activity as string, 10);
      actions.activity.selectActivity(selectedId);
    }

    this.fetchInfo();
  }

  componentDidUpdate(prevProps: Props): void {
    const { userInfo, timePeriod } = this.props;
    if (prevProps.userInfo.id !== userInfo.id) {
      this.fetchInfo();
    } else if (prevProps.timePeriod !== timePeriod) {
      this.fetchInfo();
    }
  }

  fetchInfo = async (): Promise<void> => {
    const { actions, userInfo, timePeriod } = this.props;
    const { start: startDate, end: endDate } = getIsoDatesFromTimePeriod(timePeriod);
    if (userInfo.isTracked) {
      actions.activity.searchActivities({
        ownerId: userInfo.id,
        startDate,
        endDate,
        getTags: true,
        getAccounts: true,
        getContacts: true,
        count: false,
        groupByDay: true,
      }, false);
    }
  };

  calculateAverage(x: number, n: number): number {
    let ret = x / n;
    ret = Math.round(x * 10) / 10;
    return ret;
  }

  render(): React.ReactNode {
    const { activities, activityAccounts, activityContacts, isLoadingActivities } = this.props;
    return (
      <>
        <ActivityInfoBar
          isLoading={isLoadingActivities}
          accounts={activityAccounts}
          contacts={activityContacts}
          activities={activities}
        />
        <Ref innerRef={this.contextRef}>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <UserActivityTypesOverTimeGraph />
              </Grid.Column>
              <Grid.Column>
                <UserAccountsContactedOverTime />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <UserTagsOverTimeGraph />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Ref>
      </>
    );
  }
}

export default connect(
  (state: any) => ({
    isLoadingActivities: state.activity.isLoadingActivities,
    selectedId: state.activity.selectedId,
    selectedActivity: getSelectedActivity(state),
    activities: state.activity.activities,
    activityAccounts: state.activity.activityAccounts,
    activityContacts: state.activity.activityContacts,
    activityTags: state.activity.activityTags,
    isLoadingUserInfo: state.user.isLoadingUserInfo,
    userInfo: state.user.userInfo,
    isLoadingTrackedUsers: state.user.isLoadingUsers,
    opportunityStages: state.organization.opportunityStages,
    trackedUsers: getTrackedUsers(state),
    isSyncing: state.user.isSyncing,
    permission: state.app.permission,
    timePeriod: selectAppDateSelection(state),
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(UserOverview);
