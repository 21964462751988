import React, { CSSProperties, ReactElement } from 'react';
import { Icon, Popup, SemanticICONS } from 'semantic-ui-react';

import Alpha from 'components/AlphaziseBackgroudColor/AlphaziseBackgroundColor';
import css from './BSBadgedIcon.module.css';
import { ActivityIcons } from '../../models/activity';

interface BSBadgeIconConstantProps {
  text?: string;
  badge: number;
  alpha?: number;
  popup?: string | ReactElement;
  size?: 'small' | 'medium';
  style?: CSSProperties;
}

interface BSBadgedIconNoActivityIconProps extends BSBadgeIconConstantProps {
  icon: SemanticICONS;
  color?: string;
  activityIcon?: never;
}

interface BSBadgedIconWithActivityIconProps extends BSBadgeIconConstantProps {
  icon?: never;
  color?: never;
  activityIcon: ActivityIcons;
}

type BSBadgeIconProps = BSBadgedIconNoActivityIconProps | BSBadgedIconWithActivityIconProps;

const BSBadgedIcon: React.FC<BSBadgeIconProps> = ({
  text,
  icon,
  activityIcon,
  badge,
  popup,
  color,
  alpha = 0.2,
  size = 'medium',
  style = {},
}) => {
  const activityIconToName: { [key in ActivityIcons]: SemanticICONS } = {
    mail: 'mail outline',
    calendar: 'calendar outline',
    sms: 'comment alternate',
    linkedin: 'linkedin',
    call: 'call',
    tasks: 'tasks',
  };
  const activityIconToColor: { [key in ActivityIcons]: string } = {
    mail: 'rgb(62,133,202)',
    calendar: 'rgb(211,91,143)',
    sms: 'rgb(147, 51, 195)',
    linkedin: 'rgb(10 102 194)',
    call: 'rgb(79,178,172)',
    tasks: 'rgb(164,83,72)',
  };
  let finalColor = color;
  let finalIcon: SemanticICONS;
  if (activityIcon) {
    finalIcon = activityIconToName[activityIcon];
    finalColor = activityIconToColor[activityIcon];
  } else finalIcon = icon || 'question';

  const finalStyles = style;
  finalStyles.color = finalColor;
  finalStyles.float = finalStyles.float || 'left';

  const badgedIcon = (
    <Alpha className={`${css.container} ${size}`} style={finalStyles} alpha={alpha}>
      <Icon name={finalIcon} />
      {text}
      <span className={css.badge}>{badge}</span>
    </Alpha>
  );
  if (popup) {
    return (
      <Popup
        basic
        content={popup}
        trigger={<div>{badgedIcon}</div>}
      />
    );
  }
  return badgedIcon;
};

export default BSBadgedIcon;
