import React, { FC, useState } from 'react';

import { ActivityCount } from 'models/activity';

import { ChartBodyComposition, TeamComparisonChartBody } from './Body/Body';
import { TeamComparisonChartHeader } from './Header/Header';
import { TeamComparisonChartLegend } from './Legend/TeamComparisonChartLegend';
import { getMaxValue, sortData } from './helperFunctions';
import {
  TeamComparisonChartContextProps,
  TeamComparisonChartProvider,
} from './TeamComparisonChartContext';

export type SortColumn = 'values' | 'names' | 'optimality';
export type SortDirection = 'ascending' | 'descending';
export type SortSettings = {
  column: SortColumn;
  direction: SortDirection;
};

export interface Value {
  sortValue: number | null;
  displayValue: string;
}

export interface MetricValue extends Value {
  sortValue: number | null;
  displayValue: string;
  percentOfBenchmarkDisplayValue: string;
  classification: Classification; // ex. 'belowAverage'
}

export interface Datum {
  userId: number;
  userName: string;
  value: MetricValue;
  target?: number;
  breakdown?: ActivityCount;
}

export interface Classification {
  name: string;
  color: string;
}

export interface Benchmark {
  value: Value;
  title: string; // e.g. "goal" | "avg"
}

interface TeamComparisonChartProps {
  data: Datum[];
  legend?: Classification[];
  higherIsBetter: boolean;
  benchmark?: Benchmark;
  sortSettings?: SortSettings;
  maxValue?: Value;
  className?: string;
}

interface TeamComparisonChartComposition extends FC<TeamComparisonChartProps> {
  Body: ChartBodyComposition;
  Header: FC;
  Legend: FC;
}

const TeamComparisonChart: TeamComparisonChartComposition = props => {
  const {
    legend = [],
    data,
    higherIsBetter,
    benchmark,
    sortSettings = { column: 'optimality', direction: 'descending' },
    children,
    className = '',
  } = props;

  const [sortSettingsState, setSortSettingsState] = useState<SortSettings | undefined>(undefined);
  const finalSortSettings = sortSettingsState !== undefined ? sortSettingsState : sortSettings;

  let { maxValue } = props;
  if (!maxValue) {
    maxValue = getMaxValue(data);
  }

  const contextProps: TeamComparisonChartContextProps = {
    data: sortData(data, finalSortSettings, higherIsBetter),
    legend,
    higherIsBetter,
    benchmark,
    sorting: {
      sortSettings: finalSortSettings,
      setSortSettings: setSortSettingsState,
    },
    maxValue,
  };
  return (
    <TeamComparisonChartProvider value={contextProps}>
      <section className={className}>
        {children}
      </section>
    </TeamComparisonChartProvider>
  );
};

TeamComparisonChart.Body = TeamComparisonChartBody;
TeamComparisonChart.Header = TeamComparisonChartHeader;
TeamComparisonChart.Legend = TeamComparisonChartLegend;

export { TeamComparisonChart };
