import React, { useState, useRef, useEffect } from 'react';
import { Input, Icon, InputOnChangeData, Menu } from 'semantic-ui-react';
import { ClickAwayListener } from 'components/ClickAwayListener/ClickAwayListener';
import useKeyboardListener from 'hooks/useKeyboardListener';
import css from './BSDropdown.module.css';

interface BSDropdownItem {
  key: string;
  value: string;
  label?: string;
  selected: boolean;
}

interface BSMultiSelectDropdownProps {
  onChange?: (value: string) => void;
  onClear?: () => void;
  onSelectAll?: () => void;
  sortOnChange?: boolean;
  items: BSDropdownItem[];
  title: string;
}

const BSDropwdown: React.FC<BSMultiSelectDropdownProps> = ({
  items,
  title,
  onChange,
  onClear,
  onSelectAll,
  sortOnChange = true,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState(false);
  const inputRef = useRef<Input | null>(null);
  const [preSelectedItemIndex, setPreSelectedItemIndex] = useState<number>(-1);

  const selectAllValue = 'selectAll';

  const selectedValues: string[] = [];
  items.forEach(item => {
    if (item.selected) {
      selectedValues.push(item.key);
    }
  });

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  const handleOnClear = (): void => {
    setSearchQuery('');
    if (onClear) {
      onClear();
    }
  };

  const handleSearchQuery = (_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
    setPreSelectedItemIndex(-1);
    setSearchQuery(data.value);
  };

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
    setPreSelectedItemIndex(-1);
  };

  const handleOnChange = (value: string): void => {
    if (value === selectAllValue) {
      if (onSelectAll) {
        onSelectAll();
        return;
      }
    }
    if (onChange) {
      // Checks if it's last item to be selected
      if (!selectedValues.includes(value) && selectedValues.length === items.length - 1) {
        if (onSelectAll) {
          onSelectAll();
          return;
        }
      }
      onChange(value);
    }
  };

  const allSelected = selectedValues.length === items.length;

  let options = items.map(item => ({
    ...item,
    selected: item.selected && !allSelected,
  }));

  if (sortOnChange) {
    options.sort((firstOpt, secondOpt) => {
      const x = firstOpt.selected === true;
      const y = secondOpt.selected === true;
      if (x === y) {
        return 0;
      }

      return x ? -1 : 1;
    });
  }

  if (onSelectAll) {
    options.unshift({
      key: 'select-all',
      value: selectAllValue,
      label: 'Select All',
      selected: allSelected,
    });
  }

  const text = `${title} (${allSelected ? items.length : selectedValues.length})`;

  options = options.filter(opt => {
    const value = opt.label ? opt.label.toLowerCase() : opt.value.toString().toLowerCase();
    const q = searchQuery.toLowerCase();
    return value.indexOf(q) >= 0;
  });


  const handleKeyDownListener = (): void => {
    if (preSelectedItemIndex === options.length - 1) {
      setPreSelectedItemIndex(0);
    } else {
      setPreSelectedItemIndex(preSelectedItemIndex + 1);
    }
  };


  const handleKeyUpListener = (): void => {
    if (preSelectedItemIndex === 0) {
      setPreSelectedItemIndex(options.length - 1);
    } else {
      setPreSelectedItemIndex(preSelectedItemIndex - 1);
    }
  };

  const handleKeyESCListener = (): void => {
    handleClose();
  };

  const handleKeyEnterListener = (): void => {
    if (preSelectedItemIndex !== -1 && options[preSelectedItemIndex]) {
      handleOnChange(options[preSelectedItemIndex].value);
      return;
    }

    if (options.length === 1) {
      handleOnChange(options[0].value);
    }
  };

  // Key Down
  useKeyboardListener(40, handleKeyDownListener);
  // Key Up
  useKeyboardListener(38, handleKeyUpListener);
  // Key esc
  useKeyboardListener(27, handleKeyESCListener);
  // Key enter
  useKeyboardListener(13, handleKeyEnterListener);

  const isPreSelected = (key: string): boolean => {
    if (preSelectedItemIndex !== -1 && options[preSelectedItemIndex]) {
      return options[preSelectedItemIndex].key === key;
    }
    return false;
  };

  return (
    <ClickAwayListener onClickAway={handleClose} className={`${css.bsDropdownContainer} ${css.line}`}>
      <div className={css.subContainer} onClick={handleOpen}>
        {open
          ? (
            <div className={css.open}>
              <Input
                input={{ size: text.length }}
                placeholder={text}
                ref={inputRef}
                className={css.searchInput}
                value={searchQuery}
                onChange={handleSearchQuery}
                icon={
                  searchQuery !== ''
                  && <Icon name="close" className={css.closeIcon} link onClick={handleOnClear} />
                }
              />
              <Menu vertical borderless className={`${css.dropdownMenu} scrolling`}>
                {options.map(option => (
                  <Menu.Item
                    key={option.key}
                    className={`${isPreSelected(option.key) ? css.preSelectedItem : ''}`}
                    onClick={(): void => handleOnChange(option.value)}
                  >
                    <div>
                      <Icon name="check" style={option.selected ? {} : { visibility: 'hidden' }} />
                      {option.label ? option.label : option.value}
                    </div>
                  </Menu.Item>
                ))}
                {options.length === 0 && <div className={css.noOptions}>No results found.</div>}
              </Menu>
            </div>
          )

          : (
            <div className={css.close}>
              <span className={css.closeLabel}>
                {text}
              </span>
            </div>
          )}
        <Icon className={css.caretDownIcon} name="caret down" />
      </div>
    </ClickAwayListener>
  );
};


export default BSDropwdown;
