import React, { useRef, useEffect } from 'react';

interface ClickAwayListenerProps {
  onClickAway: (() => void) | undefined;
  className?: string;
}

export const ClickAwayListener: React.FC<ClickAwayListenerProps> = ({ children, onClickAway, className }) => {
  const ref = useRef<any>(null);

  function handleClickOutside(event): void {
    if (ref && ref.current && !ref.current.contains(event.target) && onClickAway) {
      onClickAway();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
};
