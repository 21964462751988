import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Table, Icon, Popup } from 'semantic-ui-react';

import { updateAlertNotification } from 'actions/alert-actions';
import AlertLanguageProcessor from 'lib/AlertLanguageProcessor';
import { formatDate } from 'lib/util';
import { Notification } from 'models/alert';

import css from './RulesRowNotificationItem.module.css';

interface RulesRowNotificationItemProps {
  hoveredId: number | null;
  notification: Notification;
  onHover: Function;
}

const RulesRowNotificationItem: React.FC<RulesRowNotificationItemProps> = ({ hoveredId, notification, onHover }) => {
  const dispatch = useDispatch();

  const [isLoadingResolve, setIsLoadingResolve] = useState(false);
  const [isLoadingDismiss, setIsLoadingDismiss] = useState(false);

  const handleToast = (message: string): void => {
    toast(message, {
      position: 'top-center',
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const handleHoverNotification = (notificationId: number | null): void => {
    onHover(notificationId);
  };

  const handleResolveNotification = async (notificationId: number): Promise<void> => {
    setIsLoadingResolve(true);
    await dispatch(updateAlertNotification(notificationId, {
      seen: true,
      dismissed: false,
      resolved: true,
    }));
    handleToast('Notification resolved');
    setIsLoadingResolve(false);
  };

  const handleDismissNotification = async (notificationId: number): Promise<void> => {
    setIsLoadingDismiss(true);
    await dispatch(updateAlertNotification(notificationId, {
      seen: true,
      dismissed: true,
      resolved: false,
    }));
    handleToast('Notification dismissed');
    setIsLoadingDismiss(false);
  };

  const description = AlertLanguageProcessor.getAlertNotificationStatement({
    id: notification.id,
    userName: notification.userName,
    metric: notification.metric,
    metricName: notification.metricName,
    thresholdValue: notification.thresholdValue,
    thresholdOperator: notification.thresholdOperator,
    recordedValue: notification.recordedValue,
  });

  if (!description) {
    return null;
  }

  return (
    <motion.tr
      className={css.openedNotifications}
      initial={{ opacity: 0, scaleY: 0, height: 0 }}
      animate={{ opacity: 1, scaleY: 1, height: '100%' }}
      exit={{ opacity: 0, scaleY: 0, height: 0 }}
      transition={{ duration: 0.3 }}
      onMouseEnter={(): void => handleHoverNotification(notification.id)}
      onMouseLeave={(): void => handleHoverNotification(null)}
    >
      <Table.Cell />
      <Table.Cell>{formatDate(notification.createdAt)}</Table.Cell>
      <Table.Cell colSpan="3">
        {description}
      </Table.Cell>
      {hoveredId && hoveredId === notification.id ? (
        <>
          <Table.Cell
            className={css.actionIcon}
            onClick={(): Promise<void> => handleResolveNotification(notification.id)}
          >
            <Popup
              content="Resolve"
              hoverable
              inverted
              basic
              size="mini"
              position="bottom center"
              trigger={(
                <Icon
                  className={css.resolveIcon}
                  loading={isLoadingResolve}
                  name="check"
                />
              )}
            />
          </Table.Cell>
          <Table.Cell
            className={css.actionIcon}
            onClick={(): Promise<void> => handleDismissNotification(notification.id)}
          >
            <Popup
              content="Dismiss"
              hoverable
              inverted
              basic
              size="mini"
              position="bottom center"
              trigger={(
                <Icon
                  className={css.dismissIcon}
                  loading={isLoadingDismiss}
                  name="delete"
                />
              )}
            />
          </Table.Cell>
        </>
      ) : (
        <>
          <Table.Cell className={css.emptyCell} />
          <Table.Cell className={css.emptyCell} />
        </>
      )}
    </motion.tr>
  );
};

export default RulesRowNotificationItem;
