import { emailMetricDisplayInfo, EmailMetricName, isEmailMetricName } from 'models/metric/activityMetrics';
import { isPipelineMetricName, pipelineMetricDisplayInfo, PipelineMetricName } from 'models/metric/pipelineMetrics';
import { SemanticICONS } from 'semantic-ui-react';

export type MetricName = EmailMetricName | PipelineMetricName;

export interface MetricDisplayInfo {
  title: string;
  name: MetricName;
  higherIsBetter: boolean;
  displayValueFn: (val: number) => string;
  unitIcon: SemanticICONS;
  enabled: boolean;
  order?: number;
}

export const allMetricsDisplayInfo: {
  [stat in MetricName]: MetricDisplayInfo & { name: stat }
} = { ...emailMetricDisplayInfo, ...pipelineMetricDisplayInfo };

export const getMetricDisplayInfo = (metricName: MetricName): MetricDisplayInfo => {
  if (isEmailMetricName(metricName)) return emailMetricDisplayInfo[metricName];
  if (isPipelineMetricName(metricName)) return pipelineMetricDisplayInfo[metricName];
  throw Error(`'${metricName}' is not a valid MetricName`);
};
