import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'actions';
import jwtDecode from 'jwt-decode';
import { Modal, Button } from 'semantic-ui-react';

function getTokenSelector(state: any): string {
  return state.app.token;
}

interface Token {
  exp: number;
  iat: number;
}

interface LogoutControllerProps { }

const LogoutController: React.FC<LogoutControllerProps> = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(getTokenSelector);

  useEffect((): () => void => {
    let timeout: number;

    if (token) {
      const decodedToken = jwtDecode<Token>(token);
      const tokenExpiresInMilliseconds = (decodedToken.exp - (new Date()).getTime() / 1000) * 1000;
      if (tokenExpiresInMilliseconds > 0) {
        timeout = window.setTimeout((): void => {
          setShowLoginModal(true);
        }, tokenExpiresInMilliseconds);
      }
    }

    return (): void => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [token]);

  const handleLogout = (): void => {
    dispatch(actions.app.logout());
  };

  const handleLogin = (): void => {
    dispatch(actions.app.login());
  };

  return (
    <Modal open={showLoginModal} dimmer="blurring">
      <Modal.Header>BuyerSight Session Expired</Modal.Header>
      <Modal.Content>
        Your session has expired, please log in again.
      </Modal.Content>
      <Modal.Actions>
        <Button className="brand" primary onClick={handleLogin}>Login</Button>
        <Button onClick={handleLogout}>Logout</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default LogoutController;
