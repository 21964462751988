import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FaExpandAlt, FaCompressAlt } from 'react-icons/fa';
import { Icon, Dropdown, Segment, Popup, DropdownItemProps } from 'semantic-ui-react';
import moment, { Moment } from 'moment';

import allActions, { bindCombinedActions } from 'actions';
import { getTrackedSelectedTeamMembers, getTrackedSelectedTeamMembersForDropdown } from 'selectors/team';
import api from 'lib/api';
import { formatDate } from 'lib/util';
import { TeamMember } from 'models/team';
import { User } from 'models/user';
import { OpportunityStage } from 'models/opportunity';
import { getPipelineSettings } from 'selectors/organization';

import AccessControl, { hasPermission, Permission } from 'components/AccessControl';
import DashboardTile from 'components/DashboardTile';

import OpenPipelineChart, { OpenPipelineFilters } from '../../../components/OpenPipelineChart';
import css from './OpenPipeline.module.css';
import PipelineFilterModal from '../PipelineFilterModal';
import StageRepSelect from '../StageRepSelect';

interface Props {
  actions: any;
  activeUserId: number;
  opportunityStages: OpportunityStage[];
  opportunityTypes: string[];
  permission: Permission;
  pipelineSettings: any;
  teamMembers: TeamMember[];
  teamMembersForDropdown: DropdownItemProps[];
  users: User[];

}

interface State {
  isLoading: boolean;
  isMinimized: boolean;
  openPipelineData: any;
  startDate: Moment;
  endDate: Moment;
  filters: OpenPipelineFilters;
  timeStep: 'day' | 'week' | 'month';
  calendarActive: boolean;
  calendarActiveOption: CalendarKey;
}

type CalendarKey = '7day' | '14day' | '8week' | '12week' | '6month' | '12month';
type CalendarOptionProps = {
  label: string;
  start: Moment;
  end: Moment;
  timeStep: 'day' | 'week' | 'month';
};
const now = moment();
const calendarOptionsList: { [k in CalendarKey]: CalendarOptionProps } = {
  '7day': {
    label: 'Last 7 days',
    end: now,
    start: moment().subtract(7, 'day'),
    timeStep: 'day',
  },
  '14day': {
    label: 'Last 14 days',
    end: now,
    start: moment().subtract(14, 'day'),
    timeStep: 'day',
  },
  '8week': {
    label: 'Last 8 weeks',
    end: now,
    start: moment().subtract(8, 'week'),
    timeStep: 'week',
  },
  '12week': {
    label: 'Last 12 weeks',
    end: now,
    start: moment().subtract(12, 'week'),
    timeStep: 'week',
  },
  '6month': {
    label: 'Last 6 months',
    end: now,
    start: moment().subtract(6, 'month'),
    timeStep: 'month',
  },
  '12month': {
    label: 'Last 12 months',
    end: now,
    start: moment().subtract(12, 'month'),
    timeStep: 'month',
  },
};

const CALENDAR_OPTION_KEY = 'currentPipelineCalendarOption';
const CHART_FILTER_KEY = 'currentPipelineFilters';

class OpenPipeline extends Component<Props, State> {
  _isMounted = false;

  constructor(props) {
    super(props);

    const calendarActiveOption = localStorage.getItem(CALENDAR_OPTION_KEY) as CalendarKey;
    const calendarOptionProps = calendarOptionsList[calendarActiveOption] || calendarOptionsList['7day'];

    const filterStr = localStorage.getItem(CHART_FILTER_KEY);
    const filters = filterStr ? JSON.parse(filterStr) : { groupBy: 'stageName' };

    this.state = {
      isLoading: true,
      isMinimized: true,
      openPipelineData: [],
      startDate: calendarOptionProps.start,
      endDate: calendarOptionProps.end,
      timeStep: calendarOptionProps.timeStep,
      calendarActive: false,
      calendarActiveOption,
      filters,
    };
  }

  componentDidMount(): void {
    this._isMounted = true;
    this.fetchPipelineData();
  }

  componentDidUpdate(_, prevState: State): void {
    const { startDate, endDate, filters } = this.state;
    const startDateDiff = startDate !== prevState.startDate;
    const endDateDiff = endDate !== prevState.endDate;
    const customDiff = prevState.filters.custom !== filters.custom;
    if (startDateDiff || endDateDiff || customDiff) {
      this.fetchPipelineData();
      ((): void => {
        this.setState({ calendarActive: false });
      })();
    }
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  fetchPipelineData = async (): Promise<void> => {
    const { startDate, endDate, filters, timeStep } = this.state;
    this.setState({ openPipelineData: [], isLoading: true });
    const opts: any = { startDate, endDate, timeStep };
    if (filters.custom) {
      opts.custom = JSON.stringify(filters.custom);
    }
    const resp = await api.getPipelineOpen(opts);

    if (!resp.status) {
      // eslint-disable-next-line no-console
      console.error('Error fetching open pipeline');
    }

    if (this._isMounted) {
      this.setState({
        openPipelineData: resp.data,
        isLoading: false,
      });
    }
  };

  handleExpand = (): void => {
    this.setState((prevState: State) => ({ isMinimized: !prevState.isMinimized }));
  };

  handleCalendarChange = (value): void => {
    const newStart = moment(value[0]);
    const newEnd = moment(value[1]);
    const dateRange = newStart.diff(newEnd, 'days');

    if (dateRange > 90) {
      this.setState({ timeStep: 'month', calendarActive: false, startDate: newStart, endDate: newEnd });
    } else if (dateRange > 14) {
      this.setState({ timeStep: 'week', calendarActive: false, startDate: newStart, endDate: newEnd });
    } else {
      this.setState({ timeStep: 'day', calendarActive: false, startDate: newStart, endDate: newEnd });
    }
  };

  handleDownloadChart = async (): Promise<void> => {
    const { endDate } = this.state;
    const resp = await api.getPipelineOpenUnderlying(endDate.format('YYYY-MM-DD'));
    if (!resp.status) {
      return;
    }
    const { data: rawData } = resp;
    const title = 'Open pipeline';
    const header: string[] = [];
    const data: any[][] = [];

    const flattenedData: Array<any> = Object.values(rawData).flat();

    if (flattenedData.length === 0) return;

    Object.keys(flattenedData[0]).forEach((key: string): void => {
      header.push(key);
    });

    flattenedData.forEach(rowData => {
      data.push(Object.values(rowData).map(d => `"${d}"`));
    });

    const fileName = `${endDate.format('YYYYMMDD')}_${title}`;

    const rows = [header, ...data];
    const csvContent = `data:text/csv;charset=utf-8,${rows.map(e => e.join(',')).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  handleDateClick = key => (): void => {
    const buttonProps = calendarOptionsList[key];
    localStorage.setItem(CALENDAR_OPTION_KEY, key);
    this.setState({
      startDate: buttonProps.start,
      endDate: buttonProps.end,
      timeStep: buttonProps.timeStep,
      calendarActive: false,
      calendarActiveOption: key,
    });
  };

  setMenuState = (): void => {
    this.setState(prevState => ({ calendarActive: !prevState.calendarActive }));
  };

  handleGroupByChange = (groupBy): void => {
    this.setState(prevState => {
      const newFilters: OpenPipelineFilters = {
        ...prevState.filters,
        groupBy,
      };
      localStorage.setItem(CHART_FILTER_KEY, JSON.stringify(newFilters));
      return { filters: newFilters };
    });
  };

  handleFilterChange = (_, data): void => {
    this.setState(prevState => {
      const newFilters: OpenPipelineFilters = {
        ...prevState.filters,
        ownerIds: data?.owners,
        stageNames: data?.stages,
        opportunityTypes: data?.types,
        custom: data?.custom,
      };
      localStorage.setItem(CHART_FILTER_KEY, JSON.stringify(newFilters));
      return { filters: newFilters };
    });
  };

  render(): React.ReactNode {
    const {
      opportunityStages,
      opportunityTypes,
      permission,
      pipelineSettings,
      teamMembersForDropdown,
      users,
    } = this.props;
    const {
      calendarActiveOption,
      endDate,
      filters,
      isLoading,
      isMinimized,
      openPipelineData,
      startDate,
      timeStep,
    } = this.state;

    const format = 'MMM DD, YYYY';
    const label = `${formatDate(startDate, format)} - ${formatDate(endDate, format)}`;
    const buttons = Object.keys(calendarOptionsList).map(key => (
      <Dropdown.Item
        key={key}
        active={key === calendarActiveOption}
        className={css.presetButtons}
        text={calendarOptionsList[key].label}
        onClick={this.handleDateClick(key)}
      />
    ));

    const stageOptions = opportunityStages.map(stage => {
      const stageOption = {
        key: stage.masterLabel,
        text: stage.masterLabel,
        value: stage.masterLabel,
      };
      return stageOption;
    });

    const content: JSX.Element = openPipelineData.length === 0 ? (
      <Segment padded>{`There is no opportunity data for the selected time range ${label}`}</Segment>
    ) : (
      <>
        <div className={css.chartContainer}>
          <div>
            <div className={css.chartTitle}>
              Open Pipeline
            </div>
            <div className={css.chartSubTitle}>
              <Dropdown className={css.calendarDropdown} clearable item text={label} direction="right">
                <Dropdown.Menu>
                  {buttons}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <OpenPipelineChart
              filters={filters}
              isLoading={isLoading}
              isMinimized={isMinimized}
              stages={opportunityStages}
              pipelineData={openPipelineData}
              userMap={users}
              timeUnit={timeStep}
            />
          </div>
        </div>
      </>
    );

    const cornerIcon = isMinimized
      ? (<FaExpandAlt onClick={this.handleExpand} />)
      : (<FaCompressAlt onClick={this.handleExpand} />);

    const opportunityTypeOptions = opportunityTypes.map(o => ({ key: o, text: o, value: o }));
    const ownerOptions = hasPermission(permission, 'manager') ? teamMembersForDropdown : undefined;
    return (
      <DashboardTile onClose={this.handleExpand} isMinimized={isMinimized} isLoading={isLoading}>
        <div className={css.actionsContainer}>
          <AccessControl accessingTo="manager">
            <StageRepSelect
              groupBy={filters.groupBy}
              onClick={this.handleGroupByChange}
            />
          </AccessControl>
          <PipelineFilterModal
            filters={filters}
            onChange={this.handleFilterChange}
            opportunityTypeOptions={opportunityTypeOptions}
            ownerOptions={ownerOptions}
            opportunityStageOptions={stageOptions}
            customFilters={pipelineSettings?.customFilters}
          />
          <div className="link brand">
            <Popup
              inverted
              basic
              size="mini"
              position="bottom center"
              style={{ backgroundColor: '#5A5B5D' }}
              content="Download"
              trigger={<Icon name="download" title="Download as CSV" onClick={this.handleDownloadChart} />}
            />
          </div>
          <div className="link brand">
            <Popup
              inverted
              basic
              size="mini"
              position="bottom center"
              style={{ backgroundColor: '#5A5B5D' }}
              content={isMinimized ? 'Expand' : 'Collapse'}
              trigger={cornerIcon}
            />
          </div>
        </div>
        {content}
      </DashboardTile>
    );
  }
}

export default connect(
  (state: any) => ({
    activeUserId: state.app.id,
    pipelineSettings: getPipelineSettings(state),
    opportunityTypes: state.organization.opportunityTypes,
    opportunityStages: state.organization.opportunityStages,
    permission: state.app.permission,
    teamMembers: state.team.teamMembers,
    teamMembersForDropdown: getTrackedSelectedTeamMembersForDropdown(state),
    users: getTrackedSelectedTeamMembers(state),
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(OpenPipeline);
