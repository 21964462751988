import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Segment } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import { FaCompressAlt, FaExpandAlt } from 'react-icons/fa';

import { funnelStatsApi } from 'features/Api';

import DashboardTile from 'components/DashboardTile';
import Funnel from 'components/Funnel';
import FunnelFilter from 'components/FunnelFilter';
import { RootState } from 'store';

import css from './styles.module.css';

export function FunnelWidget(props: {
  userId: number;
  teamId: number;
  startDateIso: string;
  endDateIso: string;
}): ReactElement {
  const { userId, teamId, startDateIso, endDateIso } = props;

  const [isMinimized, setIsMinimized] = useState(true);

  const filter = useSelector((state: RootState) => state.funnel.filter);

  const {
    data: funnelStatsApiResponse,
    isFetching,
  } = funnelStatsApi.endpoints.getFunnelStats.useQuery({
    teamId,
    startDate: DateTime.fromISO(startDateIso).toFormat('yyyyMMdd'),
    endDate: DateTime.fromISO(endDateIso).toFormat('yyyyMMdd'),
    filter: { ...filter, ownerId: userId },
  });

  if (!isFetching && !funnelStatsApiResponse?.stats) {
    return (<Segment>Error Retrieving funnel</Segment>);
  }

  const stats = funnelStatsApiResponse?.stats || [];

  const handleExpand = (): void => {
    setIsMinimized(!isMinimized);
  };

  const cornerIcon = isMinimized
    ? (<FaExpandAlt onClick={handleExpand} />)
    : (<FaCompressAlt onClick={handleExpand} />);

  return (
    <DashboardTile onClose={handleExpand} isLoading={isFetching} isMinimized={isMinimized}>
      <div className={css.funnelHeader}>
        <div className={css.header}>
          <h4>
            Prospecting Funnel
          </h4>
        </div>
        <div className={css.actionsContainer}>
          <FunnelFilter />
          <div className="link brand">
            {cornerIcon}
          </div>
        </div>
      </div>
      <Funnel stats={stats} expanded={!isMinimized} />
    </DashboardTile>
  );
}
