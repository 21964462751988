import React, { useRef, useEffect, useState } from 'react';
import { Input, TableProps, InputOnChangeData, TableHeaderCellProps } from 'semantic-ui-react';

import { useSearchable } from 'hooks/useSearchable';
import useKeyboardListener from 'hooks/useKeyboardListener';
import { ColumnType } from 'components/withSortableTable';
import ClearableInput from 'components/Input/ClearableInput';
import SortableTable from './SortableTable';

import css from './BSTable.module.css';

interface TableColumnProperty {
  key: string;
  title: string;
  headerCellProps?: TableHeaderCellProps;
  sortType?: ColumnType;
  sort?: boolean;
}

export interface BSTableProps<T> extends TableProps {
  data: T[];
  properties: TableColumnProperty[];
  searchable?: boolean;
  sortable?: boolean;
  searchKey?: string;
  searchPlaceholder?: string;
  onRowSelect?: (rowData: T) => void;
  renderRow?: (rowData: T, index: number, array: T[]) => React.ReactNode;
}

// Weird definition of component so that we can use generics inside props.
// https://stackoverflow.com/questions/53958028/how-to-use-generics-in-props-in-react-in-a-functional-component
function BSTable<T>({
  data,
  properties,
  searchKey = '',
  searchPlaceholder = 'Search',
  searchable = true,
  sortable = true,
  onRowSelect,
  ...tableProps
}: BSTableProps<T>): React.ReactElement {
  const [searchedData, searchQuery, setSearchQuery] = useSearchable(data, searchKey);
  const [focused, setFocused] = useState(false);
  const searchInputRef = useRef<Input>(null);

  useEffect(() => {
    if (searchInputRef.current !== null) {
      searchInputRef.current.focus();
    }
  }, [searchInputRef]);

  const handleOneItemRowSelect = (): void => {
    if (searchedData.length === 1 && onRowSelect !== undefined && focused) {
      onRowSelect(searchedData[0]);
    }
  };

  useKeyboardListener(13, handleOneItemRowSelect);

  const handleSearch = (_: React.ChangeEvent<HTMLInputElement>, searchData: InputOnChangeData): void => {
    const searchTerm = searchData.value.trim();
    setSearchQuery(searchTerm);
  };

  const handleOnFocus = (): void => {
    setFocused(true);
  };

  const handleOnBlur = (): void => {
    setFocused(false);
  };

  return (
    <div className={css.searchableTableContainer} onFocus={handleOnFocus} onBlur={handleOnBlur}>
      {searchable
        && (
        <div className={css.searchContainer}>
          <ClearableInput
            ref={searchInputRef}
            className={css.searchInput}
            value={searchQuery}
            onChange={handleSearch}
            placeholder={searchPlaceholder}
          />
        </div>
        )}
      <div className={css.tableContainer}>
        <SortableTable<T>
          data={searchedData}
          isSortable={sortable}
          properties={properties}
          onRowSelect={onRowSelect}
          {...tableProps}
        />
      </div>
    </div>
  );
}

export default BSTable;
