import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { getSelectedTeam, getTeamsOfUser } from 'selectors/team';
import { connect } from 'react-redux';
import allActions, { bindCombinedActions } from 'actions';
import { Team, TeamWithMembers } from 'models/team';

interface PageActionsHeaderProps extends RouteComponentProps {
  userTeams: Team[];
  actions: any;
  selectedTeam: TeamWithMembers | undefined;
}

const teamDropdownRoutes = [
  '/app',
  '/app/account',
  '/app/alerts',
  '/app/comparison',
  '/app/funnel',
  '/app/pipeline',
  '/app/team',
];

const PageActionsHeader: React.FC<PageActionsHeaderProps> = ({ location, userTeams, actions, selectedTeam }) => {
  if (teamDropdownRoutes.includes(location.pathname)) {
    const teamNames = userTeams.map(team => (
      {
        key: team.id,
        value: team.id,
        text: team.name,
      }
    ));

    const handleSelectedTeamChange = (_: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void => {
      actions.team.setSelectedTeamId(data.value);
      actions.team.fetchTeamSettings(data.value);
      if (data.value) {
        sessionStorage.setItem('selectedTeamId', data.value.toString());
      } else {
        sessionStorage.removeItem('selectedTeamId');
      }
    };

    return (
      <Dropdown
        item
        value={selectedTeam && selectedTeam.id}
        placeholder="Teams"
        onChange={handleSelectedTeamChange}
        options={teamNames}
      />
    );
  }
  return (
    <>
    </>
  );
};

export default withRouter(
  connect(
    (state: any) => ({
      userTeams: getTeamsOfUser(state),
      selectedTeam: getSelectedTeam(state),
    }),
    dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
  )(PageActionsHeader),
);
