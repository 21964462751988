import { Chart, ChartConfiguration, registerables } from 'chart.js';
import React, { ReactElement, useCallback } from 'react';
import { Image } from 'semantic-ui-react';
import css from './styles.module.css';

const BLUE = '#335FCC';
const LIGHT_BLUE = '#E6F1FF';
const RED = '#db2828';
const LIGHT_RED = '#ffdada';
const GREEN = '#44af69';
const LIGHT_GREEN = '#e3f3e9';

const getChartConfig = (
  value: number,
  target: number,
  primaryColor = BLUE,
  secondaryColor = LIGHT_BLUE,
): ChartConfiguration<'doughnut'> => {
  const realPct = Math.floor((value / target) * 100);
  const cappedPct = Math.min(realPct, 100);
  const remainder = 100 - cappedPct;
  return {
    type: 'doughnut',
    data: {
      datasets: [
        {
          backgroundColor: [primaryColor, secondaryColor],
          data: [cappedPct, remainder],
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      circumference: 180,
      rotation: -90,
      cutout: '70%',
      maintainAspectRatio: false,
      responsive: true,
    },
  };
};

export default (props: { value: number; target: number; periodComplete: boolean; goalMet: boolean }): ReactElement => {
  let chart;
  const { value, target, periodComplete, goalMet } = props;
  const realPct = Math.floor((value / target) * 100);
  let primaryColor = BLUE;
  let secondaryColor = LIGHT_BLUE;
  if (goalMet) {
    primaryColor = GREEN;
    secondaryColor = LIGHT_GREEN;
  } else if (periodComplete) {
    primaryColor = RED;
    secondaryColor = LIGHT_RED;
  }
  const styles = { color: primaryColor };
  const config = getChartConfig(value, target, primaryColor, secondaryColor);
  const canvasRef = useCallback(canvasNode => {
    if (chart) {
      // destroy the old chart if it exists before making a new one
      chart.destroy();
    }
    if (canvasNode) {
      const ctx = canvasNode.getContext('2d');
      if (ctx) {
        Chart.register(...registerables);
        chart = new Chart(ctx, config);
      }
    }
  }, [value, target]);
  return (
    <Image as="div" className={css.Arc}>
      <div className={css.label} style={styles}>{`${realPct}%`}</div>
      <canvas ref={canvasRef} />
    </Image>
  );
};
