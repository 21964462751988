import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Input, Segment } from 'semantic-ui-react';
import allActions, { bindCombinedActions } from 'actions';
import { User } from 'models/user';
import UserManageList from 'components/UserList/UserManageList';
import withSearchable, { WithSearchable } from 'hocs/withSearchable';
import AddUserModal from 'components/Modals/AddUserModal';
import EditUserModal from 'components/Modals/EditUserModal';

import css from './UserManage.module.css';

interface Props extends WithSearchable<User> {
  actions: any;
  isLoadingUsers: boolean;
  users: User[];
}

interface State {
  addUserModalOpen: boolean;
  editUserModalOpen: boolean;
  editingUser: User | undefined;
}

class UserManage extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      addUserModalOpen: false,
      editUserModalOpen: false,
      editingUser: undefined,
    };
  }

  componentDidMount(): void {
    const { actions, users } = this.props;

    if (users.length === 0) {
      actions.user.fetchUsers();
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const { users } = this.props;
    const { editingUser } = this.state;
    if (editingUser !== undefined && prevProps.users !== users) {
      const newUser = users.find((u): boolean => u.id === editingUser!.id);
      ((): void => { this.setState({ editingUser: newUser }); })();
    }
  }

  handleOpenAddUserModal = (): void => {
    this.setState({ addUserModalOpen: true });
  }

  handleCloseAddUserModal = (): void => {
    this.setState({ addUserModalOpen: false });
  }

  handleOpenEditUserModal = (user: User): void => {
    this.setState({ editUserModalOpen: true, editingUser: user });
  }

  handleCloseEditModal = (): void => {
    this.setState({ editUserModalOpen: false, editingUser: undefined });
  }

  handleSearch = (_, data): void => {
    const { setSearchQuery } = this.props;
    const searchTerm = data.value.trim();
    setSearchQuery(searchTerm);
  };

  render(): React.ReactNode {
    const { addUserModalOpen, editUserModalOpen, editingUser } = this.state;
    const { searchedEntities } = this.props;

    return (
      <div className={css.userManage}>
        <Segment>
          <Input
            fluid
            onChange={this.handleSearch}
            placeholder="Filter by name or email"
          />
        </Segment>
        <Segment>
          <div className={css.headerContainer}>
            <div>
              <Header floated="left">
                User management
              </Header>
            </div>
          </div>
          <UserManageList
            handleOpenAddUserModal={this.handleOpenAddUserModal}
            handleOpenEditUserModal={this.handleOpenEditUserModal}
            users={searchedEntities}
          />
        </Segment>
        {addUserModalOpen
          && (
            <AddUserModal
              onClose={this.handleCloseAddUserModal}
              open={addUserModalOpen}
            />
          )}
        {editUserModalOpen && (
          <EditUserModal
            user={editingUser!}
            onClose={this.handleCloseEditModal}
            open={editUserModalOpen}
          />
        )}
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    isLoadingUsers: state.user.isLoadingUsers,
    users: state.user.users,
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(withSearchable<User, Props>(UserManage, 'users', 'name', false));
