import React, { Component, SyntheticEvent } from 'react';
import { Button, Form, Header, Icon, Segment, Table, Checkbox, Popup } from 'semantic-ui-react';
import moment from 'moment';

import ActivityHistory from '../../../../../components/ActivityHistory';
import BSIcon from '../../../../../components/BSIcon';

import css from './Rule.module.css';
import { ActivityIcons } from '../../../../../models/activity';

interface Props {
  onApply?: Function;
  onDelete?: Function;
  onPreview: Function;
  onSave: Function;
  rule: {
    id?: number;
    tagName?: string;
    has: Array<string>;
    doesNotHave: Array<string>;
    type: Array<ActivityIcons>;
    lastModified?: string;
    [x: string]: any;
  };
  tags: Array<any>;
}
interface State {
  apply: boolean;
  doesNotHave: Array<string>;
  errors: {
    count: number;
    doesNotHave?: Array<string>;
    has?: Array<string>;
    tagName?: string;
    type?: Array<string>;
  };
  has: Array<string>;
  isEditing: boolean;
  previewActivities: Array<any>;
  showPreview: boolean;
  tagName: string;
  tagOptions: Array<any>;
  type: Array<ActivityIcons>;
}

export default class Rule extends Component<Props, State> {
  public static defaultProps = {
    rule: {
      id: null,
      tagName: null,
      has: [],
      doesNotHave: [],
      type: [],
      lastModified: null,
    },
  };

  constructor(props) {
    super(props);
    const { rule } = props;

    this.state = {
      apply: true,
      doesNotHave: rule.doesNotHave || [],
      errors: { count: 0 },
      has: rule.has || [],
      isEditing: false,
      previewActivities: [],
      showPreview: false,
      tagName: rule.tagName || '',
      tagOptions: this.getTagOptions(),
      type: rule.type || [],
    };
  }

  componentDidUpdate(prevProps): void {
    const { tags } = this.props;
    if (prevProps.tags !== tags) {
      ((): void => { this.setState({ tagOptions: this.getTagOptions() }); })();
    }
  }

  getTagOptions = (): Array<{ key: string; value: string; text: string }> => {
    const { rule, tags } = this.props;
    const tagNames: any = {};
    if (rule.tagName) {
      tagNames[rule.tagName] = true;
    }
    tags.forEach(t => {
      tagNames[t.tagName] = true;
    });
    rule.has.forEach(t => {
      tagNames[t] = true;
    });
    rule.doesNotHave.forEach(t => {
      tagNames[t] = true;
    });
    return Object.keys(tagNames).map(t => ({ key: t, value: t, text: t })).sort((l, r) => (l.text < r.text ? -1 : 1));
  }

  handleAddition = (_, data): void => {
    const { value } = data;
    this.setState(prevState => ({ tagOptions: [{ text: value, value }, ...prevState.tagOptions] }));
  };

  handleApply = (e: SyntheticEvent): void => {
    e.stopPropagation();
    const { onApply, rule } = this.props;
    if (onApply && rule.id) {
      onApply(rule.id);
    }
  }

  handleChange = (e: SyntheticEvent, data): void => {
    e.stopPropagation();
    const { name, value } = data;
    const newState = { previewActivities: [], showPreview: false };
    newState[name] = value;
    this.setState(newState);
  };

  handleDelete = (e: SyntheticEvent): void => {
    e.stopPropagation();
    const { onDelete, rule } = this.props;
    if (onDelete && rule.id) {
      onDelete(rule);
    }
  }

  handleCancel = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    const { rule } = this.props;
    this.setState({
      errors: { count: 0 },
      doesNotHave: rule.doesNotHave || [],
      has: rule.has || [],
      isEditing: false,
      previewActivities: [],
      showPreview: false,
      tagName: rule.tagName || '',
      type: rule.type || [],
    });
  };

  handlePreview = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault();
    const { onPreview } = this.props;
    const { has, doesNotHave, type } = this.state;
    const searchObj: any = {
      has,
      doesNotHave,
      type,
      limit: 10,
      endDate: moment().format('YYYY-MM-DD HH:mm:ss'),
    };
    const response = await onPreview(searchObj);
    if (response.status && response.activities) {
      this.setState({ previewActivities: response.activities, showPreview: true });
    }
  };

  handleSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault();
    const { onApply, onSave, rule } = this.props;
    const { apply, has, doesNotHave, tagName, type } = this.state;
    const saveObj: any = {
      has,
      doesNotHave,
      tagName,
      type,
    };
    if (rule.id) {
      saveObj.id = rule.id;
    }

    const response = await onSave(saveObj);
    if (response.status) {
      if (onApply && apply) {
        onApply(response.rule.id);
      }
      const newRule = rule.id ? response.rule : {};
      this.setState({
        errors: { count: 0 },
        isEditing: false,
        doesNotHave: newRule.doesNotHave,
        has: newRule.has,
        previewActivities: [],
        showPreview: false,
        tagName: newRule.tagName,
        type: newRule.type,
      });
    } else if (response.error === 'INVALID_RULE') {
      this.setState({ errors: response.errors });
    }
  };

  handleTypeChange = (_, data): void => {
    const { type } = this.state;
    const { checked, name } = data;
    if (checked) {
      this.setState({ type: type.concat(name), showPreview: false });
    } else {
      this.setState({ type: type.filter((t): boolean => (t !== name)), showPreview: false });
    }
  };

  renderRule = (): React.ReactNode => {
    const { rule } = this.props;

    if (!rule.id) {
      return (
        <Table.Row className={css.ruleRow}>
          <Table.Cell colSpan="6">
            <Button content="Add New Rule" icon="plus" onClick={(): void => { this.setState({ isEditing: true }); }} />
          </Table.Cell>
        </Table.Row>
      );
    }

    let lastModified = '';
    if (rule.lastModified) {
      lastModified = moment().isSame(rule.lastModified, 'day')
        ? `Today ${moment(rule.lastModified).format('h:mma')}`
        : moment(rule.lastModified).format('MMM DD, YYYY h:mma');
    }

    return (
      <Table.Row onClick={(): void => { this.setState({ isEditing: true }); }} className={`${css.ruleRow}`}>
        <Table.Cell>{rule.tagName}</Table.Cell>
        <Table.Cell>{rule.has ? rule.has.join(', ') : null}</Table.Cell>
        <Table.Cell>{rule.doesNotHave ? rule.doesNotHave.join(', ') : null}</Table.Cell>
        <Table.Cell>{this.renderTypes(rule.type)}</Table.Cell>
        <Table.Cell>{lastModified}</Table.Cell>
        <Table.Cell className={css.editCell}>
          <div className="link brand" onClick={(): void => { this.setState({ isEditing: true }); }}>
            <Popup
              content="Edit"
              hoverable
              inverted
              basic
              size="mini"
              position="bottom center"
              trigger={(<Icon name="edit" title="edit" />)}
            />
          </div>
          <div className="link brand" onClick={this.handleDelete}>
            <Popup
              content="Delete"
              hoverable
              inverted
              basic
              size="mini"
              position="bottom center"
              trigger={(<Icon name="trash" title="delete" />)}
            />
          </div>
          <div className="link brand" onClick={this.handleApply}>
            <Popup
              content="Apply"
              hoverable
              inverted
              basic
              size="mini"
              position="bottom center"
              trigger={(<Icon name="arrow circle up" title="apply" />)}
            />
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  renderTypes(type?: Array<ActivityIcons>): React.ReactNode {
    const activityIconsToRender: ActivityIcons[] = (type && type.length) ? type : ['mail', 'call', 'calendar', 'tasks'];
    return activityIconsToRender.map(
      icon => (
        <BSIcon key={icon} activityIcon={icon} circular bordered />
      ),
    );
  }

  renderEdit = (): React.ReactNode => {
    const { rule } = this.props;
    const { apply, doesNotHave, errors, has, previewActivities, showPreview, tagName, tagOptions } = this.state;

    const formErrors: any = {};
    if (errors.count) {
      if (errors.tagName) {
        formErrors.tagName = { content: errors.tagName };
      }
      if (errors.has && errors.has.length) {
        formErrors.has = { content: errors.has.join(', ') };
      }
      if (errors.doesNotHave && errors.doesNotHave.length) {
        formErrors.doesNotHave = { content: errors.doesNotHave.join(', ') };
      }
    }

    const title = rule.id ? 'Editing Tag Rule' : 'Adding New Tag Rule';
    const handleCheckChange = (): void => {
      this.setState({ apply: !apply, showPreview: (!apply && !!previewActivities.length) });
    };
    let previewSection: React.ReactNode;
    if (showPreview) {
      previewSection = previewActivities.length ? (
        <>
          <Header>Preview Tagged Activities</Header>
          <ActivityHistory
            activityHistory={previewActivities}
            alwaysExpand
            endDate={moment(previewActivities[0].activityDate)}
            startDate={moment(previewActivities[previewActivities.length - 1].activityDate)}
          />
        </>
      ) : ('No activities match the defined rule');
    }
    return (
      <Table.Row className={`${css.ruleRow} ${css.editingRow}`}>
        <Table.Cell colSpan="6">
          <Segment>
            <Header>{title}</Header>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group>
                <Form.Dropdown
                  allowAdditions
                  error={formErrors.tagName}
                  label="Tag"
                  name="tagName"
                  onAddItem={this.handleAddition}
                  onChange={this.handleChange}
                  options={tagOptions}
                  search
                  selection
                  value={tagName}
                  width={4}
                />
                <Form.Dropdown
                  allowAdditions
                  error={formErrors.has}
                  label="Has"
                  multiple
                  name="has"
                  onAddItem={this.handleAddition}
                  onChange={this.handleChange}
                  options={tagOptions}
                  search
                  selection
                  value={has}
                  width={5}
                />
                <Form.Dropdown
                  allowAdditions
                  error={formErrors.doesNotHave}
                  label="Does Not Have"
                  multiple
                  name="doesNotHave"
                  onAddItem={this.handleAddition}
                  onChange={this.handleChange}
                  options={tagOptions}
                  search
                  selection
                  value={doesNotHave}
                  width={5}
                />
                <Form.Field width={2}>
                  <label>Activity Types</label>
                  {this.renderEditTypes()}
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Checkbox label="Apply on Save" name="apply" checked={apply} onChange={handleCheckChange} />
              </Form.Group>
              <Form.Group className={css.editButtons}>
                <Form.Button content="Preview" disabled={!apply} onClick={this.handlePreview} />
                <Form.Button content="Cancel" onClick={this.handleCancel} />
                <Form.Button content="Save" primary className="brand" />
              </Form.Group>
            </Form>
            {previewSection}
          </Segment>
        </Table.Cell>
      </Table.Row>
    );
  };

  renderEditTypes = (): React.ReactNode => {
    const { type } = this.state;
    const allTypes: ActivityIcons[] = ['mail', 'call', 'calendar', 'tasks'];
    const icons = allTypes.map(icon => {
      const checked = type && type.includes(icon);
      return (
        <Form.Checkbox
          key={icon}
          name={icon}
          label={icon}
          checked={checked}
          onChange={this.handleTypeChange}
        />
      );
    });
    return icons;
  };

  render(): React.ReactNode {
    const { isEditing } = this.state;
    return isEditing ? this.renderEdit() : this.renderRule();
  }
}
