import { handleActions } from 'redux-actions';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import actions from 'actions';
import { Activity, ActivityAccount, ActivityContact, ActivityOwner, Tag, Rule } from 'models/activity';
import { Reducer } from 'redux';

export interface ActivityState {
  selectedId?: number;
  activities: Array<Activity>;
  activityAccounts: Array<ActivityAccount>;
  activityContacts: Array<ActivityContact>;
  activityOwners: Array<ActivityOwner>;
  activityTags: Array<Tag>;
  isLoadingActivities: boolean;
  activityErrorMsg: string;
  tags: Array<Tag>;
  isLoadingTags: boolean;
  rules: Array<Rule>;
  isLoadingRules: boolean;
  isAddingTag: boolean;
  isRemovingTag: boolean;
  searchHash: string;
}

export const initialState: ActivityState = {
  selectedId: undefined,
  activities: [],
  activityAccounts: [],
  activityContacts: [],
  activityOwners: [],
  activityTags: [],
  isLoadingActivities: false,
  activityErrorMsg: '',
  tags: [],
  classifications: [],
  isLoadingTags: false,
  rules: [],
  isLoadingRules: false,
  isAddingTag: false,
  isRemovingTag: false,
  searchHash: '',
};

const reducer: Reducer<any, any> = handleActions(
  {
    // GET ACTIVITIES
    [actions.activity.clearActivities]: state => ({
      ...state,
      activities: [],
      activityOwners: [],
      activityAccounts: [],
      activityTags: [],
      searchHash: '',
    }),
    [actions.activity.searchActivitiesRequest]: state => ({ ...state, isLoadingActivities: true }),
    [actions.activity.searchActivitiesSuccess]: (state, { payload: { activities } }) => ({
      ...state,
      activities,
      isLoadingActivities: false,
    }),
    [actions.activity.searchActivitiesFailure]: state => ({ ...state, isLoadingActivities: false }),
    [actions.activity.setActivityAccounts]: (state, { payload: { activityAccounts } }) => ({
      ...state,
      activityAccounts,
    }),
    [actions.activity.setActivityOwners]: (state, { payload: { activityOwners } }) => ({
      ...state,
      activityOwners,
    }),
    [actions.activity.setActivityTags]: (state, { payload: { activityTags } }) => ({
      ...state,
      activityTags,
    }),
    [actions.activity.setActivityContacts]: (state, { payload: { activityContacts } }) => ({
      ...state,
      activityContacts,
    }),
    [actions.activity.selectActivity]: (state, { payload: { activityId } }) => (
      { ...state, selectedId: activityId }
    ),
    [actions.activity.unselectActivity]: state => ({
      ...state,
      selectedId: undefined,
    }),
    // GET TAGS
    [actions.activity.getTagsRequest]: state => ({ ...state, isLoadingTags: true }),
    [actions.activity.getTagsSuccess]: (state, { payload: { tags } }) => ({
      ...state,
      isLoadingTags: false,
      tags,
    }),
    [actions.activity.getTagsFailure]: state => ({ ...state, isLoadingTags: false }),
    // GET TAG RULES
    [actions.activity.getTagRulesRequest]: state => ({ ...state, isLoadingRules: true }),
    [actions.activity.getTagRulesSuccess]: (state, { payload: { rules } }) => ({
      ...state,
      isLoadingRules: false,
      rules,
    }),
    [actions.activity.getTagRulessFailure]: state => ({ ...state, isLoadingRules: false }),
    // SAVE TAG RULE
    [actions.activity.saveTagRuleSuccess]: (state, { payload: { rule } }) => {
      const i = findIndex(state.rules, { id: rule.id });
      const rules = cloneDeep(state.rules);
      if (i > -1) {
        rules[i] = rule;
      } else {
        rules.push(rule);
      }
      return { ...state, rules };
    },
    // DELETE TAG RULE
    [actions.activity.deleteTagRuleSuccess]: (state, { payload: { id } }) => {
      const rules = state.rules.filter(r => (r.id !== id));
      return { ...state, rules };
    },
    // ADD TAG
    [actions.activity.addTagRequest]: state => ({ ...state, isAddingTag: true }),
    [actions.activity.addTagSuccess]: state => ({ ...state, isAddingTag: false }),
    [actions.activity.addTagFailure]: state => ({ ...state, isAddingTag: false }),
    // REMOVE TAG
    [actions.activity.removeTagRequest]: state => ({ ...state, isRemovingTag: true }),
    [actions.activity.removeTagSuccess]: state => ({ ...state, isRemovingTag: false }),
    [actions.activity.removeTagFailure]: state => ({ ...state, isRemovingTag: false }),
    [actions.activity.setActivitySearchHash]: (state, { payload: { searchHash } }) => ({ ...state, searchHash }),

    // SET TAGS TO ACTIVITY
    [actions.activity.setTagsToActivity]: (state, { payload }) => {
      const activities = state.activities.map(activity => {
        if (activity.id === payload.activityId) {
          return {
            ...activity,
            tags: payload.tags.map(t => t.tagName),
            classifications: payload.classifications.map(t => t.classificationName),
          };
        }
        return activity;
      });

      return { ...state, activities };
    },
  },
  initialState,
);

export default reducer;
