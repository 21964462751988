import RecommendationCard
  from 'App/Main/ProspectingHealthRoute/ProspectingContactDetailModal/ContactRecommendations/RecommendationCard';
import { ProspectingMetricCodename } from 'features/Api/Prospecting';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Header, Loader, MenuItem, Popup, Segment, Tab, TabPaneProps } from 'semantic-ui-react';

import { getSelectedTeamId } from 'selectors/team';
import { prospectingApi } from 'features/Api';

import css from './styles.module.css';

interface StrategyInsightsProps {}

const StrategyInsights: React.FC<StrategyInsightsProps> = (): React.ReactElement => {
  const teamId = useSelector(getSelectedTeamId);

  if (!teamId) {
    return (<Segment className={css.strategyInsights}>No team selected</Segment>);
  }

  const {
    data,
    isLoading,
  } = prospectingApi.endpoints.getProspectingStrategyInsights.useQuery({ teamId });

  if (isLoading) {
    return (<Segment><Loader /></Segment>);
  }
  if (!data?.status) {
    return (<Segment>Error retrieving insights</Segment>);
  }

  const { preConversation, postConversation } = data;

  const preConversationCards = preConversation.map(t => {
    const { id, metricName } = t;
    const codename = metricName as ProspectingMetricCodename;
    return <RecommendationCard codename={codename} partition="preConversation" key={id} />;
  });

  const postConversationCards = postConversation.map(t => {
    const { id, metricName } = t;
    const codename = metricName as ProspectingMetricCodename;
    return <RecommendationCard codename={codename} partition="postConversation" key={id} />;
  });

  const preConversationMenuItem = (
    <MenuItem key="preConversationMenuItem">
      <Popup
        basic
        content="Before your team gets a connection, which could be an inbound email, connected call, or meeting."
        trigger={(<div>Pre-conversation</div>)}
      />
    </MenuItem>
  );

  const postConversationMenuItem = (
    <MenuItem key="postConversationMenuItem">
      <Popup
        basic
        content="After your team gets a connection with a prospect (inbound email, connected call, or meeting), but before they hit their goal."
        trigger={(<div>Conversations</div>)}
      />
    </MenuItem>
  );

  const panes: TabPaneProps['panes'] = [
    {
      menuItem: preConversationMenuItem,
      render: (): ReactElement => (
        <Tab.Pane loading={isLoading} className={css.pane}>
          <Header as="h3">
            Averages when your prospecting gets a conversation:
          </Header>
          {preConversationCards}
        </Tab.Pane>
      ),
    },
    {
      menuItem: postConversationMenuItem,
      render: (): ReactElement => (
        <Tab.Pane loading={isLoading} className={css.pane}>
          <Header as="h3">
            Averages when your prospecting leads to a successful deal:
          </Header>
          {postConversationCards}
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Segment className={css.insightsList}>
      <Tab panes={panes} menu={{ compact: true, pointing: true, secondary: true }} />
    </Segment>
  );
};

export default StrategyInsights;
