import React, { useCallback, useEffect, useState, SyntheticEvent } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Button, Form, Icon, Segment, Popup, DropdownProps, CheckboxProps, InputOnChangeData, Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { capitalize } from 'lodash';

import api from 'lib/api';
import actions from 'actions/alert-actions';
import AlertLanguageProcessor, { AlertRuleToProcess, metricDefinitions } from 'lib/AlertLanguageProcessor';
import { getSelectedTeamMembersForDropdown } from 'selectors/team';
import { hasPermission } from 'components/AccessControl';

import css from './AlertRule.module.css';

const { fetchAlertRule, saveAlertRule } = actions.alert;

const operatorOptions = [
  { key: '<', text: 'Less than', value: '<' },
  { key: '>', text: 'Greater than', value: '>' },
  { key: '>=', text: 'Greater than or equal to', value: '>=' },
  { key: '<=', text: 'Less than or equal to', value: '<=' },
  { key: '=', text: 'Equal to', value: '=' },
];

const periodOptions = [
  { key: '24', text: 'Daily', value: 24 },
  { key: '168', text: 'Weekly', value: 168 },
  { key: '336', text: 'Every two weeks', value: 336 },
];

const alertFrequencyWeeklyOptions = [
  { key: 'mon', text: 'Monday', value: '0 ? * * 1' },
  { key: 'tues', text: 'Tuesday', value: '0 ? * * 2' },
  { key: 'wed', text: 'Wednesday', value: '0 ? * * 3' },
  { key: 'thur', text: 'Thursday', value: '0 ? * * 4' },
  { key: 'fri', text: 'Friday', value: '0 ? * * 5' },
  { key: 'sat', text: 'Saturday', value: '0 ? * * 6' },
  { key: 'sun', text: 'Sunday', value: '0 ? * * 7' },
];

const alertFrequencyDailyOptions = [
  { key: '0', text: '12 AM', value: '0 0 * * 1-5' },
  { key: '1', text: '1 AM', value: '0 1 * * 1-5' },
  { key: '2', text: '2 AM', value: '0 2 * * 1-5' },
  { key: '3', text: '3 AM', value: '0 3 * * 1-5' },
  { key: '4', text: '4 AM', value: '0 4 * * 1-5' },
  { key: '5', text: '5 AM', value: '0 5 * * 1-5' },
  { key: '6', text: '6 AM', value: '0 6 * * 1-5' },
  { key: '7', text: '7 AM', value: '0 7 * * 1-5' },
  { key: '8', text: '8 AM', value: '0 8 * * 1-5' },
  { key: '9', text: '9 AM', value: '0 9 * * 1-5' },
  { key: '10', text: '10 AM', value: '0 10 * * 1-5' },
  { key: '11', text: '11 AM', value: '0 11 * * 1-5' },
  { key: '12', text: '12 PM', value: '0 12 * * 1-5' },
  { key: '13', text: '1 PM', value: '0 13 * * 1-5' },
  { key: '14', text: '2 PM', value: '0 14 * * 1-5' },
  { key: '15', text: '3 PM', value: '0 15 * * 1-5' },
  { key: '16', text: '4 PM', value: '0 16 * * 1-5' },
  { key: '17', text: '5 PM', value: '0 17 * * 1-5' },
  { key: '18', text: '6 PM', value: '0 18 * * 1-5' },
  { key: '19', text: '7 PM', value: '0 19 * * 1-5' },
  { key: '20', text: '8 PM', value: '0 20 * * 1-5' },
  { key: '21', text: '9 PM', value: '0 21 * * 1-5' },
  { key: '22', text: '10 PM', value: '0 22 * * 1-5' },
  { key: '23', text: '11 PM', value: '0 23 * * 1-5' },
];

const freqnencyTypes = [
  'AccountsThatNeedAttention',
  'NumberActiveAccounts',
  'NumberActiveContacts',
];

const AlertRule: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { alertId } = useParams<any>();

  const users: Array<any> = useSelector(getSelectedTeamMembersForDropdown);
  const teamId = useSelector((state: any) => state.team.selectedTeamId);
  const permission = useSelector((state: any) => state.app.permission);
  const loggedInUserId = useSelector((state: any) => state.app.id);
  const extSource = useSelector((state: any) => state.app.extSource);
  const alertRule = useSelector((state: any) => state.alert.currentRule);
  const [existingAlertId, setExistingAlertId] = useState<number>();
  const [dataSelected, setDataSelected] = useState<any>({
    alertFrequencySelect: '',
    alertFrequencyDaySelect: '',
    alertFrequencyHourSelect: '',
    deliveryMethodSelect: 'app',
    deliveryToSelect: [loggedInUserId],
    metricSelect: '',
    operatorSelect: '',
    periodSelect: '',
    thresholdInput: '',
    userSelect: '',
    metricNameInput: '',
    reportSelect: '',
    groupingKeySelect: '',
    aggKeySelect: '',
  });
  const [description, setDescription] = useState<string>();
  const [reportOptions, setReportOptions] = useState<Array<any>>();
  const [groupingOptions, setGroupingOptions] = useState<Array<any>>();
  const [aggColumnOptions, setAggColumnOptions] = useState<Array<any>>();
  const [sfReportId, setSfReportId] = useState<number>();
  const [metricDescription, setMetricDescription] = useState<string>();
  const [popup, setPopup] = useState<boolean>(false);

  const { handleSubmit } = useForm();

  const processAlert = useCallback((selected: any, id?: number): any => {
    const isSalesforceReport = selected.metricSelect === 'SalesforceReport';
    if ((!isSalesforceReport && !selected.userSelect) || !selected.periodSelect || !selected.metricSelect
      || !selected.operatorSelect || !selected.thresholdInput || !selected.alertFrequencySelect
      || !selected.deliveryToSelect
    ) {
      return false;
    }

    const type = (freqnencyTypes.includes(selected.metricSelect)) ? 'frequency' : 'timePeriod';

    const deliveryMethod: Array<string> = ['app'];
    if (selected.deliveryMethodSelect === 'appemail') deliveryMethod.push('email');

    const alert: any = {
      id,
      userId: selected.userSelect,
      type,
      metric: selected.metricSelect,
      thresholdOperator: selected.operatorSelect,
      thresholdValue: selected.thresholdInput,
      alertFrequency: selected.alertFrequencySelect,
      deliveryMethod,
      deliveryTo: selected.deliveryToSelect,
    };

    if (type !== 'frequency') {
      alert.timeFrame = selected.periodSelect;
    } else {
      if (selected.periodSelect === 24) {
        alert.alertLimit = '1 days';
      }
      if (selected.periodSelect === 168) {
        alert.alertLimit = '1 weeks';
      }
    }
    if (selected.periodSelect === 336) {
      alert.alertLimit = '2 weeks';
    }
    if (alert.metric === 'SalesforceReport') {
      alert.metricName = selected.metricNameInput;
      if (alertId === 'new') {
        alert.sfReportId = sfReportId;
        const groupingKey = selected.groupingKeySelect;
        if (groupingKey === 'total') {
          alert.groupingKey = '';
          alert.groupingKeyName = 'Total';
        } else {
          alert.groupingKey = selected.groupingKeySelect;
          alert.groupingKeyName = groupingOptions?.find(opt => (alert.groupingKey === opt.key))?.text;
        }
        alert.aggColumnKey = selected.aggKeySelect;
        alert.aggColumnName = aggColumnOptions?.find(opt => (alert.aggColumnKey === opt.key))?.text;
      }
    }

    return alert;
  }, [groupingOptions, aggColumnOptions, sfReportId, alertId]);

  useEffect(() => {
    let periodSelect = periodOptions.find(p => p.value === alertRule?.timeFrame)?.value;
    if (!periodSelect && alertRule?.alertLimit) {
      const { alertLimit } = alertRule;
      if (alertLimit.days) {
        periodSelect = 24 * alertLimit.days;
      }
      if (alertLimit.weeks) {
        periodSelect = 24 * 7 * alertLimit.weeks;
      }
    }
    const frequencyArr = alertRule?.alertFrequency.split(' ');
    const dayOpt = frequencyArr ? alertFrequencyWeeklyOptions[(frequencyArr[4] - 1)] : false;
    const alertFrequencyDaySelect = dayOpt ? alertFrequencyWeeklyOptions[(frequencyArr[4] - 1)].value : '';
    const alertFrequencyHourSelect = frequencyArr ? frequencyArr[1] : '';
    setDataSelected({
      alertFrequencySelect: alertRule?.alertFrequency,
      alertFrequencyDaySelect,
      alertFrequencyHourSelect,
      deliveryMethodSelect: alertRule?.deliveryMethod.includes('email') ? 'appemail' : 'app',
      deliveryToSelect: alertRule?.deliveryTo || [loggedInUserId],
      metricSelect: alertRule?.metric,
      operatorSelect: operatorOptions.find(o => o.value === alertRule?.thresholdOperator)?.value,
      periodSelect,
      thresholdInput: alertRule?.thresholdValue,
      userSelect: alertRule?.userId,
      metricNameInput: alertRule?.reportData?.metricName,
      reportSelect: alertRule?.reportData?.reportName,
      groupingKeySelect: alertRule?.reportData?.groupingKeyName,
      aggKeySelect: alertRule?.reportData?.aggColumnName,
    });
  }, [alertRule, loggedInUserId]);

  useEffect(() => {
    const fetchRule = async (id): Promise<void> => {
      const result: any = await dispatch(fetchAlertRule(id));
      if (!result) return;
      if (!result.rule) {
        history.replace('/app/alerts');
      }
    };

    if (alertId && teamId) {
      const newAlertId = Number.isNaN(Number.parseInt(alertId, 0)) ? null : alertId;
      setExistingAlertId(newAlertId);
      if (newAlertId) {
        fetchRule(newAlertId);
      }
    }
  }, [alertId, teamId, dispatch, history]);

  // handle description rendering
  useEffect(() => {
    const alert = processAlert(dataSelected);
    const userMap = {};
    Object.values(users).forEach((opt: any): void => {
      userMap[opt.key] = opt.text;
    });
    if (!alert) return;
    const userName = userMap[alert.userId];
    const timeFrame = periodOptions.find(p => p.value === alert.timeFrame)?.value;
    const deliveryTo = alert.deliveryTo.map(uid => userMap[uid]);
    const ruleToProcess: AlertRuleToProcess = {
      userName,
      metric: alert.metric,
      metricName: alert.metricName,
      timeFrame,
      thresholdOperator: alert.thresholdOperator,
      thresholdValue: alert.thresholdValue,
      alertFrequency: alert.alertFrequency,
      alertLimit: alert.alertLimit,
      deliveryMethod: alert.deliveryMethod,
      deliveryTo,
    };
    setDescription(AlertLanguageProcessor.getAlertRuleStatement(ruleToProcess));
  }, [dataSelected, users, processAlert]);

  // handle things when certian options are set
  useEffect(() => {
    const fetchReport = async (): Promise<void> => {
      const response = await api.getSalesforceReports();
      if (response.status) {
        const opts = response.reports.map(r => ({
          key: r.id,
          text: r.name,
          value: r.id,
        }));
        setReportOptions(opts);
      } else {
        // TODO: handle failed fetch
      }
    };
    if (alertId === 'new'
      && reportOptions === undefined
      && dataSelected.metricSelect === 'SalesforceReport') {
      fetchReport();
    }
  }, [dataSelected.metricSelect, alertId, reportOptions]);

  useEffect(() => {
    const fetchReportOptions = async (reportSfId): Promise<void> => {
      const response = await api.getSalesforceReport(reportSfId, { fetchReportOptions: true });
      if (response.status) {
        const grpOpts = response.groupings.map(g => ({
          key: g.key,
          text: g.label,
          value: g.value,
        }));
        grpOpts.unshift({
          key: 'total',
          text: 'Total',
          value: 'total',
        });
        const aggOpts = Object.keys(response.aggregateColumnInfo).map(aggKey => {
          const text = response.aggregateColumnInfo[aggKey].label;
          return {
            key: aggKey,
            text,
            value: aggKey,
          };
        });
        setSfReportId(response.report.id);
        setGroupingOptions(grpOpts);
        setAggColumnOptions(aggOpts);
        setDataSelected(prevDataSelected => ({
          ...prevDataSelected,
          groupingKeySelect: undefined,
          aggKeySelect: undefined,
        }));
      } else {
        // TODO: handle failed fetch
      }
    };
    if (alertId === 'new' && dataSelected.reportSelect) {
      const reportSfId = dataSelected.reportSelect;
      fetchReportOptions(reportSfId);
    }
  }, [dataSelected.reportSelect, alertId]);

  const onSubmit = async (): Promise<void> => {
    const alert = processAlert(dataSelected, existingAlertId);
    if (!alert) return;

    const response: any = await dispatch(saveAlertRule(alert));
    if (response.status) {
      history.replace('/app/alerts', { ruleUpdated: true });
    }
  };

  const disabled = !!existingAlertId;

  const handleWeeklyHourChange = async (
    _e: SyntheticEvent,
    { name, value }: DropdownProps | CheckboxProps | InputOnChangeData,
  ): Promise<void> => {
    const isDayChanging = (name === 'alertFrequencyDaySelect');
    const computedValue = isDayChanging ? value?.toString().replace('?', dataSelected.alertFrequencyHourSelect) : dataSelected.alertFrequencyDaySelect.replace('?', value);
    setDataSelected({
      ...dataSelected,
      alertFrequencySelect: computedValue,
      [`${name}`]: value,
    });
  };

  const handleOptionChange = async (
    _e: SyntheticEvent,
    { name, value }: DropdownProps | CheckboxProps | InputOnChangeData,
  ): Promise<void> => {
    setDataSelected({
      ...dataSelected,
      [`${name}`]: value,
    });
  };

  const defaultReportOptions = reportOptions || (dataSelected.reportSelect ? [{
    key: dataSelected.reportSelect,
    text: dataSelected.reportSelect,
    value: dataSelected.reportSelect,
  }] : []);
  const defaultGroupingKeyOptions = groupingOptions || (dataSelected.groupingKeySelect ? [{
    key: dataSelected.groupingKeySelect,
    text: dataSelected.groupingKeySelect,
    value: dataSelected.groupingKeySelect,
  }] : []);
  const defaultAggColumnOptions = aggColumnOptions || (dataSelected.aggKeySelect ? [{
    key: dataSelected.aggKeySelect,
    text: dataSelected.aggKeySelect,
    value: dataSelected.aggKeySelect,
  }] : []);

  const metricOnClick = (_, data): void => {
    setDataSelected({
      ...dataSelected,
      metricSelect: data.value,
    });
  };
  const metricOnHover = (metric): void => {
    setMetricDescription(metricDefinitions[metric]?.description);
  };
  const metricOptions = [
    (<Dropdown.Header key="customHeader" content="Custom Metrics" />),
    (<Dropdown.Header key="metricHeader" content="BuyerSight Metrics" />),
  ];

  let customInsertIndex = 1;
  Object.keys(metricDefinitions).forEach((metric): void => {
    const definition = metricDefinitions[metric];
    if (definition.source) {
      // if source on definition is defined, make sure it matches the extSource
      if (definition.source !== extSource) return;
    }
    const metricOption = (
      <Dropdown.Item
        className={css.metricOption}
        key={metric}
        onClick={metricOnClick}
        onMouseEnter={(): void => { metricOnHover(metric); }}
        value={metric}
        text={capitalize(definition.subjectTopic)}
      />
    );
    if (definition.dropdownGroup === 'buyersight') {
      metricOptions.push(metricOption);
    } else {
      metricOptions.splice(customInsertIndex, 0, metricOption);
      customInsertIndex += 1;
    }
  });
  if (customInsertIndex === 1) {
    // there are no custom metrics, remove header
    metricOptions.shift();
  }

  const handleMetricSearch = (
    options: DropdownItemProps[],
    value: string,
  ): DropdownItemProps[] => options.filter(
    (opt: DropdownItemProps): boolean => (
      opt.props.text?.toString().toLowerCase().includes(value.toLowerCase())
      || ['customHeader', 'metricHeader'].includes(opt.key)
      || false
    ),
  );

  const isDailySelection = dataSelected.periodSelect === periodOptions[0].value;

  const alertRuleSection = (
    <div className={css.formContainer}>
      <div className={css.titleContainer}>
        <div className={css.title}>
          {alertId === 'new' ? 'New Alert Rule Settings' : 'Alert Rule Settings'}
        </div>
      </div>
      <Form className={css.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={css.description}>
          {description}
        </div>
        <div>
          <Form.Field>
            <Popup
              basic
              open={popup}
              disabled={!metricDescription}
              position="left center"
              offset="40px, -75%"
              trigger={(
                <label className={css.labelItem}>Metric</label>
              )}
            >
              {metricDescription}
            </Popup>
            <Form.Select
              disabled={disabled}
              search={handleMetricSearch}
              name="metricSelect"
              onChange={handleOptionChange}
              onOpen={(): void => { setPopup(true); }}
              onClose={(): void => { setPopup(false); }}
              options={metricOptions}
              text={capitalize(metricDefinitions[dataSelected.metricSelect]?.subjectTopic)}
              value={dataSelected.metricSelect}
              error={dataSelected.metricSelect === ''}
              width={4}
            />
          </Form.Field>
          {dataSelected.metricSelect === 'SalesforceReport' ? (
            <Form.Group widths="equal">
              <Form.Field>
                <label className={css.labelItem}>Salesforce Report</label>
                <Form.Select
                  disabled={disabled}
                  name="reportSelect"
                  onChange={handleOptionChange}
                  options={defaultReportOptions}
                  value={dataSelected.reportSelect || ''}
                  error={dataSelected.reportSelect === ''}
                />
              </Form.Field>
              <Form.Field>
                <label className={css.labelItem}>Grouping</label>
                <Form.Select
                  disabled={disabled}
                  name="groupingKeySelect"
                  onChange={handleOptionChange}
                  options={defaultGroupingKeyOptions}
                  value={dataSelected.groupingKeySelect || ''}
                  error={dataSelected.groupingKeySelect === ''}
                />
              </Form.Field>
              <Form.Field>
                <label className={css.labelItem}>Summary Calculation</label>
                <Form.Select
                  disabled={disabled}
                  name="aggKeySelect"
                  onChange={handleOptionChange}
                  options={defaultAggColumnOptions}
                  value={dataSelected.aggKeySelect || ''}
                  error={dataSelected.aggKeySelect === ''}
                />
              </Form.Field>
              <Form.Field>
                <label className={css.labelItem}>Metric Name</label>
                <Form.Input
                  name="metricNameInput"
                  onChange={handleOptionChange}
                  value={dataSelected.metricNameInput || ''}
                  error={dataSelected.metricNameInput === ''}
                />
              </Form.Field>
            </Form.Group>
          ) : (
            <Form.Field>
              <label className={css.labelItem}>User</label>
              <Form.Select
                disabled={disabled}
                name="userSelect"
                options={users}
                onChange={handleOptionChange}
                value={dataSelected.userSelect}
                error={dataSelected.userSelect === ''}
                width={4}
              />
            </Form.Field>
          )}
          <Form.Group widths="five">
            <Form.Field>
              <label className={css.labelItem}>Operator</label>
              <Form.Select
                name="operatorSelect"
                options={operatorOptions}
                onChange={handleOptionChange}
                value={dataSelected.operatorSelect}
                error={dataSelected.operatorSelect === ''}
              />
            </Form.Field>
            <Form.Field>
              <label className={css.labelItem}>Threshold</label>
              <Form.Input
                name="thresholdInput"
                type="number"
                onChange={handleOptionChange}
                value={dataSelected.thresholdInput || ''}
                error={dataSelected.thresholdInput === ''}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label className={css.labelItem}>Time Period</label>
              <Form.Select
                name="periodSelect"
                options={periodOptions}
                onChange={handleOptionChange}
                value={dataSelected.periodSelect}
                error={dataSelected.periodSelect === ''}
              />
            </Form.Field>
            {
              isDailySelection ? (
                <Form.Field>
                  <label className={css.labelItem}>Time of day (ET)</label>
                  <Form.Select
                    name="alertFrequencySelect"
                    options={alertFrequencyDailyOptions}
                    onChange={handleOptionChange}
                    value={dataSelected.alertFrequencySelect}
                    error={dataSelected.alertFrequencySelect === ''}
                  />
                </Form.Field>
              ) : (
                <>
                  <Form.Field>
                    <label className={css.labelItem}>Day of week</label>
                    <Form.Select
                      name="alertFrequencyDaySelect"
                      options={alertFrequencyWeeklyOptions}
                      onChange={handleWeeklyHourChange}
                      value={dataSelected.alertFrequencyDaySelect}
                      error={dataSelected.alertFrequencyDaySelect === ''}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label className={css.labelItem}>Time of day (ET)</label>
                    <Form.Select
                      name="alertFrequencyHourSelect"
                      options={Array.from(alertFrequencyDailyOptions, opt => ({
                        key: opt.key,
                        text: opt.text,
                        value: opt.key,
                      }))}
                      onChange={handleWeeklyHourChange}
                      value={dataSelected.alertFrequencyHourSelect}
                      error={dataSelected.alertFrequencyHourSelect === ''}
                    />
                  </Form.Field>
                </>
              )
            }
            <Form.Field>
              <label className={css.labelItem}>Delivery to</label>
              <Form.Dropdown
                className={css.deliveryDropdown}
                fluid
                multiple
                name="deliveryToSelect"
                selection
                options={users}
                onChange={handleOptionChange}
                disabled={!hasPermission(permission, 'manager')}
                value={dataSelected.deliveryToSelect}
                error={dataSelected.deliveryToSelect === []}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field className={css.formGroup}>
              <label className={css.labelItem}>Alert delivery method</label>
              <div className={css.alertOption}>
                <Form.Radio
                  className={css.checkbox}
                  name="deliveryMethodSelect"
                  value="app"
                  label="App only"
                  onChange={handleOptionChange}
                  checked={dataSelected.deliveryMethodSelect === 'app'}
                />
                <Form.Radio
                  className={css.checkbox}
                  name="deliveryMethodSelect"
                  value="appemail"
                  label="App and email"
                  onChange={handleOptionChange}
                  checked={dataSelected.deliveryMethodSelect === 'appemail'}
                />
              </div>
            </Form.Field>
          </Form.Group>
        </div>
        <div className={css.actionButtons}>
          <Link to="/app/alerts">
            <Button>Cancel</Button>
          </Link>
          <Button className={css.ctaSave} type="submit">Save</Button>
        </div>
      </Form>
    </div>
  );

  return (
    <div className={css.container}>
      <Segment className={css.segment}>
        <div className={css.back}>
          <Link to="/app/alerts">
            <Icon
              className={css.icon}
              name="arrow left"
              size="big"
            />
          </Link>
        </div>
        {alertRuleSection}
      </Segment>
    </div>
  );
};

export default AlertRule;
