import { TeamApi } from 'features/Api';
import React, { CSSProperties, ReactElement, useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  GridColumn,
  GridRow,
  List,
  ListHeader,
  ListItem,
  Popup,
  Segment,
  StrictPopupProps,
} from 'semantic-ui-react';
import { Chart, ChartConfiguration, ChartData, registerables, ScriptableTooltipContext } from 'chart.js';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';

import { useGetUserPipelineAtDateQuery } from 'features/Pipeline/pipelineApi';
import { UserPipeline, PipelineDollarValue } from 'features/Pipeline/pipelineTypes';

import { selectOpportunityStageColorsMap } from 'selectors/organization';
import { StageToColorMap } from 'models/opportunity';

import BSIcon from 'components/BSIcon/BSIcon';
import css from './styles.module.css';

function getDataset(pipeline: UserPipeline, stagesToColorMap: StageToColorMap): ChartData<'pie'> {
  const labels: string[] = pipeline.map(stage => stage.masterLabel);
  const data: number[] = pipeline.map(stage => stage.amount);
  const colors: string[] = pipeline.map(stage => stagesToColorMap[stage.masterLabel] || 'blue');
  return {
    labels,
    datasets: [{
      data,
      backgroundColor: colors,
    }],
  };
}

function getChartConfig(
  pipeline: UserPipeline,
  stagesToColorsMap: StageToColorMap,
  setTooltipCtx: React.Dispatch<React.SetStateAction<null | ScriptableTooltipContext<'doughnut'>>>,
): ChartConfiguration {
  return {
    type: 'doughnut',
    data: getDataset(pipeline, stagesToColorsMap),
    options: {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: (ctx: ScriptableTooltipContext<'doughnut'>): void => setTooltipCtx(ctx),
        },
      },
    },
  };
}

function Tooltip(props: { ctx: null | ScriptableTooltipContext<'doughnut'> }): ReactElement {
  const { ctx } = props;
  const pointRef = useRef(null);
  if (!ctx || ctx.tooltip.opacity === 0 || !pointRef) return <></>;
  const { dataPoints, caretX, caretY, xAlign, yAlign } = ctx.tooltip;
  const inner = dataPoints.map(dp => (
    <ListItem key={dp.label}>
      <ListHeader>
        {dp.label}
      </ListHeader>
      {PipelineDollarValue.create(dp.parsed).getFormattedString()}
    </ListItem>
  ));
  const xAlignCssProp: 'left' | 'right' = xAlign === 'center' ? 'left' : xAlign;
  const yAlignCssProp: 'top' | 'bottom' = yAlign === 'center' ? 'top' : yAlign;
  const position = `${yAlignCssProp} ${xAlign}` as StrictPopupProps['position'];
  const triggerStyles: CSSProperties = {
    position: 'absolute',
    [xAlignCssProp]: caretX,
    [yAlignCssProp]: caretY,
    width: '1px',
    height: '1px',
    opacity: 0,
    backgroundColor: '#FFFFFF',
  };
  return (
    <>
      <Popup
        position={position}
        open
        positionFixed={false}
        content={inner}
        context={pointRef}
        basic
      />
      <div style={triggerStyles} ref={pointRef} />
    </>
  );
}

function PipelineListItem(props: {
  title: string;
  value: string;
  popupValue: string;
  disabled: boolean;
}): ReactElement {
  const { title, value, popupValue, disabled } = props;
  return (
    <Popup
      content={popupValue}
      disabled={disabled}
      position="left center"
      trigger={(
        <ListItem>
          <ListHeader>
            {title}
          </ListHeader>
          {value}
        </ListItem>
      )}
    />
  );
}

const PipelineChart = React.memo((props: {
  pipeline?: UserPipeline;
  isFetching: boolean;
}): ReactElement => {
  let chart;
  const { pipeline, isFetching } = props;
  const stagesToColorMap = useSelector(selectOpportunityStageColorsMap);
  const [tooltipCtx, setTooltipCtx] = useState<null | ScriptableTooltipContext<'doughnut'>>(null);
  const canvasRef = useCallback((canvasNode: HTMLCanvasElement | null): void => {
    if (chart) {
      // destroy the old chart if it exists before making a new one
      chart.destroy();
    }
    if (!pipeline || !canvasNode) return;
    const config = getChartConfig(pipeline, stagesToColorMap, setTooltipCtx);

    const ctx = canvasNode.getContext('2d');
    if (ctx) {
      Chart.register(...registerables);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      chart = new Chart(ctx, config);
    }
  }, [pipeline, stagesToColorMap]);

  if (isFetching) return <>Loading...</>;
  return (
    <>
      <canvas ref={canvasRef} />
      <Tooltip ctx={tooltipCtx} />
    </>
  );
});

function PipelineText(props: {
  userId: number;
  teamId: number;
  startDateIso: string;
  endDateIso: string;
}): ReactElement {
  const { userId, teamId, startDateIso, endDateIso } = props;
  const { data: teamStats, isFetching } = TeamApi.getPipelineStats.useQuery({
    teamId,
    startDate: DateTime.fromISO(startDateIso).toFormat('yyyyMMdd'),
    endDate: DateTime.fromISO(endDateIso).toFormat('yyyyMMdd'),
  });

  let openPipelineStr = 'Error';
  let pipelineCreatedStr = 'Error';
  let pipelineWonStr = 'Error';
  let pipelineLostStr = 'Error';

  let openPipelineFullStr;
  let pipelineCreatedFullStr;
  let pipelineWonFullStr;
  let pipelineLostFullStr;

  if (isFetching) {
    openPipelineStr = 'Loading';
    pipelineCreatedStr = 'Loading';
    pipelineWonStr = 'Loading';
    pipelineLostStr = 'Loading';
  } else if (teamStats && teamStats.pipeline.members[userId]) {
    const { open, created, won, lost } = teamStats.pipeline.members[userId];
    openPipelineStr = PipelineDollarValue.create(open).getFormattedString();
    pipelineCreatedStr = PipelineDollarValue.create(created).getFormattedString();
    pipelineWonStr = PipelineDollarValue.create(won).getFormattedString();
    pipelineLostStr = PipelineDollarValue.create(lost).getFormattedString();
    openPipelineFullStr = PipelineDollarValue.create(open).getFormattedString(false);
    pipelineCreatedFullStr = PipelineDollarValue.create(created).getFormattedString(false);
    pipelineWonFullStr = PipelineDollarValue.create(won).getFormattedString(false);
    pipelineLostFullStr = PipelineDollarValue.create(lost).getFormattedString(false);
  }

  return (
    <List relaxed="very">
      <PipelineListItem
        title="Open Pipeline"
        value={openPipelineStr}
        popupValue={openPipelineFullStr}
        disabled={isFetching}
      />
      <PipelineListItem
        title="Pipeline Created"
        value={pipelineCreatedStr}
        popupValue={pipelineCreatedFullStr}
        disabled={isFetching}
      />
      <PipelineListItem
        title="Pipeline Closed Won"
        value={pipelineWonStr}
        popupValue={pipelineWonFullStr}
        disabled={isFetching}
      />
      <PipelineListItem
        title="Pipeline Closed Lost"
        value={pipelineLostStr}
        popupValue={pipelineLostFullStr}
        disabled={isFetching}
      />
    </List>
  );
}

export function UserPipelineWidget(props: {
  userId: number;
  teamId: number;
  startDateIso: string;
  endDateIso: string;
}): ReactElement {
  const { userId, teamId, startDateIso, endDateIso } = props;

  const { data: pipelineData, isFetching: pipelineIsFetching } = useGetUserPipelineAtDateQuery({
    dateIso: DateTime.fromISO(endDateIso).toFormat('yyyyMMdd'),
    userId,
    teamId,
  });
  const pipeline = pipelineData?.data;
  let columnCount: 1 | 2 = 1;
  if (pipelineIsFetching) columnCount = 2;
  else if (pipeline && pipeline.reduce((sum, stage) => sum + stage.amount, 0) > 0) columnCount = 2;
  return (
    <Segment className={css.PipelineChart}>
      <Grid>
        <GridRow columns={2}>
          <GridColumn>
            <h4>Pipeline</h4>
          </GridColumn>
          <GridColumn textAlign="right">
            <Link to="/app/pipeline" className="link brand">
              <BSIcon name="long arrow alternate right" />
            </Link>
          </GridColumn>
        </GridRow>
        <GridRow columns={columnCount} divided={columnCount === 2}>
          <GridColumn className={(columnCount === 1 ? 'hidden' : '')}>
            <PipelineChart pipeline={pipeline} isFetching={pipelineIsFetching} />
          </GridColumn>
          <GridColumn verticalAlign="middle">
            <PipelineText userId={userId} teamId={teamId} startDateIso={startDateIso} endDateIso={endDateIso} />
          </GridColumn>
        </GridRow>
      </Grid>
    </Segment>
  );
}
