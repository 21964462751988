import { ScatterDataPoint } from 'chart.js';
import moment from 'moment';

export class IdealDataPoint implements ScatterDataPoint {
  type = 'IdealDataPoint';

  stageStartOrEnd: 'start' | 'end';

  x: number;

  y: number;

  daysSinceTimelineStart: number;

  activitiesPerWeek: number;

  daysInStage: number;

  stageName: string;

  masterLabel: string;

  color: string;

  constructor(x: number | string,
    y: number,
    daysSinceTimelineStart: number,
    activitiesPerWeek: number,
    daysInStage: number,
    stageName: string,
    masterLabel: string,
    color: string,
    startOrEnd: 'start' | 'end') {
    if (typeof x === 'string') {
      this.x = moment(x).unix() * 1000;
    } else this.x = x;
    this.y = y;
    this.daysSinceTimelineStart = daysSinceTimelineStart;
    this.activitiesPerWeek = activitiesPerWeek;
    this.daysInStage = daysInStage;
    this.stageName = stageName;
    this.masterLabel = masterLabel;
    this.color = color;
    this.stageStartOrEnd = startOrEnd;
  }
}
