import param from 'jquery-param';
import { AccountHealthInfo } from '../../Account/accountTypes';
import baseApi from '../apiSlice';

export interface Query {
  teamId: number;
  ownerId?: number;
  page?: number;
}

export interface Response {
  active: AccountHealthInfo[];
  inactive: AccountHealthInfo[];
  status: boolean;
  page: number;
}

const oneMinute = 60;

const accountApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getAllAccounts: builder.query<Response, Query>({
      query: params => `account/health/v2/all?${param(params)}`,
      keepUnusedDataFor: 10 * oneMinute,
    }),
  }),
});

export const useGetAccountHealthQuery = accountApi.endpoints.getAllAccounts.useQuery;
