import { createActions } from 'redux-actions';

import api from '../lib/api';
import { handleError } from '.';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const actions = createActions({
  ORGANIZATION: {
    CLEAR_ORGANIZATION_SETTINGS_ERROR: undefined,
    FETCH_ORGANIZATION_INFO_REQUEST: undefined,
    FETCH_ORGANIZATION_INFO_SUCCESS: (
      organization,
      features,
      dataSettings,
    ) => ({ organization, features, dataSettings }),
    FETCH_ORGANIZATION_INFO_FAILURE: msg => ({ msg }),
    FETCH_OPPORTUNITY_STAGES_REQUEST: undefined,
    FETCH_OPPORTUNITY_STAGES_SUCCESS: opportunityStages => ({ opportunityStages }),
    FETCH_OPPORTUNITY_STAGES_FAILURE: msg => ({ msg }),
    FETCH_OPPORTUNITY_TYPES_REQUEST: undefined,
    FETCH_OPPORTUNITY_TYPES_SUCCESS: opportunityTypes => ({ opportunityTypes }),
    FETCH_OPPORTUNITY_TYPES_FAILURE: msg => ({ msg }),
    SET_ORGANIZATION_SETTINGS_REQUEST: undefined,
    SET_ORGANIZATION_SETTINGS_SUCCESS: settings => ({ settings }),
    SET_ORGANIZATION_SETTINGS_FAILURE: msg => ({ msg }),
  },
});
/* eslint-enable @typescript-eslint/explicit-function-return-type */

export const fetchOrganizationInfo = () => async (dispatch: Function): Promise<any> => {
  dispatch(actions.organization.fetchOrganizationInfoRequest());
  const response = await api.getOrganization();
  if (response.status) {
    const { organization, features, dataSettings } = response;
    dispatch(actions.organization.fetchOrganizationInfoSuccess(organization, features, dataSettings));
  } else {
    handleError(response, dispatch);
    dispatch(actions.organization.fetchOrganizationInfoFailure('There was an error fetching the organization'));
  }
  return response;
};

export const fetchOpportunityStages = () => async (dispatch: Function): Promise<any> => {
  dispatch(actions.organization.fetchOpportunityStagesRequest());
  const response = await api.getOpportunityStages();
  if (response.status) {
    dispatch(actions.organization.fetchOpportunityStagesSuccess(response.opportunityStages));
  } else {
    handleError(response, dispatch);
    dispatch(actions.organization.fetchOpportunityStagesFailure('There was an error fetching the organization'));
  }
  return response;
};

export const fetchOpportunityTypes = () => async (dispatch: Function): Promise<any> => {
  dispatch(actions.organization.fetchOpportunityTypesRequest());
  const response = await api.getOpportunityTypes();
  if (response.status) {
    dispatch(actions.organization.fetchOpportunityTypesSuccess(response.opportunityTypes));
  } else {
    handleError(response, dispatch);
    dispatch(actions.organization.fetchOpportunityTypesFailure('There was an error fetching the organization'));
  }
  return response;
};

export const setOrganizationSettings = (settings: any) => async (dispatch: Function): Promise<any> => {
  dispatch(actions.organization.setOrganizationSettingsRequest());
  const response = await api.setOrganizationSettings(settings);
  if (response.status) {
    dispatch(actions.organization.setOrganizationSettingsSuccess(response.settings));
  } else {
    handleError(response, dispatch);
    dispatch(actions.organization.setOrganizationSettingsFailure(
      'There was an error setting the organization\'s settings',
    ));
  }
  return response;
};

const organizationActions = {
  organization: {
    ...actions.organization,
    fetchOpportunityStages,
    fetchOpportunityTypes,
    fetchOrganizationInfo,
    setOrganizationSettings,
  },
};

export default organizationActions;
