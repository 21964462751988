import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Header, Modal, ModalProps, Table, Icon, Pagination, Menu, PaginationProps, DropdownItemProps, Dropdown, DropdownProps } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import { sortBy } from 'lodash';

import { Account } from 'models/account';
import { Contact } from 'models/contact';
import { FunnelStats } from 'models/funnel';
import { funnelLabelMap } from '../Funnel';

import css from '../styles.module.css';

type DetailRow = {
  objectType: string;
  status: string;
}

type ContactDetail = Contact & DetailRow & {
  accountId?: number;
  accountName?: string;
  lastActivityDate?: string;
  isNew?: boolean;
  criticalCount?: number;
  warningCount?: number;
  criticalMetrics?: string[];
  warningMetrics?: string[];
};

type AccountDetail = Account & DetailRow & {
  opportunityId: number;
  opportunityName: string;
  stageName: string;
  amount: number;
  closeDate: string;
  createDate: string;
};

function ContactRow({ contact }: { contact: ContactDetail }): React.ReactElement {
  const { id, name, status, isNew, criticalCount } = contact;
  let link = '';
  let nameNode: React.ReactNode = name;
  let accountNode: React.ReactNode = null;

  const { accountId, accountName } = contact;
  if (accountId) {
    link = `/app/prospecting/${accountId}/contacts/${id}`;
    accountNode = (
      <Link to={`/app/prospecting/${accountId}/contacts`}>{accountName}</Link>
    );
  } else {
    link = `/app/prospecting/contacts/${id}`;
  }

  if (link) {
    nameNode = (
      <Link to={link}>{name}</Link>
    );
  }
  const statusLabel = funnelLabelMap[status] || status;
  const recomendation = criticalCount;

  return (
    <Table.Row>
      <Table.Cell>
        {nameNode}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {recomendation}
      </Table.Cell>
      <Table.Cell>
        {isNew ? 'New' : 'Existing'}
      </Table.Cell>
      <Table.Cell>
        {statusLabel}
      </Table.Cell>
      <Table.Cell>
        {accountNode}
      </Table.Cell>
    </Table.Row>
  );
}

function AccountRow({ account }: { account: AccountDetail }): React.ReactElement {
  const { id, name, status } = account;
  let link = '';
  let nameNode: React.ReactNode = name;
  let opportunityNode: React.ReactNode = null;

  const { opportunityName } = account;
  link = `/app/prospecting/${id}/contacts`;
  opportunityNode = opportunityName;

  if (link) {
    nameNode = (
      <Link to={link}>{name}</Link>
    );
  }
  const statusLabel = funnelLabelMap[status] || status;

  return (
    <Table.Row>
      <Table.Cell>
        {nameNode}
      </Table.Cell>
      <Table.Cell>
        {statusLabel}
      </Table.Cell>
      <Table.Cell>
        {opportunityNode}
      </Table.Cell>
    </Table.Row>
  );
}

interface Props {
  open: boolean;
  level?: FunnelStats;
  breakdownOptions?: DropdownItemProps[];
  filter?: {
    status?: string;
  };
  setFilter?: Function;
  onOpen?: (event: React.MouseEvent<HTMLElement, MouseEvent>, data: ModalProps) => void;
  onClose?: (event: React.MouseEvent<HTMLElement, MouseEvent>, data: ModalProps) => void;
}

type Direction = 'ascending' | 'descending' | undefined;

const FunnelDetailModal: React.FC<Props> = ({
  level,
  breakdownOptions = [],
  open,
  filter,
  setFilter,
  onOpen,
  onClose,
}) => {
  const [column, setColumn] = useState('name');
  const [direction, setDirection] = useState<Direction>('ascending');
  const [activePage, setActivePage] = useState<number>(1);

  const filterOptions: DropdownItemProps[] = [
    {
      key: 'all',
      text: 'Show All',
      value: 'all',
    },
    ...breakdownOptions,
  ];

  const ROWS_PER_PAGE = 100;

  const sorted = (key: string): Direction => (key === column ? direction : undefined);

  const handleClose = (event, props: ModalProps): void => {
    if (onClose) {
      onClose(event, props);
    }
    setActivePage(1);
  };

  const handleClick = (key: string) => (): void => {
    if (key === column) {
      setDirection(direction === 'ascending' ? 'descending' : 'ascending');
      return;
    }
    setColumn(key);
    setDirection('ascending');
  };

  const handleFilterChange = (_event, data: DropdownProps): void => {
    if (!setFilter) return;
    const status = (data.value as string);
    if (status === 'all') {
      setFilter(undefined);
    } else {
      setFilter({ status });
    }
  };

  const filterFn = (detailRow): boolean => {
    if (filter?.status) {
      return detailRow.status === filter.status;
    }
    return true;
  };

  if (!level) return null;
  const { label, details } = level;
  const sortedDetails = sortBy(details, column);
  if (direction === 'descending') sortedDetails.reverse();
  const filteredData = sortedDetails ? sortedDetails.filter(filterFn) : [];

  const startSlice = ROWS_PER_PAGE * (activePage - 1);
  const endSlice = ROWS_PER_PAGE * activePage;
  const tableData: any[] = filteredData.slice(startSlice, endSlice);

  let objectType;
  let totalPages = 0;
  if (filteredData && tableData[0]) {
    ({ objectType } = tableData[0]);
    totalPages = Math.ceil(filteredData.length / ROWS_PER_PAGE);
  }
  if (activePage > totalPages && totalPages > 0) {
    setActivePage(1);
  }

  let header: React.ReactNode = null;
  if (objectType === 'contact') {
    header = (
      <Table.Header className={css.tableHeader}>
        <Table.Row>
          <Table.HeaderCell width="5" sorted={column === 'name' ? direction : undefined} onClick={handleClick('name')}>
            Name
          </Table.HeaderCell>
          <Table.HeaderCell sorted={sorted('recomendation')}>
            Recommendations
          </Table.HeaderCell>
          <Table.HeaderCell sorted={sorted('isExisting')}>
            New/Existing
          </Table.HeaderCell>
          <Table.HeaderCell sorted={sorted('status')} onClick={handleClick('status')}>
            Status
          </Table.HeaderCell>
          <Table.HeaderCell width="5" sorted={sorted('accountName')} onClick={handleClick('accountName')}>
            Account
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    );
  } else if (objectType === 'account') {
    header = (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width="5">
            Name
          </Table.HeaderCell>
          <Table.HeaderCell>
            Status
          </Table.HeaderCell>
          <Table.HeaderCell width="5">
            Opportunity
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    );
  }


  const onPageChange = (_, data: PaginationProps): void => {
    let pageNum: number;
    if (typeof data.activePage === 'string') {
      pageNum = Number.parseInt(data.activePage, 10);
    } else {
      pageNum = data.activePage || 1;
    }
    setActivePage(pageNum);
  };

  const getPagination = (): React.ReactNode => {
    if (totalPages <= 1) { return null; }
    return (
      <Menu pagination>
        <Pagination
          firstItem={{ content: <Icon name="angle double left" />, icon: true }}
          lastItem={{ content: <Icon name="angle double right" />, icon: true }}
          prevItem={{ content: <Icon name="angle left" />, icon: true }}
          nextItem={{ content: <Icon name="angle right" />, icon: true }}
          totalPages={totalPages}
          activePage={activePage}
          onPageChange={onPageChange}
        />
      </Menu>
    );
  };

  const content = tableData.length ? tableData.map(obj => {
    const { id } = obj;
    const key = `${objectType}-${id}`;
    if (objectType === 'account') {
      return (<AccountRow key={key} account={obj} />);
    }
    if (objectType === 'contact') {
      return (<ContactRow key={key} contact={obj} />);
    }
    return null;
  }) : null;

  const handleDownloadChart = (): void => {
    if (!details) return;

    let csvHeader: string[] = [];
    const csvData: any[][] = [];

    if (objectType === 'contact') {
      csvHeader = [
        'id',
        'name',
        'isNew',
        'accountName',
        'recommendations',
      ];
      Object.values(details).forEach((obj: ContactDetail) => {
        const rowData: any = {};
        rowData.id = obj.extId;
        rowData.name = obj.name;
        rowData.isNew = (obj as ContactDetail).isNew ? 'new' : 'existing';
        rowData.account = obj.accountName;
        rowData.recommendations = obj.criticalMetrics?.join(', ') || '';
        csvData.push(Object.values(rowData).map(d => `"${d || ''}"`));
      });
    } else if (objectType === 'account') {
      csvHeader = [
        'id',
        'name',
        'status',
        'opportunityName',
        'opportunityStage',
        'opportunityCloseDate',
      ];
      Object.values(details).forEach((obj: AccountDetail) => {
        const rowData: any = {};
        rowData.id = obj.extId;
        rowData.name = obj.name;
        rowData.status = obj.status;
        rowData.opportunityName = obj.opportunityName;
        rowData.stageName = obj.stageName;
        rowData.closeDate = obj.closeDate;
        csvData.push(Object.values(rowData).map(d => `"${d || ''}"`));
      });
    }

    const levelName = level.label.replaceAll(/\s+/g, '_');
    const now = DateTime.now().toFormat('yyyyMMdd');

    const fileName = `${now}_funnel_${levelName}`;
    const rows = [csvHeader, ...csvData];
    const csvContent = `data:text/csv;charset=utf-8,${rows.map(e => e.join(',')).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const pagination = getPagination();
  const numberOfContacts = filteredData.length || 0;
  return (
    <Modal open={open} onOpen={onOpen} onClose={handleClose}>
      <Header>
        <div className={css.stats}>
          {label}
          <div className={css.statsRight}>
            <div className={css.statsBox}>
              <span className={css.value}>{numberOfContacts}</span>
              <span className={css.title}>Contacts</span>
            </div>
            <Icon
              name="download"
              title="Download as CSV"
              onClick={handleDownloadChart}
              className={css.iconDownload}
            />
          </div>
        </div>
      </Header>
      <div className={css.tableFilters}>
        <Dropdown
          selection
          onChange={handleFilterChange}
          options={filterOptions}
          value={filter?.status || 'all'}
        />
      </div>
      <Table sortable>
        {header}
        <Table.Body>
          {content}
        </Table.Body>
        <Table.Footer className={css.containerPagination}>
          <Table.Row>
            <Table.HeaderCell colSpan="5" style={{ textAlign: 'center' }}>
              {pagination}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Modal>
  );
};

export default FunnelDetailModal;
