import React, { ReactElement, useEffect, useState } from 'react';
import { Header, Message, Icon } from 'semantic-ui-react';

import Carousel from 'components/Carousel';
import DashboardTile from 'components/DashboardTile';
import teamGoalAttainmentApi, { GoalAttainment } from 'features/Api/Goals';

import GoalTrackingDetailsModal from 'components/Modals/GoalTrackingDetailsModal';
import css from './GoalTrackingCard/styles.module.css';
import GoalTrackingCard from './GoalTrackingCard';

const NoGoalsMessage = (): ReactElement => {
  const styles = {
    width: '70%',
    margin: '60px auto 0',
  };
  return (
    <Message style={styles}>
      <Message.Header>No goals configured</Message.Header>
      No goals have been configured for your organization or team.
      Please contact your system administrator to configure new goals for your team.
    </Message>
  );
};

const GoalTrackingCardWithNavigation = (props: {
  teamId: number;
  userId: number;
  goalAttainment: GoalAttainment;
  periodIndex: number;
  handleGoalDetailsModal?: Function;
  setGoalAttainmentInModal?: Function;
}): ReactElement => {
  const {
    goalAttainment: initialAttainment, teamId, userId, periodIndex: initialIndex,
    handleGoalDetailsModal, setGoalAttainmentInModal,
  } = props;
  const [goalAttainment, setGoalAttainment] = useState(initialAttainment);
  const [periodIndex, setPeriodIndex] = useState(initialIndex);

  const { goalId } = goalAttainment;
  const [getGoalTrigger, goalResult] = teamGoalAttainmentApi
    .endpoints
    .getMemberGoal
    .useLazyQuery();

  useEffect(() => {
    getGoalTrigger({ teamId, userId, goalId, periodIndex });
  }, [periodIndex]);

  useEffect(() => {
    const attainment = goalResult.data?.goalAttainments[userId];
    if (attainment) {
      setGoalAttainment(attainment);
    }
  }, [goalResult]);

  const handlePeriodChange = (event, num: number): void => {
    const newperiod = Math.max(periodIndex + num, 0);
    setPeriodIndex(newperiod);
    event.stopPropagation();
  };
  const previousGoalBtn = (
    <Icon
      className={css.navigationBtns}
      name="chevron left"
      onClick={(event): void => handlePeriodChange(event, 1)}
    />
  );
  const nextGoalBtn = periodIndex === 0 ? undefined : (
    <Icon
      className={css.navigationBtns}
      name="chevron right"
      onClick={(event): void => handlePeriodChange(event, -1)}
    />
  );

  return (
    <GoalTrackingCard
      isLoading={goalResult.isFetching}
      userGoal={goalAttainment}
      userId={userId}
      previousGoalBtn={previousGoalBtn}
      nextGoalBtn={nextGoalBtn}
      handleGoalDetailsModal={handleGoalDetailsModal}
      setGoalAttainmentInModal={setGoalAttainmentInModal}
    />
  );
};

export const GoalTrackingCardWithBreakdownModal = (props: {
  teamId: number;
  userId: number;
  goalAttainment: GoalAttainment;
  periodIndex: number;
  handleGoalDetailsModal: Function;
  setGoalAttainmentInModal: Function;
}): ReactElement => {
  const {
    userId, teamId, goalAttainment, periodIndex,
    handleGoalDetailsModal, setGoalAttainmentInModal,
  } = props;
  return (
    <GoalTrackingCardWithNavigation
      teamId={teamId}
      userId={userId}
      goalAttainment={goalAttainment}
      periodIndex={periodIndex}
      handleGoalDetailsModal={handleGoalDetailsModal}
      setGoalAttainmentInModal={setGoalAttainmentInModal}
    />
  );
};

export function GoalTracking(props: {
  userId: number;
  teamId: number;
}): ReactElement {
  const [isGoalDetailsModalOpen, setIsGoalDetailsModalOpen] = useState(false);
  const [goalAttainmentInModal, setGoalAttainmentInModal] = useState<GoalAttainment | undefined>(undefined);
  const { userId, teamId } = props;

  const title = 'Goal Overview';

  const handleGoalDetailsModal = (goalDetails?: GoalAttainment | undefined): void => {
    setIsGoalDetailsModalOpen(!isGoalDetailsModalOpen);
    setGoalAttainmentInModal(goalDetails);
  };

  const {
    data: goalsData,
    isLoading,
    isFetching,
  } = teamGoalAttainmentApi.endpoints.getMemberGoalAttainment.useQuery({ userId, teamId });

  let inner: ReactElement = isLoading ? (<div><Header>{title}</Header></div>) : (
    <div>
      <Header>{title}</Header>
      <NoGoalsMessage />
    </div>
  );

  if (!isFetching && goalsData !== undefined) {
    if (goalsData.goals.length > 0) {
      const userGoalCards = goalsData.goals.map(g => {
        const periodIndex = g.goalAttainments[userId].period.index;
        return (
          <GoalTrackingCardWithBreakdownModal
            teamId={teamId}
            userId={userId}
            goalAttainment={g.goalAttainments[userId]}
            periodIndex={periodIndex}
            handleGoalDetailsModal={handleGoalDetailsModal}
            setGoalAttainmentInModal={setGoalAttainmentInModal}
            key={g.goal.id}
          />
        );
      });

      inner = (
        <Carousel title={title}>
          {userGoalCards}
        </Carousel>
      );
    }
  }

  return (
    <DashboardTile isLoading={isFetching} isMinimized>
      {inner}
      <GoalTrackingDetailsModal
        isOpen={isGoalDetailsModalOpen}
        goalAttainment={goalAttainmentInModal}
        handleGoalDetailsModal={handleGoalDetailsModal}
        teamId={teamId}
        userId={userId}
      />
    </DashboardTile>
  );
}
