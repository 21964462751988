import React from 'react';
import { DropdownItemProps } from 'semantic-ui-react';

import css from '../styles.module.css';

interface Props {
  breakdownOptions: DropdownItemProps[];
  direction?: 'row' | 'column';
}

const FunnelLegend: React.FC<Props> = ({ breakdownOptions, direction }) => {
  const style = direction ? { flexDirection: direction } : undefined;

  const legendKeys = breakdownOptions.map(({ key, text }) => (
    <div key={key} className={css.legendRow}>
      <div className={`${css.legendKey} ${css[key]}`} />
      <div className={css.label}>{text}</div>
    </div>
  ));

  const content = legendKeys && legendKeys.length ? legendKeys : 'Empty';

  return (
    <div className={css.legend} style={style}>
      {content}
    </div>
  );
};

export default FunnelLegend;
