import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  DropdownProps,
  Input,
  InputOnChangeData,
  Modal,
  Select,
  DropdownItemProps,
} from 'semantic-ui-react';

import { permissionOptions, Permission } from 'components/AccessControl';
import api from 'lib/api';
import useDebounce from 'hooks/useDebounce';
import useOnFlagChange from 'hooks/useOnFlagChange';
import allActions, { bindCombinedActions } from 'actions';
import PermissionsInfo from 'components/PermissionsInfo';
import { getUserSfIds, getNumOfTrackedUsers } from 'selectors/user';
import { getTeams } from 'selectors/team';
import { Team } from 'models/team';
import SimpleUserTable, { SimpleUser } from '../SimpleUserTable/SimpleUserTable';

import css from './AddUserModal.module.css';
import TrackedSeats from './TrackedSeats';

interface AddUserModalProps {
  open: boolean;
  extSource: string;
  onClose: () => void;
  actions: any;
  isSyncing: boolean;
  usersSfIds: string;
  maxTrackedSeats: number;
  numOfTrackedUsers: number;
  teams: Team[];
}

const AddUserModalBase: React.FC<AddUserModalProps> = ({
  actions,
  extSource,
  open,
  onClose,
  isSyncing,
  usersSfIds,
  numOfTrackedUsers,
  maxTrackedSeats,
  teams,
}: AddUserModalProps): React.ReactElement => {
  const [extUsers, setExtUsers] = React.useState<SimpleUser[]>([]);
  const [loadingExtUsers, setLoadingExtUsers] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [selectedUserId, setSelectedUserId] = React.useState<string | undefined>(undefined);
  const [selectedPermission, setSelectedPermission] = React.useState<Permission>('rep');
  const [selectedTracked, setSelectedTracked] = React.useState(false);
  const [selectedTeamId, setSelectedTeamId] = React.useState<string | undefined>(undefined);

  const debouncedSearchQuery = useDebounce(searchQuery);

  const handleSyncChange = React.useCallback((): void => {
    if (!isSyncing) {
      actions.user.fetchUsers();
      onClose();
    }
  }, [isSyncing, actions, onClose]);

  useOnFlagChange(isSyncing, handleSyncChange);

  React.useEffect((): void => {
    const fetchExternalUsers = async (): Promise<void> => {
      setLoadingExtUsers(true);
      if (extSource === 'salesforce') {
        const data = await api.getSalesforceUsers({ search: debouncedSearchQuery });
        setLoadingExtUsers(false);
        if (data.status) {
          setExtUsers(data.users.map(user => ({
            name: user.Name,
            email: user.Email,
            role: user.UserRole ? user.UserRole.Name : '',
            id: user.Id,
          })));
        }
      }
      if (extSource === 'hubspot') {
        // TODO implement when we have a hubspot user search
        // const data = awat api.getHubSpotUsers({ search debouncedSearchQuery });
        setLoadingExtUsers(false);
        // setExtUsers(data.users.map(user => ({
        //   name: user.Name,
        //   email: user.Email,
        //   role: user.UserRole ? user.UserRole.Name : '',
        //   id: user.Id,
        // })));
      }
    };
    fetchExternalUsers();
  }, [setExtUsers, debouncedSearchQuery]);

  const handleSelectedPermissionChange = (_: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void => {
    setSelectedPermission(data.value as Permission);
  };

  const handleQueryChange = (_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
    setSearchQuery(data.value);
  };

  const handleAddUser = (): void => {
    if (selectedUserId) {
      actions.user.saveUserFromSalesforce(selectedUserId, {
        isTracked: selectedTracked,
        permission: selectedPermission,
      }).then(response => {
        if (selectedTeamId !== undefined) {
          if (response.status) {
            actions.team.addTeamMember([response.user.id], selectedTeamId);
          }
        }
      });
    }
  };

  const handleSelectTeamChange = (_: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void => {
    setSelectedTeamId(data.value as string);
  };

  const teamOptions: DropdownItemProps[] = teams.map(team => ({
    key: team.id,
    value: team.id,
    text: team.name,
  }));
  teamOptions.unshift({ key: 'invalidOption', value: undefined, text: '' });

  const filteredExtUsers = extUsers.filter(sfUser => !usersSfIds.includes(sfUser.id!.toString()));
  return (
    <Modal closeIcon closeOnDimmerClick open={open} onClose={onClose}>
      <Modal.Header>Adding User</Modal.Header>
      <Modal.Content>
        <Input
          fluid
          placeholder="Search user"
          onChange={handleQueryChange}
        />
        <SimpleUserTable
          loading={loadingExtUsers}
          users={filteredExtUsers}
          selectedUserId={selectedUserId}
          onSelectUser={setSelectedUserId}
        />
        <div className={css.modalActionsContainer}>
          <div>
            <Select onChange={handleSelectedPermissionChange} value={selectedPermission} options={permissionOptions} />
            {' '}
            <PermissionsInfo permission={selectedPermission} />
          </div>
          <TrackedSeats
            selected={selectedTracked}
            disabled={numOfTrackedUsers >= maxTrackedSeats}
            maxTrackedSeats={maxTrackedSeats}
            numOfTrackedUsers={numOfTrackedUsers}
            onChange={(selected: boolean): void => setSelectedTracked(selected)}
          />
          <div>
            <Select placeholder="Team" onChange={handleSelectTeamChange} value={selectedTeamId} options={teamOptions} />
          </div>
          <Button loading={isSyncing} disabled={selectedUserId === undefined} onClick={handleAddUser}>Add</Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};
export default connect(
  (state: any) => ({
    isSyncing: state.user.isSyncing,
    usersSfIds: getUserSfIds(state),
    maxTrackedSeats: state.organization.maxTrackedSeats,
    numOfTrackedUsers: getNumOfTrackedUsers(state),
    teams: getTeams(state),
    extSource: state.app.extSource,
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(AddUserModalBase);
