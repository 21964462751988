import React, { useState } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';

import css from './SelectTag.module.css';

interface SelectTagProps {
  tags: string[];
  onSelect: (selectedTag: string) => void;
  loading?: boolean;
}

const SelectTag: React.FC<SelectTagProps> = ({ tags, onSelect, loading = false }) => {
  const [inputTag, setInputTag] = useState<string | undefined>(undefined);
  const [selectedTag, setSelectedTag] = useState<string | undefined>(undefined);

  const handleChange = (_: any, data: any): void => {
    setSelectedTag(data.value);
  };

  const handleSearchChange = (_: any, data: any): void => {
    setInputTag(data.searchQuery !== '' ? data.searchQuery : undefined);
  };

  const handleSelect = (): void => {
    if (selectedTag !== undefined) {
      onSelect(selectedTag);
    }
  };

  const handleListenForSubmission = (ev: any): void => {
    if (ev.key === 'Enter') {
      handleSelect();
    }
  };

  const options = tags.map(t => ({
    key: t,
    text: t,
    value: t,
  }));

  if (inputTag !== undefined && !options.map(o => o.text).includes(inputTag)) {
    options.push({ key: inputTag, value: inputTag, text: inputTag });
  }

  return (
    <div tabIndex={-1} className={css.dropdownContainer} onKeyDown={handleListenForSubmission}>
      <Dropdown
        onChange={handleChange}
        search
        selection
        placeholder="Select a tag"
        fluid
        loading={loading}
        options={options}
        onSearchChange={handleSearchChange}
      />
      <Icon name="check" onClick={handleSelect} className={`clickable ${css.icon}`} />
    </div>

  );
};

export default SelectTag;
