import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Activity, ActivityIcons } from 'models/activity';
import BSChart from 'components/BSChart';
import { getColorRgb, iconToPrettyName } from 'lib/util';
import { DataSet } from 'components/BSChart/BSChart';
import { SimpleJSDateTimePeriod } from 'features/DatePicker/datePickerTypes';
import { selectAppStartAndEndDates } from 'features/App/appSlice';

interface Props {
  activities: Array<Activity>;
  userInfo: any;
  isLoading: boolean;
  timePeriod: SimpleJSDateTimePeriod;
}

interface State { }

class UserActivityTypesOverTimeGraph extends Component<Props, State> {
  getChartData(): object {
    if (!this.props) return { datasets: {}, labels: [] };
    const { activities, timePeriod: { start, end } } = this.props;
    const eventsByIconAndDate = {};
    const dateFormat = 'MM-DD-YYYY';
    activities.forEach((activity: Activity) => {
      const activityDate = moment(activity.activityDate).startOf('day');
      const key = activityDate.format(dateFormat);
      if (!eventsByIconAndDate[activity.icon as ActivityIcons]) eventsByIconAndDate[activity.icon] = {};
      if (!eventsByIconAndDate[activity.icon as ActivityIcons][key]) {
        eventsByIconAndDate[activity.icon as ActivityIcons][key] = [];
      }
      eventsByIconAndDate[activity.icon as ActivityIcons][key].push(activity);
    });

    const endDate = moment.min(moment(end), moment()).endOf('day');
    const data: object[] = [];
    const types = Object.keys(eventsByIconAndDate);

    const allDates: Array<string | number> = [];
    types.forEach((activityIcon, i) => {
      const iterator = moment(start).startOf('day').clone();
      const activitiesByDate = {};
      while (iterator.isSameOrBefore(endDate)) {
        const current = iterator.format(dateFormat);
        if (eventsByIconAndDate[activityIcon][current]) {
          const activityAdderFn = (totalActivityCount, activity): number => totalActivityCount + activity.groupCnt;
          activitiesByDate[current] = {
            x: iterator.format(dateFormat),
            y: eventsByIconAndDate[activityIcon][current].reduce(activityAdderFn, 0),
          };
        } else activitiesByDate[current] = { x: current, y: 0 };
        // only do this on the first type, else we would add dates many times
        if (i === 0) allDates.push(iterator.format(dateFormat));
        iterator.add(1, 'day');
      }
      data.push({
        label: iconToPrettyName(activityIcon as ActivityIcons),
        data: Object.values(activitiesByDate),
        backgroundColor: getColorRgb(activityIcon as ActivityIcons),
        borderColor: getColorRgb(activityIcon as ActivityIcons),
        fill: false,
      });
    });
    return data;
  }

  render(): React.ReactNode {
    const { timePeriod: { start, end }, isLoading, userInfo } = this.props;
    const { id } = userInfo;
    const datasets = this.getChartData();
    return (
      <Segment>
        <BSChart
          startDate={start}
          endDate={end}
          datasets={datasets as DataSet[]}
          title="User Activity Over Time"
          titleLink={`/app/team/${id}/activityFeed`}
          isLoading={isLoading}
        />
      </Segment>
    );
  }
}

export default connect(
  (state: any) => ({
    activities: state.activity.activities,
    userInfo: state.user.userInfo,
    isLoading: state.activity.isLoadingActivities,
    timePeriod: selectAppStartAndEndDates(state),
  }),
)(UserActivityTypesOverTimeGraph);
