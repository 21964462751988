import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import RootNode from './RootNode';
import * as serviceWorker from './serviceWorker';

if (process.env.REACT_APP_SENTRY_ID) {
  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENV || process.env.NODE_ENV,
    dsn: process.env.REACT_APP_SENTRY_ID,
  });
}

ReactDOM.render(<RootNode />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
