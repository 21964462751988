import React from 'react';
import { connect } from 'react-redux';

export type Permission = 'account_owner' | 'admin' | 'manager' | 'rep'

interface AccessControlProps {
  accessingTo: Permission;
  allowSuperAdmin?: boolean;
  children: React.ReactNode;
  isSuperAdmin: boolean;
  permission: Permission;
}

type PermissionToValue = {
  [key in Permission]: number;
};

export function hasPermission(permission: Permission, accessingTo: Permission, isSuperAdmin = false): boolean {
  const permissionToValue: PermissionToValue = {
    account_owner: 20, // eslint-disable-line @typescript-eslint/camelcase
    admin: 30,
    manager: 40,
    rep: 50,
  };

  return permissionToValue[permission] <= permissionToValue[accessingTo] || isSuperAdmin;
}

export const permissionOptions = [
  { key: 'accountOwner', value: 'account_owner', text: 'Account Admin' },
  { key: 'admin', value: 'admin', text: 'Operations Admin' },
  { key: 'manager', value: 'manager', text: 'Team View' },
  { key: 'rep', value: 'rep', text: 'Individual View' },
];

export function permissionToLabel(permission: Permission): string {
  const obj = permissionOptions.find(p => p.value === permission);
  return obj ? obj.text : '';
}

const AccessControlBase: React.FC<AccessControlProps> = (
  { allowSuperAdmin, children, permission, isSuperAdmin, accessingTo }: AccessControlProps,
) => {
  if (hasPermission(permission, accessingTo, isSuperAdmin && allowSuperAdmin)) {
    return <>{children}</>;
  }

  return <></>;
};

export const AccessControl = connect(
  (state: any) => (
    {
      permission: state.app.permission,
      isSuperAdmin: state.app.isSuperAdmin,
    }
  ),
)(AccessControlBase);

export default AccessControl;
