/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

const activitiesSelector = (state): Array<any> => state.activity.activities;
const selectedIdSelector = (state): number => state.activity.selectedId;

export const getSelectedActivity = createSelector<any, any, any>(
  [activitiesSelector, selectedIdSelector],
  (activities: Array<any>, selectedId: number) => (
    selectedId && activities && activities.length ? activities.find(a => (a.id === selectedId)) : null
  ),
);
