import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Button, Icon, Image, Segment } from 'semantic-ui-react';
import { FaSalesforce } from 'react-icons/fa';

import allActions, { bindCombinedActions } from '../../../actions';

import logo from '../../../assets/logo.svg';

import css from './Home.module.css';

interface Props {
  actions: any;
  navigate: Function;
  isLoggedIn: boolean;
  errorMsg?: string;
}

class Home extends Component<Props, {}> {
  componentDidMount(): void {
    const { isLoggedIn, navigate } = this.props;
    if (isLoggedIn) {
      navigate('/app');
    }
  }

  handleLoginSalesforce = (): void => {
    const { actions } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    actions.app_.loginSalesforce();
  };

  handleLoginHubspot = (): void => {
    const { actions } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    actions.app_.loginHubspot();
  };

  render(): React.ReactNode {
    const { errorMsg } = this.props;
    const errorSection = errorMsg ? (
      <Segment className={css.error} color="red" inverted>
        <div>{errorMsg}</div>
        <div>
          {'Please contact '}
          <a href="mailto:support@buyersight.io">support@buyersight.io</a>
          {' for help.'}
        </div>
      </Segment>
    ) : null;
    return (
      <div className={css.main}>
        <Segment className={css.home}>
          <div className={css.content}>
            <Image src={logo} />
            <Button onClick={this.handleLoginSalesforce} className={`${css.loginButton} ${css.salesforceButton}`}>
              <FaSalesforce className="icon" />
              Login with Salesforce
            </Button>
            <Button onClick={this.handleLoginHubspot} className={`${css.loginButton} ${css.hubspotButton}`}>
              <Icon name="hubspot" />
              Login with HubSpot
            </Button>
          </div>
        </Segment>
        <div className={css.links}>
          <a href="mailto:support@buyersight.io">Contact</a>
          <Link to="/tos">Terms of Service</Link>
        </div>
        {errorSection}
      </div>
    );
  }
}

export default connect(
  (state: any) => ({ isLoggedIn: state.app.isLoggedIn, errorMsg: state.app.errorMsg }),
  dispatch => ({
    actions: bindCombinedActions(allActions, dispatch, ['app']),
    navigate: bindActionCreators((path: string) => push(path), dispatch),
  }),
)(Home);
