import React from 'react';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';

import prospectingApi, { ProspectingAccount } from 'features/Api/Prospecting';
import { getSelectedTeamId } from 'selectors/team';

import css from './styles.module.css';

interface Props {
  active?: ProspectingAccount[];
  inactive?: ProspectingAccount[];
}

const ProspectingStatsBox = ({
  title,
  value,
  highlight = false,
}): React.ReactElement => (
  <div className={`${css.statsBox} ${highlight ? css.highlight : ''}`}>
    <div className={css.value}>{value}</div>
    <div className={css.title}>{title}</div>
  </div>
);

const ProspectingAccountListHeader: React.FC<Props> = ({
  active,
  inactive,
}) => {
  const teamId = useSelector(getSelectedTeamId);
  const queryParams = teamId ? { teamId } : skipToken;

  const {
    data: noAccountData,
  } = prospectingApi.endpoints.getProspectingContactsNoAccount.useQuery(queryParams);

  if (!teamId || !active || !inactive) return null;

  const noAccountContacts = noAccountData?.contacts;
  const noAccountLength = noAccountContacts?.length || 0;
  const noAccountRecs = noAccountContacts?.filter(c => c.recommendations?.length).length || 0;

  const activeAccounts = active.length;
  const activeContacts = active.reduce((prev, a) => prev + a.numContacts, noAccountLength);
  const inactiveAccounts = inactive.length;
  const accountsWithRecommendations = active.filter(a => a.contactsWithRecommendations > 0).length;
  const contactsWithRecommendations = active.reduce((prev, a) => prev + a.contactsWithRecommendations, noAccountRecs);

  return (
    <div className={css.stats}>
      <ProspectingStatsBox title="Accounts being prospected" value={activeAccounts} />
      <ProspectingStatsBox title="Contacts being prospected" value={activeContacts} />
      <ProspectingStatsBox title="Recently inactive accounts" value={inactiveAccounts} />
      <ProspectingStatsBox title="Accounts with recommendations" value={accountsWithRecommendations} highlight />
      <ProspectingStatsBox title="Contacts with recommendations" value={contactsWithRecommendations} highlight />
    </div>
  );
};

export default ProspectingAccountListHeader;
