import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { DateTime } from 'luxon';
import qs from 'query-string';
import { Grid, Segment, Sticky, Ref } from 'semantic-ui-react';
import { AnimatePresence, motion } from 'framer-motion';
import { replace } from 'connected-react-router';

import { RootState } from 'store';
import allActions, { bindCombinedActions } from 'actions';
import { getSelectedActivity } from 'selectors/activity';

import ActivityHistory from 'components/ActivityHistory';
import ActivityDetails from 'components/ActivityDetails';
import ActivitySearchBar, { DisplayOptions } from 'components/ActivitySearchBar';
import ActivityInfoBar from 'components/ActivityInfoBar';

import { Activity, ActivityAccount, ActivityOwner, Tag } from 'models/activity';
import { OpportunityStage } from 'models/opportunity';

import DatePicker from 'features/DatePicker/DatePicker';
import { TimePeriod } from 'features/DatePicker/datePickerTypes';
import {
  getIsoDatesFromTimePeriod,
  getLuxonDateTimesFromTimePeriod,
  getStartAndEndDatesFromSingleDate,
} from 'features/DatePicker/datePickerFunctions';
import { selectContactDateSelection, setContactDateSelection } from 'features/Contact/contactSlice';

import ContactInfo from './components/ContactInfo';

import css from './Contact.module.css';

interface Props {
  actions: any;
  dispatchSetContactDateSelection: Function;
  activities: Array<Activity>;
  activityAccounts: Array<ActivityAccount>;
  activityOwners: Array<ActivityOwner>;
  activityTags: Array<Tag>;
  isLoadingActivities: boolean;
  isSyncing: boolean;
  location: any;
  match: { params: { contactId: string } };
  opportunityStages: Array<OpportunityStage>;
  replaceHistory: Function;
  selectedActivity: any;
  selectedId?: number;
  timePeriod: TimePeriod;
}

interface State {
  displayOptions: DisplayOptions;
  contactId: number;
}

class ContactActivity extends Component<Props, State> {
  contextRef = React.createRef();

  constructor(props) {
    super(props);

    const defaultDisplayOptions = {
      showEmail: true,
      showCall: true,
      showEvent: true,
      showTask: true,
      showSms: true,
      showAutomatic: true,
      searchTerm: '',
      accountWhitelist: [],
      opportunityStageWhitelist: [],
      userWhitelist: [],
      tagWhitelist: [],
      filterEmailByDirection: 'both',
      emailPredictionFilters: {
        automatic: true,
        followup: false,
        pricing: false,
        prospecting: false,
        all: true,
      },
    };

    const displayOptions = Object.assign(defaultDisplayOptions, props.displayOptions);

    this.state = {
      displayOptions,
      contactId: parseInt(props.match.params.contactId, 10),
    };
  }

  componentDidMount(): void {
    const { actions, location, timePeriod, dispatchSetContactDateSelection } = this.props;
    const { contactId } = this.state;

    const { search } = location;
    const params = qs.parse(search);
    if (params.activity) {
      const selectedId = parseInt(params.activity as string, 10);
      actions.activity.selectActivity(selectedId);
    }
    if (params.startDate && params.endDate) {
      const { startDate, endDate } = params;
      const start = DateTime.fromISO(startDate.toString()).toObject();
      const end = DateTime.fromISO(endDate.toString()).toObject();
      const tp: TimePeriod = { start, end, type: 'absolute' };
      dispatchSetContactDateSelection(tp);
    }
    if (params.date) {
      const { date } = params;
      const { start, end } = getStartAndEndDatesFromSingleDate(date.toString());
      const tp: TimePeriod = {
        start: start.toObject(),
        end: end.toObject(),
        type: 'absolute',
      };
      dispatchSetContactDateSelection(tp);
    }

    const { start, end } = getIsoDatesFromTimePeriod(timePeriod);

    const fetchInfo = async (): Promise<void> => {
      actions.activity.searchActivities({
        contactId,
        start,
        end,
        getTags: true,
        getOwners: true,
        getAccounts: true,
      });
    };
    fetchInfo();
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const {
      actions,
      isLoadingActivities,
      location,
      selectedId,
      selectedActivity,
      dispatchSetContactDateSelection,
      timePeriod,
      replaceHistory,
    } = this.props;

    const { contactId } = this.state;

    if (prevProps.isLoadingActivities
      && !isLoadingActivities
      && selectedId
      && selectedActivity
      && selectedActivity?.id !== prevProps.selectedActivity?.id) {
      this.scrollTo(`activity-${selectedActivity.id}`);
    }
    const { search } = location;
    const params = qs.parse(search);
    if (search !== prevProps.location.search) {
      if (params.activity) {
        const paramId = parseInt(params.activity as string, 10);
        actions.activity.selectActivity(paramId);
      }
      if (params.startDate && params.endDate) {
        const { startDate, endDate } = params;
        const start = DateTime.fromISO(startDate.toString()).toObject();
        const end = DateTime.fromISO(endDate.toString()).toObject();
        const tp: TimePeriod = { start, end, type: 'absolute' };
        dispatchSetContactDateSelection(tp);
      }
      if (params.date) {
        const { date } = params;
        const { start, end } = getStartAndEndDatesFromSingleDate(date.toString());
        const tp: TimePeriod = {
          start: start.toObject(),
          end: end.toObject(),
          type: 'absolute',
        };
        dispatchSetContactDateSelection(tp);
      }
    }

    if (prevState.contactId !== contactId) {
      const { start: startDate, end: endDate } = getIsoDatesFromTimePeriod(timePeriod);
      actions.activity.searchActivities({
        contactId,
        startDate,
        endDate,
        getTags: true,
        getOwners: true,
        getAccounts: true,
      });
    } else if (prevProps.timePeriod !== timePeriod) {
      const { start, end } = getLuxonDateTimesFromTimePeriod(timePeriod);
      const startDate = start.toFormat('yyyy-LL-dd');
      const endDate = end.toFormat('yyyy-LL-dd');
      actions.activity.searchActivities({
        contactId,
        startDate,
        endDate,
        getTags: true,
        getOwners: true,
        getAccounts: true,
      });
      let queryStr = `?startDate=${startDate}&endDate=${endDate}`;
      if (selectedId) {
        queryStr = `${queryStr}&activity=${selectedId}`;
      }
      replaceHistory(`${location.pathname}${queryStr}`);
    }
  }

  componentWillUnmount(): void {
    const { actions } = this.props;
    actions.activity.clearActivities();
  }

  handleSearch = (newOptions): void => {
    const { displayOptions } = this.state;
    this.setState({ displayOptions: Object.assign(displayOptions, newOptions) });
  };

  scrollTo = (id): void => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView();
    }
  };

  selectActivity = (activity): void => {
    const { actions, selectedActivity } = this.props;
    if (selectedActivity?.id === activity?.id) {
      actions.activity.selectActivity(null);
    } else {
      actions.activity.selectActivity(activity.id);
    }
  };

  filterHistory(history: Array<any>, displayOptions: DisplayOptions): Array<Activity> {
    const { timePeriod } = this.props;
    const {
      showEmail,
      showCall,
      showTask,
      showEvent,
      showSms,
      showLinkedIn,
      showAutomatic,
      searchTerm,
      accountWhitelist,
      userWhitelist,
      opportunityStageWhitelist,
      tagWhitelist,
      filterEmailByDirection,
      emailPredictionFilters,
    } = displayOptions;
    const lowerSearchTerm = searchTerm.toLowerCase();

    const { start, end } = getLuxonDateTimesFromTimePeriod(timePeriod);
    return history.filter((activity): boolean => {
      const {
        activityDate,
        description,
        icon,
        isAutomatic,
        isFollowup,
        isPricing,
        isProspecting,
        ownerId,
        accountId,
        direction,
        tags,
        opportunityStages,
      } = activity;
      switch (icon) {
        case 'mail':
          if (!showEmail) return false;
          break;
        case 'call':
          if (!showCall) return false;
          break;
        case 'tasks':
          if (!showTask) return false;
          break;
        case 'calendar':
          if (!showEvent) return false;
          break;
        case 'sms':
          if (!showSms) return false;
          break;
        case 'linkedin':
          if (!showLinkedIn) return false;
          break;
        default:
          return false;
      }
      if (!showAutomatic && isAutomatic) return false;
      const luxonActivityDate = DateTime.fromISO(activityDate);
      if (luxonActivityDate < start || luxonActivityDate > end) return false;
      if (lowerSearchTerm && (
        !description
        || !description.toLowerCase().includes(lowerSearchTerm))
      ) {
        return false;
      }
      if (userWhitelist?.length && !userWhitelist.includes(ownerId)) {
        return false;
      }
      if (accountWhitelist?.length
        && !accountWhitelist.includes(accountId)
        && !accountWhitelist.filter(a => activity.groupAccountIds?.includes(a)).length
      ) {
        return false;
      }
      if (opportunityStageWhitelist?.length) {
        const matchedStages = opportunityStages.filter(value => opportunityStageWhitelist.includes(value));
        if (matchedStages.length) {
          return true;
        }
        return false;
      }
      if (tagWhitelist?.length) {
        if (tags) {
          const matchedTags = tagWhitelist.filter(value => tags.includes(value));
          if (matchedTags.length) {
            return true;
          }
        }
        return false;
      }

      if (icon === 'mail') {
        const filterByMatchingDirection = filterEmailByDirection === 'both' || direction === filterEmailByDirection;

        const filterByPrediction = emailPredictionFilters.all
          || (emailPredictionFilters.automatic && isAutomatic)
          || (emailPredictionFilters.followup && isFollowup)
          || (emailPredictionFilters.pricing && isPricing)
          || (emailPredictionFilters.prospecting && isProspecting);

        return filterByMatchingDirection && filterByPrediction;
      }

      return true;
    });
  }

  renderActivityDetails = (): React.ReactNode => {
    const {
      actions,
      selectedId,
      selectedActivity,
      timePeriod,
    } = this.props;
    const { contactId } = this.state;

    if (selectedActivity && selectedActivity.contactId !== contactId) {
      actions.activity.unselectActivity();
      return null;
    }
    if (!selectedId || !selectedActivity) return null;

    const { start, end } = getIsoDatesFromTimePeriod(timePeriod);
    const handleUpdateOpportunity = async (): Promise<any> => {
      await actions.activity.searchActivities({
        contactId,
        start,
        end,
        getTags: true,
        getOwners: true,
        getAccounts: true,
        noCache: true,
      }, false);
    };

    const onTagAdd = async (activityId: string, tag: string): Promise<any> => {
      const response = await actions.activity.addTag(activityId, tag);
      if (response.status) {
        await actions.activity.searchActivities({
          contactId,
          start,
          end,
          getTags: true,
          getOwners: true,
          getAccounts: true,
          noCache: true,
        }, false);
      }
      return response;
    };
    const onTagRemove = async (activityId: string, tag: string): Promise<any> => {
      const response = await actions.activity.removeTag(activityId, tag);
      if (response.status) {
        actions.activity.searchActivities({
          contactId,
          start,
          end,
          getTags: true,
          getOwners: true,
          getAccounts: true,
          noCache: true,
        }, false);
      }
      return response;
    };

    return (
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.2 }}
        exit={{ scale: 0 }}
      >
        <ActivityDetails
          activity={selectedActivity}
          contactId={contactId}
          onTagAdd={onTagAdd}
          onTagRemove={onTagRemove}
          onUpdateOpportunity={handleUpdateOpportunity}
        />
      </motion.div>
    );
  };

  renderActivityHistory = (): React.ReactNode => {
    const { displayOptions } = this.state;
    const { activities, isLoadingActivities, isSyncing, selectedId, timePeriod } = this.props;
    const loadingMessage = isSyncing ? 'Syncing activity data from Salesforce...' : undefined;
    const { start, end } = getIsoDatesFromTimePeriod(timePeriod);
    return (
      <Segment>
        <ActivityHistory
          activityHistory={this.filterHistory(activities, displayOptions)}
          endDate={moment(end)}
          isLoading={isLoadingActivities || isSyncing}
          loadingMessage={loadingMessage}
          onClick={this.selectActivity}
          selectedId={selectedId}
          startDate={moment(start)}
          view="user"
        />
      </Segment>
    );
  };

  renderActivityInfo = (): React.ReactNode => {
    const { activities } = this.props;
    const { contactId } = this.state;
    return (
      <ContactInfo
        contactId={contactId}
        contactActivity={activities}
      />
    );
  };

  render(): React.ReactNode {
    const { displayOptions } = this.state;
    const { activities, activityAccounts, activityOwners, activityTags, opportunityStages, timePeriod } = this.props;

    const firstActivity = activities[activities.length - 1];
    const firstActivityDate = firstActivity ? firstActivity.activityDate : undefined;

    const datePicker = (<DatePicker reduxAction={setContactDateSelection} timePeriod={timePeriod} />);

    return (
      <div className={css.contact}>
        {this.renderActivityInfo()}
        <ActivitySearchBar
          accounts={activityAccounts}
          datePicker={datePicker}
          displayOptions={displayOptions}
          onChange={this.handleSearch}
          opportunityStages={opportunityStages}
          users={activityOwners}
          startDateLimit={firstActivityDate}
          tags={activityTags}
        />
        <ActivityInfoBar
          accounts={activityAccounts}
          owners={activityOwners}
          activities={this.filterHistory(activities, displayOptions)}
        />
        <Ref innerRef={this.contextRef}>
          <Grid columns={2}>
            <Grid.Column>
              {this.renderActivityHistory()}
            </Grid.Column>
            <Grid.Column>
              <Sticky context={this.contextRef} offset={10}>
                <AnimatePresence initial={false}>
                  {this.renderActivityDetails()}
                </AnimatePresence>
              </Sticky>
            </Grid.Column>
          </Grid>
        </Ref>
      </div>
    );
  }
}

export default connect(
  (state: RootState): Partial<Props> => ({
    isLoadingActivities: state.activity.isLoadingActivities,
    selectedId: state.activity.selectedId,
    selectedActivity: getSelectedActivity(state),
    activities: state.activity.activities,
    activityAccounts: state.activity.activityAccounts,
    activityOwners: state.activity.activityOwners,
    activityTags: state.activity.activityTags,
    opportunityStages: state.organization.opportunityStages,
    timePeriod: selectContactDateSelection(state),
  }),
  dispatch => ({
    actions: bindCombinedActions(allActions, dispatch),
    dispatchSetContactDateSelection: bindActionCreators(setContactDateSelection, dispatch),
    replaceHistory: bindActionCreators((path: string) => replace(path), dispatch),
  }),
)(ContactActivity);
