import React from 'react';
import { Table } from 'semantic-ui-react';

import DimmerLoader from 'components/DimmerLoader';
import { User } from 'models/user';
import css from './SimpleUserTable.module.css';

// Abstraction from sfUsers or BuyerSight users
export type SimpleUser = Pick<User, 'id' | 'role' | 'email' | 'name'>;

interface SimpleUserTableProps {
  users: SimpleUser[];
  onSelectUser?: (userId: string) => void;
  selectedUserId?: string | string[];
  loading?: boolean;
  emptyUsersMessage?: string;
}

const SimpleUserTable: React.FC<SimpleUserTableProps> = ({
  users,
  selectedUserId,
  onSelectUser,
  emptyUsersMessage = 'There are no users',
  loading = false,
}: SimpleUserTableProps): React.ReactElement => {
  const isSelectingMultipleUsers = Array.isArray(selectedUserId);

  const handleSelectUser = (userId: string): void => {
    if (onSelectUser) {
      onSelectUser(userId);
    }
  };

  if (!loading && users.length === 0) {
    return (
      <div className={`${css.tableContainer}`}>
        {emptyUsersMessage}
      </div>
    );
  }

  const usersRow = users.map(u => {
    const isUserSelected = isSelectingMultipleUsers
      ? selectedUserId?.includes(u.id!.toString())
      : u.id!.toString() === selectedUserId;
    return (
      <Table.Row
        className={`${isUserSelected && css.selectedRow} ${css.row}`}
        onClick={(): void => handleSelectUser(u.id!.toString())}
        key={u.id!}
      >
        <Table.Cell className={css.firstCell}>
          {u.name}
        </Table.Cell>
        <Table.Cell>
          {u.email}
        </Table.Cell>
        <Table.Cell>
          {u.role}
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <div className={`${css.tableContainer}`}>
      <DimmerLoader active={loading} />
      <Table basic="very" selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {usersRow}
        </Table.Body>
      </Table>
    </div>
  );
};

export default SimpleUserTable;
