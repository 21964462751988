import { ActivityCount } from 'models/activity';
import React, { useState } from 'react';
import { Grid, Icon, Popup } from 'semantic-ui-react';
import css from 'components/TeamComparisonChart/Body/Columns/BarColumn/BarColumn.module.css';

const Outer: React.FC<{
  setPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  height?: number;
}> = ({ height, setPopupOpen, children }) => {
  const openPopupCallback = (): void => setPopupOpen(true);
  const closePopupCallback = (): void => setPopupOpen(false);
  return (
    <div
      className={`${css.outer}`}
      style={{
        height,
        borderRadius: height ? height * 2 : undefined,
      }}
      onMouseOver={openPopupCallback}
      onFocus={openPopupCallback}
      onMouseOut={closePopupCallback}
      onBlur={closePopupCallback}
    >
      {children}
    </div>
  );
};

const Inner: React.FC<{ totalWidth: number }> = ({ totalWidth, children }) => (
  <div
    className={css.inner}
    style={{
      width: `${totalWidth}%`,
    }}
  >
    {children}
  </div>
);

const InnerComponent: React.FC<{
  className: 'mail' | 'call' | 'meeting' | 'tasks';
  width: number;
}> = ({ className, width }) => {
  if (width === 0) return <></>;
  return (
    <div
      className={`${css.innerComponent} ${css[`${className}Bg`]}`}
      style={{
        width: `${width}%`,
      }}
    />
  );
};

const PopupContent: React.FC<{ breakdown: ActivityCount }> = (
  { breakdown: { types: { email, call, event, task } } },
) => {
  const iconColumnWidth = 2;
  const nameColumnWidth = 10;
  const countColumnWidth = 4;
  return (
    <Grid columns={3} className={css.popup}>
      {/* Mail + subtypes */}
      <Grid.Row className={css.extraSpace}>
        <Grid.Column width={iconColumnWidth} className={css.mailText}>
          <Icon name="square full" />
        </Grid.Column>
        <Grid.Column width={nameColumnWidth} className={css.bold}>Emails</Grid.Column>
        <Grid.Column width={iconColumnWidth}>{email.total}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={iconColumnWidth} />
        <Grid.Column width={nameColumnWidth}>Inbound</Grid.Column>
        <Grid.Column width={countColumnWidth}>{email.types.inbound}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={iconColumnWidth} />
        <Grid.Column width={nameColumnWidth}>Outbound</Grid.Column>
        <Grid.Column width={countColumnWidth}>{email.types.outbound}</Grid.Column>
      </Grid.Row>

      {/* Calls */}
      <Grid.Row className={css.extraSpace}>
        <Grid.Column width={iconColumnWidth} className={css.callText}>
          <Icon name="square full" />
        </Grid.Column>
        <Grid.Column width={nameColumnWidth} className={css.bold}>Calls</Grid.Column>
        <Grid.Column width={countColumnWidth}>{call.total}</Grid.Column>
      </Grid.Row>

      {/* Meetings */}
      <Grid.Row className={css.extraSpace}>
        <Grid.Column width={iconColumnWidth} className={css.meetingText}>
          <Icon name="square full" />
        </Grid.Column>
        <Grid.Column width={nameColumnWidth} className={css.bold}>Meetings</Grid.Column>
        <Grid.Column width={countColumnWidth}>{event.total}</Grid.Column>
      </Grid.Row>

      {/* Tasks + subtypes */}
      <Grid.Row className={css.extraSpace}>
        <Grid.Column width={iconColumnWidth} className={css.tasksText}>
          <Icon name="square full" />
        </Grid.Column>
        <Grid.Column width={nameColumnWidth} className={css.bold}>Tasks</Grid.Column>
        <Grid.Column width={countColumnWidth}>{task.total}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={iconColumnWidth} />
        <Grid.Column width={nameColumnWidth}>SMS</Grid.Column>
        <Grid.Column width={countColumnWidth}>{task.types.sms}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={iconColumnWidth} />
        <Grid.Column width={nameColumnWidth}>LinkedIn</Grid.Column>
        <Grid.Column width={countColumnWidth}>{task.types.linkedin}</Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

interface Props {
  value: number | null;
  breakdown: ActivityCount;
  max: number;
  height?: number;
  enablePopup?: boolean;
}

export const ActivityBreakdownProgressBar: (props: Props) => React.ReactElement = ({
  value: totalVal,
  breakdown,
  max,
  height,
  enablePopup = false,
}) => {
  const [popupOpen, setPopupOpen] = useState(false);

  const {
    email: { total: emailCount },
    call: { total: callCount },
    event: { total: meetingCount },
    task: { total: taskCount },
  } = breakdown.types;

  let totalWidth = 1;
  if (totalVal !== null) {
    totalWidth = (totalVal / max) * 100;
  }
  if (totalWidth < 1) totalWidth = 1;
  if (totalWidth > 100) totalWidth = 100;

  const mailWidth = totalVal !== null ? (emailCount / totalVal) * 100 : 0;
  const callWidth = totalVal !== null ? (callCount / totalVal) * 100 : 0;
  const meetingWidth = totalVal !== null ? (meetingCount / totalVal) * 100 : 0;
  const taskWidth = totalVal !== null ? (taskCount / totalVal) * 100 : 0;

  const progressBar = (
    <Outer height={height} setPopupOpen={setPopupOpen}>
      <Inner totalWidth={totalWidth}>
        <InnerComponent className="mail" width={mailWidth} />
        <InnerComponent className="call" width={callWidth} />
        <InnerComponent className="meeting" width={meetingWidth} />
        <InnerComponent className="tasks" width={taskWidth} />
      </Inner>
    </Outer>
  );

  if (enablePopup) {
    return (
      <Popup
        trigger={progressBar}
        on={['hover', 'click']}
        open={popupOpen}
      >
        <Popup.Content>
          <PopupContent breakdown={breakdown} />
        </Popup.Content>
      </Popup>
    );
  }
  return progressBar;
};
