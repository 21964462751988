import React, { Component } from 'react';
import { Chart, registerables } from 'chart.js';
import { Link } from 'react-router-dom';
import { kebabCase } from 'lodash';
import { Loader, Segment, Icon } from 'semantic-ui-react';
import moment from 'moment';

import bslegend from 'lib/bslegend';

import css from './BSChart.module.css';

export interface DataSet {
  label: string;
  data: number[];
}

interface Props {
  startDate: Date;
  endDate: Date;
  datasets: DataSet[];
  title: string;
  titleLink: string;
  isLoading: boolean;
}
interface State { }

export default class BSChart extends Component<Props, State> {
  canvas;

  chart;

  constructor(props) {
    super(props);
    this.canvas = React.createRef();
    Chart.register(...registerables);
  }

  componentDidMount(): void {
    this.renderChart();
  }

  componentDidUpdate(): void {
    this.renderChart();
  }

  handleDownloadChart = (): void => {
    const { datasets, title, startDate, endDate } = this.props;
    const header: string[] = ['Date'];
    const data: any[][] = [];
    datasets.forEach((dataset, i) => {
      header.push(dataset.label);
      dataset.data.forEach((d, j) => {
        if (i === 0) {
          data.push([moment(startDate).add(j, 'day').format('YYYY-MM-DD'), d]);
        } else {
          data[j].push(d);
        }
      });
    });

    const rows = [header, ...data];
    const csvContent = `data:text/csv;charset=utf-8,${
      rows.map(e => e.join(',')).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download',
      `${moment(startDate).format('YYYYMMDD')}-${moment(endDate).format('YYYYMMDD')}_${title.replace(/\s/g, '_')}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  renderChart = (): void => {
    const { title, datasets, isLoading } = this.props;
    if (this.chart) this.chart.destroy();

    if (isLoading) return;

    const ctx = this.canvas.current.getContext('2d');
    const chart = new Chart(ctx, {
      type: 'line',
      data: { datasets },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          bslegend: {
            containerID: kebabCase(title),
          },
        },
        scales: {
          y: {
            display: true,
            title: {
              display: false,
              text: 'value',
            },
          },
          x: {
            display: true,
            title: {
              display: true,
              text: 'Date',
            },
          },
        },
        elements: { line: { tension: 0 } },
      },
      plugins: [bslegend],
    });
    this.chart = chart;
  };

  render(): React.ReactNode {
    const { isLoading, title, titleLink } = this.props;
    if (isLoading) {
      return (
        <Segment basic padded>
          <Loader active />
        </Segment>
      );
    }
    return (
      <div className={css.chartContainer}>
        <div className={css.chartActionsContainer}>
          <div className="link brand" onClick={this.handleDownloadChart}>
            <Icon name="download" title="Download as CSV" />
          </div>
        </div>
        <div>
          <div className={css.chartTitle}>
            <Link to={titleLink}>
              <p>{title}</p>
            </Link>
          </div>
          <div id={kebabCase(title)} />
          <canvas ref={this.canvas} style={{ width: '100%' }} />
        </div>
      </div>
    );
  }
}
