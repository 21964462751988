import React, { ReactElement, PropsWithChildren, useState } from 'react';
import { Grid, GridColumn, GridRow, Header, Icon, SemanticWIDTHS } from 'semantic-ui-react';

const Carousel = (props: PropsWithChildren<{
  title: string;
  elementsPerPage?: number;
}>): ReactElement => {
  const { title, elementsPerPage, children } = props;

  let columns = 2; // default columns = 2
  const childrenArr = React.Children.toArray(children);
  if (childrenArr.length === 1) {
    columns = 1;
  } else if (elementsPerPage !== undefined) {
    columns = elementsPerPage;
  }
  const pageCount = Math.ceil(childrenArr.length / columns);
  const lastPageIndex = Math.max(0, pageCount - 1);

  const pages: ReactElement[][] = [];
  for (let i = 0; i < childrenArr.length; i += 1) {
    const child = childrenArr[i];
    let c = <>{child}</>;
    if (React.isValidElement(child)) {
      c = React.cloneElement(child, { parentCarouselColumns: columns });
    }
    const pageIndex = Math.floor(i / columns);
    if (typeof pages[pageIndex] === 'undefined') {
      pages[pageIndex] = [c];
    } else {
      pages[pageIndex].push(c);
    }
  }

  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const currentPage = pages[currentPageIndex];
  let currentPageElements: ReactElement[] = [];
  if (currentPage !== undefined) {
    currentPageElements = currentPage.map((cp, index) => {
      const key = `carosel-el-${index}`;
      return (
        <GridColumn key={key}>
          {cp}
        </GridColumn>
      );
    });
  }

  const pageBack = (): void => {
    if (currentPageIndex === 0) {
      setCurrentPageIndex(lastPageIndex);
    } else {
      setCurrentPageIndex(prevState => prevState - 1);
    }
  };

  const pageForward = (): void => {
    if (currentPageIndex >= lastPageIndex) {
      setCurrentPageIndex(0);
    } else {
      setCurrentPageIndex(prevState => prevState + 1);
    }
  };

  let pageController;
  if (pageCount > 1) {
    pageController = (
      <Grid columns={3}>
        <GridColumn>
          <Icon name="chevron circle left" onClick={pageBack} />
        </GridColumn>
        <GridColumn>
          {`${currentPageIndex + 1}/${pageCount}`}
        </GridColumn>
        <GridColumn>
          <Icon name="chevron circle right" onClick={pageForward} />
        </GridColumn>
      </Grid>
    );
  }

  return (
    <Grid className="bsCarousel" data-columns={columns}>
      <GridRow>
        <GridColumn width={13}>
          <Header>
            {title}
          </Header>
        </GridColumn>
        <GridColumn width={3}>
          {pageController}
        </GridColumn>
      </GridRow>
      <GridRow columns={columns as SemanticWIDTHS}>
        {currentPageElements}
      </GridRow>
    </Grid>
  );
};

export default Carousel;
