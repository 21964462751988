import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Header, Sidebar, Menu, Button, Icon } from 'semantic-ui-react';

import { fetchSidebarAlertNotifications, markSeenNotifications } from 'actions/alert-actions';
import { isToday, isThisWeekButNotToday, isLastWeek, isOlder } from 'lib/util';
import { getActiveNotifications, getUnreadNotificationIds } from 'selectors/alert';
import usePrevious from 'hooks/usePrevious';
import moment from 'moment/moment';
import AlertNotificationSidebarItem from './components/AlertNotificationSidebarItem';


import css from './NotificationSidebar.module.css';
import { fetchRecommendationSidebar, markSeen } from '../../../actions/recommendation-actions';
import { Recommendation } from '../../../models/recommendation';
import AlertRecommendationSidebarItem from './components/AlertRecommendationSidebarItem';
// import { Notification } from '../../../models/alert';

interface NotificationSidebarProps {
  visible: boolean;
}

type State = any;

const NotificationSidebar: React.FC<NotificationSidebarProps> = ({ visible }): React.ReactElement => {
  const limit = 20;
  const [currentOffset, setCurrentOffset] = useState(0);

  const dispatch = useDispatch();

  const alertNotifications: Array<any> = useSelector(getActiveNotifications);
  const recommendationNotifications: Array<Recommendation> = useSelector(
    (state: State) => state.recommendation.sidebar,
  );
  const isLoadingNotifications = useSelector((state: any) => state.alert.isLoadingSidebarNotifications);
  const unseenNotifications = useSelector(getUnreadNotificationIds);
  const teamId = useSelector((state: State) => state.team.selectedTeamId);

  const prevVisible = usePrevious(visible);

  useEffect(() => {
    let timeoutId;
    const timeout = (): void => {
      timeoutId = window.setTimeout((): void => {
        dispatch(fetchSidebarAlertNotifications({ limit, offset: 0 }));
        dispatch(fetchRecommendationSidebar({ limit, offset: 0 }));
        setCurrentOffset(0);
        timeout();
      }, 1000 * 60 * 15);
    };
    timeout();
    return (): void => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSidebarAlertNotifications({ limit, offset: currentOffset }));
    dispatch(fetchRecommendationSidebar({ limit, offset: currentOffset }));
  }, [dispatch, teamId, currentOffset]);

  useEffect(() => {
    if (prevVisible && !visible) {
      dispatch(markSeenNotifications(unseenNotifications));
    }
  }, [dispatch, prevVisible, unseenNotifications, visible]);

  const handleLoadMore = (): void => {
    setCurrentOffset(currentOffset + limit);
  };

  const reloadRecommendations = async (notificationId): Promise<void> => {
    await dispatch(markSeen(notificationId));
    await dispatch(fetchRecommendationSidebar({ limit, offset: currentOffset }));
  };

  const renderTimeFramedData = (conditionalHandler: (date: string) => boolean, title: string): React.ReactNode => {
    const combinedNotifications: Array<any> = alertNotifications
      .concat(recommendationNotifications).sort((a, b) => {
        const aVal = a.notificationDate || a.createdAt;
        const bVal = b.notificationDate || b.createdAt;

        if (moment(aVal).isBefore(bVal)) return 1;
        if (moment(aVal).isSame(bVal)) return 0;
        return -1;
      });
    const filteredJSX: JSX.Element[] = [];
    combinedNotifications.forEach(notification => {
      if (notification.alertRuleId && conditionalHandler(notification.createdAt)) {
        filteredJSX.push(
          <AlertNotificationSidebarItem
            key={notification.id}
            alertData={notification}
            limit={currentOffset + limit}
          />,
        );
      } else if (notification.recommendationId && conditionalHandler(notification.notificationDate)) {
        filteredJSX.push(
          <AlertRecommendationSidebarItem
            key={notification.notificationId}
            alertData={notification}
            onClick={reloadRecommendations}
          />,
        );
      }
    });

    if (filteredJSX.length === 0) {
      return null;
    }

    return (
      <div className={css.timeFrame}>
        <Header as="h4">{title}</Header>
        {filteredJSX}
      </div>
    );
  };

  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      className={css.sidebar}
      direction="right"
      id="alert-sidebar"
      icon="labeled"
      inverted
      vertical
      visible={visible}
      width="wide"
    >
      <div className={css.container}>
        <div>
          <div className={css.title}>
            <Header as="h2">Recent Notifications</Header>
          </div>
          <div className={css.content}>
            {renderTimeFramedData(isToday, 'Today')}
            {renderTimeFramedData(isThisWeekButNotToday, 'This Week')}
            {renderTimeFramedData(isLastWeek, 'Last Week')}
            {renderTimeFramedData(isOlder, 'Older than 2 weeks')}
          </div>
        </div>
        <div className={css.footer}>
          <Button className={css.footerButton} onClick={handleLoadMore}>
            {isLoadingNotifications && <Icon name="circle notch" loading />}
            Load More
          </Button>
          <Button className={css.footerButton}>
            <Link className={css.editLink} to="/app/alerts">
              Edit Alerts
            </Link>
          </Button>
        </div>
      </div>
    </Sidebar>
  );
};

export default NotificationSidebar;
