import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Segment, Checkbox, CheckboxProps, Input, InputOnChangeData, Button } from 'semantic-ui-react';

import { toast } from 'react-toastify';
import allActions, { bindCombinedActions } from 'actions';
import { Permission, hasPermission } from 'components/AccessControl';

import LoadingDimmer from 'components/LoadingDimmer/LoadingDimmer';
import css from './Info.module.css';

interface Props {
  actions: any;
  email: string;
  firstName: string;
  lastName: string;
  name: string;
  permission: Permission;
  settings: any;
  isLoadingSettings: boolean;
  errorUpdatingSettings: string;
  isUpdatingSettings: boolean;
}

interface State {
  accountActivePeriodChange: number | undefined;
}

class Info extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { accountActivePeriodChange: undefined };
  }

  handleAccountActivePeriodChange = (_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
    this.setState({ accountActivePeriodChange: data.value ? parseInt(data.value, 10) : undefined });
  }

  componentDidUpdate = (prevProps): void => {
    const { actions, errorUpdatingSettings } = this.props;
    if (prevProps.errorUpdatingSettings !== errorUpdatingSettings
      && errorUpdatingSettings !== undefined) {
      toast.error(errorUpdatingSettings);
      actions.organization.clearOrganizationSettingsError();
    }
  }

  handleExcludeWeekendsSettingsChange = (_: React.FormEvent<HTMLInputElement>, data: CheckboxProps): void => {
    const { actions } = this.props;
    actions.organization.setOrganizationSettings({ excludeWeekends: data.checked });
  }

  handleSaveAccountActivePeriod = (): void => {
    const { actions } = this.props;
    const { accountActivePeriodChange } = this.state;
    if (accountActivePeriodChange !== undefined && accountActivePeriodChange >= 1) {
      actions.organization.setOrganizationSettings({ accountActivePeriod: accountActivePeriodChange });
    }
  }

  renderSettings = (): React.ReactNode => {
    const { isUpdatingSettings, settings: { excludeWeekends, accountActivePeriod } } = this.props;
    const { accountActivePeriodChange } = this.state;
    return (
      <>
        <Header>Settings</Header>
        <div className={css.settingsContainer}>
          <LoadingDimmer active={isUpdatingSettings} />
          <Checkbox
            className={css.settingContainer}
            checked={excludeWeekends}
            toggle
            onChange={this.handleExcludeWeekendsSettingsChange}
            label="Exclude weekends from daily average calculations"
          />
          <div className={css.settingContainer}>
            <Input
              className={css.smallInput}
              type="number"
              min="1"
              defaultValue={accountActivePeriod}
              onChange={this.handleAccountActivePeriodChange}
            />
            {
              (accountActivePeriodChange && accountActivePeriodChange !== accountActivePeriod)
              && <Button icon="check" className={css.settingLabel} onClick={this.handleSaveAccountActivePeriod} />
            }
            <label className={css.settingLabel}>Account active period</label>
          </div>
        </div>
      </>
    );
  }

  render(): React.ReactNode {
    const { name, email, permission } = this.props;
    return (
      <Segment className={css.info}>
        <Header>User Info</Header>
        <div>{name}</div>
        <div>{email}</div>
        {hasPermission(permission, 'admin') && (
          this.renderSettings()
        )}
      </Segment>
    );
  }
}

export default connect(
  (state: any) => ({
    email: state.app.email,
    firstName: state.app.firstName,
    lastName: state.app.lastName,
    name: state.app.name,
    permission: state.app.permission,
    settings: state.organization.settings,
    isLoadingSettings: state.organization.isLoadingSettings,
    errorUpdatingSettings: state.organization.errorUpdatingSettings,
    isUpdatingSettings: state.organization.isUpdatingSettings,
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(Info);
