import React, { FC } from 'react';
import { Button } from 'semantic-ui-react';
import {
  SortSettings,
  TeamComparisonChart,
} from 'components/TeamComparisonChart/TeamComparisonChart';
import { TeamComparisonChartBody } from 'components/TeamComparisonChart/Body/Body';
import { useGetTeamComparisonData } from 'components/TeamComparisonChart/helperFunctions';
import { getApiDatesFromTimePeriod } from 'features/DatePicker/datePickerFunctions';
import { TimePeriod } from 'features/DatePicker/datePickerTypes';

import { getMetricDisplayInfo, MetricName } from 'models/metric';

import css from './TeamComparisonChart.module.css';

const TeamComparisonChartContent: FC<{
  modalMetric: MetricName;
  teamId: number;
  timePeriod: TimePeriod;
  includedRepIds?: number[];
  sortSettings: SortSettings | undefined;
  displayAsPercentage: boolean;
  setDisplayAsPercentage: (val: boolean) => void;
}> = ({
  modalMetric,
  teamId,
  timePeriod,
  includedRepIds,
  sortSettings,
  displayAsPercentage,
  setDisplayAsPercentage,
}) => {
  const { start, end } = getApiDatesFromTimePeriod(timePeriod);
  const {
    isFetching,
    data,
    legend,
    averageValue,
  } = useGetTeamComparisonData(modalMetric, teamId, start, end, includedRepIds);
  const { unitIcon, higherIsBetter } = getMetricDisplayInfo(modalMetric);
  return (
    <TeamComparisonChart
      data={data || []}
      legend={legend}
      higherIsBetter={higherIsBetter}
      sortSettings={sortSettings}
    >
      <TeamComparisonChart.Header className={css.modalChartHeader}>
        <section className={css.legendBox}>
          <TeamComparisonChart.Legend />
        </section>
        <section className={css.datePicker}>
          <Button.Group
            className={css.buttons}
            buttons={[
              {
                key: false,
                icon: unitIcon,
                active: !displayAsPercentage,
                onClick: (): void => setDisplayAsPercentage(false),
              },
              {
                key: true,
                icon: 'percent',
                active: displayAsPercentage,
                onClick: (): void => setDisplayAsPercentage(true),
              },
            ]}
          />
        </section>
      </TeamComparisonChart.Header>
      <TeamComparisonChartBody sortable showHeaders loading={isFetching} size="big">
        <TeamComparisonChartBody.NameColumn linkNames />
        <TeamComparisonChartBody.BarColumn enablePopup>
          { averageValue
            ? <TeamComparisonChartBody.BarColumn.BenchmarkLine value={averageValue} title="Team Avg" />
            : <></> }
        </TeamComparisonChartBody.BarColumn>
        { displayAsPercentage
          ? <TeamComparisonChartBody.PercentColumn />
          : <TeamComparisonChartBody.ValueColumn /> }
      </TeamComparisonChartBody>
    </TeamComparisonChart>
  );
};

export default TeamComparisonChartContent;
