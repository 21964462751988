import React from 'react';
import SalesforceLink from './SalesforceLink';
import HubSpotLink from './HubSpotLink';

interface Props {
  extId?: string;
  extSource?: string;
  type?: 'account' | 'contact' | 'opportunity' | 'activity' | 'user';
}

const ExternalLink = (props: Props): React.ReactElement | null => {
  const { extId, extSource, type } = props;
  if (!extId) return null;
  if (extSource === 'salesforce') {
    return (<SalesforceLink salesforceId={extId} />);
  }
  if (extSource === 'hubspot') {
    return (<HubSpotLink extId={extId} type={type} />);
  }
  return null;
};

export default ExternalLink;
