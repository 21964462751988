import { LabeledSelection } from './datePickerTypes';

export const defaultSelections: LabeledSelection[] = [
  {
    label: 'Past 7 days',
    start: { days: -7 },
    end: {},
    type: 'relative',
  },
  {
    label: 'Past 14 days',
    start: { days: -14 },
    end: {},
    type: 'relative',
  },
  {
    label: 'Past 30 days',
    start: { days: -30 },
    end: {},
    type: 'relative',
  },
  {
    label: 'Past 90 days',
    start: { days: -90 },
    end: {},
    type: 'relative',
  },
  {
    label: 'Current Month',
    start: { months: 0, startOf: 'month' },
    end: { months: 0, endOf: 'month' },
    type: 'relative',
  },
  {
    label: 'Previous Month',
    start: { months: -1, startOf: 'month' },
    end: { months: -1, endOf: 'month' },
    type: 'relative',
  },
  {
    label: 'Current Quarter',
    start: { quarters: 0, startOf: 'quarter' },
    end: { quarters: 0, endOf: 'quarter' },
    type: 'relative',
  },
  {
    label: 'Previous Quarter',
    start: { quarters: -1, startOf: 'quarter' },
    end: { quarters: -1, endOf: 'quarter' },
    type: 'relative',
  },
];

export default defaultSelections;
