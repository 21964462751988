import { TeamApi, prospectingApi } from 'features/Api';
import { EmailMetrics } from 'models/metric/activityMetrics';
import React, { useState, ReactElement } from 'react';
import { Grid, GridColumn, GridRow, Segment } from 'semantic-ui-react';
import { DateTime, Duration } from 'luxon';
import { MetricName } from 'models/metric';

import { round } from 'lib/util';
import BehaviorMetric from 'components/BehaviorMetric/BehaviorMetric';
import { Threshold } from 'models/team/settings/accountHealth';

import css from './styles.module.css';
import MetricOverTimeChartModal from '../components/MetricOverTimeChartModal';

export default function ProspectingBasicStats(props: {
  userId: number;
  teamId: number;
  startDateIso: string;
  endDateIso: string;
}): ReactElement {
  const { userId, teamId, startDateIso, endDateIso } = props;
  const [isMetricOverTimeChartModalOpen, setIsMetricOverTimeChartModalOpen] = useState(false);
  const [statNameInModal, setStatNameInModal] = useState<MetricName | undefined>(undefined);
  const {
    data: teamStatsApiResponse,
    isLoading,
    isFetching,
    isError,
  } = TeamApi.getActivityStats.useActivityStatsQuery({
    teamId,
    startDate: DateTime.fromISO(startDateIso).toFormat('yyyyMMdd'),
    endDate: DateTime.fromISO(endDateIso).toFormat('yyyyMMdd'),
  });

  const {
    data: strategyInsightsData,
    isLoading: isLoadingStrategyInsights,
  } = prospectingApi.endpoints.getProspectingStrategyInsights.useQuery({ teamId });

  let userStats: EmailMetrics;
  let avgWordCountStr = 'Error';
  let avgGradeLevelStr = 'Error';
  let avgResponseDaysStr = 'Error';
  let avgFollowupDaysStr = 'Error';

  if (!isLoading && !isFetching && !isError
    && teamStatsApiResponse && teamStatsApiResponse.emailStats && teamStatsApiResponse.emailStats.members[userId]) {
    userStats = teamStatsApiResponse.emailStats.members[userId];
    const {
      avgWordCount, avgGradeLevel,
      avgRepResponse: avgResponseSeconds, avgRepFollowUp: avgFollowupSeconds,
    } = userStats;

    avgResponseDaysStr = avgResponseSeconds !== null
      ? `${Duration.fromObject({ seconds: avgResponseSeconds }).as('days').toFixed(2)} days`
      : '--';
    avgFollowupDaysStr = avgFollowupSeconds !== null
      ? `${Duration.fromObject({ seconds: avgFollowupSeconds }).as('days').toFixed(2)} days`
      : '--';

    avgWordCountStr = avgWordCount !== null
      ? avgWordCount.toFixed(1).toString()
      : '--';
    avgGradeLevelStr = avgGradeLevel !== null
      ? avgGradeLevel.toFixed(1).toString()
      : '--';
  } else if (isLoading || isFetching) {
    avgWordCountStr = 'Loading...';
    avgGradeLevelStr = 'Loading...';
    avgResponseDaysStr = 'Loading...';
    avgFollowupDaysStr = 'Loading...';
  }

  const openMetricOverTimeChartModal = (statName: MetricName): void => {
    setIsMetricOverTimeChartModalOpen(true);
    setStatNameInModal(statName);
  };

  const metricOverTimeChartModal: ReactElement = (
    <MetricOverTimeChartModal
      open={isMetricOverTimeChartModalOpen}
      onClose={(): void => setIsMetricOverTimeChartModalOpen(false)}
      modalMetric={statNameInModal as MetricName}
      teamId={teamId}
      userId={userId}
    />
  );
  let metricWordCount: number | undefined;
  let metricGradeLevel: number | undefined;
  let metricResponseDays: number | undefined;
  let metricFollowupDays: number | undefined;

  /* eslint-disable max-len */
  let emailLengthText = 'Average outbound email word length, which is often correlated with response rates.';
  let emailGradeLevelText = 'Average outbound email readability, as measured by Flesch-Kincaid grade level. A higher grade level higher is considered difficult to read.';
  let timeToRespondText = 'Average time it takes the rep to respond to inbound emails from prospects and customers.';
  let timeToFollowUpText = 'Average time between a rep\'s outbound follow up emails, which have been identified by BuyerSight\'s intelligent tagging.';

  if (!isLoadingStrategyInsights && strategyInsightsData && strategyInsightsData.preConversation) {
    const { preConversation, postConversation } = strategyInsightsData;
    const metricValue = (
      metricName: string,
      strategyInsightGroup: Array<Threshold & {id: number}>,
    ): number | undefined => {
      const metricThreshold = strategyInsightGroup.find(a => a.metricName === metricName);
      if (metricThreshold) {
        return round(metricThreshold.meanWon, 1);
      }
      return undefined;
    };
    metricWordCount = metricValue('word_count_mean', preConversation);
    metricGradeLevel = metricValue('flesch_kincaid_grade_mean', preConversation);
    metricResponseDays = metricValue('rep_response_time_mean', postConversation);
    metricFollowupDays = metricValue('rep_followup_time_mean', preConversation);

    if (metricWordCount) {
      emailLengthText = `Average outbound email word length, which is often correlated with response rates. Pre-conversation emails on your team are most successful at ${metricWordCount} words.`;
    }
    if (metricGradeLevel) {
      emailGradeLevelText = `Average outbound email readability, as measured by Flesch-Kincaid grade level. A higher grade level higher is considered difficult to read. Pre-conversation emails on your team are most successful at a grade level of ${metricGradeLevel}.`;
    }
    if (metricResponseDays) {
      timeToRespondText = `Average time it takes the rep to respond to inbound emails from prospects and customers. Your team is most successful when they respond to inbound emails within ${metricResponseDays} ${metricResponseDays === 1 ? 'day' : 'days'}.`;
    }
    if (metricFollowupDays) {
      timeToFollowUpText = `Average time between a rep's outbound follow up emails, which have been identified by BuyerSight's intelligent tagging. Your team is most successful when they follow up within ${metricFollowupDays} ${metricFollowupDays === 1 ? 'day' : 'days'}.`;
    }
  }
  /* eslint-enable max-len */

  return (
    <Segment>
      {metricOverTimeChartModal}
      <Grid padded className={css.metricGrid}>
        <GridRow columns={4}>
          <GridColumn
            stretched
            onClick={(): void => openMetricOverTimeChartModal('avgWordCount')}
          >
            <BehaviorMetric
              icon="envelope-open-text"
              label="Email Length"
              value={avgWordCountStr}
              popupText={emailLengthText}
            />
          </GridColumn>
          <GridColumn
            stretched
            onClick={(): void => openMetricOverTimeChartModal('avgGradeLevel')}
          >
            <BehaviorMetric
              icon="book"
              label="Email Grade Level"
              value={avgGradeLevelStr}
              popupText={emailGradeLevelText}
            />
          </GridColumn>
          <GridColumn
            stretched
            onClick={(): void => openMetricOverTimeChartModal('avgRepResponse')}
          >
            <BehaviorMetric
              icon="stop-watch"
              label="Time to Respond"
              value={avgResponseDaysStr}
              popupText={timeToRespondText}
            />
          </GridColumn>
          <GridColumn
            stretched
            onClick={(): void => openMetricOverTimeChartModal('avgRepFollowUp')}
          >
            <BehaviorMetric
              icon="stop-watch"
              label="Time Between Followups"
              value={avgFollowupDaysStr}
              popupText={timeToFollowUpText}
            />
          </GridColumn>
        </GridRow>
      </Grid>
    </Segment>
  );
}
