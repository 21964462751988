import { createActions } from 'redux-actions';
import api from 'lib/api';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const actions = createActions({
  ADMIN: {
    SET_SELECTED_ORGANIZATION: selectedOrganizationId => ({ selectedOrganizationId }),
    FETCH_ORGANIZATIONS_REQUEST: undefined,
    FETCH_ORGANIZATIONS_SUCCESS: organizations => ({ organizations }),
    FETCH_ORGANIZATIONS_FAILURE: undefined,
    FETCH_USERS_BY_ORG_REQUEST: undefined,
    FETCH_USERS_BY_ORG_SUCCESS: users => ({ users }),
    FETCH_USERS_BY_ORG_FAILURE: undefined,
    FETCH_RECOMMENDATIONS_BY_ORG_REQUEST: undefined,
    FETCH_RECOMMENDATIONS_BY_ORG_SUCCESS: recommendations => ({ recommendations }),
    FETCH_RECOMMENDATIONS_BY_ORG_FAILURE: undefined,
    FETCH_RECOMMENDATION_TAGS_BY_ORG_REQUEST: undefined,
    FETCH_RECOMMENDATION_TAGS_BY_ORG_SUCCESS: tags => ({ tags }),
    FETCH_RECOMMENDATION_TAGS_BY_ORG_FAILURE: undefined,
  },
});
/* eslint-enable @typescript-eslint/explicit-function-return-type */

export const fetchOrganizations = (): Function => async (dispatch: Function): Promise<any> => {
  dispatch(actions.admin.fetchOrganizationsRequest());
  const response = await api.getAllOrganizations();
  if (response.status) {
    dispatch(actions.admin.fetchOrganizationsSuccess(response.organizations));
  } else {
    dispatch(actions.admin.fetchOrganizationsFailure());
  }
  return response;
};

export const fetchUsersByOrg = (organizationId: number): Function => async (dispatch: Function): Promise<any> => {
  dispatch(actions.admin.fetchUsersByOrgRequest());
  const response = await api.getUsersByOrg(organizationId);
  if (response.status) {
    dispatch(actions.admin.fetchUsersByOrgSuccess(response.users));
  } else {
    dispatch(actions.admin.fetchUsersByOrgFailure());
  }
  return response;
};

export const fetchRecommendationsByOrg = (
  organizationId: number,
  opts: any,
): Function => async (dispatch: Function): Promise<any> => {
  dispatch(actions.admin.fetchRecommendationsByOrgRequest());
  const response = await api.getRecommendationsByOrg(organizationId, opts);
  if (response.status) {
    const { recommendations } = response;
    dispatch(actions.admin.fetchRecommendationsByOrgSuccess(recommendations));
  } else {
    dispatch(actions.admin.fetchRecommendationsByOrgFailure());
  }
  return response;
};

export const fetchRecommendationTagsByOrg = (
  organizationId: number,
): Function => async (dispatch: Function): Promise<any> => {
  dispatch(actions.admin.fetchRecommendationTagsByOrgRequest());
  const response = await api.getRecommendationTagsForAdmin(organizationId);
  if (response.status) {
    const { tags } = response;
    dispatch(actions.admin.fetchRecommendationTagsByOrgSuccess(tags));
  } else {
    dispatch(actions.admin.fetchRecommendationTagsByOrgFailure());
  }
  return response;
};

const adminActions = {
  admin: {
    ...actions.admin,
    fetchOrganizations,
    fetchUsersByOrg,
    fetchRecommendationsByOrg,
    fetchRecommendationTagsByOrg,
  },
};

export default adminActions;
