import React from 'react';
import { User } from 'models/user';
import { Icon, Image } from 'semantic-ui-react';

import ExternalLink from 'components/ExternalLink';
import css from './UserInfoPlain.module.css';

interface UserInfoPlainProps {
  user: User;
}

const UserInfoPlain: React.FC<UserInfoPlainProps> = ({ user }) => (
  <div className={css.userDetailsContainer}>
    <div className={css.userDetailsItem}>
      {user.photoUrl
        ? <Image src={user.photoUrl} circular className={css.profilePhoto} />
        : <Icon name="user" size="big" />}
    </div>
    <div className={`${css.userInfoContainer} ${css.userDetailsItem}`}>
      <div className={css.userName}>{user.name}</div>
      <div className={css.userEmail}>{user.email}</div>
    </div>
    <ExternalLink extId={user.extId} extSource={user.extSource} type="user" />
  </div>
);

export default UserInfoPlain;
