
export interface AccountWithStats {
  id: number;
  name: string;
  ownerId: number;
  ownerName: string;
  lastActivityDate: string;
  isAccountActive: boolean;
  wasRecentlyActive: boolean;
  numActivities: number;
  numActivitiesAllTime: number;
  dailyAvgActivities: number;
  numOpenOpportunities: number;
}

export interface Account {
  active: true;
  annualRevenue: number | null;
  billingAddress: string;
  billingCity: string;
  billingCountry: string;
  billingPostalCode: string;
  billingState: string;
  billingStreet: string;
  createDate: string;
  createdAt: string;
  customData: null | any;
  deleted: false;
  description: string;
  extId: string;
  extSource: string;
  id: number;
  industry: null;
  lastActivitySync: string | null;
  lastEventSync: null;
  lastSync: string;
  name: string;
  numEmployees: number | null;
  organizationId: number;
  ownerId: number;
  ownership: string | null;
  parentId: number | null;
  phone: string;
  rating: string | null;
  sfId?: string;
  type: string;
  website: string;
}

export interface AccountHealthThresholds {
  minTimeInStage: number;
  recentActivity: number;
  avgTimeBetweenActivity: number;
  timeInStage: {
    default: number;
    [stageName: string]: number;
  };
  avgFollowUp: number;
  avgProspectResponse: number;
}

export type Grade = 'A' | 'B' | 'C' | 'D' | 'F';
export type AccountHealth = 'healthy' | 'critical' | 'inactive' | 'won' | 'lost' | 'preOpportunity';

export interface OpportunityWithHealth {
  opportunityId?: number;
  opportunitySalesforceId?: string;
  opportunityName?: string;
  opportunityOwnerId?: number;
  opportunityOwner?: string;
  opportunityType?: string;
  grade?: Grade;
  status?: AccountHealth;
  stageName?: string;
  prevStage?: string;
  weeklyActivityVelocity?: number;
  weeklyActivityVelocityInStage?: number;
  timeSinceLastActivity?: number;
  timeInStage?: number;
  avgTimeBetweenActivity?: number;
  avgTimeBetweenActivityInStage?: number;
  avgFollowUp?: number;
  avgFollowUpInStage?: number;
  [key: string]: any;
}

export interface AccountWithHealth {
  accountId: number;
  accountSalesforceId: string;
  accountName: string;
  accountOwnerId: number;
  accountOwner: string;
  hasData: boolean;
  lastActivityId: number;
  lastActivityDate: string;
  opportunities: Array<OpportunityWithHealth>;
}

export interface FlattenedAccountWithHealth extends Omit<AccountWithHealth, 'opportunities'>, OpportunityWithHealth {
  owner: string;
  ownerId: number;
  opportunityCount: number;
}

export const healthStatusMap: {
  [key in AccountHealth]: {
    key: string;
    text: string;
    value: string;
    color: string;
  }
} = {
  healthy: {
    key: 'healthy',
    text: 'Healthy',
    value: 'healthy',
    color: '#21BA45FF',
  },
  warning: {
    key: 'warning',
    text: 'Needs Attention',
    value: 'warning',
    color: '#F2701DFF',
  },
  critical: {
    key: 'critical',
    text: 'Unhealthy',
    value: 'critical',
    color: '#DA2928FF',
  },
  inactive: {
    key: 'inactive',
    text: 'Inactive',
    value: 'inactive',
    color: '#A5A5A5FF',
  },
  preOpportunity: {
    key: 'preOpportunity',
    text: 'Pre Opportunity',
    value: 'preOpportunity',
    color: '#A5A5A5FF',
  },
  won: {
    key: 'won',
    text: 'Won',
    value: 'won',
    color: '#21BA45FF',
  },
  lost: {
    key: 'lost',
    text: 'Lost',
    value: 'lost',
    color: '#A5A5A5FF',
  },
};
