import { handleActions } from 'redux-actions';
import actions from 'actions';
import { Teams, TeamMember } from 'models/team';
import _cloneDeep from 'lodash/cloneDeep';
import { Reducer } from 'redux';
import { normalize } from './utils/normalized';

interface TeamState {
  isLoadingTeams: boolean;
  teams: Teams;
  teamMembers: TeamMember[];
  userTeams: string[]; // teamIds
  isLoadingTeamSettings: boolean;
  settings: any;
  errorMsg: string | undefined;
  isLoadingUserTeams: boolean;
  isAddingTeamMember: boolean;
  selectedTeamId: number | undefined;
  isEditingTeam: boolean;
  isDeletingTeam: boolean;
}

const storedTeamId = sessionStorage.getItem('selectedTeamId');

export const initialState: TeamState = {
  totals: {},
  isLoadingTeams: false,
  isLoadingUserTeams: false,
  isAddingTeamMember: false,
  teams: {
    byIds: {},
    allIds: [],
  },
  teamMembers: [],
  userTeams: [],
  isLoadingTeamSettings: false,
  settings: {},
  selectedTeamId: storedTeamId ? parseInt(storedTeamId, 10) : undefined,
  errorMsg: undefined,
  isEditingTeam: false,
  isDeletingTeam: false,
};

function setTeamMembers(teams: Array<any>): TeamMember[] {
  return teams.flatMap(t => t.members.map(m => ({ teamId: t.id, userId: m.id })));
}

const reducer: Reducer<any, any> = handleActions(
  {
    [actions.team.fetchTeamsRequest]: state => ({ ...state, isLoadingTeams: true }),
    [actions.team.fetchTeamsSuccess]: (state, { payload: { teams } }) => (
      {
        ...state,
        teams: normalize(teams),
        isLoadingTeams: false,
      }
    ),
    [actions.team.fetchTeamsFailure]: (state, { payload: { msg } }) => (
      { ...state, errorMsg: msg, isLoadingTeams: false }
    ),

    [actions.team.fetchTeamsWithMembersRequest]: state => ({ ...state, isLoadingTeams: true }),
    [actions.team.fetchTeamsWithMembersSuccess]: (state, { payload: { teams } }) => (
      { ...state, teamMembers: setTeamMembers(teams), isLoadingTeams: false }
    ),
    [actions.team.fetchTeamsWithMembersFailure]: (state, { payload: { msg } }) => (
      { ...state, errorMsg: msg, isLoadingTeams: false }
    ),

    [actions.team.fetchUserTeamsRequest]: state => ({ ...state, isLoadingUserTeams: true }),
    [actions.team.fetchUserTeamsSuccess]: (state, { payload: { teams } }) => (
      { ...state, userTeams: teams.map(t => t.id), isLoadingUserTeams: false }
    ),
    [actions.team.fetchUserTeamsFailure]: (state, { payload: { msg } }) => (
      { ...state, errorMsg: msg, isLoadingUserTeams: false }
    ),

    [actions.team.fetchTeamSettingsRequest]: state => ({ ...state, isLoadingTeamSettings: true }),
    [actions.team.fetchTeamSettingsSuccess]: (state, { payload: { settings } }) => (
      { ...state, settings, isLoadingTeamSettings: false }
    ),
    [actions.team.fetchTeamSettingsFailure]: state => ({ ...state, isLoadingTeamSettings: true }),

    [actions.team.addTeamMemberRequest]: state => ({ ...state, isAddingTeamMember: true }),
    [actions.team.addTeamMemberSuccess]: (state, { payload: { userIds, teamId } }) => ({
      ...state,
      teamMembers: [
        ...state.teamMembers,
        ...(userIds.map(userId => ({ userId: parseInt(userId, 10), teamId }))),
      ],
      isAddingTeamMember: false,
    }),
    [actions.team.addTeamMemberFailure]: (state, { payload: { msg } }) => (
      { ...state, errorMsg: msg, isAddingTeamMember: false }
    ),
    [actions.team.removeUserFromTeamRequest]: state => ({ ...state, isRemovingTeamMember: true }),
    [actions.team.removeUserFromTeamSuccess]: (state, { payload: { userId, teamId } }) => {
      const withRemovedTeamMember = state.teamMembers.filter(tm => !(tm.userId === userId && tm.teamId === teamId));
      return { ...state, teamMembers: withRemovedTeamMember, isRemovingTeamMember: false };
    },
    [actions.team.removeUserFromTeamFailure]: (state, { payload: { msg } }) => (
      { ...state, errorMsg: msg, isRemovingTeamMember: false }
    ),

    [actions.team.setSelectedTeamIdRequest]: (state, { payload: { selectedTeamId } }) => ({ ...state, selectedTeamId }),

    [actions.team.editTeamRequest]: state => ({ ...state, isEditingTeam: true }),
    [actions.team.editTeamSuccess]: (state, { payload: { team } }) => {
      const teams = _cloneDeep(state.teams);
      teams.byIds[team.id] = team;
      return ({ ...state, teams, isEditingTeam: false });
    },
    [actions.team.editTeamFailure]: state => ({ ...state, isEditingTeam: false }),

    [actions.team.deleteTeamRequest]: state => ({ ...state, isDeletingTeam: true }),
    [actions.team.deleteTeamSuccess]: (state, { payload: { teamId } }) => {
      const teams = _cloneDeep(state.teams);
      delete teams.byIds[teamId];
      return ({ ...state, teams, isDeletingTeam: false });
    },
    [actions.team.deleteTeamFailure]: state => ({ ...state, isDeletingTeam: false }),

  },
  initialState,
);

export default reducer;
