import React, { Component } from 'react';
import { motion } from 'framer-motion';
import { Segment } from 'semantic-ui-react';

import { ActivityIcons } from 'models/activity';
import { stripHTML } from 'lib/util';
import BSIcon from '../../../BSIcon';

import css from './Activity.module.css';

interface ActivityProps {
  id: number;
  date?: string;
  description: string | null;
  emailDetails?: {
    body?: string;
    subject?: string;
    to?: string;
  };
  groupCnt: number;
  icon: ActivityIcons;
  metaDetails: any;
  onClick?: Function;
  selected?: boolean;
  subject?: string;
  type?: string;
}

export default class Activity extends Component<ActivityProps> {
  handleClick = (e): void => {
    if (e.target.tagName !== 'A') {
      const { onClick } = this.props;
      if (onClick) onClick();
    }
  }

  render(): React.ReactNode {
    const {
      id,
      date,
      description,
      emailDetails,
      groupCnt,
      icon,
      metaDetails,
      selected,
      subject,
      type,
    } = this.props;

    const emailSubject = emailDetails && emailDetails.subject ? emailDetails.subject : subject;
    const emailTo = emailDetails && emailDetails.to ? `TO: ${emailDetails.to} ` : '';
    const emailBody = emailDetails && emailDetails.body ? `${emailTo}${emailDetails.body}` : description;
    const isGrouped = groupCnt > 1;

    const countDesc = isGrouped ? (
      <div className={css.groupCountDesc}>
        {`+${groupCnt - 1} similar`}
      </div>
    ) : null;
    let groupCSS = css.activityContainer;

    if (groupCnt === 2) {
      groupCSS = `${css.activityContainer} ${css.groupedActivityTwo}`;
    } else if (groupCnt > 2) {
      groupCSS = `${css.activityContainer} ${css.groupedActivityMore}`;
    }
    return (
      <motion.div
        initial={{ scaleY: 0, height: 0 }}
        animate={{ scaleY: 1, height: '100%', transition: { delay: 0.1 } }}
        exit={{ scaleY: 0, height: 0 }}
        transition={{ duration: 0.1 }}
        className={`${css.activity} ${css[icon]} ${selected ? css.selected : ''}`}
        id={`activity-${id}`}
      >
        <div className={css.activityDate}>{date}</div>
        <Segment className={groupCSS} onClick={this.handleClick}>
          <div className={css.activityIcon}>
            <BSIcon
              className={css.icon}
              size="large"
              activityType={type}
              activityIcon={icon}
              title={type}
              bordered
            />
          </div>
          <div className={css.activityContent}>
            <div className={css.subject}>
              {`${emailSubject} - `}
              <span className={css.meta}>{metaDetails}</span>
            </div>
            <div className={css.body}>
              {emailBody ? stripHTML(emailBody) : (<i>no description provided</i>)}
            </div>
            {countDesc}
          </div>
        </Segment>
      </motion.div>
    );
  }
}
