import { createSelector } from 'reselect';
import { RootState } from '../store';
import { OpportunityStage, StageToColorMap } from '../models/opportunity';

export const getPipelineSettings = (state): any => state.organization.dataSettings.pipelinePage;

// eslint-disable-next-line max-len
export const selectOrganizationOpportunityStages = (state: RootState): OpportunityStage[] | undefined => state.organization.opportunityStages;

export const selectOpportunityStageColorsMap = createSelector(selectOrganizationOpportunityStages, stages => {
  const stageColorsMap: StageToColorMap = {};
  if (!stages) return stageColorsMap;
  stages.forEach(stage => {
    stageColorsMap[stage.masterLabel] = stage.color;
  });
  return stageColorsMap;
});
