import React, { Component } from 'react';
import { Icon, List, Popup } from 'semantic-ui-react';
import { hasPermission, Permission } from 'components/AccessControl';

interface Props {
  permission: Permission;
}
interface State {}

export default class PermissionsInfo extends Component<Props, State> {
  renderPermissionsInfo = (permission: Permission): React.ReactNode => {
    const descriptions: Array<string> = [];
    if (hasPermission(permission, 'account_owner')) {
      descriptions.push('Manage admins');
    }
    if (hasPermission(permission, 'admin')) {
      descriptions.push('Manage users and teams');
      descriptions.push('Manage organization settings');
    }
    if (hasPermission(permission, 'manager')) {
      descriptions.push('Manage and view reps in teams');
    }
    if (permission === 'rep') {
      descriptions.push('Manage and view self (if tracked)');
    }
    descriptions.push('View account pages');
    return (<List bulleted items={descriptions} />);
  };

  render(): React.ReactNode {
    const { permission } = this.props;
    const popupContent = this.renderPermissionsInfo(permission);
    return (
      <Popup
        hoverable
        mouseLeaveDelay={500}
        content={popupContent}
        trigger={(
          <Icon name="question circle" />
        )}
      />
    );
  }
}
