import { createSlice } from '@reduxjs/toolkit';
import { CustomFilter } from 'models/picklist';

export type FunnelIsNewTypes = 'new' | 'existing' | 'all';

export interface FunnelFilter {
  type: string[];
  industry: string[];
  country: string[];
  state: string[];
  leadSource: string[];
  isNew?: FunnelIsNewTypes;
  custom?: Array<CustomFilter>;
}

interface FunnelFilterState {
  ownerId: number[];
  filter: FunnelFilter;
}

const initialState: FunnelFilterState = {
  ownerId: [],
  filter: {
    type: [],
    industry: [],
    country: [],
    state: [],
    leadSource: [],
    custom: [],
  },
};

/* eslint-disable no-param-reassign */
export const funnelSlice = createSlice({
  name: 'funnel',
  initialState,
  reducers: {
    setFunnelOwnerIds: (state, action: { payload: number[] }): void => {
      state.ownerId = action.payload;
    },
    setFunnelFilter: (state, action: { payload: FunnelFilter }): void => {
      state.filter = action.payload;
    },
  },
});
/* eslint-enable no-param-reassign */


export const { setFunnelOwnerIds, setFunnelFilter } = funnelSlice.actions;

export default funnelSlice.reducer;
