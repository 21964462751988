import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { CallHistoryMethodAction, push } from 'connected-react-router';
import { skipToken } from '@reduxjs/toolkit/query';
import qs from 'query-string';
import { DropdownItemProps, Header, Icon, Loader, Placeholder, Segment } from 'semantic-ui-react';

import ProspectingContactListFilterModal from 'App/Main/ProspectingHealthRoute/ProspectingContactList/FilterModal';
import ProspectingContactTable from 'App/Main/ProspectingHealthRoute/ProspectingContactList/Table';
import { AccountApi, prospectingApi } from 'features/Api';
import {
  ContactsResponse,
  ProspectingContact,
  ProspectingPhase,
  ProspectingRecommendation,
} from 'features/Api/Prospecting';

import css from './styles.module.css';

export interface ProspectingContactFilters {
  ownerIds: number[];
  hasActivityWithOwnerIds: number[];
  phases: ProspectingPhase[];
  recommendations: ProspectingRecommendation[];
  hasRecommendation?: boolean;
  isActive?: boolean;
  contactNameSearch?: string;
}

const PROSPECTING_FILTER_KEY = 'prospectingFilters';

export const DEFAULT_CONTACT_FILTER_SETTINGS = {
  ownerIds: [],
  hasActivityWithOwnerIds: [],
  phases: [],
  recommendations: [],
  hasRecommendation: undefined,
  isActive: undefined,
  contactNameSearch: undefined,
};

export const getDefaultProspectingContactFilterSettings = (): ProspectingContactFilters => {
  const savedFiltersString = sessionStorage.getItem(PROSPECTING_FILTER_KEY);
  if (savedFiltersString) {
    const savedFilters = JSON.parse(savedFiltersString);
    return savedFilters;
  }
  return DEFAULT_CONTACT_FILTER_SETTINGS;
};

const filterContacts = (contacts: ProspectingContact[], filters: ProspectingContactFilters): ProspectingContact[] => {
  let filteredContacts = contacts;
  const { ownerIds, hasActivityWithOwnerIds, phases, recommendations, hasRecommendation, isActive } = filters;
  if (ownerIds?.length) {
    filteredContacts = filteredContacts.filter(a => ownerIds.indexOf(a.ownerId) >= 0);
  }
  if (hasActivityWithOwnerIds?.length) {
    filteredContacts = filteredContacts.filter(
      a => a.activityOwnerIds?.some(r => hasActivityWithOwnerIds.indexOf(r) >= 0),
    );
  }
  if (phases?.length) {
    filteredContacts = filteredContacts.filter(a => phases.indexOf(a.prospectingPhase) >= 0);
  }
  if (recommendations?.length) {
    filteredContacts = filteredContacts.filter(a => recommendations?.some(r => a.recommendations?.indexOf(r) >= 0));
  }
  if (hasRecommendation !== undefined) {
    if (hasRecommendation) filteredContacts = filteredContacts.filter(a => a.numRecommendations > 0);
    else filteredContacts = filteredContacts.filter(a => a.numRecommendations === 0);
  }
  if (isActive !== undefined) {
    if (isActive) filteredContacts = filteredContacts.filter(a => a.isActive);
    else filteredContacts = filteredContacts.filter(a => !a.isActive);
  }
  return filteredContacts;
};

const ContactTable = (props: {
  teamId: number;
  status: 'active' | 'inactive';
  accountId?: number;
}): ReactElement => {
  const { teamId, accountId, status } = props;
  const [filters, setFilters] = useState<ProspectingContactFilters>(getDefaultProspectingContactFilterSettings());
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const byAccountQueryParams = accountId !== undefined ? { teamId, accountId } : skipToken;
  const noAccountQueryParams = accountId === undefined ? { teamId } : skipToken;
  const inactiveQueryParams = accountId === undefined ? { teamId } : skipToken;

  const handleFilterChange = (f: React.SetStateAction<ProspectingContactFilters>): void => {
    setFilters(f);
    const serializedFilters = JSON.stringify(f);
    sessionStorage.setItem(PROSPECTING_FILTER_KEY, serializedFilters);
  };

  const {
    data: withAccountData,
    isLoading: withAccountIsLoading,
  } = prospectingApi.endpoints.getProspectingContactsByAccount.useQuery(byAccountQueryParams);

  const {
    data: noAccountData,
    isLoading: noAccountIsLoading,
  } = prospectingApi.endpoints.getProspectingContactsNoAccount.useQuery(noAccountQueryParams);

  const {
    data: inactiveData,
    isLoading: inactiveIsLoading,
  } = prospectingApi.endpoints.getProspectingInactiveContacts.useQuery(inactiveQueryParams);

  let isLoading: boolean;
  let data: ContactsResponse | undefined;
  if (accountId) {
    isLoading = withAccountIsLoading;
    data = withAccountData;
  } else if (status === 'active') {
    isLoading = noAccountIsLoading;
    data = noAccountData;
  } else {
    isLoading = inactiveIsLoading;
    data = inactiveData;
  }

  if (isLoading) {
    return <Loader active />;
  }
  if (!data?.status) {
    const msg = data?.msg || 'Error retrieving insights';
    return <>{msg}</>;
  }

  const filteredContacts = filterContacts(data.contacts, filters);
  const ownerOptions: DropdownItemProps[] = data.owners.filter(o => o.id).map(o => ({
    text: o.name,
    value: (o.id as number),
  }));

  return (
    <>
      <ProspectingContactListFilterModal
        filters={filters}
        setFilters={handleFilterChange}
        open={filterModalOpen}
        setOpen={setFilterModalOpen}
        ownerOptions={ownerOptions}
        activityOwnerOptions={ownerOptions}
      />
      <ProspectingContactTable contacts={filteredContacts} owners={data.owners} />
    </>
  );
};

const ContactListHeader = (props: { teamId?: number; accountId?: number }): ReactElement => {
  const dispatch = useDispatch();
  const { teamId, accountId } = props;
  const queryParams = accountId ? { teamId, accountId } : skipToken;
  const { data, isFetching } = AccountApi.getAccount.useGetAccountQuery(queryParams);
  const navigate = bindActionCreators((path: string) => push(path), dispatch);

  const goBack = (): CallHistoryMethodAction => navigate('/app/prospecting');
  const backButton = <Icon className={css.link} name="chevron left" onClick={goBack} />;

  let inner: string | ReactElement = '';
  if (accountId === undefined) {
    inner = 'Contacts without Account';
  }
  if (isFetching) {
    inner = (
      <Placeholder>
        <Placeholder.Header>
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    );
  } else if (data !== undefined) {
    const { account } = data;
    inner = (
      <Link to={`/app/account/${account.id}/activity`} className="brand">
        {account.name}
        {' '}
        <Icon name="chart line" />
      </Link>
    );
  }
  return (
    <Header className={css.listHeader} style={{ marginTop: 10 }}>
      {backButton}
      {inner}
    </Header>
  );
};

interface UrlParams {
  accountId?: string;
}

interface QueryParams {
  status?: 'active' | 'inactive';
}

const ProspectingContactList = (props: {
  teamId: number;
}): ReactElement => {
  const { teamId } = props;

  const urlParams = useParams<UrlParams>();
  const {
    accountId: accountIdString,
  } = urlParams;
  let accountId: number | undefined;
  if (accountIdString) {
    accountId = parseInt(accountIdString, 10);
  }

  const location = useLocation();
  const searchParams: QueryParams = qs.parse(location.search);

  const { status: statusParam } = searchParams;
  let status: 'active' | 'inactive' = 'active';
  if (statusParam === 'inactive') status = 'inactive';

  return (
    <Segment className={css.contactList}>
      <ContactListHeader teamId={teamId} accountId={accountId} />
      <ContactTable teamId={teamId} accountId={accountId} status={status} />
    </Segment>
  );
};

export default ProspectingContactList;
