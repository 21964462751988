import BSBadgedIcon from 'components/BSBadgedIcon/BSBadgedIcon';
import { Activity, ActivityAccount, ActivityContact, ActivityOwner } from 'models/activity';
import React from 'react';
import { Dimmer, Grid, Segment } from 'semantic-ui-react';

import css from './ActivityInfoBar.module.css';

interface ActivityInfoBarProps {
  isLoading?: boolean;
  accounts?: Array<ActivityAccount>;
  contacts?: Array<ActivityContact>;
  owners?: Array<ActivityOwner>;
  activities: Array<Activity>;
}

const ActivityInfoBar: React.FC<ActivityInfoBarProps> = ({
  isLoading,
  accounts,
  contacts,
  owners,
  activities,
}) => {
  const iconCount: { [key: string]: number } = {};
  let inboundCount = 0;
  let outboundCount = 0;

  activities.forEach(activity => {
    const { icon, direction, groupCnt } = activity;
    if (!iconCount[icon]) iconCount[icon] = 0;
    iconCount[icon] += groupCnt;
    if (icon === 'mail') {
      if (direction === 'inbound') {
        inboundCount += groupCnt;
      } else if (direction === 'outbound') {
        outboundCount += groupCnt;
      }
    }
  });

  const totalCount = Object.values(iconCount).reduce((prev, curr) => (prev + curr), 0);

  const mailIcon = iconCount.mail !== undefined && (
    <BSBadgedIcon
      badge={iconCount.mail}
      icon="mail"
      color="rgb(62,133,202)"
      popup={`${inboundCount} Inbound, ${outboundCount} outbound`}
    />
  );

  const accountsContacted = accounts?.length;
  const uniqueContacts = contacts?.length;
  const activityOwners = owners?.length;

  return (
    <Dimmer.Dimmable as={Segment} blurring dimmed={isLoading}>
      <Grid>
        <Grid.Row>
          <Grid.Column width="8">
            <div className={css.activityTotals}>
              <div>
                {`${totalCount} Total Activities`}
              </div>
              {mailIcon}
              {iconCount.call !== undefined && <BSBadgedIcon badge={iconCount.call} icon="call" color="rgb(79,178,172)" />}
              {iconCount.calendar !== undefined
                && <BSBadgedIcon badge={iconCount.calendar} icon="calendar outline" color="rgb(211,91,143)" />}
              {iconCount.sms !== undefined
                && <BSBadgedIcon badge={iconCount.sms} icon="comment alternate" color="rgb(147, 51, 195)" />}
              {iconCount.tasks !== undefined && <BSBadgedIcon badge={iconCount.tasks} icon="tasks" color="rgb(164,83,72)" />}
              {iconCount.linkedin !== undefined
                && <BSBadgedIcon icon="linkedin" badge={iconCount.linkedin} color="rgb(10 102 194)" />}
            </div>
          </Grid.Column>
          <Grid.Column width="8">
            <div className={css.activityStats}>
              {accountsContacted !== undefined
                && (
                  <BSBadgedIcon
                    badge={accountsContacted}
                    text="Accounts"
                    icon="building"
                    color="rgb(195,100,51)"
                    popup="Number of accounts contacted"
                  />
                )}
              {activityOwners !== undefined
                && (
                  <BSBadgedIcon
                    badge={activityOwners}
                    text="Reps"
                    icon="user"
                    color="rgb(195, 51, 147)"
                    popup="Number of reps who have had activity on this account"
                  />
                )}
              {uniqueContacts !== undefined
                && (
                  <BSBadgedIcon
                    badge={uniqueContacts}
                    text="Contacts"
                    icon="users"
                    color="rgb(51, 147, 195)"
                    popup="Number of unique contacts"
                  />
                )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Dimmer.Dimmable>
  );
};

export default ActivityInfoBar;
