import { IndicatorStatus } from 'features/Account/accountTypes';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonProps, Icon, Popup, Table } from 'semantic-ui-react';

import { formatDate } from 'lib/util';
import withSortableTable, { WithSortableTable } from 'components/withSortableTable';
import { healthStatusMap } from 'models/account';
import { langMetric, MetricCodename } from 'models/account/metric';
import { OpportunityStage } from 'models/opportunity';
import { User } from 'models/user';

import css from './ActiveAccountList.module.css';

export interface ActiveAccountListFilters {
  statuses?: IndicatorStatus[];
  ownerIds?: Array<number>;
}

export interface AccountTableView {
  status: IndicatorStatus;
  ownerId: number;
  accountId: number;
  accountName: string;
  stageName: string;
  owner: string;
  lastActivityDate?: string;
  opportunityCount: number;
  criticalMetrics?: MetricCodename[];
  warningMetrics?: MetricCodename[];
}

interface Props extends WithSortableTable<any> {
  accounts: Array<AccountTableView>;
  filters?: ActiveAccountListFilters;
  stages: OpportunityStage[];
  onLoadMore?: (e: React.MouseEvent, data: ButtonProps) => void;
  isLoadingMore?: boolean;
  teamMembers: User[];
}

interface State {}

class ActiveAccountList extends Component<Props, State> {
  render(): React.ReactNode {
    const { filters, sortable, onLoadMore, isLoadingMore, teamMembers } = this.props;

    const accountNodes = sortable.data.filter((account: AccountTableView) => {
      let showRow = true;
      if (!filters) return showRow;
      if (filters.statuses?.length && account.status) {
        showRow = showRow && filters.statuses.includes(account.status);
      }
      if (filters.ownerIds?.length && account.ownerId) {
        showRow = showRow && filters.ownerIds.includes(account.ownerId);
      }
      return showRow;
    }).map((account: AccountTableView) => {
      const { opportunityCount } = account;

      const status = account.status || 'inactive';
      const statusObj = healthStatusMap[status];

      let critical: React.ReactNode = null;
      let warning: React.ReactNode = null;
      let reason: React.ReactNode = 'Inactive';
      if (account.criticalMetrics?.length) {
        critical = account.criticalMetrics.map(metricName => {
          const metricTextPack = langMetric[metricName];
          const metric = metricTextPack.name;
          return (<li key={metric}>{metric}</li>);
        });
        critical = (
          <div className={css.criticalMetrics}>
            <h4>Critical Metrics</h4>
            <ul>{critical}</ul>
          </div>
        );
      }
      if (account.warningMetrics?.length) {
        warning = account.warningMetrics.map(metricName => {
          const metricTextPack = langMetric[metricName];
          const metric = metricTextPack.name;
          return (<li key={metric}>{metric}</li>);
        });
        warning = (
          <div className={css.warningMetrics}>
            <h4>Warning Metrics</h4>
            <ul>{warning}</ul>
          </div>
        );
      }
      if (critical || warning) {
        reason = (
          <div className={css.popupReasons}>
            {critical}
            {warning}
          </div>
        );
      } else {
        reason = statusObj.text;
      }

      const trackNotification = teamMembers.find(u => u.id === account.ownerId)
        ? null
        : (
          <Popup
            basic
            content="This user is not tracked in BuyerSight"
            trigger={(<Icon name="exclamation triangle" />)}
            size="large"
          />
        );

      return (
        <Table.Row key={account.accountId}>
          <Table.Cell className={css.accountName}>
            <Link to={`account/${account.accountId}/health`}>{account.accountName}</Link>
          </Table.Cell>
          <Popup
            basic
            position="left center"
            trigger={(
              <Table.Cell
                textAlign="center"
                className={`${css.status} ${css[status]}`}
              >
                {statusObj?.text}
              </Table.Cell>
            )}
            content={reason}
          />
          <Table.Cell>
            {account.stageName}
          </Table.Cell>
          <Table.Cell>
            {account.owner}
            {' '}
            {trackNotification}
          </Table.Cell>
          <Table.Cell textAlign="center">
            {opportunityCount}
          </Table.Cell>
          <Table.Cell>
            {account.lastActivityDate ? formatDate(account.lastActivityDate, 'MMM DD, YYYY') : 'Never'}
          </Table.Cell>
        </Table.Row>
      );
    });

    let footer;
    if (onLoadMore) {
      footer = (
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="6" textAlign="center" className={css.listFooter}>
              <Button disabled={isLoadingMore} onClick={onLoadMore}>{isLoadingMore ? 'Loading...' : 'Load More'}</Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      );
    }

    return (
      <Table sortable selectable compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortable.column === 'accountName' ? sortable.direction : undefined}
              onClick={(): void => { sortable.handleSort('accountName', 'string'); }}
              width={6}
            >
              Account Name
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              sorted={sortable.column === 'points' ? sortable.direction : undefined}
              onClick={(): void => { sortable.handleSort('points', 'number'); }}
              width={2}
            >
              Health Status
            </Table.HeaderCell>
            <Table.HeaderCell
              textAlign="center"
              style={{ cursor: 'default' }}
              width={2}
            >
              Opportunity Stage
            </Table.HeaderCell>
            <Popup
              basic
              content="Owner of the open opportunity, or if no opportunity, the account owner."
              trigger={(
                <Table.HeaderCell
                  textAlign="center"
                  sorted={sortable.column === 'owner' ? sortable.direction : undefined}
                  onClick={(): void => { sortable.handleSort('owner', 'string'); }}
                  width={3}
                >
                  Owner
                </Table.HeaderCell>
              )}
            />
            <Table.HeaderCell textAlign="center" style={{ cursor: 'default' }} width={1}>
              Open Opps
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortable.column === 'lastActivityDate' ? sortable.direction : undefined}
              onClick={(): void => { sortable.handleSort('lastActivityDate', 'date'); }}
              width={2}
            >
              Last Active
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {accountNodes}
        </Table.Body>
        {footer}
      </Table>
    );
  }
}

export default withSortableTable<any, Props>(ActiveAccountList, 'accounts');
