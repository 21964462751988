/* eslint  max-classes-per-file: 0 */
import { DateTime } from 'luxon';
import moment, { Moment } from 'moment';
import { ChartDataset, ScriptableContext } from 'chart.js';
import tinycolor from 'tinycolor2';
import { IdealDataPoint } from 'App/Main/AccountHealthRoute/components/Timeline';
import {
  ActualDataPoint,
  NotableActivityDataPoint, StageChangeDataPoint,
} from 'App/Main/AccountHealthRoute/components/AccountTimeline/AccountTimeline';
import { AccountApi } from '../../../features/Api';
import { TimelineStage } from './timelineStage';
import { OpportunityStage } from '../../opportunity';

export abstract class Timeline {
  protected stages: TimelineStage[];

  protected lineWidth = 3;

  constructor(timelineDataPoints: AccountApi.getAccountTimeline.TimelineDataPoint[]) {
    const stages: TimelineStage[] = [];
    timelineDataPoints.forEach(dataPoint => {
      stages.push(new TimelineStage(dataPoint));
    });
    stages.sort((a, b) => a.daysSinceTimelineStart - b.daysSinceTimelineStart);
    this.stages = stages;
  }

  findStageFromDate(dateToFind: string | Moment): TimelineStage | undefined {
    let date: Moment;
    if (typeof dateToFind === 'string') date = moment(dateToFind);
    else date = dateToFind;

    const { stages } = this;

    return stages.find(stage => {
      const { dateStart: dateStartString, daysInStage } = stage;
      if (dateStartString) {
        const dateStart = moment(dateStartString);
        const dateEnd = dateStart.clone().add(daysInStage, 'days');
        if (date >= dateStart && date < dateEnd) {
          return true;
        }
      }
      return false;
    });
  }

  getEarliestStage(): TimelineStage | undefined {
    if (!this.isEmpty()) {
      const { stages } = this;
      // We sort stages on init, so this should give the earliest
      return stages[0];
    }
    return undefined;
  }

  getMostRecentStage(): TimelineStage | undefined {
    if (!this.isEmpty()) {
      const { stages } = this;
      return stages[stages.length - 1];
    }
    return undefined;
  }

  /**
   * Gets start and end dates.
   *
   * If opportunity exists, returns date that opportunity was opened, and either the date it was closed, or the current
   * date (now).
   *
   * If no opportunity exists, returns undefined.
   */
  getStageDateBoundaries(): { start: DateTime; end: DateTime } | undefined {
    const firstStage = this.getEarliestStage();
    if (!firstStage) return undefined;
    const start = DateTime.fromJSDate(firstStage.dateStart?.toDate() || DateTime.now().toJSDate());

    const lastStage = this.getMostRecentStage();
    let end = DateTime.now();
    if (lastStage && lastStage.isClosed() && lastStage.dateStart) {
      end = DateTime.fromJSDate(lastStage.dateStart.toDate()).plus({ days: lastStage.daysInStage });
    }

    return { start, end };
  }


  isEmpty(): boolean {
    return this.stages.length === 0;
  }

  abstract getDatasets(opportunityStages: OpportunityStage[]): ChartDataset<'line' | 'scatter'>[];
}


type BorderGeneratorFn = (opportunityStages: OpportunityStage[]) => (context: ScriptableContext<'line'>) => string | CanvasGradient;

export class ActualTimeline extends Timeline {
  private activitiesByWeek: AccountApi.getAccountTimeline.ActivitiesByWeekDataPoint[];

  constructor(
    timelineDataPoints: AccountApi.getAccountTimeline.TimelineDataPoint[],
    activitiesByWeek: AccountApi.getAccountTimeline.ActivitiesByWeekDataPoint[],
  ) {
    super(timelineDataPoints);
    this.activitiesByWeek = activitiesByWeek.slice().sort((a, b) => a.x - b.x);
  }

  getDatasets(opportunityStages: OpportunityStage[]): ChartDataset<'line' | 'scatter'>[] {
    return [this.getTimelineDataset(opportunityStages)];
  }

  getNumberOfActivitiesByWeekDataPoints(): number {
    const { activitiesByWeek } = this;
    return activitiesByWeek.length;
  }

  getFirstActivitiesByWeekDataPoint(): AccountApi.getAccountTimeline.ActivitiesByWeekDataPoint | undefined {
    const { activitiesByWeek } = this;
    if (activitiesByWeek.length > 0) {
      // We sort activitiesByWeek on init, so this should give the earliest
      return activitiesByWeek[0];
    }
    return undefined;
  }

  getLastActivitiesByWeekDataPoint(): AccountApi.getAccountTimeline.ActivitiesByWeekDataPoint | undefined {
    const { activitiesByWeek } = this;
    if (activitiesByWeek.length > 0) {
      // We sort activitiesByWeek on init, so this should give the earliest
      return activitiesByWeek[activitiesByWeek.length - 1];
    }
    return undefined;
  }

  getTimeInPreOpportunityStage(): number {
    const firstDataPoint = this.getFirstActivitiesByWeekDataPoint();
    if (!firstDataPoint) return 0;
    const firstDataPointMoment = moment(firstDataPoint.date);

    const firstStage = this.getEarliestStage();
    if (!firstStage) {
      const now = moment();
      return now.diff(firstDataPointMoment, 'days');
    }

    const firstStageMoment = moment(firstStage.dateStart);
    return firstStageMoment.diff(firstDataPointMoment, 'days');
  }

  getStageChangeDataPoints(): NotableActivityDataPoint[] {
    const { stages } = this;
    const dataPoints: NotableActivityDataPoint[] = [];
    let previousStageName = 'No Opportunity';
    stages.forEach(stage => {
      const { dateStart, name } = stage;
      if (dateStart) {
        const dataPoint = new StageChangeDataPoint(previousStageName, name, dateStart);
        dataPoints.push(dataPoint);
        previousStageName = name;
      }
    });
    return dataPoints;
  }

  getTimelineDataset(opportunityStages: OpportunityStage[]): ChartDataset<'line'> {
    const { activitiesByWeek } = this;
    const actualData: ActualDataPoint[] = [];

    let activityStageName = 'No Opportunity';
    let activityDaysInStage = this.getTimeInPreOpportunityStage();
    activitiesByWeek.forEach(point => {
      let color = '#888';
      const {
        x,
        y,
        weekStart: weekStartString,
        weekEnd,
        activityBreakdown,
      } = point;
      const weekStart = moment(weekStartString);
      const activityStage = this.findStageFromDate(weekStart);

      if (activityStage) {
        const { name, masterLabel, daysInStage } = activityStage;
        color = opportunityStages.find(s => s.apiName === name)?.color || '#2185D0';
        color = tinycolor(color).setAlpha(1).toString();
        activityStageName = masterLabel;
        activityDaysInStage = daysInStage;
      }
      actualData.push(
        new ActualDataPoint(
          weekStartString,
          y,
          x,
          activityDaysInStage,
          y,
          activityBreakdown,
          activityStageName,
          color,
          weekStartString,
          weekEnd,
        ),
      );
    });

    return {
      label: 'Account Activities',
      tension: 0.3,
      data: actualData,
      borderColor: this.getTimelineLineGradientBorderGenerator(opportunityStages),
      borderWidth: this.lineWidth,
      yAxisID: 'yMain',
      type: 'line',
      pointBackgroundColor: 'rgba(0,0,0,0)',
      pointBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 10,
      order: 0,
    };
  }

  getTimelineLineGradientBorderGenerator: BorderGeneratorFn = opportunityStages => {
    const fn = (context): CanvasGradient | string => {
      const { chart } = context;
      const { chartArea } = chart;
      const { stages } = this;

      if (!chartArea) {
        return '#2185D0';
      }
      if (stages.length === 0) {
        return '#888';
      }

      const xAxis = chart.scales.x;

      const { min } = xAxis;
      const minPixel = xAxis.getPixelForValue(min);

      const { max } = xAxis;
      const maxPixel = xAxis.getPixelForValue(max);

      const { ctx } = chart;
      const gradient = ctx.createLinearGradient(minPixel, 0, maxPixel, 0);
      gradient.addColorStop(0, '#888');

      let lastColor = '#888';
      stages.forEach(stage => {
        const unixTimeMs = moment(stage.dateStart).unix() * 1000;
        const x = xAxis.getPixelForValue(unixTimeMs);
        let percentAlongGradient = (x - minPixel) / (maxPixel - minPixel);
        if (percentAlongGradient > 1) {
          percentAlongGradient = 1;
        }
        if (percentAlongGradient < 0) {
          percentAlongGradient = 0;
        }

        // Adding last color to current point makes the gradient hard edged
        gradient.addColorStop(percentAlongGradient, lastColor);
        const nextColorTC = tinycolor(opportunityStages.find(s => s.apiName === stage.name)?.color || '#2185D0');
        const nextColor = nextColorTC.saturate(20).setAlpha(1).toString();
        gradient.addColorStop(percentAlongGradient, nextColor);
        lastColor = nextColor;
      });

      return gradient;
    };
    return fn;
  };
}

abstract class IdealTimeline extends Timeline {
  abstract getXValuesFromStage(stage: TimelineStage): { x0: number; x1: number };

  getDatasets(opportunityStages: OpportunityStage[]): ChartDataset<'line'>[] {
    const idealDatasets: ChartDataset<'line'>[] = [];

    const { stages } = this;

    let order = 1000;

    let previousActivitiesPerWeek: number | undefined;
    stages.forEach(stage => {
      const {
        name,
        masterLabel,
        daysSinceTimelineStart,
        activitiesPerWeek,
        daysInStage,
      } = stage;

      if (!previousActivitiesPerWeek) previousActivitiesPerWeek = activitiesPerWeek;

      let color = opportunityStages.find(s => s.apiName === name)?.color || 'rgba(33, 133, 208, .7)';
      color = tinycolor(color).saturate(20).setAlpha(1).toString();

      const { x0, x1 } = this.getXValuesFromStage(stage);

      const idealData: IdealDataPoint[] = [];

      if (daysSinceTimelineStart > 0) {
        // We only want to add the previous activity line if this is not the first stage.
        // Otherwise, the tooltip has duplicate "Stage [firstStageName] begins".
        idealData.push(
          new IdealDataPoint(
            x0,
            previousActivitiesPerWeek,
            daysSinceTimelineStart,
            activitiesPerWeek,
            daysInStage,
            name,
            masterLabel,
            color,
            'start',
          ),
        );
      }
      idealData.push(
        new IdealDataPoint(
          x0,
          activitiesPerWeek,
          daysSinceTimelineStart,
          activitiesPerWeek,
          daysInStage,
          name,
          masterLabel,
          color,
          'start',
        ),
      );
      idealData.push(
        new IdealDataPoint(
          x1,
          activitiesPerWeek,
          daysSinceTimelineStart,
          activitiesPerWeek,
          daysInStage,
          name,
          masterLabel,
          color,
          'end',
        ),
      );

      idealDatasets.push({
        label: name,
        data: idealData,
        fill: false,
        type: 'line',
        borderColor: color,
        borderWidth: this.lineWidth,
        borderDash: [10, 4],
        pointBorderWidth: 0,
        pointHoverBorderWidth: 0,
        pointRadius: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: color,
        pointBackgroundColor: color,
        order,
        stack: name,
      });

      previousActivitiesPerWeek = activitiesPerWeek;
      order += 1;
    });

    return idealDatasets;
  }
}

export class IdealTimelineWithStartDate extends IdealTimeline {
  private startDate: Moment;

  constructor(idealTimelineDataPoints: AccountApi.getAccountTimeline.TimelineDataPoint[],
    actualTimelineDataPoints: AccountApi.getAccountTimeline.TimelineDataPoint[]) {
    super(idealTimelineDataPoints);
    this.startDate = this.calcStartDate(actualTimelineDataPoints);
  }

  private calcStartDate(timelineDataPoints: AccountApi.getAccountTimeline.TimelineDataPoint[]): Moment {
    let relativeDate = moment().add(1, 'second');
    timelineDataPoints.forEach(point => {
      const stageStart = moment(point.dateStart);
      if (relativeDate > stageStart) {
        relativeDate = stageStart;
      }
    });
    return relativeDate;
  }

  getXValuesFromStage(stage: TimelineStage): {x0: number; x1: number} {
    const { startDate } = this;
    const { daysSinceTimelineStart, daysInStage } = stage;

    const x0days = daysSinceTimelineStart;
    const x1days = daysSinceTimelineStart + daysInStage;

    const x0 = startDate.clone().add(x0days, 'days').unix() * 1000;
    const x1 = startDate.clone().add(x1days, 'days').unix() * 1000;


    return { x0, x1 };
  }

  getStartDate(): Moment {
    return this.startDate.clone();
  }

  getEndDate(): Moment {
    const { startDate, stages } = this;
    const endDate = startDate.clone();
    stages.forEach(stage => {
      endDate.add(stage.daysInStage, 'days');
    });
    return endDate;
  }
}

export class IdealTimelineWithoutStartDate extends IdealTimeline {
  getXValuesFromStage(stage: TimelineStage): {x0: number; x1: number} {
    const { daysSinceTimelineStart, daysInStage } = stage;

    const x0 = daysSinceTimelineStart;
    const x1 = daysSinceTimelineStart + daysInStage;

    return { x0, x1 };
  }

  findStageFromDate(dateToFind: string | moment.Moment): TimelineStage | undefined {
    throw Error(`This class can't do that ${dateToFind}`);
  }
}
