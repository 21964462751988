import React, { ReactElement } from 'react';
import { Statistic } from 'semantic-ui-react';
import css from './styles.module.css';

export default (props: { target: number; value: number }): ReactElement => {
  const { target, value } = props;
  return (
    <div className={css.Label}>
      <Statistic>
        <Statistic.Label>Attainment</Statistic.Label>
        <Statistic.Value>{value}</Statistic.Value>
      </Statistic>
      <Statistic>
        <Statistic.Label>Target</Statistic.Label>
        <Statistic.Value>{target}</Statistic.Value>
      </Statistic>
    </div>
  );
};
