import { handleActions } from 'redux-actions';
import actions from '../actions';
import { ActivityIcons } from '../models/activity';

/**
 * This maps a Salesforce activity 'type' to a ActivityIcons.
 * This is necessary because each SF configuration may have completely custom types, but we want to map those to a
 * standard icon.
 *
 * @example for our client, Alice, all of the following Salesforce types map to a phone call:
 * ```ts
 * {
 *   'Call': 'call',
 *   'Call-ATT': 'call',
 *   'NVML (Ghost Dial)': 'call',
 *   'VML': 'call',
 *   'Call-LVM': 'call',
 * }
 * ```
 */
export interface TaskTypeIconMapping {
  [type: string]: ActivityIcons;
}

export interface SettingsState {
  isLoadingTaskmap: boolean;
  taskmap: TaskTypeIconMapping;
}

export const initialState: SettingsState = {
  isLoadingTaskmap: false,
  taskmap: {},
};

const reducer = handleActions(
  {
    [actions.settings.getSettingsTaskmapRequest]: state => ({ ...state, isLoadingTaskmap: true }),
    [actions.settings.getSettingsTaskmapSuccess]: (state, { payload: { taskmap } }) => ({
      ...state,
      isLoadingTaskmap: false,
      taskmap,
    }),
    [actions.settings.getSettingsTaskmapFailure]: state => ({ ...state, isLoadingTaskmap: false }),
  },
  initialState,
);

export default reducer;
