import React, { ReactNode, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Form,
  Icon,
  Label,
  Modal,
  Popup,
} from 'semantic-ui-react';

type SortTypes = 'metricAsc' | 'metricDesc' | 'rep';

export interface ComparisonSettings {
  reps?: number[];
  sort?: SortTypes;
}

interface Props {
  settings?: ComparisonSettings;
  teamOptions?: DropdownItemProps[];
  onChange?: (event: React.MouseEvent<HTMLButtonElement>, data: ComparisonSettings) => void;
}

const SettingsModal: React.FC<Props> = ({
  settings,
  teamOptions,
  onChange,
}) => {
  const [open, setOpen] = useState(false);
  const [reps, setReps] = useState(settings?.reps || []);
  const [sort, setSort] = useState('metricDesc');

  React.useEffect(() => {
    if (settings) {
      setReps(settings.reps || []);
      setSort(settings.sort || 'metricDesc');
    }
  }, [settings]);

  const handleChange = stateFn => (_, data): void => {
    stateFn(data.value);
  };
  const handleSave = (event): void => {
    setOpen(false);
    const data: ComparisonSettings = { reps, sort: sort as SortTypes };
    if (onChange) onChange(event, data);
  };
  const handleClear = (event): void => {
    setReps([]);
    setSort('metricDesc');
    setOpen(false);
    if (onChange) onChange(event, { reps: undefined, sort: 'metricDesc' });
  };


  let activeFilters = 0;
  if (reps.length) activeFilters += 1;

  const indicator = activeFilters ? (
    <Label
      floating
      circular
      color="pink"
      style={{ fontSize: '.3rem' }}
    />
  ) : null;

  const radioOptions: SortTypes[] = ['metricDesc', 'metricAsc', 'rep'];
  const optToLabel = {
    metricDesc: 'Metric: best to worst',
    metricAsc: 'Metric: worst to best',
    rep: 'Rep name',
  };

  const radios = radioOptions.map((optName: string): ReactNode => (
    <Form.Radio
      key={optName}
      checked={sort === optName}
      label={optToLabel[optName]}
      value={optName}
      onClick={handleChange(setSort)}
    />
  ));

  return (
    <Modal
      onClose={(): void => setOpen(false)}
      onOpen={(): void => setOpen(true)}
      open={open}
      closeIcon
      trigger={(
        <div className="link brand" style={{ position: 'relative' }}>
          <Popup
            inverted
            basic
            size="mini"
            position="bottom center"
            style={{ backgroundColor: '#5A5B5D' }}
            content="Settings"
            trigger={(<Icon name="setting" />)}
          />
          {indicator}
        </div>
      )}
      size="small"
    >
      <Modal.Header>
        <h1>Filters</h1>
      </Modal.Header>
      <Modal.Content>
        <Form>
          <ModalFormField
            title="Rep"
            options={teamOptions}
            placeholder="Select rep"
            handleChange={handleChange(setReps)}
            multiple
            value={reps}
          />
          <Form.Field>
            <label>Sort by</label>
            {radios}
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={handleClear}>Clear All Filters</Button>
        <Button color="grey" onClick={(): void => setOpen(false)}>Cancel</Button>
        <Button color="pink" onClick={handleSave}>Save</Button>
      </Modal.Actions>
    </Modal>
  );
};

interface FormFieldProps extends DropdownProps {
  handleChange: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  title: string;
  options?: DropdownItemProps[];
}
/* eslint-disable react/jsx-props-no-spreading */
const ModalFormField: React.FC<FormFieldProps> = ({ handleChange, title, options, ...dropdownProps }) => {
  if (!options) return null;
  return (
    <Form.Field>
      <label>{title}</label>
      <Dropdown
        onChange={handleChange}
        fluid
        selection
        options={options}
        {...dropdownProps}
      />
    </Form.Field>
  );
};
/* esline-enable */

export default SettingsModal;
