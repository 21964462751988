import { bindActionCreators, Dispatch } from 'redux';

import { appSlice } from 'features/App/appSlice';
import account from './account-actions';
import activity from './activity-actions';
import admin from './admin-actions';
import alert from './alert-actions';
import app, { logout } from './app-actions';
import organization from './organization-actions';
import recommendation from './recommendation-actions';
import settings from './settings-actions';
import team from './team-actions';
import user from './user-actions';

const slices = [
  appSlice,
];

const slicesObj = slices.reduce((a, v) => ({ ...a, [v.name]: v.actions }), {});

const combinedActions = {
  ...account,
  ...activity,
  ...admin,
  ...alert,
  ...app,
  ...organization,
  ...recommendation,
  ...settings,
  ...team,
  ...user,
  ...slicesObj,
};

export default combinedActions;

// helper function to bind combined actions for convenience
export const bindCombinedActions = (
  appCombinedActions: any,
  dispatch: Dispatch,
  actionsToLoad: string[] | string | boolean = false,
): { [key: string]: Array<{ [key: string]: Function }> } => {
  const boundActions = {};
  Object.entries(appCombinedActions).forEach(([k, actions]: [string, any]) => {
    if (actionsToLoad && typeof actionsToLoad === 'string') {
      if (!actionsToLoad.includes(k)) {
        return;
      }
    }
    boundActions[k] = bindActionCreators(actions, dispatch);
  });
  return boundActions;
};

// helpers for actions
export function handleError(response, dispatch): void {
  if (response.error === 'NO_AUTH' || response.error === 'INVALID_TOKEN') {
    dispatch(logout());
  }
  if (response.error === 'SESSION_EXPIRED') {
    dispatch(logout('Session closed as new login was made'));
  }
}
