import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import {
  Button,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Form,
  Icon,
  Label,
  Loader,
  Modal,
  Popup,
} from 'semantic-ui-react';

import { RootState } from 'store';

import { funnelStatsApi } from 'features/Api';
import { selectAppDateSelection } from 'features/App/appSlice';
import { getApiDatesFromTimePeriod } from 'features/DatePicker/datePickerFunctions';
import { setFunnelOwnerIds, setFunnelFilter, FunnelFilter, FunnelIsNewTypes } from 'features/Funnel/funnelSlice';

import { getTrackedSelectedTeamMembersForDropdown } from 'selectors/team';
import { CustomFilter } from 'models/picklist';

interface Props {
  showOwnerFilters?: boolean;
  trigger?: React.ReactElement | null;
}

const FunnelFilterComponent: React.FC<Props> = ({
  showOwnerFilters = false,
  trigger = (<Icon name="filter" />),
}) => {
  const timePeriod = useSelector(selectAppDateSelection);
  const { start, end } = getApiDatesFromTimePeriod(timePeriod);
  const funnelOwnerIds = useSelector((state: RootState) => state.funnel.ownerId);
  const filter: FunnelFilter = useSelector((state: RootState) => state.funnel.filter);
  const teamMembersForDropdown = useSelector(getTrackedSelectedTeamMembersForDropdown);

  const [open, setOpen] = useState(false);
  const [isNew, setIsNew] = useState<FunnelIsNewTypes>('new');
  const [ownerIds, setOwnerIds] = useState(funnelOwnerIds || []);
  const [types, setTypes] = useState(filter.type || []);
  const [industries, setIndustries] = useState(filter.industry || []);
  const [countries, setCountries] = useState(filter.country || []);
  const [states, setStates] = useState(filter.state || []);
  const [leadSources, setLeadSources] = useState(filter.leadSource || []);
  const [customFilters, setCustomFilters] = useState(filter.custom || []);

  const dispatch = useDispatch();

  const {
    data: apiResponse,
    isFetching,
  } = funnelStatsApi.endpoints.getFunnelFilterValues.useQuery({
    startDate: DateTime.fromISO(start).toFormat('yyyyMMdd'),
    endDate: DateTime.fromISO(end).toFormat('yyyyMMdd'),
  });

  if (isFetching || !apiResponse?.status) {
    return (<div style={{ padding: '25px' }}><Loader active /></div>);
  }

  const convertStringsToOptions = (values: string[]): DropdownItemProps[] => {
    const options = values.map((val: string): DropdownItemProps => ({
      text: val,
      value: val,
    }));
    return options;
  };

  const isNewOptions: DropdownItemProps[] = [
    { text: 'New Contacts', value: 'new' },
    { text: 'Existing Contacts', value: 'existing' },
    { text: 'All Contacts', value: 'all' },
  ];
  const typeOptions = convertStringsToOptions(apiResponse.type);
  const industryOptions = convertStringsToOptions(apiResponse.industry);
  const countryOptions = convertStringsToOptions(apiResponse.country);
  const stateOptions = convertStringsToOptions(apiResponse.state);
  const leadSourceOptions = convertStringsToOptions(apiResponse.leadSource);

  const handleChange = stateFn => (_, data): void => {
    stateFn(data.value);
  };
  const handleCustomChange = (config: CustomFilter) => (_, data): void => {
    const { field } = config;
    const newFilters = [...customFilters];
    const i = newFilters.findIndex(c => c.field === field);
    if (i === -1) {
      newFilters.push({ ...config, values: data.value });
    } else {
      newFilters.splice(i, 1, {
        ...config,
        values: data.value,
      });
    }
    setCustomFilters(newFilters);
  };
  const handleSave = (event: React.MouseEvent): void => {
    event.stopPropagation();
    setOpen(false);
    dispatch(setFunnelOwnerIds(ownerIds));
    dispatch(setFunnelFilter({
      country: countries,
      industry: industries,
      isNew,
      leadSource: leadSources,
      state: states,
      type: types,
      custom: customFilters,
    }));
  };
  const handleReset = (): void => {
    setOpen(false);
    setIsNew(filter.isNew || 'new');
    setOwnerIds(funnelOwnerIds);
    setTypes(filter.type);
    setIndustries(filter.industry);
    setCountries(filter.country);
    setStates(filter.state);
    setLeadSources(filter.leadSource);
    setCustomFilters(filter.custom || []);
  };
  const handleClear = (): void => {
    setOpen(false);
    setIsNew('new');
    setOwnerIds([]);
    setTypes([]);
    setIndustries([]);
    setCountries([]);
    setStates([]);
    setLeadSources([]);
    setCustomFilters([]);
    dispatch(setFunnelOwnerIds([]));
    dispatch(setFunnelFilter({
      type: [],
      industry: [],
      country: [],
      state: [],
      leadSource: [],
      custom: [],
    }));
  };

  let activeFilters = 0;
  if (showOwnerFilters && ownerIds?.length) activeFilters += 1;
  if (types?.length) activeFilters += 1;
  if (industries?.length) activeFilters += 1;
  if (countries?.length) activeFilters += 1;
  if (states?.length) activeFilters += 1;
  if (leadSources?.length) activeFilters += 1;
  if (customFilters?.filter(c => c.values.length).length) activeFilters += 1;

  const indicator = activeFilters ? (
    <Label
      floating
      circular
      color="pink"
      style={{ fontSize: '.3rem' }}
    />
  ) : null;

  const ownerFormField = showOwnerFilters ? (
    <ModalFormField
      title="Owners"
      options={teamMembersForDropdown}
      placeholder="Select owner"
      handleChange={handleChange(setOwnerIds)}
      multiple
      search
      value={ownerIds}
    />
  ) : null;

  const customFilterDropdowns = apiResponse.custom?.map(customFilterConfig => {
    const { object, field, label, values: dropdownValues, isCustom } = customFilterConfig;
    const cf = customFilters.find(c => c.field === field);
    const values = cf?.values;

    const dropdownOptions = convertStringsToOptions(dropdownValues);

    const config: CustomFilter = {
      object,
      field,
      values: dropdownValues,
      isCustom,
    };
    return (
      <ModalFormField
        key={`${object}-${field}`}
        title={label}
        options={dropdownOptions}
        placeholder={`Select ${label}`}
        handleChange={handleCustomChange(config)}
        multiple
        search
        value={values}
      />
    );
  });

  return (
    <Modal
      onClose={(): void => handleReset()}
      onOpen={(): void => setOpen(true)}
      open={open}
      closeIcon
      trigger={(
        <div className="link brand" style={{ position: 'relative' }}>
          <Popup
            inverted
            basic
            size="mini"
            position="bottom center"
            style={{ backgroundColor: '#5A5B5D' }}
            content="Filters"
            trigger={(
              <div style={{ position: 'relative' }}>
                {trigger}
                {indicator}
              </div>
            )}
          />
        </div>
      )}
      size="small"
    >
      <Modal.Header>
        <h1>Filters</h1>
      </Modal.Header>
      <Modal.Content>
        <Form>
          {ownerFormField}
          <ModalFormField
            title="New/Existing Contact"
            options={isNewOptions}
            placeholder="Select"
            handleChange={handleChange(setIsNew)}
            value={isNew}
          />
          <ModalFormField
            title="Account Type"
            options={typeOptions}
            placeholder="Select type"
            handleChange={handleChange(setTypes)}
            multiple
            search
            value={types}
          />
          <ModalFormField
            title="Account Industry"
            options={industryOptions}
            placeholder="Select industry"
            handleChange={handleChange(setIndustries)}
            multiple
            search
            value={industries}
          />
          <ModalFormField
            title="Account Country"
            options={countryOptions}
            placeholder="Select country"
            handleChange={handleChange(setCountries)}
            multiple
            search
            value={countries}
          />
          <ModalFormField
            title="Account State"
            options={stateOptions}
            placeholder="Select state"
            handleChange={handleChange(setStates)}
            multiple
            search
            value={states}
          />
          <ModalFormField
            title="Contact Lead Source"
            options={leadSourceOptions}
            placeholder="Select lead source"
            handleChange={handleChange(setLeadSources)}
            multiple
            search
            value={leadSources}
          />
          {customFilterDropdowns}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={handleClear}>Clear All Filters</Button>
        <Button onClick={handleReset}>Cancel</Button>
        <Button color="pink" onClick={handleSave}>Save</Button>
      </Modal.Actions>
    </Modal>
  );
};

interface FormFieldProps extends DropdownProps {
  handleChange: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  title: string;
  options?: DropdownItemProps[];
}
/* eslint-disable react/jsx-props-no-spreading */
const ModalFormField: React.FC<FormFieldProps> = ({ handleChange, title, options, ...dropdownProps }) => {
  if (!options) return null;
  return (
    <Form.Field>
      <div>{title}</div>
      <Dropdown
        onChange={handleChange}
        fluid
        selection
        options={options}
        {...dropdownProps}
      />
    </Form.Field>
  );
};
/* esline-enable */

export default FunnelFilterComponent;
