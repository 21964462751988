import React, { Component, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { Modal, Button, Container } from 'semantic-ui-react';
import { motion } from 'framer-motion';

import allActions, { bindCombinedActions } from 'actions';

import { Team } from 'models/team';
import { getIsProspecting, getTeamsOfUser } from 'selectors/team';

import ConnectedHeap from 'components/ConnectedHeap';
import PrivateRoute from 'components/PrivateRoute';

import LogoutController from 'components/Auth/LogoutController';
import AppHeader from './components/AppHeader';
import AppMenu from './components/AppMenu';

import AccountHealthRoute from './AccountHealthRoute';
import ContactRoute from './Contact';
import AlertsDash from './AlertsDash';
import AlertRule from './AlertRule';
import Dashboard from './Dashboard';
import ProspectingDashboard from './Dashboard/ProspectingDashboard';
import NotificationSidebar from './NotificationSidebar';
import PipelineRoute from './PipelineRoute';
import ProspectingHealth from './ProspectingHealthRoute';
import RecommendationsRoute from './RecommendationsRoute';
import Settings from './Settings';
import Tags from './Tags';
import User from './User';

import css from './Main.module.css';

interface Props {
  actions: any;
  location: any;
  isLoadingOrganization: boolean;
  isLoggedIn: boolean;
  isOrganizationActive: boolean;
  selectedTeamId: number;
  isProspecting: boolean;
  userTeams?: Team[];
}
interface State {
  minimizedAppMenu: boolean;
  isMobile: boolean;
  visibleSidebar: boolean;
}

class Main extends Component<Props, State> {
  constructor(props) {
    super(props);

    const minimized = (localStorage.getItem('isMenuMinimized') === 'true') || false;
    this.state = { minimizedAppMenu: minimized, isMobile: false, visibleSidebar: false };
  }

  componentDidMount(): void {
    const { actions } = this.props;
    actions.settings.getSettingsTaskmap();
    actions.organization.fetchOrganizationInfo();
    actions.organization.fetchOpportunityStages();
    actions.organization.fetchOpportunityTypes();
    actions.user.fetchUsers();
    actions.team.fetchTeams();
    actions.team.fetchTeamsWithMembers();
    actions.team.fetchUserTeams();
    actions.recommendation.fetchRecommendationList();
    this.handleWindowsResize();
    window.addEventListener('resize', this.handleWindowsResize);
  }

  componentDidUpdate(prevProps): void {
    const { actions, selectedTeamId, userTeams, location, isLoadingOrganization } = this.props;
    if (userTeams && userTeams.length > 0 && !selectedTeamId) {
      actions.team.setSelectedTeamId(userTeams[0].id);
      actions.team.fetchTeamSettings(userTeams[0].id);
    } else if (selectedTeamId !== prevProps.selectedTeamId || (
      !isLoadingOrganization && prevProps.isLoadingOrganization && selectedTeamId
    )) {
      actions.team.fetchTeamSettings(selectedTeamId);
    }
    if (selectedTeamId !== prevProps.selectedTeamId || location.pathname !== prevProps.location.pathname) {
      actions.alert.fetchAlertNotifications();
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.handleWindowsResize);
  }

  handleWindowsResize = (): void => {
    if (window.innerWidth < 1290) {
      this.setState({ minimizedAppMenu: true, isMobile: true });
    } else {
      const minimized = (localStorage.getItem('isMenuMinimized') === 'true') || false;
      this.setState({ minimizedAppMenu: minimized, isMobile: false });
    }
  };

  handleLogout = (): void => {
    const { actions } = this.props;
    sessionStorage.removeItem('startDate');
    sessionStorage.removeItem('endDate');
    actions.app.logout();
  };

  handleMinimizeToggle = (): void => {
    const { minimizedAppMenu } = this.state;

    localStorage.setItem('isMenuMinimized', (!minimizedAppMenu).toString());
    this.setState({ minimizedAppMenu: !minimizedAppMenu });
  }

  handleNotificationToggle = (event: SyntheticEvent): void => {
    const targetId = event.currentTarget.id;
    this.setState(prevState => {
      let willBeVisible;
      if (prevState.visibleSidebar && targetId !== 'alert-sidebar') {
        willBeVisible = false;
      } else if (prevState.visibleSidebar && targetId === 'alert-sidebar') {
        willBeVisible = true;
      } else if (targetId === 'alert-badge') {
        willBeVisible = !prevState.visibleSidebar;
      }

      return { visibleSidebar: willBeVisible };
    });
    event.stopPropagation();
  }

  renderModal = (): React.ReactNode => {
    const { isLoadingOrganization, isOrganizationActive } = this.props;
    return (
      <Modal open={!isLoadingOrganization && !isOrganizationActive}>
        <Modal.Header>Account Not Yet Activated</Modal.Header>
        <Modal.Content>
          {'Please contact your account manager or '}
          <a href="mailto:support@buyersight.io">support@buyersight.io</a>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleLogout}>Logout</Button>
        </Modal.Actions>
      </Modal>
    );
  };

  render(): React.ReactNode {
    const { isLoggedIn, location, isProspecting } = this.props;
    const { minimizedAppMenu, isMobile, visibleSidebar } = this.state;

    const dashboardComponent = isProspecting ? ProspectingDashboard : Dashboard;

    return (
      <>
        <div className={css.pushable} onClick={this.handleNotificationToggle}>
          <div>
            <AppMenu
              isMobile={isMobile}
              minimized={minimizedAppMenu}
              onMinimizeToggle={this.handleMinimizeToggle}
            />

            <motion.div
              animate={{ marginLeft: minimizedAppMenu ? '86px' : '250px' }}
              transition={{ duration: 0.1 }}
            >
              <LogoutController />
              <Container className={css.mainContainer}>
                <AppHeader
                  onNotificationToggle={this.handleNotificationToggle}
                  location={location}
                  visibleNotificationSidebar={visibleSidebar}
                />
                <div className={css.page}>
                  <Switch>
                    <PrivateRoute isAuthenticated={isLoggedIn} path="/app/account" component={AccountHealthRoute} />
                    <PrivateRoute isAuthenticated={isLoggedIn} path="/app/contact" component={ContactRoute} />
                    <PrivateRoute isAuthenticated={isLoggedIn} path="/app/alerts" component={AlertsDash} />
                    <PrivateRoute isAuthenticated={isLoggedIn} path="/app/alert/:alertId" component={AlertRule} />
                    <PrivateRoute isAuthenticated={isLoggedIn} path="/app/pipeline" component={PipelineRoute} />
                    <PrivateRoute isAuthenticated={isLoggedIn} path="/app/prospecting" component={ProspectingHealth} />
                    <PrivateRoute isAuthenticated={isLoggedIn} path="/app/recommendations" component={RecommendationsRoute} />
                    <PrivateRoute isAuthenticated={isLoggedIn} path="/app/settings" component={Settings} />
                    <PrivateRoute isAuthenticated={isLoggedIn} path="/app/tags" component={Tags} />
                    <PrivateRoute isAuthenticated={isLoggedIn} path="/app/team/:userId" component={User} />
                    <PrivateRoute isAuthenticated={isLoggedIn} path="/" component={dashboardComponent} />
                  </Switch>
                </div>
              </Container>
              {isLoggedIn ? (<ConnectedHeap />) : null}
              {this.renderModal()}
            </motion.div>
          </div>
        </div>
        <NotificationSidebar visible={visibleSidebar} />
      </>
    );
  }
}

export default connect(
  (state: any) => ({
    isLoggedIn: state.app.isLoggedIn,
    isLoadingOrganization: state.organization.isLoadingOrganization,
    isOrganizationActive: state.organization.active,
    selectedTeamId: state.team.selectedTeamId,
    isProspecting: getIsProspecting(state),
    userTeams: getTeamsOfUser(state),
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(Main);
