import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Button, Checkbox, Header } from 'semantic-ui-react';

import allActions, { bindCombinedActions } from 'actions';

import css from './Consent.module.css';

interface Props {
  actions: any;
  consentRequest: boolean;
  tosConsented: boolean;
}

interface State {
  isChecked: boolean;
}

class Consent extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = { isChecked: props.tosConsented };
  }

  handleConsentClick = (): void => {
    const { actions } = this.props;
    actions.app.consent();
  };

  handleCheck = (_, data): void => {
    const { checked } = data;
    this.setState({ isChecked: checked });
  };

  logout = (): void => {
    const { actions } = this.props;
    actions.app.logout();
  };

  render(): React.ReactNode {
    const { consentRequest } = this.props;
    const { isChecked } = this.state;
    const acceptButtonDisabled = !isChecked || consentRequest;
    return (
      <div className={css.main}>
        <Header>
          Terms of Service
        </Header>
        <div>
          By clicking the checkbox below, you are acknowledging that you accept our
          {' '}
          <Link to="/tos">Terms of Service</Link>
          .
        </div>
        <div className={css.checkbox}>
          <Checkbox
            label="I understand and agree to the Terms of Service"
            onChange={this.handleCheck}
            checked={isChecked}
          />
        </div>
        <div className={css.buttonSection}>
          <Button onClick={this.logout}>Logout</Button>
          <Button onClick={this.handleConsentClick} primary disabled={acceptButtonDisabled}>Accept</Button>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    consentRequest: state.app.consentRequest,
    tosConsented: state.app.tosConsented,
  }),
  dispatch => ({
    actions: bindCombinedActions(allActions, dispatch, ['app']),
    navigate: bindActionCreators((path: string) => push(path), dispatch),
  }),
)(Consent);
