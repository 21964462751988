import { createSlice } from '@reduxjs/toolkit';
import api from 'lib/api';
import { DateSelectionSliceState } from '../DatePicker/datePickerTypes';
import { createDateSelectionSliceSelectorsAndReducers } from '../DatePicker/datePickerFunctions';

const SLICE_NAME = 'app_';

interface AppState extends DateSelectionSliceState {
  oAuthProvider: 'hubspot' | 'salesforce' | undefined;
}

const loginHubspotFn = (): void => {
  window.location.href = api.getHubSpotLoginUrl();
};

const loginSalesforceFn = (): void => {
  window.location.href = api.getSalesforceLoginUrl();
};

const initialState: AppState = {
  dateSelection: {
    type: 'relative',
    start: { days: -14 },
    end: { days: 0 },
  },
  oAuthProvider: undefined,
};

const selectorsAndReducers = createDateSelectionSliceSelectorsAndReducers<AppState>(SLICE_NAME);

const { setDateSelectionReducer: setDateFn } = selectorsAndReducers;

export const appSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setAppDateSelection: setDateFn,
    loginHubspot: loginHubspotFn,
    loginSalesforce: loginSalesforceFn,
  },
});

export const {
  selectDateSelection: selectAppDateSelection,
  selectStartAndEndDates: selectAppStartAndEndDates,
} = selectorsAndReducers;

export const { setAppDateSelection, loginHubspot, loginSalesforce } = appSlice.actions;
