import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonProps,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Form,
  Modal,
  Popup,
  Label,
  Icon,
} from 'semantic-ui-react';

import { healthStatusMap } from 'models/account';

import { ActiveAccountListFilters } from '../ActiveAccountList';

interface Props {
  filters?: ActiveAccountListFilters;
  onChange?: (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => void;
  ownerOptions?: DropdownItemProps[];
}

const statusOptions: DropdownItemProps[] = Object.values(healthStatusMap);

const FilterModal: React.FC<Props> = ({ filters, onChange, ownerOptions }) => {
  const [open, setOpen] = useState(false);
  const [owners, setOwners] = useState(filters?.ownerIds || []);
  const [status, setStatus] = useState(filters?.statuses || []);

  const handleChange = stateFn => (_, data): void => {
    stateFn(data.value);
  };
  const handleSave = (event): void => {
    setOpen(false);
    const data = { owners, status };
    if (onChange) onChange(event, data);
  };
  const handleClear = (event): void => {
    setOwners([]);
    setStatus([]);
    setOpen(false);
    if (onChange) onChange(event, {});
  };

  useEffect(() => {
    if (filters?.ownerIds) setOwners(filters.ownerIds);
    if (filters?.statuses) setStatus(filters.statuses);
  }, [filters]);

  let activeFilters = 0;
  if (owners.length) activeFilters += 1;
  if (status.length) activeFilters += 1;

  const indicator = activeFilters ? (
    <Label
      floating
      circular
      color="pink"
      style={{ fontSize: '.3rem' }}
    />
  ) : null;

  return (
    <Modal
      onClose={(): void => setOpen(false)}
      onOpen={(): void => setOpen(true)}
      open={open}
      closeIcon
      trigger={(
        <div className="link brand" style={{ position: 'relative' }}>
          <Popup
            inverted
            basic
            size="mini"
            position="bottom center"
            style={{ backgroundColor: '#5A5B5D' }}
            content="Filters"
            trigger={<Icon name="filter" />}
          />
          {indicator}
        </div>
      )}
      size="small"
    >
      <Modal.Header>
        <h1>Filters</h1>
      </Modal.Header>
      <Modal.Content>
        <Form>
          <ModalFormField
            title="Health"
            options={statusOptions}
            handleChange={handleChange(setStatus)}
            multiple
            value={status}
          />
          <ModalFormField
            title="Account Owner"
            options={ownerOptions}
            handleChange={handleChange(setOwners)}
            multiple
            value={owners}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={handleClear}>Clear All Filters</Button>
        <Button color="grey" onClick={(): void => setOpen(false)}>
          Cancel
        </Button>
        <Button color="pink" onClick={handleSave}>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

interface FormFieldProps extends DropdownProps {
  handleChange: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  title: string;
  options?: DropdownItemProps[];
}

/* eslint-disable react/jsx-props-no-spreading */
const ModalFormField: React.FC<FormFieldProps> = ({ handleChange, title, options, ...dropdownProps }) => {
  if (!options) return null;
  return (
    <Form.Field>
      <div>{title}</div>
      <Dropdown
        placeholder="Select.."
        onChange={handleChange}
        fluid
        selection
        options={options}
        {...dropdownProps}
      />
    </Form.Field>
  );
};
/* esline-enable */
export default FilterModal;
