export interface BSLegendOptions {
  containerID: string;
  display?: boolean;
  flexDirection?: CSSStyleDeclaration['flexDirection'];
  justifyContent?: CSSStyleDeclaration['justifyContent'];
  borderWidth?: number;
}

const getOrCreateLegendList = (_chart, options: BSLegendOptions): HTMLUListElement | null | undefined => {
  const { containerID: id, flexDirection, justifyContent } = options;
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer?.querySelector('ul');

  if (!listContainer && legendContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = flexDirection || 'row';
    listContainer.style.justifyContent = justifyContent || 'flex-start';
    listContainer.style.margin = '0';
    listContainer.style.padding = '0';

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const legend = {
  id: 'bslegend',
  afterUpdate: (chart, _args, options): void => {
    const ul = getOrCreateLegendList(chart, options);
    const { display, borderWidth } = options;

    if (!ul || display === false) {
      return;
    }

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item): void => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      li.style.marginLeft = '10px';
      li.style.marginBottom = '20px';

      li.onclick = (): void => {
        const { type } = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        chart.update();
      };

      const borderWidthStr = borderWidth ? `${borderWidth}px` : `${item.lineWidth}px`;

      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.style.backgroundColor = item.hidden ? 'none' : item.fillStyle;
      boxSpan.style.borderStyle = 'solid';
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = borderWidthStr;
      boxSpan.style.display = 'inline-block';
      boxSpan.style.height = '10px';
      boxSpan.style.marginRight = '10px';
      boxSpan.style.width = '20px';
      boxSpan.style.borderRadius = '5px';

      // Text
      const textContainer = document.createElement('p');
      textContainer.style.color = item.hidden ? '#CCCCCC' : item.fontColor;
      textContainer.style.margin = '0';
      textContainer.style.padding = '0';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};

export default legend;
