import {
  ProspectingContactBreadcrumb,
} from 'App/Main/components/AppHeader/components/Breadcrumbs/ProspectingContactBreadcrumb';
import withBreadcrumbs, { BreadcrumbsRoute } from 'react-router-breadcrumbs-hoc';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { AccountBreadcrumb } from 'App/Main/components/AppHeader/components/Breadcrumbs/AccountBreadcrumb';
import UserBreadcrumb from 'App/Main/components/AppHeader/components/Breadcrumbs/UserBreadcrumb';
import RecommendationBreadcrumb from 'App/Main/components/AppHeader/components/Breadcrumbs/RecommendationBreadcrumb';

import css from 'App/Main/components/AppHeader/components/Breadcrumbs/styles.module.css';

const routes: Array<BreadcrumbsRoute> = [
  { path: '/app', breadcrumb: 'Home' },
  { path: '/app/account', breadcrumb: 'Accounts' },
  { path: '/app/account/:accountId', breadcrumb: AccountBreadcrumb },
  { path: '/app/recommendations/admin', breadcrumb: 'Admin' },
  { path: '/app/recommendations/:recommendationId', breadcrumb: RecommendationBreadcrumb },
  { path: '/app/team/:userId', breadcrumb: UserBreadcrumb },
  { path: '/app/team/:userId/activityFeed', breadcrumb: 'Timeline' },
  { path: '/app/prospecting/contacts', breadcrumb: 'No Account' },
  { path: '/app/prospecting/contacts/:contactId', breadcrumb: ProspectingContactBreadcrumb },
  { path: '/app/prospecting/:accountId', breadcrumb: AccountBreadcrumb },
  { path: '/app/prospecting/:accountId/contacts/:contactId', breadcrumb: ProspectingContactBreadcrumb },
];

const routesToHide = [
  '/app',
  '/app/comparison',
];

/**
 * Routes here will not have links added to the breadcrumb text
 */
const routesToNotLinkTo = [
  '/app/prospecting/:accountId',
];

const Breadcrumbs = ({ breadcrumbs }): React.ReactElement => {
  if (routesToHide.includes(window.location.pathname)) {
    return <></>;
  }

  return (
    <div className={css.breadcrumbs}>
      {breadcrumbs.map(bc => {
        const { match, breadcrumb } = bc;
        let breadcrumbText = <NavLink to={match.url}>{breadcrumb}</NavLink>;
        if (routesToNotLinkTo.includes(match.path)) {
          breadcrumbText = <>{breadcrumb}</>;
        }
        return (
          <span key={match.url} className={css.breadcrumb}>
            {breadcrumbText}
          </span>
        );
      })}
    </div>
  );
};

const excludePaths = ['/', '/app/team'];

export default withBreadcrumbs(routes, { excludePaths })(Breadcrumbs);
