import param from 'jquery-param';
import baseApi from '../Api/apiSlice';
import {
  GetUserPipelineAtDateApiQueryParam, GetUserPipelineAtDateApiResponse,
  PipelineOpenApiQueryParam,
  PipelineStagesApiQueryParam,
} from './pipelineTypes';

const pipelineApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getOpenPipeline: builder.query<any, PipelineOpenApiQueryParam>({
      query: params => {
        const { startDateIso, endDateIso } = params;
        return `pipeline/open?startDate=${startDateIso}&endDate=${endDateIso}`;
      },
    }),
    getPipelineStages: builder.query<any, PipelineStagesApiQueryParam>({
      query: params => `pipeline/stages?${param(params)}`,
    }),
    getUserPipelineAtDate: builder.query<GetUserPipelineAtDateApiResponse, GetUserPipelineAtDateApiQueryParam>({
      query: params => {
        const { teamId, userId, dateIso } = params;
        return `team/${teamId}/${userId}/pipeline/${dateIso}`;
      },
    }),
  }),
});

export const useGetOpenPipelineQuery = pipelineApi.endpoints.getOpenPipeline.useQuery;
export const useGetPipelineStagesQuery = pipelineApi.endpoints.getPipelineStages.useQuery;
export const useGetUserPipelineAtDateQuery = pipelineApi.endpoints.getUserPipelineAtDate.useQuery;
