import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Button, Popup } from 'semantic-ui-react';
import Calendar from 'react-calendar';
import React, { useState } from 'react';
import { DateTime } from 'luxon';

import { useAppDispatch } from 'hooks';
import { TimePeriod, LabeledSelection } from './datePickerTypes';
import defaultSelections from './defaultSelections';
import { getJSDatesFromTimePeriod, getLuxonDateTimesFromTimePeriod } from './datePickerFunctions';

import css from './styles.module.css';

interface BaseProps {
  buttons?: LabeledSelection[];
  fluid?: boolean;
  timePeriod: TimePeriod;
  closeOnChange?: boolean;
}

interface PropsWithReduxAction extends BaseProps {
  reduxAction: ActionCreatorWithPayload<TimePeriod>;
  callback?: never;
}

interface PropsWithCallback extends BaseProps {
  callback: (TimePeriod) => any;
  reduxAction?: never;
}

type Props = PropsWithReduxAction | PropsWithCallback;

function getLabel(timePeriod: TimePeriod): string {
  const { start, end } = getLuxonDateTimesFromTimePeriod(timePeriod);
  return `${start.toLocaleString()} - ${end.toLocaleString()}`;
}

export const DatePicker = (props: Props): React.ReactElement => {
  const { fluid, timePeriod, reduxAction, callback, closeOnChange = true } = props;
  let { buttons } = props;

  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();

  const handleChange = (tp: TimePeriod): any => {
    if (reduxAction) {
      dispatch(reduxAction(tp));
    } else if (callback) {
      callback(tp);
    }
    if (closeOnChange) setOpen(false);
  };

  if (!buttons) {
    buttons = defaultSelections;
  }

  const buttonEls = buttons.map(buttonProps => {
    const { label: buttonLabel, ...tp } = buttonProps;
    const handleClick = (): void => {
      handleChange(tp);
    };
    return (<Button key={buttonLabel} onClick={handleClick}>{buttonLabel}</Button>);
  });

  const handleCalendarChange = (value: Date[]): void => {
    const [startDate, endDate] = value;
    const start = DateTime.fromJSDate(startDate).toObject();
    const end = DateTime.fromJSDate(endDate).toObject();
    handleChange({
      start,
      end,
      type: 'absolute',
    });
  };

  const { start, end } = getJSDatesFromTimePeriod(timePeriod);

  const label = getLabel(timePeriod);

  const style: React.CSSProperties = {};
  if (fluid) {
    style.width = '100%';
  }

  return (
    <Popup
      on="click"
      onClose={(): void => setOpen(false)}
      onOpen={(): void => setOpen(true)}
      open={open}
      trigger={<Button basic style={style}>{label}</Button>}
    >
      <div className={css.calendarDropdownContent}>
        <Calendar
          calendarType="US"
          maxDate={new Date()}
          onChange={handleCalendarChange}
          selectRange
          value={[start, end]}
        />
        <div className={css.presetDates}>
          <Button.Group vertical>
            {buttonEls}
          </Button.Group>
        </div>
      </div>
    </Popup>
  );
};

export default DatePicker;
