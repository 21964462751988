import React, { useState } from 'react';
import { Icon, Button, Input, InputOnChangeData, Popup, Header } from 'semantic-ui-react';
import { TeamWithMembers } from 'models/team';
import { EventBlocker } from 'components/EventBlocker/EventBlocker';
import { ClickAwayListener } from 'components/ClickAwayListener/ClickAwayListener';
import { connect } from 'react-redux';
import allActions, { bindCombinedActions } from 'actions';
import css from './CollapsedTeamCard.module.css';

interface CollapsedTeamCardProps {
  team: TeamWithMembers;
  active: boolean;
  actions: any;
}

export const CollapsedTeamCardBase: React.FC<CollapsedTeamCardProps> = ({ active, team, actions }) => {
  const [isEditingLoading, setIsEditingLoading] = useState(false);
  const [isEditingTeamName, setIsEditingTeamName] = useState(false);
  const [editedTeamName, setEditedTeamName] = useState(team.name);
  const [isDeletingTeam, setIsDeletingLoading] = useState(false);
  const [openConfirmDeletion, setOpenConfirmDeletion] = useState(false);

  const handleClickAway = (): void => {
    setIsEditingTeamName(false);
    setEditedTeamName(team.name);
  };

  const handleStartEditing = (): void => {
    setIsEditingTeamName(true);
  };

  const handleEdit = (): void => {
    setIsEditingLoading(true);
    const editedTeam = { ...team };
    editedTeam.name = editedTeamName;
    actions.team.editTeam(editedTeam).then(() => {
      setIsEditingLoading(false);
      handleClickAway();
    });
  };

  const handlePreDeleteTeam = (): void => {
    setOpenConfirmDeletion(true);
  };

  const handleClickAwayConfirmation = (): void => {
    setOpenConfirmDeletion(false);
  };

  const handleDeleteTeam = (): void => {
    setIsDeletingLoading(true);
    actions.team.deleteTeam(team.id).then(() => {
      setIsDeletingLoading(false);
      setOpenConfirmDeletion(false);
    });
  };

  const handleLabelChange = (_: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData): void => {
    setEditedTeamName(data.value);
  };

  return (
    <div className={css.container}>
      <div className={css.nameContainer}>
        <div className={css.labelContainer}>
          {isEditingTeamName
            ? (
              <EventBlocker>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Input defaultValue={editedTeamName} onChange={handleLabelChange} />
                  <Button className={css.saveBtn} loading={isEditingLoading} onClick={handleEdit} content="Save" />
                </ClickAwayListener>
              </EventBlocker>
            )
            : (
              <>
                <label>{team.name}</label>
                <div className={
                  `${css.editCell} ${openConfirmDeletion ? css.active : ''}`
                }
                >
                  <EventBlocker>
                    <div className={`link brand ${css.actionButton}`} onClick={handleStartEditing}>
                      <Icon name="edit" title="edit" />
                    </div>
                    <div className={`link brand ${css.actionButton}`} onClick={handlePreDeleteTeam}>
                      <Popup
                        position="top center"
                        trigger={<Icon name="trash" title="delete" />}
                        open={openConfirmDeletion}
                      >
                        <ClickAwayListener onClickAway={handleClickAwayConfirmation}>
                          <div className={css.confirmDeletionContent}>
                            <Header as="h5">
                              Are you sure you want to delete this team?
                            </Header>
                            <Button negative loading={isDeletingTeam} onClick={handleDeleteTeam}>Delete</Button>
                          </div>
                        </ClickAwayListener>
                      </Popup>
                    </div>
                  </EventBlocker>
                </div>
              </>
            )}
        </div>
      </div>
      <label className={css.info}>
        {team.members.length}
        {' '}
        team members
      </label>
      <div className={css.iconContainer}>
        <Icon className={`${css.icon} ${active && css.rotate}`} name="angle right" />
      </div>
    </div>
  );
};

export const CollapsedTeamCard = connect(
  (state: any) => ({ isEditingTeam: state.team.isEditingTeam }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(CollapsedTeamCardBase);
