import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Segment, Tab, TabPaneProps, MenuProps, Dropdown, DropdownProps } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import allActions, { bindCombinedActions } from 'actions';
import { Recommendation } from 'models/recommendation';
import { getResolvedNotifications, getUnresolvedNotifications } from 'selectors/recommendation';
import { Permission } from 'components/AccessControl';

import RecommendationRow from './components/RecommendationRow';

import css from './RecommendationsDash.module.css';

interface Props {
  actions: any;
  unresolved: Array<Recommendation>;
  resolved: Array<Recommendation>;
  isSuperAdmin: boolean;
  tags: Array<string>;
  isLoadingTags: boolean;
  permission: Permission;
}

interface State {
  activeTagFilters: (string | number | boolean)[];
}

class RecommendationsList extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = { activeTagFilters: [] };
  }

  componentDidMount(): void {
    const { actions } = this.props;
    actions.recommendation.fetchRecommendationTags();
  }

  handleResolve = async (notificationId: number): Promise<void> => {
    const { actions } = this.props;
    await actions.recommendation.markResolved(notificationId);
    await actions.recommendation.fetchRecommendationList();
  };

  handleUnresolve = async (notificationId: number): Promise<void> => {
    const { actions } = this.props;
    await actions.recommendation.markUnresolved(notificationId);
    await actions.recommendation.fetchRecommendationList();
  };

  handleTagChange = (_, data: DropdownProps): void => {
    let { value: tags } = data;
    tags = tags || [];
    if (!Array.isArray(tags)) tags = [tags];
    this.setState({ activeTagFilters: tags });
  }

  handleDelete = async (notificationId: number): Promise<void> => {
    const { actions } = this.props;
    await actions.recommendation.deleteRecommendation(notificationId);
    await actions.recommendation.fetchRecommendationList();
  };

  render(): React.ReactNode {
    const { resolved, unresolved, isSuperAdmin, tags, isLoadingTags, permission } = this.props;
    const { activeTagFilters } = this.state;

    const filterFn = (r): boolean => (
      activeTagFilters.length === 0
      || activeTagFilters.filter(t => r.tags?.includes(t)).length > 0
    );

    const resolvedRows = resolved.length ? resolved.filter(filterFn).map(r => (
      <RecommendationRow
        key={r.notificationId}
        recommendation={r}
        permission={permission}
        onArchive={(): void => { this.handleUnresolve(r.notificationId); }}
        onDelete={(): void => { this.handleDelete(r.notificationId); }}
      />
    )) : (<i>You have no archived recommendations</i>);
    const unresolvedRows = unresolved.length ? unresolved.filter(filterFn).map(r => (
      <RecommendationRow
        key={r.notificationId}
        recommendation={r}
        permission={permission}
        onArchive={(): void => { this.handleResolve(r.notificationId); }}
        onDelete={(): void => { this.handleDelete(r.notificationId); }}
      />
    )) : (<i>You have no recommendations in your inbox</i>);

    const tagOptions = tags.map(tag => ({
      key: tag,
      text: tag,
      value: tag,
    }));

    const tagSection = tags.length ? (
      <Dropdown
        placeholder="Filter By Tag"
        loading={isLoadingTags}
        multiple
        selection
        options={tagOptions}
        onChange={this.handleTagChange}
      />
    ) : null;

    const tabPanes: TabPaneProps[] = [
      { menuItem: 'Inbox', render: (): React.ReactNode => unresolvedRows },
    ];
    tabPanes.push({ menuItem: 'Archive', render: (): React.ReactNode => resolvedRows });

    const tabMenuProps: MenuProps = {
      secondary: true,
      pointing: true,
    };

    return (
      <div className={css.dashboard}>
        <Segment className={css.segment}>
          <div className={css.header}>
            <h1>Recommendations</h1>
          </div>
          {tags?.length ? (
            <div className={css.tags}>
              {tagSection}
            </div>
          ) : null}
          <Tab menu={tabMenuProps} panes={tabPanes} className={css.inbox} />
        </Segment>
        {isSuperAdmin ? (<Link to="/app/recommendations/admin">Admin</Link>) : null}
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    isSuperAdmin: state.app.isSuperAdmin,
    unresolved: getUnresolvedNotifications(state),
    resolved: getResolvedNotifications(state),
    tags: state.recommendation.tags,
    isLoadingTags: state.recommendation.isLoadingTags,
    permission: state.app.permission,
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(RecommendationsList);
