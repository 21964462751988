import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { GoalActivity } from 'models/goal';
import { formatDate } from 'lib/util';
import { getSelectedTeamMembers } from 'selectors/team';
import AccountAppLink from 'components/Links/AccountAppLink';
import ContactAppLink from 'components/Links/ContactAppLink';

export const GoalTrackingActivity = (props: {
  data: GoalActivity[];
}): ReactElement => {
  const { data } = props;

  const teamMembers = useSelector(getSelectedTeamMembers);
  const trackedTeamMemberIds = teamMembers.filter(u => u.isTracked).map(u => u.id);

  const rows = data.map(r => {
    let activityNode: React.ReactNode = r.subject;
    let ownerNode: React.ReactNode = r.ownerName;
    if (trackedTeamMemberIds.includes(r.ownerId)) {
      const linkDate = formatDate(r.activityDate, 'YYYY-MM-DD');
      const activityLink = `/app/team/${r.ownerId}/activityFeed?activity=${r.id}&date=${linkDate}`;
      activityNode = (<Link to={activityLink}>{r.subject}</Link>);
      ownerNode = (<Link to={`/app/team/${r.ownerId}`}>{r.ownerName}</Link>);
    }
    return (
      <Table.Row key={r.id}>
        <Table.Cell>{formatDate(r.activityDate, 'MM/DD/YYYY')}</Table.Cell>
        <Table.Cell>{activityNode}</Table.Cell>
        <Table.Cell>{r.type}</Table.Cell>
        <Table.Cell>{ownerNode}</Table.Cell>
        <Table.Cell>
          <ContactAppLink contact={{ id: r.contactId, name: r.contactName }} />
        </Table.Cell>
        <Table.Cell>
          <AccountAppLink account={{ id: r.accountId, name: r.accountName }} />
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <>
      <Table sortable compact basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Activity Date</Table.HeaderCell>
            <Table.HeaderCell>Subject</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Owner</Table.HeaderCell>
            <Table.HeaderCell>Contact</Table.HeaderCell>
            <Table.HeaderCell>Account</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows}
        </Table.Body>
      </Table>
    </>
  );
};

export default GoalTrackingActivity;
