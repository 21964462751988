import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Header,
  Popup,
  Icon,
} from 'semantic-ui-react';

import {
  getSelectedTeamMembers,
} from 'selectors/team';
import { getGoalHeaderText } from 'lib/util';

import DimmerLoader from 'components/DimmerLoader';
import {
  SortSettings,
  TeamComparisonChart,
  Value,
} from 'components/TeamComparisonChart/TeamComparisonChart';
import { TeamComparisonChartBody } from 'components/TeamComparisonChart/Body/Body';
import teamGoalAttainmentApi, { TeamGoalData } from 'features/Api/Goals';

import { convertAttainmentToDatum } from '../goalUtil';

import css from './GoalTeamComparison.module.css';

const GoalTeamComparison: FC<{
  goalData: TeamGoalData;
  teamId: number;
  includedRepIds?: number[];
  sortSettings: SortSettings | undefined;
  displayAsPercentage: boolean;
  setDisplayAsPercentage: (val: boolean) => void;
}> = ({
  goalData: initialGoalData,
  teamId,
  includedRepIds,
  sortSettings,
  displayAsPercentage,
  setDisplayAsPercentage,
}): ReactElement => {
  const { goal, goalAttainments: initialAttainments, period: initialPeriod } = initialGoalData;
  const [goalAttainments, setGoalAttainments] = useState(initialAttainments);
  const [period, setPeriod] = useState(initialPeriod);
  const [periodIndex, setPeriodIndex] = useState(initialPeriod.index);

  const { id: goalId, target } = goal;
  const teamMembers = useSelector(getSelectedTeamMembers);

  const [getGoalTrigger, goalResult] = teamGoalAttainmentApi
    .endpoints
    .getTeamGoal
    .useLazyQuery();

  useEffect(() => {
    if (periodIndex !== period.index) {
      getGoalTrigger({ teamId, goalId, periodIndex });
    }
  }, [periodIndex]);

  useEffect(() => {
    if (goalResult.isSuccess && goalResult.data?.goalAttainments) {
      const { goalAttainments: ga, period: p } = goalResult.data;
      setGoalAttainments(ga);
      setPeriod(p);
    }
  }, [goalResult]);

  const handlePeriodChange = (num: number) => (): void => {
    const newperiod = Math.max(periodIndex + num, 0);
    setPeriodIndex(newperiod);
  };

  const {
    data: unfilteredData,
    max,
    legend,
  } = convertAttainmentToDatum({ goal, goalAttainments, period }, teamMembers);
  const data = unfilteredData.filter(d => !includedRepIds?.length || includedRepIds.includes(d.userId));
  const targetValue: Value = {
    sortValue: target,
    displayValue: target.toString(),
  };
  const maxValue: Value = {
    sortValue: max,
    displayValue: max.toString(),
  };

  const { subHeaderText, popupContent } = getGoalHeaderText(period);
  const iconStyle = { cursor: 'pointer' };
  const nextButtonStyle = periodIndex === 0 ? { cursor: 'pointer', visibility: 'hidden' } : { cursor: 'pointer' };
  const previousGoalBtn = <Icon style={iconStyle} name="chevron left" onClick={handlePeriodChange(1)} />;
  const nextGoalBtn = <Icon style={nextButtonStyle} name="chevron right" onClick={handlePeriodChange(-1)} />;

  let subHeader = (
    <Header className={css.goalSubHeader}>
      {previousGoalBtn}
      {subHeaderText}
      {nextGoalBtn}
    </Header>
  );
  if (popupContent) {
    subHeader = <Popup content={popupContent} trigger={subHeader} />;
  }
  return (
    <TeamComparisonChart
      data={data || []}
      higherIsBetter
      legend={legend}
      maxValue={maxValue}
      sortSettings={sortSettings}
    >
      <TeamComparisonChart.Header className={css.modalChartHeader}>
        <section className={css.legendBox}>
          <TeamComparisonChart.Legend />
        </section>
        <section className={css.datePicker}>
          <Button.Group
            className={css.buttons}
            buttons={[
              {
                key: false,
                icon: 'hashtag',
                active: !displayAsPercentage,
                onClick: (): void => setDisplayAsPercentage(false),
              },
              {
                key: true,
                icon: 'percent',
                active: displayAsPercentage,
                onClick: (): void => setDisplayAsPercentage(true),
              },
            ]}
          />
          {subHeader}
        </section>
      </TeamComparisonChart.Header>
      <TeamComparisonChartBody sortable showHeaders size="big">
        <DimmerLoader active={goalResult.isFetching} />
        <TeamComparisonChartBody.NameColumn linkNames />
        <TeamComparisonChartBody.BarColumn enablePopup>
          { targetValue ? (
            <TeamComparisonChartBody.BarColumn.BenchmarkLine
              value={targetValue}
              title="Target"
              color="#2C87DC"
            />
          ) : <></> }
        </TeamComparisonChartBody.BarColumn>
        { displayAsPercentage ? <TeamComparisonChartBody.PercentColumn /> : <TeamComparisonChartBody.ValueColumn /> }
      </TeamComparisonChartBody>
    </TeamComparisonChart>
  );
};

export default GoalTeamComparison;
