import React from 'react';
import { Card } from 'semantic-ui-react';

import { ProspectingMetric, ProspectingMetricCodename } from 'features/Api/Prospecting';

import RecommendationCard from './RecommendationCard';
import css from './styles.module.css';

const ContactRecommendationsGroup = (props: {
  recommendations: ProspectingMetricCodename[];
  metrics: ProspectingMetric[];
  partition: 'preConversation' | 'postConversation';
}): React.ReactElement => {
  const { recommendations, partition, metrics } = props;
  const noRecommendations = (
    <div className={css.recommendationsGroup}>
      No recommendations
    </div>
  );
  if (!recommendations || recommendations.length === 0) {
    return noRecommendations;
  }

  const cards = recommendations?.map(r => (
    <RecommendationCard
      codename={r}
      partition={partition}
      metrics={metrics}
      key={`contact-rec-codename-${r}`}
    />
  ));

  if (!cards || cards.length === 0) {
    return noRecommendations;
  }
  return (
    <Card.Group className={css.cardsGroup}>
      {cards}
    </Card.Group>
  );
};

export default ContactRecommendationsGroup;
