import { round } from 'lib/util';
import { Duration } from 'luxon';
import { MetricDisplayInfo } from 'models/metric';

export interface EmailMetrics {
  avgActivitiesPerDay: number;
  prospectingPercentage: number | null;
  avgWordCount: number | null;
  avgGradeLevel: number | null;
  accountsEngaged: number;
  contactsEngaged: number;
  newAccounts: number;
  newContacts: number;
  newConversations: number;
  avgRepResponse: number | null;
  avgRepFollowUp: number | null;
  avgProspectResponse: number | null;
}

const emailMetricNames = [
  'activityBreakdown',
  'avgActivitiesPerDay',
  'prospectingPercentage',
  'avgWordCount',
  'avgGradeLevel',
  'accountsEngaged',
  'contactsEngaged',
  'avgRepResponse',
  'avgRepFollowUp',
  'avgProspectResponse',
] as const;

export type EmailMetricName = typeof emailMetricNames[number];
export const isEmailMetricName = (x: any): x is EmailMetricName => emailMetricNames.includes(x);

export const emailMetricDisplayInfo: {
  [stat in EmailMetricName]: MetricDisplayInfo & { name: stat };
} = {
  activityBreakdown: {
    name: 'activityBreakdown',
    title: 'Total Activities',
    higherIsBetter: true,
    displayValueFn: (val: number): string => round(val, 1).toString(),
    unitIcon: 'hashtag',
    order: 10,
    enabled: true,
  },
  avgActivitiesPerDay: {
    name: 'avgActivitiesPerDay',
    title: 'Average Daily Activities',
    higherIsBetter: true,
    displayValueFn: (val: number): string => round(val, 1).toString(),
    unitIcon: 'hashtag',
    order: 20,
    enabled: true,
  },
  accountsEngaged: {
    name: 'accountsEngaged',
    title: 'Accounts Engaged',
    higherIsBetter: true,
    displayValueFn: (val: number): string => round(val, 0).toString(),
    unitIcon: 'hashtag',
    order: 30,
    enabled: true,
  },
  contactsEngaged: {
    name: 'contactsEngaged',
    title: 'Contacts Engaged',
    higherIsBetter: true,
    displayValueFn: (val: number): string => round(val, 0).toString(),
    unitIcon: 'hashtag',
    order: 40,
    enabled: true,
  },
  avgWordCount: {
    name: 'avgWordCount',
    title: 'Email Length',
    higherIsBetter: false,
    displayValueFn: (val: number): string => round(val, 1).toString(),
    unitIcon: 'hashtag',
    order: 50,
    enabled: true,
  },
  avgGradeLevel: {
    name: 'avgGradeLevel',
    title: 'Email Grade Level',
    higherIsBetter: false,
    displayValueFn: (val: number): string => round(val, 1).toString(),
    unitIcon: 'hashtag',
    order: 60,
    enabled: true,
  },
  prospectingPercentage: {
    name: 'prospectingPercentage',
    title: 'Prospecting Percentage',
    higherIsBetter: true,
    displayValueFn: (val: number): string => round(val * 100, 1).toString(),
    unitIcon: 'hashtag',
    order: 70,
    enabled: true,
  },
  avgRepFollowUp: {
    name: 'avgRepFollowUp',
    title: 'Time Between Followups',
    higherIsBetter: false,
    displayValueFn: (val: number): string => {
      const days = Duration.fromObject({ seconds: val }).as('days');
      return `${days.toFixed(1)}d`;
    },
    unitIcon: 'clock outline',
    order: 80,
    enabled: true,
  },
  avgRepResponse: {
    name: 'avgRepResponse',
    title: 'Time to Respond',
    higherIsBetter: false,
    displayValueFn: (val: number): string => {
      const days = Duration.fromObject({ seconds: val }).as('days');
      return `${days.toFixed(1)}d`;
    },
    unitIcon: 'clock outline',
    order: 90,
    enabled: true,
  },
  avgProspectResponse: {
    name: 'avgProspectResponse',
    title: 'Prospect Response Time',
    higherIsBetter: false,
    displayValueFn: (val: number): string => {
      const days = Duration.fromObject({ seconds: val }).as('days');
      return `${days.toFixed(1)}d`;
    },
    unitIcon: 'clock outline',
    order: 100,
    enabled: false,
  },
};
