import { DateTime } from 'luxon';
import { CaseReducer, PayloadAction, Selector } from '@reduxjs/toolkit';
import {
  DateSelectionSliceState,
  RelativeTimePeriodBoundary,
  SimpleJSDateTimePeriod, SimpleStringTimePeriod,
  TimePeriod,
} from './datePickerTypes';

const DEFAULT_DAYS_AGO = 14;

function isRelativeTimePeriod(timePeriod: TimePeriod): timePeriod is TimePeriod<'relative'> {
  return (timePeriod as TimePeriod<'relative'>).type === 'relative';
}

const getLuxonDateTimeFromRelativeBoundary = (boundary: RelativeTimePeriodBoundary): DateTime => {
  const { startOf, endOf, ...duration } = boundary;
  const dateWithAddedDuration = DateTime.now().plus(duration);
  if (startOf) return dateWithAddedDuration.startOf(startOf);
  if (endOf) return dateWithAddedDuration.endOf(endOf);
  return dateWithAddedDuration.startOf('day');
};

export const getLuxonDateTimesFromTimePeriod = (timePeriod: TimePeriod): { start: DateTime; end: DateTime } => {
  try {
    if (isRelativeTimePeriod(timePeriod)) {
      const {
        start: startBoundary,
        end: endBoundary,
      } = timePeriod;

      return {
        start: getLuxonDateTimeFromRelativeBoundary(startBoundary),
        end: getLuxonDateTimeFromRelativeBoundary(endBoundary),
      };
    }
    const { start, end } = timePeriod;
    return {
      start: DateTime.fromObject(start),
      end: DateTime.fromObject(end),
    };
  } catch (e) {
    // if there's an invalid time period at any point, default to now and 30 days ago
    return {
      start: DateTime.now().minus({ day: 30 }),
      end: DateTime.now(),
    };
  }
};

export const getJSDatesFromTimePeriod = (timePeriod: TimePeriod): SimpleJSDateTimePeriod => {
  const { start, end } = getLuxonDateTimesFromTimePeriod(timePeriod);
  return {
    start: start.toJSDate(),
    end: end.toJSDate(),
  };
};

export const getApiDatesFromTimePeriod = (timePeriod: TimePeriod): SimpleStringTimePeriod => {
  const { start, end } = getLuxonDateTimesFromTimePeriod(timePeriod);
  return {
    start: start.toFormat('yyyyMMdd'),
    end: end.toFormat('yyyyMMdd'),
  };
};

export const getIsoDatesFromTimePeriod = (timePeriod: TimePeriod): { start: string; end: string } => {
  const { start, end } = getLuxonDateTimesFromTimePeriod(timePeriod);
  return {
    start: start.toISO(),
    end: end.toISO(),
  };
};

export const getTimePeriodFromLuxonDates = (start: DateTime, end: DateTime): TimePeriod<'absolute'> => ({
  type: 'absolute',
  start: start.toObject(),
  end: end.toObject(),
});

function createStartAndEndDatesSelector(sliceName: string) {
  return (state): SimpleJSDateTimePeriod => {
    const failedReturn = {
      start: new Date(),
      end: new Date(),
    };
    if (!state || !state[sliceName]) return failedReturn;
    const slice = state[sliceName];
    if (!slice.dateSelection) return failedReturn;

    const { dateSelection } = slice;
    return getJSDatesFromTimePeriod(dateSelection);
  };
}

function createDateSelectionSelector(sliceName: string) {
  return (state): TimePeriod => state[sliceName].dateSelection;
}

function createSetDateSelectionReducer
<State extends DateSelectionSliceState = DateSelectionSliceState>():
  CaseReducer<State, PayloadAction<TimePeriod>> {
  return (state, action): void => {
    // eslint-disable-next-line no-param-reassign
    state.dateSelection = action.payload;
  };
}

function createSetAbsoluteStartEndDatesReducer
<State extends DateSelectionSliceState = DateSelectionSliceState>():
  CaseReducer<State, PayloadAction<SimpleStringTimePeriod>> {
  return (state, action): void => {
    const { start: startStr, end: endStr } = action.payload;
    const start = DateTime.fromISO(startStr);
    const end = DateTime.fromISO(endStr);
    const { dateSelection } = state;
    dateSelection.type = 'absolute';

    dateSelection.start = start.toObject();
    dateSelection.end = end.toObject();
  };
}

export function getStartAndEndDatesFromSingleDate(
  selectedDateStr: string,
  daysAgo: number = DEFAULT_DAYS_AGO,
): { start: DateTime; end: DateTime } {
  const now = DateTime.now();
  const calcEnd = DateTime.fromISO(selectedDateStr).plus({ day: daysAgo / 2 });
  const end = DateTime.min(calcEnd, now);

  const start = end.minus({ day: daysAgo });
  return { start, end };
}

function createSetDateSelectionAroundDateReducer
<State extends DateSelectionSliceState = DateSelectionSliceState>(): CaseReducer<State, PayloadAction<string>> {
  return (state, action): void => {
    const { payload: selectedDateStr } = action;
    const { start, end } = getStartAndEndDatesFromSingleDate(selectedDateStr);

    const { dateSelection } = state;
    dateSelection.type = 'absolute';
    dateSelection.start = start.toObject();
    dateSelection.end = end.toObject();
  };
}

export function createDateSelectionSliceSelectorsAndReducers
<State extends DateSelectionSliceState = DateSelectionSliceState>(sliceName: string): {
  setDateSelectionReducer: CaseReducer<State, PayloadAction<TimePeriod>>;
  setAbsoluteStartEndDatesReducer: CaseReducer<State, PayloadAction<SimpleStringTimePeriod>>;
  setDateSelectionAroundDateReducer: CaseReducer<State, PayloadAction<string>>;
  selectStartAndEndDates: Selector<any, SimpleJSDateTimePeriod>;
  selectDateSelection: Selector<any, TimePeriod>;
} {
  return {
    setDateSelectionReducer: createSetDateSelectionReducer(),
    setAbsoluteStartEndDatesReducer: createSetAbsoluteStartEndDatesReducer(),
    setDateSelectionAroundDateReducer: createSetDateSelectionAroundDateReducer(),
    selectStartAndEndDates: createStartAndEndDatesSelector(sliceName),
    selectDateSelection: createDateSelectionSelector(sliceName),
  };
}
