import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Header, Input, Segment, Accordion, Loader } from 'semantic-ui-react';
import allActions, { bindCombinedActions } from 'actions';
import { TeamCard } from 'components/TeamCard/TeamCard';
import { getUsersGroupedByTeams } from 'selectors/team';
import { TeamWithMembers } from 'models/team';
import { User } from 'models/user';
import AddTeamMemberModal from 'components/Modals/AddTeamMemberModal';
import withSearchable, { WithSearchable } from 'hocs/withSearchable';
import AddTeamModal from 'components/Modals/AddTeamModal';

import css from './TeamManage.module.css';

interface Props extends WithSearchable<TeamWithMembers> {
  actions: any;
  isLoadingTeams: boolean;
  users: Array<User>;
  teams: TeamWithMembers[];
}

interface State {
  addTeamModalOpen: boolean;
  addTeamMemberModalOpen: boolean;
  activeTeamAccordionIndexes: number[];
  addingTeamMemberToTeamId: number | undefined;
  addingTeamMemberToTeamName: string | undefined;
}

class TeamManage extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      addTeamModalOpen: false,
      addTeamMemberModalOpen: false,
      addingTeamMemberToTeamId: undefined,
      addingTeamMemberToTeamName: undefined,
      activeTeamAccordionIndexes: [],
    };
  }

  componentDidMount(): void {
    const { actions, users, teams } = this.props;

    if (users.length === 0) {
      actions.user.fetchUsers();
    }

    if (teams.length === 0) {
      actions.team.fetchTeams();
      actions.team.fetchTeamsWithMembers();
    }
  }

  handleOpenAddNewTeamModal = (): void => {
    this.setState({ addTeamModalOpen: true });
  };

  handleSearch = (_, data): void => {
    const { setSearchQuery } = this.props;
    const searchTerm = data.value.trim();
    setSearchQuery(searchTerm);
  };

  handleCloseAddTeamModal = (): void => {
    this.setState({ addTeamModalOpen: false });
  }

  handleToggleActiveTeamAccordionIndex = (index: number): void => {
    const { activeTeamAccordionIndexes } = this.state;
    const newActiveAccordionIndexes = [...activeTeamAccordionIndexes];
    const isAlreadyActive = activeTeamAccordionIndexes.findIndex(i => i === index);
    if (isAlreadyActive === -1) {
      newActiveAccordionIndexes.push(index);
    } else {
      newActiveAccordionIndexes.splice(isAlreadyActive, 1);
    }

    this.setState({ activeTeamAccordionIndexes: newActiveAccordionIndexes });
  }

  handleOpenAddTeamMemberModal = (teamId: number, teamName: string): void => {
    this.setState({
      addTeamMemberModalOpen: true,
      addingTeamMemberToTeamId: teamId,
      addingTeamMemberToTeamName: teamName,
    });
  }

  renderTeams = (): React.ReactNode => {
    const { activeTeamAccordionIndexes } = this.state;
    const { searchedEntities, isLoadingTeams } = this.props;
    const teams = searchedEntities;

    if (teams.length > 0) {
      return teams.map((team, index) => (
        <TeamCard
          key={team.id}
          index={index}
          handleToggleActive={(): void => this.handleToggleActiveTeamAccordionIndex(index)}
          active={activeTeamAccordionIndexes.includes(index)}
          team={team}
          handleOpenAddTeamMemberModal={(): void => this.handleOpenAddTeamMemberModal(team.id, team.name)}
        />
      ));
    }
    if (isLoadingTeams) {
      return <Loader active={isLoadingTeams} />;
    }

    return <><label className={css.centered}>There are no teams yet</label></>;
  }

  render(): React.ReactNode {
    const {
      addTeamModalOpen,
      addTeamMemberModalOpen,
      addingTeamMemberToTeamId,
      addingTeamMemberToTeamName,
    } = this.state;
    return (
      <div className={css.userManage}>
        <Segment>
          <Input
            fluid
            onChange={this.handleSearch}
            placeholder="Filter by name"
          />
        </Segment>
        <Segment>
          <div className={css.headerContainer}>
            <div>
              <Header floated="left">
                Team management
              </Header>
            </div>
            <div>
              <Header floated="right">
                <Button onClick={this.handleOpenAddNewTeamModal}>+ Add Team</Button>
              </Header>
            </div>
          </div>
          <div className={css.teamsContainer}>
            <Accordion>
              {this.renderTeams()}
            </Accordion>
          </div>
        </Segment>
        {addTeamModalOpen && <AddTeamModal open onClose={this.handleCloseAddTeamModal} />}
        {addTeamMemberModalOpen
          && (
            <AddTeamMemberModal
              open={addTeamMemberModalOpen}
              teamId={addingTeamMemberToTeamId!}
              teamName={addingTeamMemberToTeamName!}
              onClose={(): void => this.setState({ addTeamMemberModalOpen: false })}
            />
          )}
      </div>
    );
  }
}

export default connect(
  (state: any) => ({
    users: state.user.users,
    teams: getUsersGroupedByTeams(state),
    isLoadingTeams: state.team.isLoadingTeams,
  }),
  dispatch => ({ actions: bindCombinedActions(allActions, dispatch) }),
)(withSearchable<TeamWithMembers, Props>(TeamManage, 'teams', 'name', false));
