import React, { ReactElement } from 'react';
import { prospectingApi } from 'features/Api';
import { useSelector } from 'react-redux';
import { getSelectedTeamId } from 'selectors/team';

const WithoutAccount = (props: {
  teamId: number;
  contactId: number;
}): ReactElement => {
  const { teamId, contactId } = props;
  const {
    data,
    isLoading,
  } = prospectingApi.endpoints.getProspectingContactsNoAccount.useQuery({ teamId });

  const genericContactEl = <>Contact</>;

  if (!data || isLoading) return <>Loading...</>;

  const { contacts } = data;

  const contact = contacts.find(c => c.id === contactId);
  if (contact === undefined) return genericContactEl;

  const { name } = contact;

  return (
    <span>
      {name}
    </span>
  );
};

const WithAccount = (props: {
  teamId: number;
  accountId: number;
  contactId: number;
}): ReactElement => {
  const { teamId, accountId, contactId } = props;
  const {
    data,
    isLoading,
  } = prospectingApi.endpoints.getProspectingContactsByAccount.useQuery({ teamId, accountId });

  const genericContactEl = <>Contact</>;

  if (!data || isLoading) return <>Loading...</>;

  const { contacts } = data;

  const contact = contacts.find(c => c.id === contactId);
  if (contact === undefined) return genericContactEl;

  const { name } = contact;

  return (
    <span>
      {name}
    </span>
  );
};

const Generic = (): ReactElement => (<>Contact</>);

export const ProspectingContactBreadcrumb = (
  props: {
    match: {
      params: {
        accountId: string;
        contactId: string;
      };
    };
  },
): ReactElement => {
  const teamId = useSelector(getSelectedTeamId);
  const { match: { params: { accountId: accountIdStr, contactId: contactIdStr } } } = props;
  const accountId = parseInt(accountIdStr, 10);
  const contactId = parseInt(contactIdStr, 10);
  if (teamId === undefined) return <Generic />;
  if (Number.isNaN(contactId)) return <Generic />;
  if (Number.isNaN(accountId)) return <WithoutAccount teamId={teamId} contactId={contactId} />;
  return <WithAccount teamId={teamId} accountId={accountId} contactId={contactId} />;
};
