import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Icon, Placeholder, Segment, Loader } from 'semantic-ui-react';

import api from 'lib/api';
import { Activity } from 'models/activity';
import ExternalLink from 'components/ExternalLink';

import css from './ContactInfo.module.css';

interface StateProps {
  contactActivity: Array<Activity>;
  contactId: number;
}

interface ActivityDate {
  date: Date;
  id: number;
}

const ContactInfo: React.FC<StateProps> = ({ contactId, contactActivity }) => {
  const [isFetchingContactActivityInfo, setIsFetchingContactActivityInfo] = useState(false);
  const [lastActivity, setLastActivity] = useState<ActivityDate | undefined>(undefined);
  const [firstActivity, setFirstActivity] = useState<ActivityDate | undefined>(undefined);

  const [isLoading, setIsLoading] = useState(false);
  const [extId, setExtId] = useState();
  const [extSource, setExtSource] = useState();
  const [name, setName] = useState();

  useEffect(() => {
    if (contactId !== undefined) {
      setIsLoading(true);
      api.getContact(contactId).then(res => {
        if (res.status && res.contact) {
          const { contact } = res;
          setExtId(contact.extId);
          setExtSource(contact.extSource);
          setName(contact.name);
        }
      }).finally(() => {
        setIsLoading(false);
      });
      setIsFetchingContactActivityInfo(true);
      api.getContactActivityInfo(contactId).then(res => {
        setIsFetchingContactActivityInfo(false);
        if (res.status) {
          setLastActivity({ date: res.data.lastActivityDate, id: res.data.lastActivityId });
          setFirstActivity({ date: res.data.firstActivityDate, id: res.data.firstActivityId });
        }
      }).finally(() => {
        setIsFetchingContactActivityInfo(false);
      });
    }
  }, [contactId]);

  const renderFirstAndLastActivity = (): React.ReactNode => {
    if (firstActivity === undefined || lastActivity === undefined) {
      return null;
    }

    const firstActivityDate = moment(firstActivity.date);
    const lastActivityDate = moment(lastActivity.date);

    const baseUrl = `/app/contact/${contactId}/activity`;
    return (
      <>
        <div className={css.stat}>
          <div className={css.label}>Most Recent Activity</div>
          <div className={css.value}>
            <Link to={`${baseUrl}?activity=${lastActivity.id}&date=${lastActivityDate.format('YYYY-MM-DD')}`}>
              {lastActivityDate.format('MMM DD, YYYY')}
            </Link>
          </div>
        </div>
        <div className={css.stat}>
          <div className={css.label}>First Activity</div>
          <div className={css.value}>
            <Link to={`${baseUrl}?activity=${firstActivity.id}&date=${firstActivityDate.format('YYYY-MM-DD')}`}>
              {firstActivityDate.format('MMM DD, YYYY')}
            </Link>
          </div>
        </div>
      </>
    );
  };


  const renderAvgWeekTouch = (): React.ReactNode => {
    if (firstActivity === undefined) {
      return null;
    }

    const numWeeks = Math.ceil(moment().diff(firstActivity.date, 'days') / 7);
    const avgActivityPerWeek = numWeeks ? Math.floor((100 * contactActivity.length) / numWeeks) / 100 : 0;

    return (
      <div className={css.stat}>
        <div className={css.label}>Avg. Touchpoints / Week</div>
        <div className={css.value}>
          {avgActivityPerWeek}
        </div>
      </div>
    );
  };

  return isLoading ? (
    <Segment>
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    </Segment>
  ) : (
    <Segment className={css.content}>
      <Icon name="building" size="big" />
      <div className={css.contactHeader}>
        {name}
        {' '}
        <ExternalLink extId={extId} extSource={extSource} type="contact" />
      </div>
      {isFetchingContactActivityInfo && <Loader active />}
      {(lastActivity !== undefined && firstActivity !== undefined)
          && (
            <>
              {renderFirstAndLastActivity()}
              {renderAvgWeekTouch()}
            </>
          )}
    </Segment>
  );
};

export default ContactInfo;
