import { AccountApi } from 'features/Api';
import React, { FC } from 'react';

export const AccountBreadcrumb: FC<{ match: { params: { accountId: string } } }> = params => {
  const { match: { params: { accountId: accountIdStr } } } = params;
  const accountId = parseInt(accountIdStr, 10);

  const { data: accountData, isFetching } = AccountApi.getAccount.useGetAccountQuery({ accountId });

  if (!accountData || isFetching) return <>Loading...</>;

  const { account: { name } } = accountData;

  return (
    <span>
      {name}
    </span>
  );
};
