import React, { FC, ReactElement } from 'react';
import { Tab } from 'semantic-ui-react';

import { SortSettings } from 'components/TeamComparisonChart/TeamComparisonChart';
import { metricnameToActivityStatnameMap } from 'features/Api/Activity';
import { setAppDateSelection } from 'features/App/appSlice';
import DatePicker from 'features/DatePicker/DatePicker';
import { TimePeriod } from 'features/DatePicker/datePickerTypes';
import { MetricName } from 'models/metric';

import TeamComparisonChartContent from '../TeamComparisonChartContent';
import TeamComparisonChartHistoric from '../TeamComparisonChartHistoric';

import css from './MetricModalContent.module.css';

const MetricModalContent: FC<{
  modalMetric: MetricName;
  teamId: number;
  timePeriod: TimePeriod;
  includedRepIds?: number[];
  sortSettings: SortSettings | undefined;
  displayAsPercentage: boolean;
  setDisplayAsPercentage: (val: boolean) => void;
}> = ({
  modalMetric,
  teamId,
  timePeriod,
  includedRepIds,
  sortSettings,
  displayAsPercentage,
  setDisplayAsPercentage,
}): ReactElement => {
  const mappedMetric = metricnameToActivityStatnameMap[modalMetric];

  const comparisonChart = (
    <TeamComparisonChartContent
      modalMetric={modalMetric}
      teamId={teamId}
      timePeriod={timePeriod}
      includedRepIds={includedRepIds}
      sortSettings={sortSettings}
      displayAsPercentage={displayAsPercentage}
      setDisplayAsPercentage={setDisplayAsPercentage}
    />
  );

  if (!mappedMetric) {
    return (
      <>
        <div className={css.datePickerNoTab}>
          <DatePicker timePeriod={timePeriod} reduxAction={setAppDateSelection} />
        </div>
        <div>
          {comparisonChart}
        </div>
      </>
    );
  }

  const panes = [
    {
      menuItem: 'Overview',
      render: (): ReactElement => comparisonChart,
    },
    {
      menuItem: 'Historical',
      render: (): ReactElement => (
        <TeamComparisonChartHistoric
          modalMetric={mappedMetric}
          timePeriod={timePeriod}
          teamId={teamId}
        />
      ),
    },
  ];

  return (
    <>
      <div className={css.datePicker}><DatePicker timePeriod={timePeriod} reduxAction={setAppDateSelection} /></div>
      <Tab panes={panes} menu={{ secondary: true, pointing: true }} />
    </>
  );
};

export default MetricModalContent;
