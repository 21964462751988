import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';

interface Props {
  extId: string;
  type?: 'account' | 'contact' | 'opportunity' | 'activity' | 'user';
}

const HubSpotLink = (props: Props): React.ReactElement | null => {
  const { extId, type } = props;

  const organizationExtId = useSelector((state: any) => state.organization.extId);

  let url;
  switch (type) {
    case 'account':
      url = `//app.hubspot.com/contacts/${organizationExtId}/company/${extId}`;
      break;
    case 'activity':
      // //app.hubspot.com/contacts/${organizationExtId}/company/${companyExtId}/?engagement=${extId}
      break;
    case 'contact':
      url = `//app.hubspot.com/contacts/${organizationExtId}/contact/${extId}`;
      break;
    case 'opportunity':
      url = `//app.hubspot.com/contacts/${organizationExtId}/deal/${extId}`;
      break;
    case 'user':
      break;
    default:
      break;
  }

  if (!url) {
    return null;
  }

  return (
    <sup>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Icon name="external" size="small" />
      </a>
    </sup>
  );
};

export default HubSpotLink;
